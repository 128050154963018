import type { JssStyle } from 'jss';

import { ESize } from '@common/enums';

export const commonInputStyles: Record<ESize, JssStyle> = {
    [ESize.S]: {
        height: 31,
        padding: '5px 10px',
        fontSize: 13,
        borderRadius: 3,
    },
    [ESize.M]: {
        height: 39,
        padding: '8px 12px',
        fontSize: 15,
        borderRadius: 4,
    },
    [ESize.L]: {
        height: 48,
        padding: '10px 16px',
        fontSize: 19,
        borderRadius: 6,
    },
};

export const commonWebsitesFormInputStyles: Record<ESize, JssStyle> = {
    [ESize.S]: {
        height: '35px',
        padding: '8px 10px',
        fontSize: '13px',
        lineHeight: '15px',
    },
    [ESize.M]: {
        height: '41px',
        padding: '10px 16px',
        fontSize: '15px',
        lineHeight: '17px',
    },
    [ESize.L]: {
        height: '57px',
        padding: '16px 22px',
        fontSize: '19px',
        lineHeight: '21px',
    },
};

export const commonWebsitesFormTextareaStyles: Record<ESize, JssStyle> = {
    [ESize.S]: {
        height: '65px',
        padding: '8px 10px',
        fontSize: '13px',
        lineHeight: '15px',
    },
    [ESize.M]: {
        height: '75px',
        padding: '10px 16px',
        fontSize: '15px',
        lineHeight: '17px',
    },
    [ESize.L]: {
        height: '99px',
        padding: '16px 22px',
        fontSize: '19px',
        lineHeight: '21px',
    },
};

export const commonLabelStyles: Record<ESize, JssStyle> = {
    [ESize.S]: {
        fontSize: 13,
    },
    [ESize.M]: {
        fontSize: 15,
    },
    [ESize.L]: {
        fontSize: 19,
    },
};
