import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { RESOURCE_URL } from '../tokens';
import getAnalyticsHeaders from '@sites/analytics/src/app/shared/hooks/get-analytics-headers';

@Injectable({ providedIn: 'root' })
export class UseAnalytics {
    private readonly http: HttpClient = inject(HttpClient);
    private readonly resourceUrl = inject(RESOURCE_URL);

    public trackPageVisit(pageId: number): void {
        this.http
            .post(
                `${this.resourceUrl}/api/landings-service/public/visit`,
                {
                    pageId,
                },
                {
                    headers: getAnalyticsHeaders(),
                },
            )
            .subscribe();
    }
}
