<div [class]="classes?.modalWrapper">
    <button type="button" class="close-modal-btn" aria-label="Close" (click)="onCancel()">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="imageContainer">
        <ng-container *ngIf="images.length; else emptyImage">
            <swiper
                class="default-swiper"
                [ngClass]="{ 'with-bottom-navigation': isHasBottomSpace }"
                [config]="swiperConfig"
                style="--swiper-navigation-color: {{ websiteColorCss(cardSettings.gallery.buttonColor) }}; --swiper-pagination-color: {{ websiteColorCss(cardSettings.gallery.paginationColor) }}"
                [rewind]="true"
                [navigation]="true"
                [pagination]="{ clickable: true, dynamicBullets: true }"
                [grabCursor]="true"
                [autoplay]="autoplay"
                [spaceBetween]="0"
                [slidesPerView]="1"
                [autoHeight]="data.cardSettings.gallery.aspectRatio === aspectRatio.default">
                <ng-template swiperSlide *ngFor="let image of images; index as i; trackBy: instanceId">
                    <div
                        *ngIf="data.cardSettings.gallery.aspectRatio !== aspectRatio.default"
                        class="image-wrapper imageWrapper">
                        <ng-container *ngIf="image?.hasResize; else displayDefault">
                            <picture>
                                <source
                                    *ngFor="let source of imagesSrcSets[i]"
                                    [srcset]="source.url"
                                    [media]="source.media"
                                />
                                <img
                                    class="gridImage"
                                    [src]="formatImageURL(image)"
                                    [alt]="image.alt"
                                    [style.objectPosition]="(image.backgroundPositionX || 50) + '% ' + (image.backgroundPositionY || 50) + '%'"
                                />
                            </picture>
                        </ng-container>
                        <ng-template #displayDefault>
                            <img
                                class="gridImage"
                                [src]="formatImageURL(image)"
                                [alt]="image.alt"
                                [style.objectPosition]="(image.backgroundPositionX || 50) + '% ' + (image.backgroundPositionY || 50) + '%'"
                            />
                        </ng-template>
                    </div>
                    <div *ngIf="data.cardSettings.gallery.aspectRatio === aspectRatio.default"
                         class="image-wrapper imageWrapper">
                        <img
                            class="default-image"
                            [src]="formatImageURL(image)"
                            [alt]="image.alt"
                        />
                    </div>
                </ng-template>
            </swiper>
        </ng-container>
        <ng-template #emptyImage>
            <div class="blank-wrapper">
                <img [src]="formatImgSrc('./assets/img/websites/lpc-empty-product.png')" alt="product_blank">
            </div>
        </ng-template>
    </div>
    <div class="contentWrapper">
        <div class="card-title sp-ui-text">
            <h2>{{ element.name }}</h2>
        </div>

        <div class="card-price-block sp-ui-text" *ngIf="element.price">
            <p class="card-new-price">{{ element.price}} {{ currency }}</p>
            <p class="card-old-price" *ngIf="element.oldPrice"> {{ element.oldPrice }} {{ currency }}</p>
        </div>

        <div class="variants-block sp-ui-text d-flex" *ngIf="propertiesArray.controls.length">
            <div class="mr-10" *ngFor="let property of propertiesArray.value; index as i">
                <label>{{ property.label }}</label>
                <select (change)="setPropertyValue($event?.target['value'], i)">
                    <option [value]="''" [selected]="!property.value"> {{ 'choose_variant_tooltip' | translate }}</option>
                    <option *ngFor="let option of property.parameters"
                            [value]="option"
                            [selected]="option === property.value"
                            [disabled]="isDisabledOption(option.trim(), i)">
                        {{ option }}</option>
                </select>
            </div>
        </div>

        <div class="buttonWrapper">
            <a [class]="getBasketButtonStyles" (click)="addItemToCart(element, $event)" class="card-button" [class.disabled]="isDisabledCartButton">
                <span>{{ basketButton.label }}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.4142 4.58579C23.1953 5.36683 23.1953 6.63317 22.4142 7.41421L11.4142 18.4142C10.6332 19.1953 9.36684 19.1953 8.58579 18.4142L2.58579 12.4142C1.80474 11.6332 1.80474 10.3668 2.58579 9.58579C3.36683 8.80474 4.63317 8.80474 5.41421 9.58579L10 14.1716L19.5858 4.58579C20.3668 3.80474 21.6332 3.80474 22.4142 4.58579Z" fill="white"/>
                </svg>
            </a>
            <div [class]="isZeroQuantity || isDisabledCartButton || !isHasAvailableVariants ? 'hint-container' : 'hide-hint'">

                <span *ngIf="isZeroQuantity || !isHasAvailableVariants else chooseProperties" class="sp-ui-text">{{ 'widget_product_not_available' | translate }}</span>
                <ng-template #chooseProperties><span class="sp-ui-text"> {{ 'choose_variant_tooltip' | translate }}</span></ng-template>
            </div>

            <ng-container *ngIf="isPhysicalProductType">
                <ng-container *ngIf="!isZeroQuantity; else notAvailable">
                    <div class="card-product-counter">
                        <button class="counter-control" (click)="changeCounter(counterControl.value - 1)" [class.disabled]="isMinValue">
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                <rect y="4" width="10" height="2" rx="1" fill="currentColor"/>
                            </svg>
                        </button>
                        <input class="quantity-control"
                               [formControl]="counterControl"
                               [min]="1"
                               [max]="quantity"
                               type="number"
                               (change)="changeCounter($event.target['value'])"
                               (blur)="validateCounter()"
                        />
                        <button class="counter-control" (click)="changeCounter(counterControl.value + 1)" [class.disabled]="isMaxValue">
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4 9C4 9.55228 4.44772 10 5 10C5.55228 10 6 9.55228 6 9V6H9C9.55229 6 10 5.55228 10 5C10 4.44772 9.55228 4 9 4H6V1C6 0.447715 5.55228 0 5 0C4.44772 0 4 0.447716 4 1V4H1C0.447715 4 0 4.44772 0 5C0 5.55228 0.447715 6 1 6H4V9Z" fill="currentColor"/>
                            </svg>
                        </button>
                    </div>
                </ng-container>
                <ng-template #notAvailable>
                    <div class="sp-ui-text"> {{ 'widget_product_not_available' | translate }} </div>
                </ng-template>
            </ng-container>
        </div>

        <div class="card-description sp-ui-text">
            <div [innerHTML]="element.description | spSafeHtml"></div>
        </div>

        <div class="card-parameters sp-ui-text" *ngFor="let parameter of element.sections">
            <label> {{ parameter.name }}</label>
            <ng-container *ngFor="let param of parameter.parameters">
                <p>{{ param.parameter }}<span *ngIf="param.value">:</span> {{ param.value }}</p>
            </ng-container>
        </div>
    </div>
</div>
