import type { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { type AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import type { ThemeFont, ThemeSelectedFonts } from '@libs/themes/src/types/theme-font.type';

import { Component, inject, type OnDestroy, type OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { ESimpleThemeFontProperties } from '@libs/themes/src/variants/simple/enums/font.enum';
import { EThemeFontTags } from '@libs/themes/src/enums/font-tags.enum';
import { EThemeFontFamilies, type EThemeFontWeights } from '@libs/themes/src/enums/font-families.enum';

import {
    FONT_FAMILY_NAMES,
    FONT_FAMILY_RULES,
    FONT_FAMILY_WEIGHTS,
    FONT_WEIGHT_VALUES,
} from '@libs/themes/src/constants/font-families.conts';
import { CSS_RESTRICTIONS } from '@common/constants';
import { DeviceMode } from '@common/types/layout.type';
import { getValidFontSize, getValidLetterSpacing, getValidLineHeight } from '@common/helpers';

import { convertFontValuesFromIntToStr, convertFontValuesFromStrToInt } from '@libs/themes/src/helpers/converters';

import { ThemeFacade } from '@libs/themes/src/store/theme/theme.facade';
import { ThemeSettingsFacade } from '@libs/themes/src/store/theme-settings/theme-settings.facade';

@Component({
    template: '',
})
export abstract class SpThemeFontSettingsComponent implements OnInit, OnDestroy {
    protected readonly fb: UntypedFormBuilder = inject(UntypedFormBuilder);
    protected readonly themeFacade: ThemeFacade = inject(ThemeFacade);
    protected readonly themeSettingsFacade: ThemeSettingsFacade = inject(ThemeSettingsFacade);

    protected hasMobileStyles: boolean = false;

    public abstract readonly FONT_TAG_TRANSLATIONS: Readonly<Record<EThemeFontTags, string>>;
    public abstract readonly FONT_WEIGHT_TRANSLATIONS: Readonly<Record<EThemeFontWeights, string>>;

    public form: UntypedFormArray;
    public isSynchronizedControl: UntypedFormControl;
    public initialFontsState: ThemeSelectedFonts;
    public isSaved = false;
    public deviceMode: DeviceMode = DeviceMode.desktop;

    public FONTS_TAGS: EThemeFontTags[];
    public readonly FONT_FAMILY_LIST: string[] = Object.values(EThemeFontFamilies);
    public readonly HIDDEN_FONT_TAGS: EThemeFontTags[] = [EThemeFontTags.pre];
    public readonly FONT_FAMILY_NAMES = FONT_FAMILY_NAMES;
    public readonly FONT_FAMILY_WEIGHTS = FONT_FAMILY_WEIGHTS;
    public readonly FONT_WEIGHT_VALUES = FONT_WEIGHT_VALUES;
    public readonly simpleThemeFontProperties = ESimpleThemeFontProperties;

    private readonly unsubscribe$ = new Subject<void>();

    constructor() {
        this.beforeInitForm();
        this.initForm();
    }

    protected abstract beforeInitForm(): void;

    public ngOnInit(): void {
        this.form.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((data: ThemeFont[]) => {
            const pFont: ThemeFont = data.find((item: ThemeFont) => item.tag === EThemeFontTags.p);

            const fonts: ThemeFont[] = data.map((font: ThemeFont) => {
                if (this.HIDDEN_FONT_TAGS.includes(font.tag)) {
                    return convertFontValuesFromIntToStr({
                        ...font,
                        font: pFont.font,
                        styles: {
                            ...font.styles,
                            fontFamily: pFont.styles.fontFamily,
                        },
                    });
                }

                return convertFontValuesFromIntToStr(font);
            });

            const valuesToDispatch: ThemeSelectedFonts = {
                selected: fonts,
                selectedIndex: null,
            };

            if (this.hasMobileStyles) {
                valuesToDispatch.isSynchronized = this.isSynchronizedControl.value ?? true;
            }

            this.dispatchValues(valuesToDispatch);
        });

        if (this.hasMobileStyles) {
            this.isSynchronizedControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((isSynchronized: boolean) => {
                this.themeSettingsFacade.changeFontsSynchronizationAction(isSynchronized);
            });
        }

        this.setFormData();
    }

    protected initForm(): void {
        const tagFonts = this.FONTS_TAGS.map((tag: string) => {
            const group: any = {
                tag,
                font: null,
                styles: this.fb.group({
                    fontFamily: null,
                    fontSize: [null, [Validators.min(CSS_RESTRICTIONS.FONT_SIZE_MIN), Validators.max(CSS_RESTRICTIONS.FONT_SIZE_MAX)]],
                    fontWeight: null,
                    fontStyle: null,
                    lineHeight: [
                        null,
                        [Validators.min(CSS_RESTRICTIONS.LINE_HEIGHT_MIN), Validators.max(CSS_RESTRICTIONS.LINE_HEIGHT_MAX)],
                    ],
                    textTransform: null,
                    letterSpacing: [
                        null,
                        [Validators.min(CSS_RESTRICTIONS.LETTER_SPACING_MIN), Validators.max(CSS_RESTRICTIONS.LETTER_SPACING_MAX)],
                    ],
                }),
            };

            if (this.hasMobileStyles) {
                group.mobileStyles = this.fb.group({
                    fontFamily: null,
                    fontSize: [null, [Validators.min(CSS_RESTRICTIONS.FONT_SIZE_MIN), Validators.max(CSS_RESTRICTIONS.FONT_SIZE_MAX)]],
                    fontWeight: null,
                    fontStyle: null,
                    lineHeight: [
                        null,
                        [Validators.min(CSS_RESTRICTIONS.LINE_HEIGHT_MIN), Validators.max(CSS_RESTRICTIONS.LINE_HEIGHT_MAX)],
                    ],
                    textTransform: null,
                    letterSpacing: [
                        null,
                        [Validators.min(CSS_RESTRICTIONS.LETTER_SPACING_MIN), Validators.max(CSS_RESTRICTIONS.LETTER_SPACING_MAX)],
                    ],
                });

                this.isSynchronizedControl = this.fb.control(true);
            }

            return this.fb.group(group);
        });

        this.form = this.fb.array(tagFonts);
    }

    protected setFormData(): void {
        this.themeSettingsFacade.themeFonts$.pipe(takeUntil(this.unsubscribe$), take(1)).subscribe((themeFonts: ThemeSelectedFonts) => {
            const fonts: ThemeFont[] = themeFonts.selected.map((font: ThemeFont) => convertFontValuesFromStrToInt(font));

            this.form.patchValue(fonts, { emitEvent: false });

            if (this.hasMobileStyles) {
                this.isSynchronizedControl.patchValue(themeFonts.isSynchronized, { emitEvent: false });
            }

            if (!this.initialFontsState) {
                // save initial
                this.initialFontsState = themeFonts;
            }
        });
    }

    public dispatchValues(themeFonts: ThemeSelectedFonts): void {
        this.themeSettingsFacade.changeFontsAction(
            {
                values: themeFonts.selected,
                index: themeFonts.selectedIndex,
            },
            themeFonts.isSynchronized,
        );
    }

    /**
     * @deprecated use getControlValue instead
     */
    public getStyleValue(tag: AbstractControl, property: string): string {
        return tag.get(this.currentModeState).get(property)?.value;
    }

    public getControlValue(control: AbstractControl, fontProperties: ESimpleThemeFontProperties): AbstractControl['value'] {
        return control.get(this.currentModeState).get(fontProperties)?.value;
    }

    public getFontValue(control: AbstractControl): AbstractControl['value'] {
        return control.get('font')?.value;
    }

    public setControlValue(
        control: AbstractControl,
        fontProperties: ESimpleThemeFontProperties,
        value: string | number,
        isSynchronizedControl: boolean = false,
    ): void {
        if (isSynchronizedControl) {
            control.get('styles').get(fontProperties).patchValue(value, { emitEvent: false });
            control.get('mobileStyles').get(fontProperties).patchValue(value);
            return;
        }

        control.get(this.currentModeState).get(fontProperties).patchValue(value);
    }

    public changeStyleValue(tag: AbstractControl, property: string, value: string): void {
        this.form.markAsDirty();
        tag.get(this.currentModeState).patchValue({ [property]: value });
    }

    public changeFont(tag: AbstractControl, event: Event): void {
        const selected = (event.target as HTMLSelectElement).value;

        this.form.markAsDirty();
        tag.patchValue({
            font: selected,
            styles: {
                fontFamily: FONT_FAMILY_RULES[selected],
            },
        });
    }

    public changeFontWeight(tag: AbstractControl, event: Event): void {
        const { value } = event.target as HTMLSelectElement;

        this.changeStyleValue(tag, ESimpleThemeFontProperties.fontWeight, value);
    }

    public toggleTextTransform(tag: AbstractControl): void {
        let { value } = tag.get(this.currentModeState).get(ESimpleThemeFontProperties.textTransform);
        value = value === 'uppercase' ? 'none' : 'uppercase';

        this.changeStyleValue(tag, ESimpleThemeFontProperties.textTransform, value);
    }

    public toggleFontStyle(tag: AbstractControl): void {
        let { value } = tag.get(this.currentModeState).get(ESimpleThemeFontProperties.fontStyle);
        value = value === 'italic' ? 'normal' : 'italic';

        this.changeStyleValue(tag, ESimpleThemeFontProperties.fontStyle, value);
    }

    public setFontSize(tag: AbstractControl, value: number): void {
        return tag.setValue(getValidFontSize(value));
    }

    public setLineHeight(tag: AbstractControl, value: number): void {
        return tag.setValue(getValidLineHeight(value));
    }

    public setLetterSpacing(tag: AbstractControl, value: number): void {
        return tag.setValue(getValidLetterSpacing(value));
    }

    public onchangeDeviceMode(mode: DeviceMode): void {
        if (this.deviceMode === mode) {
            return;
        }

        this.deviceMode = mode;
    }

    public onSave(): void {
        this.isSaved = true;
        this.themeFacade.unselectActiveThemeSettingAction();
    }

    public onCancel(): void {
        if (this.form.dirty) {
            this.dispatchValues(this.initialFontsState);
        }

        this.themeFacade.unselectActiveThemeSettingAction();
    }

    public get FONT_SIZE_MIN(): number {
        return CSS_RESTRICTIONS.FONT_SIZE_MIN;
    }

    public get FONT_SIZE_MAX(): number {
        return CSS_RESTRICTIONS.FONT_SIZE_MAX;
    }

    public get LINE_HEIGHT_MIN(): number {
        return CSS_RESTRICTIONS.LINE_HEIGHT_MIN;
    }

    public get LINE_HEIGHT_MAX(): number {
        return CSS_RESTRICTIONS.LINE_HEIGHT_MAX;
    }

    public get LETTER_SPACING_MIN(): number {
        return CSS_RESTRICTIONS.LETTER_SPACING_MIN;
    }

    public get LETTER_SPACING_MAX(): number {
        return CSS_RESTRICTIONS.LETTER_SPACING_MAX;
    }

    public get isDesktopMode(): boolean {
        return this.deviceMode === DeviceMode.desktop;
    }

    public get isMobileMode(): boolean {
        return this.deviceMode === DeviceMode.mobile;
    }

    public get currentModeState(): keyof Pick<ThemeFont, 'styles' | 'mobileStyles'> {
        return this.isDesktopMode ? 'styles' : 'mobileStyles';
    }

    public ngOnDestroy(): void {
        if (!this.isSaved && this.form.dirty) {
            this.dispatchValues(this.initialFontsState);
        }

        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
