const TOP_SHAPE_DIVIDER_1 = '<path d="M0 0H1920V98C1920 98 1560 14 960 14C360 14 0 98 0 98V0Z"/>';

const TOP_SHAPE_DIVIDER_2 = '<path d="M0 0H1920V98C1680 98 1680 18.2 1440 18.2C1200 18.2 1200 98 960 98C720 98 720 18.2 480 18.2C240 18.2 240 98 0 98V0Z"/>';

const TOP_SHAPE_DIVIDER_3 = '<path d="M0 0H1920V9.8C431.5 9.8 0 98 0 98V0Z"/>';

const TOP_SHAPE_DIVIDER_4 = '<path d="M0 0H1920V53.5938C1920 53.5938 1680 98 1440 98C1200 98 960 53.5938 960 53.5938C960 53.5938 720 9.1875 480 9.1875C240 9.1875 0 53.5938 0 53.5938V0Z"/>';

const TOP_SHAPE_DIVIDER_5 = '<path d="M0 0H1920V56.35C1680 56.35 1680 21.6825 1440 21.6825C1200 21.6825 1200 98 960 98C720 98 720 5.5125 480 5.5125C240 5.5125 240 56.35 0 56.35V0Z"/>';

const TOP_SHAPE_DIVIDER_6 = '<path d="M0 0H1920V98L960 14L0 98V0Z"/>';

const TOP_SHAPE_DIVIDER_7 = '<path d="M0 0H1920V98L1440 8.4L960 98L480 8.4L0 98V0Z"/>';

const TOP_SHAPE_DIVIDER_8 = '<path d="M0 0H1920V7L0 98V0Z"/>';

const TOP_SHAPE_DIVIDER_9 = '<path d="M0 0H1920V98L540 7.7L0 98V0Z"/>';

const TOP_SHAPE_DIVIDER_10 = '<path d="M0 0H1920V42.875L1440 29.5225L960 98L480 5.5125L0 42.875V0Z"/>';

const TOP_SHAPE_DIVIDER_11 = '<path d="M0 81.1345V0H1920V81.1345C1920 81.1345 1901 87.0351 1867 85.0682C1834.23 83.1728 1798.67 69.8248 1785 61.4655C1775.5 65.891 1762.5 69.5457 1740.5 71.3C1720 72.9346 1702.33 67.694 1693 65.891C1679.33 74.0864 1637.4 91.7557 1579 96.8696C1520.6 101.984 1470.33 88.5103 1452.5 81.1345C1443.67 81.1345 1422.4 80.6427 1408 78.6758C1393.6 76.7089 1376.33 67.694 1369.5 63.4324C1351.5 69.8248 1333 72.2461 1290.5 72.7751C1258.9 73.1685 1230.83 59.4986 1219.5 52.6145C1213.17 54.4174 1197.6 58.1218 1186 58.5152C1174.4 58.9085 1161.5 56.7122 1156.5 55.5648C1129.5 70.8083 1093.5 82.1179 1033.5 82.1179C983.022 82.1179 945 73.7586 909 58.5152C897.833 60.8099 865.9 65.4976 827.5 65.891C789.1 66.2844 755.167 57.2039 743 52.6145C726.5 67.3662 705.5 80.1054 655.5 81.6262C607.891 83.0742 573.167 72.2834 558.5 63.9241C548.333 67.8579 517.7 75.0371 476.5 72.2834C435.3 69.5298 410.333 52.1227 403 43.7634C390.5 50.1558 368 56.0565 345 57.04C322.096 58.0193 295.333 52.1227 283.5 48.1889C255 66.8745 241.2 72.7751 186 80.6427C130.8 88.5103 73.6667 72.4473 52 63.4324C47.6667 65.7271 36.4 71.2016 26 74.742C15.6 78.2825 0 81.1345 0 81.1345Z"/>\
    <path opacity="0.5" d="M0 89.0198V0H1920V89.0198L1914 87.5525C1910.8 86.7699 1904.67 84.6177 1902 83.6395L1844.5 67.9877C1834 75.8136 1802.1 91.6611 1758.5 92.4437C1714.9 93.2263 1686.33 79.4005 1677.5 72.3897L1451.5 73.8571C1444 78.9113 1419 89.0198 1379 89.0198C1339 89.0198 1317.67 77.2809 1312 71.4115L1281.5 70.4333C1270 78.7483 1235 95.8675 1187 97.824C1139 99.7805 1102.33 84.9438 1090 77.2809L932 43.0426C927.5 57.227 901.2 87.5525 832 95.3784C762.8 103.204 711.833 85.9221 695 76.3027L660.5 74.8354C657 79.0744 637.9 87.9438 589.5 89.5089C541.1 91.0741 507 78.7483 496 72.3897L416 51.8467C406.167 59.8357 375.5 76.8896 331.5 81.1939C287.5 85.4982 250.167 75.8136 237 70.4333L96 67.9877C95.5 72.2267 87.3 82.3678 58.5 89.0198C29.7 95.6719 7.5 91.7915 0 89.0198Z"/>';

const TOP_SHAPE_DIVIDER_12 = '<path d="M0 0H1920V89.8333L1440 6.53333L960 98L480 6.53333L0 89.8333V0Z"/>\
    <path opacity="0.5" d="M0 0H1920V98L1440 36.4L960 98L480 36.4L0 98V0Z"/>';

const TOP_SHAPE_DIVIDER_13 = '<path d="M1920 0H0V7L1920 98V0Z"/>\
    <path opacity="0.5" d="M0 0H1920V7L0 98V0Z"/>';

const TOP_SHAPE_DIVIDER_14 = '<path d="M0 0H1920V42.5837C1680 42.5837 1680 22.0096 1440 22.0096C1200 22.0096 1200 73.6842 960 73.6842C720 73.6842 720 2.87081 480 2.87081C240 2.87081 240 42.5837 0 42.5837V0Z"/>\
    <path opacity="0.5" d="M0 0H1920V52.2237C1680 52.2237 1680 23.8553 1440 23.8553C1200 23.8553 1200 98 960 98C720 98 720 5.80263 480 5.80263C240 5.80263 240 52.2237 0 52.2237V0Z"/>';

const TOP_SHAPE_DIVIDER_15 = '<path d="M0 0H1920V100C1920 100 1820 7.14286 1460 7.14286H460C100 7.14286 0 100 0 100V0Z"/>';

const F_V_TOP_SHAPE_DIVIDER_1 = '<path d="M0 0H1920V14.0042C1920 14.0042 1560 98 960 98C360 98 0 14 0 14V0Z"/>';

const F_V_TOP_SHAPE_DIVIDER_2 = '<path d="M0 0H1920V18.1986C1680 18.1986 1680 98 1440 98C1200 98 1200 18.1986 960 18.1986C720 18.1986 720 98 480 98C240 98 240 18.1986 0 18.1986V0Z"/>';

const F_V_TOP_SHAPE_DIVIDER_3 = '<path d="M0 0H1920V98C431.5 98 0 9.8 0 9.8V0Z"/>';

const F_V_TOP_SHAPE_DIVIDER_4 = '<path d="M1920 0H0V53.5938C0 53.5938 240 98 480 98C720 98 960 53.5938 960 53.5938C960 53.5938 1200 9.1875 1440 9.1875C1680 9.1875 1920 53.5938 1920 53.5938V0Z"/>';

const F_V_TOP_SHAPE_DIVIDER_5 = '<path d="M0 2.53677e-06H1920V46.8428C1680 46.8428 1680 81.7283 1440 81.7283C1200 81.7283 1200 4.93082 960 4.93082C720 4.93082 720 98 480 98C240 98 240 46.8428 0 46.8428V2.53677e-06Z"/>';

const F_V_TOP_SHAPE_DIVIDER_6 = '<path d="M0 14V-1.90735e-06H1920V14L960 98L0 14Z"/>';

const F_V_TOP_SHAPE_DIVIDER_7 = '<path d="M0 -1.95158e-06L1920 -3.17713e-05V8.6623L1440 98L960 8.6623L480 98L0 8.6623V-1.95158e-06Z"/>';

const F_V_TOP_SHAPE_DIVIDER_8 = '<path d="M1920 0H0V7L1920 98V0Z"/>';

const F_V_TOP_SHAPE_DIVIDER_9 = '<path d="M0 -2.69739e-06H1920V7.82881L540 98L0 7.82881V-2.69739e-06Z"/>';

const F_V_TOP_SHAPE_DIVIDER_10 = '<path d="M0 -1.44541e-06H1920V60.7771L1440 74.0797L960 5.85803L480 98L0 60.7771V-1.44541e-06Z"/>';

const F_V_TOP_SHAPE_DIVIDER_11 = '<path d="M0 0.5H1920V48.5C1918.33 48.6667 1913.7 49.9 1908.5 51.5C1903.3 53.1 1897 56.5425 1894.5 58.0637C1887.36 57.9548 1878.93 58.0362 1869.5 58.5C1857.69 59.081 1844.54 61.5612 1832 64.8327C1826.83 58.7218 1805 46.2 1759 45C1713 43.8 1683.17 61.184 1674 70.026C1650.48 60.742 1612.34 49.1202 1569.5 45.5C1513.82 40.7944 1468.64 55.5658 1451.97 64C1438.5 55 1415.02 48.5 1381 48.5C1347 48.5 1320 58.5 1306 70.9404C1301.36 70.6594 1296.82 70.5 1292.5 70.5C1289.72 70.5 1286.87 70.6431 1284 70.9075C1271 56.5 1241.1 43.7 1191.5 40.5C1141.9 37.3 1104 52.5 1081.5 66.4727C1065.71 63.7653 1048.2 61.7285 1029.5 61C980.164 59.0778 942.075 70.0704 921.5 79.2329C897 57 865.1 43.9 815.5 41.5C765.9 39.1 720 46 685 66.8679C679.621 65.4903 673.946 64.236 668 63.1609C644 53.5 626.514 48.5 584 48.5C536.5 48.5 500 60.5 488 70.1642C484.715 70.0574 481.377 70 478 70C453.8 70 432.679 78.9877 418.252 88C408.668 80.8333 385 64.4773 337.5 58C295.734 52.3047 262.5 59.5 227.5 71.8292C212.919 67.7486 195.97 64.1836 177 62C146.763 58.5195 117.817 61.585 94.6985 66.5C85.5 57.5 76.5 51.7188 54 47.5C33 43.5625 14 46 0 48.5V0.5Z"/>\
    <path opacity="0.5" d="M0 59V0H1920V59C1920 59 1900 56.5 1869.5 58C1839.53 59.4738 1801 73.1667 1785.5 81C1779.83 78.6667 1762.4 73.8 1738 73C1713.6 72.2 1697.17 75.6667 1692 77.5C1674.83 68.6667 1626.3 49.8 1569.5 45C1512.7 40.2 1466.83 55.6667 1451 64C1443 63.3333 1423.5 62.4 1409.5 64C1395.5 65.6 1377.67 75 1370.5 79.5C1355.83 76.3333 1319.7 70 1292.5 70C1265.3 70 1232.5 83.6667 1219.5 90.5C1214.67 88.6667 1201.6 85 1188 85C1174.4 85 1161.33 86.6667 1156.5 87.5C1139.83 79.5 1091.1 62.9 1029.5 60.5C967.9 58.1 923.833 75.8333 909.5 85C898.833 83.1667 868.1 79.2 830.5 78C792.9 76.8 756.5 86.1667 743 91C733.333 83.8333 703.4 67.9 661 61.5C618.6 55.1 574.333 70.8333 557.5 79.5C546.167 76.1667 514.4 69.5 478 69.5C441.6 69.5 412.167 89.8333 402 100C394.667 96.5 373.7 88.6 348.5 85C323.3 81.4 294.333 89.8333 283 94.5C270.833 86.1667 232.6 67.9 177 61.5C121.4 55.1 70.1667 70.8333 51.5 79.5C47.6667 77.5 38.2 70.8 27 66C15.8 61.2 0 59 0 59Z"/>';

const F_V_TOP_SHAPE_DIVIDER_12 = '<path d="M481 100L960 10L1440 100L1920 9.99997V0H0V10L481 100Z"/>\
    <path opacity="0.5" d="M1920 29.1096V-7.62939e-06H0V29.1096L480 100L960 29.1096L1440 100L1920 29.1096Z"/>';

const F_V_TOP_SHAPE_DIVIDER_13 = '<path opacity="0.5" d="M0 40L960 100L382.5 0H0V40Z"/>\
    <path d="M382.5 0H1920V40L959.999 100L382.5 0Z"/>';

const F_V_TOP_SHAPE_DIVIDER_14 = '<path d="M0 -5.77351e-06H1920V51.2063C1680 51.2063 1680 81.0247 1440 81.0247C1200 81.0247 1200 3.09026 960 3.09026C720 3.09026 720 100 480 100C240 100 240 51.2063 0 51.2063V-5.77351e-06Z"/>\
    <path opacity="0.5" d="M0 0H1920V60.3383C1680 60.3383 1680 80.8859 1440 80.8859C1200 80.8859 1200 29.278 960 29.278C720 29.278 720 100 480 100C240 100 240 60.3383 0 60.3383V0Z"/>';

const F_V_TOP_SHAPE_DIVIDER_15 = '<path d="M0 -1.98805e-06H1920V6.86981C1920 6.86981 1820 98 1460 98H460C100 98 0 6.86981 0 6.86981V-1.98805e-06Z"/>';

const F_H_TOP_SHAPE_DIVIDER_1 = '<path d="M0 0H1920V98C1920 98 1560 14 960 14C360 14 0 98 0 98V0Z"/>';

const F_H_TOP_SHAPE_DIVIDER_2 = '<path d="M0 0H1920V98C1680 98 1680 18.2 1440 18.2C1200 18.2 1200 98 960 98C720 98 720 18.2 480 18.2C240 18.2 240 98 0 98V0Z"/>';

const F_H_TOP_SHAPE_DIVIDER_3 = '<path d="M1920 0H0V9.8C1488.5 9.8 1920 98 1920 98V0Z"/>';

const F_H_TOP_SHAPE_DIVIDER_4 = '<path d="M1920 0H0V53.5938C0 53.5938 240 98 480 98C720 98 960 53.5938 960 53.5938C960 53.5938 1200 9.1875 1440 9.1875C1680 9.1875 1920 53.5938 1920 53.5938V0Z"/>';

const F_H_TOP_SHAPE_DIVIDER_5 = '<path d="M1920 0H0V56.35C240 56.35 240 21.6825 480 21.6825C720 21.6825 720 98 960 98C1200 98 1200 5.5125 1440 5.5125C1680 5.5125 1680 56.35 1920 56.35V0Z"/>';

const F_H_TOP_SHAPE_DIVIDER_6 = '<path d="M0 0H1920V98L960 14L0 98V0Z"/>';

const F_H_TOP_SHAPE_DIVIDER_7 = '<path d="M0 0H1920V98L1440 8.4L960 98L480 8.4L0 98V0Z"/>';

const F_H_TOP_SHAPE_DIVIDER_8 = '<path d="M1920 0H0V7L1920 98V0Z"/>';

const F_H_TOP_SHAPE_DIVIDER_9 = '<path d="M1920 0H0V98L1380 7.7L1920 98V0Z"/>';

const F_H_TOP_SHAPE_DIVIDER_10 = '<path d="M1920 0H0V42.875L480 29.5225L960 98L1440 5.5125L1920 42.875V0Z"/>';

const F_H_TOP_SHAPE_DIVIDER_11 = '<path d="M1920 81.1345V0H0V81.1345C0 81.1345 19 87.0351 53 85.0682C85.7653 83.1728 121.333 69.8248 135 61.4655C144.5 65.891 157.5 69.5457 179.5 71.3C200 72.9346 217.667 67.694 227 65.891C240.667 74.0864 282.6 91.7557 341 96.8696C399.4 101.984 449.667 88.5103 467.5 81.1345C476.333 81.1345 497.6 80.6427 512 78.6758C526.4 76.7089 543.667 67.694 550.5 63.4324C568.5 69.8248 587 72.2461 629.5 72.7751C661.1 73.1685 689.167 59.4986 700.5 52.6145C706.833 54.4174 722.4 58.1218 734 58.5152C745.6 58.9085 758.5 56.7122 763.5 55.5648C790.5 70.8083 826.5 82.1179 886.5 82.1179C936.978 82.1179 975 73.7586 1011 58.5152C1022.17 60.8099 1054.1 65.4976 1092.5 65.891C1130.9 66.2844 1164.83 57.2039 1177 52.6145C1193.5 67.3662 1214.5 80.1054 1264.5 81.6262C1312.11 83.0742 1346.83 72.2834 1361.5 63.9241C1371.67 67.8579 1402.3 75.0371 1443.5 72.2834C1484.7 69.5298 1509.67 52.1227 1517 43.7634C1529.5 50.1558 1552 56.0565 1575 57.04C1597.9 58.0193 1624.67 52.1227 1636.5 48.1889C1665 66.8745 1678.8 72.7751 1734 80.6427C1789.2 88.5103 1846.33 72.4473 1868 63.4324C1872.33 65.7271 1883.6 71.2016 1894 74.742C1904.4 78.2825 1920 81.1345 1920 81.1345Z"/>\
    <path opacity="0.5" d="M1920 89.0198V0H0V89.0198L6 87.5525C9.19995 86.7699 15.3334 84.6177 18 83.6395L75.5 67.9877C86 75.8136 117.9 91.6611 161.5 92.4437C205.1 93.2263 233.667 79.4005 242.5 72.3897L468.5 73.8571C476 78.9113 501 89.0198 541 89.0198C581 89.0198 602.333 77.2809 608 71.4115L638.5 70.4333C650 78.7483 685 95.8675 733 97.824C781 99.7805 817.667 84.9438 830 77.2809L988 43.0426C992.5 57.227 1018.8 87.5525 1088 95.3784C1157.2 103.204 1208.17 85.9221 1225 76.3027L1259.5 74.8354C1263 79.0744 1282.1 87.9438 1330.5 89.5089C1378.9 91.0741 1413 78.7483 1424 72.3897L1504 51.8467C1513.83 59.8357 1544.5 76.8896 1588.5 81.1939C1632.5 85.4982 1669.83 75.8136 1683 70.4333L1824 67.9877C1824.5 72.2267 1832.7 82.3678 1861.5 89.0198C1890.3 95.6719 1912.5 91.7915 1920 89.0198Z"/>';

const F_H_TOP_SHAPE_DIVIDER_12 = '<path d="M0 0H1920V89.8333L1440 6.53333L960 98L480 6.53333L0 89.8333V0Z"/>\
    <path opacity="0.5" d="M0 0H1920V98L1440 36.4L960 98L480 36.4L0 98V0Z"/>';

const F_H_TOP_SHAPE_DIVIDER_13 = '<path d="M0 0H1920V7L960 52.5L0 98V0Z"/>\
    <path opacity="0.5" d="M1920 0H0V7L1920 98V0Z"/>';

const F_H_TOP_SHAPE_DIVIDER_14 = '<path d="M1920 0H0V42.5837C240 42.5837 240 22.0096 480 22.0096C720 22.0096 720 73.6842 960 73.6842C1200 73.6842 1200 2.87081 1440 2.87081C1680 2.87081 1680 42.5837 1920 42.5837V0Z"/>\
    <path opacity="0.5" d="M1920 0H0V52.2237C240 52.2237 240 23.8553 480 23.8553C720 23.8553 720 98 960 98C1200 98 1200 5.80263 1440 5.80263C1680 5.80263 1680 52.2237 1920 52.2237V0Z"/>';

const F_H_TOP_SHAPE_DIVIDER_15 = '<path d="M0 0H1920V98C1920 98 1820 7 1460 7H460C100 7 0 98 0 98V0Z"/>';

const F_H_V_TOP_SHAPE_DIVIDER_1 = '<path d="M0 0H1920V14.0042C1920 14.0042 1560 98 960 98C360 98 0 14 0 14V0Z"/>';

const F_H_V_TOP_SHAPE_DIVIDER_2 = '<path d="M0 0H1920V18.1986C1680 18.1986 1680 98 1440 98C1200 98 1200 18.1986 960 18.1986C720 18.1986 720 98 480 98C240 98 240 18.1986 0 18.1986V0Z"/>';

const F_H_V_TOP_SHAPE_DIVIDER_3 = '<path d="M1920 0H0V98C1488.5 98 1920 9.8 1920 9.8V0Z"/>';

const F_H_V_TOP_SHAPE_DIVIDER_4 = '<path d="M0 0H1920V53.5938C1920 53.5938 1680 98 1440 98C1200 98 960 53.5938 960 53.5938C960 53.5938 720 9.1875 480 9.1875C240 9.1875 0 53.5938 0 53.5938V0Z"/>';

const F_H_V_TOP_SHAPE_DIVIDER_5 = '<path d="M1920 2.48849e-06H0V46.8428C240 46.8428 240 81.7283 480 81.7283C720 81.7283 720 4.93082 960 4.93082C1200 4.93082 1200 98 1440 98C1680 98 1680 46.8428 1920 46.8428V2.48849e-06Z"/>';

const F_H_V_TOP_SHAPE_DIVIDER_6 = '<path d="M0 14V-1.90735e-06H1920V14L960 98L0 14Z"/>';

const F_H_V_TOP_SHAPE_DIVIDER_7 = '<path d="M0 -1.95158e-06L1920 -3.17713e-05V8.6623L1440 98L960 8.6623L480 98L0 8.6623V-1.95158e-06Z"/>';

const F_H_V_TOP_SHAPE_DIVIDER_8 = '<path d="M0 0H1920V7L0 98V0Z"/>';

const F_H_V_TOP_SHAPE_DIVIDER_9 = '<path d="M1920 -2.69739e-06H0V7.82881L1380 98L1920 7.82881V-2.69739e-06Z"/>';

const F_H_V_TOP_SHAPE_DIVIDER_10 = '<path d="M1920 -1.44541e-06H0V60.7771L480 74.0797L960 5.85803L1440 98L1920 60.7771V-1.44541e-06Z"/>';

const F_H_V_TOP_SHAPE_DIVIDER_11 = '<path d="M1920 0.5H0V48.5C1.66663 48.6667 6.30005 49.9 11.5 51.5C16.7 53.1 23 56.5425 25.5 58.0637C32.6422 57.9548 41.0691 58.0362 50.5 58.5C62.3138 59.081 75.4594 61.5612 88 64.8327C93.1666 58.7218 115 46.2 161 45C207 43.8 236.833 61.184 246 70.026C269.524 60.742 307.661 49.1202 350.5 45.5C406.182 40.7944 451.357 55.5658 468.031 64C481.5 55 504.979 48.5 539 48.5C573 48.5 600 58.5 614 70.9404C618.644 70.6594 623.184 70.5 627.5 70.5C630.284 70.5 633.126 70.6431 636 70.9075C649 56.5 678.9 43.7 728.5 40.5C778.1 37.3 816 52.5 838.5 66.4727C854.29 63.7653 871.802 61.7285 890.5 61C939.836 59.0778 977.925 70.0704 998.5 79.2329C1023 57 1054.9 43.9 1104.5 41.5C1154.1 39.1 1200 46 1235 66.8679C1240.38 65.4903 1246.05 64.236 1252 63.1609C1276 53.5 1293.49 48.5 1336 48.5C1383.5 48.5 1420 60.5 1432 70.1642C1435.29 70.0574 1438.62 70 1442 70C1466.2 70 1487.32 78.9877 1501.75 88C1511.33 80.8333 1535 64.4773 1582.5 58C1624.27 52.3047 1657.5 59.5 1692.5 71.8292C1707.08 67.7486 1724.03 64.1836 1743 62C1773.24 58.5195 1802.18 61.585 1825.3 66.5C1834.5 57.5 1843.5 51.7188 1866 47.5C1887 43.5625 1906 46 1920 48.5V0.5Z"/>\
    <path opacity="0.5" d="M1920 59V0H0V59C0 59 20 56.5 50.5 58C80.4664 59.4738 119 73.1667 134.5 81C140.167 78.6667 157.6 73.8 182 73C206.4 72.2 222.833 75.6667 228 77.5C245.167 68.6667 293.7 49.8 350.5 45C407.3 40.2 453.167 55.6667 469 64C477 63.3333 496.5 62.4 510.5 64C524.5 65.6 542.333 75 549.5 79.5C564.167 76.3333 600.3 70 627.5 70C654.7 70 687.5 83.6667 700.5 90.5C705.333 88.6667 718.4 85 732 85C745.6 85 758.667 86.6667 763.5 87.5C780.167 79.5 828.9 62.9 890.5 60.5C952.1 58.1 996.167 75.8333 1010.5 85C1021.17 83.1667 1051.9 79.2 1089.5 78C1127.1 76.8 1163.5 86.1667 1177 91C1186.67 83.8333 1216.6 67.9 1259 61.5C1301.4 55.1 1345.67 70.8333 1362.5 79.5C1373.83 76.1667 1405.6 69.5 1442 69.5C1478.4 69.5 1507.83 89.8333 1518 100C1525.33 96.5 1546.3 88.6 1571.5 85C1596.7 81.4 1625.67 89.8333 1637 94.5C1649.17 86.1667 1687.4 67.9 1743 61.5C1798.6 55.1 1849.83 70.8333 1868.5 79.5C1872.33 77.5 1881.8 70.8 1893 66C1904.2 61.2 1920 59 1920 59Z"/>';

const F_H_V_TOP_SHAPE_DIVIDER_12 = '<path d="M481 100L960 10L1440 100L1920 9.99997V0H0V10L481 100Z"/>\
    <path opacity="0.5" d="M1920 28.3333V-2.28882e-05H0V28.3333L480 97.3333L960 28.3333L1440 97.3333L1920 28.3333Z"/>';

const F_H_V_TOP_SHAPE_DIVIDER_13 = '<path opacity="0.5" d="M1920 40L960 100L1537.5 0H1920V40Z"/>\
    <path d="M1537.5 0H0.000488281V40L960.001 100L1537.5 0Z"/>';

const F_H_V_TOP_SHAPE_DIVIDER_14 = '<path d="M1920 3.05176e-05H0V49.7106C240 49.7106 240 78.6579 480 78.6579C720 78.6579 720 3.00003 960 3.00003C1200 3.00003 1200 97.079 1440 97.079C1680 97.079 1680 49.7106 1920 49.7106V3.05176e-05Z"/>\
    <path opacity="0.5" d="M1920 2.28882e-05H0V60.4163C240 60.4163 240 80.9905 480 80.9905C720 80.9905 720 29.3158 960 29.3158C1200 29.3158 1200 100.129 1440 100.129C1680 100.129 1680 60.4163 1920 60.4163V2.28882e-05Z"/>';

const F_H_V_TOP_SHAPE_DIVIDER_15 = '<path d="M0 -1.98805e-06H1920V6.86981C1920 6.86981 1820 98 1460 98H460C100 98 0 6.86981 0 6.86981V-1.98805e-06Z"/>';

const BOTTOM_SHAPE_DIVIDER_1 = '<path d="M0 100H1920V2C1920 2 1560 86 960 86C360 86 0 2 0 2V100Z"/>';

const BOTTOM_SHAPE_DIVIDER_2 = '<path d="M0 100H1920V2C1680 2 1680 81.8 1440 81.8C1200 81.8 1200 2 960 2C720 2 720 81.8 480 81.8C240 81.8 240 2 0 2V100Z"/>';

const BOTTOM_SHAPE_DIVIDER_3 = '<path d="M0 100H1920V90.2C431.5 90.2 0 2 0 2V100Z"/>';

const BOTTOM_SHAPE_DIVIDER_4 = '<path d="M0 100H1920V46.4062C1920 46.4062 1680 2 1440 2C1200 2 960 46.4062 960 46.4062C960 46.4062 720 90.8125 480 90.8125C240 90.8125 0 46.4062 0 46.4062V100Z"/>';

const BOTTOM_SHAPE_DIVIDER_5 = '<path d="M0 100H1920V43.65C1680 43.65 1680 78.3175 1440 78.3175C1200 78.3175 1200 2 960 2C720 2 720 94.4875 480 94.4875C240 94.4875 240 43.65 0 43.65V100Z"/>';

const BOTTOM_SHAPE_DIVIDER_6 = '<path d="M0 100H1920V2L960 86L0 2V100Z"/>';

const BOTTOM_SHAPE_DIVIDER_7 = '<path d="M0 100H1920V2L1440 91.6L960 2L480 91.6L0 2V100Z"/>';

const BOTTOM_SHAPE_DIVIDER_8 = '<path d="M0 100H1920V93L0 2V100Z"/>';

const BOTTOM_SHAPE_DIVIDER_9 = '<path d="M0 100H1920V2L540 92.3L0 2V100Z"/>';

const BOTTOM_SHAPE_DIVIDER_10 = '<path d="M0 100H1920V57.125L1440 70.4775L960 2L480 94.4875L0 57.125V100Z"/>';

const BOTTOM_SHAPE_DIVIDER_11 = '<path d="M0 18.8655V100H1920V18.8655C1920 18.8655 1901 12.9649 1867 14.9318C1834.23 16.8272 1798.67 30.1752 1785 38.5345C1775.5 34.109 1762.5 30.4543 1740.5 28.7C1720 27.0654 1702.33 32.306 1693 34.109C1679.33 25.9136 1637.4 8.24431 1579 3.13038C1520.6 -1.98355 1470.33 11.4897 1452.5 18.8655C1443.67 18.8655 1422.4 19.3573 1408 21.3242C1393.6 23.2911 1376.33 32.306 1369.5 36.5676C1351.5 30.1752 1333 27.7539 1290.5 27.2249C1258.9 26.8315 1230.83 40.5014 1219.5 47.3855C1213.17 45.5826 1197.6 41.8782 1186 41.4848C1174.4 41.0915 1161.5 43.2878 1156.5 44.4352C1129.5 29.1917 1093.5 17.8821 1033.5 17.8821C983.022 17.8821 945 26.2414 909 41.4848C897.833 39.1901 865.9 34.5024 827.5 34.109C789.1 33.7156 755.167 42.7961 743 47.3855C726.5 32.6338 705.5 19.8946 655.5 18.3738C607.891 16.9258 573.167 27.7166 558.5 36.0759C548.333 32.1421 517.7 24.9629 476.5 27.7166C435.3 30.4702 410.333 47.8773 403 56.2366C390.5 49.8442 368 43.9435 345 42.96C322.096 41.9807 295.333 47.8773 283.5 51.8111C255 33.1255 241.2 27.2249 186 19.3573C130.8 11.4897 73.6667 27.5527 52 36.5676C47.6667 34.2729 36.4 28.7984 26 25.258C15.6 21.7175 0 18.8655 0 18.8655Z"/>\
    <path opacity="0.5" d="M0 10.9802V100H1920V10.9802L1914 12.4475C1910.8 13.2301 1904.67 15.3823 1902 16.3605L1844.5 32.0123C1834 24.1864 1802.1 8.33893 1758.5 7.55634C1714.9 6.77374 1686.33 20.5995 1677.5 27.6103L1451.5 26.1429C1444 21.0887 1419 10.9802 1379 10.9802C1339 10.9802 1317.67 22.7191 1312 28.5885L1281.5 29.5667C1270 21.2517 1235 4.1325 1187 2.17602C1139 0.219536 1102.33 15.0562 1090 22.7191L932 56.9574C927.5 42.773 901.2 12.4475 832 4.62161C762.8 -3.2043 711.833 14.0779 695 23.6973L660.5 25.1646C657 20.9256 637.9 12.0562 589.5 10.4911C541.1 8.92587 507 21.2517 496 27.6103L416 48.1533C406.167 40.1643 375.5 23.1104 331.5 18.8061C287.5 14.5018 250.167 24.1864 237 29.5667L96 32.0123C95.5 27.7733 87.3 17.6322 58.5 10.9802C29.7 4.32814 7.5 8.2085 0 10.9802Z"/>';

const BOTTOM_SHAPE_DIVIDER_12 = '<path d="M0 100H1920V10.1667L1440 93.4667L960 2L480 93.4667L0 10.1667V100Z"/>\
    <path opacity="0.5" d="M0 100H1920V2L1440 63.6L960 2L480 63.6L0 2V100Z"/>';

const BOTTOM_SHAPE_DIVIDER_13 = '<path d="M0 100H1920V93L0 2V100Z"/>\
    <path opacity="0.5" d="M1920 100H0V93L1920 2V100Z"/>';

const BOTTOM_SHAPE_DIVIDER_14 = '<path d="M0 100H1920V57.4163C1680 57.4163 1680 77.9904 1440 77.9904C1200 77.9904 1200 26.3158 960 26.3158C720 26.3158 720 97.1292 480 97.1292C240 97.1292 240 57.4163 0 57.4163V100Z"/>\
    <path opacity="0.5" d="M0 100H1920V47.7763C1680 47.7763 1680 76.1447 1440 76.1447C1200 76.1447 1200 2 960 2C720 2 720 94.1974 480 94.1974C240 94.1974 240 47.7763 0 47.7763V100Z"/>';

const BOTTOM_SHAPE_DIVIDER_15 = '<path d="M0 100H1920V2C1920 2 1820 93 1460 93H460C100 93 0 2 0 2V100Z"/>';

const F_V_BOTTOM_SHAPE_DIVIDER_1 = '<path d="M0 100H1920V85.9958C1920 85.9958 1560 2 960 2C360 2 0 86 0 86V100Z"/>';

const F_V_BOTTOM_SHAPE_DIVIDER_2 = '<path d="M0 100H1920V81.8014C1680 81.8014 1680 2 1440 2C1200 2 1200 81.8014 960 81.8014C720 81.8014 720 2 480 2C240 2 240 81.8014 0 81.8014V100Z"/>';

const F_V_BOTTOM_SHAPE_DIVIDER_3 = '<path d="M0 100H1920V2C431.5 2 0 90.2 0 90.2V100Z"/>';

const F_V_BOTTOM_SHAPE_DIVIDER_4 = '<path d="M1920 100H0V46.4062C0 46.4062 240 2 480 2C720 2 960 46.4062 960 46.4062C960 46.4062 1200 90.8125 1440 90.8125C1680 90.8125 1920 46.4062 1920 46.4062V100Z"/>';

const F_V_BOTTOM_SHAPE_DIVIDER_5 = '<path d="M0 100H1920V53.1572C1680 53.1572 1680 18.2717 1440 18.2717C1200 18.2717 1200 95.0692 960 95.0692C720 95.0692 720 2 480 2C240 2 240 53.1572 0 53.1572V100Z"/>';

const F_V_BOTTOM_SHAPE_DIVIDER_6 = '<path d="M0 86V100H1920V86L960 2L0 86Z"/>';

const F_V_BOTTOM_SHAPE_DIVIDER_7 = '<path d="M0 100H1920V91.6L1440 2L960 91.6L480 2L0 91.6V100Z"/>';

const F_V_BOTTOM_SHAPE_DIVIDER_8 = '<path d="M1920 100H0V93L1920 2V100Z"/>';

const F_V_BOTTOM_SHAPE_DIVIDER_9 = '<path d="M0 100H1920V92.3L540 2L0 92.3V100Z"/>';

const F_V_BOTTOM_SHAPE_DIVIDER_10 = '<path d="M0 100H1920V39.3625L1440 26.01L960 94.4875L480 2L0 39.3625V100Z"/>';

const F_V_BOTTOM_SHAPE_DIVIDER_11 = '<path d="M0 100.5H1920V53.5971C1918.33 53.4343 1913.7 52.2291 1908.5 50.6657C1903.3 49.1023 1897 45.7385 1894.5 44.252C1887.36 44.3584 1878.93 44.2789 1869.5 43.8257C1857.69 43.258 1844.54 40.8345 1832 37.6378C1826.83 43.609 1805 55.8446 1759 57.0171C1713 58.1897 1683.17 41.2031 1674 32.5632C1650.48 41.635 1612.34 52.9911 1569.5 56.5286C1513.82 61.1266 1468.64 46.6928 1451.97 38.4514C1438.5 47.2457 1415.02 53.5971 1381 53.5971C1347 53.5971 1320 43.8257 1306 31.6697C1301.36 31.9442 1296.82 32.1 1292.5 32.1C1289.72 32.1 1286.87 31.9601 1284 31.7018C1271 45.78 1241.1 58.2874 1191.5 61.4143C1141.9 64.5411 1104 49.6886 1081.5 36.0352C1065.71 38.6808 1048.2 40.671 1029.5 41.3829C980.164 43.2611 942.075 32.5198 921.5 23.5667C897 45.2914 865.1 58.092 815.5 60.4371C765.9 62.7823 720 56.04 685 35.6491C679.621 36.9952 673.946 38.2208 668 39.2714C644 48.7114 626.514 53.5971 584 53.5971C536.5 53.5971 500 41.8714 488 32.4281C484.715 32.5325 481.377 32.5886 478 32.5886C453.8 32.5886 432.679 23.8063 418.252 15C408.668 22.0029 385 37.9851 337.5 44.3143C295.734 49.8794 262.5 42.8486 227.5 30.8012C212.919 34.7885 195.97 38.272 177 40.4057C146.763 43.8067 117.817 40.8112 94.6985 36.0086C85.5 44.8029 76.5 50.452 54 54.5743C33 58.4218 14 56.04 0 53.5971V100.5Z"/>\
    <path opacity="0.5" d="M0 42.18V100H1920V42.18C1920 42.18 1900 44.63 1869.5 43.16C1839.53 41.7157 1801 28.2967 1785.5 20.62C1779.83 22.9067 1762.4 27.676 1738 28.46C1713.6 29.244 1697.17 25.8467 1692 24.05C1674.83 32.7067 1626.3 51.196 1569.5 55.9C1512.7 60.604 1466.83 45.4467 1451 37.28C1443 37.9333 1423.5 38.848 1409.5 37.28C1395.5 35.712 1377.67 26.5 1370.5 22.09C1355.83 25.1933 1319.7 31.4 1292.5 31.4C1265.3 31.4 1232.5 18.0067 1219.5 11.31C1214.67 13.1067 1201.6 16.7 1188 16.7C1174.4 16.7 1161.33 15.0667 1156.5 14.25C1139.83 22.09 1091.1 38.358 1029.5 40.71C967.9 43.062 923.833 25.6833 909.5 16.7C898.833 18.4967 868.1 22.384 830.5 23.56C792.9 24.736 756.5 15.5567 743 10.82C733.333 17.8433 703.4 33.458 661 39.73C618.6 46.002 574.333 30.5833 557.5 22.09C546.167 25.3567 514.4 31.89 478 31.89C441.6 31.89 412.167 11.9633 402 2C394.667 5.43 373.7 13.172 348.5 16.7C323.3 20.228 294.333 11.9633 283 7.39C270.833 15.5567 232.6 33.458 177 39.73C121.4 46.002 70.1667 30.5833 51.5 22.09C47.6667 24.05 38.2 30.616 27 35.32C15.8 40.024 0 42.18 0 42.18Z"/>';

const F_V_BOTTOM_SHAPE_DIVIDER_12 = '<path d="M481 2L960 90.2L1440 2L1920 90.2V100H0V90.2L481 2Z"/>\
    <path opacity="0.5" d="M1920 71.4726V100H0V71.4726L480 2L960 71.4726L1440 2L1920 71.4726Z"/>';

const F_V_BOTTOM_SHAPE_DIVIDER_13 = '<path d="M382.5 100H1920V60.8L959.999 2L382.5 100Z"/>\
    <path opacity="0.5" d="M0 60.8L960 2L382.5 100H0V60.8Z"/>';

const F_V_BOTTOM_SHAPE_DIVIDER_14 = '<path d="M0 100H1920V51.354C1680 51.354 1680 23.0266 1440 23.0266C1200 23.0266 1200 97.0642 960 97.0642C720 97.0642 720 5 480 5C240 5 240 51.354 0 51.354V100Z"/>\
    <path opacity="0.5" d="M0 100H1920V40.8684C1680 40.8684 1680 20.7318 1440 20.7318C1200 20.7318 1200 71.3076 960 71.3076C720 71.3076 720 2 480 2C240 2 240 40.8684 0 40.8684V100Z"/>';

const F_V_BOTTOM_SHAPE_DIVIDER_15 = '<path d="M0 100H1920V93C1920 93 1820 2 1460 2H460C100 2 0 93 0 93V100Z"/>';

const F_H_BOTTOM_SHAPE_DIVIDER_1 = '<path d="M0 100H1920V2C1920 2 1560 86 960 86C360 86 0 2 0 2V100Z"/>';

const F_H_BOTTOM_SHAPE_DIVIDER_2 = '<path d="M0 100H1920V2C1680 2 1680 81.8 1440 81.8C1200 81.8 1200 2 960 2C720 2 720 81.8 480 81.8C240 81.8 240 2 0 2V100Z"/>';

const F_H_BOTTOM_SHAPE_DIVIDER_3 = '<path d="M1920 100H0V90.2C1488.5 90.2 1920 2 1920 2V100Z"/>';

const F_H_BOTTOM_SHAPE_DIVIDER_4 = '<path d="M1920 100H0V46.4062C0 46.4062 240 2 480 2C720 2 960 46.4062 960 46.4062C960 46.4062 1200 90.8125 1440 90.8125C1680 90.8125 1920 46.4062 1920 46.4062V100Z"/>';

const F_H_BOTTOM_SHAPE_DIVIDER_5 = '<path d="M1920 100H0V43.65C240 43.65 240 78.3175 480 78.3175C720 78.3175 720 2 960 2C1200 2 1200 94.4875 1440 94.4875C1680 94.4875 1680 43.65 1920 43.65V100Z"/>';

const F_H_BOTTOM_SHAPE_DIVIDER_6 = '<path d="M0 100H1920V2L960 86L0 2V100Z"/>';

const F_H_BOTTOM_SHAPE_DIVIDER_7 = '<path d="M0 100H1920V2L1440 91.6L960 2L480 91.6L0 2V100Z"/>';

const F_H_BOTTOM_SHAPE_DIVIDER_8 = '<path d="M1920 100H0V93L1920 2V100Z"/>';

const F_H_BOTTOM_SHAPE_DIVIDER_9 = '<path d="M1920 100H0V2L1380 92.3L1920 2V100Z"/>';

const F_H_BOTTOM_SHAPE_DIVIDER_10 = '<path d="M1920 100H0V57.125L480 70.4775L960 2L1440 94.4875L1920 57.125V100Z"/>';

const F_H_BOTTOM_SHAPE_DIVIDER_11 = '<path d="M1920 18.8655V100H0V18.8655C0 18.8655 19 12.9649 53 14.9318C85.7653 16.8272 121.333 30.1752 135 38.5345C144.5 34.109 157.5 30.4543 179.5 28.7C200 27.0654 217.667 32.306 227 34.109C240.667 25.9136 282.6 8.24431 341 3.13038C399.4 -1.98355 449.667 11.4897 467.5 18.8655C476.333 18.8655 497.6 19.3573 512 21.3242C526.4 23.2911 543.667 32.306 550.5 36.5676C568.5 30.1752 587 27.7539 629.5 27.2249C661.1 26.8315 689.167 40.5014 700.5 47.3855C706.833 45.5826 722.4 41.8782 734 41.4848C745.6 41.0915 758.5 43.2878 763.5 44.4352C790.5 29.1917 826.5 17.8821 886.5 17.8821C936.978 17.8821 975 26.2414 1011 41.4848C1022.17 39.1901 1054.1 34.5024 1092.5 34.109C1130.9 33.7156 1164.83 42.7961 1177 47.3855C1193.5 32.6338 1214.5 19.8946 1264.5 18.3738C1312.11 16.9258 1346.83 27.7166 1361.5 36.0759C1371.67 32.1421 1402.3 24.9629 1443.5 27.7166C1484.7 30.4702 1509.67 47.8773 1517 56.2366C1529.5 49.8442 1552 43.9435 1575 42.96C1597.9 41.9807 1624.67 47.8773 1636.5 51.8111C1665 33.1255 1678.8 27.2249 1734 19.3573C1789.2 11.4897 1846.33 27.5527 1868 36.5676C1872.33 34.2729 1883.6 28.7984 1894 25.258C1904.4 21.7175 1920 18.8655 1920 18.8655Z"/>\
    <path opacity="0.5" d="M1920 10.9802V100H0V10.9802L6 12.4475C9.19995 13.2301 15.3334 15.3823 18 16.3605L75.5 32.0123C86 24.1864 117.9 8.33893 161.5 7.55634C205.1 6.77374 233.667 20.5995 242.5 27.6103L468.5 26.1429C476 21.0887 501 10.9802 541 10.9802C581 10.9802 602.333 22.7191 608 28.5885L638.5 29.5667C650 21.2517 685 4.1325 733 2.17602C781 0.219536 817.667 15.0562 830 22.7191L988 56.9574C992.5 42.773 1018.8 12.4475 1088 4.62161C1157.2 -3.2043 1208.17 14.0779 1225 23.6973L1259.5 25.1646C1263 20.9256 1282.1 12.0562 1330.5 10.4911C1378.9 8.92587 1413 21.2517 1424 27.6103L1504 48.1533C1513.83 40.1643 1544.5 23.1104 1588.5 18.8061C1632.5 14.5018 1669.83 24.1864 1683 29.5667L1824 32.0123C1824.5 27.7733 1832.7 17.6322 1861.5 10.9802C1890.3 4.32814 1912.5 8.2085 1920 10.9802Z"/>';

const F_H_BOTTOM_SHAPE_DIVIDER_12 = '<path d="M0 100H1920V10.1667L1440 93.4667L960 2L480 93.4667L0 10.1667V100Z"/>\
    <path opacity="0.5" d="M0 100H1920V2L1440 63.6L960 2L480 63.6L0 2V100Z"/>';

const F_H_BOTTOM_SHAPE_DIVIDER_13 = '<path d="M0 100H1920V93L0 2V100Z"/>\
    <path opacity="0.5" d="M1920 100H0V93L1920 2V100Z"/>';

const F_H_BOTTOM_SHAPE_DIVIDER_14 = '<path d="M1920 100H0V57.4163C240 57.4163 240 77.9904 480 77.9904C720 77.9904 720 26.3158 960 26.3158C1200 26.3158 1200 97.1292 1440 97.1292C1680 97.1292 1680 57.4163 1920 57.4163V100Z"/>\
    <path opacity="0.5" d="M1920 100H0V47.7763C240 47.7763 240 76.1447 480 76.1447C720 76.1447 720 2 960 2C1200 2 1200 94.1974 1440 94.1974C1680 94.1974 1680 47.7763 1920 47.7763V100Z"/>';

const F_H_BOTTOM_SHAPE_DIVIDER_15 = '<path d="M0 100H1920V2C1920 2 1820 93 1460 93H460C100 93 0 2 0 2V100Z"/>';

const F_H_V_BOTTOM_SHAPE_DIVIDER_1 = '<path d="M0 100H1920V85.9958C1920 85.9958 1560 2 960 2C360 2 0 86 0 86V100Z"/>';

const F_H_V_BOTTOM_SHAPE_DIVIDER_2 = '<path d="M0 100H1920V81.8014C1680 81.8014 1680 2 1440 2C1200 2 1200 81.8014 960 81.8014C720 81.8014 720 2 480 2C240 2 240 81.8014 0 81.8014V100Z"/>';

const F_H_V_BOTTOM_SHAPE_DIVIDER_3 = '<path d="M1920 100H0V2C1488.5 2 1920 90.2 1920 90.2V100Z"/>';

const F_H_V_BOTTOM_SHAPE_DIVIDER_4 = '<path d="M1920 100H0V46.4062C0 46.4062 240 2 480 2C720 2 960 46.4062 960 46.4062C960 46.4062 1200 90.8125 1440 90.8125C1680 90.8125 1920 46.4062 1920 46.4062V100Z"/>';

const F_H_V_BOTTOM_SHAPE_DIVIDER_5 = '<path d="M1920 100H0V53.1572C240 53.1572 240 18.2717 480 18.2717C720 18.2717 720 95.0692 960 95.0692C1200 95.0692 1200 2 1440 2C1680 2 1680 53.1572 1920 53.1572V100Z"/>';

const F_H_V_BOTTOM_SHAPE_DIVIDER_6 = '<path d="M0 86V100H1920V86L960 2L0 86Z"/>';

const F_H_V_BOTTOM_SHAPE_DIVIDER_7 = '<path d="M0 100H1920V91.6L1440 2L960 91.6L480 2L0 91.6V100Z"/>';

const F_H_V_BOTTOM_SHAPE_DIVIDER_8 = '<path d="M0 100H1920V93L0 2V100Z"/>';

const F_H_V_BOTTOM_SHAPE_DIVIDER_9 = '<path d="M1920 100H0V92.3L1380 2L1920 92.3V100Z"/>';

const F_H_V_BOTTOM_SHAPE_DIVIDER_10 = '<path d="M1920 100H0V39.3625L480 26.01L960 94.4875L1440 2L1920 39.3625V100Z"/>';

const F_H_V_BOTTOM_SHAPE_DIVIDER_11 = '<path d="M1920 100.5H0V53.5971C1.66663 53.4343 6.30005 52.2291 11.5 50.6657C16.7 49.1023 23 45.7385 25.5 44.252C32.6422 44.3584 41.0691 44.2789 50.5 43.8257C62.3138 43.258 75.4594 40.8345 88 37.6378C93.1666 43.609 115 55.8446 161 57.0171C207 58.1897 236.833 41.2031 246 32.5632C269.524 41.635 307.661 52.9911 350.5 56.5286C406.182 61.1266 451.357 46.6928 468.031 38.4514C481.5 47.2457 504.979 53.5971 539 53.5971C573 53.5971 600 43.8257 614 31.6697C618.644 31.9442 623.184 32.1 627.5 32.1C630.284 32.1 633.126 31.9601 636 31.7018C649 45.78 678.9 58.2874 728.5 61.4143C778.1 64.5411 816 49.6886 838.5 36.0352C854.29 38.6808 871.802 40.671 890.5 41.3829C939.836 43.2611 977.925 32.5198 998.5 23.5667C1023 45.2914 1054.9 58.092 1104.5 60.4371C1154.1 62.7823 1200 56.04 1235 35.6491C1240.38 36.9952 1246.05 38.2208 1252 39.2714C1276 48.7114 1293.49 53.5971 1336 53.5971C1383.5 53.5971 1420 41.8714 1432 32.4281C1435.29 32.5325 1438.62 32.5886 1442 32.5886C1466.2 32.5886 1487.32 23.8063 1501.75 15C1511.33 22.0029 1535 37.9851 1582.5 44.3143C1624.27 49.8794 1657.5 42.8486 1692.5 30.8012C1707.08 34.7885 1724.03 38.272 1743 40.4057C1773.24 43.8067 1802.18 40.8112 1825.3 36.0086C1834.5 44.8029 1843.5 50.452 1866 54.5743C1887 58.4218 1906 56.04 1920 53.5971V100.5Z"/>\
    <path opacity="0.5" d="M1920 42.18V100H0V42.18C0 42.18 20 44.63 50.5 43.16C80.4664 41.7157 119 28.2967 134.5 20.62C140.167 22.9067 157.6 27.676 182 28.46C206.4 29.244 222.833 25.8467 228 24.05C245.167 32.7067 293.7 51.196 350.5 55.9C407.3 60.604 453.167 45.4467 469 37.28C477 37.9333 496.5 38.848 510.5 37.28C524.5 35.712 542.333 26.5 549.5 22.09C564.167 25.1933 600.3 31.4 627.5 31.4C654.7 31.4 687.5 18.0067 700.5 11.31C705.333 13.1067 718.4 16.7 732 16.7C745.6 16.7 758.667 15.0667 763.5 14.25C780.167 22.09 828.9 38.358 890.5 40.71C952.1 43.062 996.167 25.6833 1010.5 16.7C1021.17 18.4967 1051.9 22.384 1089.5 23.56C1127.1 24.736 1163.5 15.5567 1177 10.82C1186.67 17.8433 1216.6 33.458 1259 39.73C1301.4 46.002 1345.67 30.5833 1362.5 22.09C1373.83 25.3567 1405.6 31.89 1442 31.89C1478.4 31.89 1507.83 11.9633 1518 2C1525.33 5.43 1546.3 13.172 1571.5 16.7C1596.7 20.228 1625.67 11.9633 1637 7.39C1649.17 15.5567 1687.4 33.458 1743 39.73C1798.6 46.002 1849.83 30.5833 1868.5 22.09C1872.33 24.05 1881.8 30.616 1893 35.32C1904.2 40.024 1920 42.18 1920 42.18Z"/>';

const F_H_V_BOTTOM_SHAPE_DIVIDER_12 = '<path d="M481 2L960 90.2L1440 2L1920 90.2V100H0V90.2L481 2Z"/>\
    <path opacity="0.5" d="M1920 71.4726V100H0V71.4726L480 2L960 71.4726L1440 2L1920 71.4726Z"/>';

const F_H_V_BOTTOM_SHAPE_DIVIDER_13 = '<path d="M1537.5 100H0.000488281V60.8L960.001 2L1537.5 100Z"/>\
    <path opacity="0.5" d="M1920 60.8L960 2L1537.5 100H1920V60.8Z"/>';

const F_H_V_BOTTOM_SHAPE_DIVIDER_14 = '<path d="M1920 100H0V51.354C240 51.354 240 23.0266 480 23.0266C720 23.0266 720 97.0642 960 97.0642C1200 97.0642 1200 5 1440 5C1680 5 1680 51.354 1920 51.354V100Z"/>\
    <path opacity="0.5" d="M1920 100H0V40.8684C240 40.8684 240 20.7318 480 20.7318C720 20.7318 720 71.3076 960 71.3076C1200 71.3076 1200 2 1440 2C1680 2 1680 40.8684 1920 40.8684V100Z"/>';

const F_H_V_BOTTOM_SHAPE_DIVIDER_15 = '<path d="M0 100H1920V93C1920 93 1820 2 1460 2H460C100 2 0 93 0 93V100Z"/>';

export const TOP_SHAPE_DIVIDERS = [
    TOP_SHAPE_DIVIDER_1,
    TOP_SHAPE_DIVIDER_2,
    TOP_SHAPE_DIVIDER_3,
    TOP_SHAPE_DIVIDER_4,
    TOP_SHAPE_DIVIDER_5,
    TOP_SHAPE_DIVIDER_6,
    TOP_SHAPE_DIVIDER_7,
    TOP_SHAPE_DIVIDER_8,
    TOP_SHAPE_DIVIDER_9,
    TOP_SHAPE_DIVIDER_10,
    TOP_SHAPE_DIVIDER_11,
    TOP_SHAPE_DIVIDER_12,
    TOP_SHAPE_DIVIDER_13,
    TOP_SHAPE_DIVIDER_14,
    TOP_SHAPE_DIVIDER_15
];

export const FLIP_HORIZONTAL_TOP_SHAPE_DIVIDERS = [
    F_H_TOP_SHAPE_DIVIDER_1,
    F_H_TOP_SHAPE_DIVIDER_2,
    F_H_TOP_SHAPE_DIVIDER_3,
    F_H_TOP_SHAPE_DIVIDER_4,
    F_H_TOP_SHAPE_DIVIDER_5,
    F_H_TOP_SHAPE_DIVIDER_6,
    F_H_TOP_SHAPE_DIVIDER_7,
    F_H_TOP_SHAPE_DIVIDER_8,
    F_H_TOP_SHAPE_DIVIDER_9,
    F_H_TOP_SHAPE_DIVIDER_10,
    F_H_TOP_SHAPE_DIVIDER_11,
    F_H_TOP_SHAPE_DIVIDER_12,
    F_H_TOP_SHAPE_DIVIDER_13,
    F_H_TOP_SHAPE_DIVIDER_14,
    F_H_TOP_SHAPE_DIVIDER_15
];

export const FLIP_VERTICAL_TOP_SHAPE_DIVIDERS = [
    F_V_TOP_SHAPE_DIVIDER_1,
    F_V_TOP_SHAPE_DIVIDER_2,
    F_V_TOP_SHAPE_DIVIDER_3,
    F_V_TOP_SHAPE_DIVIDER_4,
    F_V_TOP_SHAPE_DIVIDER_5,
    F_V_TOP_SHAPE_DIVIDER_6,
    F_V_TOP_SHAPE_DIVIDER_7,
    F_V_TOP_SHAPE_DIVIDER_8,
    F_V_TOP_SHAPE_DIVIDER_9,
    F_V_TOP_SHAPE_DIVIDER_10,
    F_V_TOP_SHAPE_DIVIDER_11,
    F_V_TOP_SHAPE_DIVIDER_12,
    F_V_TOP_SHAPE_DIVIDER_13,
    F_V_TOP_SHAPE_DIVIDER_14,
    F_V_TOP_SHAPE_DIVIDER_15
];

export const FLIP_HORIZONTAL_VERTICAL_TOP_SHAPE_DIVIDERS = [
    F_H_V_TOP_SHAPE_DIVIDER_1,
    F_H_V_TOP_SHAPE_DIVIDER_2,
    F_H_V_TOP_SHAPE_DIVIDER_3,
    F_H_V_TOP_SHAPE_DIVIDER_4,
    F_H_V_TOP_SHAPE_DIVIDER_5,
    F_H_V_TOP_SHAPE_DIVIDER_6,
    F_H_V_TOP_SHAPE_DIVIDER_7,
    F_H_V_TOP_SHAPE_DIVIDER_8,
    F_H_V_TOP_SHAPE_DIVIDER_9,
    F_H_V_TOP_SHAPE_DIVIDER_10,
    F_H_V_TOP_SHAPE_DIVIDER_11,
    F_H_V_TOP_SHAPE_DIVIDER_12,
    F_H_V_TOP_SHAPE_DIVIDER_13,
    F_H_V_TOP_SHAPE_DIVIDER_14,
    F_H_V_TOP_SHAPE_DIVIDER_15
];

export const BOTTOM_SHAPE_DIVIDERS = [
    BOTTOM_SHAPE_DIVIDER_1,
    BOTTOM_SHAPE_DIVIDER_2,
    BOTTOM_SHAPE_DIVIDER_3,
    BOTTOM_SHAPE_DIVIDER_4,
    BOTTOM_SHAPE_DIVIDER_5,
    BOTTOM_SHAPE_DIVIDER_6,
    BOTTOM_SHAPE_DIVIDER_7,
    BOTTOM_SHAPE_DIVIDER_8,
    BOTTOM_SHAPE_DIVIDER_9,
    BOTTOM_SHAPE_DIVIDER_10,
    BOTTOM_SHAPE_DIVIDER_11,
    BOTTOM_SHAPE_DIVIDER_12,
    BOTTOM_SHAPE_DIVIDER_13,
    BOTTOM_SHAPE_DIVIDER_14,
    BOTTOM_SHAPE_DIVIDER_15
];

export const FLIP_HORIZONTAL_BOTTOM_SHAPE_DIVIDERS = [
    F_H_BOTTOM_SHAPE_DIVIDER_1,
    F_H_BOTTOM_SHAPE_DIVIDER_2,
    F_H_BOTTOM_SHAPE_DIVIDER_3,
    F_H_BOTTOM_SHAPE_DIVIDER_4,
    F_H_BOTTOM_SHAPE_DIVIDER_5,
    F_H_BOTTOM_SHAPE_DIVIDER_6,
    F_H_BOTTOM_SHAPE_DIVIDER_7,
    F_H_BOTTOM_SHAPE_DIVIDER_8,
    F_H_BOTTOM_SHAPE_DIVIDER_9,
    F_H_BOTTOM_SHAPE_DIVIDER_10,
    F_H_BOTTOM_SHAPE_DIVIDER_11,
    F_H_BOTTOM_SHAPE_DIVIDER_12,
    F_H_BOTTOM_SHAPE_DIVIDER_13,
    F_H_BOTTOM_SHAPE_DIVIDER_14,
    F_H_BOTTOM_SHAPE_DIVIDER_15
];

export const FLIP_VERTICAL_BOTTOM_SHAPE_DIVIDERS = [
    F_V_BOTTOM_SHAPE_DIVIDER_1,
    F_V_BOTTOM_SHAPE_DIVIDER_2,
    F_V_BOTTOM_SHAPE_DIVIDER_3,
    F_V_BOTTOM_SHAPE_DIVIDER_4,
    F_V_BOTTOM_SHAPE_DIVIDER_5,
    F_V_BOTTOM_SHAPE_DIVIDER_6,
    F_V_BOTTOM_SHAPE_DIVIDER_7,
    F_V_BOTTOM_SHAPE_DIVIDER_8,
    F_V_BOTTOM_SHAPE_DIVIDER_9,
    F_V_BOTTOM_SHAPE_DIVIDER_10,
    F_V_BOTTOM_SHAPE_DIVIDER_11,
    F_V_BOTTOM_SHAPE_DIVIDER_12,
    F_V_BOTTOM_SHAPE_DIVIDER_13,
    F_V_BOTTOM_SHAPE_DIVIDER_14,
    F_V_BOTTOM_SHAPE_DIVIDER_15
];

export const FLIP_HORIZONTAL_VERTICAL_BOTTOM_SHAPE_DIVIDERS = [
    F_H_V_BOTTOM_SHAPE_DIVIDER_1,
    F_H_V_BOTTOM_SHAPE_DIVIDER_2,
    F_H_V_BOTTOM_SHAPE_DIVIDER_3,
    F_H_V_BOTTOM_SHAPE_DIVIDER_4,
    F_H_V_BOTTOM_SHAPE_DIVIDER_5,
    F_H_V_BOTTOM_SHAPE_DIVIDER_6,
    F_H_V_BOTTOM_SHAPE_DIVIDER_7,
    F_H_V_BOTTOM_SHAPE_DIVIDER_8,
    F_H_V_BOTTOM_SHAPE_DIVIDER_9,
    F_H_V_BOTTOM_SHAPE_DIVIDER_10,
    F_H_V_BOTTOM_SHAPE_DIVIDER_11,
    F_H_V_BOTTOM_SHAPE_DIVIDER_12,
    F_H_V_BOTTOM_SHAPE_DIVIDER_13,
    F_H_V_BOTTOM_SHAPE_DIVIDER_14,
    F_H_V_BOTTOM_SHAPE_DIVIDER_15
];