<div class="alert-box edu-modal" [class]="classes.modalBodyStyles">
    <div class="sp-lpc-form">
        <button type="button" class="close-modal-btn" aria-label="Close" (click)="onCloseModal()">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="sp-ui-text">
            <h2 class="edu-modal-title">{{ 'password_recovery' | translate }}</h2>
        </div>

        <div *ngIf="form.hasError('alreadySent')" class="error-alert">
            {{ 'error_reset_password_link_already_sent' | translate }}
        </div>

        <div class="field-block" [class]="classes.formField">
            <p>{{ 'we_will_send_you_recovery_letter' | translate }}</p>
        </div>

        <div class="field-block" [class]="classes.formField">
            <label>Email</label>
            <input
                name="email"
                [class.has-error]="email.invalid && email.touched"
                [class.disabled]="form.disabled"
                [formControl]="email"
                placeholder="example@mail.com"
                [class]="classes.formControl"
            />
            <div *ngIf="email.invalid && email.touched" class="error-reason text-danger">
                <span *ngIf="email.hasError('required')">{{ 'error_required' | translate }}</span>
                <span *ngIf="email.hasError('email')">{{ 'error_invalid_email' | translate }}</span>
                <span *ngIf="email.hasError('notFound')">{{ 'error_user_not_found' | translate }}</span>
            </div>
        </div>

    <div class="edu-modal-footer"
        [class]="classes?.buttonWrapper">
        <button
            type="button"
            class="sp-ui-button sp-ui-button-md sp-ui-button-primary-light"
            [class.disabled]="form.disabled"
            (click)="onPasswordRecovery()"
        >
            {{ 'send' | translate }}
        </button>
    </div>
</div>
