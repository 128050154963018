import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import type { SafeHtml } from '@angular/platform-browser';
import type { ChatBotWidgetData } from '@web/widgets/chat-bot';
import type { ChatBotData } from '@web/widgets/chat-bot/type/chat-bot-widget.type';
import { CommonModule } from '@angular/common';

import { EButtonStyleType, EButtonType, ESize, ESocialNetworks } from '@common/enums';
import { socialIconTemplates } from '@common/icons/social-icons';

import { getStyles } from '@web/widgets/chat-bot/components/chat-bot-view/chat-bot-view.component.styles';

import { BUTTON_SIZE_CLASS_MAP, BUTTON_TYPE_CLASS_MAP, DEFAULT_BUTTON_CLASS } from '@web/widgets/common/base/base-button/base-button.const';
import { getSocialNetworkIconSrc } from '@web/shared/components/web-social-networks/utils';

import { AbstractWidgetComponent } from '@web-builder/widgets/abstract-widget.component';

@Component({
    selector: 'web-builder-chat-bot',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './chat-bot.component.html',
    styleUrls: ['./chat-bot.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatBotComponent extends AbstractWidgetComponent<ChatBotWidgetData> implements OnInit {
    public readonly EButtonType = EButtonType;
    protected getStyles = getStyles;

    public getStyleList(icon: ChatBotData): string {
        if (icon.type === EButtonType.icon) {
            return `${this.classes?.chatBotGroup}`;
        }

        let styles = `${DEFAULT_BUTTON_CLASS} ${BUTTON_SIZE_CLASS_MAP[this.widgetData.buttonSize ? this.widgetData.buttonSize : ESize.M]}`;
        if (this.chatBotStyleType === EButtonStyleType.custom) {
            return `${styles} ${this.classes?.chatBotGroup}`;
        }

        return `${styles} ${BUTTON_TYPE_CLASS_MAP[this.chatBotStyleType]} ${this.classes?.chatBotGroup}`;
    }

    public getSocialNetworkIconSrcByChatBot(chatBot: ChatBotData): string {
        const folderName: string = chatBot.type === EButtonType.button ? 'full-white' : chatBot.iconType;
        const src = getSocialNetworkIconSrc(folderName, chatBot.socialNetwork);

        return this.formatImgSrc(src);
    }

    public getIcon(iconName: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(socialIconTemplates[iconName]);
    }

    public get chatBotStyleType(): EButtonStyleType {
        return this.widgetData.styleType;
    }

    public get chatBots(): ChatBotData[] {
        return this.widgetData.chatBots;
    }

    public getChatBotType(chatBot: ChatBotData): 'email' | 'phone' | 'chatBot' {
        switch (chatBot.socialNetwork) {
            case ESocialNetworks.email:
                return 'email';
            case ESocialNetworks.phone:
                return 'phone';
            default:
                return 'chatBot';
        }
    }

    private getNormalizedLink(link: string, type: string): string {
        const trimmedLink = link?.trim();

        if (!trimmedLink) {
            return '';
        }

        if (type === 'email') {
            return this.linkFormatterService.formatEmailLink(trimmedLink);
        }

        if (type === 'phone') {
            return this.linkFormatterService.formatPhoneLink(trimmedLink);
        }

        return this.linkFormatterService.formatLink(trimmedLink);
    }

    private getChatBotURL(chatBot: ChatBotData): { url: string; searchParams?: URLSearchParams | null } {
        try {
            const url = new URL(chatBot.link);
            if (chatBot.socialNetwork === ESocialNetworks.viber) {
                return {
                    url: url.href,
                };
            }

            const normalizedUrl = url.origin + url.pathname;

            if (chatBot.socialNetwork === ESocialNetworks.telegram) {
                const telegramQueryParams = this.utmTagsService.parseTelegramQueryParams(url.search);

                // TMP fix for botChain
                if (chatBot?.botChain?.chainId === '645b3c0c86644385a0042568') {
                    chatBot.botChain.chainId = null;
                }

                if ((chatBot?.botChain as any)?.chainId) {
                    telegramQueryParams.set('start', (chatBot.botChain as any)?.chainId);
                    return {
                        url: `https://tg.pulse.is${url.pathname}`,
                        searchParams: telegramQueryParams,
                    };
                }

                if (normalizedUrl.includes('t.me') && url.pathname.toLowerCase().includes('bot')) {
                    return {
                        url: normalizedUrl.replace('t.me', 'tg.pulse.is'),
                        searchParams: telegramQueryParams,
                    };
                }

                return {
                    url: normalizedUrl,
                    searchParams: telegramQueryParams,
                };
            }

            if (chatBot.socialNetwork === ESocialNetworks.instagram) {
                const instagramQueryParams = this.utmTagsService.parseInstagramQueryParams(url.search);
                if ((chatBot?.botChain as any)?.chainId) {
                    instagramQueryParams.set('ref', (chatBot.botChain as any)?.chainId);
                }
                return {
                    url: normalizedUrl,
                    searchParams: instagramQueryParams,
                };
            }

            if (chatBot.socialNetwork === ESocialNetworks.facebook) {
                const facebookQueryParams = this.utmTagsService.parseFacebookQueryParams(url.search);
                if ((chatBot?.botChain as any)?.chainId) {
                    facebookQueryParams.set('ref', (chatBot.botChain as any)?.chainId);
                }

                return {
                    url: normalizedUrl,
                    searchParams: facebookQueryParams,
                };
            }

            return {
                url: normalizedUrl,
                searchParams: url.searchParams,
            };
        } catch (e) {
            return {
                url: chatBot.link,
            };
        }
    }

    private processLink(chatBot: ChatBotData): string {
        const { url, searchParams } = this.getChatBotURL(chatBot);

        if (!url) {
            return '';
        }

        const utmTags = this.utmTagsService.getChatBotUtmTags({
            social: chatBot.socialNetwork,
            chatBotSearchParams: searchParams,
        });
        if (!utmTags) {
            return url;
        }

        return url + utmTags;
    }

    public getChatBotLink(chatBot: ChatBotData): string {
        const chatBotType = this.getChatBotType(chatBot);
        if (chatBotType !== 'chatBot') {
            return this.getNormalizedLink(chatBot.link, chatBotType);
        }

        const normalizedLink = this.getNormalizedLink(this.processLink(chatBot), chatBotType);
        if (!normalizedLink) {
            return '';
        }

        return normalizedLink;
    }
}
