import { MapStyle } from '@web/widgets/map/type/map-widget.type';

import whiteStyle from '@web/widgets/map/model/map-styles/white.style';
import grayStyle from '@web/widgets/map/model/map-styles/gray.style';

export const mapStyles: Record<string, google.maps.MapTypeStyle[]> = {
    [MapStyle.Default]: [],
    [MapStyle.White]: whiteStyle.styles,
    [MapStyle.Gray]: grayStyle.styles,
};
