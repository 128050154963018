<div class="alert-box edu-modal">
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="onCloseModal()">
            <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">{{ 'sign_up' | translate }}</h4>
    </div>
    <div class="modal-body">
        <div class="form-control-row">
            <p>{{ 'confirmation_email_has_been_sent' | translate }}</p>
        </div>
        <br>
        <div class="form-control-row">
            <p>{{ 'please_check_your_spam_folder_or_try' | translate }}.</p>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="sp-ui-button sp-ui-button-md sp-ui-button-primary-light" (click)="onChangeUser()">{{ 'change_user' | translate }}</button>
    </div>
</div>

