<ng-container *ngIf="!!file?.videoFiles?.length">
    <div class="control-handle" *ngFor="let fileItem of file.videoFiles; index as index">
        <ng-container>
            <div class="control-handle-container">
                <div class="file-box">
                    <div class="control-handle-content">
                        <div class="wrap-text">
                            <span class="file-name text-nowrap">{{ fileItem.videoFile.name }}</span>
                            <div class="d-flex align-items-end">
                                <div class="file-size text-nowrap">{{ fileItem.videoFile.size | spByteFormat }}</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button class="btn btn-link" (click)="removeFileEvent.emit(index)">
                            <span class="sp-icon icon-bin"> </span>
                        </button>
                        <button class="btn btn-link" (click)="replaceFileEvent.emit(index)">
                            <span class="sp-icon icon-marquee-upload"> </span>
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="preview-image">
        <div class="form-group row align-items-baseline d-flex">
            <label class="col-xs-9 control-label">
                {{ "video_poster_image" | translate }}
            </label>
            <div class="col-xs-3 control-switch">
                <sp-switch [active]="showPoster || file?.previewUrl" (toggleActiveEvent)="togglePreview($event)"></sp-switch>
            </div>
        </div>

        <div class="img-panel panel-default" *ngIf="file?.previewUrl">
            <div class="panel-body">
                <div class="media panel-image" dropdown #dropdown="bs-dropdown" [insideClick]="true">
                    <div class="panel-image-content">
                        <div class="preview-thumbnail" [style.background-image]="encodeFilePreviewUrl()"></div>
                        <div class="file-name">
                            <strong class="text-muted">{{ file.previewUrl }}</strong>
                        </div>
                    </div>
                    <div>
                        <button class="btn btn-link" (click)="openImagePreviewFm.emit()">
                            <a>
                                <img src="./assets/img/file-manager/upload-icon.svg" />
                            </a>
                        </button>
                        <button (click)="removePreview()" class="btn btn-link">
                            <a>
                                <img src="./assets/img/file-manager/delete-icon.png" />
                            </a>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <button class="btn btn-action btn-block" (click)="openImagePreviewFm.emit()" *ngIf="!file.previewUrl && showPoster">
            {{ "control_file_manager_choose_from_gallery" | translate }}
        </button>
    </div>
</ng-container>
