import { SelectedFileInterface } from '@sendpulse/file-manager';

export type ReplacedFile = {
    file: SelectedFileInterface;
    index?: number;
};

export enum PreviewType {
    IMAGE_SINGLE = 'IMAGE_SINGLE',
    IMAGE_MULTIPLE = 'IMAGE_MULTIPLE',
    FILE = 'FILE',
    VIDEO = 'VIDEO',
    AUDIO = 'AUDIO',
}
