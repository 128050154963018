import type { ThemedBaseButtonProp } from './base-button.type';
import type { ButtonAdditionalEffectsSettings } from '@common/components/sp-button-additional-effects-settings/type/button-additional-effects-settings.type';
import { ButtonIcon } from '@common/components/sp-button-icon/type/button-icon.type';

import { EButtonStyleType, EButtonHoverType, ESize, EButtonAdditionalEffectsCondition, EButtonAdditionalHoverEffectsTypes, EButtonAdditionalStaticEffectsTypes, EHorizontalPosition } from '@common/enums';

import { buttonIconTemplates } from '@common/icons/button-icons';
export const DEFAULT_BUTTON_CLASS = 'sp-ui-button';

export const BUTTON_TYPE_CLASS_MAP = {
    [EButtonStyleType.primary]: 'sp-ui-button-primary-light',
    [EButtonStyleType.secondary]: 'sp-ui-button-secondary-light',
};

export const BUTTON_SIZE_CLASS_MAP = {
    [ESize.S]: 'sp-ui-button-sm',
    [ESize.M]: 'sp-ui-button-md',
    [ESize.L]: 'sp-ui-button-lg',
};

export const DEFAULT_BUTTON_BASE: ThemedBaseButtonProp = {
    color: ['rgb(255,255,255)'],
    background: ['rgb(0,0,0)'],
    borderColor: ['rgb(0,0,0)'],
    borderWidth: 2,
    borderRadius: 8,
    borderStyle: 'solid',
};

export const DEFAULT_BUTTON_HOVER_STATES = {
    hoverType: EButtonHoverType.opacity,
    opacity: 80,
    shadow: 'none',
    color: ['rgb(255,255,255)'],
    background: ['rgb(22,117,242)'],
    borderColor: ['rgba(0,0,0,0)'],
}

export const DEFAULT_BUTTON_ADDITIONAL_EFFECTS: ButtonAdditionalEffectsSettings = {
    isActive: false,
    effectCondition: EButtonAdditionalEffectsCondition.hover,
    hoverEffects: EButtonAdditionalHoverEffectsTypes.bounce,
    staticEffects: EButtonAdditionalStaticEffectsTypes.shine,
}

export const DEFAULT_BUTTON_ICON: ButtonIcon = {
    isActive: false,
    icon: buttonIconTemplates.telegram,
    position: EHorizontalPosition.L,
}
