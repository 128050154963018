import type { SelectedFileInterface } from '@sendpulse/file-manager';
import type { SelectedFileExtended } from '@common/types/file.type';

import { FILE_EXTENSION } from '@common/regexp';
import { EConditionType, EHorizontalPosition, EOverlayType, ESize, EVerticalPosition } from '@common/enums';

export function extendFileData(files: SelectedFileInterface[]): SelectedFileExtended[] {
    return files.map((file) => ({
        ...file,
        alt: file.name.replace(FILE_EXTENSION, ''),
        textSize: ESize.M,
        textPosition: EVerticalPosition.B,
        textColor: '#ffffff',
        textBackground: 'rgba(0,0,0,0.5)',
    }));
}

export function extendWebFileData(file: SelectedFileInterface): SelectedFileExtended {
    return {
        ...file,
        description: null,
        link: null,
        alt: file.name.replace(FILE_EXTENSION, ''),
        textSize: ESize.M,
        textPosition: EVerticalPosition.B,
        textJustify: EHorizontalPosition.L,
        textColor: '#ffffff',
        textBackground: 'rgba(0,0,0,0.5)',
        hasResize: true,
        hasSourceResize: true,
    };
}

export function extendWebFileDataCallback(
    file: SelectedFileInterface,
    cdnUrl: string,
    callback: (extendedFile: SelectedFileExtended) => void,
) {
    // TODO: refactor on observable
    const img = new Image();
    img.onload = () => {
        callback({
            ...file,
            description: null,
            link: null,
            alt: file.name.replace(FILE_EXTENSION, ''),
            textSize: ESize.M,
            textPosition: EVerticalPosition.B,
            textJustify: EHorizontalPosition.L,
            textColor: '#ffffff',
            textBackground: 'rgba(0,0,0,0.5)',
            imageHeight: img.height,
            imageWidth: img.width,
        });
    };
    img.src = `${cdnUrl}${file.url}`;
}

export function extendWebFileDataPromise(
    file: SelectedFileInterface,
    cdnUrl: string,
    needResize: boolean = true,
): Promise<SelectedFileExtended> {
    return new Promise((resolve) => {
        const img = new Image();
        const fileExtension = file.extension?.toLowerCase()?.trim();

        let hasResize: boolean;

        if (needResize === false) {
            hasResize = false;
        } else {
            hasResize = !(fileExtension?.includes('gif') || fileExtension?.includes('svg'));
        }

        img.onload = () => {
            resolve({
                link: null,
                alt: file.name.replace(FILE_EXTENSION, ''),
                imageHeight: img.height,
                imageWidth: img.width,
                isImageEffect: false,
                condition: EConditionType.ALWAYS,
                overlay: EOverlayType.TEXT,
                description: null,
                textSize: ESize.M,
                fontSize: 14,
                textStyle: [],
                textColor: '#ffffff',
                textBackground: 'rgba(0,0,0,0.5)',
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 5,
                paddingRight: 5,
                textPosition: EVerticalPosition.B,
                textJustify: EHorizontalPosition.L,
                maskShape: null,
                overlayImage: null,
                ...file,
                hasResize,
                hasSourceResize: hasResize,
                isTargetBlank: false,
            });
        };
        img.src = `${cdnUrl}${file.url}`;
    });
}

export function preparedImageData(widgetImage, editedImage, userHash) {
    let url;
    if (widgetImage?.url) {
        const urlParts = widgetImage.url.split('/');
        urlParts.shift();
        urlParts.pop();
        url = urlParts.join('/');
    }

    return {
        ...widgetImage,
        ...editedImage,
        alt: editedImage.name,
        url: `/${url ?? userHash}/${editedImage.name}`,
    };
}

export function preparedSelectedFile(path: string, userHash: string, cdn: string): SelectedFileInterface {
    return {
        path,
        isFolder: false,
        thumb: 'thumb' + path,
        filesCount: 1,
        name: path.split('/').pop(),
        size: null,
        url: `/${userHash}${path}`,
        defaultCdnUrl: cdn.replace(/^https?:\/\//, ''),
        extension: `image/${path.split('.').pop()}`,
    };
}
