import type { PaymentFormWidgetData } from '@web/widgets/payment-form/type/payment-form-widget.type';

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EButtonAdditionalEffectsCondition, EButtonAdditionalHoverEffectsTypes, EButtonAdditionalStaticEffectsTypes, EButtonStyleType, ESize } from '@common/enums';

import { getStyles } from '@web/widgets/payment-form/components/payment-form-view/payment-form-view.component.styles';

import { BUTTON_SIZE_CLASS_MAP, BUTTON_TYPE_CLASS_MAP, DEFAULT_BUTTON_CLASS } from '@web/widgets/common/base/base-button/base-button.const';
import { AbstractWidgetComponent } from '@web-builder/widgets/abstract-widget.component';

import { PaymentFormModalComponent } from './payment-form-modal/payment-form-modal.component';
import { SpPipesModule } from '@common/pipes/pipes.module';
import { EPaymentView } from '@common/enums/payment-form-view.enum';
import { PaymentFormStaticComponent } from '@web-builder/widgets/payment-form/payment-form-static/payment-form-static.component';
import { SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'web-builder-payment-form',
    templateUrl: './payment-form.component.html',
    styleUrls: ['./payment-form.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, SpPipesModule, PaymentFormStaticComponent],
})
export class PaymentFormComponent extends AbstractWidgetComponent<PaymentFormWidgetData> {
    protected getStyles = getStyles;

    public get formButtonSettings(): PaymentFormWidgetData['formButtonSettings'] {
        return this.widgetData.formButtonSettings;
    }

    public get elements(): PaymentFormWidgetData['elements'] {
        return this.widgetData.elements;
    }

    public getButtonEffectClass(button: PaymentFormWidgetData['formButtonSettings']): string {
        let nameEffect: string = '';

        if (button.additionalEffects?.isActive) {
            if (button.additionalEffects?.effectCondition === EButtonAdditionalEffectsCondition.hover) {
                switch (button.additionalEffects?.hoverEffects) {
                    case EButtonAdditionalHoverEffectsTypes.bounce:
                        nameEffect = EButtonAdditionalHoverEffectsTypes.bounce;
                        break;
                    case EButtonAdditionalHoverEffectsTypes.shake:
                        nameEffect = EButtonAdditionalHoverEffectsTypes.shake;
                        break;
                    case EButtonAdditionalHoverEffectsTypes.decrease:
                        nameEffect = EButtonAdditionalHoverEffectsTypes.decrease;
                        break;
                    case EButtonAdditionalHoverEffectsTypes.increase:
                        nameEffect = EButtonAdditionalHoverEffectsTypes.increase;
                        break;
                    default:
                        break;
                }
            }

            if (button.additionalEffects?.effectCondition === EButtonAdditionalEffectsCondition.static) {
                switch (button.additionalEffects?.staticEffects) {
                    case EButtonAdditionalStaticEffectsTypes.shine:
                        nameEffect = EButtonAdditionalStaticEffectsTypes.shine;
                        break;
                    case EButtonAdditionalStaticEffectsTypes.twinkle:
                        nameEffect = EButtonAdditionalStaticEffectsTypes.twinkle;
                        break;
                    default:
                        break;
                }
            }

            return 'button_effect_' + nameEffect;
        }

        return '';
    }

    public getButtonStyles(formButtonSettings: PaymentFormWidgetData['formButtonSettings']): string {
        let styles = `${DEFAULT_BUTTON_CLASS} ${BUTTON_SIZE_CLASS_MAP[formButtonSettings.size ? formButtonSettings.size : ESize.M]}`;
        if (formButtonSettings.styleType === EButtonStyleType.custom) {
            return `${styles} ${this.classes?.buttonStyles} ${this.getButtonEffectClass(formButtonSettings)}`;
        }

        return `${styles} ${BUTTON_TYPE_CLASS_MAP[formButtonSettings.styleType]} ${this.classes?.buttonStyles} ${this.getButtonEffectClass(formButtonSettings)}`;
    }

    public getIcon(icon: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(icon);
    }

    public showPaymentModal(): void {
        this.modalService.show(PaymentFormModalComponent, {
            ignoreBackdropClick: true,
            class: this.classes.modalContainerStyles,
            initialState: {
                classes: this.classes,
                elements: this.elements,
                widgetData: this.widgetData,
                widget: this.widget,
            },
        });
    }

    public get isStaticForm(): boolean {
        return this.widgetData.formView === EPaymentView.static;
    }

    public get isModalForm(): boolean {
        return this.widgetData.formView === EPaymentView.modal || !this.widgetData.formView;
    }

    public get rootFormWithPositionClasses() {
        return `${this.classes?.form} ${this.classes?.formPosition}`;
    }
}
