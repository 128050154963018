import { Component, Input, type OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateModule } from '@ngx-translate/core';
import { Classes } from 'jss';

@Component({
    selector: 'web-builder-edu-course-password-recovery-success-modal',
    styleUrls: ['./edu-course-password-recovery-success-modal.component.less'],
    templateUrl: './edu-course-password-recovery-success-modal.component.html',
    imports: [CommonModule, ReactiveFormsModule, TranslateModule],
    standalone: true,
})
export class EduCoursePasswordRecoverySuccessModalComponent implements OnInit {
    @Input() public email!: string;
    @Input() public classes!: Classes;

    public onClose: Subject<boolean>;

    constructor(private readonly bsModalRef: BsModalRef) {}

    public ngOnInit(): void {
        this.onClose = new Subject();
    }

    public onConfirm(): void {
        this.onClose.next(true);
        this.bsModalRef.hide();
    }

    public onCancel(): void {
        this.onClose.next(false);
        this.bsModalRef.hide();
    }

    public onCloseModal(): void {
        this.bsModalRef.hide();
    }
}
