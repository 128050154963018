<div class="alert-box edu-modal">
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="onCloseModal()">
            <span aria-hidden="true">&times;</span>
        </button>
        <h3 class="modal-title">{{ 'sign_up_title' | translate }}</h3>
    </div>
    <div class="modal-body">
        <div class="form-control-row">
            <p>{{ 'user_tariff_restriction_message' | translate }}</p>
        </div>
    </div>
</div>
