import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'web-edu-course-tariff-restrictions-modal',
    styleUrls: ['./edu-course-tariff-restrictions-modal.component.less'],
    templateUrl: './edu-course-tariff-restrictions-modal.component.html',
    imports: [CommonModule, ReactiveFormsModule, TranslateModule],
    standalone: true,
})
export class EduCourseTariffRestrictionsModalComponent {
    constructor(private readonly bsModalRef: BsModalRef) {}

    public onCloseModal(): void {
        this.bsModalRef.hide();
    }
}
