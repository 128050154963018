import type { ThemeFont, ThemeSelectedFonts } from '@libs/themes/src/types/theme-font.type';
import type { ThemeColor, ThemeColorsKeyValue, ThemeSelectedColors } from '@libs/themes/src/types/theme-color.type';
import type { ThemeButton, ThemeSelectedButtons } from '@libs/themes/src/types/theme-button.type';

import { WEBSITE_THEME_FONTS_VARIABLES, WEBSITE_THEME_BUTTONS_VARIABLES, WEBSITE_THEME_COLORS_VARIABLES, WEBSITE_THEME_BUTTONS_HOVER_VARIABLES } from '../constants/css-variables';

export function formatWebsiteColorsValues(values: Record<string, string>, colors: ThemeSelectedColors): Record<string, string> {
    if (!colors) {
        return values;
    }

    return {
        ...values,
        ...colors.selected.reduce((colorValue: Record<string, string>, color: ThemeColor) => {
            if (!WEBSITE_THEME_COLORS_VARIABLES[color.name]) {
                return colorValue;
            }

            return {
                ...colorValue,
                [WEBSITE_THEME_COLORS_VARIABLES[color.name]]: color.value,
            };
        }, {}),
    };
}

export function formatWebsiteFontsValues(values: Record<string, string>, fonts: ThemeSelectedFonts): Record<string, string> {
    if (!fonts) {
        return values;
    }

    return {
        ...values,
        ...fonts.selected.reduce((fontValue: Record<string, string>, font: ThemeFont) => {
            if (!WEBSITE_THEME_FONTS_VARIABLES[font.tag]) {
                return fontValue;
            }

            Object.keys(font.styles).forEach((prop: string) => {
                if (!WEBSITE_THEME_FONTS_VARIABLES[font.tag][prop] || !font.styles[prop]) {
                    return;
                }

                fontValue[WEBSITE_THEME_FONTS_VARIABLES[font.tag][prop]] = font.styles[prop];
            });

            if (font.mobileStyles) {
                Object.keys(font.mobileStyles).forEach((prop: string) => {
                    if (!WEBSITE_THEME_FONTS_VARIABLES[font.tag][prop] || !font.mobileStyles[prop]) {
                        return;
                    }

                    const key = `${WEBSITE_THEME_FONTS_VARIABLES[font.tag][prop]}-mobile`;
                    fontValue[key] = font.mobileStyles[prop];
                });
            }

            return fontValue;
        }, {}),
    };
}

export function formatWebsiteButtonValues(values: Record<string, string>, buttons: ThemeSelectedButtons): Record<string, string> {
    if (!buttons) {
        return values;
    }

    return {
        ...values,
        ...buttons.selected.reduce((buttonValues: Record<string, string>, button: ThemeButton) => {
            const buttonStyleTypeVariables = WEBSITE_THEME_BUTTONS_VARIABLES[button.styleType];
            const buttonHoverStyleTypeVariables = WEBSITE_THEME_BUTTONS_HOVER_VARIABLES[button.styleType];

            Object.keys(buttonStyleTypeVariables).forEach((prop: string) => {
                const buttonStyleTypeVariable = buttonStyleTypeVariables[prop];
                const buttonBasePropValue = button.base?.[prop];

                if (Array.isArray(buttonStyleTypeVariable)) {
                    buttonStyleTypeVariable.forEach((variable: string, index: number) => {
                        const value = buttonBasePropValue[index] || buttonBasePropValue[0];

                        if (WEBSITE_THEME_COLORS_VARIABLES[value]) {
                            buttonValues[variable] = values[WEBSITE_THEME_COLORS_VARIABLES[value]];
                            return;
                        }

                        buttonValues[variable] = value;
                    });
                    return;
                }

                buttonValues[buttonStyleTypeVariable] = buttonBasePropValue;
            });

            if (!button.hover) {
                return buttonValues;
            }

            Object.keys(buttonHoverStyleTypeVariables).forEach((prop: string) => {
                const buttonHoverStyleTypeVariable = buttonHoverStyleTypeVariables[prop];
                const buttonHoverPropValue = button.hover?.[prop];

                if (Array.isArray(buttonHoverStyleTypeVariable)) {
                    buttonHoverStyleTypeVariable.forEach((variable: string, index: number) => {
                        const value = buttonHoverPropValue[index] || buttonHoverPropValue[0];

                        if (WEBSITE_THEME_COLORS_VARIABLES[value]) {
                            buttonValues[variable] = values[WEBSITE_THEME_COLORS_VARIABLES[value]];
                            return;
                        }

                        buttonValues[variable] = value;
                    });
                    return;
                }

                buttonValues[buttonHoverStyleTypeVariable] = buttonHoverPropValue;
            });

            return buttonValues;
        }, {}),
    };
}

export function formatNextWebsiteButtons(colors: ThemeColor[], buttons: ThemeButton[]): ThemeButton[] {
    const colorsObj: ThemeColorsKeyValue = colors.reduce((result: ThemeColorsKeyValue, color: ThemeColor) => ({ ...result, [color.name]: color.value }), {});

    return buttons.map((button: ThemeButton) => {
        const styles = Object.keys(button.base).reduce((res, prop: string) => {
            return res;
            // const replacedProp = prop.replace(/Light|Dark/, '');
            // return {
            //     ...res,
            //     [replacedProp]: colorsObj[button.base[prop]],
            // };
        }, {});

        return {
            ...button,
            styles: {
                ...button.styles,
                ...styles,
            },
        };
    });
}
