import type { FormElement, FormRadio, Option } from '@common/types/form-element.type';
import type { FormButtonSettings } from '@common/components/sp-form-button-settings';
import type { FormInputSettings } from '@components/sp-form-input-settings';

import { Component, ChangeDetectionStrategy, inject, type AfterContentInit, type OnDestroy } from '@angular/core';

import {
    EButtonAdditionalEffectsCondition,
    EButtonAdditionalHoverEffectsTypes,
    EButtonAdditionalStaticEffectsTypes,
    EButtonStyleType,
    ECrmCommentPlace,
    EFormControlType,
    EFormInputType,
    EPaymentType,
    ESize,
} from '@common/enums';
import * as typeGuards from '@common/type-guards/form-elements.type-guards';

import { BUTTON_SIZE_CLASS_MAP, BUTTON_TYPE_CLASS_MAP, DEFAULT_BUTTON_CLASS } from '@web/widgets/common/base/base-button/base-button.const';

import { getStyles } from '@web/widgets/shop-cart/components/shop-cart-view/shop-cart-view.component.styles';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from '@moddi3/ngx-intl-tel-input';
import { CountryISOService } from '@common/services/REST/country-iso.http.service';
import { type ShopCartWidgetData } from '@web/widgets/shop-cart';
import { type ProductCard, ProductType } from '@common/types/product-card.type';
import type { PaymentFormWidgetData } from '@web/widgets/payment-form';
import { paymentsList, paymentTypeNames } from '@common/constants/payment-form.const';
import type { PaymentElement, PaymentFormElement, PaymentMethod } from '@common/types/payment-form.type';
import { AbstractWidgetComponent } from '@web-builder/widgets/abstract-widget.component';
import { type AbstractControl, type FormArray, FormControl, type FormGroup, Validators } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { ShopCartHttpService } from '@web-builder/core/services/REST/shop-cart.http.service';
import { CartFacade } from '@web-builder/store/cart/cart.facade';
import type { SelectedFileExtended } from '@common/types/file.type';
import { AnalyticsService } from '@web-builder/core/services/analytics.service';
import { SafeHtml } from '@angular/platform-browser';
import { getUuid } from '@common/helpers';

@Component({
    selector: 'web-builder-shop-cart-view',
    templateUrl: './shop-cart.component.html',
    styleUrls: ['./shop-cart.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopCartComponent extends AbstractWidgetComponent<ShopCartWidgetData> implements AfterContentInit, OnDestroy {
    private readonly analyticsService: AnalyticsService = inject(AnalyticsService);
    private readonly shopCartHttpService: ShopCartHttpService = inject(ShopCartHttpService);
    private readonly countryISOService: CountryISOService = inject(CountryISOService);
    private readonly pageId: number = this.transferStateService.get('pageId');
    private readonly cartFacade: CartFacade = inject(CartFacade);
    private unsubscribe$ = new Subject<void>();

    public currency: string = this.transferStateService.get('site')?.settings?.shop?.currency;
    public cartItems$ = this.cartFacade.cartItems$;
    public cartTotalPrice$ = this.cartFacade.cartTotalPrice$;
    public selectCartLoaded$ = this.cartFacade.selectCartLoaded$;
    public isAllDigitalProducts$ = this.cartFacade.isAllDigitalProducts$;
    public isDeliveryVariants: boolean = !!this.transferStateService.get('site')?.settings?.shop?.delivery_variants;
    public deliveryVariants: any = this.isDeliveryVariants
        ? this.transferStateService.get('site')?.settings?.shop?.delivery_variants.reduce((arr, el, index) => {
              arr.push({ ...el, index });
              return arr;
          }, [])
        : [];

    protected getStyles = getStyles;

    public test: boolean = true;

    public readonly SearchCountryField = SearchCountryField;
    public readonly PhoneNumberFormat = PhoneNumberFormat;
    public readonly formInputType = EFormInputType;
    public readonly paymentsList = paymentsList;
    public readonly typeGuards = typeGuards;
    public excludeCountries = [CountryISO.Russia];
    public CountryISO = CountryISO.Ukraine;
    public currentPaymentMethodIndex: number = 0;
    public isShowSuccessMessage: boolean = false;
    public isShowInvalidPaymentMethodError: boolean = false;
    public showErrorMessage: boolean = false;
    public isLoading = false;
    public phoneError: string = '';
    public emailError: string = '';
    public formGroup: FormGroup;
    public deliveryVariant: FormGroup;

    public ngAfterContentInit(): void {
        this.formGroup = this.fb.group({
            formArray: this.fb.array([]),
        });

        if (this.deliveryVariants.length) this.initFormDeliveryArray();
        this.cartTotalPrice$.subscribe((price) => {
            if (this.deliveryVariant && this.deliveryVariant.get('delivery').value !== null) {
                const sDel = this.deliveryVariants[this.deliveryVariant.get('delivery').value];

                if (sDel.isDeliveryCondition) {
                    if (price < (sDel?.minPrice ? sDel?.minPrice : 0) || price > (sDel?.maxPrice ? sDel?.maxPrice : 9999999)) {
                        this.deliveryVariant.get('delivery').patchValue(null);
                    }
                }
            }
        });
        this.generateFormArray();
        this.getCountryISO();
        this.formSubscription();
    }

    private formSubscription() {
        this.formGroup.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => (this.isShowSuccessMessage = false));
    }

    public increaseQuantity(item: ProductCard) {
        if (item.quantity === null) {
            this.cartFacade.setQuantity(item, +item.selectedQuantity + 1);
            return;
        }
        if (item.selectedQuantity < item.quantity) {
            this.cartFacade.setQuantity(item, +item.selectedQuantity + 1);
        }
    }

    public decreaseQuantity(item: ProductCard) {
        if (item.selectedQuantity > 1) this.cartFacade.setQuantity(item, +item.selectedQuantity - 1);
    }

    public setQuantity(item: ProductCard, selectedQuantity: number) {
        if (item.quantity === null && +selectedQuantity > 0) {
            this.cartFacade.setQuantity(item, +selectedQuantity);
            return;
        }
        if (+selectedQuantity > +item.quantity) {
            this.cartFacade.setQuantity(item, +item.quantity);
            return;
        } else if (selectedQuantity <= 0) {
            this.cartFacade.setQuantity(item, 1);
            return;
        }

        this.cartFacade.setQuantity(item, +selectedQuantity);
    }

    public removeItemFromCart(item: ProductCard) {
        this.cartFacade.removeItemFromCart(item);
    }

    public initFormDeliveryArray(): void {
        this.deliveryVariant = this.fb.group({
            delivery: new FormControl(null, Validators.required),
        });
    }

    public generateFormArray(): void {
        this.formGroup = this.fb.group({
            formArray: this.fb.array(
                this.elements.map((formElement) => {
                    const options = (formElement as FormRadio)?.options || null;

                    return this.fb.group({
                        ...{
                            ...formElement,
                            ...{
                                options: this.fb.array(options ? (formElement as FormRadio).options.map((opt) => this.fb.group(opt)) : []),
                            },
                        },
                        value: [this.getDefaultValue(formElement, options), this.getValidators(formElement)],
                    });
                }),
            ),
        });

        this.formGroup.markAsUntouched();
    }

    public inputErrors(control: AbstractControl): string[] {
        const errors: string[] = [];

        Object.keys(control.get('value').errors).forEach((err) => {
            if (control.get('type').value === EFormControlType.radio) {
                const isRadioChecked = control.get('options').value.some((el) => el.selected);
                if (!isRadioChecked && err === 'required') {
                    errors.push('control_errors_required');
                }

                return;
            }

            if (err === 'pattern') errors.push('email_validation_error');
            if (control.get('type').value === EFormInputType.phone && this.phoneError) errors.push(this.phoneError);
            if (control.get('type').value === EFormInputType.email && this.emailError) errors.push(this.emailError);
            if (err === 'required' && control.get('type').value !== EFormControlType.checkbox) errors.push('control_errors_required');
            if (err === 'required' && control.get('type').value === EFormControlType.checkbox)
                errors.push('control_errors_checkbox_required');
        });

        return errors;
    }

    public changeCheckBoxValue(control: AbstractControl): void {
        control.get('value').patchValue(!control.get('value').value);
    }

    public changeCheckRadioValue(control: AbstractControl, option: { label: string; selected: boolean; value: string }) {
        const opt = this.getControlValue(control, 'options').map((el) => {
            return {
                ...el,
                ...{ selected: option.label === el.label && option.value === el.value },
            };
        });
        control.get('options').patchValue(opt);
        control.get('value').patchValue(option.value);
    }

    public getPaymentElementLabel(paymentElementType: PaymentElement['type'], index: number): string {
        if (paymentElementType === EPaymentType.custom) {
            return this.paymentElements.at(index).customPaymentName;
        }

        return paymentsList[paymentElementType].label;
    }

    public changeCurrentPaymentMethodIndex(index: number): void {
        this.currentPaymentMethodIndex = index;

        if (this.isShowInvalidPaymentMethodError) {
            this.isShowInvalidPaymentMethodError = false;
        }
    }

    public changeSelectValue(control: AbstractControl, event) {
        const option = event.target.value;
        const opt = this.getControlValue(control, 'options').map((el) => {
            return {
                ...el,
                ...{ selected: option === el.value },
            };
        });
        control.get('options').patchValue(opt);
        control.get('value').patchValue(option);
    }

    public getPhoneErrorMsg() {
        return (document.getElementById('phone') as any).placeholder;
    }

    public getButtonEffectClass(button: FormButtonSettings): string {
        let nameEffect: string = '';

        if (button.additionalEffects?.isActive) {
            if (button.additionalEffects?.effectCondition === EButtonAdditionalEffectsCondition.hover) {
                switch (button.additionalEffects?.hoverEffects) {
                    case EButtonAdditionalHoverEffectsTypes.bounce:
                        nameEffect = EButtonAdditionalHoverEffectsTypes.bounce;
                        break;
                    case EButtonAdditionalHoverEffectsTypes.shake:
                        nameEffect = EButtonAdditionalHoverEffectsTypes.shake;
                        break;
                    case EButtonAdditionalHoverEffectsTypes.decrease:
                        nameEffect = EButtonAdditionalHoverEffectsTypes.decrease;
                        break;
                    case EButtonAdditionalHoverEffectsTypes.increase:
                        nameEffect = EButtonAdditionalHoverEffectsTypes.increase;
                        break;
                    default:
                        break;
                }
            }

            if (button.additionalEffects?.effectCondition === EButtonAdditionalEffectsCondition.static) {
                switch (button.additionalEffects?.staticEffects) {
                    case EButtonAdditionalStaticEffectsTypes.shine:
                        nameEffect = EButtonAdditionalStaticEffectsTypes.shine;
                        break;
                    case EButtonAdditionalStaticEffectsTypes.twinkle:
                        nameEffect = EButtonAdditionalStaticEffectsTypes.twinkle;
                        break;
                    default:
                        break;
                }
            }

            return 'button_effect_' + nameEffect;
        }

        return '';
    }

    private isAllDigitalProducts(): boolean {
        return !(JSON.parse(window.localStorage.getItem('cartStore')) as any).cartItems.some((item) => item.type === ProductType.physical);
    }

    public submitForm(): void {
        if (this.isShowInvalidPaymentMethodError) {
            return;
        }

        this.isShowSuccessMessage = false;

        if (
            this.formGroup.invalid ||
            (this.isAllDigitalProducts() ? false : this.deliveryVariant && this.deliveryVariant.get('delivery').value === null)
        ) {
            this.formGroup.markAllAsTouched();
            this.showErrorMessage = true;
            return;
        }

        this.formGroup.disable();

        return this.submitCartPaymentForm(this.prepareCartPaymentPayload());
    }

    private setPhoneEmailControlErrors(typeError: 'phone' | 'email') {
        this.formArrayControls.forEach((control) => {
            switch (typeError) {
                case 'email':
                    if (control.get('type').value === EFormInputType.email) {
                        control.get('value').setErrors({ email: true });
                    }
                    break;
                case 'phone':
                    if (control.get('type').value === EFormInputType.phone) {
                        control.get('value').setErrors({ phone: true });
                    }
                    break;
            }
        });
    }

    private submitCartPaymentForm(payload: any): void {
        if (this.isCustomPaymentType) window.localStorage.clear();

        this.shopCartHttpService
            .submitCartPayment(payload)
            .pipe(
                catchError((err) => {
                    this.formGroup.markAllAsTouched();
                    this.formGroup.enable();

                    if (err.status === 412) {
                        this.isShowInvalidPaymentMethodError = true;
                    } else if (err?.error?.errors) {
                        const errors = err.error.errors;
                        Object.keys(errors).forEach((key) => {
                            if (key.includes('phone')) this.setPhoneEmailControlErrors('phone');
                            if (key.includes('email')) this.setPhoneEmailControlErrors('email');
                            if (key.includes('paymentMethodId')) {
                                this.isShowInvalidPaymentMethodError = true;
                            }
                        });
                    }
                    this.showErrorMessage = true;
                    this.changeDetectorRef.detectChanges();

                    return of(null);
                }),
            )
            .subscribe((res) => {
                if (res === null) return;

                this.formGroup.enable();

                if (!res?.data?.url) {
                    this.isShowSuccessMessage = true;

                    if (payload?.backUrl) {
                        window.open(payload.backUrl, '_self');
                    } else {
                        if (this.isCustomPaymentType) setTimeout(() => location.reload(), 5000);
                    }
                }

                if (res?.data?.url) {
                    window.open(res.data.url, '_self');
                }

                if (this.widgetData?.analyticsSettings?.googleAnalytic) {
                    this.analyticsService.gaEventRequest(this.widgetData?.analyticsSettings?.googleSettings);
                }

                if (this.widgetData?.analyticsSettings?.pixelAnalytic) {
                    this.analyticsService.pixelFbEventRequest(this.widgetData?.analyticsSettings?.pixelSettings);
                }

                this.changeDetectorRef.detectChanges();
            });
    }

    public getButtonStyles(formButtonSettings: FormButtonSettings): string {
        let styles = `${DEFAULT_BUTTON_CLASS} ${BUTTON_SIZE_CLASS_MAP[formButtonSettings.size ? formButtonSettings.size : ESize.M]}`;

        if (formButtonSettings.styleType === EButtonStyleType.custom) {
            return `${styles} ${this.classes?.buttonStyles} ${this.getButtonEffectClass(formButtonSettings)}`;
        }

        return `${styles} ${BUTTON_TYPE_CLASS_MAP[formButtonSettings.styleType]} ${this.classes?.buttonStyles} ${this.getButtonEffectClass(
            formButtonSettings,
        )}`;
    }

    public getIcon(icon: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(icon);
    }

    public getWidgetId(widget) {
        return widget.displayId ? widget.displayId : `widget-${widget.id}`;
    }

    public getPaymentIconSrc(type: PaymentMethod['type']): string {
        return `${this.utilsService.formatImgSrc(`./assets/img/websites/payment-icons/sm/${paymentTypeNames[type]}-sm.svg`)}`;
    }

    public getInputIdForSelenium(control: AbstractControl): string {
        return `${this.widget.id}${control.value?.variable ? '-variable-' + control.value?.variable : ''}${
            control.value?.crmContactVariable ? '-crmContactVariable-' + control.value?.crmContactVariable : ''
        }${control.value?.crmDealVariable ? '-crmDealVariable-' + control.value?.crmDealVariable : ''}`;
    }

    public refreshErrorMessageValue() {
        this.showErrorMessage = !this.showErrorMessage;
    }

    public getSelected(options: Option[]) {
        return !options.some((el: Option) => el.selected);
    }

    public getControlValue(control: AbstractControl, controlName: string) {
        return control.get(controlName)?.value;
    }

    public formatImageURL(image: SelectedFileExtended): string {
        return 'https://' + image.defaultCdnUrl + image.url;
    }

    private getCountryISO() {
        this.countryISOService.getCountryISO().subscribe((data) => {
            this.CountryISO = data.country_code.toLowerCase();
            this.changeDetectorRef.detectChanges();
        });
    }

    private getDefaultValue(element: FormElement, options: Option[]): any {
        if (element.type === EFormControlType.checkbox) {
            return false;
        }

        if (element.type === EFormControlType.select) {
            return options?.find((el) => el.selected)?.value;
        }

        if (element.type === EFormControlType.radio) {
            return options?.find((el) => el.selected)?.value;
        }

        if (element.type === EFormInputType.hidden) {
            return element.value;
        }

        return '';
    }

    private getValidators(elem: FormElement): Validators[] {
        let validators: Validators[] = [];

        if (elem.type === EFormInputType.file) {
            return validators;
        }

        if (elem.required && elem.type === EFormControlType.radio && !elem.options.some((el) => el.selected)) {
            validators.push(Validators.required);
            return validators;
        }

        if (elem.required && elem.type !== EFormControlType.checkbox) {
            validators.push(Validators.required);
        }

        if (elem.type === EFormInputType.email) {
            validators.push(Validators.pattern(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/));
        }

        if (elem.type === EFormControlType.checkbox && elem.required) {
            validators.push(Validators.requiredTrue);
        }

        if (elem.type === EFormControlType.textarea && elem.required) {
            validators.push(Validators.required);
        }

        if (elem.type === EFormInputType.input || elem.type === EFormInputType.name) {
            validators.push(Validators.maxLength(128));
        }

        return validators;
    }

    private prepareCartPaymentPayload(): any {
        window.localStorage.setItem('cartId', getUuid());

        const paymentPayload: {
            cartId: any;
            userId: any;
            currency: any;
            paymentMethodId: any;
            paymentType: any;
            totalCost: any;
            name: any;
            isEditableTotalCost: boolean;
            backUrl: any;
            productId?: number;
            productCode?: string;
            paymentMethodName?: string;
        } = {
            cartId: window.localStorage.getItem('cartId'),
            userId: this.transferStateService.get('userId'),
            currency: null,
            paymentMethodId: null,
            paymentType: null,
            totalCost: null,
            name: null,
            isEditableTotalCost: false,
            backUrl: null,
            productId: null,
            productCode: null,
        };
        const payload: {
            pipelineId: any;
            dealName: any;
            pipelineStepId: any;
            elementId: any;
            email: any;
            phone?: any;
            addressBookId?: any;
            createAddressBook: any;
            variables: {};
            contactFields: {};
            contactVariables: {};
            dealVariables: {};
            utm: string;
            contactComments: any[];
            dealComments: any[];
            pageId: any;
            digitalProductIds?: string[];
        } = {
            pipelineId: null,
            dealName: null,
            pipelineStepId: null,
            elementId: null,
            email: null,
            createAddressBook: false,
            variables: {},
            contactFields: {},
            contactVariables: {},
            dealVariables: {},
            utm: this.utmTagsService.getUtmTags(),
            contactComments: [],
            dealComments: [],
            pageId: this.pageId,
            digitalProductIds: [],
        };

        (this.formGroup.value.formArray as PaymentFormElement[]).forEach((val) => {
            if (val.type === EFormInputType.email || val.type === EFormInputType.phone) {
                if (val.type === EFormInputType.email) payload.email = val.value;
                if (val.type === EFormInputType.phone) {
                    if (val.value?.e164Number) {
                        payload.phone = val.value?.e164Number;
                        payload.variables[EFormInputType.phone] = val.value?.e164Number;
                    } else {
                        payload.phone = val.value;
                        payload.variables[EFormInputType.phone] = val.value;
                    }
                }
                return;
            }

            if (val.type === EFormControlType.textarea) {
                if (val.crmCommentPlace === ECrmCommentPlace.crmAllComment) {
                    payload.contactComments.push(val.value);
                    payload.dealComments.push(val.value);
                }
                if (val.crmCommentPlace === ECrmCommentPlace.crmContactComment) {
                    payload.contactComments.push(val.value);
                }
                if (val.crmCommentPlace === ECrmCommentPlace.crmDealComment) {
                    payload.dealComments.push(val.value);
                }
            }

            if (val.value) {
                if (val.variable) payload.variables[val.variable] = val.value;
                if (val.crmContactVariable && val.crmContactVariable !== 'lastName' && val.crmContactVariable !== 'firstName') {
                    payload.contactVariables[val.crmContactVariable] = val.value;
                }
                if (val.crmContactVariable) payload.contactFields[val.crmContactVariable] = val.value;
                if (val.crmDealVariable) payload.dealVariables[val.crmDealVariable] = val.value;
            }
        });

        if (this.widgetData.selectedPipeline) payload.pipelineId = this.widgetData.selectedPipeline.id;
        if (this.widgetData.paymentFormName) payload.dealName = this.widgetData.paymentFormName;
        if (this.widgetData.selectedPipelineStep) payload.pipelineStepId = this.widgetData.selectedPipelineStep.id;
        if (this.widgetData.selectedAddressBook) payload.addressBookId = this.widgetData.selectedAddressBook;
        if (this.widget) payload.elementId = this.widget.id;
        payload.createAddressBook = this.widgetData.createAddressBook;

        const cartData = JSON.parse(window.localStorage.getItem('cartStore'));
        if (cartData.cartItems.length) {
            let deliveryInfo = '';

            if (this.deliveryVariants.length && !this.isAllDigitalProducts()) {
                deliveryInfo = `\nDelivery ${this.deliveryVariants[this.deliveryVariant.get('delivery').value].name} - ${
                    this.deliveryPrice
                } ${this.currency}`;
            }

            let cartItemsInfo =
                cartData.cartItems
                    .map((item) => {
                        if (item.type === ProductType.digital) {
                            payload.digitalProductIds.push(item.digitalProductId);
                        }

                        return `${item.name} ${item.selectedProperties.length ? `(${item.selectedProperties.join(',')})` : ''} * ${
                            item.selectedQuantity
                        } - ${item.price * item.selectedQuantity} ${
                            this.isCustomPaymentType ? this.currency : this.paymentElement.currency
                        }`;
                    })
                    .join('\n') +
                `\nPayment method - ${
                    this.isCustomPaymentType ? this.paymentElement.customPaymentName : EPaymentType[this.paymentElement.type]
                } ${deliveryInfo} \nTotal ${
                    this.deliveryVariants.length ? cartData.totalPrice + this.deliveryPrice : cartData.totalPrice
                } ${this.isCustomPaymentType ? this.currency : this.paymentElement.currency}`;

            payload.dealComments = [...payload.dealComments, cartItemsInfo];
        }

        if (this.paymentElement.currency) paymentPayload.currency = this.isCustomPaymentType ? this.currency : this.paymentElement.currency;
        if (this.paymentElement.payment_method_id) paymentPayload.paymentMethodId = this.paymentElement.payment_method_id;
        if (this.widgetData.additionalFormSettings.openLink) paymentPayload.backUrl = this.widgetData.additionalFormSettings.openLink;
        if (this.paymentElement.type) paymentPayload.paymentType = this.isCustomPaymentType ? null : this.paymentElement.type;
        paymentPayload.totalCost = this.deliveryVariants.length
            ? (cartData.totalPrice + this.deliveryPrice).toString()
            : cartData.totalPrice.toString();
        paymentPayload.paymentMethodName = this.paymentElement.customPaymentName;
        if (this.widgetData.paymentFormName) paymentPayload.name = this.widgetData.paymentFormName;
        if (this.paymentElement?.rro_isActive) {
            switch (this.paymentElement.type) {
                case EPaymentType.liqpay:
                    paymentPayload.productId = Number(this.paymentElement?.rro_info?.id);
                    break;
                case EPaymentType.monobank:
                    paymentPayload.productCode = this.paymentElement?.rro_info?.id.toString();
            }
        }
        if (this.isCustomPaymentType) {
            window.localStorage.clear();
            paymentPayload.paymentMethodName = this.paymentElement.customPaymentName;
            delete paymentPayload.paymentMethodId;
        }

        return { ...payload, ...paymentPayload };
    }

    public goToCardPage(item: ProductCard) {
        window.location.href = item.cardUrl;
    }

    public get deliveryPrice(): number {
        return this.deliveryVariant && this.deliveryVariant.get('delivery').value !== null
            ? this.deliveryVariants[this.deliveryVariant.get('delivery').value].price
            : 0;
    }

    public get elements(): FormElement[] {
        return this.widgetData.elements;
    }

    public get paymentElements(): PaymentFormWidgetData['paymentElements'] {
        return this.widgetData.paymentElements;
    }

    public get paymentFormName(): ShopCartWidgetData['paymentFormName'] {
        return this.widgetData.paymentFormName;
    }

    public get formArrayControls(): AbstractControl[] {
        return (this.formGroup.get('formArray') as FormArray).controls;
    }

    public get inputSettings(): FormInputSettings {
        return this.widgetData.inputSettings;
    }

    public get cartIconSettings(): ShopCartWidgetData['cartIconSettings'] {
        return this.widgetData.cartIconSettings;
    }

    public get formButtonSettings(): FormButtonSettings {
        return this.widgetData.formButtonSettings;
    }

    public get activePaymentElements(): PaymentElement[] {
        return this.widgetData.paymentElements.filter((el: PaymentElement) => el.isActive);
    }

    public get paymentElement(): PaymentFormWidgetData['paymentElements'][0] {
        return this.activePaymentElements[this.currentPaymentMethodIndex];
    }

    private get isCustomPaymentType(): boolean {
        return this.paymentElement.type === EPaymentType.custom;
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
