import { Directive, ElementRef, HostListener, inject, Input, Renderer2 } from '@angular/core';

type Position = 'top' | 'bottom' | 'left' | 'right';

@Directive({
    selector: '[spTooltip]',
    standalone: true,
})
export class TooltipDirective {
    @Input('spTooltip') tooltipText = '';
    @Input() position?: Position;

    private readonly el: ElementRef = inject(ElementRef);
    private readonly renderer: Renderer2 = inject(Renderer2);

    private tooltip: HTMLElement | null = null;
    private readonly offset = 5;
    private readonly viewportPadding = 5;

    @HostListener('mouseenter') onMouseEnter() {
        if (!this.tooltip) {
            this.show();
        }
    }

    @HostListener('mouseleave') onMouseLeave() {
        if (this.tooltip) {
            this.hide();
        }
    }

    @HostListener('window:scroll', ['$event'])
    @HostListener('window:resize', ['$event'])
    onWindowChange() {
        if (this.tooltip) {
            this.updatePosition();
        }
    }

    private getViewportRect(): DOMRect {
        return {
            top: this.viewportPadding,
            left: this.viewportPadding,
            right: window.innerWidth - this.viewportPadding,
            bottom: window.innerHeight - this.viewportPadding,
            width: window.innerWidth - this.viewportPadding * 2,
            height: window.innerHeight - this.viewportPadding * 2,
        } as DOMRect;
    }

    private show() {
        this.tooltip = this.renderer.createElement('div');
        this.renderer.appendChild(document.body, this.tooltip);

        // Add tooltip content
        this.renderer.setProperty(this.tooltip, 'innerHTML', this.tooltipText);

        // Add styling
        this.setStyle();

        // Position the tooltip
        this.updatePosition();
    }

    private hide() {
        if (this.tooltip) {
            this.renderer.removeChild(document.body, this.tooltip);
            this.tooltip = null;
        }
    }

    private setStyle() {
        if (!this.tooltip || !this.tooltipText) return;

        const styles = {
            padding: '10px',
            'background-color': '#333',
            color: '#fff',
            'border-radius': '6px',
            'font-size': '12px',
            opacity: 0,
            position: 'fixed',
            'z-index': '10000',
            'text-wrap': 'nowrap',
            transition: 'opacity 0.3s ease-in-out',
            'white-space': 'nowrap',
        };

        Object.entries(styles).forEach(([key, value]) => {
            this.renderer.setStyle(this.tooltip, key, value);
        });

        const styleTag = this.renderer.createElement('style');
        const tooltipId = `tooltip-${Math.random().toString(36).substring(2, 11)}`;
        this.renderer.setAttribute(this.tooltip, 'id', tooltipId);

        const css = `
                #${tooltipId}::before {
                    content: '';
                    position: absolute;
                    border: 7px solid transparent;
                }

                #${tooltipId}.tooltip-top::before {
                    border-top-color: #333;
                    border-bottom: 0;
                    bottom: -7px;
                    left: 50%;
                    transform: translateX(-50%);
                }

                #${tooltipId}.tooltip-bottom::before {
                    border-bottom-color: #333;
                    border-top: 0;
                    top: -7px;
                    left: 50%;
                    transform: translateX(-50%);
                }

                #${tooltipId}.tooltip-left::before {
                    border-left-color: #333;
                    border-right: 0;
                    right: -7px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                #${tooltipId}.tooltip-right::before {
                    border-right-color: #333;
                    border-left: 0;
                    left: -7px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            `;

        this.renderer.setProperty(styleTag, 'textContent', css);
        this.renderer.appendChild(document.head, styleTag);
    }

    private updatePosition() {
        if (!this.tooltip || !this.tooltipText) return;

        const hostRect = this.el.nativeElement.getBoundingClientRect();
        const tooltipRect = this.tooltip.getBoundingClientRect();
        const viewportRect = this.getViewportRect();

        // Calculate available spaces
        const spaces = {
            top: hostRect.top - viewportRect.top,
            bottom: viewportRect.bottom - hostRect.bottom,
            left: hostRect.left - viewportRect.left,
            right: viewportRect.right - hostRect.right,
        };

        // Initial position (preferred or calculated)
        let position = this.position || 'top';

        if (!this.position) {
            // Check if tooltip fits in preferred position
            const initialPlacement = this.getPlacement(position, hostRect, tooltipRect);
            if (!this.fitsInViewport(initialPlacement, tooltipRect, viewportRect)) {
                // Try flipping the position
                const flippedPosition = this.getFlippedPosition(position);
                const flippedPlacement = this.getPlacement(flippedPosition, hostRect, tooltipRect);

                if (this.fitsInViewport(flippedPlacement, tooltipRect, viewportRect)) {
                    position = flippedPosition;
                } else {
                    // If flipping doesn't work, try shifting
                    position = this.findBestFittingPosition(hostRect, tooltipRect, viewportRect, spaces);
                }
            }
        }

        this.setPosition(position, hostRect, tooltipRect);
    }

    private getPlacement(position: Position, hostRect: DOMRect, tooltipRect: DOMRect) {
        let top: number, left: number;

        switch (position) {
            case 'top':
                top = hostRect.top - tooltipRect.height - this.offset;
                left = hostRect.left + (hostRect.width - tooltipRect.width) / 2;
                break;
            case 'bottom':
                top = hostRect.bottom + this.offset;
                left = hostRect.left + (hostRect.width - tooltipRect.width) / 2;
                break;
            case 'left':
                top = hostRect.top + (hostRect.height - tooltipRect.height) / 2;
                left = hostRect.left - tooltipRect.width - this.offset;
                break;
            case 'right':
                top = hostRect.top + (hostRect.height - tooltipRect.height) / 2;
                left = hostRect.right + this.offset;
                break;
        }

        return { top, left };
    }

    private getFlippedPosition(position: Position): Position {
        switch (position) {
            case 'top':
                return 'bottom';
            case 'bottom':
                return 'top';
            case 'left':
                return 'right';
            case 'right':
                return 'left';
        }
    }

    private fitsInViewport(placement: { top: number; left: number }, tooltipRect: DOMRect, viewportRect: DOMRect): boolean {
        return (
            placement.top >= viewportRect.top &&
            placement.top + tooltipRect.height <= viewportRect.bottom &&
            placement.left >= viewportRect.left &&
            placement.left + tooltipRect.width <= viewportRect.right
        );
    }

    private findBestFittingPosition(
        hostRect: DOMRect,
        tooltipRect: DOMRect,
        viewportRect: DOMRect,
        spaces: Record<Position, number>,
    ): Position {
        const positions: Position[] = ['top', 'right', 'bottom', 'left'];

        // Try each position and return the first one that fits
        for (const position of positions) {
            const placement = this.getPlacement(position, hostRect, tooltipRect);
            if (this.fitsInViewport(placement, tooltipRect, viewportRect)) {
                return position;
            }
        }

        // If no position fits perfectly, return the one with the most space.
        // This approach ensures that the tooltip is displayed in the position
        // where it has the maximum available space, minimizing the chance of
        // being cut off or overlapping with other elements.
        const maxSpace = Math.max(...Object.values(spaces));
        return (Object.entries(spaces).find(([, space]) => space === maxSpace)?.[0] as Position) || 'top';
    }

    private setPosition(position: Position, hostRect: DOMRect, tooltipRect: DOMRect) {
        if (!this.tooltip || !this.tooltipText) return;

        // Remove any existing position classes
        ['top', 'bottom', 'left', 'right'].forEach((pos) => {
            this.renderer.removeClass(this.tooltip, `tooltip-${pos}`);
        });

        const placement = this.getPlacement(position, hostRect, tooltipRect);

        // Add new position class
        this.renderer.addClass(this.tooltip, `tooltip-${position}`);

        // Set position
        this.renderer.setStyle(this.tooltip, 'top', `${placement.top}px`);
        this.renderer.setStyle(this.tooltip, 'left', `${placement.left}px`);

        // Show tooltip
        setTimeout(() => {
            if (this.tooltip) {
                this.renderer.setStyle(this.tooltip, 'opacity', '1');
            }
        }, 0);
    }
}
