import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, ViewChild } from '@angular/core';
import { SwiperComponent, SwiperModule } from 'swiper/angular';
import { LightgalleryModule } from 'lightgallery/angular';
import { type InitDetail } from 'lightgallery/lg-events';
import { type LightGallery } from 'lightgallery/lightgallery';
import { type LightGallerySettings } from 'lightgallery/lg-settings';

import Swiper, { Autoplay, EffectCards, EffectFade, FreeMode, Navigation, Pagination, type SwiperOptions, Thumbs } from 'swiper';

import { EGalleryTypes } from '@common/enums';
import { type SelectedFileExtended } from '@common/types/file.type';

import { AbstractWidgetComponent } from '@web-builder/widgets/abstract-widget.component';
import { BLANK_IMAGE } from '@web/widgets/image/constants/image.consts';
import { type GalleryWidgetData } from '@web/widgets/gallery';
import { getStyles } from '@web/widgets/gallery/components/gallery-view/gallery-view.component.styles';
import { type CarouselOptions, type CollageOptions } from '@web/widgets/gallery/type/gallery-widget.type';
import { SpDirectivesModule } from '@common/directives/directives.module';
import { setChatAt } from '@common/helpers/string';
import { environment } from '@web-builder-env/environment';
import { HideSwiperOnSsrDirective } from '@web-builder/core/directives/swiper-ssr.directive';

Swiper.use([Autoplay, Pagination, Thumbs, Navigation, EffectCards, EffectFade, FreeMode]);

@Component({
    selector: 'web-builder-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, SwiperModule, LightgalleryModule, SpDirectivesModule, HideSwiperOnSsrDirective],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class GalleryComponent extends AbstractWidgetComponent<GalleryWidgetData> implements AfterViewInit {
    @ViewChild('swiperComponent') swiperComponent: SwiperComponent;
    @ViewChild('thumbsSwiperComponent') thumbsSwiperComponent: SwiperComponent;

    protected getStyles = getStyles;
    public lightGallery!: LightGallery;
    public BLANK_IMAGE = this.formatImgSrc(BLANK_IMAGE);

    public imagesSrcSets = [];

    thumbsSwiper: Swiper;
    swiperConfig: SwiperOptions = {};
    thumbsParentConfig: SwiperOptions = {};

    ngAfterViewInit() {
        if (this.isCarousel && this.isBrowser) {
            if (this.swiperComponent) {
                this.swiperComponent.swiperRef.autoplay.running = this.carouselOptions.autoplay;
            }

            if (this.thumbsSwiperComponent) {
                this.thumbsSwiperComponent.swiperRef.autoplay.running = this.carouselOptions.autoplay;
            }
        }
    }

    public instanceId = (index: number): number => index;

    protected widgetInit() {
        this.imagesSrcSets = this.images.map((image) => {
            if (!image?.hasResize) {
                return null;
            }

            const srcSets = [
                {
                    size: 720,
                    media: '(max-width: 720px)',
                },
                {
                    size: 1080,
                    media: '(max-width: 1080px)',
                },
                {
                    size: 1920,
                    media: '(min-width: 1081px)',
                },
            ];

            const splitImgSrc = this.formatImageURL(image).split('/');
            splitImgSrc.splice(4, 0, 'resize_landing');
            const extensionDotIndex = splitImgSrc[splitImgSrc.length - 1].lastIndexOf('.');
            splitImgSrc[splitImgSrc.length - 1] = setChatAt(splitImgSrc[splitImgSrc.length - 1], extensionDotIndex, '_');

            const res = splitImgSrc.join('/');

            return srcSets.map((srcset) => {
                if (srcset.size >= image.imageWidth || image.extension.includes('svg')) {
                    if (image?.hasSourceResize) {
                        return { ...srcset, url: `${res}/source.webp` };
                    }

                    return { ...srcset, url: this.formatImageURL(image) };
                }

                return { ...srcset, url: `${res}/${srcset.size}.webp` };
            });
        });
    }

    public get images(): SelectedFileExtended[] {
        return this.widgetData.images;
    }

    public get gallerySettings(): LightGallerySettings {
        return {
            dynamic: true,
            dynamicEl: this.widgetData.images.map((image) => ({
                src: this.formatImageURL(image),
                thumb: this.formatImageURL(image),
            })),
            download: false,
        };
    }

    public get isMasonry(): boolean {
        if ('style' in this.widgetData.options) {
            return ['masonry', '2col-2row'].includes(this.widgetData.options.style);
        }
        return false;
    }
    public get isCarousel(): boolean {
        return this.widgetData.type === EGalleryTypes.carousel;
    }

    public get carouselOptions(): CarouselOptions {
        return this.widgetData.options as CarouselOptions;
    }

    public get loopedSlides(): number {
        return this.widgetData.images.length === this.carouselOptions.rowItems
            ? this.widgetData.images.length
            : this.widgetData.images.length - 1;
    }

    public get autoplay() {
        if (!this.carouselOptions.autoplay) return false;

        return {
            delay: 2000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        };
    }

    public get masonryArrays(): SelectedFileExtended[][] {
        const images = [...this.widgetData.images];
        const style = (this.widgetData.options as CollageOptions).style;

        let columns = 3;
        if (style === '2col-2row') {
            columns = 2;
        }

        let arr = [...Array(columns)].map(() => []);

        for (let i = 0; i < images.length; i++) {
            arr[i % columns].push(images[i]);
        }
        return arr;
    }

    public setThumbs(swiper: Swiper): void {
        this.thumbsSwiper = swiper;
        this.thumbsParentConfig = {
            thumbs: {
                swiper: swiper,
            },
        };

        this.changeDetectorRef.detectChanges();
    }

    public init = (detail: InitDetail) => {
        this.lightGallery = detail.instance;
    };

    public formatImageURL(image: SelectedFileExtended): string {
        return environment.fileManagerCdnUrl + image.url;
    }

    public openGallery(index: number) {
        if (!this.widgetData.options.clickable) return;

        this.lightGallery.openGallery(index);
    }

    public openMasonryGallery(columnIndex: number, imageIndex: number) {
        const columns = (this.widgetData.options as CollageOptions).style === '2col-2row' ? 2 : 3;

        let normalizedIndex = columnIndex + columns * imageIndex;
        this.openGallery(normalizedIndex);
    }

    public isHasBottomSpace(): boolean {
        if (this.widgetData.images.length <= this.carouselOptions.rowItems) {
            return false;
        }

        return true;
    }

    public imgIndex(columnIndex: number, imageIndex: number): number {
        return imageIndex * this.masonryArrays.length + columnIndex;
    }

    public link(image: SelectedFileExtended): string {
        const trimmedLink = image?.link?.trim();

        if (!trimmedLink) {
            return null;
        }

        return this.linkFormatterService.formatLink(trimmedLink);
    }

    public overlayImageSrc(image: SelectedFileExtended): string | boolean {
        if (!image.overlayImage) {
            return false;
        }
        return `${environment.fileManagerCdnUrl}${image.overlayImage.url}`;
    }

    public mouseImgIn(event: MouseEvent): void {
        const target = event.target as HTMLElement;

        const parent = target.closest('.sp-image-root');

        if (parent) {
            const overlayImage = parent.querySelector('.overlayImage') as HTMLElement;
            const image = parent.querySelector('.sp-gallery-image') as HTMLElement;

            if (overlayImage && image) {
                image.style.opacity = '0';
                overlayImage.style.opacity = '1';
            }
        }
    }

    public mouseImgOut(event: MouseEvent): void {
        const target = event.target as HTMLElement;

        const parent = target.closest('.sp-image-root');

        if (parent) {
            const overlayImage = parent.querySelector('.overlayImage') as HTMLElement;
            const image = parent.querySelector('.sp-gallery-image') as HTMLElement;

            if (overlayImage && image) {
                image.style.opacity = '1';
                overlayImage.style.opacity = '0';
            }
        }
    }

    public getMaskUrl(image: SelectedFileExtended): string {
        const src = this.formatImgSrc(`./assets/img/websites/mask-shapes/${image?.maskShape}.svg`);
        return 'url(' + src + ')';
    }
}
