import type { OnInit } from '@angular/core';

import { CommonModule, DOCUMENT } from '@angular/common';
import type { CodeWidgetData } from '@web/widgets/code';
import { ChangeDetectionStrategy, Component, ElementRef, inject, Renderer2 } from '@angular/core';

import { getStyles } from '@web/widgets/code/components/code-view/code-view.component.styles';

import { TranslateModule } from '@ngx-translate/core';
import { AbstractWidgetComponent } from '@web-builder/widgets/abstract-widget.component';

@Component({
    selector: 'web-builder-code',
    templateUrl: './code.component.html',
    styleUrls: ['./code.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, TranslateModule],
})
export class CodeComponent extends AbstractWidgetComponent<CodeWidgetData> implements OnInit {
    private readonly document = inject(DOCUMENT);
    private readonly elementRef: ElementRef<HTMLElement> = inject(ElementRef);
    private readonly renderer = inject(Renderer2);

    protected getStyles = getStyles;

    public ngOnInit(): void {
        super.ngOnInit();
        if (this.isServer || this.isPreview) {
            return;
        }

        this.elementRef.nativeElement.innerHTML = this.widgetData.content;

        const scripts: HTMLCollectionOf<HTMLScriptElement> = this.elementRef.nativeElement.getElementsByTagName('script');

        Array.from(scripts).forEach((script) => {
            const scriptCopy = this.renderer.createElement('script');
            scriptCopy.type = script.type ? script.type : 'text/javascript';
            if (script.innerHTML) {
                scriptCopy.innerHTML = script.innerHTML;
            } else if (script.src) {
                scriptCopy.src = script.src;
            }

            Array.from(script.attributes).forEach((attr) => {
                scriptCopy.setAttribute(attr.name, attr.value);
            });

            script.parentNode.replaceChild(scriptCopy, script);
        });
    }
}
