export const HEX_COLOR: RegExp = /^#([0-9A-F]{6}|[0-9A-F]{3})$/i;

export const COLOR: RegExp = /(#([\da-f]{3}){1,2}|(rgb|hsl)a\((\d{1,3}%?,\s?){3}(1|0?\.\d+)\)|(rgb|hsl)\(\d{1,3}%?(,\s?\d{1,3}%?){2}\))/gi;

export const URL: RegExp = /^(https?:\/\/|mailto:|ftp:|tel:|file:\/\/\/)[-a-zA-Z0-9@:%._\+~#=]{1,256}(\.[a-zA-Z0-9()]{1,6})?\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;

export const PHONE: RegExp =
    /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;

export const YOUTUBE_LINKS: RegExp = /(?:youtu\.be\/|youtube\.com\/(?:embed\/|watch\?v=|v\/|shorts\/|live\/)|youtu\.be\/)([^\?\&\s]+)/;

export const VIMEO_LINKS: RegExp =
    /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/;

export const FILE_EXTENSION: RegExp = /\.[^/.]+$/;

export const RELATIVE_AND_ABSOLUTE_URL: RegExp =
    /(^(\/)\w+\.[^\s]+$|^\/[^\s]*$)|(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))/;
