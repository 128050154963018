import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, inject, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';

import { getStyles } from '@web/widgets/product-card/components/product-card-view/product-card-view.component.styles';

import { AbstractWidgetComponent } from '@web-builder/widgets/abstract-widget.component';
import { CardViewType, type ProductCardWidgetData } from '@web/widgets/product-card/type/product-card-widget.type';
import { PreviewViewCardComponent } from '@web-builder/widgets/product-card/preview-view-card/preview-view-card.component';
import { OpenViewCardComponent } from '@web-builder/widgets/product-card/open-view-card/open-view-card.component';
import { AnalyticsService } from '@web-builder/core/services/analytics.service';
import { EPixelTypes, PixelSettings } from '@web/shared/components/web-analytics-settings/web-analytics-setings.type';

@Component({
    selector: 'web-builder-product-card',
    standalone: true,
    imports: [CommonModule, PreviewViewCardComponent, OpenViewCardComponent],
    templateUrl: './product-card.component.html',
    styleUrls: ['./product-card.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCardComponent extends AbstractWidgetComponent<ProductCardWidgetData> implements AfterViewInit, OnDestroy {
    private readonly analyticsService: AnalyticsService = inject(AnalyticsService);
    private readonly elementRef: ElementRef<HTMLElement> = inject(ElementRef);
    private observer!: IntersectionObserver;

    protected getStyles = getStyles;
    public readonly currency: string = this.transferStateService.get('site')?.settings?.shop?.currency;

    ngAfterViewInit() {
        this.initIntersectionObserver();
    }

    public get cardViewType(): ProductCardWidgetData['cardViewType'] {
        return this.widgetData.cardViewType;
    }

    public get isOpenCardView(): boolean {
        return this.cardViewType === CardViewType.open;
    }

    public get isModalCardView(): boolean {
        return this.cardViewType === CardViewType.modal;
    }

    public get displayId(): string {
        return this.widget.displayId;
    }

    public get widgetId(): string {
        return this.widget.id;
    }

    sendAnalyticsEvent() {
        const settings: PixelSettings = {
            type: EPixelTypes.viewContent,
            currency: this.currency,
            value: this.widgetData?.element?.price?.toString() ?? null,
            content_ids: this.widgetData?.element?.id,
            content_name: this.widgetData?.element?.name,
            content_type: this.widgetData?.element?.catalogCategoryName ? this.widgetData?.element?.catalogCategoryName : 'product_widget',
        };

        this.analyticsService.pixelFbEventRequest(settings);
    }

    private initIntersectionObserver() {
        if (!this.isPreview && this.platformService.isPlatformBrowser()) {
            this.observer = new IntersectionObserver(
                (entries) => {
                    for (const entry of entries) {
                        if (entry.isIntersecting) {
                            this.sendAnalyticsEvent();
                            this.observer.disconnect();
                            break;
                        }
                    }
                },
                {
                    root: null,
                    threshold: 0.1,
                },
            );

            this.observer.observe(this.elementRef.nativeElement);
        }
    }

    ngOnDestroy() {
        if (this.observer) {
            this.observer.disconnect();
        }
    }
}
