import type { JssStyle } from 'jss';
import type { WebWidgetEntity } from '@web/types';
import type { ButtonData, ButtonWidgetData } from '@web/widgets/button/type/button-widget.type';

import { EButtonStyleType, EButtonWidthType, EHorizontalPosition, ESize } from '@common/enums';

import { getBaseButtonData } from '@web/widgets/common/base/base-button/base-button.styles';

function getButtonGroupStyles({ data }: WebWidgetEntity<ButtonWidgetData>, isConstructor: boolean = false): JssStyle {
    const justifyStyles = {
        [EHorizontalPosition.L]: 'flex-start',
        [EHorizontalPosition.M]: 'center',
        [EHorizontalPosition.R]: 'flex-end',
    };

    const containerInnerMargin = {
        [ESize.S]: '-5px',
        [ESize.M]: '-10px',
        [ESize.L]: '-15px',
    };

    const buttonOuterMargin = {
        [ESize.S]: '5px',
        [ESize.M]: '10px',
        [ESize.L]: '15px',
    };

    let horizontalAlignValue: string;

    if (data.mobileHorizontalAlign) {
        horizontalAlignValue = justifyStyles[data.mobileHorizontalAlign];
    } else {
        horizontalAlignValue = justifyStyles[data.horizontalAlign];
    }

    const mobileMediaQueryKey = '@media (max-width: 992px)';
    const desktopMediaQueryKey = '@media (min-width: 993px)';
    const mobileConstructorSelector = '&.mobileWidgetViewWrap';
    const styles: any = {
        zIndex: data?.position?.zIndex,
        position: 'relative',
        transform: `rotate(${data?.position?.rotate ?? 0}deg)`,
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        margin: containerInnerMargin[data.horizontalIndent],
        justifyContent: justifyStyles[data.horizontalAlign],
        '& a': {
            margin: buttonOuterMargin[data.horizontalIndent],
        },
        [mobileMediaQueryKey]: {
            justifyContent: horizontalAlignValue,
            transform: `rotate(${data?.position?.mobileRotate ?? 0}deg)`,
        },
        [mobileConstructorSelector]: {
            justifyContent: horizontalAlignValue,
        },
    };

    if (!isConstructor) {
        if (data?.hideOnMobile && !data?.hideOnDesktop) {
            styles[mobileMediaQueryKey] = { display: 'none' };
        }

        if (!data?.hideOnMobile && data?.hideOnDesktop) {
            styles[desktopMediaQueryKey] = { display: 'none' };
        }

        if (data?.hideOnMobile && data?.hideOnDesktop) {
            styles.display = 'none';
        }
    }

    if (isConstructor) {
        styles[mobileConstructorSelector].transform = `rotate(${data?.position?.mobileRotate ?? 0}deg)`;
    }

    return styles;
}

export function getStyles(widget: WebWidgetEntity<ButtonWidgetData>, { isConstructor }: any) {
    const { data } = widget;

    const buttonsStyles = data.buttons.reduce((result: Record<string, JssStyle>, button: ButtonData, index: number) => {
        const widthType = button.widthType || EButtonWidthType.auto;

        if (button.styleType === EButtonStyleType.custom) {
            result['button_' + index] = {
                width: widthType !== EButtonWidthType.auto ? '100%' : 'auto',
                maxWidth: widthType === EButtonWidthType.fixed ? `${button.widthValue}px` : 'auto',
                flexDirection: button.buttonIcon?.position === EHorizontalPosition.L ? 'row' : 'row-reverse',
                ...getBaseButtonData(button.buttonBase, button.buttonHover),
            };
        }

        result['button_width_' + index] = {
            width: widthType !== EButtonWidthType.auto ? '100%' : 'auto',
            maxWidth: widthType === EButtonWidthType.fixed ? `${button.widthValue}px` : 'auto',
            flexDirection: button.buttonIcon?.position === EHorizontalPosition.L ? 'row' : 'row-reverse',
        };

        return result;
    }, {});

    return {
        ...buttonsStyles,
        'button-group': getButtonGroupStyles(widget, isConstructor),
    };
}
