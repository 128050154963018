<div class="map">
    <google-map
        *ngIf="mapsApiLoaded && isBrowser"
        [options]="mapOptions"
        [height]="null"
        [width]="null"
        [center]="widgetData.tempCenter || widgetData.center"
        [zoom]="widgetData.zoom"
        (mapInitialized)="openWindow($event)"
    >
        <map-marker
            #markerRef="mapMarker"
            *ngFor="let marker of widgetData.addresses"
            [position]="marker.position"
            [title]="marker.title"
            (mapClick)="openInfo(window, markerRef)"
        >
            <map-info-window #window="mapInfoWindow" class="sp-ui-text">
                <h3>{{ marker.title }}</h3>
                <p>
                    {{ marker.description }} <br *ngIf="marker.description" />
                    {{ marker.address }}
                </p>
            </map-info-window>
        </map-marker>
    </google-map>
</div>
