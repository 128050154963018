import type { OnInit } from '@angular/core';
import type { Classes } from 'jss';

import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';

import { ServerStylesService } from '@common/services/server-styles.service';
import { PlatformService } from '@common/services/platform.service';

import { SpecificSectionStructureEntity, SpecificBlockStructureEntity } from '@web/types';
import { getStyles } from '@web/layout/content/page-content/components/sections/specific-section-wrapper/specific-section-wrapper.component.styles';
import { environment } from '@web-builder-env/environment';

@Component({
    selector: 'web-builder-specific-section-wrapper',
    templateUrl: './specific-section-wrapper.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class SpecificSectionWrapperComponent implements OnInit {
    @Input() public section: SpecificSectionStructureEntity;
    @Input() public block: SpecificBlockStructureEntity;
    @Input() public index: number;
    @Input() public isMobileContainerSection: boolean;

    public classes: Classes;

    public isBrowser: boolean = this.platformService.isPlatformBrowser();

    constructor(
        private readonly stylesService: ServerStylesService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly platformService: PlatformService,
    ) {}

    public ngOnInit(): void {
        const styles = getStyles(this.section, this.block, false, environment.fileManagerCdnUrl);

        this.classes = this.stylesService.getStyles(this.section.id, styles);

        this.changeDetectorRef.detectChanges();
    }

    public get sectionId(): string {
        return `section-${this.section.id}`;
    }
}
