import type { JssStyle } from 'jss';
import type { WebWidgetEntity } from '@web/types';
import type { NavigationWidgetData } from '@web/widgets/navigation/type/navigation-widget.type';

import { websiteColorCss } from '@libs/themes';

import { EFontStyle, EHorizontalPosition, EMenuLayoutType, ESize } from '@common/enums';
import { EffectTypes } from '@web/shared/components/web-hover-effects/types';

const DEFAULT_HOVER_EFFECTS = {
    type: EffectTypes.transparency,
    transparency: 80,
    lineWidth: 1,
    color: '#000',
    lineType: 'solid',
    animationType: 'moving_up',
};

function getAlignMenuList(elementData: NavigationWidgetData): JssStyle {
    switch (elementData.horizontalPosition) {
        case EHorizontalPosition.L:
            return { justifyContent: 'flex-start' };
        case EHorizontalPosition.M:
            return { justifyContent: 'center' };
        case EHorizontalPosition.R:
            return { justifyContent: 'flex-end' };
        default:
            return { justifyContent: 'normal' };
    }
}

function getMenuListMargin(elementData: NavigationWidgetData): JssStyle {
    if (elementData.layoutDirection === EMenuLayoutType.HORIZONTAL) {
        switch (elementData?.menuItemIndent) {
            case ESize.M:
                return { margin: '0 -15px' };
            case ESize.L:
                return { margin: '0 -20px' };
            case ESize.S:
            default:
                return { margin: '0 -10px' };
        }
    } else {
        switch (elementData?.menuItemIndent) {
            case ESize.M:
                return { marginTop: '-15px', marginRight: '-15px', marginBottom: '-15px', marginLeft: '-15px' };
            case ESize.L:
                return { marginTop: '-20px', marginRight: '-20px', marginBottom: '-20px', marginLeft: '-20px' };
            case ESize.S:
            default:
                return { marginTop: '-10px', marginRight: '-10px', marginBottom: '-10px', marginLeft: '-10px' };
        }
    }
}

function getItemsIndent(elementData: NavigationWidgetData): JssStyle {
    if (elementData.layoutDirection === EMenuLayoutType.HORIZONTAL) {
        switch (elementData?.menuItemIndent) {
            case ESize.S:
                return { padding: '10px' };
            case ESize.M:
                return { padding: '10px 15px' };
            case ESize.L:
                return { padding: '10px 20px' };
            default:
                return { padding: '10px' };
        }
    } else {
        switch (elementData?.menuItemIndent) {
            case ESize.S:
                return { padding: '10px' };
            case ESize.M:
                return { padding: '15px' };
            case ESize.L:
                return { padding: '20px' };
            default:
                return { padding: '10px' };
        }
    }
}

function getItemsAlign(elementData: NavigationWidgetData): JssStyle {
    switch (elementData.horizontalPosition) {
        case EHorizontalPosition.L:
            return { textAlign: 'left' };
        case EHorizontalPosition.M:
            return { textAlign: 'center' };
        case EHorizontalPosition.R:
            return { textAlign: 'right' };
        default:
            return { textAlign: 'center' };
    }
}

function getAlignSubmenuList(elementData: NavigationWidgetData): JssStyle {
    switch (elementData.horizontalPosition) {
        case EHorizontalPosition.L:
            return { left: '0', right: 'auto' };
        case EHorizontalPosition.M:
            return { left: '0', right: 'auto' };
        case EHorizontalPosition.R:
            return { left: 'auto', right: '0' };
        default:
            return { left: '0', right: 'auto' };
    }
}

function getDirection(elementData: NavigationWidgetData): JssStyle {
    switch (elementData.layoutDirection) {
        case EMenuLayoutType.HORIZONTAL:
            return { flexDirection: 'row' };
        case EMenuLayoutType.VERTICAL:
            return { flexDirection: 'column' };
        default:
            return { justifyContent: 'initial' };
    }
}

function getFontStyles({ fontStyle }: NavigationWidgetData): JssStyle {
    return {
        fontWeight: fontStyle.includes(EFontStyle.B) ? 'bold' : 'initial',
        fontStyle: fontStyle.includes(EFontStyle.I) ? 'italic' : 'initial',
        textTransform: fontStyle.includes(EFontStyle.AT) ? 'uppercase' : 'initial',
    };
}

function getSubmenuFontStyles({ submenuFontStyle }: NavigationWidgetData): JssStyle {
    if (!submenuFontStyle) {
        return ;
    } else {
        return {
            fontWeight: submenuFontStyle.includes(EFontStyle.B) ? 'bold' : 'initial',
            fontStyle: submenuFontStyle.includes(EFontStyle.I) ? 'italic' : 'initial',
            textTransform: submenuFontStyle.includes(EFontStyle.AT) ? 'uppercase' : 'initial',
        };
    }
}

function getHoverEffectsStyles({ hoverEffects }: NavigationWidgetData): JssStyle {
    switch (hoverEffects.type) {
        case EffectTypes.color:
            return {
                color: websiteColorCss(hoverEffects.color),
            };
        case EffectTypes.underline:
            return {
                '&:after': {
                    opacity: 1,
                },
            };
        case EffectTypes.transparency:
            return {
                opacity: hoverEffects.transparency / 100,
            };
        default:
            return {};
    }
}

function getPseudoUnderline({ hoverEffects }: NavigationWidgetData): JssStyle {
    if (hoverEffects.type === EffectTypes.underline) {
        return {
            '&:after': {
                content: '""',
                position: 'absolute',
                bottom: '-12px',
                right: 0,
                left: 0,
                height: '1px',
                borderBottom: `${hoverEffects.lineWidth}px ${hoverEffects.lineType} ${websiteColorCss(hoverEffects.color)}`,
                transition: '0.2s ease-in-out',
                opacity: 0,
            },
        };
    }

    return {};
}

export function getStyles({ data }: WebWidgetEntity<NavigationWidgetData>): JssStyle {
    return {
        'menu-wrapper': {
            display: 'flex',
            alignItems: 'center',
        },
        'menu-logo': {
            maxWidth: data.logoWidth + 'px',
        },
        'menu-list-wrapper': {
            display: 'flex',
            width: '100%',
            ...getAlignMenuList(data),
        },
        'menu-list': {
            paddingLeft: 0,
            marginBottom: 0,
            listStyleType: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            ...getDirection(data),
            ...getAlignMenuList(data),
            ...getMenuListMargin(data),
            '& li': {
                position: 'relative',
                '& span': {
                    ...getItemsIndent(data),
                    ...getItemsAlign(data),
                    display: 'block',
                    '& a': {
                        fontSize: `${data.fontSize}px`,
                        color: websiteColorCss(data.textColor),
                        position: 'relative',
                        transition: '0.2s ease-in-out',
                        ...getFontStyles(data),
                        '&:hover': {
                            ...getHoverEffectsStyles({
                                ...data,
                                hoverEffects: data.hoverEffects ?? DEFAULT_HOVER_EFFECTS,
                            }),
                            textDecoration: 'none',
                        },
                        ...getPseudoUnderline({
                            ...data,
                            hoverEffects: data.hoverEffects ?? DEFAULT_HOVER_EFFECTS,
                        }),
                        '& .caret-icon': {
                            display: 'inline-block',
                            marginLeft: '4px',
                        },
                    },
                    '& .caret-icon-mobile': {
                        display: 'none',
                        padding: '0px 6px',
                        '&:hover': {
                            cursor: 'pointer',
                            opacity: '0.8',
                        }
                    },
                    '& .sp-menu-item-without-link': {
                        '&.mobile-item': {
                            display: 'none',
                        }
                    },
                },
                '& .dropdown-submenu-list': {
                    position: 'absolute',
                    top: 'calc(100% - 5px)',
                    ...getAlignSubmenuList(data),
                    zIndex: 999999,
                    padding: '4px',
                    backgroundColor: data.submenuListBackground,
                    borderRadius: '6px',
                    boxShadow: '0px 6px 12px 0px rgba(0, 0, 0, 0.12)',
                    display: 'none',
                    listStyleType: 'none',
                    '& li': {
                        marginBottom: '0px',
                        '& span': {
                            ...getItemsAlign(data),
                            display: 'block',
                            '& a': {
                                fontSize: `${data.submenuFontSize}px`,
                                ...getSubmenuFontStyles(data),
                                color: data.submenuTextColor,
                                position: 'relative',
                                transition: '0.2s ease-in-out',
                                textWrap: 'nowrap',
                                '&:hover': {
                                    opacity: '0.8',
                                    textDecoration: 'none',
                                    '&:after': {
                                        display: 'none',
                                    }
                                },
                            },
                        },
                    },
                },
            },
            '&.v-list': {
                flexDirection: 'column',
            },
        },
    };
}
