import type { JssStyle } from 'jss';
import type { WebWidgetEntity } from '@web/types';
import type { TimerWidgetData } from '@web/widgets/timer/type/timer-widget.type';

import { EWebsiteThemeColorNames } from '@libs/themes/src/variants/website/enums/color.enum';
import { commonFontSizeCoefficient, webPStyles } from '@libs/themes/src/variants/website/styles/text.styles';
import { websiteColorCss } from '@libs/themes/src/variants/website/utils/variables';

import { EHorizontalPosition, ESize, ETimerAppearanceSeparator } from '@common/enums';

function commonColonSeparator(element: WebWidgetEntity<TimerWidgetData>): JssStyle {
    return {
        content: '""',
        position: 'absolute',
        display: 'block',
        background: element.data.dividerColor || websiteColorCss(EWebsiteThemeColorNames.dark),
    };
}

function commonLineSeparator(element: WebWidgetEntity<TimerWidgetData>): JssStyle {
    return {
        content: '""',
        position: 'absolute',
        height: '50%',
        top: '15%',
        width: '1px',
        display: 'block',
        background: element.data.dividerColor || websiteColorCss(EWebsiteThemeColorNames.primary),
    };
}

function separatorS(element: WebWidgetEntity<TimerWidgetData>): JssStyle {
    const commonSeparatorStyles = {
        position: 'relative',
        margin: '0 2px',
    };

    switch (element.data.appearance) {
        case ETimerAppearanceSeparator.none:
            return { ...commonSeparatorStyles };
        case ETimerAppearanceSeparator.colon:
            return {
                ...commonSeparatorStyles,
                '&:before': {
                    ...commonColonSeparator(element),
                    top: 'calc(50% - 14px)',
                    width: '2px',
                    height: '2px',
                },
                '&:after': {
                    ...commonColonSeparator(element),
                    top: 'calc(50% - 5px)',
                    width: '2px',
                    height: '2px',
                },
            };
        case ETimerAppearanceSeparator.line:
            return {
                ...commonSeparatorStyles,
                '&:before': { ...commonLineSeparator(element) },
            };
        default:
            return { ...commonSeparatorStyles };
    }
}

function separatorM(element: WebWidgetEntity<TimerWidgetData>): JssStyle {
    const commonSeparatorStyles = {
        position: 'relative',
        margin: '0 4px',
    };

    switch (element.data.appearance) {
        case ETimerAppearanceSeparator.none:
            return { ...commonSeparatorStyles };
        case ETimerAppearanceSeparator.colon:
            return {
                ...commonSeparatorStyles,
                '&:before': {
                    ...commonColonSeparator(element),
                    top: 'calc(50% - 20px)',
                    width: '3px',
                    height: '3px',
                },
                '&:after': {
                    ...commonColonSeparator(element),
                    top: 'calc(50% - 5px)',
                    width: '3px',
                    height: '3px',
                },
            };
        case ETimerAppearanceSeparator.line:
            return {
                ...commonSeparatorStyles,
                '&:before': { ...commonLineSeparator(element) },
            };
        default:
            return { ...commonSeparatorStyles };
    }
}

function separatorL(element: WebWidgetEntity<TimerWidgetData>): JssStyle {
    const commonSeparatorStyles = {
        position: 'relative',
        margin: '0 6px',
    };

    switch (element.data.appearance) {
        case ETimerAppearanceSeparator.none:
            return { ...commonSeparatorStyles };
        case ETimerAppearanceSeparator.colon:
            return {
                ...commonSeparatorStyles,
                '&:before': {
                    ...commonColonSeparator(element),
                    top: 'calc(50% - 20px)',
                    width: '4px',
                    height: '4px',
                },
                '&:after': {
                    ...commonColonSeparator(element),
                    top: 'calc(50% - 5px)',
                    width: '4px',
                    height: '4px',
                },
            };
        case ETimerAppearanceSeparator.line:
            return {
                ...commonSeparatorStyles,
                '&:before': { ...commonLineSeparator(element) },
            };
        default:
            return { ...commonSeparatorStyles };
    }
}

function separatorStyles(element: WebWidgetEntity<TimerWidgetData>): JssStyle {
    switch (element.data.size) {
        case ESize.S:
            return separatorS(element);
        case ESize.M:
            return separatorM(element);
        case ESize.L:
            return separatorL(element);
        default:
            return separatorM(element);
    }
}

function getJustifyContent(horizontalPosition: EHorizontalPosition) {
    switch (horizontalPosition) {
        case EHorizontalPosition.L:
            return { justifyContent: 'flex-start' };
        case EHorizontalPosition.M:
            return { justifyContent: 'center' };
        case EHorizontalPosition.R:
            return { justifyContent: 'flex-end' };
        default:
            return { justifyContent: 'normal' };
    }
}

export function getStyles(element: WebWidgetEntity<TimerWidgetData>, { isConstructor }: any): JssStyle {
    const { data } = element;
    const fontSizeCoefficient = commonFontSizeCoefficient[element.data.size];
    const mobileFontSizeCoefficient = commonFontSizeCoefficient[element.data.mobileSize];

    let wrapColumns: any = {};
    let value: JssStyle = {};
    let label: JssStyle = {};
    let expiredText: JssStyle = {};

    if (!element.data.mobileHorizontalAlign) {
        wrapColumns = {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            ...getJustifyContent(element.data.horizontalAlign),
            '@media (max-width: 992px)': {
                ...getJustifyContent(element.data.horizontalAlign),
            },
        };

        value = {
            ...webPStyles,
            minWidth: '40px',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: `calc(50px * ${fontSizeCoefficient})`,
            color: element.data.textColor,
            '@media (max-width: 992px)': {
                fontSize: `calc(50px * ${fontSizeCoefficient})`,
            },
        };

        label = {
            ...webPStyles,
            overflow: 'hidden',
            wordWrap: 'break-word',
            textAlign: 'center',
            fontSize: `calc(15px * ${fontSizeCoefficient})`,
            color: element.data.textColor,
            '@media (max-width: 992px)': {
                fontSize: `calc(15px * ${fontSizeCoefficient})`,
            },
        };

        expiredText = {
            ...webPStyles,
            fontSize: `calc(${webPStyles.fontSize} * ${fontSizeCoefficient})`,
            color: element.data.textColor,
            '@media (max-width: 992px)': {
                fontSize: `calc(${webPStyles.fontSize} * ${fontSizeCoefficient})`,
            },
        };
    } else {
        wrapColumns = {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            ...getJustifyContent(element.data.horizontalAlign),
            '@media (max-width: 992px)': {
                ...getJustifyContent(element.data.mobileHorizontalAlign),
            },
            '&.mobileWidgetViewWrap': {
                ...getJustifyContent(element.data.mobileHorizontalAlign),
            },
        };

        value = {
            ...webPStyles,
            minWidth: '40px',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: `calc(50px * ${fontSizeCoefficient})`,
            color: element.data.textColor,
            '@media (max-width: 992px)': {
                fontSize: `calc(50px * ${mobileFontSizeCoefficient})`,
            },
            '&.mobileWidgetViewElement': {
                fontSize: `calc(50px * ${mobileFontSizeCoefficient})`,
            },
        };

        label = {
            ...webPStyles,
            overflow: 'hidden',
            wordWrap: 'break-word',
            textAlign: 'center',
            fontSize: `calc(15px * ${fontSizeCoefficient})`,
            color: element.data.textColor,
            '@media (max-width: 992px)': {
                fontSize: `calc(15px * ${mobileFontSizeCoefficient})`,
            },
            '&.mobileWidgetViewElement': {
                fontSize: `calc(15px * ${mobileFontSizeCoefficient})`,
            },
        };

        expiredText = {
            ...webPStyles,
            fontSize: `calc(${webPStyles.fontSize} * ${fontSizeCoefficient})`,
            color: element.data.textColor,
            '@media (max-width: 992px)': {
                fontSize: `calc(${webPStyles.fontSize} * ${mobileFontSizeCoefficient})`,
            },
            '&.mobileWidgetViewElement': {
                fontSize: `calc(15px * ${mobileFontSizeCoefficient})`,
            },
        };
    }

    if (!isConstructor) {
        if (data?.hideOnMobile && !data?.hideOnDesktop) {
            wrapColumns['@media (max-width: 992px)'] = { display: 'none' };
        }

        if (!data?.hideOnMobile && data?.hideOnDesktop) {
            wrapColumns['@media (min-width: 993px)'] = { display: 'none' };
        }

        if (data?.hideOnMobile && data?.hideOnDesktop) {
            wrapColumns.display = 'none';
        }
    }

    return {
        wrapColumns,
        wrapColumn: {
            display: 'flex',
            '&:last-child .separator': {
                display: 'none',
            },
        },
        column: {
            minWidth: '80px',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            color: element.data.textColor,
        },
        value,
        label,
        expiredText,
        separator: separatorStyles(element),
    };
}
