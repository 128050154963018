import { APP_INITIALIZER, Provider } from '@angular/core';
import { TranslateModuleConfig, MissingTranslationHandler, TranslateLoader } from '@ngx-translate/core';

import { CustomMissingTranslationHandler } from '@common/handlers/missing-translations.handler';

export function createTranslationModuleConfig(factory: Function, dependencies: any[]): TranslateModuleConfig {
    return {
        missingTranslationHandler: {
            provide: MissingTranslationHandler,
            useClass: CustomMissingTranslationHandler,
        },
        loader: {
            provide: TranslateLoader,
            useFactory: factory,
            deps: dependencies,
        },
    };
}

export function createTranslationProvider(factory: Function, dependencies: any[]): Provider {
    return {
        provide: APP_INITIALIZER,
        useFactory: factory,
        deps: dependencies,
        multi: true,
    };
}
