import type { LineWidgetData } from '@web/widgets/line';

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { getStyles } from '@web/widgets/line/components/line-view/line-view.component.styles';

import { AbstractWidgetComponent } from '@web-builder/widgets/abstract-widget.component';

@Component({
    selector: 'web-builder-line',
    templateUrl: './line.component.html',
    styleUrls: ['./line.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule],
})
export class LineComponent extends AbstractWidgetComponent<LineWidgetData> {
    protected getStyles = getStyles;
}
