import { EButtonStyleType } from '@common/enums';
import { ESimpleThemeButtonProperties, ESimpleThemeButtonHoverProperties } from '@common/libs/themes/src/variants/simple/enums/button.enum';
import { ESimpleThemeFontProperties } from '@common/libs/themes/src/variants/simple/enums/font.enum';

export const SIMPLE_THEME_BUTTONS_VARIABLES = {
    [EButtonStyleType.primary]: {
        [ESimpleThemeButtonProperties.borderRadius]: '--primary-button-border-radius',
        [ESimpleThemeButtonProperties.borderStyle]: '--primary-button-border-style',
        [ESimpleThemeButtonProperties.borderWidth]: '--primary-button-border-width',
        [ESimpleThemeButtonProperties.borderColor]: '--primary-button-border-color',
        [ESimpleThemeButtonProperties.color]: '--primary-button-color',
        [ESimpleThemeButtonProperties.background]: '--primary-button-background',
        [ESimpleThemeFontProperties.fontSize]: '--primary-button-font-size',
        [ESimpleThemeFontProperties.fontWeight]: '--primary-button-font-weight',
        [ESimpleThemeFontProperties.fontStyle]: '--primary-button-font-style',
        [ESimpleThemeFontProperties.fontFamily]: '--primary-button-font-family',
        [ESimpleThemeFontProperties.lineHeight]: '--primary-button-line-height',
        [ESimpleThemeFontProperties.textTransform]: '--primary-button-text-transform',
        [ESimpleThemeFontProperties.letterSpacing]: '--primary-button-letter-spacing',
    },
    [EButtonStyleType.secondary]: {
        [ESimpleThemeButtonProperties.borderRadius]: '--secondary-button-border-radius',
        [ESimpleThemeButtonProperties.borderStyle]: '--secondary-button-border-style',
        [ESimpleThemeButtonProperties.borderWidth]: '--secondary-button-border-width',
        [ESimpleThemeButtonProperties.borderColor]: '--secondary-button-border-color',
        [ESimpleThemeButtonProperties.color]: '--secondary-button-color',
        [ESimpleThemeButtonProperties.background]: '--secondary-button-background',
        [ESimpleThemeFontProperties.fontSize]: '--secondary-button-font-size',
        [ESimpleThemeFontProperties.fontWeight]: '--secondary-button-font-weight',
        [ESimpleThemeFontProperties.fontStyle]: '--secondary-button-font-style',
        [ESimpleThemeFontProperties.fontFamily]: '--secondary-button-font-family',
        [ESimpleThemeFontProperties.lineHeight]: '--secondary-button-line-height',
        [ESimpleThemeFontProperties.textTransform]: '--secondary-button-text-transform',
        [ESimpleThemeFontProperties.letterSpacing]: '--secondary-button-letter-spacing',
    },
};

export const SIMPLE_THEME_BUTTONS_HOVER_VARIABLES = {
    [EButtonStyleType.primary]: {
        [ESimpleThemeButtonHoverProperties.opacity]: '--primary-button-hover-opacity',
        [ESimpleThemeButtonHoverProperties.shadow]: '--primary-button-hover-shadow',
        [ESimpleThemeButtonHoverProperties.color]: '--primary-button-hover-color',
        [ESimpleThemeButtonHoverProperties.background]: '--primary-button-hover-background',
        [ESimpleThemeButtonHoverProperties.borderColor]: '--primary-button-hover-border-color',
    },
    [EButtonStyleType.secondary]: {
        [ESimpleThemeButtonHoverProperties.opacity]: '--secondary-button-hover-opacity',
        [ESimpleThemeButtonHoverProperties.shadow]: '--secondary-button-hover-shadow',
        [ESimpleThemeButtonHoverProperties.color]: '--secondary-button-hover-color',
        [ESimpleThemeButtonHoverProperties.background]: '--secondary-button-hover-background',
        [ESimpleThemeButtonHoverProperties.borderColor]: '--secondary-button-hover-border-color',
    },
};
