import type { JssStyle } from 'jss';
import type { StructurePageStructureEntity } from '@web/types';
import type { Variant } from '@web/store/page-structure/structures/structures.models';

// function getSpanValue(cells: number[], index: number, fallbackValue: number) {

function getRowsStyle(structure: StructurePageStructureEntity) {
    const cellsWidth = structure?.data?.rowCellWidth?.flat() ?? [];

    if (structure.variant === 0) {
        return {
            '& .row0': {
                gridColumn: `span ${cellsWidth[0] ?? 4}/ span ${cellsWidth[0] ?? 4}`,
            },
            '& .row1': {
                gridColumn: `span ${cellsWidth[1] ?? 4}/ span ${cellsWidth[1] ?? 4}`,
            },
        };
    }

    if (structure.variant === 1) {
        return {
            '& .row0': {
                gridColumn: `span ${cellsWidth[0] ?? 4}/ span ${cellsWidth[0] ?? 4}`,
            },
            '& .row1': {
                gridColumn: `span ${cellsWidth[1] ?? 4}/ span ${cellsWidth[1] ?? 4}`,
            },
            '& .row2': {
                gridColumn: `span ${cellsWidth[2] ?? 8}/ span ${cellsWidth[2] ?? 8}`,
            },
        };
    }

    if (structure.variant === 2) {
        return {
            '& .row0': {
                gridColumn: `span ${cellsWidth[0] ?? 3}/ span ${cellsWidth[0] ?? 3}`,
            },
            '& .row1': {
                gridColumn: `span ${cellsWidth[1] ?? 5}/ span ${cellsWidth[1] ?? 5}`,
            },
        };
    }

    if (structure.variant === 3) {
        return {
            '& .row0': {
                gridColumn: `span ${cellsWidth[0] ?? 2}/ span ${cellsWidth[0] ?? 2}`,
            },
            '& .row1': {
                gridColumn: `span ${cellsWidth[1] ?? 4}/ span ${cellsWidth[1] ?? 4}`,
            },
            '& .row2': {
                gridColumn: `span ${cellsWidth[2] ?? 2}/ span ${cellsWidth[2] ?? 2}`,
            },
        };
    }

    if (structure.variant === 4) {
        return {
            '& .row0': {
                gridColumn: `span ${cellsWidth[0] ?? 4}/ span ${cellsWidth[0] ?? 4}`,
            },
            '& .row1': {
                gridColumn: `span ${cellsWidth[1] ?? 4}/ span ${cellsWidth[1] ?? 4}`,
            },
            '& .row2': {
                gridColumn: `span ${cellsWidth[2] ?? 4}/ span ${cellsWidth[2] ?? 4}`,
            },
            '& .row3': {
                gridColumn: `span ${cellsWidth[3] ?? 4}/ span ${cellsWidth[3] ?? 4}`,
            },
        };
    }

    if (structure.variant === 5) {
        return {
            '& .row0': {
                gridColumn: `span ${cellsWidth[0] ?? 8}/ span ${cellsWidth[0] ?? 8}`,
            },
            '& .row1': {
                gridColumn: `span ${cellsWidth[1] ?? 4}/ span ${cellsWidth[1] ?? 4}`,
            },
            '& .row2': {
                gridColumn: `span ${cellsWidth[2] ?? 4}/ span ${cellsWidth[2] ?? 4}`,
            },
        };
    }
}

export function getStyles(structure: StructurePageStructureEntity, variant: Variant): JssStyle {
    const root = {
        display: 'grid',
        gridTemplateColumns: 'repeat(8, minmax(0, 1fr))',
        // gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: 'auto auto',
        rowGap: structure.data.gapY + 'px',
        columnGap: structure.data.gapX + 'px',
        ...getRowsStyle(structure),
    };

    return {
        root,
    };
}
