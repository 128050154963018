import type { JssStyle } from 'jss';
import type { WebWidgetEntity } from '@web/types';
import { type ImageWidgetData } from '@web/widgets/image/type/image-widget.type';
import { type LogoWidgetData } from '@web/widgets/logo/type/logo-widget.type';



function getImgWidgetWrapperStyles(): JssStyle {
    return {
        display: 'flex',
    };
}

function getImgWrapperStyles({ styles }: WebWidgetEntity<ImageWidgetData>): JssStyle {
    const imgWrapper: JssStyle = {
        width: '100%',
        maxWidth: styles.maxWidth + 'px',
    };

    return imgWrapper;
}

function getImageStyles({ data }: WebWidgetEntity<ImageWidgetData>): JssStyle {
    if (!data.image) {
        return {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        };
    }
    let imageStyles: JssStyle = {
        display: 'block',
        height: 'auto',
        maxWidth: '100%',
    };

    return imageStyles;
}

export function getStyles(widget: WebWidgetEntity<LogoWidgetData>): JssStyle {
    return {
        imgWidgetWrapper: getImgWidgetWrapperStyles(),
        imgWrapper: getImgWrapperStyles(widget),
        image: getImageStyles(widget),
    };
}
