import type { OnInit } from '@angular/core';
import { Component, HostListener, Input } from '@angular/core';
import type { HttpErrorResponse } from '@angular/common/http';
import type { FormControl, FormGroup } from '@angular/forms';
import { ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateModule } from '@ngx-translate/core';

import { EduCourse } from '@common/types/edu-course.type';

import { EduHttpService } from '@web-builder/core/services/REST/edu.http.service';
import { Classes } from 'jss';

type PasswordRecoveryFormData = {
    email: string;
};

type PasswordRecoveryFormGroup = FormGroup<{
    email: FormControl<PasswordRecoveryFormData['email']>;
}>;

export type PasswordRecoveryModalResult = {
    message?: 'passwordRecoverySuccess';
    email?: string;
};

@Component({
    selector: 'web-builder-password-recovery-modal',
    styleUrls: ['./password-recovery-modal.component.less'],
    templateUrl: './password-recovery-modal.component.html',
    imports: [CommonModule, ReactiveFormsModule, TranslateModule],
    standalone: true,
})
export class PasswordRecoveryModalComponent implements OnInit {
    @Input() public eduCourse!: EduCourse;
    @Input() public domainId!: number;
    @Input() public classes!: Classes;

    public onClose: Subject<PasswordRecoveryModalResult>;
    public form: PasswordRecoveryFormGroup;

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly bsModalRef: BsModalRef,
        private readonly eduHttpService: EduHttpService,
    ) {
        this.initForm();
    }

    public ngOnInit(): void {
        this.onClose = new Subject();
    }

    public initForm(): void {
        this.form = this.fb.group({
            email: this.fb.control(null, [Validators.required, Validators.email]),
        });
    }

    public onPasswordRecovery(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.form.disable();
        this.eduHttpService
            .forgotPassword({
                email: this.form.value.email,
                domainId: this.domainId,
                courseId: this.eduCourse?.id,
            })
            .subscribe(
                () => {
                    this.bsModalRef.hide();
                    this.onClose.next({ message: 'passwordRecoverySuccess', email: this.email.value });
                },
                (error: HttpErrorResponse) => {
                    this.form.enable();

                    if (error.status === 404) {
                        this.email.setErrors({ notFound: true });
                    }

                    if (error.status === 429) {
                        this.form.setErrors({ alreadySent: true });
                    }
                },
            );
    }

    @HostListener('document:keydown.enter')
    public onEnterPress(): void {
        this.onPasswordRecovery();
    }

    public onCancel(): void {
        this.onClose.next(null);
        this.bsModalRef.hide();
    }

    public onCloseModal(): void {
        this.bsModalRef.hide();
    }

    public get email(): FormControl<PasswordRecoveryFormData['email']> {
        return this.form.get('email') as FormControl<PasswordRecoveryFormData['email']>;
    }
}
