import type { Uuid } from '@common/types';

export type SelectedEntity = {
    entityType: ESelectedEntityType;
    entityId?: Uuid;
};

export enum ESelectedEntityType {
    block = 'block',
    specificBlock = 'specificBlock',
    specificSection = 'specificSection',
    column = 'column',
    structure = 'structure',
    widget = 'widget',
    theme = 'theme',
}

export enum PreviewMode {
    mobile = 'mobile',
    desktop = 'desktop',
}

export enum DeviceMode {
    mobile = 'mobile',
    desktop = 'desktop',
}

export type DrawerState = {
    drawerType: EDrawerType;
    blockIndex?: number;
};

export enum EDrawerType {
    block = 'block',
    page = 'page',
    navigation = 'navigation',
}
