import type { OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, HostListener, inject, Input } from '@angular/core';
import {
    type AbstractControl,
    type AsyncValidatorFn,
    type FormArray,
    type FormControl,
    type FormGroup,
    FormsModule,
    ReactiveFormsModule,
    UntypedFormBuilder,
    type ValidationErrors,
    Validators,
} from '@angular/forms';
import type { FormElement, FormRadio, Option } from '@common/types/form-element.type';
import type { PaymentElement, PaymentFormElement } from '@common/types/payment-form.type';
import { type PaymentMethod } from '@common/types/payment-form.type';
import type { FormAdditionalSettings } from '@components/sp-form-additional-settings';
import { PaymentFormWidgetData } from '@web/widgets/payment-form';
import { WebWidgetEntity } from '@web/types';
import { CommonModule } from '@angular/common';
import { type Observable, of, tap, timer } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Classes } from 'jss';

import * as typeGuards from '@common/type-guards/form-elements.type-guards';
import { EButtonStyleType, ECrmCommentPlace, EFormControlType, EFormInputType, EPaymentType, ESize } from '@common/enums';
import { paymentsList, paymentTypeNames } from '@common/constants/payment-form.const';

import { BUTTON_SIZE_CLASS_MAP, BUTTON_TYPE_CLASS_MAP, DEFAULT_BUTTON_CLASS } from '@web/widgets/common/base/base-button/base-button.const';
import { ProductType } from '@web/widgets/payment-form/type/payment-form-widget.type';

import { PaymentHttpService } from '@web-builder/core/services/REST/payment.http.service';
import { TransferStateService } from '@web-builder/core/services/transfer-state.service';
import { SpPipesModule } from '@common/pipes/pipes.module';
import { EduHttpService } from '@web-builder/core/services/REST/edu.http.service';
import { AnalyticsService } from '@web-builder/core/services/analytics.service';
import { UtmTagsService } from '@web-builder/core/services/utm-tags.service';
import { CountryISO, NgxIntlTelInputModule, PhoneNumberFormat, SearchCountryField } from '@moddi3/ngx-intl-tel-input';
import { CountryISOService } from '@common/services/REST/country-iso.http.service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { UtilsService } from '@web-builder/core/services/utils.service';
import { DomSanitizer, type SafeHtml } from '@angular/platform-browser';
import { NgSelectModule } from '@ng-select/ng-select';
import { type HttpErrorResponse } from '@angular/common/http';
import { PaymentGoogleSettings } from '@web/shared/components/web-analytics-settings/web-analytics-setings.type';

export type PromoFormControls = {
    label: FormControl<string>;
    placeholder: FormControl<string>;
    required: FormControl<boolean>;
    type: FormControl<EFormInputType>;
    fullPrice: FormControl<number>;
    price: FormControl<number>;
    value: FormControl<string>;
};

@Component({
    selector: 'web-builder-payment-form-modal',
    templateUrl: './payment-form-modal.component.html',
    styleUrls: ['./payment-form-modal.component.less'],
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        SpPipesModule,
        NgxIntlTelInputModule,
        BsDropdownModule,
        NgSelectModule,
        FormsModule,
    ],
})
export class PaymentFormModalComponent implements OnInit {
    @Input() public classes: Classes;
    @Input() public elements: FormElement[];
    @Input() public widgetData: PaymentFormWidgetData;
    @Input() public widget: WebWidgetEntity<PaymentFormWidgetData>;

    private readonly paymentHttpService: PaymentHttpService = inject(PaymentHttpService);
    private readonly eduHttpService: EduHttpService = inject(EduHttpService);
    private readonly analyticsService: AnalyticsService = inject(AnalyticsService);
    private readonly countryISOService: CountryISOService = inject(CountryISOService);
    private readonly utilsService: UtilsService = inject(UtilsService);
    private readonly pageId: number = this.transferStateService.get('pageId');
    private readonly lang: string = this.transferStateService.get('lang');

    public currentPaymentMethodIndex: number = 0;

    public readonly SearchCountryField = SearchCountryField;
    public readonly PhoneNumberFormat = PhoneNumberFormat;
    public readonly formInputType = EFormInputType;

    public formGroup: FormGroup;
    public amountControl: FormControl;
    public promoGroup: FormGroup<PromoFormControls>;
    public typeGuards = typeGuards;
    public CountryISO = CountryISO.Ukraine;
    public excludeCountries = [CountryISO.Russia];
    public isEduCourseError: boolean = false;
    public showErrorMessage: boolean = false;
    private isHasPromoCode: boolean = false;
    public isShowSuccessMessage: boolean = false;
    public isShowInvalidPaymentMethodError: boolean = false;
    public dropdownListValues = [];

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly bsModalRef: BsModalRef,
        private readonly transferStateService: TransferStateService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly utmTagsService: UtmTagsService,
        protected readonly sanitizer: DomSanitizer,
    ) {
        this.amountControl = this.fb.control(null);
    }

    public ngOnInit(): void {
        this.formGroup = this.fb.group({
            formArray: this.fb.array([]),
        });

        this.generateFormArray();
        this.setFormValue();
        this.getCountryISO();

        if (this.isProductTypeEduCourse) {
            this.checkEduCourseStatus();
            this.checkPromoCodeExists();
        }
    }

    public generateFormArray(): void {
        this.amountControl.setValidators([Validators.min(this.paymentElement.amount)]);
        this.amountControl.setValue(this.paymentElement.amount);

        this.formGroup = this.fb.group({
            formArray: this.fb.array(
                this.elements.map((member: FormElement) => {
                    const options = (member as FormRadio)?.options || null;

                    this.dropdownListValues.push(options ? options.filter((el) => el.selected) : options);
                    return this.fb.group({
                        ...member,
                        options: this.fb.array(options ? (member as FormRadio).options.map((opt) => this.fb.group(opt)) : []),
                        value: [this.getDefaultValue(member, options), this.getValidators(member)],
                    });
                }),
            ),
        });

        this.formGroup.markAsUntouched();
    }

    private setPromoField() {
        this.promoGroup = this.promoCodeFormGroup;
    }

    private get promoCodeFormGroup(): FormGroup<PromoFormControls> {
        return this.fb.group({
            label: ['control_promo_label'],
            placeholder: ['control_promo_placeholder'],
            required: [false],
            type: [EFormInputType.promocode],
            fullPrice: [''],
            price: [''],
            value: ['', [], [this.checkPromoValidator()]],
        });
    }

    public getControlValue(control: AbstractControl, controlName: string) {
        return control.get(controlName)?.value;
    }

    public getButtonStyles(formButtonSettings: PaymentFormWidgetData['formButtonSettings']): string {
        let styles = `${DEFAULT_BUTTON_CLASS} ${BUTTON_SIZE_CLASS_MAP[formButtonSettings.size ? formButtonSettings.size : ESize.M]}`;
        if (formButtonSettings.styleType === EButtonStyleType.custom) {
            return `${styles} ${this.classes?.buttonStyles}`;
        }

        return `${styles} ${BUTTON_TYPE_CLASS_MAP[formButtonSettings.styleType]} ${this.classes?.buttonStyles}`;
    }

    public getIcon(icon: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(icon);
    }

    public inputErrors(control: AbstractControl): string[] {
        const errors: string[] = [];

        Object.keys(control.get('value').errors).forEach((err) => {
            if (control.get('type').value === EFormControlType.select) {
                const isRadioChecked = control.get('options').value.some((el) => el.selected);
                if (!isRadioChecked && err === 'required') {
                    errors.push('control_errors_required');
                }

                return;
            }

            if (err === 'email') errors.push('email_validation_error');
            if (control.get('type').value === EFormInputType.phone && err === 'phone') errors.push('control_errors_invalid_phone_number');
            if (control.get('type').value === EFormInputType.email && err === 'email') errors.push('email_validation_error');
            if (err === 'required' && control.get('type').value !== EFormControlType.checkbox) errors.push('control_errors_required');
            if (err === 'required' && control.get('type').value === EFormControlType.checkbox)
                errors.push('control_errors_checkbox_required');
            if (err === 'maxlength' && control.get('type').value === EFormControlType.textarea)
                errors.push('control_errors_textarea_maxlength');
            if (err === 'maxlength' && control.get('type').value === EFormInputType.name) errors.push('control_errors_name_maxlength');
        });

        return errors;
    }

    public changeCheckBoxValue(control: AbstractControl) {
        control.get('value').patchValue(!control.get('value').value);
    }

    public changeCheckRadioValue(control: AbstractControl, option: { label: string; selected: boolean; value: string }) {
        const opt = this.getControlValue(control, 'options').map((el) => {
            return {
                ...el,
                ...{ selected: option.label === el.label && option.value === el.value },
            };
        });
        control.get('options').patchValue(opt);
        control.get('value').patchValue(option.value);
    }

    public changeSelectValue(control: AbstractControl, event) {
        if (event) {
            if (Array.isArray(event)) {
                const opt = this.getControlValue(control, 'options').map((el) => {
                    return {
                        ...el,
                        ...{ selected: event.map((e) => e.value).indexOf(el.value) > -1 },
                    };
                });
    
                control.get('value').patchValue(event.length ? `["${event.map((el) => el.value).join('","')}"]` : null);
                control.get('options').patchValue(opt);
            } else {
                const opt = this.getControlValue(control, 'options').map((el) => {
                    return {
                        ...el,
                        ...{ selected: event.value === el.value },
                    };
                });
    
                control.get('value').patchValue(event.value);
                control.get('options').patchValue(opt);
            }
        }
    }

    public onCancel(): void {
        this.bsModalRef.hide();
    }

    private getDefaultValue(element: FormElement, options: Option[]): any {
        if (element.type === EFormControlType.checkbox) {
            return false;
        }

        if (element.type === EFormControlType.select) {
            const arr = options.filter((el) => el.selected).map((el) => el.label);

            return arr.length ? `["${arr.join('","')}"]` : '';
        }

        if (element.type === EFormInputType.hidden) {
            return element.value;
        }

        if (element.type === EFormControlType.radio) {
            return element.options.find((opt) => opt.selected)?.value;
        }

        return '';
    }

    private getValidators(elem: FormElement): Validators[] {
        let validators: Validators[] = [];

        if (elem.type === EFormControlType.select) {
            return validators;
        }

        if (elem.required && elem.type !== EFormControlType.checkbox) {
            validators.push(Validators.required);
        }

        if (elem.type === EFormInputType.email) {
            validators.push(Validators.email);
        }

        if (elem.type === EFormControlType.checkbox && elem.required) {
            validators.push(Validators.requiredTrue);
        }

        if (elem.type === EFormControlType.textarea) {
            validators.push(Validators.maxLength(5000));

            if (elem.required) {
                validators.push(Validators.required);
            }
        }

        if (elem.type === EFormInputType.input || elem.type === EFormInputType.name) {
            validators.push(Validators.maxLength(128));
        }

        return validators;
    }

    public submitForm(): void {
        if (this.isShowInvalidPaymentMethodError) {
            return;
        }

        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            this.showErrorMessage = true;
            return;
        }

        this.formGroup.disable();

        if (this.isProductTypeEduCourse) {
            return this.submitEduCourseForm(this.prepareEduSubmitPayload());
        }

        return this.submitPaymentForm(this.preparePaymentPayload());
    }

    private submitPaymentForm(payload: any): void {
        this.paymentHttpService
            .submitPayment(payload)
            .pipe(
                catchError((err) => {
                    this.formGroup.markAllAsTouched();
                    this.formGroup.enable();

                    if (err.status === 412) {
                        this.isShowInvalidPaymentMethodError = true;
                    } else if (err?.error?.errors) {
                        const errors = err.error.errors;
                        Object.keys(errors).forEach((key) => {
                            if (key.includes('phone')) this.setPhoneEmailControlErrors('phone');
                            if (key.includes('email')) this.setPhoneEmailControlErrors('email');
                            if (key.includes('paymentMethodId')) {
                                this.isShowInvalidPaymentMethodError = true;
                            }
                        });
                    }
                    this.showErrorMessage = true;
                    this.changeDetectorRef.detectChanges();

                    return of(null);
                }),
            )
            .subscribe((res) => {
                if (res === null) return;

                this.sendGaEvent(payload.currency, payload.totalCost);
                this.sendPixelFbEvent(payload.currency, payload.totalCost);

                if (!res?.data?.url) {
                    this.isShowSuccessMessage = true;
                    setTimeout(() => {
                        this.onCancel();
                        if (payload?.backUrl) window.open(payload.backUrl, '_self');
                    }, 5000);
                }

                if (res?.data?.url) {
                    this.onCancel();
                    window.open(res.data.url, '_self');
                }

                this.formGroup.enable();
                this.changeDetectorRef.detectChanges();
            });
    }

    private submitEduCourseForm(payload: any): void {
        this.paymentHttpService
            .submitEduPayment(payload)
            .pipe(
                catchError((err) => {
                    this.formGroup.markAllAsTouched();
                    this.formGroup.enable();

                    if (err?.error?.errors) {
                        const errors = err.error.errors;
                        Object.keys(errors).forEach((key) => {
                            if (key.includes('phone')) this.setPhoneEmailControlErrors('phone');
                            if (key.includes('email')) this.setPhoneEmailControlErrors('email');
                            if (key.includes('paymentMethodId')) {
                                this.isShowInvalidPaymentMethodError = true;
                            }
                        });
                    }
                    this.showErrorMessage = true;
                    this.changeDetectorRef.detectChanges();

                    return of(null);
                }),
            )
            .subscribe((res) => {
                if (res && Object.keys(res).length === 0) {
                    this.isEduCourseError = true;
                }

                if (res === null) return;

                this.sendGaEvent(payload.currency, payload.totalCost);
                this.sendPixelFbEvent(payload.currency, payload.totalCost);

                if (res?.data) {
                    this.onCancel();
                    window.open(res.data, '_self');
                }

                this.formGroup.enable();
                this.changeDetectorRef.detectChanges();
            });
    }

    private preparePaymentPayload(): any {
        const paymentPayload: {
            currency: any;
            paymentMethodId: any;
            paymentType: any;
            totalCost: any;
            name: any;
            isEditableTotalCost: boolean;
            backUrl: any;
            productId?: number;
            productCode?: string;
            paymentMethodName?: string;
        } = {
            currency: null,
            paymentMethodId: null,
            paymentType: null,
            totalCost: null,
            name: null,
            isEditableTotalCost: false,
            backUrl: null,
            productId: null,
            productCode: null,
        };
        const payload: {
            pipelineId: any;
            dealName: any;
            pipelineStepId: any;
            elementId: any;
            email: any;
            phone?: any;
            addressBookId?: any;
            pageId: any;
            createAddressBook: any;
            variables: {};
            contactFields: {};
            contactVariables: {};
            dealVariables: {};
            utm: string;
            contactComments: any[];
            dealComments: any[];
        } = {
            pipelineId: null,
            dealName: null,
            pipelineStepId: null,
            elementId: null,
            email: null,
            createAddressBook: false,
            pageId: this.pageId,
            variables: {},
            contactFields: {},
            contactVariables: {},
            dealVariables: {},
            utm: this.utmTagsService.getUtmTags(),
            contactComments: [],
            dealComments: [],
        };

        (this.formGroup.value.formArray as PaymentFormElement[]).forEach((val) => {
            if (val.type === EFormInputType.email || val.type === EFormInputType.phone) {
                if (val.type === EFormInputType.email) payload.email = val.value;
                if (val.type === EFormInputType.phone) {
                    if (val.value?.e164Number) {
                        payload.phone = val.value?.e164Number;
                        payload.variables[EFormInputType.phone] = val.value?.e164Number;
                    } else {
                        payload.phone = val.value;
                        payload.variables[EFormInputType.phone] = val.value;
                    }
                }
                return;
            }

            if (val.type === EFormControlType.textarea) {
                if (val.crmCommentPlace === ECrmCommentPlace.crmAllComment) {
                    payload.contactComments.push(val.value);
                    payload.dealComments.push(val.value);
                }
                if (val.crmCommentPlace === ECrmCommentPlace.crmContactComment) {
                    payload.contactComments.push(val.value);
                }
                if (val.crmCommentPlace === ECrmCommentPlace.crmDealComment) {
                    payload.dealComments.push(val.value);
                }
            }

            if (val.value) {
                if (val.variable) payload.variables[val.variable] = val.value;
                if (val.crmContactVariable && val.crmContactVariable !== 'lastName' && val.crmContactVariable !== 'firstName') {
                    payload.contactVariables[val.crmContactVariable] = val.value;
                }
                if (val.crmContactVariable) payload.contactFields[val.crmContactVariable] = val.value;
                if (val.crmDealVariable) payload.dealVariables[val.crmDealVariable] = val.value;
            }
        });

        if (this.widgetData.selectedPipeline) payload.pipelineId = this.widgetData.selectedPipeline.id;
        if (this.widgetData.productName) payload.dealName = this.widgetData.dealName;
        if (this.widgetData.selectedPipelineStep) payload.pipelineStepId = this.widgetData.selectedPipelineStep.id;
        if (this.widgetData.selectedAddressBook) payload.addressBookId = this.widgetData.selectedAddressBook;
        if (this.widget) payload.elementId = this.widget.id;
        payload.createAddressBook = this.widgetData.createAddressBook;

        if (this.paymentElement.currency) paymentPayload.currency = this.paymentElement.currency;
        if (this.paymentElement.payment_method_id) paymentPayload.paymentMethodId = this.paymentElement.payment_method_id;
        if (this.paymentElement.type) paymentPayload.paymentType = this.isCustomPaymentType ? null : this.paymentElement.type;
        if (this.paymentElement.amount) paymentPayload.totalCost = this.amountControl.value.toString();
        if (this.widgetData.productName) paymentPayload.name = this.widgetData.productName;
        if (this.additionalFormSettings.openLink) paymentPayload.backUrl = this.getOpenLink();
        if (this.paymentElement?.rro_isActive) {
            switch (this.paymentElement.type) {
                case EPaymentType.liqpay:
                    paymentPayload.productId = Number(this.paymentElement?.rro_info?.id);
                    break;
                case EPaymentType.monobank:
                    paymentPayload.productCode = this.paymentElement?.rro_info?.id.toString();
            }
        }

        if (this.isCustomPaymentType) {
            paymentPayload.paymentMethodName = this.paymentElement.customPaymentName;
            delete paymentPayload.paymentMethodId;
        }

        return { ...payload, ...paymentPayload };
    }

    private prepareEduSubmitPayload(): any {
        return {
            ...this.preparePaymentPayload(),
            courseId: this.courseId,
            pageId: this.pageId,
            language: this.lang,
            promoCode: this.promoValueControl.hasError('invalidPromoCode') ? null : this.promoGroup?.value?.value,
            eduPaymentMethodId: this.paymentElement?.eduPaymentMethodId ?? null,
        };
    }

    public refreshErrorMessageValue() {
        this.showErrorMessage = !this.showErrorMessage;
    }

    private checkPromoCodeExists(): void {
        this.eduHttpService.getPromoCodeExists(this.transferStateService.get('userId'), this.courseId).subscribe((res) => {
            this.isHasPromoCode = res?.promoCodeExists;
            this.setPromoField();
        });
    }

    @HostListener('click', ['$event.target'])
    public hideErrorMessage(event) {
        if (this.showErrorMessage && !event.classList.contains('submit-btn')) {
            setTimeout(() => (this.showErrorMessage = false), 100);
        }
    }

    public changeAmount(event: Event): void {
        const value = (event.target as HTMLInputElement).value;
        this.amountControl.setValue(Number(value));
    }

    public validateAmount(): void {
        if (this.amountControl.value < this.paymentElement.amount) {
            this.amountControl.setValue(this.paymentElement.amount);
        }
    }

    public getSelected(options: Option[]) {
        return !options.some((el: Option) => el.selected);
    }

    public getInputIdForSelenium(control: AbstractControl): string {
        return `${this.widget.id}${control.value?.variable ? '-variable-' + control.value?.variable : ''}${
            control.value?.crmContactVariable ? '-crmContactVariable-' + control.value?.crmContactVariable : ''
        }${control.value?.crmDealVariable ? '-crmDealVariable-' + control.value?.crmDealVariable : ''}`;
    }

    public getPaymentElementLabel(paymentElementType: PaymentElement['type'], index: number): string {
        if (paymentElementType === EPaymentType.custom) {
            return this.paymentElements.at(index).customPaymentName;
        }

        return paymentsList[paymentElementType].label;
    }

    public changeCurrentPaymentMethodIndex(index: number): void {
        this.currentPaymentMethodIndex = index;
        this.amountControl.setValue(this.paymentElement.amount);

        if (this.promoValueControl) {
            this.promoValueControl.updateValueAndValidity();
        }

        if (this.isShowInvalidPaymentMethodError) {
            this.isShowInvalidPaymentMethodError = false;
        }
    }

    public get isProductTypeDefault(): boolean {
        return this.productType === ProductType.default;
    }

    public get isProductTypeEduCourse(): boolean {
        return this.productType === ProductType.eduCourse;
    }

    public get formArrayControls(): AbstractControl[] {
        return (this.formGroup.get('formArray') as FormArray).controls;
    }

    public get inputSettings(): PaymentFormWidgetData['inputSettings'] {
        return this.widgetData.inputSettings;
    }

    public get formButtonSettings(): PaymentFormWidgetData['formButtonSettings'] {
        return this.widgetData.formButtonSettings;
    }

    public get paymentElement(): PaymentFormWidgetData['paymentElements'][0] {
        return this.activePaymentElements[this.currentPaymentMethodIndex];
    }

    public get paymentElements(): PaymentFormWidgetData['paymentElements'] {
        return this.widgetData.paymentElements;
    }

    public get activePaymentElements(): PaymentElement[] {
        return this.widgetData.paymentElements.filter((el: PaymentElement) => el.isActive);
    }

    public get additionalFormSettings(): FormAdditionalSettings {
        return this.widgetData.additionalFormSettings;
    }

    public get productType(): PaymentFormWidgetData['productType'] {
        return this.widgetData.productType;
    }

    public get productSettings(): PaymentFormWidgetData['productSettings'] {
        return this.widgetData.productSettings;
    }

    private setFormValue() {
        if (!this.isProductTypeEduCourse) return;

        this.eduHttpService.getStudentInfo().subscribe({
            next: (data) => {
                this.formArrayControls.forEach((c: FormGroup) => {
                    for (const [key, value] of Object.entries(data)) {
                        if (c.value.type === key || c.value.crmContactVariable === key) {
                            c.controls.value.patchValue(value);
                        }
                    }
                });
            },
            error: () => {
                console.log('err');
            },
        });
    }

    public get courseId(): number {
        return this.productSettings.courseId;
    }

    public getPhoneErrorMsg() {
        return (document.getElementById('phone') as any).placeholder;
    }

    private checkEduCourseStatus(): void {
        this.eduHttpService
            .getCourseInfo(this.transferStateService.get('userId'), this.courseId)
            .pipe(
                tap(() => (this.isEduCourseError = false)),
                catchError(() => {
                    this.formGroup.markAllAsTouched();
                    this.formGroup.enable();

                    return of(null);
                }),
            )
            .subscribe((res) => {
                this.isEduCourseError = res?.status === 'blocked' || res?.status === 'unknown';
                this.changeDetectorRef.detectChanges();
            });
    }

    private sendGaEvent(currency, totalCost) {
        let paymentGoogleSettings: PaymentGoogleSettings = {
            currency: currency,
            value: totalCost,
            productName: this.widgetData.productName,
        }

        if (this.widgetData?.analyticsSettings?.googleAnalytic) {
            this.analyticsService.gaEventRequest(this.widgetData?.analyticsSettings?.googleSettings, true, paymentGoogleSettings);
        }
    }

    private sendPixelFbEvent(currency: string, value: string) {
        if (this.widgetData?.analyticsSettings?.pixelAnalytic) {
            this.analyticsService.pixelFbEventRequest({ ...this.widgetData?.analyticsSettings?.pixelSettings, currency, value });
        }
    }

    private getCountryISO() {
        this.countryISOService.getCountryISO().subscribe((data) => {
            this.CountryISO = data.country_code.toLowerCase();
            this.changeDetectorRef.detectChanges();
        });
    }

    private setPhoneEmailControlErrors(typeError: 'phone' | 'email') {
        this.formArrayControls.forEach((control) => {
            switch (typeError) {
                case 'email':
                    if (control.get('type').value === EFormInputType.email) {
                        control.get('value').setErrors({ email: true });
                    }
                    break;
                case 'phone':
                    if (control.get('type').value === EFormInputType.phone) {
                        control.get('value').setErrors({ phone: true });
                    }
                    break;
            }
        });
    }

    private checkPromoValidator(): AsyncValidatorFn {
        return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
            return timer(500).pipe(
                switchMap(() => {
                    if (!Boolean(control.value)) {
                        return of(null);
                    }

                    if (!control.dirty && control.pristine) {
                        return of(null);
                    }

                    return this.eduHttpService.usePromoCode(
                        this.pageId,
                        this.courseId,
                        this.paymentElement.type,
                        this.paymentElement.payment_method_id,
                        control.value,
                        this.paymentElement?.eduPaymentMethodId,
                    );
                }),
                map((response: { data: { price: number; fullPrice: number } }) => {
                    if (!response) return null;

                    this.promoGroup.patchValue({ fullPrice: response?.data?.fullPrice, price: response?.data?.price });
                    this.changeDetectorRef.detectChanges();

                    return null;
                }),
                catchError(({ error }: HttpErrorResponse) => {
                    control.markAsTouched();
                    this.promoGroup.patchValue({ fullPrice: null, price: null });
                    if (error?.errors?.paymentMethodId) {
                        return of({ invalidPaymentMethod: true });
                    } else {
                        return of({ invalidPromoCode: true });
                    }
                }),
            );
        };
    }

    public get isShowDiscountPrice(): boolean {
        if (!this.promoValueControl) return false;

        return this.promoValueControl?.value && !this.promoValueControl.pending && !this.promoValueControl.hasError('invalidPromoCode');
    }

    public get isShowPromoField(): boolean {
        return this.isHasPromoCode;
    }

    public get promoValueControl(): FormControl<string> {
        if (!this.promoGroup) return null;

        return this.promoGroup.get('value') as FormControl<string>;
    }

    private get isCustomPaymentType(): boolean {
        return this.paymentElement.type === EPaymentType.custom;
    }

    public getPaymentIconSrc(type: PaymentMethod['type']): string {
        return `${this.utilsService.formatImgSrc(`./assets/img/websites/payment-icons/sm/${paymentTypeNames[type]}-sm.svg`)}`;
    }

    public isShowSelectErr(control: AbstractControl) {
        const isSelected =
            this.getControlValue(control, 'options')
                .map((e) => e.selected)
                .indexOf(true) > -1;

        return this.getControlValue(control, 'required') && this.showErrorMessage && !isSelected;
    }

    public getOpenLink(): string {
        // if (![7845601, 672358].includes(this.transferStateService.get('userId'))) {
        //     return this.additionalFormSettings.openLink;
        // }

        const chatBotLinkType = this.isChatBotLink(this.additionalFormSettings.openLink);
        if (chatBotLinkType) {
            const formData = (this.formGroup.value.formArray as PaymentFormElement[]).reduce((result, el) => {
                if (el.variable === 'email' || el.variable === 'phone') {
                    result[el.variable] = el.value;
                } else {
                    result[el.label.trim()] = el.value;
                }
                return result;
            }, {});

            if (chatBotLinkType === 'telegram') {
                const start = this.additionalFormSettings.openLink.includes('?') ? '|' : '?';
                const telegramFormDataParams = this.joinChatBotLinkParams(formData, start, '|');

                let link = this.additionalFormSettings.openLink;

                if (link.includes('t.me')) {
                    link = link.replace('t.me', 'tg.pulse.is');
                }

                return encodeURI(link + telegramFormDataParams);
            }

            if (chatBotLinkType === 'messenger') {
                const start = this.additionalFormSettings.openLink.includes('?') ? '__' : '?';
                const messengerFormDataParams = this.joinChatBotLinkParams(formData, start, '__');

                return encodeURI(this.additionalFormSettings.openLink + messengerFormDataParams);
            }

            if (chatBotLinkType === 'instagram') {
                const start = this.additionalFormSettings.openLink.includes('?') ? '__' : '?';
                const instagramFormDataParams = this.joinChatBotLinkParams(formData, start, '__');

                return encodeURI(this.additionalFormSettings.openLink + instagramFormDataParams);
            }

            if (chatBotLinkType === 'viber') {
                const start = this.additionalFormSettings.openLink.includes('?') ? '|' : '?';
                const viberFormDataParams = this.joinChatBotLinkParams(formData, start, '|');

                return encodeURI(this.additionalFormSettings.openLink + viberFormDataParams);
            }

            if (chatBotLinkType === 'whatsapp') {
                const start = this.additionalFormSettings.openLink.includes('?') ? '&' : '?';
                const whatsappFormDataParams = this.joinChatBotLinkParams(formData, start, '&');

                return encodeURI(this.additionalFormSettings.openLink + whatsappFormDataParams);
            }

            console.warn('Unknown chat bot link type');
        }

        return this.additionalFormSettings.openLink;
    }

    private isChatBotLink(link: string): 'telegram' | 'messenger' | 'instagram' | 'viber' | 'whatsapp' | null {
        if (link.includes('t.me') || link.includes('tg.pulse.is')) {
            return 'telegram';
        }

        if (link.includes('m.me')) {
            return 'messenger';
        }

        if (link.includes('ig.me')) {
            return 'instagram';
        }

        if (link.includes('whatsapp.com')) {
            return 'whatsapp';
        }

        if (link.includes('viber://')) {
            return 'viber';
        }

        console.warn('Can not determine chat bot link type', link);
        return null;
    }

    private joinChatBotLinkParams(formData: Record<string, string>, start: string, delimiter: string): string {
        return Object.keys(formData).reduce((acc, key) => {
            if (!acc) {
                return `${start}${key}=${formData[key]}`;
            }
            return `${acc}${delimiter}${key}=${formData[key]}`;
        }, '');
    }
}
