import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { type Classes } from 'jss';
import { type FormControl, FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { type HttpErrorResponse, type HttpResponse } from '@angular/common/http';
import { EduHttpService, type LoginResponseData } from '@web-builder/core/services/REST/edu.http.service';
import { environment } from '@web-builder-env/environment';
import { BehaviorSubject, EMPTY, forkJoin, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { EduCourse } from '@common/types/edu-course.type';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ELoginEduDefaultForm } from '@common/enums/login-edu-default-form.enum';
import { UtmTagsService } from '@web-builder/core/services/utm-tags.service';
import { type SignInFormData, type SignInFormGroup } from '@web-builder/widgets/login-edu/type/login-edu.type';
import { TooltipDirective } from '@web-builder/core/directives/tooltip.directive';

@Component({
    selector: 'web-builder-sign-in-form',
    templateUrl: './sign-in-form.component.html',
    imports: [CommonModule, ReactiveFormsModule, FormsModule, TranslateModule],
    standalone: true,
})
export class SignInFormComponent {
    @Input() data: {
        classes: Classes;
        buttonStyles: string;
        buttonLabel: string;
    };
    @Input() public eduCourse!: EduCourse;
    @Input() public domainId!: number;
    @Input() public isRegistrationButtonActive!: boolean;

    @Output() recoveryPassEvent = new EventEmitter();
    @Output() signInSuccessEvent = new EventEmitter();
    @Output() changeFormEvent: EventEmitter<ELoginEduDefaultForm> = new EventEmitter<ELoginEduDefaultForm>();

    public form: SignInFormGroup | any;
    public showPassword: boolean = false;

    public loginType: 'password' | 'quicklogin' = 'password';
    public requestStatus: BehaviorSubject<'idle' | 'success' | 'error'> = new BehaviorSubject('idle');
    public passwordFormControl: FormControl = this.fb.control(null);

    constructor(
        private readonly fb: NonNullableFormBuilder,
        private readonly cookieService: CookieService,
        private readonly eduHttpService: EduHttpService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly utmTagsService: UtmTagsService,
    ) {
        this.initForm();
    }

    private initForm(): void {
        this.passwordFormControl = this.fb.control(null, [Validators.required, Validators.minLength(8)]);
        this.form = this.fb.group({
            email: this.fb.control(null, [Validators.email, Validators.required]),
        });

        this.toggleFormPassword();
    }

    public toggleFormPassword(): void {
        if (this.loginType === 'password') {
            this.form.setControl('password', this.passwordFormControl);
        } else if (this.loginType === 'quicklogin') {
            this.form.removeControl('password');
        }

        this.form.updateValueAndValidity();
    }

    public onSignIn(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.form.disable();
        of(this.loginType)
            .pipe(
                take(1),
                mergeMap((loginType) => {
                    const data: any = {
                        email: this.email.value,
                        domainId: this.domainId,
                        utm: this.utmTagsService.getUtmTags(),
                    };

                    if (loginType === 'password') {
                        data.password = this.password.value;
                        return forkJoin({ loginType: of(loginType), response: this.eduHttpService.login(data) });
                    }

                    return forkJoin({ loginType: of(loginType), response: this.eduHttpService.quickLoginEmail(data) });
                }),
                mergeMap(({ loginType, response }) => {
                    this.requestStatus.next('success');

                    const accessToken = response.headers.get('access_token');
                    const refreshToken = response.headers.get('refresh_token');

                    this.cookieService.set('oauth_access_token', accessToken, { path: '/' });
                    this.cookieService.set('oauth_refresh_token', refreshToken, { path: '/' });

                    if (loginType === 'password') {
                        if (!this.eduCourse?.id) {
                            window.location.href = environment.eduStudentUrl;
                            return;
                        }

                        this.signInSuccessEvent.emit();
                    }
                    return EMPTY;
                }),
                catchError((error: HttpErrorResponse) => {
                    this.requestStatus.next('error');

                    this.form.enable();

                    if (error.status === 401) {
                        this.form.setErrors({ invalidCredentials: true });

                        this.changeDetectorRef.detectChanges();
                    }

                    if (error.status === 403 || error.status === 404) {
                        const message = error?.error?.error?.message;

                        if (message === 'Student does not exist.') {
                            this.form.setErrors({ userNotFound: true });

                            this.changeDetectorRef.detectChanges();

                            return EMPTY;
                        }
                    }

                    if (error.status === 429) {
                        this.form.setErrors({ tooManyRequests: true });

                        this.changeDetectorRef.detectChanges();

                        return EMPTY;
                    }

                    this.changeDetectorRef.detectChanges();

                    return EMPTY;
                }),
            )
            .subscribe();
    }

    public toggleShowPassword(): void {
        this.showPassword = !this.showPassword;
    }

    public get email(): FormControl<SignInFormData['email']> {
        return this.form.get('email') as FormControl<SignInFormData['email']>;
    }

    public get password(): FormControl<SignInFormData['password']> {
        return this.form.get('password') as FormControl<SignInFormData['password']>;
    }

    public get isFormInvalid(): boolean {
        return this.form.hasError('invalidCredentials') || this.form.hasError('userNotFound') || this.form.hasError('tooManyRequests');
    }

    protected readonly ELoginEduDefaultForm = ELoginEduDefaultForm;
}
