import type { OnInit } from '@angular/core';
import type { TimerWidgetData } from '@web/widgets/timer';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { SpTimerModule } from '@common/components/sp-timer';

import { getStyles } from '@web/widgets/timer/components/timer-view/timer-view.component.styles';

import { AbstractWidgetComponent } from '@web-builder/widgets/abstract-widget.component';

@Component({
    selector: 'web-builder-timer',
    templateUrl: './timer.component.html',
    styleUrls: ['./timer.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, SpTimerModule],
})
export class TimerComponent extends AbstractWidgetComponent<TimerWidgetData> implements OnInit {
    protected getStyles = getStyles;
    
    public getWidgetId(widget) {
        return widget.displayId ? widget.displayId : `widget-${widget.id}`;
    }
}
