<div *ngIf="element; else emptyProductCard" [class]="classes?.previewMainWrapper">
    <div [class]="wrapperClass" (click)="!showMoreButton.isShowButton ? showCard() : null" [style.cursor]="!showMoreButton.isShowButton ? 'pointer' : 'default'">
        <div class="preview-image-container">
            <ng-container *ngIf="element.images.length; else emptyImage">
                <div *ngIf="data.previewModalSettings.gallery.aspectRatio !== aspectRatio.default"
                    class="image-wrapper resizeble"
                    [style.paddingTop.%]="getImagePaddingTop(element.images[0])">
                    <img [src]="formatImageURL(element.images[0])"alt="0">
                </div>
                <div *ngIf="data.previewModalSettings.gallery.aspectRatio === aspectRatio.default"
                    class="image-wrapper">
                    <img [src]="formatImageURL(element.images[0])" alt="0">
                </div>
            </ng-container>
            <ng-template #emptyImage>
                <div class="blank-wrapper">
                    <img [src]="formatImgSrc('./assets/img/websites/lpc-empty-product.png')" alt="product_blank">
                </div>
            </ng-template>
        </div>
        <div [class]="classes?.previewContentWrapper">
            <div class="left-side">
                <div class="card-title sp-ui-text">
                    <h2>{{ element.name }}</h2>
                </div>

                <div class="card-description sp-ui-text" *ngIf="previewModalSettings.typography.descriptionSettings.isShow">
                    <div [innerHTML]="element.shortDescription | spSafeHtml"></div>
                </div>
            </div>

            <div class="right-side">
                <div class="card-price-block sp-ui-text" *ngIf="element.price">
                    <p class="card-new-price" *ngIf="previewModalSettings.typography.priceSettings.isShow">{{ element.price}} {{ currency }}</p>
                    <p class="card-old-price" *ngIf="element.oldPrice && previewModalSettings.typography.oldPriceSettings.isShow"> {{ element.oldPrice }} {{ currency }}</p>
                </div>

                <div class="buttonWrapper">
                    <a *ngIf="showMoreButton.isShowButton" [class]="buttonClass" class="card-button" (click)="showCard()">
                        {{ showMoreButton.label }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #emptyProductCard>
    <div [class]="classes?.blankImageWrapper">
        <img [src]="formatImgSrc('./assets/img/websites/lpc-empty-product-icon.svg')" alt="product_blank">
    </div>
</ng-template>
