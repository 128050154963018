<div class="tabs">
    <div *ngFor="let type of BUTTON_TYPES" class="tab"
         [class.active]="currentBtnType === type"
         (click)="currentBtnType = type">
        {{ BUTTON_STYLE_TYPES_TRANSLATIONS[type] | translate }}
    </div>
</div>
<div class="panel-options-body">
    <div *ngFor="let type of BUTTON_TYPES; index as i">
        <div *ngIf="currentBtnType === type">
            <sp-themes-button-tab
                showHoverSettings
                [colorsPreset]="themeColors$ | async"
                [base]="themeButtons[i].base"
                [hover]="themeButtons[i].hover"
                [styles]="themeButtons[i].styles"
                (valueChangeEvent)="valueChangeHandler(type, $event)">
            </sp-themes-button-tab>
        </div>
    </div>
</div>

<div class="panel-options-footer">
    <button type="button" class="btn btn-default mr-10" (click)="onCancel()">{{ 'cancel' | translate }}</button>
    <button type="button" class="btn btn-success" (click)="onSave()">{{ 'save' | translate }}</button>
</div>
