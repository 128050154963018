import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import type { SafeHtml } from '@angular/platform-browser';
import type { SocialWidgetData } from '@web/widgets/social';
import type { SocialData } from '@web/widgets/social/type/social-widget.type';
import { CommonModule } from '@angular/common';

import { socialIconTemplates } from '@common/icons/social-icons';
import { EButtonStyleType, EButtonType, ESize, ESocialNetworks } from '@common/enums';

import { getStyles } from '@web/widgets/social/components/social-view/social-view.component.styles';

import { BUTTON_SIZE_CLASS_MAP, BUTTON_TYPE_CLASS_MAP, DEFAULT_BUTTON_CLASS } from '@web/widgets/common/base/base-button/base-button.const';
import { getSocialNetworkIconSrc } from '@web/shared/components/web-social-networks/utils';

import { AbstractWidgetComponent } from '@web-builder/widgets/abstract-widget.component';

@Component({
    selector: 'web-builder-social',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './social.component.html',
    styleUrls: ['./social.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialComponent extends AbstractWidgetComponent<SocialWidgetData> implements OnInit {
    public readonly EButtonType = EButtonType;
    protected getStyles = getStyles;

    public getStyleList(icon: SocialData): string {
        if (icon.type === EButtonType.icon) {
            return `${this.classes?.socialGroup}`;
        }

        let styles = `${DEFAULT_BUTTON_CLASS} ${BUTTON_SIZE_CLASS_MAP[this.widgetData.buttonSize ? this.widgetData.buttonSize : ESize.M]}`;
        if (this.socialStyleType === EButtonStyleType.custom) {
            return `${styles} ${this.classes?.socialGroup}`;
        }

        return `${styles} ${BUTTON_TYPE_CLASS_MAP[this.socialStyleType]} ${this.classes?.socialGroup}`;
    }

    public getIcon(iconName: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(socialIconTemplates[iconName]);
    }

    public getSocialIconSrcBySocial(social: SocialData): string {
        const folderName: string = social.type === EButtonType.button ? 'full-white' : social.iconType;
        const src = getSocialNetworkIconSrc(folderName, social.socialNetwork);
        return this.formatImgSrc(src);
    }

    public get socials(): SocialData[] {
        return this.widgetData.socials;
    }

    public get socialStyleType(): EButtonStyleType {
        return this.widgetData.styleType;
    }

    public getSocialType(social: SocialData): string {
        switch (social.socialNetwork) {
            case ESocialNetworks.email:
                return 'email';
            case ESocialNetworks.phone:
                return 'phone';
            default:
                return 'social';
        }
    }

    private getNormalizedLink(link: string, type: string): string {
        const trimmedLink = link?.trim();

        if (!trimmedLink) {
            return null;
        }

        if (type === 'email') {
            return this.linkFormatterService.formatEmailLink(trimmedLink);
        }

        if (type === 'phone') {
            return this.linkFormatterService.formatPhoneLink(trimmedLink);
        }

        return this.linkFormatterService.formatLink(trimmedLink);
    }

    private getSocialURL(socialData: SocialData): { url: string; searchParams?: URLSearchParams | null } {
        try {
            const url = new URL(socialData.link);
            if (socialData.socialNetwork === ESocialNetworks.viber) {
                const viberQueryParams = this.utmTagsService.parseViberQueryParams(url.search);
                let normalizedUrl = url.href;
                if (normalizedUrl.includes('?')) {
                    normalizedUrl = normalizedUrl.split('?')[0];
                }

                return {
                    url: normalizedUrl,
                    searchParams: viberQueryParams,
                };
            }

            if (socialData.socialNetwork === ESocialNetworks.skype) {
                return {
                    url: socialData.link,
                };
            }

            const normalizedUrl = url.origin + url.pathname;

            if (socialData.socialNetwork === ESocialNetworks.telegram) {
                const telegramQueryParams = this.utmTagsService.parseTelegramQueryParams(url.search);
                if (normalizedUrl.includes('t.me') && url.pathname.toLowerCase().includes('bot')) {
                    return {
                        url: normalizedUrl.replace('t.me', 'tg.pulse.is'),
                        searchParams: telegramQueryParams,
                    };
                }

                return {
                    url: normalizedUrl,
                    searchParams: telegramQueryParams,
                };
            }

            if (socialData.socialNetwork === ESocialNetworks.instagram) {
                const instagramQueryParams = this.utmTagsService.parseInstagramQueryParams(url.search);
                return {
                    url: normalizedUrl,
                    searchParams: instagramQueryParams,
                };
            }

            if (socialData.socialNetwork === ESocialNetworks.facebook) {
                const facebookQueryParams = this.utmTagsService.parseFacebookQueryParams(url.search);
                return {
                    url: normalizedUrl,
                    searchParams: facebookQueryParams,
                };
            }

            return {
                url: normalizedUrl,
                searchParams: url.searchParams,
            };
        } catch (e) {
            return {
                url: socialData.link,
            };
        }
    }

    private processLink(socialData: SocialData): string {
        const { url, searchParams } = this.getSocialURL(socialData);

        if (!url) {
            return '';
        }

        const utmTags = this.utmTagsService.getChatBotUtmTags({
            social: socialData.socialNetwork,
            chatBotSearchParams: searchParams,
        });
        if (!utmTags) {
            return url;
        }

        return url + utmTags;
    }

    public getSocialLink(social: SocialData): string {
        const socialType = this.getSocialType(social);

        if (socialType !== 'social') {
            return this.getNormalizedLink(social.link, socialType);
        }

        const normalizedLink = this.getNormalizedLink(this.processLink(social), socialType);
        if (!normalizedLink) {
            return null;
        }

        return normalizedLink;
    }
}
