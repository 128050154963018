import { CommonModule } from '@angular/common';
import { type AfterContentInit, Component, inject } from '@angular/core';
import { SwiperModule } from 'swiper/angular';

import Swiper, { Autoplay, EffectCards, EffectFade, FreeMode, Navigation, Pagination, Thumbs, type SwiperOptions } from 'swiper';

import { AbstractWidgetComponent } from '@web-builder/widgets/abstract-widget.component';
import { getStyles } from '@web/widgets/testimonial/components/testimonial-view/testimonial-view.component.styles';
import { type TestimonialWidgetData } from '@web/widgets/testimonial';
import { EButtonStyleType, ESize, ETestimonialLayout, ETestimonialType } from '@common/enums';
import { EduHttpService } from '@web-builder/core/services/REST/edu.http.service';
import { type Testimonial } from '@common/types/testimonial.type';
import { BehaviorSubject } from 'rxjs';
import { type TestimonialButtonSettings } from '@web/widgets/testimonial/type/testimonial-widget.type';
import { BUTTON_SIZE_CLASS_MAP, BUTTON_TYPE_CLASS_MAP, DEFAULT_BUTTON_CLASS } from '@web/widgets/common/base/base-button/base-button.const';
import { TranslateModule } from '@ngx-translate/core';
import { HideSwiperOnSsrDirective } from '@web-builder/core/directives/swiper-ssr.directive';

Swiper.use([Autoplay, Pagination, Thumbs, Navigation, EffectCards, EffectFade, FreeMode]);

@Component({
    selector: 'web-builder-testimonial',
    templateUrl: './testimonial.component.html',
    styleUrls: ['./testimonial.component.less'],
    standalone: true,
    imports: [CommonModule, SwiperModule, TranslateModule, HideSwiperOnSsrDirective],
})
export class TestimonialComponent extends AbstractWidgetComponent<TestimonialWidgetData> implements AfterContentInit {
    private readonly eduHttpService: EduHttpService = inject(EduHttpService);
    private readonly eduCdnUrl: string = '//1076228245.rsc.cdn77.org/';

    protected getStyles = getStyles;

    public isFreeTariff: boolean = this.transferStateService.get('eduTariff') === 'education_free';
    public BLANK_TESTIMONIAL_AVATAR = this.formatImgSrc('./assets/img/websites/lpc-avatar-example.svg');
    public BLANK_TESTIMONIAL = this.formatImgSrc('./assets/img/websites/testimonial-blank.svg');
    public ETestimonialLayout = ETestimonialLayout;
    public ETestimonialType = ETestimonialType;
    public swiperConfig: SwiperOptions = {
        navigation: {
            nextEl: '.swiper-button-next-unique',
            prevEl: '.swiper-button-prev-unique',
        },
    };
    public testimonialsList$ = new BehaviorSubject<Testimonial[]>([]);
    public testimonialsList: Testimonial[] = [];
    public total: number = null;
    public offset: number = 0;

    public ngAfterContentInit(): void {
        this.loadMore();
    }

    public getClassLayoutType(data): string {
        switch (data.settings.type) {
            case ETestimonialType.avatarTop:
                return `${this.classes?.testimonial} ${this.classes?.avatarTopType}`;
            case ETestimonialType.avatarLeft:
                return `${this.classes?.testimonial} ${this.classes?.avatarLeftType}`;
            default:
                return `${this.classes?.testimonial} ${this.classes?.noAvatarType}`;
        }
    }

    public showFullTextGrid(ev: Event, id: string): void {
        const textEl = document.getElementById(id);
        textEl.style.webkitLineClamp = 'unset';
        this.hideElement(ev);
    }

    public showFullTextSlider(ev: Event, id: string): void {
        const textEl = document.getElementsByClassName(id);
        Array.from(textEl).forEach((el) => ((el as HTMLElement).style.webkitLineClamp = 'unset'));
        this.hideElement(ev);
    }

    private hideElement(ev: Event): void {
        (ev.target as HTMLElement).style.display = 'none';
    }

    public imgSrc(src: string): string {
        return `${this.eduCdnUrl}${src}`;
    }

    public getSlidesPerView(totalItems: number, itemsInRow: number): number {
        return totalItems >= itemsInRow ? itemsInRow : totalItems;
    }

    public get autoplay() {
        if (!this.widgetData.settings.isAutoScroll) return false;

        return {
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        };
    }

    public get courseId(): number {
        return this.widgetData.selectedCourseId;
    }

    public get limit(): number {
        return this.widgetData.layout === ETestimonialLayout.grid ? this.widgetData.settings.rowItems : 1000;
    }

    public loadMore() {
        this.eduHttpService
            .getTestimonials(this.transferStateService.get('userId'), this.courseId, this.offset, this.limit)
            .subscribe((res) => {
                this.testimonialsList.push(...res.data);
                this.testimonialsList$.next(this.testimonialsList);
                this.total = this.total ? this.total : res.meta.total;
                this.offset += this.limit;
            });
    }

    public getButtonStyles(formButtonSettings: TestimonialButtonSettings): string {
        let styles = `${DEFAULT_BUTTON_CLASS} ${BUTTON_SIZE_CLASS_MAP[ESize.M]}`;

        if (formButtonSettings.styleType === EButtonStyleType.custom) {
            return `${styles} ${this.classes?.buttonStyles}`;
        }

        return `${styles} ${BUTTON_TYPE_CLASS_MAP[formButtonSettings.styleType]} ${this.classes?.buttonStyles}`;
    }
}
