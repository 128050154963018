import { ChangeDetectionStrategy, Component, inject, ViewChild, type OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AbstractWidgetComponent } from '@web-builder/widgets/abstract-widget.component';

import type { MapWidgetData } from '@web/widgets/map';
import { getStyles } from '@web/widgets/map/components/map-view/map-view.component.styles';
import { GoogleMapsModule, MapMarker, MapInfoWindow } from '@angular/google-maps';
import { MapsService } from '@web-builder/core/services/maps.service';
import { mapStyles } from '@web/widgets/map/model/map-styles';

@Component({
    selector: 'web-builder-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, GoogleMapsModule],
})
export class MapComponent extends AbstractWidgetComponent<MapWidgetData> implements OnInit {
    @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
    @ViewChild(MapMarker, { static: false }) marker: MapMarker;

    protected getStyles = getStyles;

    private mapsService = inject(MapsService);
    mapsApiLoaded: boolean = false;

    ngOnInit() {
        if (this.platformService.isPlatformServer()) return;

        super.ngOnInit();
        this.mapsService.loadMapsAPi().then((val) => {
            this.mapsApiLoaded = !!val;
            this.changeDetectorRef.detectChanges();
        });
    }

    openWindow(map: google.maps.Map) {
        map.setOptions({ styles: mapStyles[this.widgetData.mapStyle] || [] });
    }

    public mapOptions: google.maps.MapOptions = {
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        gestureHandling: 'cooperative',
        minZoom: 1,
        maxZoom: 22,
    };

    prevOpen: MapInfoWindow = null;
    openInfo(window: MapInfoWindow, marker: MapMarker) {
        if (this.prevOpen) {
            this.prevOpen.close();
        }

        window.open(marker);
        this.prevOpen = window;
    }
}
