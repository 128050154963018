<div class="payment-form-content" [class]="rootFormWithPositionClasses">
    <form class="sp-lpc-form" [formGroup]="formGroup">
        <div class="sp-ui-text" [class]="classes?.paymentFormDescription">
            <h2 class="payment-modal-title">
                {{ widgetData.paymentFormName }}
            </h2>
            <ng-container *ngIf="isProductTypeDefault">
                <p class="payment-modal-product-name">
                    {{ widgetData.productName }}
                </p>
                <p *ngIf="!paymentElement.isEditableTotalCost" class="payment-modal-product-price">
                    {{ paymentElement.amount }} {{ paymentElement.currency }}
                </p>
            </ng-container>
        </div>

        <ng-container *ngIf="!isEduCourseError; else showEduCoursesError">
            <div *ngIf="paymentElement.isEditableTotalCost" class="min-amount-control" [class]="classes.formField">
                <label>{{ "amount" | translate }}</label>
                <div class="input-group">
                    <input
                        [class]="classes.formControl"
                        [value]="amountControl.value"
                        [min]="paymentElement.amount"
                        type="number"
                        (change)="changeAmount($event)"
                        (blur)="validateAmount()"
                    />

                    <div class="input-group-addon">
                        {{ paymentElement.currency }}
                    </div>
                </div>

                <div class="sp-ui-text">
                    <p>
                        <small>{{
                                "minimal_amount" | translate : { PRICE: paymentElement.amount, CURRENCY: paymentElement.currency }
                            }}</small>
                    </p>
                </div>
            </div>

            <span class="modal-divider" *ngIf="isProductTypeDefault"></span>

            <div formArrayName="formArray">
                <div
                    *ngFor="let control of formArrayControls; index as index"
                    class="field-block"
                    [class]="classes.formField"
                    [formGroupName]="index"
                    [ngSwitch]="true"
                >
                    <ng-container *ngSwitchCase="typeGuards.isInput(control.value)">
                        <label [class.hidden]="control.value.type === formInputType.hidden" *ngIf="inputSettings.showLabels">
                            {{ getControlValue(control, "label")}}{{ getControlValue(control, "required") ? " *" : "" }}
                        </label>
                        <ng-container *ngIf="!control.value.isDynamicPlaceholderEnabled">
                            <input
                                [id]="getInputIdForSelenium(control)"
                                [class]="classes.formControl"
                                [class.is-invalid]="control.invalid"
                                [formControlName]="'value'"
                                [type]="getControlValue(control, 'htmlInputType')"
                                [placeholder]="getControlValue(control, 'placeholder') | translate"
                                [value]="control.value.type === formInputType.hidden ? getControlValue(control, 'value') : ''"
                                (focusout)="refreshErrorMessageValue()"
                            />
                        </ng-container>
                        <ng-container *ngIf="control.value.type === 'phone' && control.value.isDynamicPlaceholderEnabled">
                            <ngx-intl-tel-input
                                [class]="'intl-size-' + inputSettings.inputSize"
                                [formControlName]="'value'"
                                [cssClass]="classes?.formControl"
                                [class.is-invalid]="control.invalid"
                                [enableAutoCountrySelect]="true"
                                [selectFirstCountry]="false"
                                [separateDialCode]="true"
                                [enablePlaceholder]="true"
                                [searchCountryFlag]="true"
                                [phoneValidation]="true"
                                [searchCountryField]="[SearchCountryField.All]"
                                [excludeCountries]="excludeCountries"
                                [selectedCountryISO]="CountryISO"
                                [numberFormat]="PhoneNumberFormat.International">
                            </ngx-intl-tel-input>
                        </ng-container>

                        <ng-container *ngIf="control.invalid && control.touched && showErrorMessage">
                            <div class="text-error-msg-container">
                                <div class="text-error-msg">
                                    {{ inputErrors(control)[0] | translate }}
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="getControlValue(control, 'htmlInputType') === 'tel' && control.invalid && control.touched && control.value.isDynamicPlaceholderEnabled">
                            <div class="text-error-msg-container">
                                <div class="text-error-msg">{{ 'control_errors_phone_format' | translate: { PHONE_FORMAT: getPhoneErrorMsg() } }}</div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="typeGuards.isTextarea(control.value)">
                        <label *ngIf="inputSettings.showLabels">
                            {{ getControlValue(control, "label") }}{{ getControlValue(control, "required") ? " *" : "" }}
                        </label>
                        <textarea
                            [class]="classes?.formControlTextarea"
                            [class.is-invalid]="control.invalid"
                            class="sp-form-input"
                            [formControlName]="'value'"
                            [placeholder]="getControlValue(control, 'placeholder')"
                            (focusout)="refreshErrorMessageValue()"></textarea>

                        <ng-container *ngIf="control.invalid && control.touched && showErrorMessage">
                            <div class="text-error-msg-container">
                                <div class="text-error-msg">{{ inputErrors(control)[0] | translate }}</div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="typeGuards.isCheckbox(control.value)">
                        <label class="sp-checkbox">
                            <input
                                [id]="getInputIdForSelenium(control)"
                                [class]="classes.spCheckbox"
                                [value]="getControlValue(control, 'value')"
                                [type]="getControlValue(control, 'htmlInputType')"
                                (change)="changeCheckBoxValue(control)"
                            />
                            <span *ngIf="getControlValue(control, 'required')" class="checkbox-text" [innerHTML]="' ' + (getControlValue(control, 'label') | removePTags) + ' *'"></span>
                            <span *ngIf="!getControlValue(control, 'required')" class="checkbox-text" [innerHTML]="' ' + (getControlValue(control, 'label') | removePTags)"></span>
                        </label>
                        <ng-container *ngIf="control.invalid && control.touched && showErrorMessage">
                            <div class="text-error-msg-container">
                                <div class="text-error-msg">
                                    {{ inputErrors(control)[0] | translate }}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="typeGuards.isRadio(control.value)">
                        <label *ngIf="inputSettings.showLabels">
                            {{ getControlValue(control, "label") }}{{ getControlValue(control, "required") ? " *" : "" }}
                        </label>
                        <div class="radio-options" *ngFor="let option of getControlValue(control, 'options')">
                            <label class="sp-radio">
                                <input
                                    [id]="getInputIdForSelenium(control)"
                                    [class]="classes.spRadio"
                                    [type]="getControlValue(control, 'htmlInputType')"
                                    [formControlName]="'value'"
                                    [checked]="option.selected"
                                    (change)="changeCheckRadioValue(control, option)"
                                />
                                <span> {{ option.label }}</span>
                            </label>
                        </div>
                        <ng-container *ngIf="control.invalid && control.touched">
                            <div class="text-error-msg-container">
                                <div class="text-error-msg">{{ inputErrors(control)[0] | translate }}</div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="typeGuards.isSelect(control.value)">
                        <label *ngIf="inputSettings.showLabels">
                            <label *ngIf="inputSettings.showLabels">
                                {{ getControlValue(control, "label") }}{{ getControlValue(control, "required") ? " *" : "" }}
                            </label>
                        </label>
                        <ng-select
                            appendTo="body"
                            [class]="classes?.formControl"
                            [id]="getInputIdForSelenium(control)"
                            [placeholder]="'select_value' | translate"
                            [items]="getControlValue(control, 'options')"
                            (change)="changeSelectValue(control, $event)"
                            [(ngModel)]="getControlValue(control, 'htmlInputType') === 'multiselect' ? dropdownListValues[index] : dropdownListValues[index][0]"
                            [ngModelOptions]="{ standalone: true }"
                            [multiple]="getControlValue(control, 'htmlInputType') === 'multiselect'"
                            [closeOnSelect]="false">
                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                <div class="ng-value" *ngFor="let item of items">
                                    <span class="ng-value-icon left" (click)="clear(item);" aria-hidden="true">×</span>
                                    <span class="ng-value-label">{{ item.label }}</span>
                                </div>
                            </ng-template>
                        </ng-select>
                        <ng-container *ngIf="isShowSelectErr(control)">
                            <div class="text-error-msg-container">
                                <div class="text-error-msg">{{ 'control_errors_required' | translate }}</div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>

            <ng-container *ngIf="activePaymentElements.length > 1">
                <div [class]="classes?.formField">
                    <label *ngIf="inputSettings.showLabels">{{ "payment_method" | translate }}</label>
                    <div class="type-dropdown-default" dropdown>
                        <button
                            class="btn btn-default dropdown-toggle custom-btn"
                            data-toggle="dropdown"
                            dropdownToggle>
                            <div>
                                <img
                                    [src]="getPaymentIconSrc(paymentElement.type)"
                                    [alt]="paymentElement.type"
                                />
                                <span>{{ getPaymentElementLabel(paymentElement.type, currentPaymentMethodIndex) | translate }}</span>
                            </div>
                            <span class="caret"></span>
                        </button>

                        <ul class="dropdown-menu" *dropdownMenu>
                            <ng-container *ngFor="let payment of activePaymentElements; let i = index">
                                <li (click)="changeCurrentPaymentMethodIndex(i)" [class.selected]="i === currentPaymentMethodIndex">
                                    <img
                                        [src]="getPaymentIconSrc(payment.type)"
                                        [alt]="getPaymentElementLabel(payment.type, i) | translate"
                                    />
                                    <span>{{ getPaymentElementLabel(payment.type, i) | translate }}</span>
                                </li>
                            </ng-container>
                        </ul>

                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="isShowPromoField">
                <div class="field-block" [class]="classes.formField" [formGroup]="promoGroup">
                    <label>
                        {{ promoGroup.value.label | translate }}
                    </label>
                    <input
                        [class]="classes.formControl"
                        [class.is-invalid]="promoValueControl.invalid"
                        [formControlName]="'value'"
                        type="text"
                        [placeholder]="promoGroup.value.placeholder | translate"
                        [value]="promoValueControl.value"
                    />
                    <ng-container *ngIf="promoValueControl.invalid">
                        <div class="text-error-msg-container">
                            <div class="text-error-msg" *ngIf="promoValueControl.hasError('invalidPromoCode')">
                                {{ 'control_promo_error' | translate }}
                            </div>
                            <div class="text-error-msg" *ngIf="promoValueControl.hasError('invalidPaymentMethod')">
                                {{ 'control_promo_payment_error' | translate }}
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            <ng-container *ngIf="isProductTypeEduCourse">
                <span class="modal-divider"></span>
                <div class="sp-ui-text" [class]="classes?.paymentFormDescription">
                    <p class="payment-modal-product-name">
                        {{ widgetData.productName }}
                    </p>
                    <ng-container *ngIf="isShowDiscountPrice;else priceBlock">
                        <div class="payment-modal-product-price-promo">
                            <p>{{ promoGroup.value?.fullPrice }} {{ paymentElement.currency }}</p>
                            <p class="payment-modal-product-price">{{ promoGroup.value.price }} {{ paymentElement.currency }}</p>
                        </div>
                    </ng-container>
                    <ng-template #priceBlock>
                        <p *ngIf="!paymentElement.isEditableTotalCost" class="payment-modal-product-price">
                            {{ paymentElement.amount }} {{ paymentElement.currency }}
                        </p>
                    </ng-template>
                </div>
            </ng-container>

            <div [class]="classes?.buttonWrapper">
                <a
                    [id]="widget.id + '-submit'"
                    class="submit-btn"
                    [class]="getButtonStyles(formButtonSettings)"
                    [class.sp-ui-button-loading]="formGroup.disabled"
                    [class.disabled]="isShowInvalidPaymentMethodError"
                    (click)="submitForm()">
                        <span *ngIf="formButtonSettings.buttonIcon?.isActive"
                              class="icon"
                              [innerHTML]="getIcon(formButtonSettings.buttonIcon?.icon)"></span>
                    {{ formButtonSettings.label }}
                </a>
            </div>

            <div *ngIf="isShowInvalidPaymentMethodError" class="error-reason text-danger">
                {{ "invalid_payment_method_error" | translate }}
            </div>

            <div *ngIf="isShowSuccessMessage" style="margin-top: 15px;color: #7fbc6c;">
                {{ "custom_payment_success" | translate : { PAYMENT_NAME: getPaymentElementLabel(paymentElement.type, currentPaymentMethodIndex)  }  }}
            </div>

            <div class="modal-hint-text" *ngIf="!!widget.data.modalTextHint" [innerHtml]="widget.data.modalTextHint | spSafeHtml"></div>
        </ng-container>

        <ng-template #showEduCoursesError>
            <div class="error-alert">
                <p><b>{{ 'payment_error' | translate }}</b></p>
                <p>{{ 'payment_error_admin' | translate }}</p>
            </div>
        </ng-template>
    </form>
</div>
