import type { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import type { SubscriptionResponseType } from '@common/types/subscription-response.type';
import { TransferStateService } from '@web-builder/core/services/transfer-state.service';
import { getAnalyticsHeaders } from '@sites/analytics/hooks';

@Injectable({
    providedIn: 'root',
})
export class CrmHttpService {
    constructor(private http: HttpClient, private transferStateService: TransferStateService) {}

    public getSubscription(payload: any): Observable<SubscriptionResponseType> {
        return this.http.post<SubscriptionResponseType>(
            `${this.transferStateService.getResourceUrl()}/api/landings-service/public/subscribe`,
            payload,
            {
                headers: getAnalyticsHeaders(),
            },
        );
    }
}
