import type { JssStyle } from 'jss';
import type { FontFamilyPresetPair, ThemeFontPreset } from '@libs/themes/src/types/theme-font.type';

import { hashCode } from '@common/helpers';

import { EThemeFontTags } from '@libs/themes/src/enums/font-tags.enum';
import { DEFAULT_FONT_SIZES } from '@libs/themes/src/constants/font-sizes.const';
import { DEFAULT_FONT_LINE_HEIGHT } from '@libs/themes/src/constants/font-height.const';
import { DEFAULT_FONT_LETTER_SPACING } from '@libs/themes/src/constants/font-spacing.const';
import { FONT_FAMILY_RULES } from '@libs/themes/src/constants/font-families.conts';

import { DEFAULT_WEBSITE_FONT_FAMILY_PRESET_PAIRS } from '../constants/default-presets';

export function getWebsiteThemeFontPresets(): ThemeFontPreset[] {
    const common: JssStyle = {
        fontStyle: 'normal',
        textTransform: 'none',
    };

        const tagsOrder: EThemeFontTags[] = [
            EThemeFontTags.h2,
            EThemeFontTags.p,
            EThemeFontTags.button,
            EThemeFontTags.h1,
            EThemeFontTags.h3,
            EThemeFontTags.h4,
            EThemeFontTags.leading,
            EThemeFontTags.big,
            EThemeFontTags.small,
            EThemeFontTags.quote,
            EThemeFontTags.signature,
            EThemeFontTags.pre,
        ];

    return DEFAULT_WEBSITE_FONT_FAMILY_PRESET_PAIRS.map((presetPairs: FontFamilyPresetPair) => ({
        index: hashCode(presetPairs),
        values: tagsOrder.map((tag: EThemeFontTags) => {
            if (tag === EThemeFontTags.p) {
                const fontStyles = {
                    ...common,
                    fontFamily: FONT_FAMILY_RULES[presetPairs.paragraph],
                    fontSize: DEFAULT_FONT_SIZES[tag],
                    fontWeight: 'normal',
                    lineHeight: DEFAULT_FONT_LINE_HEIGHT[tag],
                    letterSpacing: DEFAULT_FONT_LETTER_SPACING[tag],
                };

                return {
                    font: presetPairs.paragraph,
                    tag,
                    styles: fontStyles,
                    mobileStyles: fontStyles,
                    isSynchronized: true,
                };
            }

            if (tag === EThemeFontTags.quote) {
                const fontStyles = {
                    ...common,
                    fontFamily: FONT_FAMILY_RULES[presetPairs.header],
                    fontSize: DEFAULT_FONT_SIZES[tag],
                    fontWeight: 'bold',
                    lineHeight: DEFAULT_FONT_LINE_HEIGHT[tag],
                    letterSpacing: DEFAULT_FONT_LETTER_SPACING[tag],
                    fontStyle: 'italic',
                };

                return {
                    font: presetPairs.header,
                    tag,
                    styles: fontStyles,
                    mobileStyles: fontStyles,
                    isSynchronized: true,
                };
            }

            const fontStyles = {
                ...common,
                fontFamily: FONT_FAMILY_RULES[presetPairs.header],
                fontSize: DEFAULT_FONT_SIZES[tag],
                fontWeight: 'bold',
                lineHeight: DEFAULT_FONT_LINE_HEIGHT[tag],
                letterSpacing: DEFAULT_FONT_LETTER_SPACING[tag],
            };

            return {
                font: presetPairs.header,
                tag,
                styles: fontStyles,
                mobileStyles: fontStyles,
                isSynchronized: true,
            };
        }),
    }));
}
