import { Component, ChangeDetectionStrategy, inject, type AfterContentInit } from '@angular/core';
import { DomSanitizer, type SafeHtml } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

import { CartFacade } from '@web-builder/store/cart/cart.facade';

import { SpDirectivesModule } from '@common/directives/directives.module';
import { SpPipesModule } from '@common/pipes/pipes.module';
import { CART_ICON_TEMPLATES } from '@common/constants/car-icon.const';
import { ESize } from '@common/enums';
import { TransferStateService } from '@web-builder/core/services/transfer-state.service';
import { type ShopCartIconSettingsFromServerType } from '@common/types/shop-cart-icon-settings.type';
import { type Observable, of } from 'rxjs';
import { PlatformService } from '@common/services/platform.service';

import { TranslateModule } from '@ngx-translate/core';
import { ShopCartHttpService } from '@web-builder/core/services/REST/shop-cart.http.service';
import { catchError } from 'rxjs/operators';

@Component({
    selector: 'web-builder-shop-cart-icon-view',
    templateUrl: './shop-cart-icon.component.html',
    styleUrls: ['./shop-cart-icon.component.less'],
    imports: [CommonModule, SpDirectivesModule, SpPipesModule, TranslateModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class ShopCartIconComponent implements AfterContentInit {
    public readonly cartFacade: CartFacade = inject(CartFacade);
    public readonly cartNumberOfItems$: Observable<number> = this.cartFacade.selectCartNumberOfItems$;
    public readonly cartIcon: ShopCartIconSettingsFromServerType = this.transferStateService.get('site')?.settings?.shop?.cart_icon;
    public readonly shopEnabled: ShopCartIconSettingsFromServerType = this.transferStateService.get('site')?.settings?.shop?.enabled;

    constructor(
        private readonly sanitizer: DomSanitizer,
        private readonly transferStateService: TransferStateService,
        private readonly platformService: PlatformService,
        private readonly shopCartHttpService: ShopCartHttpService,
    ) {}

    public ngAfterContentInit(): void {
        if (this.platformService.isPlatformBrowser()) {
            const cartId = window.localStorage.getItem('cartId');
            const userId = this.transferStateService.get('userId');

            if (cartId) {
                this.shopCartHttpService
                    .getCartStatus(cartId, userId)
                    .pipe(
                        catchError(() => {
                            this.cartFacade.getCartLocalStore();
                            return of(null);
                        }),
                    )
                    .subscribe((data) => {
                        if (data) window.localStorage.clear();
                        this.cartFacade.getCartLocalStore();
                    });
            } else {
                this.cartFacade.getCartLocalStore();
            }
        }
    }

    public getIcon(): SafeHtml {
        if (this.shopEnabled === null) return;

        return this.sanitizer.bypassSecurityTrustHtml(CART_ICON_TEMPLATES[this.cartIcon.icon_type]);
    }

    public getIconStyles() {
        if (this.shopEnabled === null) return;

        return {
            borderRadius: `${this.cartIcon.icon_rounding}px`,
            background: this.cartIcon.icon_bg_color,
            color: this.cartIcon.icon_color,
            height: this.previewIconSize(this.cartIcon.icon_size),
            width: this.previewIconSize(this.cartIcon.icon_size),
        };
    }

    public getCounterStyles() {
        if (this.shopEnabled === null) return;

        return {
            background: this.cartIcon.icon_counter_bg_color,
            color: this.cartIcon.icon_counter_color,
        };
    }

    public previewIconSize(size: string): string {
        switch (size) {
            case ESize.S:
                return '40px';
            case ESize.M:
                return '48px';
            case ESize.L:
                return '56px';
            default:
                return '48px';
        }
    }

    public cartIconClick(): void {
        const params = new URL(window.location.href).search;
        window.open(`${window.location.origin}/cart${params}`, '_self');
    }
}
