<ng-container *ngIf="!widgetData.images.length">
    <div [class]="classes.imageWrapper">
        <img [class]="classes.gridImage" [src]="BLANK_IMAGE" />
    </div>
</ng-container>

<ng-container *ngIf="widgetData.images.length">
    <lightgallery
        *ngIf="widgetData.options.clickable && platformService.isPlatformBrowser()"
        [settings]="gallerySettings"
        [onInit]="init"
    ></lightgallery>
    <div [class]="classes.galleryWrapper">
        <ng-container *ngIf="!isCarousel">
            <div class="sp-gallery-group" [class]="classes.gridContainer" *ngIf="!isMasonry">
                <div class="sp-gallery-item" [class]="classes.gridItem" *ngFor="let image of images; index as i; trackBy: instanceId">
                    <div
                        [class]="classes.imageWrapper"
                        (click)="openGallery(i)"
                        [style.paddingTop.%]="(widgetData.images[i].resizedHeight / widgetData.images[i].resizedWidth) * 100"
                    >
                        <a
                            class="sp-image-root"
                            [attr.href]="link(image)"
                            [attr.target]="image?.isTargetBlank ? '_blank' : '_self'"
                            [class]="classes?.imgWrapper"
                            (mouseleave)="mouseImgOut($event)"
                            (mouseenter)="mouseImgIn($event)">

                            <img
                                *ngIf="image?.overlayImage && image?.isImageEffect"
                                [class]="classes?.gridImage + ' shape '"
                                [ngClass]="{ 'overlayImage': overlayImageSrc(image) }"
                                [style.objectPosition]="image && (image.backgroundPositionX || 50) + '% ' + (image.backgroundPositionY || 50) + '%'"
                                [src]="overlayImageSrc(image)"
                                [alt]="image?.overlayImage?.name"/>
                            <ng-container *ngIf="image?.hasResize; else displayDefault">
                                <picture>
                                    <source
                                        *ngFor="let source of imagesSrcSets[i]"
                                        [srcset]="source.url"
                                        [media]="source.media"
                                    />
                                    <img
                                        class="sp-gallery-image"
                                        [class]="classes?.gridImage + ' shape ' + classes['image' + i]"
                                        [ngClass]="{ 'mainImage': overlayImageSrc }"
                                        [ngStyle]="{ '-webkit-mask-image': image.isImageEffect && image.maskShape ? getMaskUrl(image) : '' }"
                                        [src]="formatImageURL(image)"
                                        [alt]="image.alt"
                                        [style.objectPosition]="(image.backgroundPositionX || 50) + '% ' + (image.backgroundPositionY || 50) + '%'"
                                    />
                                </picture>
                            </ng-container>
                            <ng-template #displayDefault>
                                <img
                                    class="sp-gallery-image"
                                    [class]="classes?.gridImage + ' shape ' + classes['image' + i]"
                                    [ngClass]="{ 'mainImage': overlayImageSrc(image) }"
                                    [ngStyle]="{ '-webkit-mask-image': image.isImageEffect && image.maskShape ? getMaskUrl(image) : '' }"
                                    [src]="formatImageURL(image)"
                                    [alt]="image.alt"
                                    [style.objectPosition]="(image.backgroundPositionX || 50) + '% ' + (image.backgroundPositionY || 50) + '%'"
                                />
                            </ng-template>
                            <div *ngIf="image.isImageEffect" class="sp-gallery-description" [class]="classes['imageDescription' + i]">
                                <div [class]="classes['text' + i]">
                                    <span>{{ image?.description }}</span>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="isMasonry">
                <div class="sp-gallery-group" [class]="classes.gridContainer">
                    <div *ngFor="let item of masonryArrays; index as columnIndex; trackBy: instanceId" class="grid">
                        <div *ngFor="let image of item; index as imageIndex; trackBy: instanceId" [class]="classes.gridItem" class="sp-gallery-item">
                            <div
                                [class]="classes.imageWrapper"
                                (click)="openMasonryGallery(columnIndex, imageIndex)"
                                [style.paddingTop.%]="
                                    (widgetData.images[imageIndex * masonryArrays.length + columnIndex].resizedHeight /
                                        widgetData.images[imageIndex * masonryArrays.length + columnIndex].resizedWidth) *
                                    100
                                "
                            >
                                <a
                                    class="sp-image-root"
                                    [attr.href]="link(image)"
                                    [attr.target]="image?.isTargetBlank ? '_blank' : '_self'"
                                    [class]="classes?.imgWrapper"
                                    (mouseleave)="mouseImgOut($event)"
                                    (mouseenter)="mouseImgIn($event)">

                                    <img
                                        *ngIf="image?.overlayImage && image?.isImageEffect"
                                        [class]="classes?.gridImage + ' shape '"
                                        [ngClass]="{ 'overlayImage': overlayImageSrc }"
                                        [style.objectPosition]="image && (image.backgroundPositionX || 50) + '% ' + (image.backgroundPositionY || 50) + '%'"
                                        [src]="overlayImageSrc(image)"
                                        [alt]="image?.overlayImage?.name"/>
                                    <ng-container *ngIf="image?.hasResize; else displayDefault">
                                        <picture>
                                            <source
                                                *ngFor="let source of imagesSrcSets[imgIndex(columnIndex, imageIndex)]"
                                                [srcset]="source.url"
                                                [media]="source.media"
                                            />
                                            <img
                                                class="sp-gallery-image"
                                                [class]="classes?.gridImage + ' shape ' + classes['image' + columnIndex + '-' + imageIndex]"
                                                [ngStyle]="{ '-webkit-mask-image': image.isImageEffect && image.maskShape ? getMaskUrl(image) : '' }"
                                                [src]="formatImageURL(image)"
                                                [alt]="image.alt"
                                                [style.objectPosition]="(image.backgroundPositionX || 50) + '% ' + (image.backgroundPositionY || 50) + '%'"
                                            />
                                        </picture>
                                    </ng-container>
                                    <ng-template #displayDefault>
                                        <img
                                            class="sp-gallery-image"
                                            [class]="classes?.gridImage + ' shape ' + classes['image' + columnIndex + '-' + imageIndex]"
                                            [ngStyle]="{ '-webkit-mask-image': image.isImageEffect && image.maskShape ? getMaskUrl(image) : '' }"
                                            [src]="formatImageURL(image)"
                                            [alt]="image.alt"
                                            [style.objectPosition]="(image.backgroundPositionX || 50) + '% ' + (image.backgroundPositionY || 50) + '%'"
                                        />
                                    </ng-template>
                                    <div *ngIf="image.isImageEffect" class="sp-gallery-description" [class]="classes['imageDescription' + columnIndex + '-' + imageIndex]">
                                        <div [class]="classes['text' + columnIndex + '-' + imageIndex]">
                                            <span>{{ image.description }}</span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
    </ng-container>

        <ng-container *ngIf="isCarousel">
            <swiper
                #swiperComponent
                class="default-swiper"
                [class.with-bottom-navigation]="isHasBottomSpace()"
                [config]="swiperConfig"
                *ngIf="carouselOptions.type === 'default'"
                style="--swiper-navigation-color: {{ carouselOptions.knobsColor }}; --swiper-pagination-color: #000"
                [rewind]="true"
                [navigation]="true"
                [pagination]="{ clickable: true, dynamicBullets: true }"
                [grabCursor]="true"
                [autoplay]="autoplay"
                [spaceBetween]="carouselOptions.imageGap"
                [slidesPerView]="carouselOptions.rowItems"
            >
                <ng-template swiperSlide *ngFor="let image of widgetData.images; index as i; trackBy: instanceId">
                    <div
                        class="sp-gallery-item"
                        [class]="classes.imageWrapper"
                        [style.paddingTop.%]="(widgetData.images[i].resizedHeight / widgetData.images[i].resizedWidth) * 100"
                        (click)="openGallery(i)"
                    >
                        <a
                            class="sp-image-root"
                            [attr.href]="link(image)"
                            [attr.target]="image?.isTargetBlank ? '_blank' : '_self'"
                            (mouseleave)="mouseImgOut($event)"
                            (mouseenter)="mouseImgIn($event)">

                            <img
                                *ngIf="overlayImageSrc(image) && image?.isImageEffect"
                                [class]="classes?.gridImage + ' shape ' + classes['image' + i]"
                                [ngClass]="{ 'overlayImage': overlayImageSrc(image) }"
                                [style.objectPosition]="image && (image.backgroundPositionX || 50) + '% ' + (image.backgroundPositionY || 50) + '%'"
                                [src]="overlayImageSrc(image)"
                                [alt]="image?.overlayImage?.name"/>

                            <ng-container *ngIf="image?.hasResize; else displayDefault">
                                <picture>
                                    <source
                                        *ngFor="let source of imagesSrcSets[i]"
                                        [srcset]="source.url"
                                        [media]="source.media"
                                    />
                                    <img
                                        #imageRef
                                        class="sp-gallery-image"
                                        [class]="classes?.gridImage + ' shape ' + classes['image' + i]"
                                        [ngStyle]="{ '-webkit-mask-image': image?.maskShape && image?.isImageEffect ? getMaskUrl(image) : '' }"
                                        [src]="formatImageURL(image)"
                                        [alt]="image.alt"
                                        [style.objectPosition]="(image.backgroundPositionX || 50) + '% ' + (image.backgroundPositionY || 50) + '%'"
                                    />
                                </picture>
                            </ng-container>
                            <ng-template #displayDefault>
                                <img
                                    class="sp-gallery-image"
                                    [class]="classes?.gridImage + ' shape ' + classes['image' + i]"
                                    [ngStyle]="{ '-webkit-mask-image': image?.maskShape && image?.isImageEffect ? getMaskUrl(image) : '' }"
                                    [src]="formatImageURL(image)"
                                    [alt]="image.alt"
                                    [style.objectPosition]="(image.backgroundPositionX || 50) + '% ' + (image.backgroundPositionY || 50) + '%'"
                                />
                            </ng-template>
                            <div *ngIf="image.isImageEffect" class="sp-gallery-description" [class]="classes['imageDescription' + i]">
                                <div [class]="classes['text' + i]">
                                    <span>{{ image.description }}</span>
                                </div>
                            </div>
                        </a>
                    </div>
                </ng-template>
            </swiper>
            <swiper
                #thumbsSwiperComponent
                [config]="thumbsParentConfig"
                *ngIf="thumbsSwiper && carouselOptions.type !== 'default'"
                style="--swiper-navigation-color: {{ carouselOptions.knobsColor }}"
                [loop]="true"
                [loopedSlides]="loopedSlides"
                [navigation]="true"
                [grabCursor]="true"
                [autoplay]="autoplay"
                [spaceBetween]="0"
                [slidesPerView]="1"
            >
                <ng-template swiperSlide *ngFor="let image of widgetData.images; index as i; trackBy: instanceId">
                    <div
                        [class]="classes.imageWrapper"
                        (click)="openGallery(i)"
                        [style.paddingTop.%]="(widgetData.images[i].resizedHeight / widgetData.images[i].resizedWidth) * 100"
                    >
                        <a
                            class="sp-image-root"
                            [attr.href]="link(image)"
                            [attr.target]="image?.isTargetBlank ? '_blank' : '_self'"
                            (mouseleave)="mouseImgOut($event)"
                            (mouseenter)="mouseImgIn($event)">
                            <img
                                *ngIf="overlayImageSrc(image) && image?.isImageEffect"
                                [class]="classes?.gridImage + ' shape ' + classes['image' + i]"
                                [ngClass]="{ 'overlayImage': overlayImageSrc(image) }"
                                [style.objectPosition]="image && (image.backgroundPositionX || 50) + '% ' + (image.backgroundPositionY || 50) + '%'"
                                [src]="overlayImageSrc(image)"
                                [alt]="image?.overlayImage?.name"/>
                            <ng-container *ngIf="image?.hasResize; else displayDefault">
                                <picture>
                                    <source
                                        *ngFor="let source of imagesSrcSets[i]"
                                        [srcset]="source.url"
                                        [media]="source.media"
                                    />
                                    <img
                                        class="sp-gallery-image"
                                        [class]="classes?.gridImage + ' shape ' + classes['image' + i]"
                                        [ngStyle]="{ '-webkit-mask-image': image.maskShape && image?.isImageEffect ? getMaskUrl(image) : '' }"
                                        [src]="formatImageURL(image)"
                                        [alt]="image.alt"
                                        [style.objectPosition]="(image.backgroundPositionX || 50) + '% ' + (image.backgroundPositionY || 50) + '%'"
                                    />
                                </picture>
                            </ng-container>
                            <ng-template #displayDefault>
                                <img
                                    class="sp-gallery-image"
                                    [class]="classes?.gridImage + ' shape ' + classes['image' + i]"
                                    [ngStyle]="{ '-webkit-mask-image': image.maskShape && image.isImageEffect? getMaskUrl(image) : '' }"
                                    [src]="formatImageURL(image)"
                                    [alt]="image.alt"
                                    [style.objectPosition]="(image.backgroundPositionX || 50) + '% ' + (image.backgroundPositionY || 50) + '%'"
                                />
                            </ng-template>
                            <div *ngIf="image.isImageEffect" class="sp-gallery-description" [class]="classes['imageDescription' + i]">
                                <div [class]="classes['text' + i]">
                                    <span>{{ image.description }}</span>
                                </div>
                            </div>
                        </a>
                    </div>
                </ng-template>
            </swiper>
            <div *ngIf="carouselOptions.type !== 'default'" class="thumbs-swiper-container" [class]="classes.thumbsSwiperContainer">
                <swiper
                    class="thumbsSwiper"
                    (swiper)="setThumbs($event)"
                    [spaceBetween]="carouselOptions.imageGap"
                    [slidesPerView]="carouselOptions.rowItems"
                    [watchSlidesProgress]="true"
                >
                    <ng-template swiperSlide *ngFor="let image of widgetData.images; index as imageIndex">
                        <div [class]="classes.thumbWrapper">
                            <ng-container *ngIf="image?.hasResize; else displayDefault">
                                <picture>
                                    <source
                                        *ngFor="let source of imagesSrcSets[imageIndex]"
                                        [srcset]="source.url"
                                        [media]="source.media"
                                    />
                                    <img
                                        class="sp-gallery-image"
                                        [class]="classes.thumbImage"
                                        [src]="formatImageURL(image)"
                                        [alt]="image.alt"
                                    />
                                </picture>
                            </ng-container>
                            <ng-template #displayDefault>
                                <img
                                    class="sp-gallery-image"
                                    [class]="classes.thumbImage"
                                    [src]="formatImageURL(image)"
                                    [alt]="image.alt"
                                />
                            </ng-template>
                        </div>
                    </ng-template>
                </swiper>
            </div>
        </ng-container>
    </div>
</ng-container>
