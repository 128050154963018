import type { OnInit } from '@angular/core';
import type { AccordionWidgetData } from '@web/widgets/accordion';

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SpPipesModule } from '@common/pipes/pipes.module';

import { getStyles } from '@web/widgets/accordion/components/accordion-view/accordion-view.component.styles';

import { AbstractWidgetComponent } from '@web-builder/widgets/abstract-widget.component';

@Component({
    selector: 'web-builder-accordion',
    standalone: true,
    imports: [CommonModule, SpPipesModule],
    templateUrl: './accordion.component.html',
    styleUrls: ['./accordion.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionComponent extends AbstractWidgetComponent<AccordionWidgetData> implements OnInit {
    protected getStyles = getStyles;

    public get accordionList() {
        return this.widgetData.accordionList;
    }

    public toggle(index: number, isOpen: boolean): void {
        if (Boolean(this.widgetData.isAutoCollapse === false)) {
            if (!this.widgetData.accordionList[index].isOpen) {
                const selector = document.querySelector(
                    `[id='widget-${this.widget.id}'] ul[id='${this.widget.columnId}'] .itemContent_${index} .sp-ui-text`,
                );
                this.widgetData.accordionList[index].isOpen = true;
                this.widgetData.accordionList[index].contentHeight = (selector as HTMLDivElement)?.offsetHeight.toString() + 'px';

                setTimeout(() => {
                    this.widgetData.accordionList[index].contentHeight = 'auto';
                }, 350);
                return;
            }

            this.widgetData.accordionList[index].isOpen = false;
            this.widgetData.accordionList[index].contentHeight = '0';
        } else {
            if (!this.widgetData.accordionList[index].isOpen) {
                this.widgetData.accordionList.map((el) => {
                    el.isOpen = false;
                    el.contentHeight = '0';
                });

                const selector = document.querySelector(
                    `[id='widget-${this.widget.id}'] ul[id='${this.widget.columnId}'] .itemContent_${index} .sp-ui-text`,
                );
                this.widgetData.accordionList[index].isOpen = true;
                this.widgetData.accordionList[index].contentHeight = (selector as HTMLDivElement)?.offsetHeight.toString() + 'px';

                setTimeout(() => {
                    this.widgetData.accordionList[index].contentHeight = 'auto';
                }, 350);
                return;
            }

            this.widgetData.accordionList[index].isOpen = false;
            this.widgetData.accordionList[index].contentHeight = '0';
        }
    }
}
