import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ButtonMode } from '@common/components/sp-custom-button';

import { EButtonStyleType } from '@common/enums';
import { ThemeButton, ThemeSelectedButtons } from '@common/libs/themes/src/types/theme-button.type';

import { SpThemeButtonSettingsComponent } from '@libs/themes/src/abstract/abstract-buttons-settings.component';

@Component({
    selector: 'sp-simple-button-settings',
    styleUrls: ['./simple-button-settings.component.less'],
    templateUrl: './simple-button-settings.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpSimpleButtonSettingsComponent extends SpThemeButtonSettingsComponent {
    public readonly buttonStyleTypesTranslations = {
        [EButtonStyleType.primary]: 'theme_button_settings_primary',
        [EButtonStyleType.secondary]: 'theme_button_settings_secondary',
    };

    protected formatThemeButtons(buttons: ThemeSelectedButtons, themeValues: Record<string, string>): ThemeButton[] {
        // if (!buttons.selectedIndex) {
        //     return buttons.selected;
        // }

        return buttons.selected;
    }

    public getThemeButtonBase(button: ThemeButton): ThemeButton['base'] {
        if (button?.base) {
            return button.base;
        }

        return { ...button.styles, ...button.data } as ThemeButton['base'];
    }

    public getThemeButtonHover(button: ThemeButton): ThemeButton['hover'] {
        if (button?.hover) {
            return button.hover;
        }

        return { ...button.hover } as ThemeButton['hover'];
    }
}
