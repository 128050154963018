<div [class]="classes?.['button-group']" *ngIf="isModalForm" class="widget-specific-nav-align">
    <ng-container *ngFor="let button of buttons; let i = index">
        <a
            *ngIf="isDefaultButton(button)"
            [class]="getDefaultButtonClass(button, i)"
            [href]="getDefaultButtonLink(button)"
            [target]="getDefaultButtonTarget(button)"
            (click)="buttonClick($event)"
        >
            {{ button.label }}
        </a>

        <div *ngIf="isAuthorizationButton(button) && isButtonActive(button)" [spTooltip]="signInUnavailableTooltip" style="display: block">
            <a [class]="getDefaultButtonClass(button, i)" (click)="signIn()" [class.disabled]="!course && !singleSignInButton">
                {{ button.label }}
            </a>
        </div>

        <div *ngIf="isRegistrationButton(button) && isButtonActive(button)" [spTooltip]="signUpUnavailableTooltip" style="display: block">
            <a [class]="getRegistrationButtonClass(button, i)" (click)="signUp()" [class.disabled]="!course">
                {{ getRegistrationButton(button).label }}
            </a>
        </div>
    </ng-container>
</div>

<div *ngIf="isStaticForm">
    <ng-container *ngIf="isSignUp">
        <web-builder-sign-up-form
            [eduCourse]="course"
            [domainId]="domainId"
            [isAuthorizationButtonActive]="isAuthorizationButtonActive"
            [data]="registrationButtonData"
            (signUpSuccessEvent)="openSignUpSuccessModal($event)"
            (changeFormEvent)="changeDefaultForm($event)"
            *ngIf="!isShowDynamicSignUpForm"
        ></web-builder-sign-up-form>
        <web-builder-sign-up-dynamic-form
            *ngIf="isShowDynamicSignUpForm"
            [data]="registrationDynamicFormData"
            [eduCourse]="course"
            [domainId]="domainId"
            [isAuthorizationButtonActive]="isAuthorizationButtonActive"
            (changeFormEvent)="changeDefaultForm($event)"
            (signUpSuccessEvent)="openSignUpSuccessModal($event)"
        >
        </web-builder-sign-up-dynamic-form>
    </ng-container>
    <ng-container *ngIf="isSignIn">
        <web-builder-sign-in-form
            [eduCourse]="course"
            [domainId]="domainId"
            [isRegistrationButtonActive]="isRegistrationButtonActive"
            [data]="authorizationButtonData"
            (recoveryPassEvent)="openPasswordRecoveryModal()"
            (signInSuccessEvent)="goToStudentPanel()"
            (changeFormEvent)="changeDefaultForm($event)"
            *ngIf="!isShowDynamicSignInForm"
        ></web-builder-sign-in-form>
        <web-builder-sign-in-dynamic-form
            *ngIf="isShowDynamicSignInForm"
            [eduCourse]="course"
            [domainId]="domainId"
            [isRegistrationButtonActive]="isRegistrationButtonActive"
            [data]="authorizationDynamicFormData"
            (recoveryPassEvent)="openPasswordRecoveryModal()"
            (signInSuccessEvent)="goToStudentPanel()"
            (changeFormEvent)="changeDefaultForm($event)"
        ></web-builder-sign-in-dynamic-form>
    </ng-container>
</div>
