import type { AfterViewInit, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import type { BsModalRef } from 'ngx-bootstrap/modal';
import type { EduCourse } from '@common/types/edu-course.type';
import type {
    AuthorizationButtonData,
    CheckboxButtonData,
    DefaultButtonData,
    LoginButtonData,
    LoginEduWidgetData,
    LoginWidgetButtons,
    RegistrationButtonData,
} from '@web/widgets/login-edu';
import { SignUpModalComponent } from './sign-up-modal/sign-up-modal.component';
import { SignInModalComponent } from './sign-in-modal/sign-in-modal.component';
import { CommonModule } from '@angular/common';
import { type SignInModalResult, type SignUpModalResult } from '@web-builder/widgets/login-edu/type/login-edu.type';

import { EButtonLinkType, EButtonStyleType, EFormInputType, EUserTariffStatus } from '@common/enums';
import { ELoginEduView } from '@common/enums/login-edu-view-form.enum';
import { ELoginEduDefaultForm } from '@common/enums/login-edu-default-form.enum';

import { getStyles } from '@web/widgets/login-edu/components/login-edu-view/login-edu-view.component.styles';
import { BUTTON_SIZE_CLASS_MAP, BUTTON_TYPE_CLASS_MAP, DEFAULT_BUTTON_CLASS } from '@web/widgets/common/base/base-button/base-button.const';
import { EduHttpService } from '@web-builder/core/services/REST/edu.http.service';

import { AbstractWidgetComponent } from '@web-builder/widgets/abstract-widget.component';

import { environment } from '@web-builder-env/environment';
import { SignInSuccessModalComponent } from './sign-in-success-modal/sign-in-success-modal.component';
import { SignUpSuccessModalComponent } from './sign-up-success-modal/sign-up-success-modal.component';
import { PasswordRecoveryModalComponent } from './password-recovery-modal/password-recovery-modal.component';
import { PasswordRecoverySuccessModalComponent } from './password-recovery-success-modal/password-recovery-success-modal.component';
import { TariffRestrictionsModalComponent } from './tariff-restrictions-modal/tariff-restrictions-modal.component';
import { SignUpFormComponent } from './sign-up-form/sign-up-form.component';
import { SignInFormComponent } from './sign-in-form/sign-in-form.component';
import { SignInDynamicFormComponent } from '@web-builder/widgets/login-edu/sign-in-dynamic-form/sign-in-dynamic-form.component';
import { SignInDynamicModalComponent } from '@web-builder/widgets/login-edu/sign-in-dynamic-modal/sign-in-dynamic-modal.component';
import { SignUpDynamicFormComponent } from '@web-builder/widgets/login-edu/sign-up-dynamic-form/sign-up-dynamic-form.component';
import { SignUpDynamicModalComponent } from '@web-builder/widgets/login-edu/sign-up-dynamic-modal/sign-up-dynamic-modal.component';
import { TooltipDirective } from '@web-builder/core/directives/tooltip.directive';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

type RegistrationState = 'registration' | 'enroll' | 'enter';

@Component({
    selector: 'web-builder-login-edu',
    templateUrl: './login-edu.component.html',
    styleUrls: ['./login-edu.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        SignUpFormComponent,
        SignInFormComponent,
        SignInDynamicFormComponent,
        SignUpDynamicFormComponent,
        TooltipDirective,
        TranslateModule,
    ],
})
export class LoginEduComponent extends AbstractWidgetComponent<LoginEduWidgetData> implements OnInit, AfterViewInit {
    private readonly eduHttpService: EduHttpService = inject(EduHttpService);
    private readonly translateService: TranslateService = inject(TranslateService);

    protected getStyles = getStyles;

    public currentRegistrationState: RegistrationState = 'registration';
    public defaultForm: ELoginEduDefaultForm;

    public ngOnInit() {
        super.ngOnInit();
        this.defaultForm = this.widgetData.defaultForm;
    }

    public ngAfterViewInit(): void {
        if (this.isServer || this.isPreview) {
            return;
        }

        const urlParams = new URLSearchParams(window.location.search);
        const action = urlParams.get('action');
        //
        // if (action === 'login') {
        //     this.openSignInModal();
        if (action === 'signup') {
            this.checkUserTariff();
        }

        if (this.cookieService.check('oauth_access_token')) {
            if (!this.isRegistrationButtonActive) {
                this.currentRegistrationState = 'enter';
                return;
            }

            this.eduHttpService.studentCourseStatus(this.courseId).subscribe(
                (res: string) => {
                    this.currentRegistrationState = res !== 'deletedFromCourse' ? 'enter' : 'enroll';

                    this.changeDetectorRef.detectChanges();
                },
                () => {
                    this.currentRegistrationState = 'enroll';
                    this.changeDetectorRef.detectChanges();
                },
            );
        }
    }

    public signIn(): void {
        if (this.isPreview) {
            return;
        }

        if (this.isCurrentRegistrationStateRegistration) {
            this.openSignInModal();
            return;
        }

        this.goToStudentPanel();
    }

    public signUp(): void {
        if (this.isPreview) {
            return;
        }

        if (this.isCurrentRegistrationStateEnter) {
            this.goToStudentPanel();
            return;
        }

        if (this.isCurrentRegistrationStateEnroll) {
            this.openSignInSuccessModal();
            return;
        }

        this.checkUserTariff();
    }

    public buttonClick(event: Event): void {
        if (this.isPreview) {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    private openSignUpModal(): void {
        const signUpModalLabel = 'sign-up-modal ' + this.classes.modalContainerStyles;

        if (!this.course) {
            return;
        }

        if (this.isModalOpenByClass('sign-up-modal')) {
            return;
        }

        let modal: BsModalRef<SignUpModalComponent | SignUpDynamicModalComponent>;
        if (this.isShowDynamicSignUpForm) {
            modal = this.modalService.show(SignUpDynamicModalComponent, {
                animated: false,
                class: signUpModalLabel,
                initialState: {
                    eduCourse: this.course,
                    domainId: this.domainId,
                    isAuthorizationButtonActive: this.isAuthorizationButtonActive,
                    analytics: this.widgetData?.analyticsSettings,
                    checkboxes: this.checkboxes,
                    isShowCheckboxes: this.isShowCheckboxes,
                    classes: this.classes,
                    elements: this.signUpElements,
                },
            });
        } else {
            modal = this.modalService.show(SignUpModalComponent, {
                animated: false,
                class: signUpModalLabel,
                initialState: {
                    eduCourse: this.course,
                    domainId: this.domainId,
                    isAuthorizationButtonActive: this.isAuthorizationButtonActive,
                    analytics: this.widgetData?.analyticsSettings,
                    checkboxes: this.checkboxes,
                    isShowCheckboxes: this.isShowCheckboxes,
                    classes: this.classes,
                },
            });
        }

        modal.content.onClose.subscribe(({ data, message }: SignUpModalResult) => {
            if (data) {
                this.openSignUpSuccessModal(data);
                return;
            }

            if (message === 'signIn') {
                this.openSignInModal();
            }
        });
    }

    private openTariffRestrictionsModal(): void {
        if (!this.course) {
            return;
        }

        this.modalService.show(TariffRestrictionsModalComponent, { animated: false });
    }

    private openSignInModal(): void {
        const signInModalLabel = 'sign-in-modal ' + this.classes.modalContainerStyles;

        if (!this.course && this.isRegistrationButtonActive) {
            return;
        }

        if (this.isModalOpenByClass('sign-in-modal')) {
            return;
        }

        let modal: BsModalRef<SignInModalComponent | SignInDynamicModalComponent>;
        if (this.isShowDynamicSignInForm) {
            modal = this.modalService.show(SignInDynamicModalComponent, {
                animated: false,
                class: signInModalLabel,
                initialState: {
                    eduCourse: this.course,
                    domainId: this.domainId,
                    classes: this.classes,
                    elements: this.widgetData.signInElements,
                },
            });
        } else {
            modal = this.modalService.show(SignInModalComponent, {
                animated: false,
                class: signInModalLabel,
                initialState: {
                    eduCourse: this.course,
                    domainId: this.domainId,
                    classes: this.classes,
                },
            });
        }

        modal.content.onClose.subscribe(({ message }) => {
            if (message === 'passwordRecovery') {
                this.openPasswordRecoveryModal();
                return;
            }

            if (message === 'signInSuccess') {
                // window.location.reload(); // узнать зачем это
                window.location.href = environment.eduStudentUrl;
            }
        });
    }

    public openPasswordRecoveryModal(): void {
        const passwordRecoveryLabel = 'password-recovery-modal ' + this.classes.modalContainerStyles;

        if (!this.course && this.isRegistrationButtonActive) {
            return;
        }

        const modal: BsModalRef<PasswordRecoveryModalComponent> = this.modalService.show(PasswordRecoveryModalComponent, {
            animated: false,
            class: passwordRecoveryLabel,
            initialState: {
                eduCourse: this.course,
                domainId: this.domainId,
                classes: this.classes,
            },
        });

        modal.content.onClose.subscribe(({ message, email }) => {
            if (message === 'passwordRecoverySuccess') {
                this.openPasswordRecoverySuccessModal(email);
            }
        });
    }

    public openPasswordRecoverySuccessModal(email: string): void {
        const passwordRecoverySuccessModalLabel = 'password-recovery-success-modal ' + this.classes.modalContainerStyles;

        if (!this.course && this.isRegistrationButtonActive) {
            return;
        }

        const modal: BsModalRef<PasswordRecoverySuccessModalComponent> = this.modalService.show(PasswordRecoverySuccessModalComponent, {
            animated: false,
            class: passwordRecoverySuccessModalLabel,
            initialState: {
                email,
                classes: this.classes,
            },
        });

        modal.content.onClose.subscribe((result: boolean) => {
            if (result) {
                this.openSignInModal();
            }
        });
    }

    // todo: make it signup success message component
    public openSignUpSuccessModal(signUpFormData: SignUpModalResult['data']): void {
        if (!this.course) {
            return;
        }
        const modal: BsModalRef<SignUpSuccessModalComponent> = this.modalService.show(SignUpSuccessModalComponent, {
            animated: false,
            initialState: { signUpFormData },
        });
        modal.content.onClose.subscribe((isSuccess: boolean) => {
            if (!isSuccess) {
                return;
            }
            this.openSignInModal();
        });
    }

    public openSignInSuccessModal(signInData: Partial<SignInModalResult['data']> = {}): void {
        const signInSuccessModalLabel = 'sign-in-success-modal ' + this.classes.modalContainerStyles;

        if (!this.course) {
            return;
        }

        const modal: BsModalRef<SignInSuccessModalComponent> = this.modalService.show(SignInSuccessModalComponent, {
            animated: false,
            class: signInSuccessModalLabel,
            initialState: {
                eduCourse: this.course,
                signInData,
                classes: this.classes,
            },
        });

        modal.content.onClose.subscribe(({ message }) => {
            if (message === 'changeUser') {
                return this.openSignInModal();
            }
        });
    }

    private checkUserTariff(): void {
        this.eduHttpService.getUserTariff(this.course?.userId).subscribe((status) => {
            if (status === EUserTariffStatus.blocked) {
                this.openTariffRestrictionsModal();
            } else {
                this.openSignUpModal();
            }
        });
    }

    public getDefaultButtonClass(button: DefaultButtonData | AuthorizationButtonData, index: number): string {
        let styles = `${DEFAULT_BUTTON_CLASS} ${BUTTON_SIZE_CLASS_MAP[button.size]}`;

        if (button.styleType === EButtonStyleType.custom) {
            return `${styles} ${this.classes['button_' + index]}`;
        }

        return `${styles} ${BUTTON_TYPE_CLASS_MAP[button.styleType]}`;
    }

    public getRegistrationButtonClass(button: RegistrationButtonData, index: number): string {
        const loginButton = this.getRegistrationButton(button);
        let styles = `${DEFAULT_BUTTON_CLASS} ${BUTTON_SIZE_CLASS_MAP[loginButton.size]}`;

        if (loginButton.styleType === EButtonStyleType.custom) {
            return `${styles} ${this.classes['button_registration_' + index]}`;
        }

        return `${styles} ${BUTTON_TYPE_CLASS_MAP[loginButton.styleType]}`;
    }

    public getDefaultButtonLink(button: DefaultButtonData): string {
        switch (button.link.type) {
            case EButtonLinkType.anchor:
                const query = this.platformService.isPlatformBrowser() ? window.location.search : '';
                return `#${button.link.url}${query}`;
            case EButtonLinkType.email:
                return `mailto:${button.link.url}`;
            case EButtonLinkType.phone:
                return `tel:${button.link.url}`;
            default:
                return button.link.url;
        }
    }

    public getDefaultButtonTarget(button: DefaultButtonData): string {
        return button?.isTargetBlank ? '_blank' : '_self';
    }

    public isDefaultButton(button: LoginWidgetButtons): boolean {
        return button.buttonType === 'default';
    }

    public isAuthorizationButton(button: LoginWidgetButtons): boolean {
        return button.buttonType === 'authorization';
    }

    public isRegistrationButton(button: LoginWidgetButtons): boolean {
        return button.buttonType === 'registration';
    }

    public isButtonActive(button: AuthorizationButtonData | RegistrationButtonData): boolean {
        return button.isActive;
    }

    public changeDefaultForm(type: ELoginEduDefaultForm) {
        this.defaultForm = type;
    }

    private isModalOpenByClass(modalSelector: string): boolean {
        return Boolean(document.querySelector(`.${modalSelector}`));
    }

    public getRegistrationButton(button: RegistrationButtonData): LoginButtonData {
        if (this.isCurrentRegistrationStateEnroll) {
            return button.enrollButton;
        }

        if (this.isCurrentRegistrationStateEnter) {
            return button.enterButton;
        }

        return button.registrationButton;
    }

    public goToStudentPanel(): void {
        window.location.href = environment.eduStudentUrl;
    }

    public get isAuthorizationButtonActive(): boolean {
        return this.authorizationButton.isActive;
    }

    public get isRegistrationButtonActive(): boolean {
        return this.registrationButton.isActive;
    }

    private get isCurrentRegistrationStateEnroll(): boolean {
        return this.currentRegistrationState === 'enroll';
    }

    private get isCurrentRegistrationStateEnter(): boolean {
        return this.currentRegistrationState === 'enter';
    }

    private get isCurrentRegistrationStateRegistration(): boolean {
        return this.currentRegistrationState === 'registration';
    }

    public get buttons(): LoginWidgetButtons[] {
        return this.widgetData.buttons;
    }

    public get singleSignInButton() {
        return this.buttons.filter((button) => button.buttonType === 'authorization' && button.isActive).length === 1;
    }

    public get course(): EduCourse {
        return this.widgetData?.course;
    }

    public get courseId(): number {
        return this.course?.id;
    }

    public get domainId(): number {
        return this.transferStateService.get('domainId') ?? this.course?.domainId;
    }

    public get isStaticForm(): boolean {
        return this.widgetData.formView === ELoginEduView.static;
    }

    public get isModalForm(): boolean {
        return this.widgetData.formView === ELoginEduView.modal || !this.widgetData.formView;
    }

    public get checkboxes(): CheckboxButtonData[] {
        return this.widgetData.checkboxes;
    }

    public get isShowCheckboxes(): boolean {
        return this.widgetData.registrationCheckbox;
    }

    public get isSignUp(): boolean {
        return this.defaultForm === ELoginEduDefaultForm.register;
    }

    public get isSignIn(): boolean {
        return this.defaultForm === ELoginEduDefaultForm.login;
    }

    public get authorizationBtnStyles(): string {
        const index = this.buttons.findIndex((btn) => btn.buttonType === 'authorization');

        return this.getDefaultButtonClass(this.authorizationButton, index);
    }

    public get authorizationButtonData() {
        return {
            classes: this.classes,
            buttonStyles: this.authorizationBtnStyles,
            buttonLabel: this.authorizationButton.label,
        };
    }

    public get authorizationDynamicFormData() {
        return {
            classes: this.classes,
            buttonStyles: this.authorizationBtnStyles,
            buttonLabel: this.authorizationButton.label,
            elements: this.widgetData.signInElements,
        };
    }

    public get registrationBtnStyles(): string {
        const index = this.buttons.findIndex((btn) => btn.buttonType === 'registration');

        return this.getRegistrationButtonClass(this.registrationButton, index);
    }

    public get signUpElements() {
        return this.widgetData.signUpElements.filter((element) => element.type !== EFormInputType.password);
    }

    public get registrationButtonData() {
        return {
            classes: this.classes,
            buttonStyles: this.registrationBtnStyles,
            buttonLabel: this.registrationButton.registrationButton.label,
            analytics: this.widgetData.analyticsSettings,
            checkboxes: this.checkboxes,
            isShowCheckboxes: this.isShowCheckboxes,
        };
    }

    public get registrationDynamicFormData() {
        return {
            classes: this.classes,
            buttonStyles: this.registrationBtnStyles,
            buttonLabel: this.registrationButton.registrationButton.label,
            analytics: this.widgetData.analyticsSettings,
            checkboxes: this.checkboxes,
            isShowCheckboxes: this.isShowCheckboxes,
            elements: this.signUpElements,
        };
    }

    public get isShowDynamicSignInForm(): boolean {
        return !!this.widgetData?.signInElements?.length;
    }

    public get isShowDynamicSignUpForm(): boolean {
        return !!this.widgetData?.signInElements?.length;
    }

    public get signInUnavailableTooltip(): string | null {
        return !this.course && !this.singleSignInButton ? this.translateService.instant('edu_login_unavailable') : null;
    }

    public get signUpUnavailableTooltip(): string | null {
        return !this.course ? this.translateService.instant('edu_login_unavailable') : null;
    }

    private get authorizationButton(): AuthorizationButtonData {
        return this.buttons.find((button: LoginWidgetButtons) => button.buttonType === 'authorization') as AuthorizationButtonData;
    }

    private get registrationButton(): RegistrationButtonData {
        return this.buttons.find((button: LoginWidgetButtons) => button.buttonType === 'registration') as RegistrationButtonData;
    }
}
