<ng-container *ngIf="hasContent">
    <div *ngIf="!(isRunningStripeAnimation && isActiveAnimation)"
         #animatedElem
         class="sp-ui-text sp-text-root"
         [class]="classes?.root"
    >
        <div [class]="classes?.content" [innerHTML]="content | spSafeHtml"></div>
    </div>

    <div *ngIf="isRunningStripeAnimation && isActiveAnimation">
         <div #marqueeDiv class="marquee-text-wrap" [class]="classes?.root">
             <div class="sp-ui-text sp-text-root">
                 <div [class]="classes?.content" [innerHTML]="content | spSafeHtml"></div>
             </div>
         </div>
    </div>
</ng-container>
