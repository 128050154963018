import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

import { SelectedFileExtended } from '@common/types/file.type';

import { encodeFilePreviewUrl } from '../helpers';

@Component({
    selector: 'sp-image-preview-single',
    templateUrl: './sp-image-preview-single.component.html',
    styleUrls: ['./sp-image-preview-single.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpImagePreviewSingleComponent {
    @Input() public file: SelectedFileExtended = null;
    @Input() public isLink: boolean;
    @Input() public showSetupBtn: boolean;
    @Input() public cdnUrl: string;
    @Input() public short: boolean = false

    @Output() public updateFileEvent: EventEmitter<SelectedFileExtended> = new EventEmitter<SelectedFileExtended>();
    @Output() public removeFileEvent: EventEmitter<any> = new EventEmitter();
    @Output() public replaceFileEvent: EventEmitter<any> = new EventEmitter();
    @Output() public imageEditEvent: EventEmitter<void> = new EventEmitter<void>();

    public readonly closeSubject: Subject<void> = new Subject<void>();

    public saveSetupData(file: SelectedFileExtended): void {
        this.updateFileEvent.emit({ ...this.file, ...file });
    }

    public emitHiddenEventToChild(): void {
        this.closeSubject.next();
    }

    public encodeFilePreviewUrl(file: SelectedFileExtended): string {
        return encodeFilePreviewUrl(file, this.cdnUrl);
    }
}
