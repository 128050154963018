/* eslint-disable @angular-eslint/directive-selector */
import { type AfterViewInit, Directive, ElementRef, inject, Renderer2 } from '@angular/core';
import { PlatformService } from '@common/services/platform.service';

@Directive({
    selector: 'swiper',
    standalone: true,
})
export class HideSwiperOnSsrDirective implements AfterViewInit {
    private el: ElementRef = inject(ElementRef);
    private renderer: Renderer2 = inject(Renderer2);
    private platformService: PlatformService = inject(PlatformService);

    ngAfterViewInit() {
        if (this.platformService.isPlatformServer()) {
            const firstImage = this.el.nativeElement.querySelector('.swiper-slide:first-child img');
            if (firstImage) {
                this.renderer.setStyle(this.el.nativeElement, 'display', 'none');

                const fallbackImageContainer = this.renderer.createElement('div');
                this.renderer.addClass(fallbackImageContainer, 'image-wrapper');
                this.renderer.addClass(fallbackImageContainer, 'imageWrapper');

                const img = firstImage.cloneNode(true);
                this.renderer.addClass(img, 'default-image');
                this.renderer.appendChild(fallbackImageContainer, img);
                this.renderer.insertBefore(this.el.nativeElement.parentNode, fallbackImageContainer, this.el.nativeElement);
            }
        }
    }
}
