import type { JssStyle } from 'jss';
import type { ColumnStructureEntity, SectionStructureEntity } from '@web/types';

import { websiteColorCss } from '@libs/themes/src/variants/website/utils/variables';
import { EFilerOverlay, EVerticalPosition } from '@common/enums';
import { encodeFilePreviewUrlWithResize } from '@components/sp-file-manager';

function getRgbaColor(color: string, opacity: number): string {
    let r, g, b;

    if (color.includes('rgb')) {
        const rgbArr = color.match(/\d+/g);
        r = rgbArr[0];
        g = rgbArr[1];
        b = rgbArr[2];
    } else {
        r = parseInt(color.slice(1, 3), 16);
        g = parseInt(color.slice(3, 5), 16);
        b = parseInt(color.slice(5, 7), 16);
    }

    return `rgba(${r},${g},${b},${opacity})`;
}

export function getStyles(
    column: ColumnStructureEntity,
    columnSection: SectionStructureEntity,
    isConstructor: boolean = false,
    isPreview: boolean = false,
    fileManagerCdnUrl: string,
): JssStyle {
    const { columnIndent } = columnSection;
    const { styles, mobileStyles, data, mobileData } = column;
    const verticalPositionMatrix = {
        [EVerticalPosition.T]: 'flex-start',
        [EVerticalPosition.M]: 'center',
        [EVerticalPosition.B]: 'flex-end',
    };

    const boxShadowValue = '0 0 12px 4px rgba(0, 0, 0, .08)';
    const borderValue = `${styles.borderWidth}px ` + styles.borderStyle + ` ${styles.borderColor}`;
    let colorOverflow = '';

    let columnWrapper: JssStyle = {
        height: '100%',
        position: 'relative',
        marginRight: columnIndent / 2,
        marginLeft: columnIndent / 2,
    };
    let columnBg: JssStyle = {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        zIndex: 0,
        display: 'block',
        background: websiteColorCss(styles.backgroundColor as string),
        borderTopLeftRadius:
            styles.borderTopLeftRadius || styles.borderTopLeftRadius === 0 ? `${styles.borderTopLeftRadius}px` : `${styles.borderRadius}px`,
        borderTopRightRadius:
            styles.borderTopRightRadius || styles.borderTopRightRadius === 0
                ? `${styles.borderTopRightRadius}px`
                : `${styles.borderRadius}px`,
        borderBottomRightRadius:
            styles.borderBottomRightRadius || styles.borderBottomRightRadius === 0
                ? `${styles.borderBottomRightRadius}px`
                : `${styles.borderRadius}px`,
        borderBottomLeftRadius:
            styles.borderBottomLeftRadius || styles.borderBottomLeftRadius === 0
                ? `${styles.borderBottomLeftRadius}px`
                : `${styles.borderRadius}px`,
    };

    const columnWidgetsList: JssStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        justifyContent: `${verticalPositionMatrix[data.verticalAlign]}`,
        // '& div[class^="imgWidgetWrapper"]': {
        //     justifyContent: `${horizontalPositionMatrix[data.horizontalAlign]}`,
        // },
        // '& div[class^="button-group"]': {
        //     justifyContent: `${horizontalPositionMatrix[data.horizontalAlign]}`,
        // },
    };

    if (data.isShadow) {
        columnBg.boxShadow = boxShadowValue;
    }

    if (data.isBorder === false) {
        columnBg.border = 'none';
    }

    if (data.isBorder) {
        columnBg.border = borderValue;
    }

    if (data.isColorOverflow) {
        let rgba = getRgbaColor(data.colorOverflow, data.colorOverflowOpacity);
        colorOverflow = `linear-gradient(${rgba}, ${rgba}), `;
    }

    if (data.backgroundImage) {
        columnBg.background = `${colorOverflow}${encodeFilePreviewUrlWithResize(data.backgroundImage, fileManagerCdnUrl)}, ${
            columnBg.background
        }`;
        columnBg.backgroundPosition = styles.backgroundPosition;
        columnBg.backgroundRepeat = styles.backgroundRepeat;
        columnBg.backgroundSize = styles.backgroundSize;

        if (data.isFilterOverlay) {
            columnBg.filter = data.filterOverlay;

            if (data.filterOverlay === EFilerOverlay.BLURRY) {
                columnBg.margin = '-3px';
            }
        }
    }

    const columnInner: JssStyle = {
        paddingTop: `${styles.paddingTop}px`,
        paddingBottom: `${styles.paddingBottom}px`,
        paddingLeft: `${styles.paddingLeft}px`,
        paddingRight: `${styles.paddingRight}px`,
        height: '100%',
    };

    if (mobileStyles) {
        columnBg['@media (max-width: 992px)'] = {
            background: websiteColorCss(mobileStyles.backgroundColor as string),
            borderTopLeftRadius:
                mobileStyles.borderTopLeftRadius || mobileStyles.borderTopLeftRadius === 0
                    ? `${mobileStyles.borderTopLeftRadius}px`
                    : `${mobileStyles.borderRadius}px`,
            borderTopRightRadius:
                mobileStyles.borderTopRightRadius || mobileStyles.borderTopRightRadius === 0
                    ? `${mobileStyles.borderTopRightRadius}px`
                    : `${mobileStyles.borderRadius}px`,
            borderBottomRightRadius:
                mobileStyles.borderBottomRightRadius || mobileStyles.borderBottomRightRadius === 0
                    ? `${mobileStyles.borderBottomRightRadius}px`
                    : `${mobileStyles.borderRadius}px`,
            borderBottomLeftRadius:
                mobileStyles.borderBottomLeftRadius || mobileStyles.borderBottomLeftRadius === 0
                    ? `${mobileStyles.borderBottomLeftRadius}px`
                    : `${mobileStyles.borderRadius}px`,
        };

        columnBg['&.mobileColumnBg'] = {
            background: websiteColorCss(mobileStyles.backgroundColor as string),
        };

        columnInner['@media (max-width: 992px)'] = {
            paddingTop: `${mobileStyles.paddingTop}px`,
            paddingBottom: `${mobileStyles.paddingBottom}px`,
            paddingLeft: `${mobileStyles.paddingLeft}px`,
            paddingRight: `${mobileStyles.paddingRight}px`,
        };

        if (isConstructor) {
            columnBg['&.mobileColumnViewWrap'] = {
                background: websiteColorCss(mobileStyles.backgroundColor as string),
                borderTopLeftRadius:
                    mobileStyles.borderTopLeftRadius || mobileStyles.borderTopLeftRadius === 0
                        ? `${mobileStyles.borderTopLeftRadius}px`
                        : `${mobileStyles.borderRadius}px`,
                borderTopRightRadius:
                    mobileStyles.borderTopRightRadius || mobileStyles.borderTopRightRadius === 0
                        ? `${mobileStyles.borderTopRightRadius}px`
                        : `${mobileStyles.borderRadius}px`,
                borderBottomRightRadius:
                    mobileStyles.borderBottomRightRadius || mobileStyles.borderBottomRightRadius === 0
                        ? `${mobileStyles.borderBottomRightRadius}px`
                        : `${mobileStyles.borderRadius}px`,
                borderBottomLeftRadius:
                    mobileStyles.borderBottomLeftRadius || mobileStyles.borderBottomLeftRadius === 0
                        ? `${mobileStyles.borderBottomLeftRadius}px`
                        : `${mobileStyles.borderRadius}px`,
            };

            columnInner['&.mobileColumnViewInner'] = {
                paddingTop: `${mobileStyles.paddingTop}px`,
                paddingBottom: `${mobileStyles.paddingBottom}px`,
                paddingLeft: `${mobileStyles.paddingLeft}px`,
                paddingRight: `${mobileStyles.paddingRight}px`,
            };
        }
    }

    if (!mobileStyles) {
        columnInner['@media (max-width: 992px)'] = {
            paddingLeft: '15px',
            paddingRight: '15px',
        };

        if (isConstructor) {
            columnInner['&.mobileColumnViewInner'] = {
                paddingLeft: '15px',
                paddingRight: '15px',
            };
        }
    }

    if (mobileStyles && mobileData) {
        if (mobileData.backgroundImage) {
            columnBg['@media (max-width: 992px)'] = {
                background: `${colorOverflow}${encodeFilePreviewUrlWithResize(
                    mobileData.backgroundImage,
                    fileManagerCdnUrl,
                )}`,
                backgroundPosition: mobileStyles.backgroundPosition,
                backgroundRepeat: mobileStyles.backgroundRepeat,
                backgroundSize: mobileStyles.backgroundSize,
                backgroundColor: websiteColorCss(mobileStyles.backgroundColor as string),
            };
        }

        if (isConstructor && mobileData.backgroundImage) {
            columnBg['&.mobileColumnBg'] = {
                background: `${colorOverflow}${encodeFilePreviewUrlWithResize(
                    mobileData.backgroundImage,
                    fileManagerCdnUrl,
                )}`,
                backgroundPosition: mobileStyles.backgroundPosition,
                backgroundRepeat: mobileStyles.backgroundRepeat,
                backgroundSize: mobileStyles.backgroundSize,
                backgroundColor: websiteColorCss(mobileStyles.backgroundColor as string),
            };
        }
    }

    const columnAnimationPreset = {
        opacity: data.animation.isActive && !isPreview ? 0 : 1,
        height: '100%',
    };

    return {
        columnWrapper,
        columnBg,
        columnInner,
        columnWidgetsList,
        columnAnimationPreset,
    };
}
