import { Injectable } from '@angular/core';
import { BaseHttpService } from './base.http.service';
import { shareReplay, type Observable } from 'rxjs';

type GeoIpResponse = {
    organization: string;
    region: string;
    asn: number;
    ip: string;
    timezone: string;
    longitude: number;
    postal_code: string;
    city: string;
    continent_code: string;
    country: string;
    region_code: string;
    country_code: string;
    latitude: number;
};
@Injectable({
    providedIn: 'root',
})
export class CountryISOService extends BaseHttpService {
    public getCountryISO(): Observable<any> {
        return this.http.get<GeoIpResponse>(`https://geoip.sendpulse.com`).pipe(shareReplay(1));
    }
}
