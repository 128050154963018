import { InputBorderEnum, FormStyleEnum, ESize, EHorizontalPosition, Widgets } from '@common/enums';

//kostil until we have adapter in builder

const defaultLoginEduInputSettings = {
    showLabels: true,
    inputBorderType: InputBorderEnum.FULL_BORDER,
    formStyleType: FormStyleEnum.VERTICAL,
    backgroundColor: '#fff',
    borderColor: '#ddd',
    textColor: '#333',
    inputTextColor: '#333',
    borderRadius: 3,
    inputSize: ESize.M,
    horizontalPosition: EHorizontalPosition.L,
    linkColor: '#333',
    modalBackgroundColor: '#fbfcfc',
    modalBorderRadius: 6,
    modalPadding: 30,
};

const defaultPaymentInputSettings = {
    modalBackgroundColor: '#fbfcfc',
    modalBorderRadius: 6,
    modalPadding: 45,
    showLabels: true,
    inputBorderType: InputBorderEnum.FULL_BORDER,
    formStyleType: FormStyleEnum.VERTICAL,
    backgroundColor: '#fff',
    borderColor: '#ddd',
    textColor: '#333',
    inputTextColor: '#333',
    borderRadius: 3,
    inputSize: ESize.M,
    horizontalPosition: EHorizontalPosition.L,
};

const defaultFormInputSetting = {
    showLabels: true,
    inputBorderType: InputBorderEnum.FULL_BORDER,
    formStyleType: FormStyleEnum.VERTICAL,
    backgroundColor: '#fff',
    borderColor: '#ddd',
    textColor: '#333',
    inputTextColor: '#333',
    borderRadius: 3,
    inputSize: ESize.M,
    horizontalPosition: EHorizontalPosition.L,
};

export const defaultInputSettingsMap = {
    [Widgets.LOGIN_EDU]: defaultLoginEduInputSettings,
    [Widgets.PAYMENT_FORM]: defaultPaymentInputSettings,
    [Widgets.FORM]: defaultFormInputSetting,
};
