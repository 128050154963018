import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LinkFormatterService {
    private readonly HTTP_PROTOCOL: string = 'http://';
    private readonly HTTPS_PROTOCOL: string = 'https://';
    private readonly VIBER_PROTOCOL: string = 'viber://';
    private readonly EMAIL_PROTOCOL: string = 'mailto:';
    private readonly PHONE_PROTOCOL: string = 'tel:';
    private readonly TRACKING_DOMAIN: string = 'https://click.pulse.is/public';
    private readonly SKYPE_PROTOCOL: string = 'skype:';

    private domainUrl: string = null;
    private isInitialized: boolean = false;

    public init(domainUrl: string): void {
        if (this.isInitialized) {
            return;
        }

        this.domainUrl = domainUrl;
        this.isInitialized = true;
    }

    public formatLink(link: string): string {
        const validProtocolsRegex = /^(mailto:|ftp:|tel:|file:)/;

        if (!link) {
            return link;
        }

        if (validProtocolsRegex.test(link)) {
            return link;
        }

        if (link.startsWith('/')) {
            return this.domainUrl + link;
        }

        if (link.startsWith(this.HTTPS_PROTOCOL) || link.startsWith(this.VIBER_PROTOCOL) || link.startsWith(this.SKYPE_PROTOCOL)) {
            return link;
        }

        if (link.startsWith(this.HTTP_PROTOCOL)) {
            return link.replace(this.HTTP_PROTOCOL, this.HTTPS_PROTOCOL);
        }

        return this.HTTPS_PROTOCOL + link;
    }

    public formatEmailLink(link: string): string {
        if (link.startsWith(this.EMAIL_PROTOCOL)) {
            return link;
        }

        return this.EMAIL_PROTOCOL + link;
    }

    public formatPhoneLink(link: string): string {
        if (link.startsWith(this.PHONE_PROTOCOL)) {
            return link;
        }

        return this.PHONE_PROTOCOL + link;
    }
}
