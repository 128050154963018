import {
    type AfterViewInit,
    type OnInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ElementRef,
    Component,
    Input,
    ViewChild,
    Renderer2,
} from '@angular/core';
import type { Classes } from 'jss';
import { BlockStructureEntity } from '@web/types';
import { ServerStylesService } from '@common/services/server-styles.service';
import { PlatformService } from '@common/services/platform.service';
import { EScrollEffect } from '@common/enums';
import { websiteColorCss } from '@common/libs/themes/src/variants/website/utils/variables';
import { getStyles } from '@web/layout/content/page-content/components/blocks/block-wrapper/block-wrapper.component.styles';
import { isIphone } from '@common/helpers';
import { environment } from '@web-builder-env/environment';

@Component({
    selector: 'web-builder-block-wrapper',
    templateUrl: './block-wrapper.component.html',
    styleUrls: ['./block-wrapper.component.less'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class BlockWrapperComponent implements OnInit, AfterViewInit {
    @Input() public block: BlockStructureEntity;
    @Input() public index: number;

    @ViewChild('blockBg') blockBg: ElementRef<HTMLDivElement>;
    @ViewChild('parallax') parallax: ElementRef<HTMLDivElement>;

    public classes: Classes;
    public pinedTopBlockHeight: number = 0;
    public pinedBottomBlockHeight: number = 0;
    public currentPosition: number = 0;
    public imgHeight: number = 0;
    public backgroundColor: string = 'transparent';
    public isBrowser: boolean = this.platformService.isPlatformBrowser();

    constructor(
        private readonly stylesService: ServerStylesService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly platformService: PlatformService,
        private readonly renderer: Renderer2,
    ) {
        if (this.isBrowser) this.currentPosition = window.scrollY;
    }

    public ngOnInit(): void {
        const styles = getStyles(this.block, false, environment.fileManagerCdnUrl);
        this.classes = this.stylesService.getStyles(this.block.id, styles);
        this.changeDetectorRef.detectChanges();
    }

    public ngAfterViewInit(): void {
        if (this.platformService.isPlatformServer()) {
            return;
        }

        if (this.block.data.pinOnTheTop) {
            this.pinedTopBlockHeight = (document.querySelector(`[id='block-${this.block.id}']`) as HTMLDivElement)?.offsetHeight;
            this.backgroundColor = websiteColorCss(this.block.styles.backgroundColor as string);

            const htmlNode = document.querySelector('html') as HTMLHtmlElement;
            htmlNode.style.scrollPaddingTop = (this.pinedTopBlockHeight?.toString() ?? '0') + 'px';

            this.changeDetectorRef.detectChanges();
        }

        if (this.block.data.pinOnTheBottom) {
            this.pinedBottomBlockHeight = (document.querySelector(`[id='block-${this.block.id}']`) as HTMLDivElement)?.offsetHeight;
            this.backgroundColor = websiteColorCss(this.block.styles.backgroundColor as string);

            this.changeDetectorRef.detectChanges();
        }

        this.setScrollEvents();
    }

    public get blockId(): string {
        return `block-${this.block.id}`;
    }

    private initScrollSubscription() {
        document.addEventListener('scroll', () => {
            const clientHeight = document.documentElement.clientHeight;
            const blockOffset = this.blockBg.nativeElement.getBoundingClientRect().top;
            const isParallax = this.block.data.scrollEffect === EScrollEffect.PARALLAX;
            const isInViewZone = blockOffset < clientHeight;

            if (isParallax && isInViewZone) {
                this.moveBgParallax();
            } else {
                if (isIphone()) this.moveBgFixed();
            }
        });
    }

    private setScrollEvents(): void {
        if (this.block.data.isScrollEffect && this.block.data.scrollEffect === EScrollEffect.FIXED && isIphone()) {
            const actualImage = new Image();

            actualImage.src = window
                .getComputedStyle(this.blockBg.nativeElement)
                .getPropertyValue('background-image')
                .match(/url\("([^"]+)"\)/g)[0]
                .replace('url("', '')
                .replace('")', '');

            actualImage.onload = () => {
                this.initFixedScroll(this.blockBg, this.renderer, actualImage.height);
                this.initScrollSubscription();
            };

            this.renderer.setStyle(this.blockBg.nativeElement, 'background-attachment', 'scroll');
        }

        if (this.block.data.isScrollEffect && this.block.data.scrollEffect === EScrollEffect.PARALLAX) {
            this.moveBgParallax();
            this.initScrollSubscription();
        }
    }

    private moveBgParallax() {
        const clientHeight = document.documentElement.clientHeight;
        const blockOffsetTop = this.blockBg.nativeElement.getBoundingClientRect().top;
        const blockOffsetBottom = this.blockBg.nativeElement.getBoundingClientRect().bottom;
        const blockHeight = this.blockBg.nativeElement.getBoundingClientRect().height;

        if (blockOffsetTop > clientHeight || blockOffsetTop + blockHeight < 0) return;

        const percent = ((((blockOffsetBottom + blockOffsetTop - clientHeight) * 80) / blockHeight) * 40) / 100 / 2;

        this.renderer.setStyle(this.parallax.nativeElement, 'transform', `translate3d(0%, ${percent}%, 0px)`);
    }

    private moveBgFixed() {
        const clientHeight = document.documentElement.clientHeight;
        const blockOffset = this.blockBg.nativeElement.getBoundingClientRect().top;
        const blockHeight = this.blockBg.nativeElement.getBoundingClientRect().height;

        if (blockOffset > clientHeight || blockOffset + blockHeight < 0) return;

        const xPosition = (this.imgHeight - clientHeight + blockOffset) * -1;
        const bgPosition = `50% ${xPosition}px`;

        this.renderer.setStyle(this.blockBg.nativeElement, 'background-position', bgPosition);
    }

    private initFixedScroll(element: ElementRef<HTMLDivElement>, renderer: Renderer2, actualImageHeight: number): void {
        this.imgHeight = actualImageHeight;
        this.moveBgFixed();
    }

    public get isParallax(): boolean {
        return this.block?.data?.isScrollEffect && this.block?.data?.scrollEffect === EScrollEffect.PARALLAX;
    }

    public getBackgroundVideoUrl(): string | boolean {
        if (this.block.data?.backgroundVideo === null) {
            return false;
        }

        return `${environment.fileManagerCdnUrl}${this.block.data?.backgroundVideo.url}`
    }

    public getMobileBackgroundVideoUrl(): string | boolean {
        if (this.block.mobileData?.backgroundVideo === null) {
            return false;
        }

        return `${environment.fileManagerCdnUrl}${this.block.mobileData?.backgroundVideo.url}`
    }
}
