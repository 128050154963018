export enum ERadioGroupType {
    size = 'size',
    horizontalPosition = 'horizontalPosition',
    verticalPosition = 'verticalPosition',
    menu = 'menuType',
    logoPosition = 'logoPosition',
    faqIconStyle = 'faqIconStyle',
    faqIconPosition = 'faqIconPosition',
    loginDefaultForm = 'loginDefaultForm',
}
