import type { SafeHtml } from '@angular/platform-browser';
import type { ButtonData, ButtonWidgetData } from '@web/widgets/button';
import type { AnimationSettings } from '@common/components/sp-animation-settings/type/animation-settings.type';

import { type AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, inject, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
    EButtonAdditionalEffectsCondition,
    EButtonAdditionalHoverEffectsTypes,
    EButtonAdditionalStaticEffectsTypes,
    EButtonLinkType,
    EButtonStyleType,
} from '@common/enums';
import { SpPipesModule } from '@common/pipes/pipes.module';
import { getGsapScrollTrigger } from '@components/sp-animation-settings';

import { getStyles } from '@web/widgets/button/components/button-view/button-view.component.styles';
import { BUTTON_SIZE_CLASS_MAP, BUTTON_TYPE_CLASS_MAP, DEFAULT_BUTTON_CLASS } from '@web/widgets/common/base/base-button/base-button.const';

import { AbstractWidgetComponent } from '@web-builder/widgets/abstract-widget.component';
import { AnalyticsService } from '@web-builder/core/services/analytics.service';

@Component({
    selector: 'web-builder-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, SpPipesModule],
})
export class ButtonComponent extends AbstractWidgetComponent<ButtonWidgetData> implements AfterViewInit {
    @ViewChild('animatedElem') animatedElem: ElementRef<HTMLDivElement>;

    private readonly analyticsService: AnalyticsService = inject(AnalyticsService);

    protected getStyles = getStyles;

    public ngAfterViewInit(): void {
        const animationOptions = this.widgetData.animation;
        if (animationOptions?.isActive) getGsapScrollTrigger(animationOptions, this.animatedElem.nativeElement);
    }

    public getButtonEffectClass(button: ButtonData): string {
        let nameEffect: string = '';

        if (button.additionalEffects?.isActive) {
            if (button.additionalEffects?.effectCondition === EButtonAdditionalEffectsCondition.hover) {
                switch (button.additionalEffects?.hoverEffects) {
                    case EButtonAdditionalHoverEffectsTypes.bounce:
                        nameEffect = EButtonAdditionalHoverEffectsTypes.bounce;
                        break;
                    case EButtonAdditionalHoverEffectsTypes.shake:
                        nameEffect = EButtonAdditionalHoverEffectsTypes.shake;
                        break;
                    case EButtonAdditionalHoverEffectsTypes.decrease:
                        nameEffect = EButtonAdditionalHoverEffectsTypes.decrease;
                        break;
                    case EButtonAdditionalHoverEffectsTypes.increase:
                        nameEffect = EButtonAdditionalHoverEffectsTypes.increase;
                        break;
                    default:
                        break;
                }
            }

            if (button.additionalEffects?.effectCondition === EButtonAdditionalEffectsCondition.static) {
                switch (button.additionalEffects?.staticEffects) {
                    case EButtonAdditionalStaticEffectsTypes.shine:
                        nameEffect = 'shine';
                        break;
                    case EButtonAdditionalStaticEffectsTypes.twinkle:
                        nameEffect = 'twinkle';
                        break;
                    default:
                        break;
                }
            }

            return 'button_effect_' + nameEffect;
        }

        return '';
    }

    public getButtonClass(button: ButtonData, index: number): string {
        const styles = `${DEFAULT_BUTTON_CLASS} ${BUTTON_SIZE_CLASS_MAP[button.size]}`;

        if (button.styleType === EButtonStyleType.custom) {
            return `${styles} ${this.classes['button_' + index]} ${this.getButtonEffectClass(button)}`;
        }

        return `${styles} ${BUTTON_TYPE_CLASS_MAP[button.styleType]} ${this.classes['button_width_' + index]} ${this.getButtonEffectClass(
            button,
        )}`;
    }

    public getIcon(icon: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(icon);
    }

    private getNormalizedLink(type: EButtonLinkType, link: string): string {
        const trimmedLink = link?.trim();

        if (!trimmedLink) {
            return null;
        }

        if (this.isPreview && type === EButtonLinkType.anchor) {
            return `about:srcdoc#${trimmedLink}`;
        }

        if (type === EButtonLinkType.anchor) {
            const domain = this.transferStateService.get('domainUrl');
            const pageUrl = this.transferStateService.get('pageUrl');
            const query = this.platformService.isPlatformBrowser() ? window.location.search : '';

            if (!domain || !pageUrl) {
                let anchorPageUrl = '';
                if (this.platformService.isPlatformBrowser()) {
                    anchorPageUrl = window.location.pathname.substring(1);
                }

                const replacedLink = trimmedLink.replace('#', '');
                return `${anchorPageUrl}${query}#${replacedLink}`;
            }

            return `${domain}/${pageUrl}${query}#${trimmedLink}`;
        }

        if (type === EButtonLinkType.email) {
            return this.linkFormatterService.formatEmailLink(trimmedLink);
        }

        if (type === EButtonLinkType.phone) {
            return this.linkFormatterService.formatPhoneLink(trimmedLink);
        }

        return this.linkFormatterService.formatLink(trimmedLink);
    }

    public getButtonLink(button: ButtonData): string {
        const { url, type } = button.link;
        const normalizedLink = this.getNormalizedLink(type, url);

        if (!normalizedLink) {
            return null;
        }

        return normalizedLink;
    }

    public trySendAnalytic(button: ButtonData): void {
        this.sendGaEvent(button);
        this.sendPixelFbEvent(button);
    }

    public getButtonTarget(button: ButtonData): string {
        return button?.isTargetBlank ? '_blank' : '_self';
    }

    public get buttons(): ButtonData[] {
        return this.widgetData.buttons;
    }

    public get animation(): AnimationSettings {
        return this.widgetData.animation;
    }

    private sendGaEvent(button: ButtonData) {
        if (button?.analyticsSettings?.googleAnalytic) {
            this.analyticsService.gaEventRequest(button?.analyticsSettings?.googleSettings);
        }
    }

    private sendPixelFbEvent(button: ButtonData) {
        if (button?.analyticsSettings?.pixelAnalytic) {
            this.analyticsService.pixelFbEventRequest(button?.analyticsSettings?.pixelSettings);
        }
    }
}
