import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, inject, Input, OnInit, Renderer2, SecurityContext, ViewChild } from "@angular/core";
import { BlockStructureEntity } from "@web/types";
import {
    BOTTOM_SHAPE_DIVIDERS,
    TOP_SHAPE_DIVIDERS,
    FLIP_HORIZONTAL_TOP_SHAPE_DIVIDERS,
    FLIP_HORIZONTAL_BOTTOM_SHAPE_DIVIDERS,
    FLIP_HORIZONTAL_VERTICAL_BOTTOM_SHAPE_DIVIDERS,
    FLIP_HORIZONTAL_VERTICAL_TOP_SHAPE_DIVIDERS,
    FLIP_VERTICAL_BOTTOM_SHAPE_DIVIDERS,
    FLIP_VERTICAL_TOP_SHAPE_DIVIDERS
} from "@common/constants/shape-dividers.const";

import { EBlockShapeDividerFlip } from "@common/enums/block-shape-dividers-type.enum";
import { PlatformService } from "@common/services/platform.service";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: 'web-builder-block-shape-dividers',
    templateUrl: './block-shape-dividers.component.html',
    styleUrls: ['./block-shape-dividers.component.less'],
    changeDetection: ChangeDetectionStrategy.Default,
})

export class BlockShapeDividersComponent implements OnInit, AfterViewInit {
    @Input() public block: BlockStructureEntity;

    @ViewChild('desktopTopDividerContainer') desktopTopDividerContainer: ElementRef<HTMLElement>;
    @ViewChild('desktopBottomDividerContainer') desktopBottomDividerContainer: ElementRef<HTMLElement>;
    @ViewChild('mobileTopDividerContainer') mobileTopDividerContainer: ElementRef<HTMLElement>;
    @ViewChild('mobileBottomDividerContainer') mobileBottomDividerContainer: ElementRef<HTMLElement>;

    private readonly renderer: Renderer2 = inject(Renderer2);

    public topShapeDividers = TOP_SHAPE_DIVIDERS;
    public bottomShapeDividers = BOTTOM_SHAPE_DIVIDERS;
    public flipHorizontalTopShapeDividers = FLIP_HORIZONTAL_TOP_SHAPE_DIVIDERS;
    public flipHorizontalBottomShapeDividers = FLIP_HORIZONTAL_BOTTOM_SHAPE_DIVIDERS;
    public flipVerticalTopShapeDividers = FLIP_VERTICAL_TOP_SHAPE_DIVIDERS;
    public flipVerticalBottomShapeDividers = FLIP_VERTICAL_BOTTOM_SHAPE_DIVIDERS;
    public flipHorizontalVerticalTopShapeDividers = FLIP_HORIZONTAL_VERTICAL_TOP_SHAPE_DIVIDERS;
    public flipHorizontalVerticalBottomShapeDividers = FLIP_HORIZONTAL_VERTICAL_BOTTOM_SHAPE_DIVIDERS;

    public topDividerView: string;
    public bottomDividerView: string;
    public mobileTopDividerView: string;
    public mobileBottomDividerView: string;

    public innerHtmlTopDivider;
    public innerHtmlBottomDivider;
    public innerHtmlMobileTopDivider;
    public innerHtmlMobileBottomDivider;

    constructor(
        private readonly platformService: PlatformService,
        protected readonly sanitizer: DomSanitizer,
    ) {}

    public ngOnInit(): void {
        this.topDividerView = this.getTopDividerView();
        this.bottomDividerView = this.getBottomDividerView();
        this.mobileTopDividerView = this.getMobileTopDividerView();
        this.mobileBottomDividerView = this.getMobileBottomDividerView();
    }

    public ngAfterViewInit(): void {

        this.innerHtmlTopDivider = this.sanitizer.sanitize(
            SecurityContext.HTML,
            this.sanitizer.bypassSecurityTrustHtml(this.topDividerView)
        );

        this.innerHtmlBottomDivider = this.sanitizer.sanitize(
            SecurityContext.HTML,
            this.sanitizer.bypassSecurityTrustHtml(this.bottomDividerView)
        );

        this.innerHtmlMobileTopDivider = this.sanitizer.sanitize(
            SecurityContext.HTML,
            this.sanitizer.bypassSecurityTrustHtml(this.mobileTopDividerView)
        );

        this.innerHtmlMobileBottomDivider = this.sanitizer.sanitize(
            SecurityContext.HTML,
            this.sanitizer.bypassSecurityTrustHtml(this.mobileBottomDividerView)
        );

        if (this.platformService.isPlatformServer()) {
            // .innerHTML is not supported by SSR
            setTimeout(() => {
                try {
                    if (this.block.data?.isTopShapeDivider) {
                        this.renderer.appendChild(this.desktopTopDividerContainer.nativeElement, this.topDividerView);
                    }
            
                    if (this.block.data?.isBottomShapeDivider) {
                        this.renderer.appendChild(this.desktopBottomDividerContainer.nativeElement, this.bottomDividerView);
                    }
            
                    if (this.block.mobileData?.isTopShapeDivider) {
                        this.renderer.appendChild(this.mobileTopDividerContainer.nativeElement, this.mobileTopDividerView);
                    }
            
                    if (this.block.mobileData?.isBottomShapeDivider) {
                        this.renderer.appendChild(this.mobileBottomDividerContainer.nativeElement, this.mobileBottomDividerView);
                    }
                } catch (error) {
                    //TypeError: not a node
                };
            })
            
        } else {
            if (this.block.data?.isTopShapeDivider) {
                this.desktopTopDividerContainer.nativeElement.innerHTML = this.innerHtmlTopDivider;
            }

            if (this.block.data?.isBottomShapeDivider) {
                this.desktopBottomDividerContainer.nativeElement.innerHTML = this.innerHtmlBottomDivider;
            }

            if (this.block.mobileData?.isTopShapeDivider) {
                this.mobileTopDividerContainer.nativeElement.innerHTML = this.innerHtmlMobileTopDivider;
            }

            if (this.block.mobileData?.isBottomShapeDivider) {
                this.mobileBottomDividerContainer.nativeElement.innerHTML = this.innerHtmlMobileBottomDivider;
            }
        }
    }

    public getTopDividerView(): string {
        let viewIndex = +this.block.data.topShapeDividerView - 1;

        if (this.block.data?.topShapeDividerHorizontalFlip === EBlockShapeDividerFlip.HORIZONTAL && 
            this.block.data?.topShapeDividerVerticalFlip === EBlockShapeDividerFlip.NONE) {

            return this.flipHorizontalTopShapeDividers[viewIndex];

        } else if (this.block.data?.topShapeDividerHorizontalFlip === EBlockShapeDividerFlip.NONE && 
            this.block.data?.topShapeDividerVerticalFlip === EBlockShapeDividerFlip.VERTICAL) {

            return this.flipVerticalTopShapeDividers[viewIndex];

        } else if (this.block.data?.topShapeDividerHorizontalFlip === EBlockShapeDividerFlip.HORIZONTAL && 
            this.block.data?.topShapeDividerVerticalFlip === EBlockShapeDividerFlip.VERTICAL) {
            
            return this.flipHorizontalVerticalTopShapeDividers[viewIndex];
        } else {
            return this.topShapeDividers[viewIndex];
        }
    }

    public getBottomDividerView(): string {
        let viewIndex = +this.block.data?.bottomShapeDividerView - 1;

        if (this.block.data?.bottomShapeDividerHorizontalFlip === EBlockShapeDividerFlip.HORIZONTAL && 
            this.block.data?.bottomShapeDividerVerticalFlip === EBlockShapeDividerFlip.NONE) {

            return this.flipHorizontalBottomShapeDividers[viewIndex];

        } else if (this.block.data?.bottomShapeDividerHorizontalFlip === EBlockShapeDividerFlip.NONE && 
            this.block.data?.bottomShapeDividerVerticalFlip === EBlockShapeDividerFlip.VERTICAL) {

            return this.flipVerticalBottomShapeDividers[viewIndex];

        } else if (this.block.data?.bottomShapeDividerHorizontalFlip === EBlockShapeDividerFlip.HORIZONTAL && 
            this.block.data?.bottomShapeDividerVerticalFlip === EBlockShapeDividerFlip.VERTICAL) {
            
            return this.flipHorizontalVerticalBottomShapeDividers[viewIndex];
        } else {
            return this.bottomShapeDividers[viewIndex];
        }
    }

    public getMobileTopDividerView(): string {
        let viewIndex = +this.block.mobileData?.topShapeDividerView - 1;

        if (this.block.mobileData?.topShapeDividerHorizontalFlip === EBlockShapeDividerFlip.HORIZONTAL && 
            this.block.mobileData?.topShapeDividerVerticalFlip === EBlockShapeDividerFlip.NONE) {

            return this.flipHorizontalTopShapeDividers[viewIndex];

        } else if (this.block.mobileData?.topShapeDividerHorizontalFlip === EBlockShapeDividerFlip.NONE && 
            this.block.mobileData?.topShapeDividerVerticalFlip === EBlockShapeDividerFlip.VERTICAL) {

            return this.flipVerticalTopShapeDividers[viewIndex];

        } else if (this.block.mobileData?.topShapeDividerHorizontalFlip === EBlockShapeDividerFlip.HORIZONTAL && 
            this.block.mobileData?.topShapeDividerVerticalFlip === EBlockShapeDividerFlip.VERTICAL) {
            
            return this.flipHorizontalVerticalTopShapeDividers[viewIndex];
        } else {
            return this.topShapeDividers[viewIndex];
        }
    }

    public getMobileBottomDividerView(): string {
        let viewIndex = +this.block.mobileData?.bottomShapeDividerView - 1;

        if (this.block.mobileData?.bottomShapeDividerHorizontalFlip === EBlockShapeDividerFlip.HORIZONTAL && 
            this.block.mobileData?.bottomShapeDividerVerticalFlip === EBlockShapeDividerFlip.NONE) {

            return this.flipHorizontalBottomShapeDividers[viewIndex];

        } else if (this.block.mobileData?.bottomShapeDividerHorizontalFlip === EBlockShapeDividerFlip.NONE && 
            this.block.mobileData?.bottomShapeDividerVerticalFlip === EBlockShapeDividerFlip.VERTICAL) {

            return this.flipVerticalBottomShapeDividers[viewIndex];

        } else if (this.block.mobileData?.bottomShapeDividerHorizontalFlip === EBlockShapeDividerFlip.HORIZONTAL && 
            this.block.mobileData?.bottomShapeDividerVerticalFlip === EBlockShapeDividerFlip.VERTICAL) {
            
            return this.flipHorizontalVerticalBottomShapeDividers[viewIndex];
        } else {
            return this.bottomShapeDividers[viewIndex];
        }
    }

    public getDividerColorValue(value): string {
        switch (value) {
            case 'light':
                return 'var(--light-color)';
            case 'dark':
                return 'var(--dark-color)';
            case 'primary':
                return 'var(--primary-color)';
            case 'secondary':
                return 'var(--secondary-color)';
            case 'accent':
                return 'var(--accent-color)';
            default:
                return value;
        }
    }
}