import type { Observable } from 'rxjs';
import type { ThemeButton, ThemeButtonHover, ThemeColor, ThemeFont } from '@libs/themes';

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { map } from 'rxjs/operators';

import { ThemeButtonData, ThemeButtonStyles } from '@libs/themes';

import { ThemeConfigFacade } from '@libs/themes/src/store/theme-config/theme-config.facade';
import { ThemeSettingsFacade } from '@libs/themes/src/store/theme-settings/theme-settings.facade';

import { EThemeSettings } from '@libs/themes';
import { EThemeFontTags } from '@libs/themes/src/enums/font-tags.enum';
import { FONT_FAMILY_NAMES } from '@libs/themes/src/constants/font-families.conts';

import { InputBoolean } from '@common/helpers/convert';
import { ButtonMode } from '@components/sp-custom-button';

@Component({
    selector: 'sp-themes-button-tab',
    templateUrl: './button-tab.component.html',
    styleUrls: ['./button-tab.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpThemesButtonTabComponent {
    @Input() public styles: ThemeButtonStyles;
    @Input() public base?: ThemeButtonData;
    @Input() public hover?: ThemeButtonHover;
    @Input() public colorsPreset?: ThemeColor[];
    @Input() public buttonMode: ButtonMode = ButtonMode.light;

    @Input() @InputBoolean() public showHoverSettings: boolean = false;

    @Input() @InputBoolean() public isMiniSiteButton: boolean = false;

    @Output() public valueChangeEvent: EventEmitter<ThemeButton> = new EventEmitter();

    public readonly FONT_FAMILY_NAMES = FONT_FAMILY_NAMES;

    constructor(private readonly themeConfigFacade: ThemeConfigFacade, private readonly themeSettingsFacade: ThemeSettingsFacade) {}

    public openFontSettings(): void {
        this.themeConfigFacade.setActiveThemeSettingAction(EThemeSettings.fontsSettings);
    }

    public get selectedThemeButtonFont$(): Observable<ThemeFont> {
        return this.themeSettingsFacade.selectedThemeFonts$.pipe(
            map((fonts: ThemeFont[]) => {
                return fonts.find((font: ThemeFont) => font.tag === EThemeFontTags.button);
            }),
        );
    }
}
