import type { ThemeButton, ThemeColor, ThemeSettingsState } from '@libs/themes';
import {
    formatWebsiteButtonValues,
    formatWebsiteColorsValues,
    formatWebsiteFontsValues,
    formatNextWebsiteButtons,
} from '@libs/themes/src/variants/website/utils/formatter';

import { AbstractThemeFormatter } from './abstract-theme-formatter';

export class WebThemeFormatter extends AbstractThemeFormatter {
    public formatThemeColors(theme: ThemeSettingsState, values: Record<string, string> = {}): Record<string, string> {
        return formatWebsiteColorsValues(values, theme.colors);
    }

    public formatThemeFonts(theme: ThemeSettingsState, values: Record<string, string> = {}): Record<string, string> {
        return formatWebsiteFontsValues(values, theme.fonts);
    }

    public formatThemeButtons(theme: ThemeSettingsState, values: Record<string, string> = {}): Record<string, string> {
        return formatWebsiteButtonValues(values, theme.buttons);
    }

    public formatThemeButtonsHover(theme: ThemeSettingsState, values: Record<string, string> = {}): Record<string, string> {
        return formatWebsiteButtonValues(values, theme.buttons);
    }

    public formatNextButtonSettings(colors: ThemeColor[], buttons: ThemeButton[]): ThemeButton[] {
        return formatNextWebsiteButtons(colors, buttons);
    }
}
