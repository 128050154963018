import { EGalleryTypes } from '@common/enums';
import {
    type CollageType,
    type CarouselOptions,
    type CollageOptions,
    type GridOptions,
} from '@web/widgets/gallery/type/gallery-widget.type';

export const gridOptions: GridOptions = {
    rowItems: 3,
    imageGap: 30,
    borderRadius: 10,
    clickable: false,
};

export const collageOptions: CollageOptions = {
    style: '2col-3item-1',
    imageGap: 30,
    borderRadius: 10,
    clickable: false,
};

export const carouselOptions: CarouselOptions = {
    rowItems: 3,
    type: 'default',
    imageGap: 30,
    borderRadius: 10,
    clickable: false,
    autoplay: false,
    knobsColor: '#fff',
};

export const defaultOptions = {
    [EGalleryTypes.grid]: gridOptions,
    [EGalleryTypes.collage]: collageOptions,
    [EGalleryTypes.carousel]: carouselOptions,
};

/** itemPerLine it's the minimum number of photos for correctly display collage style */
export const collageMetadata: Record<CollageType, { itemPerLine: number; gridColumns: number }> = {
    '2col-1': { itemPerLine: 2, gridColumns: 5 },
    '2col-2': { itemPerLine: 2, gridColumns: 5 },
    '2col-3item-1': { itemPerLine: 3, gridColumns: 12 },
    '2col-3item-2': { itemPerLine: 3, gridColumns: 12 },
    '2col-2row': { itemPerLine: 0, gridColumns: 0 },
    '2row-3item': { itemPerLine: 3, gridColumns: 12 },
    '5col-2row': { itemPerLine: 5, gridColumns: 12 },
    masonry: { itemPerLine: 0, gridColumns: 0 },
    '3col-1': { itemPerLine: 5, gridColumns: 12 },
    '3col-2': { itemPerLine: 5, gridColumns: 12 },
};
