<div *ngIf="widgetData.layout === ETestimonialLayout.grid && (testimonialsList$ | async).length && !isFreeTariff">
    <div [class]="classes?.testimonialWrapper">
        <div
            *ngFor="let testimonial of (testimonialsList$ | async);  index as i;"
            [class]="getClassLayoutType(widgetData)">
            <img
                *ngIf="widgetData.settings.type === ETestimonialType.avatarTop || widgetData.settings.type === ETestimonialType.avatarLeft"
                [src]="testimonial.student.avatar ? imgSrc(testimonial.student.avatar) : BLANK_TESTIMONIAL_AVATAR"
                class="testimonial-avatar"  alt=""/>
            <div class="testimonial-body">
                <div>
                    <div
                        *ngIf="widgetData.settings.type === ETestimonialType.avatarTop || widgetData.settings.type === ETestimonialType.avatarLeft"
                        class="sp-ui-text">
                        <p class="testimonial-name">
                            {{testimonial.student.firstName}}
                        </p>
                    </div>
                    <div class="sp-ui-text">
                        <p class="testimonial-message" [class]="classes?.showMoreTextStyle" [id]="'text' + i + widgetData.selectedCourseId">
                            {{ testimonial.message }}
                        </p>
                        <span
                            *ngIf="widgetData.settings.isShortText"
                            [class]="classes?.showMoreLink"
                            (click)="showFullTextGrid($event, 'text' + i + widgetData.selectedCourseId)">
                            {{widgetData.settings.linkText}}
                        </span>
                    </div>
                </div>
                <div class="testimonial-footer">
                    <img
                        *ngIf="widgetData.settings.type === ETestimonialType.noAvatar"
                        [src]="testimonial.student.avatar ? imgSrc(testimonial.student.avatar) : BLANK_TESTIMONIAL_AVATAR"
                        class="testimonial-avatar"  alt="s"/>
                    <div>
                        <div class="sp-ui-text">
                            <p
                                *ngIf="widgetData.settings.type === ETestimonialType.noAvatar"
                                class="testimonial-name">
                                {{testimonial.student.firstName}}
                            </p>
                        </div>
                        <div class="testimonial-rate">
                            <svg
                                *ngFor="let index of [1,2,3,4,5]"
                                [ngClass]="{'current': testimonial.rate >= index}"
                                xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                <path d="M9.83426 1.04893C10.0438 0.404017 10.9562 0.404018 11.1657 1.04894L13.1493 7.1536C13.243 7.44202 13.5117 7.63729 13.815 7.63729H20.2338C20.9119 7.63729 21.1939 8.50502 20.6453 8.9036L15.4523 12.6765C15.207 12.8547 15.1043 13.1707 15.1981 13.4591L17.1816 19.5638C17.3911 20.2087 16.653 20.745 16.1044 20.3464L10.9114 16.5735C10.6661 16.3953 10.3339 16.3953 10.0886 16.5735L4.89561 20.3464C4.34701 20.745 3.60888 20.2087 3.81842 19.5638L5.80195 13.4591C5.89566 13.1707 5.793 12.8547 5.54766 12.6765L0.354721 8.9036C-0.193879 8.50502 0.0880656 7.63729 0.766172 7.63729H7.185C7.48825 7.63729 7.75702 7.44201 7.85073 7.1536L9.83426 1.04893Z" fill="currentColor"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        *ngIf="((testimonialsList$ | async).length !== total) && widgetData.settings.isShowMore"
        [class]="classes?.showMoreButtonWrapper">
        <a [class]="getButtonStyles(widgetData.buttonSettings)" (click)="loadMore()">
            {{ widgetData.buttonSettings.label }}
        </a>
    </div>
</div>

<div class="testimonial-swiper-container"
     *ngIf="widgetData.layout === ETestimonialLayout.carousel && (testimonialsList$ | async).length && !isFreeTariff">
    <swiper
        class="testimonial-swiper"
        style="--swiper-pagination-color: {{ widgetData.settings.buttonColor }}"
        [config]="swiperConfig"
        [loop]="true"
        [loopedSlides]="(testimonialsList$ | async).length"
        [navigation]="true"
        [pagination]="{ clickable: true, dynamicBullets: true }"
        [grabCursor]="true"
        [autoplay]="autoplay"
        [spaceBetween]="widgetData.settings.spaceBetween"
        [slidesPerView]="getSlidesPerView((testimonialsList$ | async).length, widgetData.settings.rowItems)">
        <ng-template swiperSlide *ngFor="let testimonial of (testimonialsList$ | async); index as i;">
            <div [class]="getClassLayoutType(widgetData)">
                <img
                    *ngIf="widgetData.settings.type === ETestimonialType.avatarTop || widgetData.settings.type === ETestimonialType.avatarLeft"
                    [src]="testimonial.student.avatar ? imgSrc(testimonial.student.avatar) : BLANK_TESTIMONIAL_AVATAR"
                    class="testimonial-avatar"  alt=""/>
                <div class="testimonial-body">
                    <div>
                        <div
                            *ngIf="widgetData.settings.type === ETestimonialType.avatarTop || widgetData.settings.type === ETestimonialType.avatarLeft"
                            class="sp-ui-text">
                            <p class="testimonial-name">
                                {{testimonial.student.firstName}}
                            </p>
                        </div>
                        <div class="sp-ui-text">
                            <p class="testimonial-message" [class]="classes?.showMoreTextStyle + ' text' + i + widgetData.selectedCourseId">
                                {{ testimonial.message }}
                            </p>
                            <span *ngIf="widgetData.settings.isShortText"
                                  [class]="classes?.showMoreLink"
                                  (click)="showFullTextSlider($event, 'text' + i + widgetData.selectedCourseId)">
                                {{widgetData.settings.linkText}}
                            </span>
                        </div>
                    </div>
                    <div class="testimonial-footer">
                        <img
                            *ngIf="widgetData.settings.type === ETestimonialType.noAvatar"
                            [src]="testimonial.student.avatar ? imgSrc(testimonial.student.avatar) : BLANK_TESTIMONIAL_AVATAR"
                            class="testimonial-avatar"  alt=""/>
                        <div>
                            <div class="sp-ui-text">
                                <p
                                    *ngIf="widgetData.settings.type === ETestimonialType.noAvatar"
                                    class="testimonial-name">
                                    {{testimonial.student.firstName}}
                                </p>
                            </div>
                            <div class="testimonial-rate">
                                <svg
                                    *ngFor="let index of [1,2,3,4,5]"
                                    [ngClass]="{'current': testimonial.rate >= index}"
                                    xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                    <path d="M9.83426 1.04893C10.0438 0.404017 10.9562 0.404018 11.1657 1.04894L13.1493 7.1536C13.243 7.44202 13.5117 7.63729 13.815 7.63729H20.2338C20.9119 7.63729 21.1939 8.50502 20.6453 8.9036L15.4523 12.6765C15.207 12.8547 15.1043 13.1707 15.1981 13.4591L17.1816 19.5638C17.3911 20.2087 16.653 20.745 16.1044 20.3464L10.9114 16.5735C10.6661 16.3953 10.3339 16.3953 10.0886 16.5735L4.89561 20.3464C4.34701 20.745 3.60888 20.2087 3.81842 19.5638L5.80195 13.4591C5.89566 13.1707 5.793 12.8547 5.54766 12.6765L0.354721 8.9036C-0.193879 8.50502 0.0880656 7.63729 0.766172 7.63729H7.185C7.48825 7.63729 7.75702 7.44201 7.85073 7.1536L9.83426 1.04893Z" fill="currentColor"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </swiper>

    <div [ngStyle]="{'color': widgetData.settings.buttonColor}" class="swiper-button-prev-unique"></div>
    <div [ngStyle]="{'color': widgetData.settings.buttonColor}" class="swiper-button-next-unique"></div>
</div>

<div *ngIf="(testimonialsList$ | async).length === 0 || isFreeTariff" [class]="classes?.blankImageWrapper">
    <img [src]="BLANK_TESTIMONIAL" alt="gallery_blank">
    <span>{{ "testimonial_empty_state" | translate }}</span>
</div>
