import { AfterContentInit, ChangeDetectorRef, inject } from '@angular/core';
import { Component, HostListener, Input } from '@angular/core';
import { FormArray, FormGroup, FormsModule } from '@angular/forms';
import { type AbstractControl, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateModule } from '@ngx-translate/core';

import { EduCourse } from '@common/types/edu-course.type';
import { AnalyticsSettings } from '@web/shared/components/web-analytics-settings/web-analytics-setings.type';

import { TransferStateService } from '@web-builder/core/services/transfer-state.service';
import { UtilsService } from '@web-builder/core/services/utils.service';
import { EduHttpService } from '@web-builder/core/services/REST/edu.http.service';
import { AnalyticsService } from '@web-builder/core/services/analytics.service';
import { Classes } from 'jss';
import { UtmTagsService } from '@web-builder/core/services/utm-tags.service';

import { type CheckboxButtonData } from '@web/widgets/login-edu';
import { SpPipesModule } from '@common/pipes/pipes.module';
import { type HttpErrorResponse } from '@angular/common/http';
import { EAttributeTypesCrm, EContactFieldsCrm, ECrmCommentPlace, EFormControlType, EFormInputType } from '@common/enums';
import {
    type CheckboxData,
    type CheckboxesFormControl,
    type SignUpFormData,
    type SignUpModalResult,
} from '@web-builder/widgets/login-edu/type/login-edu.type';
import { type FormElement, type FormRadio, type Option } from '@common/types/form-element.type';
import * as typeGuards from '@common/type-guards/form-elements.type-guards';
import { CountryISO, NgxIntlTelInputModule, PhoneNumberFormat, SearchCountryField } from '@moddi3/ngx-intl-tel-input';
import { isNumber } from '@common/helpers';
import { NgSelectModule } from '@ng-select/ng-select';
import { CountryISOService } from '@common/services/REST/country-iso.http.service';

@Component({
    selector: 'web-builder-sign-up-dynamic-modal',
    styleUrls: ['./sign-up-dynamic-modal.component.less'],
    templateUrl: './sign-up-dynamic-modal.component.html',
    imports: [CommonModule, ReactiveFormsModule, TranslateModule, SpPipesModule, NgxIntlTelInputModule, NgSelectModule, FormsModule],
    standalone: true,
})
export class SignUpDynamicModalComponent implements AfterContentInit {
    @Input() public eduCourse!: EduCourse;
    @Input() public domainId!: number;
    @Input() public isAuthorizationButtonActive!: boolean;
    @Input() public analytics: AnalyticsSettings;
    @Input() public classes!: Classes;
    @Input() public checkboxes: CheckboxButtonData[] = [];
    @Input() public isShowCheckboxes: boolean;
    @Input() public elements: FormElement[];

    public onClose: Subject<SignUpModalResult>;
    public form: any;
    public showErrorMessage: boolean = false;
    public CountryISO = CountryISO.Ukraine;
    private readonly countryISOService: CountryISOService = inject(CountryISOService);
    public dropdownListValues = [];

    constructor(
        private readonly fb: NonNullableFormBuilder,
        private readonly bsModalRef: BsModalRef,
        private readonly transferStateService: TransferStateService,
        private readonly utilsService: UtilsService,
        private readonly eduHttpService: EduHttpService,
        private readonly analyticsService: AnalyticsService,
        private readonly utmTagsService: UtmTagsService,
        private readonly changeDetectorRef: ChangeDetectorRef,
    ) {}

    public ngAfterContentInit(): void {
        this.initForm();
        this.getCountryISO();
        this.onClose = new Subject();
    }

    private initForm(): void {
        this.form = this.fb.group({
            checkboxes: this.checkboxesArray,
            formArray: this.fb.array(
                // TODO: remove any
                this.elements.map((formElement) => {
                    const options = (formElement as FormRadio)?.options || null;

                    this.dropdownListValues.push(options ? options.filter((el) => el.selected) : options);
                    return this.fb.group({
                        ...{
                            ...formElement,
                            ...{
                                options: this.fb.array(options ? (formElement as FormRadio).options.map((opt) => this.fb.group(opt)) : []),
                            },
                        },
                        value: [this.getDefaultValue(formElement, options), this.getValidators(formElement)],
                    });
                }),
            ),
        });

        this.form.markAsUntouched();
    }

    public get formArrayControls(): AbstractControl[] {
        return (this.form.get('formArray') as FormArray).controls;
    }

    public onSignUp(): void {
        if (this.form.invalid) {
            this.showErrorMessage = true;
            this.form.markAllAsTouched();
            return;
        }

        this.showErrorMessage = false;
        const payload: any = {
            language: this.utilsService.getLanguageCode(this.transferStateService.get('lang')),
            domainId: this.domainId,
            responsibleId: this.eduCourse.userId,
            courseId: this.eduCourse.id,
            attributes: this.attributesPayload,
            contactComments: [],
            dealComments: [],
        };

        this.formArrayControls.forEach((control) => {
            if (control.value.variable) {
                payload[control.value.variable] = control.value.value;
            }

            if (control.value.type === EFormInputType.phone) {
                if (control.value.value?.e164Number) {
                    payload.phone = control.value.value?.e164Number.replace(/[^0-9]/g, '');
                } else {
                    payload.phone = control.value.value.replace(/[^0-9]/g, '');
                }
            }

            if (
                control.value.crmContactVariable === EContactFieldsCrm.firstName ||
                control.value.crmContactVariable === EContactFieldsCrm.lastName
            ) {
                payload[control.value.crmContactVariable] = control.value.value;
            }

            if (control.value.type === EFormControlType.textarea) {
                if (control.value.crmCommentPlace === ECrmCommentPlace.crmAllComment) {
                    payload.contactComments.push(control.value.value);
                    payload.dealComments.push(control.value.value);
                }
                if (control.value.crmCommentPlace === ECrmCommentPlace.crmContactComment) {
                    payload.contactComments.push(control.value.value);
                }
                if (control.value.crmCommentPlace === ECrmCommentPlace.crmDealComment) {
                    payload.dealComments.push(control.value.value);
                }
            }
        });

        if (!payload.firstName) payload.firstName = this.emailControl.value.value.split('@')[0];

        const utm = this.utmTagsService.getUtmTags();
        if (Boolean(utm)) {
            payload.utm = utm;
        }

        this.form.disable();
        this.eduHttpService.register(payload).subscribe(
            () => {
                this.bsModalRef.hide();
                if (this.analytics?.googleAnalytic) {
                    this.analyticsService.gaEventRequest(this.analytics?.googleSettings);
                }

                if (this.analytics?.pixelAnalytic) {
                    this.analyticsService.pixelFbEventRequest(this.analytics?.pixelSettings);
                }

                this.onClose.next({
                    data: this.formData,
                });
            },
            (error: HttpErrorResponse) => {
                this.form.enable();

                if (error.status === 422) {
                    const { errors } = error?.error;

                    if ('email' in errors) {
                        this.form.setErrors({ unique: true });
                        this.formArrayControls.find((control) => control.value.type === EFormInputType.email).setErrors({ unique: true });
                    }

                    if ('courseId' in errors) {
                        this.form.setErrors({ invalidCourse: true });
                    }
                }
            },
        );
    }

    public onSignIn(): void {
        this.onClose.next({ message: 'signIn' });
        this.bsModalRef.hide();
    }

    public onCloseModal(): void {
        this.bsModalRef.hide();
    }

    public changeCheckBoxValue(control: AbstractControl): void {
        control.patchValue({ ...control.value, value: !control.value.value });
    }

    @HostListener('document:keydown.enter')
    public onEnterPress(): void {
        this.onSignUp();
    }

    private get checkboxesArray(): FormArray<FormGroup<CheckboxesFormControl>> {
        const array = this.fb.array<FormGroup<CheckboxesFormControl>>([]);

        if (!this.checkboxes?.length) {
            return array as FormArray<FormGroup<CheckboxesFormControl>>;
        }

        this.checkboxes.forEach((checkbox) => {
            array.push(
                this.fb.group({
                    crmVariableType: this.fb.control(checkbox.crmVariableType),
                    crmContactVariable: this.fb.control(checkbox.crmContactVariable),
                    required: this.fb.control(checkbox.required),
                    label: this.fb.control(checkbox.label),
                    value: this.fb.control(false, this.isShowCheckboxes && checkbox.required ? [Validators.requiredTrue] : null),
                }),
            );
        });

        return array as FormArray<FormGroup<CheckboxesFormControl>>;
    }

    public get checkboxesControls(): FormArray {
        return this.form.get('checkboxes') as FormArray;
    }

    private get formData(): SignUpFormData {
        const checkboxesArr: CheckboxData[] = this.checkboxesControls.controls.map((checkbox) => ({ ...checkbox.getRawValue() }));

        return {
            ...this.form.getRawValue(),
            checkboxes: checkboxesArr,
        };
    }

    private get attributesPayload() {
        const attributes = [];
        if (this.checkboxesControls.controls.length) {
            this.checkboxesControls.controls.forEach((control) => {
                const checkbox = {
                    value:
                        control.value?.crmVariableType === EAttributeTypesCrm.checkbox
                            ? control.value.value
                            : control.value.value?.toString(),
                    attributeId: control.value?.crmContactVariable,
                };

                if (checkbox.attributeId) {
                    attributes.push(checkbox);
                }
            });
        }

        if (this.formArrayControls.length) {
            this.formArrayControls.forEach((control) => {
                const attribute = {
                    value: control.value.value?.toString(),
                    attributeId: control.value?.crmContactVariable,
                };

                if (isNumber(attribute?.attributeId)) {
                    attributes.push(attribute);
                }
            });
        }

        return attributes;
    }

    public isControlTypeHidden(type): boolean {
        return type === EFormInputType.hidden;
    }

    public getControlValue(control: AbstractControl, controlName: string) {
        return control.get(controlName)?.value;
    }

    public refreshErrorMessageValue() {
        this.showErrorMessage = !this.showErrorMessage;
    }

    public inputErrors(control: AbstractControl): string[] {
        const errors: string[] = [];

        Object.keys(control.get('value').errors).forEach((err) => {
            if (control.get('type').value === EFormControlType.radio) {
                const isRadioChecked = control.get('options').value.some((el) => el.selected);
                if (!isRadioChecked && err === 'required') {
                    errors.push('control_errors_required');
                }

                return;
            }

            if (err === 'email') errors.push('email_validation_error');
            if (control.get('type').value === EFormInputType.phone && !control.get('isDynamicPlaceholderEnabled').value)
                errors.push('control_errors_invalid_phone_number');
            if (err === 'required' && control.get('type').value !== EFormControlType.checkbox) errors.push('control_errors_required');
            if (err === 'required' && control.get('type').value === EFormControlType.checkbox)
                errors.push('control_errors_checkbox_required');
            if (err === 'required' && control.get('type').value === EFormControlType.textarea) errors.push('control_errors_required');
            if (err === 'maxlength' && control.get('type').value === EFormControlType.textarea) errors.push('control_errors_textarea_maxlength');
            if (err === 'maxlength' && control.get('type').value === EFormInputType.name) errors.push('control_errors_name_maxlength');
        });

        return errors;
    }

    public changeCheckRadioValue(control: AbstractControl, option: { label: string; selected: boolean; value: string }) {
        const opt = this.getControlValue(control, 'options').map((el) => {
            return {
                ...el,
                ...{ selected: option.label === el.label && option.value === el.value },
            };
        });
        control.get('options').patchValue(opt);
        control.get('value').patchValue(option.value);
    }

    public changeSelectValue(control: AbstractControl, event) {
        if (event) {
            if (Array.isArray(event)) {
                const opt = this.getControlValue(control, 'options').map((el) => {
                    return {
                        ...el,
                        ...{ selected: event.map((e) => e.value).indexOf(el.value) > -1 },
                    };
                });
    
                control.get('value').patchValue(event.length ? `["${event.map((el) => el.value).join('","')}"]` : null);
                control.get('options').patchValue(opt);
            } else {
                const opt = this.getControlValue(control, 'options').map((el) => {
                    return {
                        ...el,
                        ...{ selected: event.value === el.value },
                    };
                });
    
                control.get('value').patchValue(event.value);
                control.get('options').patchValue(opt);
            }
        }
    }

    public getSelected(options: Option[]) {
        return !options.some((el) => el.selected);
    }

    public getPhoneErrorMsg() {
        return (document.getElementById('phone') as any).placeholder;
    }

    private getValidators(elem: FormElement): Validators[] {
        let validators: Validators[] = [];

        if (elem.required && elem.type === EFormControlType.radio && !elem.options.some((el) => el.selected)) {
            validators.push(Validators.required);
            return validators;
        }

        if (elem.required && elem.type !== EFormControlType.checkbox) {
            validators.push(Validators.required);
        }

        if (elem.type === EFormInputType.email) {
            validators.push(Validators.email);
        }

        if (elem.type === EFormControlType.checkbox && elem.required) {
            validators.push(Validators.requiredTrue);
        }

        if (elem.type === EFormControlType.textarea) {
            validators.push(Validators.maxLength(5000));

            if (elem.required) {
                validators.push(Validators.required);
            }
        }

        if (elem.type === EFormInputType.input || elem.type === EFormInputType.name) {
            validators.push(Validators.maxLength(128));
        }

        return validators;
    }

    private getDefaultValue(element: FormElement, options: Option[]): any {
        if (element.type === EFormControlType.checkbox) {
            return false;
        }

        if (element.type === EFormControlType.select) {
            return options?.find((el) => el.selected)?.value;
        }

        if (element.type === EFormControlType.radio) {
            return options?.find((el) => el.selected)?.value;
        }

        if (element.type === EFormInputType.hidden) {
            return element.value;
        }

        return '';
    }

    public get emailControl() {
        return this.formArrayControls.find((control) => control.value.type === EFormInputType.email);
    }

    protected readonly typeGuards = typeGuards;
    protected readonly PhoneNumberFormat = PhoneNumberFormat;
    protected readonly SearchCountryField = SearchCountryField;
    protected readonly EFormInputType = EFormInputType;

    public isShowSelectErr(control: AbstractControl) {
        const isSelected =
            this.getControlValue(control, 'options')
                .map((e) => e.selected)
                .indexOf(true) > -1;

        return this.getControlValue(control, 'required') && this.showErrorMessage && !isSelected;
    }

    private getCountryISO() {
        this.countryISOService.getCountryISO().subscribe((data) => {
            this.CountryISO = data.country_code.toLowerCase();
            this.changeDetectorRef.detectChanges();
        });
    }
}
