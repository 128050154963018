<div #ref>
    <ng-content></ng-content>
</div>

<div *ngIf="!ref.children.length && showCreateButton">
    <sp-defautl-upload-button></sp-defautl-upload-button>
</div>

<ng-container
    *ngIf="(file || files) && !showCreateButton"
    [ngTemplateOutlet]="previewTemplate || defaultPreviews"
    [ngTemplateOutletContext]="{
        replaceFileFn: replaceFileHandlerFn,
        encodeFilePreviewUrlFn: encodeFilePreviewUrlFn,
        file: file,
        files: files
    }"
></ng-container>

<ng-template #defaultPreviews>
    <sp-image-preview-single
        *ngIf="previewType === PreviewType.IMAGE_SINGLE && !showCreateButton"
        [file]="file"
        [isLink]="isLink"
        [cdnUrl]="cdnUrl"
        [showSetupBtn]="showSetupBtn"
        (removeFileEvent)="removeFileEvent.emit($event)"
        (updateFileEvent)="updateFileEvent.emit($event)"
        (replaceFileEvent)="replaceFileHandler($event)"
        (imageEditEvent)="imageEditEvent.emit()"
        [short]="short"
    ></sp-image-preview-single>

    <sp-image-preview-multiple
        *ngIf="previewType === PreviewType.IMAGE_MULTIPLE && files"
        [files]="files"
        [isLink]="isLink"
        [cdnUrl]="cdnUrl"
        [showSetupBtn]="showSetupBtn"
        (updateFilesEvent)="updateFilesEvent.emit($event)"
        (activateFileEvent)="activateFileEvent.emit($event)"
        (removeFileEvent)="removeFileEvent.emit($event)"
        (replaceFileEvent)="replaceFileHandler($event)"
        (imageEditEvent)="imageCarouselEditEvent.emit($event)"
    ></sp-image-preview-multiple>

    <sp-file-preview
        *ngIf="previewType === PreviewType.FILE && files"
        [files]="files"
        [cdnUrl]="cdnUrl"
        (updateFilesEvent)="updateFilesEvent.emit($event)"
        (replaceFileEvent)="replaceFileHandler($event)"
    ></sp-file-preview>
    <ng-container *ngIf="previewType === PreviewType.VIDEO">
        <sp-video-preview
            [file]="file"
            [isFreeTariff]="isFreeTariff"
            (removeFileEvent)="removeFileEvent.emit($event)"
            (updateFileEvent)="updateFileEvent.emit($event)"
            (replaceFileEvent)="replaceFileHandler($event)"
            (openImagePreviewFm)="setupPreviewImage()"
            (addVideos)="fileManagerContextService.openFileManagerDrawer()"
            (removePreviewImage)="removePreviewImage()"
        >
        </sp-video-preview>
    </ng-container>

    <sp-audio-preview
        *ngIf="previewType === PreviewType.AUDIO && file"
        [file]="file"
        (removeFileEvent)="removeFileEvent.emit()"
        (replaceFileEvent)="replaceFileHandler()"
    ></sp-audio-preview>
</ng-template>

<div #refAddMore>
    <ng-content select="[slot='add-more-files']"></ng-content>
</div>

<div *ngIf="!refAddMore.children.length && previewType !== PreviewType.IMAGE_SINGLE && !showCreateButton && files">
    <sp-link-upload-button></sp-link-upload-button>
</div>
