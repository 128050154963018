<div *ngIf="files.length > 0"
     cdkDropList
     [cdkDropListData]="filesForm.value"
     (cdkDropListDropped)="dropGroup($event)">
    <div cdkDrag cdkDropListGroup [cdkDragData]="file" class="panel panels panel-default" *ngFor="let file of filesForm.value; let i = index; trackBy: trackFn">
        <div class="panel-body" (click)="activateFileEvent.emit(i)" [class.selected]="file.isActive">
            <img cdkDragHandle class="handle" src="./assets/img/dnd-icon.svg" alt="icon">
            <div
                class="panel-image panel-images"
                dropdown
                #dropdown="bs-dropdown"
                [insideClick]="true"
                (onHidden)="emitHiddenEventToChild()">
                <div class="panel-image-content">
                    <div class="preview-thumbnail" [style.background-image]="encodeFilePreviewUrl(file)"></div>
                    <div class="file-name">
                        <strong class="text-muted">{{ file.name }}</strong>
                    </div>
                </div>

                <div>
                    <button
                        class="btn btn-link"
                        (click)="imageEditEvent.emit(i)">
                        <span class="sp-icon icon-crop-pic"> </span>
                    </button>
                    <button (click)="replaceFileEvent.emit(i)" class="btn btn-link">
                        <a>
                            <span class="sp-icon icon-marquee-upload"> </span>
                        </a>
                    </button>

                    <button
                        *ngIf="showSetupBtn"
                        class="btn btn-link"
                        dropdownToggle>
                        <span class="sp-icon icon-settings"> </span>
                    </button>

                    <button (click)="removeMultipleFile(file)" class="btn btn-link">
                        <a>
                            <span class="sp-icon icon-bin"> </span>
                        </a>
                    </button>
                </div>

                <div *dropdownMenu class="dropdown-menu">
                    <sp-setup-image-dropdown
                        [file]="file"
                        [isLink]="isLink"
                        [closeEvent]="closeSubject.asObservable()"
                        (save)="saveSetupData($event, i)"
                        (close)="dropdown.hide()">
                    </sp-setup-image-dropdown>
                </div>
            </div>
        </div>
    </div>
</div>
