import type { SelectedFileExtended } from '@common/types/file.type';
import type { ImageWidgetData } from '@web/widgets/image';
import type { AnimationSettings } from '@common/components/sp-animation-settings/type/animation-settings.type';

import { type AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import { getStyles } from '@web/widgets/image/components/image-view/image-view.component.styles';
import { BLANK_IMAGE } from '@web/widgets/image/constants/image.consts';

import { AbstractWidgetComponent } from '@web-builder/widgets/abstract-widget.component';
import { SpDirectivesModule } from '@common/directives/directives.module';
import { setChatAt } from '@common/helpers/string';
import { getGsapScrollTrigger } from '@components/sp-animation-settings';
import { environment } from '@web-builder-env/environment';

@Component({
    selector: 'web-builder-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, SpDirectivesModule],
})
export class ImageComponent extends AbstractWidgetComponent<ImageWidgetData> implements AfterViewInit {
    @ViewChild('imageRef') imageRef: ElementRef<HTMLElement>;
    @ViewChild('overlayImageRef') overlayImageRef: ElementRef<HTMLElement>;
    @ViewChild('animatedElem') animatedElem: ElementRef<HTMLDivElement>;

    protected getStyles = getStyles;

    public srcsetSources: { size: number; media: string; url?: string }[] = [
        {
            size: 720,
            media: '(max-width: 720px)',
        },
        {
            size: 1080,
            media: '(max-width: 1080px)',
        },
        {
            size: 1920,
            media: '(min-width: 1081px)',
        },
    ];

    public ngAfterViewInit(): void {
        const animationOptions = this.widgetData.animation;
        if (animationOptions.isActive) getGsapScrollTrigger(animationOptions, this.animatedElem.nativeElement);
    }

    public get getMaskUrl(): string {
        const src = this.formatImgSrc(`./assets/img/websites/mask-shapes/${this.image?.maskShape}.svg`);
        return 'url(' + src + ')';
    }

    protected widgetInit() {
        if (!this.image?.hasResize) {
            return;
        }

        const splitImgSrc = this.imgSrc.split('/');
        splitImgSrc.splice(4, 0, 'resize_landing');
        const extensionDotIndex = splitImgSrc[splitImgSrc.length - 1].lastIndexOf('.');
        splitImgSrc[splitImgSrc.length - 1] = setChatAt(splitImgSrc[splitImgSrc.length - 1], extensionDotIndex, '_');

        const res = splitImgSrc.join('/');

        this.srcsetSources = this.srcsetSources.map((srcset) => {
            if (srcset.size >= this.image.imageWidth || this.image.extension.includes('svg')) {
                if (this.image?.hasSourceResize) {
                    return { ...srcset, url: `${res}/source.webp` };
                }

                return { ...srcset, url: this.imgSrc };
            }

            return { ...srcset, url: `${res}/${srcset.size}.webp` };
        });
    }

    public get image(): SelectedFileExtended {
        return this.widgetData.image;
    }

    public get imgSrc(): string {
        if (!this.image) {
            return this.formatImgSrc(BLANK_IMAGE);
        }

        if (this.image.url.startsWith('http')) {
            return this.image.url;
        }

        return `${environment.fileManagerCdnUrl}${this.image.url}`;
    }

    public get link(): string {
        const trimmedLink = this.image?.link?.trim();

        if (!trimmedLink) {
            return null;
        }

        return this.linkFormatterService.formatLink(trimmedLink);
    }

    public get isTargetBlank(): boolean {
        return this.image?.isTargetBlank;
    }

    public get placeholder(): string {
        return this.image?.name || 'placeholder';
    }

    public get animation(): AnimationSettings {
        return this.widgetData.animation;
    }

    public get overlayImage(): SelectedFileExtended {
        return this.widgetData?.image?.overlayImage;
    }

    public get overlayImageSrc(): string | boolean {
        if (!this.overlayImage || !this.image?.isImageEffect) {
            return false;
        }

        return `${environment.fileManagerCdnUrl}${this.overlayImage.url}`;
    }

    public mouseImgIn() {
        if (!this.overlayImageSrc) return;

        this.imageRef.nativeElement.style.opacity = '0';
        this.overlayImageRef.nativeElement.style.opacity = '1';
    }

    public mouseImgOut() {
        if (!this.overlayImageSrc) return;

        this.imageRef.nativeElement.style.opacity = '1';
        this.overlayImageRef.nativeElement.style.opacity = '0';
    }
}
