import { type OnInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { Classes } from 'jss';
import { StructurePageStructureEntity } from '@web/types';
import { ServerStylesService } from '@common/services/server-styles.service';
import { getStyles } from '@web/layout/content/page-content/components/structures/structure-wrapper/structure-wrapper.component.styles';
import type { Variant } from '@web/store/page-structure/structures/structures.models';

const VARIANTS_MAP: Record<number, Partial<Variant>> = {
    0: [[{ colSpan: 4 }, { colSpan: 4 }]],
    1: [[{ colSpan: 4 }, { colSpan: 4 }], [{ colSpan: 8 }]],
    2: [[{ colSpan: 3 }, { colSpan: 5 }]],
    3: [[{ colSpan: 2 }, { colSpan: 4 }, { colSpan: 2 }]],
    4: [
        [{ colSpan: 4 }, { colSpan: 4 }],
        [{ colSpan: 4 }, { colSpan: 4 }],
    ],
};

function prepareVariant(variant: Variant): Variant {
    let index = 0;
    return variant.map((row) => {
        return row.map((cell) => {
            return {
                colSpan: cell.colSpan,
                index: index++,
            };
        });
    });
}

function getVariantMap(variant: number) {
    if (VARIANTS_MAP[variant]) {
        return prepareVariant(VARIANTS_MAP[variant]);
    }

    return prepareVariant(VARIANTS_MAP[0]);
}

@Component({
    selector: 'web-builder-structure-wrapper',
    templateUrl: './structure-wrapper.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class StructureWrapperComponent implements OnInit {
    @Input() public structure: StructurePageStructureEntity;
    @Input() public index: number;

    public classes: Classes;

    constructor(private readonly stylesService: ServerStylesService) {}

    public ngOnInit(): void {
        const styles = getStyles(this.structure, getVariantMap(this.structure.variant));
        this.classes = this.stylesService.getStyles(this.structure.id, styles);
    }
}
