import { type AfterViewInit, ElementRef, type OnInit, ViewChild } from '@angular/core';
import type { Classes } from 'jss';
import { ColumnStructureEntity, SectionStructureEntity } from '@web/types';
import type { AnimationSettings } from '@common/components/sp-animation-settings/type/animation-settings.type';

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { ServerStylesService } from '@common/services/server-styles.service';

import { getStyles } from '@web/layout/content/page-content/components/columns/column-wrapper/column-wrapper.component.styles';
import { PlatformService } from '@common/services/platform.service';
import { getGsapScrollTrigger } from '@components/sp-animation-settings';
import { TransferStateService } from '@web-builder/core/services/transfer-state.service';
import { environment } from '@web-builder-env/environment';

@Component({
    selector: 'web-builder-column-wrapper',
    templateUrl: './column-wrapper.component.html',
    styleUrls: ['./column-wrapper.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnWrapperComponent implements OnInit, AfterViewInit {
    @Input() public column!: ColumnStructureEntity;
    @Input() public columnSection!: SectionStructureEntity;
    @Input() public index!: number;

    @ViewChild('columnBg') columnBg: ElementRef<HTMLDivElement>;
    @ViewChild('animatedElem') animatedElem: ElementRef<HTMLDivElement>;
    @ViewChild('scrollTrigger') scrollTrigger: ElementRef<HTMLDivElement>;

    public classes: Classes;
    public isBrowser: boolean = this.platformService.isPlatformBrowser();
    public isPreview: boolean = this.transferStateService.isPreview;

    constructor(
        protected readonly stylesService: ServerStylesService,
        private readonly platformService: PlatformService,
        protected readonly transferStateService: TransferStateService,
    ) {}

    public ngOnInit(): void {
        this.classes = this.stylesService.getStyles(
            this.column.id,
            getStyles(this.column, this.columnSection, true, this.isPreview, environment.fileManagerCdnUrl),
        );
    }

    public ngAfterViewInit(): void {
        if (this.isActiveAnimation) getGsapScrollTrigger(this.animation, this.animatedElem.nativeElement, this.scrollTrigger.nativeElement);
    }

    public get animation(): AnimationSettings {
        return this.column.data.animation;
    }

    public get columnId(): string {
        return `column-${this.column.id}`;
    }

    public get isActiveAnimation(): boolean {
        return this.animation?.isActive;
    }
}
