import type { OnDestroy, OnInit } from '@angular/core';
import type { Observable } from 'rxjs';
import type { ThemeColor } from '@libs/themes/src/types/theme-color.type';
import type { ThemeButton, ThemeButtonData, ThemeSelectedButtons } from '@libs/themes/src/types/theme-button.type';

import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

import { EButtonStyleType } from '@common/enums';

import { ThemeFacade } from '@libs/themes/src/store/theme/theme.facade';
import { ThemeSettingsFacade } from '@libs/themes/src/store/theme-settings/theme-settings.facade';

@Component({
    template: '',
})
export abstract class SpThemeButtonSettingsComponent implements OnInit, OnDestroy {
    public themeButtons: ThemeButton[];
    public initialButtonsState: ThemeSelectedButtons;
    public isSaved = false;
    public currentBtnType = EButtonStyleType.primary;

    private isDirty = false;

    public readonly themeColors$: Observable<ThemeColor[]> = this.themeFacade.selectedThemeColors$;

    public readonly BUTTON_TYPES = [EButtonStyleType.primary, EButtonStyleType.secondary];
    public readonly BUTTON_STYLE_TYPES_TRANSLATIONS = {
        [EButtonStyleType.primary]: 'theme_button_settings_primary',
        [EButtonStyleType.secondary]: 'theme_button_settings_secondary',
    };

    protected unsubscribe$ = new Subject<void>();

    constructor(private readonly themeFacade: ThemeFacade, private readonly themeSettingsFacade: ThemeSettingsFacade) {}

    protected abstract formatThemeButtons(buttons: ThemeSelectedButtons, themeValues: Record<string, string>): ThemeButton[];

    public ngOnInit(): void {
        this.themeSettingsFacade.themeButtons$
            .pipe(
                takeUntil(this.unsubscribe$),
                withLatestFrom(this.themeFacade.themeValues$, (buttons: ThemeSelectedButtons, values: Record<string, string>) => ({
                    buttons,
                    values,
                })),
            )
            .subscribe(({ buttons, values }) => {
                this.themeButtons = this.formatThemeButtons(buttons, values);

                if (!this.initialButtonsState) {
                    this.initialButtonsState = buttons;
                }
            });
    }

    public valueChangeHandler(type: string, changes: ThemeButton): void {
        this.isDirty = true;
        const themeButtons = this.themeButtons.map((themeButton: ThemeButton) => {
            if (themeButton.styleType === type) {
                return {
                    ...themeButton,
                    base: {
                        ...changes.base,
                        borderWidth: changes.base.borderWidth + 'px',
                        borderRadius: changes.base.borderRadius + 'px',
                    },
                    hover: {
                        ...changes.hover
                    },
                };
            }

            return themeButton;
        });

        this.dispatchValues({ selected: themeButtons, selectedIndex: null });
    }

    private dispatchValues(themeButtons: ThemeSelectedButtons): void {
        this.themeSettingsFacade.changeButtonsAction({
            values: themeButtons.selected,
            index: themeButtons.selectedIndex,
        });
    }

    public onSave(): void {
        this.isSaved = true;
        this.themeFacade.unselectActiveThemeSettingAction();
    }

    public onCancel(): void {
        if (this.isDirty) {
            this.dispatchValues(this.initialButtonsState);
        }

        this.themeFacade.unselectActiveThemeSettingAction();
    }

    public ngOnDestroy(): void {
        if (!this.isSaved) {
            this.dispatchValues(this.initialButtonsState);
        }

        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
