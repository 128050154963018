<div class="panel-options-body">
    <form [formGroup]="form" class="form form-horizontal">
        <div class="form-row">
            <div class="form-group mb-20" *ngFor="let colorName of colorNames" >
                <div class="col-xs-10">
                    <label>{{ COLOR_NAME_TRANSLATIONS[colorName] | translate }}</label>
                    <div class="text-muted small">{{ COLOR_DESCRIPTION_TRANSLATIONS[colorName] | translate }}</div>
                </div>
                <div class="col-xs-2">
                    <sp-color-picker
                        hideGradient
                        [color]="form.get(colorName).value"
                        (colorChangeEvent)="onChangeColor(colorName, $event)">
                    </sp-color-picker>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="panel-options-footer">
    <button type="button" class="btn btn-default mr-10" (click)="onCancel()">{{ 'cancel' | translate }}</button>
    <button type="button" class="btn btn-success" (click)="onSave()">{{ 'save' | translate }}</button>
</div>

