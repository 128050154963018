<div class="alert-box edu-modal" [class]="classes.modalBodyStyles">
    <div class="sp-lpc-form">
        <button type="button" class="close-modal-btn" aria-label="Close" (click)="onCloseModal()">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="sp-ui-text">
            <h2 class="edu-modal-title">{{ "sign_in" | translate }}</h2>
        </div>

        <div *ngIf="isFormInvalid" class="error-alert">
            <div *ngIf="form.hasError('invalidCredentials')">
                {{ "error_forbidden_access" | translate }}
            </div>

            <div *ngIf="form.hasError('userNotFound')">
                {{ "error_user_not_found" | translate }}
            </div>

            <div *ngIf="form.hasError('tooManyRequests')">
                {{ "error_too_many_requests" | translate }}
            </div>
        </div>

        <div class="field-block" [class]="classes.formField">
            <label>Email</label>
            <input
                name="email"
                [class]="classes.formControl"
                [class.has-error]="email.invalid && email.touched"
                [class.disabled]="form.disabled"
                [formControl]="email"
                placeholder="example@mail.com"
            />
            <div *ngIf="email.invalid && email.touched" class="error-reason text-danger">
                <span *ngIf="email.hasError('required')">{{ "error_required" | translate }}</span>
                <span *ngIf="email.hasError('email')">{{ "error_invalid_email" | translate }}</span>
            </div>
        </div>

        <div class="field-block" [class]="classes.formField">
            <div class="login-options">
                <div class="radio-group">
                    <label>
                        <input
                            type="radio"
                            name="login-type-dynamic"
                            [value]="'password'"
                            [(ngModel)]="loginType"
                            (ngModelChange)="toggleFormPassword()"
                        />
                        {{ "password" | translate }}
                    </label>
                </div>
                <div class="radio-group">
                    <label>
                        <input
                            type="radio"
                            name="login-type-dynamic"
                            [value]="'quicklogin'"
                            [(ngModel)]="loginType"
                            (ngModelChange)="toggleFormPassword()"
                        />
                        {{ "quick_login" | translate }}
                    </label>
                </div>
            </div>

            <ng-container *ngIf="loginType === 'password'">
                <div class="password-wrapper">
                    <input
                        name="password"
                        class=""
                        [class]="classes.formControl"
                        [type]="showPassword ? 'text' : 'password'"
                        [class.has-error]="password.invalid && password.touched"
                        [class.disabled]="form.disabled"
                        [formControl]="password"
                    />
                    <div class="password-icon" (click)="toggleShowPassword()">
                        <svg
                            *ngIf="!showPassword"
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                        >
                            <path
                                d="M7.5 13.125C1.875 13.125 0 7.5 0 7.5C0 7.5 1.875 1.875 7.5 1.875C13.125 1.875 15 7.5 15 7.5C15 7.5 13.125 13.125 7.5 13.125ZM7.5 3.75C6.75832 3.75 6.0333 3.96994 5.41662 4.382C4.79993 4.79406 4.31929 5.37969 4.03546 6.06491C3.75163 6.75013 3.67736 7.50413 3.82205 8.23156C3.96675 8.95899 4.3239 9.62721 4.84835 10.1517C5.37279 10.6761 6.04098 11.0333 6.76841 11.178C7.49584 11.3227 8.24984 11.2484 8.93506 10.9645C9.62028 10.6807 10.206 10.2001 10.618 9.5834C11.0301 8.96671 11.25 8.24168 11.25 7.5C11.25 6.50544 10.8549 5.55161 10.1517 4.84835C9.44839 4.14509 8.49456 3.75 7.5 3.75ZM7.5 9.375C7.00272 9.375 6.5258 9.17743 6.17417 8.8258C5.82254 8.47417 5.625 7.99728 5.625 7.5C5.625 7.46812 5.63249 7.43909 5.63437 7.40627C5.78104 7.46344 5.9364 7.49513 6.09375 7.5C6.46671 7.5 6.82439 7.35185 7.08811 7.08813C7.35184 6.8244 7.5 6.46671 7.5 6.09375C7.49514 5.9364 7.46343 5.78105 7.40624 5.63438C7.43812 5.63438 7.46719 5.625 7.5 5.625C7.99728 5.625 8.4742 5.82257 8.82583 6.1742C9.17746 6.52583 9.375 7.00272 9.375 7.5C9.375 7.99728 9.17746 8.47417 8.82583 8.8258C8.4742 9.17743 7.99728 9.375 7.5 9.375Z"
                                fill="currentColor"
                            />
                        </svg>

                        <svg *ngIf="showPassword" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                            <path
                                d="M0 7.49997C0 7.49997 1.875 1.87497 7.5 1.87497C8.49857 1.87497 9.37895 2.05224 10.1517 2.34384C10.7961 2.58702 11.3656 2.90972 11.8663 3.27543C12.4489 3.70102 12.9384 4.18485 13.3443 4.6694C14.5272 6.08165 15 7.49997 15 7.49997C15 7.49997 13.125 13.125 7.5 13.125C6.60036 13.125 5.79665 12.9811 5.08118 12.7393L6.66474 11.1558C6.69916 11.1636 6.73371 11.171 6.76841 11.1779C7.49584 11.3226 8.24984 11.2483 8.93506 10.9645C9.62028 10.6807 10.206 10.2001 10.618 9.58337C11.0301 8.96668 11.25 8.24165 11.25 7.49997C11.25 7.27197 11.2292 7.04611 11.1888 6.82492L13.3443 4.6694C12.9384 4.18485 12.4489 3.70102 11.8663 3.27543C11.3656 2.90972 10.7961 2.58702 10.1517 2.34384L8.65337 3.93174C8.28413 3.81238 7.89528 3.74997 7.5 3.74997C6.75832 3.74997 6.0333 3.96991 5.41662 4.38197C4.79993 4.79402 4.31929 5.37966 4.03546 6.06488C3.75163 6.7501 3.67736 7.5041 3.82205 8.23153L1.61608 10.2829C0.4622 8.88657 0 7.49997 0 7.49997Z"
                                fill="currentColor"
                            />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M14.7071 0.292893C15.0976 0.683417 15.0976 1.31658 14.7071 1.70711L1.70711 14.7071C1.31658 15.0976 0.683417 15.0976 0.292893 14.7071C-0.0976311 14.3166 -0.0976311 13.6834 0.292893 13.2929L13.2929 0.292893C13.6834 -0.0976311 14.3166 -0.0976311 14.7071 0.292893Z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                </div>

                <div *ngIf="password.invalid && password.touched" class="error-reason text-danger">
                    <span *ngIf="password.hasError('required')">
                        {{ "error_required" | translate }}
                    </span>
                    <span *ngIf="password.hasError('minlength')">
                        {{ "error_password_length" | translate }}
                    </span>
                </div>
            </ng-container>

            <ng-container *ngIf="loginType === 'quicklogin'">
                <ng-container *ngIf="(requestStatus | async) !== 'success'">
                    <!-- idle or error -->
                    <div class="sp-ui-text">
                        <p>{{ "quick_login_will_send" | translate }}</p>
                    </div>
                    <div class="edu-modal-footer" [class]="classes?.buttonWrapper">
                        <!-- this is placeholder p tag -->
                        <p></p>
                        <a
                            type="button"
                            class="sp-ui-button sp-ui-button-md sp-ui-button-primary-light"
                            [class.disabled]="form.disabled"
                            (click)="onSignIn()"
                        >
                            {{ "quick_login_send" | translate }}
                        </a>
                    </div>
                </ng-container>
                <ng-container *ngIf="(requestStatus | async) === 'success'">
                    <p>
                        {{ "quick_login_email_sent_1" | translate }}
                        <span style="display: block"> {{ "quick_login_email_sent_2" | translate }} </span>
                    </p>
                </ng-container>
            </ng-container>
        </div>
    </div>

    <div class="edu-modal-footer" [class]="classes?.buttonWrapper" *ngIf="loginType === 'password'">
        <button
            type="button"
            class="sp-ui-button sp-ui-button-md sp-ui-button-secondary-light sp-ui-button-secondary-edu"
            (click)="onPasswordRecovery()"
        >
            {{ "forgot_password_question" | translate }}
        </button>
        <a
            type="button"
            class="sp-ui-button sp-ui-button-md sp-ui-button-primary-light"
            [class.disabled]="form.disabled"
            (click)="onSignIn()"
        >
            {{ "sign_in" | translate }}
        </a>
    </div>
</div>
