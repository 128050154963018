import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { SpFileManagerContextService } from '@common/components/sp-file-manager/src/sp-file-manager-context.service';
import { SpFilesSelectComponent } from '@common/components/sp-file-manager/src/sp-files-select/sp-files-select.component';
import { InputBoolean } from '@common/helpers/convert';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'sp-defautl-upload-button',
    template: `
        <ng-template #uploadButton>
            <button
                class="btn btn-action btn-block"
                [class.disabled]="disabled"
                (click)="!stockImagesDropdown && ctx.openFileManagerDrawer()"
            >
                {{ 'control_file_manager_choose_from_gallery' | translate }}
                <span *ngIf="stockImagesDropdown" class="caret"></span>
            </button>
        </ng-template>

        <ng-container *ngIf="stockImagesDropdown; else uploadButton">
            <sp-files-select menuFullWidth (myPhotosClick)="ctx.openFileManagerDrawer()" (unsplashClick)="unsplashRequested.emit()">
                <ng-container [ngTemplateOutlet]="uploadButton"></ng-container>
            </sp-files-select>
        </ng-container>
    `,
    styles: [
        `
            .btn-block .caret {
                float: none;
                vertical-align: top;
            }
        `,
    ],
    standalone: true,
    imports: [CommonModule, TranslateModule, SpFilesSelectComponent],
})
export class SpDefaultUploadButtonComponent {
    @Input() @InputBoolean() disabled: boolean = false;
    @Input() @InputBoolean() stockImagesDropdown: boolean = false;

    @Output() unsplashRequested = new EventEmitter<void>();

    public readonly ctx = inject(SpFileManagerContextService, {
        skipSelf: true,
    });
}
