import type { FormControl } from '@angular/forms';
import { FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import type { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { EduCourse } from '@common/types/edu-course.type';
import type { LoginResponseData } from '@web-builder/core/services/REST/edu.http.service';
import { EduHttpService } from '@web-builder/core/services/REST/edu.http.service';

import { Component, HostListener, Input, type OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, EMPTY, forkJoin, of, Subject } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';

import { environment } from '@web-builder-env/environment';
import { Classes } from 'jss';
import { UtmTagsService } from '@web-builder/core/services/utm-tags.service';
import { type SignInFormData, type SignInFormGroup, type SignInModalResult } from '@web-builder/widgets/login-edu/type/login-edu.type';

@Component({
    selector: 'web-builder-sign-in-modal',
    styleUrls: ['./sign-in-modal.component.less'],
    templateUrl: './sign-in-modal.component.html',
    imports: [CommonModule, ReactiveFormsModule, FormsModule, TranslateModule],
    standalone: true,
})
export class SignInModalComponent implements OnInit {
    @Input() public eduCourse!: EduCourse;
    @Input() public domainId!: number;
    @Input() public classes!: Classes;

    public onClose: Subject<SignInModalResult>;
    public form: SignInFormGroup | any;
    public showPassword: boolean = false;

    public loginType: 'password' | 'quicklogin' = 'password';
    public requestStatus: BehaviorSubject<'idle' | 'success' | 'error'> = new BehaviorSubject('idle');
    public passwordFormControl: FormControl = this.fb.control(null);

    constructor(
        private readonly fb: NonNullableFormBuilder,
        private readonly cookieService: CookieService,
        private readonly bsModalRef: BsModalRef,
        private readonly eduHttpService: EduHttpService,
        private readonly utmTagsService: UtmTagsService,
    ) {
        this.initForm();
    }

    public ngOnInit(): void {
        this.onClose = new Subject();
    }

    private initForm(): void {
        this.passwordFormControl = this.fb.control(null, [Validators.required, Validators.minLength(8)]);
        this.form = this.fb.group({
            email: this.fb.control(null, [Validators.email, Validators.required]),
        });

        this.toggleFormPassword();
    }

    public toggleFormPassword(): void {
        if (this.loginType === 'password') {
            this.form.setControl('password', this.passwordFormControl);
        } else if (this.loginType === 'quicklogin') {
            this.form.removeControl('password');
        }

        (this.form as FormControl).updateValueAndValidity();
    }

    public onSignIn(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.form.disable();
        of(this.loginType)
            .pipe(
                take(1),
                mergeMap((loginType) => {
                    const data: any = {
                        email: this.email.value,
                        domainId: this.domainId,
                        utm: this.utmTagsService.getUtmTags(),
                    };

                    if (loginType === 'password') {
                        data.password = this.password.value;
                        return forkJoin({ loginType: of(loginType), response: this.eduHttpService.login(data) });
                    }

                    return forkJoin({ loginType: of(loginType), response: this.eduHttpService.quickLoginEmail(data) });
                }),
                mergeMap(({ loginType, response }) => {
                    this.requestStatus.next('success');

                    const accessToken = response.headers.get('access_token');
                    const refreshToken = response.headers.get('refresh_token');

                    this.cookieService.set('oauth_access_token', accessToken, { path: '/' });
                    this.cookieService.set('oauth_refresh_token', refreshToken, { path: '/' });

                    if (loginType === 'password') {
                        if (!this.eduCourse?.id) {
                            window.location.href = environment.eduStudentUrl;
                            return;
                        }

                        this.onClose.next({
                            message: 'signInSuccess',
                            data: response.body,
                        });
                    }

                    return EMPTY;
                }),
                catchError((error: HttpErrorResponse) => {
                    this.requestStatus.next('error');

                    this.form.enable();

                    if (error.status === 401) {
                        this.form.setErrors({ invalidCredentials: true });
                    }

                    if (error.status === 403 || error.status === 404) {
                        const message = error?.error?.error?.message;

                        if (message === 'Student does not exist.') {
                            this.form.setErrors({ userNotFound: true });
                            return EMPTY;
                        }
                    }

                    if (error.status === 429) {
                        this.form.setErrors({ tooManyRequests: true });
                        return EMPTY;
                    }

                    return EMPTY;
                }),
            )
            .subscribe();
    }

    @HostListener('document:keydown.enter')
    public onEnterPress(): void {
        this.onSignIn();
    }

    public onPasswordRecovery(): void {
        this.onClose.next({ message: 'passwordRecovery' });
        this.bsModalRef.hide();
    }

    public onCloseModal(): void {
        this.bsModalRef.hide();
    }

    public toggleShowPassword(): void {
        this.showPassword = !this.showPassword;
    }

    public get email(): FormControl<SignInFormData['email']> {
        return this.form.get('email') as FormControl<SignInFormData['email']>;
    }

    public get password(): FormControl<SignInFormData['password']> {
        return this.form.get('password') as FormControl<SignInFormData['password']>;
    }

    public get isFormInvalid(): boolean {
        return this.form.hasError('invalidCredentials') || this.form.hasError('userNotFound') || this.form.hasError('tooManyRequests');
    }
}
