import { Injectable, inject, Self, Host, SkipSelf } from '@angular/core';
import { Subject, take } from 'rxjs';
import {
    DirectoryItemCategory,
    FmDrawerOptions,
    FmFeatureApiService,
    FmSourceType,
    FmViewType,
    SelectedFileInterface,
} from '@sendpulse/file-manager';
import { TranslateService } from '@ngx-translate/core';

export type FMContextData = {
    displayOnly: DirectoryItemCategory[];
    multiSelect: boolean;
    fmSourceType: FmSourceType;
};

@Injectable()
export class SpFileManagerContextService {
    private readonly translateService: TranslateService = inject(TranslateService);
    private readonly fmFeatureApiService: FmFeatureApiService = inject(FmFeatureApiService);

    private selectedFileData$: Subject<{
        file: SelectedFileInterface | SelectedFileInterface[];
        replace: boolean;
        replaceIndex: number;
    }> = new Subject();

    public fmData: FMContextData;

    public setFmData(data: FMContextData) {
        this.fmData = data;
    }

    public getSelectedFileData() {
        return this.selectedFileData$.asObservable();
    }

    public openDrawerService({
        displayOnly,
        multiSelect,
        displayedExtensions,
        fmSourceType = FmSourceType.EDUCATION,
    }: {
        displayOnly?: DirectoryItemCategory;
        multiSelect?: boolean;
        fmSourceType?: FmSourceType;
        displayedExtensions?: string[];
    }) {
        let unsupportableVideo = [];

        if (displayOnly === DirectoryItemCategory.Video) {
            unsupportableVideo = [
                'video/mpeg', // .mpeg
                'video/x-msvideo', // .avi
                'video/x-m4v', // .m4v
                'video/quicktime', // .mov
            ];
        }

        const options: FmDrawerOptions = {
            title: this.specificTitleWithTranslate(displayOnly),
            sourceType: fmSourceType,
            initialViewType: FmViewType.LIST,
            displayOnly: [displayOnly],
            unsupportableExtentions: ['audio/x-ms-wma', ...unsupportableVideo],
            multiSelect,
            displayedExtensions,
        };
        return this.fmFeatureApiService.openDrawer(options, { top: 75 });
    }

    public openFileManagerDrawer(
        { replace, replaceIndex }: { replace: boolean; replaceIndex: number | null } = { replace: false, replaceIndex: null },
    ): void {
        this.openDrawerService({
            displayOnly: this.fmData.displayOnly?.[0],
            multiSelect: replace ? false : this.fmData.multiSelect,
        })
            .pipe(take(1))
            .subscribe((file) => {
                this.selectedFileData$.next({ file, replace, replaceIndex });
            });
    }

    private specificTitleWithTranslate(displayOnly?): string {
        if (!displayOnly) {
            return this.translateService.instant('select_file');
        }
        switch (displayOnly) {
            case DirectoryItemCategory.Audio:
                return this.translateService.instant('select_audio');
            case DirectoryItemCategory.Image:
                return this.translateService.instant('select_image');
            case DirectoryItemCategory.Video:
                return this.translateService.instant('select_video');
            default:
                return this.translateService.instant('select_file');
        }
    }
}
