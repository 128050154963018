import { Component, ChangeDetectionStrategy, inject, type AfterContentInit } from '@angular/core';
import { type SafeHtml } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

import { CartFacade } from '@web-builder/store/cart/cart.facade';

import { SpDirectivesModule } from '@common/directives/directives.module';
import { SpPipesModule } from '@common/pipes/pipes.module';
import { CART_ICON_TEMPLATES } from '@common/constants/cart-icon.const';
import { ESize } from '@common/enums';
import { type ShopCartIconSettingsFromServerType } from '@common/types/shop-cart-icon-settings.type';
import { type Observable, of } from 'rxjs';

import { TranslateModule } from '@ngx-translate/core';
import { AbstractWidgetComponent } from '@web-builder/widgets/abstract-widget.component';
import type { ShopCartIconWidgetData } from '@web/widgets/shop-cart-icon';
import { EIconPosition } from '@common/enums/icon-position.enum';
import { ShopCartHttpService } from '@web-builder/core/services/REST/shop-cart.http.service';
import { catchError } from 'rxjs/operators';

@Component({
    selector: 'web-builder-shop-cart-icon-view',
    templateUrl: './shop-cart-icon.component.html',
    styleUrls: ['./shop-cart-icon.component.less'],
    imports: [CommonModule, SpDirectivesModule, SpPipesModule, TranslateModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class ShopCartIconComponent extends AbstractWidgetComponent<ShopCartIconWidgetData> implements AfterContentInit {
    protected getStyles = null;

    public readonly cartFacade: CartFacade = inject(CartFacade);
    public readonly shopCartHttpService: ShopCartHttpService = inject(ShopCartHttpService);
    public readonly cartNumberOfItems$: Observable<number> = this.cartFacade.selectCartNumberOfItems$;
    public readonly cartIcon: ShopCartIconSettingsFromServerType = this.transferStateService.get('site')?.settings?.shop?.cart_icon;
    public readonly shopEnabled: ShopCartIconSettingsFromServerType = this.transferStateService.get('site')?.settings?.shop?.enabled;
    public readonly iconPositionType: EIconPosition = this.cartIcon?.icon_position_type ?? EIconPosition.SITE;

    public ngAfterContentInit(): void {
        if (this.platformService.isPlatformBrowser()) {
            const cartId = window.localStorage.getItem('cartId');
            const userId = this.transferStateService.get('userId');

            if (cartId) {
                this.shopCartHttpService
                    .getCartStatus(cartId, userId)
                    .pipe(
                        catchError(() => {
                            this.cartFacade.getCartLocalStore();
                            return of(null);
                        }),
                    )
                    .subscribe((data) => {
                        if (data) window.localStorage.clear();
                        this.cartFacade.getCartLocalStore();
                    });
            } else {
                this.cartFacade.getCartLocalStore();
            }
        }
    }

    public getIcon(): SafeHtml {
        if (this.shopEnabled === null) return;

        return this.sanitizer.bypassSecurityTrustHtml(
            CART_ICON_TEMPLATES(this.cartIcon.icon_type, this.previewIconImgSize(this.cartIcon.icon_size)),
        );
    }

    public getIconStyles() {
        if (this.shopEnabled === null) return;

        let iconStyle = {
            borderRadius: `${this.cartIcon.icon_rounding}px`,
            background: this.iconPositionType === 'site' ? this.cartIcon.icon_bg_color : 'transparent',
            color: this.cartIcon.icon_color,
            height: this.previewIconSize(this.cartIcon.icon_size),
            width: this.previewIconSize(this.cartIcon.icon_size),
        };

        if (this.iconPositionType === 'header') {
            iconStyle = { ...iconStyle, ...{ boxShadow: 'none' } };
        }

        return iconStyle;
    }

    public getCounterStyles() {
        if (this.shopEnabled === null) return;

        return {
            background: this.cartIcon.icon_counter_bg_color,
            color: this.cartIcon.icon_counter_color,
        };
    }

    public previewIconSize(size: string): string {
        switch (size) {
            case ESize.S:
                return '40px';
            case ESize.M:
                return '48px';
            case ESize.L:
                return '56px';
            default:
                return '48px';
        }
    }

    public previewIconSizeStyle() {
        return {
            height: `${this.previewIconImgSize(this.cartIcon.icon_size)}px`,
            width: `${this.previewIconImgSize(this.cartIcon.icon_size)}px`,
        };
    }

    public previewIconImgSize(size: string): number {
        switch (size) {
            case ESize.S:
                return 19;
            case ESize.M:
                return 24;
            case ESize.L:
                return 29;
            default:
                return 24;
        }
    }

    public cartIconClick(): void {
        if (this.isPreview) return;

        const params = new URL(window.location.href).search;
        window.open(`${window.location.origin}/cart${params}`, '_self');
    }
}
