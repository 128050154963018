import type {
    FormElement,
    FormCheckbox,
    FormInput,
    FormInputDate,
    FormRadio,
    FormSelectWebsites,
    FormTextarea,
    FormNumber,
} from '@common/types/form-element.type';
import { EFormControlType, EFormInputType } from '@common/enums';

export const isInput = (control: FormElement): control is FormInput => {
    const controlType = (control as FormInput).type;
    return Object.values(EFormInputType).includes(controlType);
};

export const isInputDate = (control: FormElement): control is FormInputDate => {
    const controlType = (control as FormInputDate).type;
    return controlType === EFormInputType.date;
};

export const isTextarea = (control: FormElement): control is FormTextarea => {
    const controlType = (control as FormTextarea).type;
    return controlType === EFormControlType.textarea;
};

export const isInputNumber = (control: FormElement): control is FormNumber => {
    const controlType = (control as FormNumber).type;
    return controlType === EFormInputType.number;
};

export const isCheckbox = (control: FormElement): control is FormCheckbox => {
    const controlType = (control as FormCheckbox).type;
    return controlType === EFormControlType.checkbox;
};

export const isRadio = (control: FormElement): control is FormRadio => {
    const controlType = (control as FormRadio).type;
    return controlType === EFormControlType.radio;
};

export const isSelect = (control: FormElement): control is FormSelectWebsites => {
    const controlType = (control as FormSelectWebsites).type;
    return controlType === EFormControlType.select || controlType === 'multiselect';
};

export const isSystemElement = (element: FormElement) => {
    const { type } = element;
    return (
        type === EFormInputType.email || type === EFormInputType.phone || type === EFormInputType.password || type === EFormInputType.file
    );
};
