import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { InputBoolean } from '@common/helpers/convert';
import { SpOverlayDropDown, SpOverlayDropdownTriggerForDirective } from '@common/ui/sp-overlay-dropdown';
import { TranslateModule } from '@ngx-translate/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@Component({
    selector: 'sp-files-select',
    exportAs: 'spfilesselect',
    template: `
        <ng-template #contentTemplate><ng-content></ng-content></ng-template>

        <div class="dropdown-wrapper">
            <div
                [class.inline-block]="!menuFullWidth"
                *ngIf="!toggless"
                [dropdownTriggerFor]="imagesDropdown"
                [menuFullWidth]="menuFullWidth"
                #trigger="dropdownTriggerFor"
            >
                <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
            </div>

            <div *ngIf="toggless" [dropdownTriggerFor]="imagesDropdown" toggless #dropdown="dropdownTriggerFor">
                <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
            </div>

            <sp-overlay-dropdown #imagesDropdown>
                <a (click)="myPhotosClick.emit()" class="dropdown-item">{{ 'file_manager_label' | translate }}</a>
                <a (click)="unsplashClick.emit()" class="dropdown-item">{{ 'stock_images_label' | translate }}</a>
            </sp-overlay-dropdown>
        </div>
    `,
    styles: [
        `
            .dropdown-wrapper {
                display: block;
                position: relative;
            }

            .dropdown-item {
                display: block;
                color: #000;
                padding: 8px 12px;
                border-radius: 6px;

                &:hover {
                    color: #262626;
                    text-decoration: none;
                    background-color: #eff3f3;
                }
            }
        `,
    ],
    standalone: true,
    imports: [CommonModule, BsDropdownModule, SpOverlayDropDown, SpOverlayDropdownTriggerForDirective, TranslateModule],
})
export class SpFilesSelectComponent {
    @Input() @InputBoolean() toggless: boolean = false;
    @Input() @InputBoolean() menuFullWidth: boolean = false;

    @Output() myPhotosClick = new EventEmitter<void>();
    @Output() unsplashClick = new EventEmitter<void>();

    @ViewChild('dropdown', { static: false }) public dp!: SpOverlayDropdownTriggerForDirective;
}
