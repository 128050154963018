<div *ngIf="file" class="control-handle">
    <div class="control-handle-container">
        <div class="file-box">
            <div class="control-handle-content">
                <div class="wrap-text">
                    <span class="file-name text-nowrap">{{ file.name }}</span>
                    <span class="file-size text-nowrap">{{ file.size | spByteFormat }}</span>
                </div>
            </div>
            <div>
                <button class="btn btn-link" (click)="removeFileEvent.emit()">
                    <span class="sp-icon icon-bin"> </span>
                </button>
                <button class="btn btn-link" (click)="replaceFileEvent.emit()">
                    <span class="sp-icon icon-marquee-upload"> </span>
                </button>
            </div>
        </div>
    </div>
</div>
