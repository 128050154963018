import type { JssStyle } from 'jss';
import type { WebWidgetEntity } from '@web/types';
import type { AccordionWidgetData } from '@web/widgets/accordion/type/accordion-widget.type';

import { EFaqIconPosition, EFaqIconStyle } from '@common/enums/faq-icon.enum';
import { websiteColorCss } from '@common/libs/themes/src/variants/website/utils/variables';

export function getStyles({ data, styles }: WebWidgetEntity<AccordionWidgetData>, { isConstructor }: any): JssStyle {
    const mobileConstructorSelector = '&.mobileWidgetViewWrap';

    const accordionList = {
        ...styles['accordionList'],
        position: 'relative',
        transform: `rotate(${data?.position?.rotate ?? 0}deg)`,
        [mobileConstructorSelector]: {},
    };

    if (accordionList && !isConstructor) {
        if (data?.hideOnMobile && !data?.hideOnDesktop) {
            accordionList['@media (max-width: 992px)'] = { display: 'none' };
        }

        if (!data?.hideOnMobile && data?.hideOnDesktop) {
            accordionList['@media (min-width: 993px)'] = { display: 'none' };
        }

        if (data?.hideOnMobile && data?.hideOnDesktop) {
            accordionList.display = 'none';
        }
    }

    if (accordionList && isConstructor) {
        accordionList[mobileConstructorSelector].transform = `rotate(${data?.position?.mobileRotate ?? 0}deg)`;
    }

    const accordionStyles: JssStyle = {
        ...styles,
        accordionList,
        accordionItemDivider: {
            borderBottom: data.isIndent ? '1px solid' : 'none',
            borderBottomColor: data.themeIndentColor ? websiteColorCss(data.themeIndentColor) : data.indentColor,
        },
        accordionItemTitle: {
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            padding: '12px 0 8px',
            flexDirection: data.horizontalAlign === EFaqIconPosition.R ? 'row' : 'row-reverse',
        },
        icon: {
            '&:after': {
                content: '""',
                width: data.iconType === EFaqIconStyle.PLUS ? '16px' : '12px',
                height: data.iconType === EFaqIconStyle.PLUS ? '2px' : '3px',
                borderRadius: data.iconType === EFaqIconStyle.PLUS ? '1px' : '0',
                backgroundColor: data.themeIconColor ? websiteColorCss(data.themeIconColor) : data.iconColor,
                position: 'absolute',
                top: 'calc(50% - 1px)',
                left: data.iconType === EFaqIconStyle.PLUS ? 'calc(50% - 8px)' : '2px',
                transform: data.iconType === EFaqIconStyle.PLUS ? '' : 'rotate(45deg)',
            },
            '&:before': {
                content: '""',
                width: data.iconType === EFaqIconStyle.PLUS ? '2px' : '12px',
                height: data.iconType === EFaqIconStyle.PLUS ? '16px' : '3px',
                borderRadius: data.iconType === EFaqIconStyle.PLUS ? '1px' : '0',
                backgroundColor: data.themeIconColor ? websiteColorCss(data.themeIconColor) : data.iconColor,
                position: 'absolute',
                top: data.iconType === EFaqIconStyle.PLUS ? 'calc(50% - 8px)' : 'calc(50% - 1px)',
                left: data.iconType === EFaqIconStyle.PLUS ? 'calc(50% - 1px)' : '',
                right: data.iconType === EFaqIconStyle.PLUS ? '' : '2px',
                transform: data.iconType === EFaqIconStyle.PLUS ? '' : 'rotate(135deg)',
            },
        },
        activeIcon: {
            '&:before': {
                transform: data.iconType === EFaqIconStyle.PLUS ? 'rotate(45deg)' : 'rotate(45deg)',
            },
            '&:after': {
                transform: data.iconType === EFaqIconStyle.PLUS ? 'rotate(45deg)' : 'rotate(135deg)',
            },
        },
    };

    if (data.horizontalAlign === EFaqIconPosition.R) {
        return {
            ...accordionStyles,
            accordionIconMargin: {
                marginLeft: '16px',
                marginRight: 0,
                marginBottom: '10px',
            },
        };
    }

    if (data.horizontalAlign === EFaqIconPosition.L) {
        return {
            ...accordionStyles,
            accordionIconMargin: {
                marginRight: '16px',
                marginLeft: 0,
                marginBottom: '10px',
            },
        };
    }

    return {
        ...accordionStyles,
    };
}
