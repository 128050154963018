import type { Observable } from 'rxjs';
import type { HttpDataResponse } from '@common/types/http-response.type';
import type { PaymentResponseType } from '@common/types/payment-response.type';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TransferStateService } from '@web-builder/core/services/transfer-state.service';
import { getAnalyticsHeaders } from '@sites/analytics/hooks';

@Injectable({
    providedIn: 'root',
})
export class PaymentHttpService {
    constructor(protected readonly http: HttpClient, private transferStateService: TransferStateService) {}

    public submitPayment(payload: any): Observable<HttpDataResponse<PaymentResponseType>> {
        return this.http.post<HttpDataResponse<PaymentResponseType>>(
            `${this.transferStateService.getResourceUrl()}/api/landings-service/public/payment/user-url`,
            payload,
            {
                headers: getAnalyticsHeaders(),
            },
        );
    }

    public submitEduPayment(payload: any): Observable<PaymentResponseType> {
        return this.http.post<PaymentResponseType>(
            `${this.transferStateService.getResourceUrl()}/api/landings-service/public/education/payment`,
            payload,
            {
                headers: getAnalyticsHeaders(),
            },
        );
    }
}
