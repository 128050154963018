import type { JssStyle } from 'jss';
import type { FormWidgetData } from '@web/widgets/form';

import { EThemeFontTags, EWebsiteThemeColorNames, EWebsiteThemeFontProperties, websiteColorCss, websiteFontCss } from '@libs/themes';
import { EButtonStyleType, EButtonWidthType, EHorizontalPosition, ESize, FormStyleEnum, InputBorderEnum, Widgets } from '@common/enums';

import { commonLabelStyles, commonWebsitesFormInputStyles, commonWebsitesFormTextareaStyles } from '@common/constants/input-sizes.const';
import { commonWebsitesFormButtonTextStyles } from '@common/constants/button-sizes.const';

import { getBaseButtonData } from '@web/widgets/common/base/base-button/base-button.styles';
import { defaultInputSettingsMap } from '@web/widgets/common/base/base-form/defaults';

const horizontalPositionMap = {
    [EHorizontalPosition.L]: 'flex-start',
    [EHorizontalPosition.M]: 'center',
    [EHorizontalPosition.R]: 'flex-end',
};

const inputSizeMap = {
    [ESize.S]: '11px',
    [ESize.M]: '13px',
    [ESize.L]: '19px',
};

const passwordIconMap = {
    [ESize.S]: 14,
    [ESize.M]: 16,
    [ESize.L]: 20,
};

export function getBaseFormStyles(formData: FormWidgetData, widget: Widgets = Widgets.FORM, isConstructor?: boolean): JssStyle {
    const inputSettings = formData.inputSettings ?? defaultInputSettingsMap[widget];
    const formStyleType = inputSettings.formStyleType ?? FormStyleEnum.VERTICAL;
    const formButtonSettings = formData.formButtonSettings;

    const formControl = {
        ...commonWebsitesFormInputStyles[inputSettings?.inputSize],
        '&:hover': {
            borderColor: '#9a9a9a',
        },
        '&:focus': {
            borderColor: '#82f6ff',
            boxShadow: '0 0 0 2px rgb(151 247 255 / 30%);',
            outline: '0',
        },
        borderWidth: '1px',
        boxSizing: 'border-box',
        borderStyle: 'solid',
        whiteSpace: 'nowrap',
        borderTopWidth: `${inputSettings.inputBorderType === InputBorderEnum.BOTTOM_BORDER ? '0px' : '1px'}`,
        borderRightWidth: `${inputSettings.inputBorderType === InputBorderEnum.BOTTOM_BORDER ? '0px' : '1px'}`,
        borderLeftWidth: `${inputSettings.inputBorderType === InputBorderEnum.BOTTOM_BORDER ? '0px' : '1px'}`,
        borderRadius: `${inputSettings.borderRadius}px`,
        background: websiteColorCss(inputSettings.backgroundColor as string),
        borderColor: websiteColorCss(inputSettings.borderColor as string),
        color: websiteColorCss(inputSettings.inputTextColor as string),
        width: '100%',
        '& option': {
            background: websiteColorCss(inputSettings.backgroundColor as string),
        },
        '&::placeholder': {
            color: websiteColorCss(inputSettings.inputTextColor as string),
            opacity: 0.5,
        },
        '&.ng-select': {
            '& .ng-select-container': {
                minHeight: 'auto',
                height: 'auto',
                border: 'none',
                background: 'transparent',
                '& .ng-value-container': {
                        margin: '-1px',
                        padding: '0',
                    '& .ng-placeholder': {
                        top: 0,
                        color: websiteColorCss(inputSettings.inputTextColor as string),
                        opacity: 0.5,
                    },
                    '& .ng-value': {
                        margin: '2px !important',
                        color: websiteColorCss(inputSettings.inputTextColor as string),
                    },
                    '& .ng-input': {
                        display: 'none',
                    },
                },
                '&:hover': {
                    boxShadow: 'none !important',
                },
                '& .ng-clear-wrapper': {
                    color: websiteColorCss(inputSettings.inputTextColor as string),
                    '&:hover': {
                        opacity: 0.6,
                    },
                },
                '& .ng-arrow-wrapper': {
                    marginRight: '-10px',
                    '& .ng-arrow': {
                        borderTopColor: websiteColorCss(inputSettings.inputTextColor as string),
                    },
                    '&:hover': {
                        opacity: 0.6,
                    },
                },
            },
            '&.ng-select-opened': {
                '& .ng-select-container': {
                    '& .ng-arrow-wrapper': {
                    '& .ng-arrow': {
                        borderBottomColor: websiteColorCss(inputSettings.inputTextColor as string),
                    }
                },
                },
            }
        },
    };

    const formControlTextarea = {
        ...commonWebsitesFormTextareaStyles[inputSettings?.inputSize],
        '&:hover': {
            borderColor: '#9a9a9a',
        },
        '&:focus': {
            borderColor: '#82f6ff',
            boxShadow: '0 0 0 2px rgb(151 247 255 / 30%);',
            outline: '0',
        },
        borderWidth: '1px',
        boxSizing: 'border-box',
        borderStyle: 'solid',
        borderTopWidth: `${inputSettings.inputBorderType === InputBorderEnum.BOTTOM_BORDER ? '0px' : '1px'}`,
        borderRightWidth: `${inputSettings.inputBorderType === InputBorderEnum.BOTTOM_BORDER ? '0px' : '1px'}`,
        borderLeftWidth: `${inputSettings.inputBorderType === InputBorderEnum.BOTTOM_BORDER ? '0px' : '1px'}`,
        borderRadius: `${inputSettings.borderRadius}px`,
        background: websiteColorCss(inputSettings.backgroundColor as string),
        borderColor: websiteColorCss(inputSettings.borderColor as string),
        color: websiteColorCss(inputSettings.inputTextColor as string),
        width: '100%',
        '& option': {
            background: websiteColorCss(inputSettings.backgroundColor as string),
        },
    };

    const formDropdownButton = {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '39px',
        backgroundColor: '#fff',
        border: '1px solid #bcc8c8',
        boxSizing: 'border-box',
        borderRadius: '4px',

        '& div': {
            display: 'flex',
            alignItems: 'center',
        },

        '& span': {
            fontWeight: 'normal',
        },

        '& img': {
            width: '20px',
            height: '20px',
            marginRight: '10px',
        },

        '&:hover': {
            borderColor: '#b8cece',
            backgroundColor: '#fff',
        },

        '& .open': {
            '& .dropdown-toggle.custom-btn': {
                backgroundColor: '#fff',
                borderColor: '#00b175',
                outline: '0 !important',
                boxShadow: '0 0 0 2px rgba(49,193,120,0.2)',
            },

            '& .dropdown-menu': {
                margin: '0 5px',
                maxWidth: '300px',
            },
        },
    };

    const linkControl: JssStyle = {
        ...commonLabelStyles[inputSettings.inputSize],
        color: websiteColorCss(inputSettings.linkColor as string),
        marginTop: `${inputSettings.inputSize === ESize.S ? '6px' : inputSettings.inputSize === ESize.M ? '8px' : '10px'}`,
        display: 'block',
        '&:hover': {
            cursor: 'pointer',
            opacity: 0.8,
        },
    };

    const formPosition: JssStyle = {
        position: 'relative',
        transform: `rotate(${formData?.position?.rotate ?? 0}deg)`,
        '@media (max-width: 992px)': {
            transform: `rotate(${formData?.position?.mobileRotate ?? 0}deg)`,
        },
    };

    if (isConstructor) {
        formPosition['&.mobileWidgetViewWrap'] = {
            transform: `rotate(${formData?.position?.mobileRotate ?? 0}deg)`,
        };
    }

    let formFieldsContainer: JssStyle = {};
    let form: JssStyle = {};

    let commonForm: JssStyle = {
        fontFamily: websiteFontCss(EThemeFontTags.p, EWebsiteThemeFontProperties.fontFamily),
        '& label': {
            color: inputSettings.textColor,
        },
        '& .modal-divider': {
            display: 'block',
            width: '100%',
            height: '1px',
            margin: '25px 0',
            background: websiteColorCss(EWebsiteThemeColorNames.primary),
        },
        '& .min-amount-control': {
            '& .input-group': {
                width: '240px',
                position: 'relative',
                display: 'table',
                borderCollapse: 'separate',
                '& input': {
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                },
                '& .input-group-addon': {
                    padding: '8px 12px',
                    fontSize: '15px',
                    fontWeight: 400,
                    lineHeight: 1,
                    borderWidth: '1px',
                    boxSizing: 'border-box',
                    borderStyle: 'solid',
                    whiteSpace: 'nowrap',
                    borderTopWidth: `${inputSettings.inputBorderType === InputBorderEnum.BOTTOM_BORDER ? '0px' : '1px'}`,
                    borderRightWidth: `${inputSettings.inputBorderType === InputBorderEnum.BOTTOM_BORDER ? '0px' : '1px'}`,
                    borderLeftWidth: `${inputSettings.inputBorderType === InputBorderEnum.BOTTOM_BORDER ? '0px' : '1px'}`,
                    borderRadius: `${inputSettings.borderRadius}px`,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    background: websiteColorCss(inputSettings.backgroundColor as string),
                    borderColor: websiteColorCss(inputSettings.borderColor as string),
                    color: websiteColorCss(inputSettings.textColor as string),
                    display: 'table-cell',
                    width: '1%',
                    verticalAlign: 'middle',
                    textAlign: 'center',
                },
            },
        },
    };

    if (!isConstructor) {
        if (formData?.hideOnMobile && !formData?.hideOnDesktop) {
            commonForm['@media (max-width: 992px)'] = {
                display: 'none',
            } as any;
        }

        if (!formData?.hideOnMobile && formData?.hideOnDesktop) {
            commonForm['@media (min-width: 993px)'] = {
                display: 'none',
            };
        }

        if (formData?.hideOnMobile && formData?.hideOnDesktop) {
            commonForm.display = 'none';
        }
    }

    let verticalForm: JssStyle = {
        'max-width': '480px',
        margin: `0 ${
            inputSettings.horizontalPosition === EHorizontalPosition.L || inputSettings.horizontalPosition === EHorizontalPosition.M
                ? 'auto'
                : 0
        } 0 ${
            inputSettings.horizontalPosition === EHorizontalPosition.R || inputSettings.horizontalPosition === EHorizontalPosition.M
                ? 'auto'
                : 0
        }`,
    };

    let horizontalForm: JssStyle = {
        display: 'flex',
        justifyContent: horizontalPositionMap[inputSettings.horizontalPosition],
        margin: '-10px',
        '& form': {
            width: '100%',
        },
    };

    let formField: JssStyle = {};

    let commonFormField: JssStyle = {
        '&.has-error input': {
            borderColor: '#bb364d',
            backgroundColor: '#fff',
            '&[type=checkbox] + span': {
                color: '#bb364d',
            },
            '&:focus': {
                boxShadow: '0 0 0 2px rgb(212 105 106 / 30%)',
            },
        },
        '&.has-error select': {
            borderColor: '#bb364d',
            backgroundColor: '#fff',
        },
        '& label': {
            ...commonLabelStyles[inputSettings.inputSize],
            fontWeight: 'bold',
            display: 'block',
            marginBottom: `${inputSettings.inputSize === ESize.S ? '4px' : inputSettings.inputSize === ESize.M ? '5px' : '8px'}`,
            color: websiteColorCss(inputSettings.textColor as string),
        },
        '& .checkbox-text': {
            fontWeight: 400,
        },
        '& .radio-text': {
            fontWeight: 400,
        },
        '& .checkbox-input': {
            height: inputSizeMap[inputSettings.inputSize],
            width: inputSizeMap[inputSettings.inputSize],
            position: 'relative',
            top: '1px',
            margin: 0,
        },
        '& .radio-input': {
            height: inputSizeMap[inputSettings.inputSize],
            width: inputSizeMap[inputSettings.inputSize],
            position: 'relative',
            top: '1px',
            margin: 0,
        },
        '& p': {
            color: websiteColorCss(inputSettings.textColor as string),
            ...commonLabelStyles[inputSettings.inputSize],
        },

        '& input[type="email"] ': {
            background: websiteColorCss(inputSettings.backgroundColor as string),
        },

        '& input[type="text"]': {
            background: websiteColorCss(inputSettings.backgroundColor as string),
        },

        '& .ngx-dropzone-file': {
            borderRadius: `${inputSettings.borderRadius}px`,
        },

        '& ngx-dropzone': {
            borderRadius: `${inputSettings.borderRadius}px`,
        },
    };

    let verticalFormField: JssStyle = {
        marginBottom: `${inputSettings.inputSize === ESize.S ? '12px' : inputSettings.inputSize === ESize.M ? '15px' : '18px'}`,
    };

    let horizontalFormField: JssStyle = {
        margin: '10px',
        minWidth: '100px',
        '@media(min-width: 481px)': {
            maxWidth: '280px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'end',
            '& textarea': {
                height: `${
                    inputSettings.inputSize === ESize.S ? '35px' : inputSettings.inputSize === ESize.M ? '41px' : '57px'
                } !important`,
                resize: 'none',
            },
            '& .iti': {
                marginBottom: '0 !important',
                minWidth: '200px',
            },
        },
        '@media(max-width: 480px)': {
            width: '100%',
        },
    };

    let buttonWrapper: JssStyle = {};
    let formButtonWidth: JssStyle = {};
    let formButtonSize: JssStyle = {};

    if (formStyleType === FormStyleEnum.VERTICAL) {
        form = {
            ...commonForm,
            ...verticalForm,
        };

        formField = {
            ...commonFormField,
            ...verticalFormField,
        };

        buttonWrapper = {
            display: 'flex',
            marginTop: `${formButtonSettings.size === ESize.S ? '20px' : formButtonSettings.size === ESize.M ? '24px' : '28px'}`,
            '&.button-without-form': {
                marginTop: 0,
            },
        };
    }

    if (formStyleType === FormStyleEnum.HORIZONTAL) {
        let horizontalFormButtonWrapperWidth: JssStyle = {};

        if (formButtonSettings?.horizontalFormWidthType === EButtonWidthType.full) {
            horizontalFormButtonWrapperWidth = {
                width: '100%',
            };
        } else if (formButtonSettings?.horizontalFormWidthType === EButtonWidthType.fixed) {
            horizontalFormButtonWrapperWidth = {
                width: '100%',
                maxWidth: formButtonSettings?.widthValue + 'px',
            };
        } else {
            horizontalFormButtonWrapperWidth = {
                width: 'auto',
            };
        }

        form = {
            ...commonForm,
            ...horizontalForm,
        };

        formFieldsContainer = {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'flex-end',
            justifyContent: horizontalPositionMap[inputSettings.horizontalPosition],
            width: '100%',
        };

        formField = {
            ...commonFormField,
            ...horizontalFormField,
        };

        buttonWrapper = {
            margin: '10px',
            ...horizontalFormButtonWrapperWidth,
            '@media(max-width: 480px)': {
                width: '100%',
            },
        };

        formButtonSize = {
            padding: commonWebsitesFormButtonTextStyles[formButtonSettings.size || ESize.M].padding,
            fontSize: commonWebsitesFormButtonTextStyles[formButtonSettings.size || ESize.M].fontSize,
            lineHeight: commonWebsitesFormButtonTextStyles[formButtonSettings.size || ESize.M].lineHeight,
        };
    }

    const pointerEvents: JssStyle = {
        pointerEvents: 'none',
    };

    if (formStyleType === FormStyleEnum.HORIZONTAL) {
        if (formButtonSettings?.horizontalFormWidthType === EButtonWidthType.auto) {
            formButtonWidth = {
                width: 'auto',
            };
        } else if (formButtonSettings?.horizontalFormWidthType === EButtonWidthType.fixed) {
            formButtonWidth = {
                width: '100%',
                maxWidth: formButtonSettings?.widthValue + 'px',
            };
        } else {
            formButtonWidth = {
                width: '100%',
            };
        }
    } else {
        if (formButtonSettings?.widthType === EButtonWidthType.auto) {
            formButtonWidth = {
                width: 'auto',
            };
        } else if (formButtonSettings?.widthType === EButtonWidthType.fixed) {
            formButtonWidth = {
                width: '100%',
                maxWidth: formButtonSettings?.widthValue + 'px',
            };
        } else {
            formButtonWidth = {
                width: '100%',
            };
        }
    }

    let buttonStyles: JssStyle = {
        // textAlign: 'center',
        // display: 'block !important',
        boxSizing: 'border-box',
        margin: `0 ${
            inputSettings.horizontalPosition === EHorizontalPosition.L || inputSettings.horizontalPosition === EHorizontalPosition.M
                ? 'auto'
                : 0
        } 0 ${
            inputSettings.horizontalPosition === EHorizontalPosition.R || inputSettings.horizontalPosition === EHorizontalPosition.M
                ? 'auto'
                : 0
        } !important`,
        flexDirection: formButtonSettings.buttonIcon?.position === EHorizontalPosition.L ? 'row' : 'row-reverse',
        ...formButtonWidth,
        ...formButtonSize,
    };

    if (formButtonSettings.styleType === EButtonStyleType.custom) {
        buttonStyles = { ...buttonStyles, ...getBaseButtonData(formButtonSettings.buttonBase, formButtonSettings.buttonHover) };
    }

    let paymentFormDescription: JssStyle = {
        '& h2': {
            color: websiteColorCss(inputSettings.textColor as string),
        },
        '& p': {
            color: websiteColorCss(inputSettings.textColor as string),
        },
        color: websiteColorCss(inputSettings.textColor as string),
    };

    let passwordIconStyle: JssStyle = {
        height: passwordIconMap[inputSettings.inputSize],
        width: passwordIconMap[inputSettings.inputSize],
        top: 'calc(50% - ' + passwordIconMap[inputSettings.inputSize] / 2 + 'px)',
        '& svg': {
            height: passwordIconMap[inputSettings.inputSize],
            width: passwordIconMap[inputSettings.inputSize],
            color: inputSettings.inputTextColor,
        },
    };

    const modalStyles = {} as any;
    if (widget === Widgets.PAYMENT_FORM || widget === Widgets.LOGIN_EDU) {
        let modalBackgroundColor = inputSettings?.modalBackgroundColor ?? '#fbfcfc';
        let modalBorderRadius = inputSettings?.modalBorderRadius ?? 6;
        let paymentModalPadding = inputSettings?.modalPadding ?? 45;
        let eduModalPadding = inputSettings?.modalPadding ?? 30;

        modalStyles.modalBodyStyles = {
            '&.payment-form-modal-container': {
                background: websiteColorCss(modalBackgroundColor),
                borderRadius: `${modalBorderRadius}px`,
                padding: `${paymentModalPadding}px`,
                '& .close-modal-btn': {
                    color: websiteColorCss(inputSettings.textColor as string),
                },
            },
            '&.edu-modal': {
                background: websiteColorCss(modalBackgroundColor),
                borderRadius: `${modalBorderRadius}px`,
                padding: `${eduModalPadding}px`,
                '& h2': {
                    color: websiteColorCss(inputSettings.textColor as string),
                },
                '& .close-modal-btn': {
                    color: websiteColorCss(inputSettings.textColor as string),
                },
                '& .sp-ui-button-secondary-edu': {
                    color: websiteColorCss(inputSettings.linkColor as string),
                    border: '1px dashed',
                    borderColor: websiteColorCss(inputSettings.linkColor as string),
                    '&:hover': {
                        background: 'none',
                        color: websiteColorCss(inputSettings.linkColor as string),
                        border: '1px dashed',
                        borderColor: websiteColorCss(inputSettings.linkColor as string),
                        boxShadow: 'none',
                        opacity: 0.8,
                    },
                },
                '& .password-icon': {
                    '& svg': {
                        color: websiteColorCss(inputSettings.inputTextColor as string),
                    },
                },
            },
        };

        modalStyles.modalContainerStyles = {
            '& .modal-content': {
                borderRadius: `${modalBorderRadius + 2}px`,
            },
        };
    }

    return {
        form,
        formPosition,
        formFieldsContainer,
        formField,
        formControl,
        formControlTextarea,
        pointerEvents,
        buttonWrapper,
        buttonStyles,
        paymentFormDescription,
        linkControl,
        passwordIconStyle,
        formDropdownButton,
        ...modalStyles,
    };
}
