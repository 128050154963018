import { Widgets } from '@common/enums';

import { AccordionComponent } from '@web-builder/widgets/accordion/accordion.component';
import { ButtonComponent } from '@web-builder/widgets/button/button.component';
import { ChatBotComponent } from '@web-builder/widgets/chat-bot/chat-bot.component';
import { ImageComponent } from '@web-builder/widgets/image/image.component';
import { IndentComponent } from '@web-builder/widgets/indent/indent.component';
import { LineComponent } from '@web-builder/widgets/line/line.component';
import { MenuComponent } from '@web-builder/widgets/menu/menu.component';
import { SocialComponent } from '@web-builder/widgets/social/social.component';
import { TextComponent } from '@web-builder/widgets/text/text.component';
import { TimerComponent } from '@web-builder/widgets/timer/timer.component';
import { VideoComponent } from '@web-builder/widgets/video/video.component';
import { FormComponent } from '@web-builder/widgets/form/form.component';
import { PaymentFormComponent } from '@web-builder/widgets/payment-form/payment-form.component';
import { MapComponent } from '@web-builder/widgets/map/map.component';
import { LoginEduComponent } from '@web-builder/widgets/login-edu/login-edu.component';
import { GalleryComponent } from '@web-builder/widgets/gallery/gallery.component';
import { CodeComponent } from '@web-builder/widgets/code/code.component';
import { TestimonialComponent } from '@web-builder/widgets/testimonial/testimonial.component';
import { LogoComponent } from '@web-builder/widgets/logo/logo.component';
import { NavigationComponent } from '@web-builder/widgets/navigation/navigation.component';
import { ProductCardComponent } from '@web-builder/widgets/product-card/product-card.component';
import { ShopCartComponent } from '@web-builder/widgets/shop-cart/shop-cart.component';

type WidgetComponentsType = {
    // loadComponent(): Promise<any>;
    component: any;
};

type WidgetConfigType = {
    [key in Widgets]: WidgetComponentsType;
};

const WidgetsConfig: Partial<WidgetConfigType> = {
    [Widgets.INDENT]: {
        component: IndentComponent,
        // loadComponent: () => import('./indent/indent.component'),
    },
    [Widgets.BUTTON]: {
        component: ButtonComponent,
    },
    [Widgets.LINE]: {
        component: LineComponent,
    },
    [Widgets.TEXT]: {
        component: TextComponent,
    },
    [Widgets.IMAGE]: {
        component: ImageComponent,
    },
    [Widgets.ACCORDION]: {
        component: AccordionComponent,
    },
    [Widgets.MENU]: {
        component: MenuComponent,
    },
    [Widgets.TIMER]: {
        component: TimerComponent,
    },
    [Widgets.CHAT_BOTS]: {
        component: ChatBotComponent,
    },
    [Widgets.SOCIAL_NETWORKS]: {
        component: SocialComponent,
    },
    [Widgets.VIDEO]: {
        component: VideoComponent,
    },
    [Widgets.FORM]: {
        component: FormComponent,
    },
    [Widgets.PAYMENT_FORM]: {
        component: PaymentFormComponent,
    },
    [Widgets.MAP]: {
        component: MapComponent,
    },
    [Widgets.LOGIN_EDU]: {
        component: LoginEduComponent,
    },
    [Widgets.GALLERY]: {
        component: GalleryComponent,
    },
    [Widgets.CODE]: {
        component: CodeComponent,
    },
    [Widgets.TESTIMONIAL]: {
        component: TestimonialComponent,
    },
    [Widgets.LOGO]: {
        component: LogoComponent,
    },
    [Widgets.NAVIGATION]: {
        component: NavigationComponent,
    },
    [Widgets.PRODUCT_CARD]: {
        component: ProductCardComponent,
    },
    [Widgets.SHOP_CART]: {
        component: ShopCartComponent,
    },
};

export default WidgetsConfig;
