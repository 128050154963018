import { GradientObject } from '@components/sp-color-picker/src/types/color-picker.type';
import { EColorPickerGradientTypes } from '@common/components/sp-color-picker/src/enum/color-picker.enum';

export const defaultGradient: GradientObject = {
    type: EColorPickerGradientTypes.linearGradient,
    deg: '115.36deg',
    values: [
        {
            color: '#FDA949',
            position: '0%',
        },
        {
            color: '#DF1F6A',
            position: '100%',
        },
    ],
};

export const defaultColor = '#FFFFFF';
export const defaultDeg = '0';
