import { createReducer, on } from '@ngrx/store';
import { CartActions } from '@web-builder/store/cart/cart.actions';
import type { CartFeatureState } from './cart.selector';
import { type ProductCard, ProductType } from '@common/types/product-card.type';

export const initialCartState: CartFeatureState = {
    cartItems: [],
    numberOfItems: 0,
    totalPrice: 0,
    isAllDigitalProducts: false,
    loaded: false,
};

function updateLS(store: CartFeatureState) {
    window.localStorage.setItem('cartStore', JSON.stringify(store));
}

function getTotalPrice(cartItems: ProductCard[]): number {
    return cartItems.reduce((total, item) => total + item.price * item.selectedQuantity, 0);
}

function isAllDigitalProducts(cartItems: ProductCard[]): boolean {
    return !cartItems.some((item) => item.type === ProductType.physical);
}

export const cartReducer = createReducer(
    initialCartState,
    on(CartActions.getCartLocalStore, () => {
        const cartStore = JSON.parse(window.localStorage.getItem('cartStore'));
        const updatedStore = cartStore ? cartStore : initialCartState;

        return {
            ...updatedStore,
        };
    }),
    on(CartActions.addItemToCart, (store: CartFeatureState, result) => {
        const existingItem = store.cartItems.find(({ id }) => id === result.cartItem.id);
        const cartItems = store.cartItems
            .map((cartItem) =>
                cartItem.id !== result.cartItem.id
                    ? cartItem
                    : { ...cartItem, selectedQuantity: cartItem.selectedQuantity + result.cartItem.selectedQuantity },
            )
            .concat(existingItem ? [] : [{ ...result.cartItem }]);
        const updatedStore = {
            ...store,
            cartItems,
            numberOfItems: cartItems.length,
            totalPrice: getTotalPrice(cartItems),
            isAllDigitalProducts: isAllDigitalProducts(cartItems),
        };

        updateLS(updatedStore);

        return { ...updatedStore };
    }),
    on(CartActions.removeItemFromCart, (store: CartFeatureState, result) => {
        const cartItems = [...store.cartItems.filter((item) => item.id !== result.cartItem.id)];
        const updatedStore = {
            cartItems,
            numberOfItems: cartItems.length,
            totalPrice: getTotalPrice(cartItems),
            loaded: true,
            isAllDigitalProducts: isAllDigitalProducts(cartItems),
        };

        updateLS(updatedStore);

        return { ...updatedStore };
    }),
    on(CartActions.setNumberOfItemInCart, (store: CartFeatureState, result) => {
        const cartItems = store.cartItems.map((cartItem) =>
            cartItem.id !== result.cartItem.id ? cartItem : { ...cartItem, selectedQuantity: result.selectedQuantity },
        );

        const updatedStore = {
            ...store,
            cartItems,
            totalPrice: getTotalPrice(cartItems),
            isAllDigitalProducts: isAllDigitalProducts(cartItems),
        };

        updateLS(updatedStore);

        return { ...updatedStore };
    }),

    on(CartActions.cartLoaded, (store: CartFeatureState) => {
        const updatedStore = {
            ...store,
            loaded: true,
        };

        return { ...updatedStore };
    }),

    on(CartActions.cartLoading, (store: CartFeatureState) => {
        const updatedStore = {
            ...store,
            loaded: false,
        };

        return { ...updatedStore };
    }),
);
