import type { IndentWidgetData } from '@web/widgets/indent';

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { getStyles } from '@web/widgets/indent/components/indent-view/indent-view.component.styles';

import { AbstractWidgetComponent } from '@web-builder/widgets/abstract-widget.component';

@Component({
    selector: 'web-builder-indent',
    templateUrl: './indent.component.html',
    styleUrls: ['./indent.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule],
})
export class IndentComponent extends AbstractWidgetComponent<IndentWidgetData> {
    protected getStyles = getStyles;
}
