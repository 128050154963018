import { EThemeFontTags } from '@libs/themes/src/enums/font-tags.enum';

export const DEFAULT_FONT_SIZES = {
    [EThemeFontTags.leading]: '24px',
    [EThemeFontTags.big]: '19px',
    [EThemeFontTags.small]: '13px',
    [EThemeFontTags.quote]: '18px',
    [EThemeFontTags.signature]: '14px',
    [EThemeFontTags.p]: '16px',
    [EThemeFontTags.h1]: '36px',
    [EThemeFontTags.h2]: '28px',
    [EThemeFontTags.h3]: '22px',
    [EThemeFontTags.h4]: '18px',
    [EThemeFontTags.button]: '16px',
};
