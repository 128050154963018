import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { SelectedFileExtended } from '@common/types/file.type';

import { URL } from '@common/regexp';

import { ERadioGroupType } from '@ui/sp-radio-group';
import { ColorPickerEventPayload } from '@components/sp-color-picker';

@Component({
    selector: 'sp-setup-image-dropdown',
    templateUrl: './sp-setup-image-dropdown.component.html',
    styleUrls: ['./sp-setup-image-dropdown.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpSetupImageDropdownComponent implements OnInit, OnDestroy {
    @Input() file: SelectedFileExtended;
    @Input() isLink: boolean;
    @Input() closeEvent: Observable<void>;

    @Output() close: EventEmitter<string> = new EventEmitter();
    @Output() save: EventEmitter<SelectedFileExtended> = new EventEmitter<SelectedFileExtended>();

    public readonly MAX_DESCRIPTION_LENGTH = 100;
    public ERadioGroupType = ERadioGroupType;
    public imageSetupForm: UntypedFormGroup;
    private subscription: Subscription = new Subscription();
    private cachedInitData: any;

    constructor(private readonly fb: UntypedFormBuilder) {
        this.initForm();
    }

    public ngOnInit(): void {
        this.subscription.add(
            this.closeEvent.subscribe(() => {
                this.save.emit(this.cachedInitData);
            }),
        );

        this.subscription.add(
            this.imageSetupForm.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
                this.save.emit(this.imageSetupForm.value);
            }),
        );
        this.setFormData();
    }

    private initForm(): void {
        this.imageSetupForm = this.fb.group({
            link: [null, [Validators.pattern(URL)]],
            description: null,
            alt: null,
            textSize: null,
            textPosition: null,
            textColor: null,
            textBackground: null,
        });
    }

    private setFormData(): void {
        const data = {
            link: this.file.link,
            description: this.file.description,
            alt: this.file.alt,
            textSize: this.file.textSize,
            textPosition: this.file.textPosition,
            textColor: this.file.textColor,
            textBackground: this.file.textBackground,
        };

        this.imageSetupForm.patchValue(data, { emitEvent: false });
        this.cachedInitData = this.imageSetupForm.value;
    }

    public onSave(): void {
        this.cachedInitData = this.imageSetupForm.value;
        this.close.emit();
    }

    public onClose(): void {
        this.imageSetupForm.patchValue(this.cachedInitData, { emitEvent: false });
        this.close.emit();
    }

    // ALT
    public get alt(): UntypedFormControl {
        return this.imageSetupForm.get('alt') as UntypedFormControl;
    }

    // LINK
    public get link(): UntypedFormControl {
        return this.imageSetupForm.get('link') as UntypedFormControl;
    }

    // DESCRIPTION
    public get description(): UntypedFormControl {
        return this.imageSetupForm.get('description') as UntypedFormControl;
    }

    // TEXT_SIZE
    public get textSize(): AbstractControl {
        return this.imageSetupForm.get('textSize');
    }

    public setTextSize(value: string): void {
        this.textSize.setValue(value);
    }

    // TEXT_POSITION
    public get textPosition(): AbstractControl {
        return this.imageSetupForm.get('textPosition');
    }

    public setTextPosition(value: string): void {
        this.textPosition.setValue(value);
    }

    // TEXT_COLOR
    public get textColor(): AbstractControl {
        return this.imageSetupForm.get('textColor');
    }

    public setTextColor({ value }: ColorPickerEventPayload): void {
        this.textColor.setValue(value);
    }

    // TEXT_BACKGROUND
    public get textBackground(): AbstractControl {
        return this.imageSetupForm.get('textBackground');
    }

    public setTextBackground({ value }: ColorPickerEventPayload): void {
        this.textBackground.setValue(value);
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
