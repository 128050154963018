import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxIntlTelInputModule } from '@moddi3/ngx-intl-tel-input';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { TranslateModule } from '@ngx-translate/core';

import { SpPipesModule } from '@common/pipes/pipes.module';

import jss from 'jss';
import preset from 'jss-preset-default';

import { browserTranslationsModuleConfig, BrowserTranslationProvider } from '@web-builder/core/configs/translations.config';
import { JwtInterceptor } from '@web-builder/core/interceptors/jwt.interceptor';

import { WidgetResolverComponent } from './core/resolvers/widget-resolver.component';
import { BlockWrapperComponent } from './components/block-wrapper/block-wrapper.component';
import { SpecificBlockWrapperComponent } from './components/specific-block-wrapper/specific-block-wrapper.component';
import { SpecificSectionWrapperComponent } from './components/specific-section-wrapper/specific-section-wrapper.component';
import { ColumnWrapperComponent } from './components/column-wrapper/column-wrapper.component';
import { SpecificColumnWrapperComponent } from './components/specific-column-wrapper/specific-column-wrapper.component';
import { StructureWrapperComponent } from './components/structure-wrapper/structure-wrapper.component';
import { FormComponent } from './widgets/form/form.component';
import { ShopCartComponent } from './widgets//shop-cart/shop-cart.component';
import { SubscriptionMessageModalComponent } from './widgets/form/subscription-message-modal/subscription-message-modal.component';
import { ModalsComponent } from './components/modals/modals.component';
import { BlockShapeDividersComponent } from './components/block-wrapper/block-shape-dividers/block-shape-dividers.component'

import { AppComponent } from './app.component';
import { AppStoreModule } from '@web-builder/store/app-store.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ShopCartIconComponent } from '@web-builder/widgets/shop-cart-icon/shop-cart-icon.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { RESOURCE_URL } from '@sites/analytics/tokens';
import { TransferStateService } from '@web-builder/core/services/transfer-state.service';

jss.setup(preset());

@NgModule({
    declarations: [
        AppComponent,
        WidgetResolverComponent,
        BlockWrapperComponent,
        SpecificBlockWrapperComponent,
        SpecificSectionWrapperComponent,
        ColumnWrapperComponent,
        SpecificColumnWrapperComponent,
        StructureWrapperComponent,
        FormComponent,
        ShopCartComponent,
        SubscriptionMessageModalComponent,
        BlockShapeDividersComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'webBuilderApp' }),
        TranslateModule.forRoot(browserTranslationsModuleConfig),
        ModalModule.forRoot(),
        ReactiveFormsModule,
        HttpClientModule,
        SpPipesModule,
        NgxIntlTelInputModule,
        BrowserAnimationsModule,
        NgxDropzoneModule,
        ModalsComponent,
        AppStoreModule,
        BsDropdownModule,
        ShopCartIconComponent,
        NgSelectModule,
        FormsModule,
    ],
    providers: [
        BrowserTranslationProvider,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true,
        },
        {
            provide: RESOURCE_URL,
            useFactory: (transferStateService: TransferStateService) => transferStateService.getResourceUrl(),
            deps: [TransferStateService],
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
