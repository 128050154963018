import type { OnInit } from '@angular/core';
import { Component, HostListener, Input } from '@angular/core';
import type { FormArray, FormControl, FormGroup } from '@angular/forms';
import { type AbstractControl, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateModule } from '@ngx-translate/core';

import { EduCourse } from '@common/types/edu-course.type';
import { AnalyticsSettings } from '@web/shared/components/web-analytics-settings/web-analytics-setings.type';

import { TransferStateService } from '@web-builder/core/services/transfer-state.service';
import { UtilsService } from '@web-builder/core/services/utils.service';
import { EduHttpService } from '@web-builder/core/services/REST/edu.http.service';
import { AnalyticsService } from '@web-builder/core/services/analytics.service';
import { Classes } from 'jss';
import { UtmTagsService } from '@web-builder/core/services/utm-tags.service';

import { type CheckboxButtonData } from '@web/widgets/login-edu';
import { SpPipesModule } from '@common/pipes/pipes.module';
import { type HttpErrorResponse } from '@angular/common/http';
import { EAttributeTypesCrm } from '@common/enums';
import {
    type CheckboxData,
    type CheckboxesFormControl,
    type SignUpFormData,
    type SignUpFormGroup,
    type SignUpModalResult,
} from '@web-builder/widgets/login-edu/type/login-edu.type';

@Component({
    selector: 'web-builder-sign-up-modal',
    styleUrls: ['./sign-up-modal.component.less'],
    templateUrl: './sign-up-modal.component.html',
    imports: [CommonModule, ReactiveFormsModule, TranslateModule, SpPipesModule],
    standalone: true,
})
export class SignUpModalComponent implements OnInit {
    @Input() public eduCourse!: EduCourse;
    @Input() public domainId!: number;
    @Input() public isAuthorizationButtonActive!: boolean;
    @Input() public analytics: AnalyticsSettings;
    @Input() public classes!: Classes;
    @Input() public checkboxes: CheckboxButtonData[] = [];
    @Input() public isShowCheckboxes: boolean;

    public onClose: Subject<SignUpModalResult>;
    public form: SignUpFormGroup;

    constructor(
        private readonly fb: NonNullableFormBuilder,
        private readonly bsModalRef: BsModalRef,
        private readonly transferStateService: TransferStateService,
        private readonly utilsService: UtilsService,
        private readonly eduHttpService: EduHttpService,
        private readonly analyticsService: AnalyticsService,
        private readonly utmTagsService: UtmTagsService,
    ) {}

    public ngOnInit(): void {
        this.initForm();
        this.onClose = new Subject();
    }

    private initForm(): void {
        this.form = this.fb.group({
            name: this.fb.control(null, [Validators.required]),
            email: this.fb.control(null, [Validators.email, Validators.required]),
            checkboxes: this.checkboxesArray,
        });
    }

    public onSignUp(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        const payload: any = {
            firstName: this.form.value.name,
            email: this.form.value.email,
            language: this.utilsService.getLanguageCode(this.transferStateService.get('lang')),
            domainId: this.domainId,
            responsibleId: this.eduCourse.userId,
            courseId: this.eduCourse.id,
            attributes: this.checkboxesPayload,
        };

        const utm = this.utmTagsService.getUtmTags();
        if (Boolean(utm)) {
            payload.utm = utm;
        }

        this.form.disable();
        this.eduHttpService.register(payload).subscribe(
            () => {
                this.bsModalRef.hide();
                if (this.analytics?.googleAnalytic) {
                    this.analyticsService.gaEventRequest(this.analytics?.googleSettings);
                }

                if (this.analytics?.pixelAnalytic) {
                    this.analyticsService.pixelFbEventRequest(this.analytics?.pixelSettings);
                }

                this.onClose.next({
                    data: this.formData,
                });
            },
            (error: HttpErrorResponse) => {
                this.form.enable();

                if (error.status === 422) {
                    const { errors } = error?.error;

                    if ('email' in errors) {
                        this.form.setErrors({ unique: true });
                        this.email.setErrors({ unique: true });
                    }

                    if ('courseId' in errors) {
                        this.form.setErrors({ invalidCourse: true });
                    }
                }
            },
        );
    }

    public onSignIn(): void {
        this.onClose.next({ message: 'signIn' });
        this.bsModalRef.hide();
    }

    public onCloseModal(): void {
        this.bsModalRef.hide();
    }

    public changeCheckBoxValue(control: AbstractControl): void {
        control.patchValue({ ...control.value, value: !control.value.value });
    }

    @HostListener('document:keydown.enter')
    public onEnterPress(): void {
        this.onSignUp();
    }

    public get name(): FormControl<SignUpFormData['name']> {
        return this.form.get('name') as FormControl<SignUpFormData['name']>;
    }

    public get email(): FormControl<SignUpFormData['email']> {
        return this.form.get('email') as FormControl<SignUpFormData['email']>;
    }

    private get checkboxesArray(): FormArray<FormGroup<CheckboxesFormControl>> {
        const array = this.fb.array<FormGroup<CheckboxesFormControl>>([]);

        if (!this.checkboxes?.length) {
            return array as FormArray<FormGroup<CheckboxesFormControl>>;
        }

        this.checkboxes.forEach((checkbox) => {
            array.push(
                this.fb.group({
                    crmVariableType: this.fb.control(checkbox.crmVariableType),
                    crmContactVariable: this.fb.control(checkbox.crmContactVariable),
                    required: this.fb.control(checkbox.required),
                    label: this.fb.control(checkbox.label),
                    value: this.fb.control(false, this.isShowCheckboxes && checkbox.required ? [Validators.requiredTrue] : null),
                }),
            );
        });

        return array as FormArray<FormGroup<CheckboxesFormControl>>;
    }

    public get checkboxesControls(): FormArray {
        return this.form.get('checkboxes') as FormArray;
    }

    private get formData(): SignUpFormData {
        const checkboxesArr: CheckboxData[] = this.checkboxesControls.controls.map((checkbox) => ({ ...checkbox.getRawValue() }));

        return {
            ...this.form.getRawValue(),
            checkboxes: checkboxesArr,
        };
    }

    private get checkboxesPayload() {
        if (!this.checkboxesControls.controls.length) return [];

        const checkboxes = [];
        this.checkboxesControls.controls.forEach((control) => {
            const checkbox = {
                value:
                    control.value?.crmVariableType === EAttributeTypesCrm.checkbox ? control.value.value : control.value.value?.toString(),
                attributeId: control.value.crmContactVariable,
            };
            if (checkbox.attributeId) {
                checkboxes.push(checkbox);
            }
        });

        return checkboxes;
    }
}
