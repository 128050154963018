import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { SpFileManagerContextService } from '@common/components/sp-file-manager/src/sp-file-manager-context.service';
import { SpFilesSelectComponent } from '@common/components/sp-file-manager/src/sp-files-select/sp-files-select.component';
import { InputBoolean } from '@common/helpers/convert';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'sp-link-upload-button',
    template: `
        <ng-template #uploadButton>
            <a class="dashed" [class]="class" [class.disabled]="disabled" (click)="!stockImagesDropdown && ctx.openFileManagerDrawer()">
                {{ label | translate }} <span *ngIf="stockImagesDropdown" class="caret"></span>
            </a>
        </ng-template>

        <ng-container *ngIf="stockImagesDropdown; else uploadButton">
            <sp-files-select (myPhotosClick)="ctx.openFileManagerDrawer()" (unsplashClick)="unsplashRequested.emit()">
                <ng-container [ngTemplateOutlet]="uploadButton"></ng-container>
            </sp-files-select>
        </ng-container>
    `,
    styles: [``],
    standalone: true,
    imports: [CommonModule, TranslateModule, SpFilesSelectComponent],
})
export class SpLinkUploadButtonComponent {
    @Input() @InputBoolean() public stockImagesDropdown: boolean = false;
    @Input() @InputBoolean() public disabled: boolean = false;
    @Input() public label: string = 'add';
    @Input() public class: string;

    @Output() public unsplashRequested = new EventEmitter<void>();

    public ctx = inject(SpFileManagerContextService, {
        skipSelf: true,
    });
}
