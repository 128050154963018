import { type SelectedFileExtended } from '@common/types/file.type';
import { type EHorizontalPosition } from '@common/enums';
import type { AnimationSettings } from '@common/components/sp-animation-settings/type/animation-settings.type';

export enum EResizeType {
    scale = 'scale',
    fill = 'fill',
}

export type ImageWidgetData = {
    image: SelectedFileExtended;
    horizontalAlign: EHorizontalPosition;
    mobileHorizontalAlign?: EHorizontalPosition;
    isShadow: boolean;
    isBorder: boolean;
    animation: AnimationSettings;
    resizeType: EResizeType;
    isSynchronizeStyle: boolean;
    hideOnMobile?: boolean;
    hideOnDesktop?: boolean;
    position: {
        width?: number;
        x?: number;
        y?: number;
        rotate?: number;
        zIndex?: number;
        mobileX?: number;
        mobileY?: number;
        mobileRotate?: number;
        mobileZIndex?: number;
    };
};
