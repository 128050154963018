export type MapWidgetData = {
    apiKey: string;
    addresses: MapItem[];
    zoom: number;
    center: google.maps.LatLngLiteral;
    tempCenter: google.maps.LatLngLiteral;
    mapStyle: MapStyle;
};

export type MapItem = {
    address: string;
    position: google.maps.LatLngLiteral;
    title?: string;
    description?: string;
};

export type StyleItem = {
    name: string;
    active: boolean;
    styles: string;
};

export enum MapStyle {
    Default = 'default',
    White = 'white',
    Gray = 'gray',
}
