import { EPaymentType } from '@common/enums';

export const paymentCurrencies = ['CZK', 'EUR', 'GBP', 'UAH', 'USD', 'KZT'];

export const paymentTypeNames = {
    [EPaymentType.yooMoney]: 'yooMoney',
    [EPaymentType.youKassa]: 'yooKassa',
    [EPaymentType.payPal]: 'payPal',
    [EPaymentType.fondy]: 'fondy',
    [EPaymentType.roboKassa]: 'roboKassa',
    [EPaymentType.stripe]: 'stripe',
    [EPaymentType.mercadopago]: 'mercadopago',
    [EPaymentType.liqpay]: 'liqpay',
    [EPaymentType.wayforpay]: 'wayforpay',
    [EPaymentType.flutterwave]: 'flutterwave',
    [EPaymentType.monobank]: 'monobank',
    [EPaymentType.custom]: 'cash',
    [EPaymentType.cryptomus]: 'cryptomus',
    [EPaymentType.whitepay]: 'whitepay',
    [EPaymentType.klarna]: 'klarna',
    [EPaymentType.stripeConnect]: 'stripe',
};

export const amountConfig = {
    min: 1,
    max: 999999,
    step: 0.01,
};

export const minimumAmountConfig = {
    [EPaymentType.whitepay]: 8,
    [EPaymentType.cryptomus]: 1,
};

export const paymentsList = {
    [EPaymentType.payPal]: {
        type: EPaymentType.payPal,
        label: 'paypal',
        index: 1,
    },
    [EPaymentType.yooMoney]: {
        type: EPaymentType.yooMoney,
        label: 'you_money',
        index: 2,
        hidden: true,
    },
    [EPaymentType.youKassa]: {
        type: EPaymentType.youKassa,
        label: 'you_kassa',
        index: 3,
        hidden: true,
    },
    [EPaymentType.fondy]: {
        type: EPaymentType.fondy,
        label: 'fondy',
        index: 4,
    },
    [EPaymentType.roboKassa]: {
        type: EPaymentType.roboKassa,
        label: 'robo_kassa',
        index: 5,
        hidden: true,
    },
    [EPaymentType.stripe]: {
        type: EPaymentType.stripe,
        label: 'stripe',
        index: 6,
        hidden: true,
    },
    [EPaymentType.mercadopago]: {
        type: EPaymentType.mercadopago,
        label: 'mercadopago',
        index: 7,
    },
    [EPaymentType.liqpay]: {
        type: EPaymentType.liqpay,
        label: 'liqpay',
        index: 8,
    },
    [EPaymentType.wayforpay]: {
        type: EPaymentType.wayforpay,
        label: 'wayforpay',
        index: 9,
    },
    [EPaymentType.flutterwave]: {
        type: EPaymentType.flutterwave,
        label: 'flutterwave',
        index: 10,
    },
    [EPaymentType.monobank]: {
        type: EPaymentType.monobank,
        label: 'monobank',
        index: 11,
    },
    [EPaymentType.cryptomus]: {
        type: EPaymentType.cryptomus,
        label: 'cryptomus',
        index: 12,
    },
    [EPaymentType.custom]: {
        type: EPaymentType.custom,
        label: 'custom_payment_name',
        index: 13,
    },
    [EPaymentType.whitepay]: {
        type: EPaymentType.whitepay,
        label: 'whitepay',
        index: 14,
    },
    [EPaymentType.klarna]: {
        type: EPaymentType.klarna,
        label: 'klarna',
        index: 15,
    },
    [EPaymentType.stripeConnect]: {
        type: EPaymentType.stripeConnect,
        label: 'stripe',
        index: 16,
    },
};
