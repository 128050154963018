<ul class="sp-accordion-group" [id]="widget.columnId" [class]="classes?.accordionList">
    <li
        class="sp-accordion-item"
        *ngFor="let accordionItem of accordionList; let i = index"
        [class]="[classes?.accordionItem, classes?.accordionItemDivider]"
    >
        <div [class]="classes?.accordionItemTitle" (click)="toggle(i, accordionItem.isOpen)">
            <div [class]="classes?.accordionItemTitleContent">
                <div class="sp-ui-text sp-accordion-title" [innerHTML]="accordionItem.title | spSafeHtml"></div>
            </div>
            <div
                [ngClass]="
                    accordionItem.isOpen
                        ? [classes?.activeIcon, classes?.accordionItemTitleIcon, classes?.accordionIconMargin, classes?.icon]
                        : [classes?.accordionItemTitleIcon, classes?.accordionIconMargin, classes?.icon]
                "
            ></div>
        </div>
        <div
            class="accordion-animation"
            [class]="[classes?.accordionItemTitleContent, 'itemContent_' + i]"
            [class.active]="accordionItem.isOpen"
            [style.height]="accordionItem.contentHeight"
        >
            <div class="sp-ui-text sp-accordion-content" [innerHTML]="accordionItem.content | spSafeHtml"></div>
        </div>
    </li>
</ul>
