import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateModule } from '@ngx-translate/core';

import { EduHttpService } from '@web-builder/core/services/REST/edu.http.service';
import { type SignUpModalResult } from '@web-builder/widgets/login-edu/type/login-edu.type';

@Component({
    selector: 'web-builder-sign-up-success-modal',
    styleUrls: ['./sign-up-success-modal.component.less'],
    templateUrl: './sign-up-success-modal.component.html',
    imports: [CommonModule, TranslateModule],
    standalone: true,
})
export class SignUpSuccessModalComponent implements OnInit {
    @Input() public signUpFormData!: SignUpModalResult['data']; // not using this data currently but might be useful in future

    public onClose: Subject<boolean>;

    constructor(private readonly bsModalRef: BsModalRef, private readonly eduHttpService: EduHttpService) {}

    public ngOnInit(): void {
        this.onClose = new Subject();
    }

    public onChangeUser(): void {
        this.onClose.next(true);
        this.bsModalRef.hide();
    }

    public onCloseModal(): void {
        this.bsModalRef.hide();
    }
}
