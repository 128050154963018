import {
    ChangeDetectionStrategy,
    Component,
    Input,
    Output,
    EventEmitter,
    type OnChanges,
    ViewChild,
    type SimpleChanges,
    type Type,
} from '@angular/core';

import { DeviceMode } from '@common/types/layout.type';
import { SpHostDirective } from '@common/directives/host.directive';
import { EThemeSettings } from '@common/libs/themes/src/enums/theme-settings.enum';

import {
    SpWebsiteButtonSettingsComponent,
    SpWebsiteFontSettingsComponent,
    SpWebsiteColorsSettingsComponent,
    SpWebsitePresetSettingsComponent,
} from '@libs/themes/src/variants/website';

type ThemeComponent =
    | SpWebsiteButtonSettingsComponent
    | SpWebsiteFontSettingsComponent
    | SpWebsiteColorsSettingsComponent
    | SpWebsitePresetSettingsComponent;

@Component({
    selector: 'sp-website-theme-control-resolver',
    template: '<ng-template spHost></ng-template>',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpWebsiteThemeControlResolverComponent implements OnChanges {
    @Input() public activeThemeSetting: EThemeSettings;
    @Input() public deviceMode: DeviceMode;

    @Output() public readonly changeDeviceMode: EventEmitter<DeviceMode> = new EventEmitter();

    @ViewChild(SpHostDirective, { static: true }) public elementHost: SpHostDirective;

    private themeSettingsComponents: Record<any, any> = {
        [EThemeSettings.colorsSettings]: SpWebsiteColorsSettingsComponent,
        [EThemeSettings.buttonsSettings]: SpWebsiteButtonSettingsComponent,
        [EThemeSettings.fontsSettings]: SpWebsiteFontSettingsComponent,
    };

    public ngOnChanges(changes: SimpleChanges) {
        const { activeThemeSetting } = changes;

        if (activeThemeSetting?.previousValue !== activeThemeSetting?.currentValue) {
            this.loadComponent();
        }
    }

    private loadComponent(): void {
        const component: Type<ThemeComponent> = this.themeSettingsComponents[this.activeThemeSetting] || SpWebsitePresetSettingsComponent;
        const { viewContainerRef } = this.elementHost;

        viewContainerRef.clear();
        const componentRef = viewContainerRef.createComponent<ThemeComponent>(component);

        if (componentRef.componentType === SpWebsiteFontSettingsComponent) {
            componentRef.setInput('deviceMode', this.deviceMode);
            (componentRef.instance as SpWebsiteFontSettingsComponent).changeDeviceMode.subscribe((deviceMode: DeviceMode) => {
                this.changeDeviceMode.emit(deviceMode);
            });
            // (componentRef.instance as SpWebsiteFontSettingsComponent).deviceMode = this.deviceMode;
        }
        // if (typeof componentRef.instance)
        // componentRef.instance.deviceMode = this.deviceMode;
    }
}
