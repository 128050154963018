import { ECartIconType } from '@common/enums';

export const CART_ICON_TEMPLATES = (type: string, size: number) => {
    switch (type) {
        case 'svgIcon1':
            return `
            <svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.11486 9.80099C3.2171 8.77859 4.07743 8 5.10493 8H18.895C19.9225 8 20.7828 8.77859 20.885 9.80099L21.885 19.801C22.0028 20.9784 21.0782 22 19.895 22H4.10493C2.92169 22 1.99712 20.9784 2.11486 19.801L3.11486 9.80099ZM18.895 10H5.10493L4.10493 20H19.895L18.895 10Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5468 4.66439C10.215 5.0955 10 5.76126 10 6.6V11.4C10 11.9523 9.55228 12.4 9 12.4C8.44772 12.4 8 11.9523 8 11.4V6.6C8 5.45051 8.2909 4.31628 8.96199 3.44445C9.65692 2.54166 10.7024 2 12 2C13.2976 2 14.3431 2.54166 15.038 3.44445C15.7091 4.31628 16 5.45051 16 6.6V11.4C16 11.9523 15.5523 12.4 15 12.4C14.4477 12.4 14 11.9523 14 11.4V6.6C14 5.76126 13.785 5.0955 13.4532 4.66439C13.1451 4.26423 12.6906 4 12 4C11.3094 4 10.8549 4.26423 10.5468 4.66439Z" fill="currentColor"/>
            </svg>
        `;
        case 'svgIcon2':
            return `
            <svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6635 3.05829C14.1836 2.87253 14.7558 3.14358 14.9416 3.66369L16.4903 8.00003H20.4344C21.7869 8.00003 22.7494 9.31446 22.3411 10.6038L19.1744 20.6038C18.9112 21.4351 18.1397 22 17.2677 22H6.7323C5.86033 22 5.08885 21.4351 4.82561 20.6038L1.65895 10.6038C1.25065 9.31446 2.21317 8.00003 3.56563 8.00003H7.19757L9.08068 3.60611C9.29824 3.09848 9.88612 2.86333 10.3937 3.08088C10.9014 3.29844 11.1365 3.88632 10.919 4.39395L9.37351 8.00003H14.3665L13.0581 4.33636C12.8723 3.81626 13.1434 3.24404 13.6635 3.05829ZM15.0808 10H8.51637L7.91897 11.3939C7.70142 11.9016 7.11354 12.1367 6.60591 11.9192C6.09828 11.7016 5.86313 11.1137 6.08068 10.6061L6.34043 10L3.56563 10L6.7323 20L17.2677 20L20.4344 10H17.2045L17.4416 10.6637C17.6273 11.1838 17.3563 11.756 16.8362 11.9418C16.3161 12.1275 15.7438 11.8565 15.5581 11.3364L15.0808 10Z" fill="currentColor"/>
            </svg>
        `;
        case 'svgIcon3':
            return `
            <svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.0452937 1.20176C0.210017 0.674617 0.77089 0.380814 1.29804 0.545538L4.09866 1.42068C4.60993 1.58044 5.03574 1.93861 5.28072 2.41495L6.61018 5.00002H19.4708C20.9131 5.00002 21.8812 6.48029 21.3031 7.80166L18.6781 13.8017C18.3596 14.5296 17.6404 15 16.8458 15H16.6178L17.3942 16.5528L15.6054 17.4472L14.3817 15H6.99997V17H4.99997V6.2421L3.50214 3.32965L0.701521 2.45451C0.174373 2.28978 -0.11943 1.72891 0.0452937 1.20176ZM6.99997 7.00002L6.99997 13L16.8458 13L19.4708 7.00002H6.99997Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5 22C6.10457 22 7 21.1046 7 20C7 18.8954 6.10457 18 5 18C3.89543 18 3 18.8954 3 20C3 21.1046 3.89543 22 5 22ZM5 24C7.20914 24 9 22.2091 9 20C9 17.7909 7.20914 16 5 16C2.79086 16 1 17.7909 1 20C1 22.2091 2.79086 24 5 24Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18 22C19.1046 22 20 21.1046 20 20C20 18.8954 19.1046 18 18 18C16.8954 18 16 18.8954 16 20C16 21.1046 16.8954 22 18 22ZM18 24C20.2091 24 22 22.2091 22 20C22 17.7909 20.2091 16 18 16C15.7909 16 14 17.7909 14 20C14 22.2091 15.7909 24 18 24Z" fill="currentColor"/>
            </svg>
        `;
        default:
            return `
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6635 3.05829C14.1836 2.87253 14.7558 3.14358 14.9416 3.66369L16.4903 8.00003H20.4344C21.7869 8.00003 22.7494 9.31446 22.3411 10.6038L19.1744 20.6038C18.9112 21.4351 18.1397 22 17.2677 22H6.7323C5.86033 22 5.08885 21.4351 4.82561 20.6038L1.65895 10.6038C1.25065 9.31446 2.21317 8.00003 3.56563 8.00003H7.19757L9.08068 3.60611C9.29824 3.09848 9.88612 2.86333 10.3937 3.08088C10.9014 3.29844 11.1365 3.88632 10.919 4.39395L9.37351 8.00003H14.3665L13.0581 4.33636C12.8723 3.81626 13.1434 3.24404 13.6635 3.05829ZM15.0808 10H8.51637L7.91897 11.3939C7.70142 11.9016 7.11354 12.1367 6.60591 11.9192C6.09828 11.7016 5.86313 11.1137 6.08068 10.6061L6.34043 10L3.56563 10L6.7323 20L17.2677 20L20.4344 10H17.2045L17.4416 10.6637C17.6273 11.1838 17.3563 11.756 16.8362 11.9418C16.3161 12.1275 15.7438 11.8565 15.5581 11.3364L15.0808 10Z" fill="currentColor"/>
            </svg>
        `;
    }
};

export const CART_ICON_TYPES = [
    {
        label: 'svgIcon1',
        type: ECartIconType.ICON_1,
    },
    {
        label: 'svgIcon2',
        type: ECartIconType.ICON_2,
    },
    {
        label: 'svgIcon3',
        type: ECartIconType.ICON_3,
    },
];
