import type { StateKey } from '@angular/platform-browser';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Request } from 'express';
import type { RequestDTO, RequestType } from '@web-builder-server/types';
import type { ValueOf } from '@common/types';

import { Inject, Injectable, Optional } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';

import { PlatformService } from '@common/services/platform.service';
import { IS_PREVIEW } from '@common/tokens';

import { environment } from '@web-builder-env/environment';

@Injectable({
    providedIn: 'root',
})
export class TransferStateService {
    private readonly isPreviewStateKey: StateKey<boolean> = makeStateKey<boolean>('isPreview');
    private readonly transferStateKeys: Record<keyof RequestDTO, StateKey<ValueOf<RequestDTO>>> = {
        structure: makeStateKey<RequestDTO['structure']>('structure'),
        themeSettings: makeStateKey<RequestDTO['themeSettings']>('themeSettings'),
        lang: makeStateKey<RequestDTO['lang']>('lang'),
        pageId: makeStateKey<RequestDTO['pageId']>('pageId'),
        siteId: makeStateKey<RequestDTO['siteId']>('siteId'),
        googleMapsApiKey: makeStateKey<RequestDTO['googleMapsApiKey']>('googleMapsApiKey'),
        domainUrl: makeStateKey<RequestDTO['domainUrl']>('domainUrl'),
        domainId: makeStateKey<RequestDTO['domainId']>('domainId'),
        userId: makeStateKey<RequestDTO['userId']>('userId'),
        showPoweredBy: makeStateKey<RequestDTO['showPoweredBy']>('showPoweredBy'),
        eduTariff: makeStateKey<RequestDTO['eduTariff']>('eduTariff'),
        pageUrl: makeStateKey<RequestDTO['pageUrl']>('pageUrl'),
        site: makeStateKey<RequestDTO['site']>('site'),
    };

    constructor(
        private readonly transferState: TransferState,
        private readonly platformService: PlatformService,
        @Optional() @Inject(REQUEST) private readonly request: Request<{}, {}, RequestType>,
        @Optional() @Inject(IS_PREVIEW) private readonly isPreviewToken: boolean,
    ) {
        if (this.platformService.isPlatformServer()) {
            for (const [key, val] of Object.entries(this.request.body)) {
                this.transferState.set(this.transferStateKeys[key], val);
            }

            this.transferState.set(this.isPreviewStateKey, this.isPreviewToken);
        }
    }

    public get(key: keyof RequestDTO) {
        if (this.platformService.isPlatformServer()) {
            return this.request.body[key];
        }

        return this.transferState.get(this.transferStateKeys[key], null);
    }

    public getResourceUrl(): string {
        return this.isPreview ? environment.memberAreaUrl : environment.proxyUrl;
    }

    public get isPreview(): boolean {
        if (this.platformService.isPlatformServer()) {
            return this.isPreviewToken;
        }

        return this.transferState.get(this.isPreviewStateKey, false);
    }
}
