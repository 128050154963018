import type { SelectedFileInterface } from '@sendpulse/file-manager';
import type { SelectedFileExtended } from '@common/types/file.type';
import { setChatAt } from '@common/helpers/string';

const DEFAULT_IMAGE_THUMBNAIL_PREVIEW = './assets/img/elements/gallery-blank.svg';

export const encodeFilePreviewUrl = (file: SelectedFileInterface, baseUrl: string): string => {
    if (file.url?.startsWith('http')) {
        return `url('${file.url}')`;
    }

    let url = DEFAULT_IMAGE_THUMBNAIL_PREVIEW;

    if (file.url && file.defaultCdnUrl) {
        return `url('${encodeURI(baseUrl + file.url)}')`;
    }

    if (file.url) {
        url = encodeURI(file.url); // encoding to prevent spaces and other special chars
    }

    return `url('${baseUrl}${url}')`;
};

export const fileNameWithPath = (path: string): string => path.split('/').pop();

export const encodeFilePreviewUrlWithResize = (file: SelectedFileExtended, baseUrl: string): string => {
    if (!file.hasResize || !file.defaultCdnUrl || !file.url || file.extension === 'image/gif') {
        return encodeFilePreviewUrl(file, baseUrl);
    }

    const src = baseUrl + file.url;
    const splitImgSrc = src.split('/');
    splitImgSrc.splice(4, 0, 'resize_landing');
    const extensionDotIndex = splitImgSrc[splitImgSrc.length - 1].lastIndexOf('.');
    splitImgSrc[splitImgSrc.length - 1] = setChatAt(splitImgSrc[splitImgSrc.length - 1], extensionDotIndex, '_');

    const res = splitImgSrc.join('/');
    return `url('${res}/source.webp')`;
};
