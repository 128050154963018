import { type AfterViewInit, ElementRef, type OnInit, ViewChild } from '@angular/core';
import type { Classes } from 'jss';
import { ColumnStructureEntity, SpecificSectionStructureEntity, SpecificBlocksEntity } from '@web/types';
import type { AnimationSettings } from '@common/components/sp-animation-settings/type/animation-settings.type';

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { ServerStylesService } from '@common/services/server-styles.service';

import { getStyles } from '@web/layout/content/page-content/components/columns/specific-column-wrapper/specific-column-wrapper.component.styles';
import { getGsapScrollTrigger } from '@components/sp-animation-settings';

@Component({
    selector: 'web-builder-specific-column-wrapper',
    templateUrl: './specific-column-wrapper.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecificColumnWrapperComponent implements OnInit, AfterViewInit {
    @ViewChild('animatedElem') animatedElem: ElementRef<HTMLDivElement>;

    @Input() public column!: ColumnStructureEntity;
    @Input() public columnSection!: SpecificSectionStructureEntity;
    @Input() public block!: SpecificBlocksEntity;
    @Input() public index!: number;

    public classes: Classes;

    constructor(protected readonly stylesService: ServerStylesService) {}

    public ngOnInit(): void {
        this.classes = this.stylesService.getStyles(this.column.id, getStyles(this.column, this.columnSection, this.block));
    }

    public ngAfterViewInit(): void {
        const animationOptions = this.column.data.animation;
        if (animationOptions?.isActive) getGsapScrollTrigger(animationOptions, this.animatedElem.nativeElement);
    }

    public get animation(): AnimationSettings {
        return this.column.data.animation;
    }

    public get columnId(): string {
        return `column-${this.column.id}`;
    }
}
