import type { AfterContentInit } from '@angular/core';
import { Component, HostListener, inject } from '@angular/core';
import type { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import type { PaymentFormElement } from '@common/types/payment-form.type';
import type { FormElement, FormRadio, Option } from '@common/types/form-element.type';
import type { FormAdditionalSettings } from '@components/sp-form-additional-settings';
import type { FormButtonSettings } from '@components/sp-form-button-settings';
import type { FormInputSettings } from '@components/sp-form-input-settings';
import type { FormWidgetData } from '@web/widgets/form/type/form-widget.type';
import { of, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { getStyles } from '@web/widgets/form/components/form-view/form-view.component.styles';

import {
    EActionAfterConfirm,
    EButtonAdditionalEffectsCondition,
    EButtonAdditionalHoverEffectsTypes,
    EButtonAdditionalStaticEffectsTypes,
    EButtonStyleType,
    ESize,
} from '@common/enums';
import { ESubscriptionResponseResult } from '@common/enums/subscription-response-result.enum';
import { ECrmCommentPlace, EFormControlType, EFormInputType } from '@common/enums/form.enum';
import * as typeGuards from '@common/type-guards/form-elements.type-guards';

import { BUTTON_SIZE_CLASS_MAP, BUTTON_TYPE_CLASS_MAP, DEFAULT_BUTTON_CLASS } from '@web/widgets/common/base/base-button/base-button.const';

import { AbstractWidgetComponent } from '@web-builder/widgets/abstract-widget.component';
import { CrmHttpService } from '@web-builder/core/services/REST/crm.http.service';

import { SubscriptionMessageModalComponent } from './subscription-message-modal/subscription-message-modal.component';
import { AnalyticsService } from '@web-builder/core/services/analytics.service';

import { CountryISO, PhoneNumberFormat, SearchCountryField } from '@moddi3/ngx-intl-tel-input';
import { CountryISOService } from '@common/services/REST/country-iso.http.service';
import { SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'web-builder-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.less'],
})
export class FormComponent extends AbstractWidgetComponent<FormWidgetData> implements AfterContentInit {
    private readonly crmHttpService: CrmHttpService = inject(CrmHttpService);
    private readonly analyticsService: AnalyticsService = inject(AnalyticsService);
    private readonly countryISOService: CountryISOService = inject(CountryISOService);
    public readonly SearchCountryField = SearchCountryField;
    public readonly PhoneNumberFormat = PhoneNumberFormat;

    public readonly formInputType = EFormInputType;
    public CountryISO = CountryISO.Ukraine;
    public excludeCountries = [CountryISO.Russia];
    public typeGuards = typeGuards;
    public formGroup: FormGroup;
    public files: File[] = [];
    public isFilesError = false;
    public isFilesSizeError = false;
    public isLoading = false;

    protected getStyles = getStyles;
    private readonly pageId = this.transferStateService.get('pageId');

    public showErrorMessage: boolean = false;

    public phoneError: string = '';
    public emailError: string = '';
    public dropdownListValues = [];

    public ngAfterContentInit(): void {
        if (this.platformService.isPlatformBrowser()) {
            const urlParams = new URLSearchParams(window.location.search);
            const formId = urlParams.get('subscribed_form_id');

            if (formId && formId === this.widget.id) {
                this.showSubscriptionMessageModal();
            }
        }

        this.formGroup = this.fb.group({
            formArray: this.fb.array([]),
        });
        this.generateFormArray();
        this.getCountryISO();
    }

    public generateFormArray(): void {
        this.formGroup = this.fb.group({
            formArray: this.fb.array(
                this.elements.map((formElement) => {
                    const options = (formElement as FormRadio)?.options || null;

                    this.dropdownListValues.push(options ? options.filter((el) => el.selected) : options);
                    return this.fb.group({
                        ...{
                            ...formElement,
                            ...{
                                options: this.fb.array(options ? (formElement as FormRadio).options.map((opt) => this.fb.group(opt)) : []),
                            },
                        },
                        value: [this.getDefaultValue(formElement, options), this.getValidators(formElement)],
                    });
                }),
            ),
        });

        this.formGroup.markAsUntouched();
    }

    public get formArrayControls(): AbstractControl[] {
        return (this.formGroup.get('formArray') as FormArray).controls;
    }

    public get elements(): FormElement[] {
        return this.widgetData.elements;
    }

    public get inputSettings(): FormInputSettings {
        return this.widgetData.inputSettings;
    }

    public get additionalFormSettings(): FormAdditionalSettings {
        return this.widgetData.additionalFormSettings;
    }

    public get formButtonSettings(): FormButtonSettings {
        return this.widgetData.formButtonSettings;
    }

    public getControlValue(control: AbstractControl, controlName: string) {
        return control.get(controlName)?.value;
    }

    public getButtonEffectClass(button: FormButtonSettings): string {
        let nameEffect: string = '';

        if (button.additionalEffects?.isActive) {
            if (button.additionalEffects?.effectCondition === EButtonAdditionalEffectsCondition.hover) {
                switch (button.additionalEffects?.hoverEffects) {
                    case EButtonAdditionalHoverEffectsTypes.bounce:
                        nameEffect = EButtonAdditionalHoverEffectsTypes.bounce;
                        break;
                    case EButtonAdditionalHoverEffectsTypes.shake:
                        nameEffect = EButtonAdditionalHoverEffectsTypes.shake;
                        break;
                    case EButtonAdditionalHoverEffectsTypes.decrease:
                        nameEffect = EButtonAdditionalHoverEffectsTypes.decrease;
                        break;
                    case EButtonAdditionalHoverEffectsTypes.increase:
                        nameEffect = EButtonAdditionalHoverEffectsTypes.increase;
                        break;
                    default:
                        break;
                }
            }

            if (button.additionalEffects?.effectCondition === EButtonAdditionalEffectsCondition.static) {
                switch (button.additionalEffects?.staticEffects) {
                    case EButtonAdditionalStaticEffectsTypes.shine:
                        nameEffect = EButtonAdditionalStaticEffectsTypes.shine;
                        break;
                    case EButtonAdditionalStaticEffectsTypes.twinkle:
                        nameEffect = EButtonAdditionalStaticEffectsTypes.twinkle;
                        break;
                    default:
                        break;
                }
            }

            return 'button_effect_' + nameEffect;
        }

        return '';
    }

    public getButtonStyles(formButtonSettings: FormButtonSettings): string {
        let styles = `${DEFAULT_BUTTON_CLASS} ${BUTTON_SIZE_CLASS_MAP[formButtonSettings.size ? formButtonSettings.size : ESize.M]}`;
        if (formButtonSettings.styleType === EButtonStyleType.custom) {
            return `${styles} ${this.classes?.buttonStyles} ${this.getButtonEffectClass(formButtonSettings)}`;
        }

        return `${styles} ${BUTTON_TYPE_CLASS_MAP[formButtonSettings.styleType]} ${this.classes?.buttonStyles} ${this.getButtonEffectClass(
            formButtonSettings,
        )}`;
    }

    public getIcon(icon: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(icon);
    }

    public inputErrors(control: AbstractControl): string[] {
        const errors: string[] = [];

        Object.keys(control.get('value').errors).forEach((err) => {
            if (control.get('type').value === EFormControlType.radio) {
                const isRadioChecked = control.get('options').value.some((el) => el.selected);
                if (!isRadioChecked && err === 'required') {
                    errors.push('control_errors_required');
                }

                return;
            }

            if (err === 'pattern') errors.push('email_validation_error');
            if (control.get('type').value === EFormInputType.phone && this.phoneError) errors.push(this.phoneError);
            if (control.get('type').value === EFormInputType.email && this.emailError) errors.push(this.emailError);
            if (err === 'required' && control.get('type').value !== EFormControlType.checkbox) errors.push('control_errors_required');
            if (err === 'required' && control.get('type').value === EFormControlType.checkbox)
                errors.push('control_errors_checkbox_required');
            if (err === 'maxlength' && control.get('type').value === EFormControlType.textarea)
                errors.push('control_errors_textarea_maxlength');
            if (err === 'maxlength' && control.get('type').value === EFormInputType.name) errors.push('control_errors_name_maxlength');
        });

        return errors;
    }

    public changeCheckBoxValue(control: AbstractControl): void {
        control.get('value').patchValue(!control.get('value').value);
    }

    public changeCheckRadioValue(control: AbstractControl, option: { label: string; selected: boolean; value: string }) {
        const opt = this.getControlValue(control, 'options').map((el) => {
            return {
                ...el,
                ...{ selected: option.label === el.label && option.value === el.value },
            };
        });
        control.get('options').patchValue(opt);
        control.get('value').patchValue(option.value);
    }

    public changeSelectValue(control: AbstractControl, event) {
        if (event) {
            if (Array.isArray(event)) {
                const opt = this.getControlValue(control, 'options').map((el) => {
                    return {
                        ...el,
                        ...{ selected: event.map((e) => e.value).indexOf(el.value) > -1 },
                    };
                });

                control.get('value').patchValue(event.length ? `["${event.map((el) => el.value).join('","')}"]` : null);
                control.get('options').patchValue(opt);
            } else {
                const opt = this.getControlValue(control, 'options').map((el) => {
                    return {
                        ...el,
                        ...{ selected: event.value === el.value },
                    };
                });

                control.get('value').patchValue(event.value);
                control.get('options').patchValue(opt);
            }
        }
    }

    public submitForm(): void {
        if (this.isPreview) {
            return;
        }

        const payload: {
            pipelineId?: any;
            dealName: any;
            pipelineStepId?: any;
            elementId: any;
            email?: any;
            phone?: any;
            book_id?: any;
            pageId: any;
            attachments?: File[];
            variables: {};
            contactFields: {};
            contactVariables: {};
            dealVariables: {};
            utm: string;
            updateExistingContact: boolean;
            contactComments: any[];
            dealComments: any[];
            createContactAndDeal?: boolean;
            backUrl?: string;
        } = {
            dealName: null,
            elementId: null,
            pageId: this.pageId,
            variables: {},
            contactFields: {},
            contactVariables: {},
            dealVariables: {},
            utm: this.utmTagsService.getUtmTags(),
            updateExistingContact: !!this.widgetData.additionalFormSettings.updateExistingContact,
            contactComments: [],
            dealComments: [],
            backUrl: this.additionalFormSettings.openLink ?? null,
        };

        this.formGroup.markAllAsTouched();

        (this.formGroup.value.formArray as PaymentFormElement[]).forEach((val) => {
            if (val.type === EFormInputType.email || val.type === EFormInputType.phone || val.type === EFormInputType.file) {
                if (val.type === EFormInputType.email) payload.email = val.value;
                if (val.type === EFormInputType.phone) {
                    if (val.value?.e164Number) {
                        payload.phone = val.value?.e164Number;
                        payload.variables[EFormInputType.phone] = val.value?.e164Number;
                    } else {
                        payload.phone = val.value;
                        payload.variables[EFormInputType.phone] = val.value;
                    }
                }
                if (val.type === EFormInputType.file) {
                    payload.attachments = this.files;
                    this.isFilesError = !this.files.length && val.required;
                    this.isFilesSizeError = this.filesSize > 500;
                }

                return;
            }

            if (val.type === EFormControlType.textarea) {
                if (val.crmCommentPlace === ECrmCommentPlace.crmAllComment) {
                    payload.contactComments.push(val.value);
                    payload.dealComments.push(val.value);
                }
                if (val.crmCommentPlace === ECrmCommentPlace.crmContactComment) {
                    payload.contactComments.push(val.value);
                }
                if (val.crmCommentPlace === ECrmCommentPlace.crmDealComment) {
                    payload.dealComments.push(val.value);
                }
            }

            if (val.value !== null) {
                if (val.variable) payload.variables[val.variable] = val.value;
                if (val.crmContactVariable) payload.contactVariables[val.crmContactVariable] = val.value;
                if (val.crmContactVariable) payload.contactFields[val.crmContactVariable] = val.value;
                if (val.crmDealVariable) payload.dealVariables[val.crmDealVariable] = val.value;
            }
        });

        if (this.widgetData.selectedPipeline) payload.pipelineId = this.widgetData.selectedPipeline.id;
        if (this.widgetData.selectedPipeline) payload.dealName = this.widgetData.dealName;
        if (this.widgetData.selectedPipelineStep) payload.pipelineStepId = this.widgetData.selectedPipelineStep.id;
        if (this.widgetData.selectedAddressBook) payload.book_id = this.widgetData.selectedAddressBook;
        if (this.widgetData.selectedAddressBook) payload.book_id = this.widgetData.selectedAddressBook;
        if (this.widgetData.createContactAndDeal) payload.createContactAndDeal = this.widgetData.createContactAndDeal;
        if (this.widget) payload.elementId = this.widget.id;

        let formDataPayload = new FormData();

        for (let key in payload) {
            if (this.files.length && key === 'attachments') {
                this.files.forEach((file) => formDataPayload.append('attachments[]', file));
            } else if (typeof payload[key] === 'object') {
                for (let objKey in payload[key]) {
                    const formDataKey = `${key}[${objKey}]`;

                    formDataPayload.append(formDataKey, this.getValueForFormData(payload[key][objKey]));
                }
            } else {
                formDataPayload.append(key, this.getValueForFormData(payload[key]));
            }
        }

        // console.log(this.actionAfterSuccessSubscription());
        // return;

        if (this.formGroup.valid && !this.isFilesError && !this.isFilesSizeError) {
            this.formGroup.disable();
            this.isLoading = true;

            this.crmHttpService
                .getSubscription(formDataPayload)
                .pipe(
                    tap(() => {
                        this.phoneError = '';
                        this.emailError = '';
                    }),
                    catchError((err) => {
                        if (err?.error?.errors) {
                            const errors = err.error.errors;
                            Object.keys(errors).forEach((key) => {
                                if (key.includes('phone')) this.phoneError = errors[key];
                                if (key.includes('email')) this.emailError = errors[key];
                            });
                        }

                        this.formGroup.markAllAsTouched();
                        this.formGroup.enable();

                        return of(null);
                    }),
                )
                .subscribe((res) => {
                    this.isLoading = false;
                    this.files = [];

                    if (res?.result === ESubscriptionResponseResult.SUCCESS) {
                        this.actionAfterSuccessSubscription();
                    }

                    if (res?.result === ESubscriptionResponseResult.RE_CAPTCHA) {
                        window.open(res.redirectUrl, '_self');
                    }

                    if (this.widgetData?.analyticsSettings?.googleAnalytic) {
                        this.analyticsService.gaEventRequest(this.widgetData?.analyticsSettings?.googleSettings);
                    }

                    if (this.widgetData.analyticsSettings.pixelAnalytic) {
                        this.analyticsService.pixelFbEventRequest(this.widgetData?.analyticsSettings?.pixelSettings);
                    }

                    this.formGroup.enable();
                    this.changeDetectorRef.detectChanges();
                });
        }

        this.showErrorMessage = true;
    }

    private getDefaultValue(element: FormElement, options: Option[]): any {
        if (element.type === EFormControlType.checkbox) {
            return false;
        }

        if (element.type === EFormControlType.select) {
            const arr = options.filter((el) => el.selected).map((el) => el.label);

            if (arr.length > 0) {
                return arr.length === 1 ? arr[0] : `["${arr.join('","')}"]`;
            } else {
                return '';
            }
        }

        if (element.type === EFormControlType.radio) {
            return options?.find((el) => el.selected)?.value;
        }

        if (element.type === EFormInputType.hidden) {
            return element.value;
        }

        return '';
    }

    private getValidators(elem: FormElement): Validators[] {
        let validators: Validators[] = [];

        if (elem.type === EFormInputType.file) {
            return validators;
        }

        if (elem.required && elem.type === EFormControlType.radio && !elem.options.some((el) => el.selected)) {
            validators.push(Validators.required);
            return validators;
        }

        if (elem.required && elem.type !== EFormControlType.checkbox) {
            validators.push(Validators.required);
        }

        if (elem.type === EFormInputType.email) {
            validators.push(Validators.pattern(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/));
        }

        if (elem.type === EFormControlType.checkbox && elem.required) {
            validators.push(Validators.requiredTrue);
        }

        if (elem.type === EFormControlType.textarea) {
            validators.push(Validators.maxLength(5000));

            if (elem.required) {
                validators.push(Validators.required);
            }
        }

        if (elem.type === EFormInputType.input || elem.type === EFormInputType.name) {
            validators.push(Validators.maxLength(128));
        }

        return validators;
    }

    public showSubscriptionMessageModal() {
        this.modalService
            .show(SubscriptionMessageModalComponent, {
                initialState: { subscriptionMessage: this.additionalFormSettings.subscriptionMessage },
            })
            .onHide.subscribe(() => {
                this.formGroup.reset();
                this.generateFormArray();
                this.showErrorMessage = false;
                this.changeDetectorRef.detectChanges();
            });
    }

    public refreshErrorMessageValue() {
        this.showErrorMessage = !this.showErrorMessage;
    }

    @HostListener('click', ['$event.target'])
    public hideErrorMessage(event) {
        if (this.showErrorMessage && !event.classList.contains('submit-btn')) {
            setTimeout(() => {
                this.showErrorMessage = false;
            }, 100);
        }
    }

    public getSelected(options: Option[]) {
        return !options.some((el) => el.selected);
    }

    private actionAfterSuccessSubscription(): void {
        if (this.additionalFormSettings.actionAfterConfirmType === EActionAfterConfirm.message) {
            this.showSubscriptionMessageModal();
            return;
        }

        const chatBotLinkType = this.isChatBotLink(this.additionalFormSettings.openLink);
        if (chatBotLinkType) {
            const formData = (this.formGroup.value.formArray as PaymentFormElement[]).reduce((result, el) => {
                if (el.variable === 'phone') {
                    if (el.value?.e164Number) {
                        result[el.variable] = el.value?.e164Number;
                    } else {
                        result[el.variable] = el.value;
                    }
                    return result;
                }
                if (el.variable === 'email') {
                    result[el.variable] = el.value;
                } else {
                    result[el.label.trim()] = el.value;
                }
                return result;
            }, {});

            if (chatBotLinkType === 'telegram') {
                const start = this.additionalFormSettings.openLink.includes('?') ? '|' : '?';
                const telegramFormDataParams = this.joinChatBotLinkParams(formData, start, '|');

                let link = this.additionalFormSettings.openLink;

                if (link.includes('t.me')) {
                    link = link.replace('t.me', 'tg.pulse.is');
                }

                console.log('redirecting to link', link + telegramFormDataParams);
                window.open(link + telegramFormDataParams, '_self');
                return;
            }

            if (chatBotLinkType === 'messenger') {
                const start = this.additionalFormSettings.openLink.includes('?') ? '__' : '?';
                const messengerFormDataParams = this.joinChatBotLinkParams(formData, start, '__');

                console.log('redirecting to link', this.additionalFormSettings.openLink + messengerFormDataParams);
                window.open(this.additionalFormSettings.openLink + messengerFormDataParams, '_self');
                return;
            }

            if (chatBotLinkType === 'instagram') {
                const start = this.additionalFormSettings.openLink.includes('?') ? '__' : '?';
                const instagramFormDataParams = this.joinChatBotLinkParams(formData, start, '__');

                console.log('redirecting to link', this.additionalFormSettings.openLink + instagramFormDataParams);
                window.open(this.additionalFormSettings.openLink + instagramFormDataParams, '_self');
                return;
            }

            if (chatBotLinkType === 'viber') {
                const start = this.additionalFormSettings.openLink.includes('?') ? '|' : '?';
                const viberFormDataParams = this.joinChatBotLinkParams(formData, start, '|');

                console.log('redirecting to link', this.additionalFormSettings.openLink + viberFormDataParams);
                window.open(this.additionalFormSettings.openLink + viberFormDataParams, '_self');
                return;
            }

            if (chatBotLinkType === 'whatsapp') {
                const start = this.additionalFormSettings.openLink.includes('?') ? '&' : '?';
                const whatsappFormDataParams = this.joinChatBotLinkParams(formData, start, '&');

                console.log('redirecting to link', this.additionalFormSettings.openLink + whatsappFormDataParams);
                window.open(this.additionalFormSettings.openLink + whatsappFormDataParams, '_self');
                return;
            }

            console.warn('Unknown chat bot link type');
        }

        window.open(this.additionalFormSettings.openLink, '_self');
    }

    private joinChatBotLinkParams(formData: Record<string, string>, start: string, delimiter: string): string {
        return Object.keys(formData).reduce((acc, key) => {
            if (!acc) {
                return `${start}${key}=${formData[key]}`;
            }
            return `${acc}${delimiter}${key}=${formData[key]}`;
        }, '');
    }

    public getInputIdForSelenium(control: AbstractControl): string {
        return `${this.widget.id}${control.value?.variable ? '-variable-' + control.value?.variable : ''}${
            control.value?.crmContactVariable ? '-crmContactVariable-' + control.value?.crmContactVariable : ''
        }${control.value?.crmDealVariable ? '-crmDealVariable-' + control.value?.crmDealVariable : ''}`;
    }

    public isControlTypeHidden(type): boolean {
        return type === EFormInputType.hidden;
    }

    public getPhoneErrorMsg() {
        return (document.getElementById('phone') as any).placeholder;
    }

    private getCountryISO() {
        this.countryISOService.getCountryISO().subscribe((data) => {
            this.CountryISO = data.country_code.toLowerCase();
            this.changeDetectorRef.detectChanges();
        });
    }

    public get fileUploadImg(): string {
        return this.utilsService.formatImgSrc('./assets/img/websites/forms-icons/lpc-f-file-upload-icon.svg');
    }

    public onFileUpload(event: { addedFiles: File[] }) {
        this.files.push(...event.addedFiles);
    }

    public onFileRemove(event: File) {
        this.files.splice(this.files.indexOf(event), 1);
    }

    public get filesSize(): number {
        let size: number = 0;
        this.files.forEach((file) => {
            size += file.size;
        });

        return Math.ceil(size / 1024 / 1024);
    }

    private getValueForFormData(value: any) {
        let formDataValue = '';

        if (value === true) {
            formDataValue = '1';
        } else if (value === false) {
            formDataValue = '0';
        } else {
            formDataValue = value;
        }
        return formDataValue;
    }

    private isChatBotLink(link: string): 'telegram' | 'messenger' | 'instagram' | 'viber' | 'whatsapp' | null {
        if (link.includes('t.me') || link.includes('tg.pulse.is')) {
            return 'telegram';
        }

        if (link.includes('m.me')) {
            return 'messenger';
        }

        if (link.includes('ig.me')) {
            return 'instagram';
        }

        if (link.includes('whatsapp.com')) {
            return 'whatsapp';
        }

        if (link.includes('viber://')) {
            return 'viber';
        }

        console.warn('Can not determine chat bot link type', link);
        return null;
    }

    public isShowSelectErr(control: AbstractControl) {
        const isSelected =
            this.getControlValue(control, 'options')
                .map((e) => e.selected)
                .indexOf(true) > -1;

        return this.getControlValue(control, 'required') && this.showErrorMessage && !isSelected;
    }

    public get rootFormWithPositionClasses() {
        return `${this.classes?.form} ${this.classes?.formPosition}`;
    }
}
