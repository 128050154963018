<div class="alert-box edu-modal" [class]="classes.modalBodyStyles" [formGroup]="form">
    <div class="sp-lpc-form">
        <button type="button" class="close-modal-btn" aria-label="Close" (click)="onCloseModal()">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="sp-ui-text">
            <h2 class="edu-modal-title">{{ "sign_up_title" | translate }}</h2>
        </div>

        <div *ngIf="form.hasError('invalidCourse')" class="error-alert">
            {{ "error_invalid_course" | translate }}
        </div>
        <div *ngIf="form.hasError('unique')" class="error-alert">
            {{ "error_user_already_exist" | translate }}
        </div>

        <div *ngIf="!form.hasError('invalidCourse')" [class]="classes?.formFieldsContainer">
            <div formArrayName="formArray">
                <div [class]="classes?.formField"
                     class="field-block sp-form-control"
                     *ngFor="let control of formArrayControls; index as index"
                     [formGroupName]="index"
                     [ngSwitch]="true"

                >
                    <ng-container *ngSwitchCase="typeGuards.isInput(control.value)">
                        <label [class.hidden]="isControlTypeHidden(control.value.type)">
                            {{ getControlValue(control, "label") }}{{ getControlValue(control, "required") ? " *" : "" }}
                        </label>
                        <ng-container *ngIf="control.value.type !== EFormInputType.password && !control.value.isDynamicPlaceholderEnabled">
                            <input
                                class="sp-form-input"
                                [class]="classes?.formControl"
                                [class.is-invalid]="control.invalid"
                                [formControlName]="'value'"
                                [type]="getControlValue(control, 'htmlInputType')"
                                [placeholder]="isControlTypeHidden(control.value.type) ? '' : getControlValue(control, 'placeholder')"
                                (focusout)="refreshErrorMessageValue()"
                                [value]="isControlTypeHidden(control.value.type) ? getControlValue(control, 'value') : ''"
                            />
                        </ng-container>
                        <ng-container *ngIf="control.value.type === EFormInputType.phone && control.value.isDynamicPlaceholderEnabled">
                            <ngx-intl-tel-input
                                class="intl-size-M"
                                [formControlName]="'value'"
                                [cssClass]="classes?.formControl"
                                [class.is-invalid]="control.invalid"
                                [enableAutoCountrySelect]="true"
                                [selectFirstCountry]="false"
                                [separateDialCode]="true"
                                [enablePlaceholder]="true"
                                [searchCountryFlag]="true"
                                [phoneValidation]="true"
                                [searchCountryField]="[SearchCountryField.All]"
                                [selectedCountryISO]="CountryISO"
                                [numberFormat]="PhoneNumberFormat.International">
                            </ngx-intl-tel-input>
                        </ng-container>
                        <ng-container *ngIf="control.invalid && control.touched && showErrorMessage">
                            <div class="text-error-msg-container">
                                <div class="text-error-msg">{{ inputErrors(control)[0] | translate }}</div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="getControlValue(control, 'htmlInputType') === 'tel' && control.invalid && this.showErrorMessage && control.value.isDynamicPlaceholderEnabled">
                            <div class="text-error-msg-container">
                                <div class="text-error-msg">{{ 'control_errors_phone_format' | translate: { PHONE_FORMAT: getPhoneErrorMsg() } }}</div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="typeGuards.isTextarea(control.value)">
                        <label>
                            {{ getControlValue(control, "label") }}{{ getControlValue(control, "required") ? " *" : "" }}
                        </label>
                        <textarea
                            [class]="classes?.formControlTextarea"
                            [class.is-invalid]="control.invalid"
                            class="sp-form-input"
                            [formControlName]="'value'"
                            [placeholder]="getControlValue(control, 'placeholder')"
                            (focusout)="refreshErrorMessageValue()"></textarea>

                            <ng-container *ngIf="control.invalid && control.touched && showErrorMessage">
                                <div class="text-error-msg-container">
                                    <div class="text-error-msg">{{ inputErrors(control)[0] | translate }}</div>
                                </div>
                            </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="typeGuards.isCheckbox(control.value)">
                        <label class="sp-checkbox">
                            <input
                                class="checkbox-input sp-form-checkbox"
                                [class]="classes?.spCheckbox"
                                [class.is-invalid]="control.invalid"
                                [value]="getControlValue(control, 'value')"
                                [type]="getControlValue(control, 'htmlInputType')"
                                (change)="changeCheckBoxValue(control)"
                            />
                            <span *ngIf="getControlValue(control, 'required')" class="checkbox-text" [innerHTML]="' ' + (getControlValue(control, 'label') | removePTags) + ' *'"></span>
                            <span *ngIf="!getControlValue(control, 'required')" class="checkbox-text" [innerHTML]="' ' + (getControlValue(control, 'label') | removePTags)"></span>
                        </label>
                        <ng-container *ngIf="control.invalid && control.touched && showErrorMessage">
                            <div class="text-error-msg-container">
                                <div class="text-error-msg">
                                    {{ inputErrors(control)[0] | translate }}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="typeGuards.isRadio(control.value)">
                        <label>
                            {{ getControlValue(control, "label") }}{{ getControlValue(control, "required") ? " *" : "" }}
                        </label>
                        <div *ngFor="let option of getControlValue(control, 'options')" class="radio-options">
                            <label>
                                <input
                                    class="radio-input sp-form-radio"
                                    [class]="classes?.spRadio"
                                    [type]="getControlValue(control, 'htmlInputType')"
                                    [formControlName]="'value'"
                                    [checked]="option.selected"
                                    (change)="changeCheckRadioValue(control, option)"
                                />
                                <span class="radio-text"> {{ option.label }}</span>
                            </label>
                        </div>
                        <ng-container *ngIf="control.invalid && control.touched && showErrorMessage">
                            <div class="text-error-msg-container">
                                <div class="text-error-msg">{{ inputErrors(control)[0] | translate }}</div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="typeGuards.isSelect(control.value)">
                        <label>
                            {{ getControlValue(control, "label") }}{{ getControlValue(control, "required") ? " *" : "" }}
                        </label>
                        <ng-select
                            [class]="classes?.formControl"
                            [placeholder]="'select_value' | translate"
                            [items]="getControlValue(control, 'options')"
                            (change)="changeSelectValue(control, $event)"
                            [(ngModel)]="getControlValue(control, 'htmlInputType') === 'multiselect' ? dropdownListValues[index] : dropdownListValues[index][0]"
                            [ngModelOptions]="{ standalone: true }"
                            [multiple]="getControlValue(control, 'htmlInputType') === 'multiselect'"
                            [closeOnSelect]="false">
                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                <div class="ng-value" *ngFor="let item of items">
                                    <span class="ng-value-icon left" (click)="clear(item);" aria-hidden="true">×</span>
                                    <span class="ng-value-label">{{ item.label }}</span>
                                </div>
                            </ng-template>
                        </ng-select>
                        <ng-container *ngIf="isShowSelectErr(control)">
                            <div class="text-error-msg-container">
                                <div class="text-error-msg">{{ 'control_errors_required' | translate }}</div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>

            <ng-container *ngIf="isShowCheckboxes">
                <div class="field-block" [class]="classes.formField" formArrayName="checkboxes">
                    <ng-container *ngFor="let control of checkboxesControls.controls">
                        <label  class="sp-checkbox">
                            <input class="checkbox-input" [class.is-invalid]="control.invalid" type="checkbox" (change)="changeCheckBoxValue(control)">
                            <span *ngIf="control.value.required" class="checkbox-text" [innerHTML]="' ' + (control.value.label | removePTags) + ' *'"></span>
                            <span *ngIf="!control.value.required" class="checkbox-text" [innerHTML]="' ' + (control.value.label | removePTags)"></span>
                        </label>
                        <ng-container *ngIf="control.invalid && control.touched">
                            <div class="error-reason text-danger">
                                <span>
                                    {{ 'control_errors_checkbox_required' | translate }}
                                </span>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>

    <div *ngIf="!form.hasError('invalidCourse')"
        class="edu-modal-footer"
        [class]="classes?.buttonWrapper">
        <button *ngIf="isAuthorizationButtonActive" type="button" class="sp-ui-button sp-ui-button-md sp-ui-button-secondary-light sp-ui-button-secondary-edu" (click)="onSignIn()">
            {{ "sign_in" | translate }}
        </button>
        <button
            type="button"
            class="sp-ui-button sp-ui-button-md sp-ui-button-primary-light"
            [class.disabled]="form.disabled"
            (click)="onSignUp()"
        >
            {{ "sign_up" | translate }}
        </button>
    </div>
</div>
