<div [class]="data.classes?.form">
    <div *ngIf="form.hasError('invalidCourse')" class="error-alert">
        {{ "error_invalid_course" | translate }}
    </div>
    <div *ngIf="form.hasError('unique')" class="error-alert">
        {{ "error_user_already_exist" | translate }}
    </div>
    <form [class]="data.classes?.formContainer" class="sp-lpc-form" [formGroup]="form">
        <div [class]="data.classes?.formFieldsContainer">
            <div [class]="data.classes?.formField" class="field-block">
                <label>{{ "name" | translate }}</label>
                <input
                    name="name"
                    [class]="data.classes?.formControl"
                    [class.has-error]="name.invalid && name.touched"
                    [class.disabled]="form.disabled"
                    [placeholder]="'input_name_placeholder' | translate"
                    [formControl]="name"
                />
                <div *ngIf="name.invalid && name.touched" class="error-reason text-danger">
                    <span *ngIf="name.hasError('required')">{{ "error_required" | translate }}</span>
                </div>
            </div>
            <div [class]="data.classes?.formField" class="field-block">
                <label>{{ "email" | translate }}</label>
                <input
                    [class]="data.classes?.formControl"
                    placeholder="example@mail.com"
                    [class.has-error]="email.invalid && email.touched"
                    [class.disabled]="form.disabled"
                    [formControl]="email"
                />
                <div *ngIf="email.invalid && email.touched" class="error-reason text-danger">
                    <span *ngIf="email.hasError('required')">{{ "error_required" | translate }}</span>
                    <span *ngIf="email.hasError('email')">{{ "error_invalid_email" | translate }}</span>
                    <span *ngIf="email.hasError('unique')">{{ "error_user_already_exist" | translate }}</span>
                    <!--                    TODO: RENAME TRANSLATIONS KEY -->
                </div>
            </div>

            <ng-container *ngIf="data.isShowCheckboxes">
                <div [class]="data.classes?.formField" formArrayName="checkboxes">
                    <ng-container *ngFor="let control of checkboxesControls.controls">
                        <label class="sp-checkbox">
                            <input
                                class="checkbox-input"
                                [class.is-invalid]="control.invalid"
                                type="checkbox"
                                (change)="changeCheckBoxValue(control)"
                            />
                            <span
                                *ngIf="control.value.required"
                                class="checkbox-text"
                                [innerHTML]="' ' + (control.value.label | removePTags) + ' *'"
                            ></span>
                            <span
                                *ngIf="!control.value.required"
                                class="checkbox-text"
                                [innerHTML]="' ' + (control.value.label | removePTags)"
                            ></span>
                        </label>
                        <ng-container *ngIf="control.invalid && control.touched">
                            <div class="text-error-msg-container">
                                <div class="text-error-msg">
                                    {{ "control_errors_checkbox_required" | translate }}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>

            <div
                [class]="data.classes?.['registration-button-wrapper']"
                [spTooltip]="!eduCourse ? ('edu_login_unavailable' | translate) : null"
            >
                <a
                    type="button"
                    [class]="data.buttonStyles"
                    class="common-form-btn"
                    [class.disabled]="form.disabled || !eduCourse"
                    (click)="onSignUp()"
                >
                    {{ data.buttonLabel }}
                </a>
            </div>

            <a
                *ngIf="isAuthorizationButtonActive"
                [class]="data.classes?.linkControl"
                (click)="changeFormEvent.emit(ELoginEduDefaultForm.login)"
            >
                {{ "go_to_login" | translate }} &#8594;
            </a>
        </div>
    </form>
</div>
