import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SpSwitchModule } from '@ui/sp-switch';
import { ReactiveFormsModule } from '@angular/forms';

import { SpButtonAdditionalEffectsSettingsComponent } from '@common/components/sp-button-additional-effects-settings/sp-button-additional-effects-settings.component';

@NgModule({
    imports: [CommonModule, TranslateModule, SpSwitchModule, ReactiveFormsModule],
    declarations: [SpButtonAdditionalEffectsSettingsComponent],
    exports: [SpButtonAdditionalEffectsSettingsComponent],
})
export class SpButtonAdditionalEffectsSettingsModule {}