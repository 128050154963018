<div class="panel-options-body">
    <div class="mb-20" *ngIf="isConfigColors">
        <label>{{ 'theme_settings_color_scheme' | translate }}</label>
        <a class="dotted pull-right" (click)="openDetailSettingsView(EThemeSettings.colorsSettings)">
            {{ 'theme_settings_customize' | translate }}
        </a>
        <div class="form-control preset-control" dropdown>
            <ul class="color-elements" dropdownToggle>
                <li *ngFor="let color of selectedThemeColors$ | async">
                    <div class="color-element" [style.background]="color.value">
                        <sp-transparent-icon
                            [color]="color.value"
                        ></sp-transparent-icon>
                    </div>
                </li>
            </ul>

            <ul class="dropdown-menu preset-wrapper scroll-wrapper" *dropdownMenu>
                <li *ngFor="let colorTemplate of colorsPresets$ | async">
                    <ul class="color-elements"
                        [class.selected]="(selectedThemeColorIndex$ | async) === colorTemplate.index"
                        (click)="selectColorsThemeHandler(colorTemplate)">
                        <li *ngFor="let color of colorTemplate.values">
                            <div class="color-element" [style.background]="color.value">
                                <sp-transparent-icon
                                    [color]="color.value"
                                ></sp-transparent-icon>
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>

    <div class="mb-20" *ngIf="isConfigFonts">
        <label>{{ 'theme_settings_font_text_styles' | translate }}</label>
        <a class="dotted pull-right" (click)="openDetailSettingsView(EThemeSettings.fontsSettings)">
            {{ 'theme_settings_customize' | translate }}
        </a>
        <div class="form-control preset-control scroll-wrapper" dropdown>
            <div class="font-elements" dropdownToggle>
                <div class="font-element title-style">
                        <span
                            class="font-weight-bold"
                            [style.font-family]="h1Font.styles.fontFamily">
                            {{ FONT_FAMILY_NAMES[h1Font.font] }}
                        </span>
                    <div>
                        <span class="font-element-size">h1</span>
                        <span class="font-element-size">{{ h1Font.styles.fontSize }}</span>
                    </div>
                </div>
                <div class="font-element paragraph-style">
                        <span [style.font-family]="pFont.styles.fontFamily">
                            {{ FONT_FAMILY_NAMES[pFont.font] }}
                        </span>
                    <div>
                        <span class="font-element-size">p</span>
                        <span class="font-element-size">{{ pFont.styles.fontSize }}</span>
                    </div>
                </div>
            </div>

            <ul class="dropdown-menu preset-wrapper scroll-wrapper">
                <li *ngFor="let fontPreset of fontsPresets$ | async">
                    <div
                        class="font-elements default-elements"
                        [class.selected]="(selectedThemeFontIndex$ | async) === fontPreset.index"
                        (click)="selectFontsThemeHandler(fontPreset)"
                    >
                        <div *ngIf="findTitleFont(fontPreset) as h1Preset" class="font-element title-style">
                            <span
                                class="font-weight-bold"
                                [style.font-family]="h1Preset.styles.fontFamily"
                            >
                                {{ FONT_FAMILY_NAMES[h1Preset.font] }}
                            </span>
                            <div>
                                <span class="font-element-size">h1</span>
                                <span class="font-element-size">{{ h1Preset.styles.fontSize }}</span>
                            </div>
                        </div>
                        <div *ngIf="findParagraphFont(fontPreset) as pPreset" class="font-element paragraph-style">
                            <span [style.font-family]="pPreset.styles.fontFamily">
                                {{ FONT_FAMILY_NAMES[pPreset.font] }}
                            </span>
                            <div>
                                <span class="font-element-size">p</span>
                                <span class="font-element-size">{{ pPreset.styles.fontSize }}</span>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>

    <div *ngIf="isConfigButtons">
        <label>{{ 'theme_settings_buttons' | translate }}</label>
        <a class="dotted pull-right" (click)="openDetailSettingsView(EThemeSettings.buttonsSettings)">
            {{ 'theme_settings_customize' | translate }}
        </a>
        <div class="form-control preset-control" dropdown>
            <div class="button-elements" dropdownToggle>
                <sp-website-button-preset
                    class="button-element"
                    *ngFor="let selectedButtonPreset of selectedThemeButtons$ | async; index as i"
                    [type]="BUTTON_STYLE_TYPE_ARRAY[i]"
                    [selected]="true"
                    [button]="selectedButtonPreset">
                </sp-website-button-preset>
            </div>

            <ul class="dropdown-menu preset-wrapper scroll-wrapper" *dropdownMenu>
                <li *ngFor="let buttonPreset of buttonsPresets$ | async">
                    <div class="button-elements default-elements"
                         [class.selected]="(selectedThemeButtonIndex$ | async) === buttonPreset.index"
                         (click)="selectButtonsThemeHandler(buttonPreset)">
                        <sp-website-button-preset
                            class="button-element"
                            *ngFor="let button of buttonPreset.values; index as i"
                            [type]="BUTTON_STYLE_TYPE_ARRAY[i]"
                            [button]="button">
                        </sp-website-button-preset>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>
