import type { JssStyle } from 'jss';
import type { ColumnStructureEntity, SpecificSectionStructureEntity, SpecificBlocksEntity } from '@web/types';

import { EHorizontalPosition, EVerticalPosition } from '@common/enums';

import { websiteColorCss } from '@libs/themes/src/variants/website/utils/variables';

export function getStyles(
    column: ColumnStructureEntity,
    columnSection: SpecificSectionStructureEntity,
    block: SpecificBlocksEntity,
    isConstructor: boolean = false,
): JssStyle {
    const { data } = column;
    const horizontalPositionMatrix = {
        [EHorizontalPosition.L]: 'flex-start',
        [EHorizontalPosition.M]: 'center',
        [EHorizontalPosition.R]: 'flex-end',
    };
    const verticalPositionMatrix = {
        [EVerticalPosition.T]: 'flex-start',
        [EVerticalPosition.M]: 'center',
        [EVerticalPosition.B]: 'flex-end',
    };

    const horizontalTextPositionMatrix = {
        [EHorizontalPosition.L]: 'left',
        [EHorizontalPosition.M]: 'center',
        [EHorizontalPosition.R]: 'right',
    };

    const columnWidgetsList: JssStyle = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
        height: '100%',
        justifyContent: horizontalPositionMatrix[data.horizontalAlign],
        alignItems: verticalPositionMatrix[columnSection.data.verticalPosition],
        '& .widget-specific-nav-align': {
            justifyContent: horizontalPositionMatrix[data.horizontalAlign] + ' !important',
            '@media (min-width: 993px)': {
                '& .sp-ui-text span': {
                    textAlign: horizontalTextPositionMatrix[data.horizontalAlign] + ' !important',
                },
            },
        },
        '@media (max-width: 992px)': {
            justifyContent: horizontalPositionMatrix[block.data.horizontalPosition],
            alignItems: horizontalPositionMatrix[block.data.horizontalPosition],
            '& .widget-specific-nav-align': {
                justifyContent: horizontalPositionMatrix[block.data.horizontalPosition] + ' !important',
                '& .sp-ui-text p, h1, h2, h3, h4, span, li, em, strong, b': {
                    textAlign: horizontalTextPositionMatrix[block.data.horizontalPosition] + ' !important',
                },
            },
        },
    };

    const columnInner: JssStyle = {
        height: '100%',
    };

    if (isConstructor) {
        columnWidgetsList['&.mobileColumnWidgetsList'] = {
            justifyContent: horizontalPositionMatrix[block.data.horizontalPosition],
            alignItems: horizontalPositionMatrix[block.data.horizontalPosition],
            flexDirection: 'column',
        };
    } else {
        columnWidgetsList['@media (max-width: 992px)'] = {
            justifyContent: horizontalPositionMatrix[block.data.horizontalPosition],
            alignItems: horizontalPositionMatrix[block.data.horizontalPosition],
            flexDirection: 'column',
        };
    }

    return {
        columnInner,
        columnWidgetsList,
    };
}
