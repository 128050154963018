import type { JssStyle } from 'jss';
import type { WebWidgetEntity } from '@web/types';
import type { VideoWidgetData } from '@web/widgets/video/type/video-widget.type';
import { EHorizontalPosition } from '@common/enums';

export function getStyles(widget: WebWidgetEntity<VideoWidgetData>): JssStyle {
    const { styles } = widget;
    const videoWrapper: JssStyle = {
        '& .plyr__poster': {
            backgroundSize: (widget.data?.isShorts && !widget.data.customPreviewFile) ? 'cover' : widget.data.customPreviewSize,
        }
    };
    const video: JssStyle = {};

    let horizontalAlign: string = '';

    switch (widget.data?.horizontalPosition) {
        case EHorizontalPosition.L:
            horizontalAlign = '0 auto 0 0';
            break;
        case EHorizontalPosition.R:
            horizontalAlign = '0 0 0 auto';
            break;
        default:
            horizontalAlign = '0 auto';
    }

    if (!widget.data.videoUrl) {
        video.position = 'absolute';
        video.top = '50%';
        video.left = '50%';
        video.transform = 'translate(-50%, -50%)';
    }

    if (widget.data.videoUrl) {
        video.width = styles.width;
        video.margin = horizontalAlign;
        video.display = styles.display;
        video.maxWidth = widget.data?.maxWidth + 'px';
    }

    if (!widget.data.videoUrl) {
        videoWrapper.height = '200px';
        videoWrapper.background = '#F8FDFE';
        videoWrapper.position = 'relative';
    }

    return {
        videoWrapper,
        video,
    };
}
