import type { SelectedFileExtended } from '@common/types/file.type';
import type { LogoWidgetData } from '@web/widgets/logo';

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpDirectivesModule } from '@common/directives/directives.module';
import { setChatAt } from '@common/helpers/string';

import { getStyles } from '@web/widgets/logo/components/logo-view/logo-view.component.styles';
import { BLANK_LOGO } from '@web/widgets/logo/constants/logo.consts';

import { AbstractWidgetComponent } from '@web-builder/widgets/abstract-widget.component';
import { environment } from '@web-builder-env/environment';

@Component({
    selector: 'web-builder-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, SpDirectivesModule],
})
export class LogoComponent extends AbstractWidgetComponent<LogoWidgetData> {
    protected getStyles = getStyles;

    public srcsetSources: { size: number; media: string; url?: string }[] = [
        {
            size: 720,
            media: '(max-width: 720px)',
        },
        {
            size: 1080,
            media: '(max-width: 1080px)',
        },
        {
            size: 1920,
            media: '(min-width: 1081px)',
        },
    ];

    protected widgetInit() {
        if (!this.image?.hasResize) {
            return;
        }

        const splitImgSrc = this.imgSrc.split('/');
        splitImgSrc.splice(4, 0, 'resize_landing');
        const extensionDotIndex = splitImgSrc[splitImgSrc.length - 1].lastIndexOf('.');
        splitImgSrc[splitImgSrc.length - 1] = setChatAt(splitImgSrc[splitImgSrc.length - 1], extensionDotIndex, '_');

        const res = splitImgSrc.join('/');

        this.srcsetSources = this.srcsetSources.map((srcset) => {
            if (srcset.size >= this.image.imageWidth || this.image.extension.includes('svg')) {
                if (this.image?.hasSourceResize) {
                    return { ...srcset, url: `${res}/source.webp` };
                }

                return { ...srcset, url: this.imgSrc };
            }

            return { ...srcset, url: `${res}/${srcset.size}.webp` };
        });
    }

    public get image(): SelectedFileExtended {
        return this.widgetData.image;
    }

    public get imgSrc(): string {
        if (!this.image) {
            return this.formatImgSrc(BLANK_LOGO);
        }

        return `${environment.fileManagerCdnUrl}${this.image.url}`;
    }

    public get link(): string {
        const trimmedLink = this.image?.link?.trim();

        if (!trimmedLink) {
            return null;
        }

        return this.linkFormatterService.formatLink(trimmedLink);
    }

    public get placeholder(): string {
        return this.image?.name || 'placeholder';
    }
}
