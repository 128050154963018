import { Injectable } from '@angular/core';
import { CatalogProductCard, CatalogProductType, ProductCardWidgetData } from '@web/widgets/product-card/type/product-card-widget.type';
import { concatMap, from, type Observable, of, reduce } from 'rxjs';
import { type ProductCard, ProductType, ProductUnits } from '@common/types/product-card.type';
import { type SelectedFileInterface } from '@sendpulse/file-manager';
import type { SelectedFileExtended } from '@common/types/file.type';
import { extendWebFileDataPromise, preparedSelectedFile } from '@common/helpers';
import { ProductCatalogHttpService } from '@web-builder/core/services/REST/product-catalog.http.service';
import { Widgets } from '@common/enums';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import type { WebWidgetEntity } from '@web/types';
import { TransferStateService } from '@web-builder/core/services/transfer-state.service';
import { environment } from '@web-builder-env/environment';

const catalogUnits = {
    1: ProductUnits.pieces,
    2: ProductUnits.service,
    3: ProductUnits.meter,
    4: ProductUnits.kit,
    5: ProductUnits.l,
    6: ProductUnits.kg,
};

@Injectable({ providedIn: 'root' })
export class ProductCatalogService {
    public transformProductData(catalogProduct: CatalogProductCard, userHash: string, cdn: string): Observable<ProductCard> {
        return from(
            (async (): Promise<ProductCard> => {
                const transformedImages: SelectedFileInterface[] = this.transformImages(catalogProduct.images, userHash, cdn);

                const extendedImages: SelectedFileExtended[] = [];

                for (const file of transformedImages) {
                    const extendedFile = await extendWebFileDataPromise(file, cdn);
                    extendedImages.push(extendedFile);
                }

                return {
                    name: catalogProduct.name,
                    description: catalogProduct.description,
                    shortDescription: catalogProduct.shortDescription,
                    price: catalogProduct.prices[0].value,
                    oldPrice: catalogProduct.prices[0].oldValue,
                    images: extendedImages,
                    sections: catalogProduct.sections.map((section) => ({
                        name: section.name,
                        parameters: section.values.map((param) => ({
                            parameter: param.key,
                            value: param.value,
                        })),
                    })),
                    vendorCode: catalogProduct.vendorCode,
                    quantity: catalogProduct.balance.value,
                    unit: catalogUnits[catalogProduct.balance.type],
                    id: catalogProduct.id.toString(),
                    type: catalogProduct.productType === CatalogProductType.physical ? ProductType.physical : ProductType.digital,
                    variants: [],
                    properties: [],
                    catalogProductId: catalogProduct.id,
                    catalogCategoryName: catalogProduct.categoryName,
                };
            })(),
        ) as Observable<ProductCard>;
    }

    public transformImages(images: string[], userHash: string, cdn: string): SelectedFileInterface[] {
        if (!images.length) return [];

        let transformedArr: SelectedFileInterface[] = [];

        images.forEach((path) => {
            transformedArr.push(preparedSelectedFile(path, userHash, cdn));
        });

        return transformedArr;
    }

    public adaptCatalogWidgetsData(widgets: WebWidgetEntity[]): Observable<WebWidgetEntity[]> {
        const date = this.transferStateService.get('site')?.pub_date;
        const userId = this.transferStateService.get('userId');

        if (!widgets?.length || !date || !userId) {
            return of(widgets).pipe(take(1));
        }

        const productCatalogCardWidgets = this.filterProductCardWidgets(widgets);

        if (!productCatalogCardWidgets.length) {
            return of(widgets).pipe(take(1));
        }

        return this.getUpdatedProductIds(userId, date).pipe(
            take(1),
            catchError(() => of([])),
            switchMap((updatedProductIds) => {
                const matchingWidgets = this.getMatchingWidgets(productCatalogCardWidgets, updatedProductIds);

                if (!matchingWidgets.length) {
                    return of(widgets).pipe(take(1));
                }

                return this.updateWidgetsData(widgets, matchingWidgets, userId);
            }),
            catchError(() => of(widgets)),
        );
    }

    private filterProductCardWidgets(widgets: WebWidgetEntity[]): WebWidgetEntity[] {
        return widgets.filter(
            (widget) => widget.name === Widgets.PRODUCT_CARD && (widget?.data as ProductCardWidgetData)?.element?.catalogProductId,
        );
    }

    private getUpdatedProductIds(userId: string, date: string): Observable<number[]> {
        return this.productCatalogHttpService.getAllCatalogProducts(userId, { fromUpdatedAt: date, limit: 300, offset: 0 }).pipe(
            take(1),
            map(({ products }) => products.map((product) => product.id)),
            catchError(() => of([])),
        );
    }

    private getMatchingWidgets(productCatalogCardWidgets: WebWidgetEntity[], updatedProductIds: number[]): WebWidgetEntity[] {
        return productCatalogCardWidgets.filter((widget) =>
            updatedProductIds.includes((widget?.data as ProductCardWidgetData)?.element?.catalogProductId),
        );
    }

    private updateWidgetsData(
        widgets: WebWidgetEntity[],
        matchingWidgets: WebWidgetEntity[],
        userId: string,
    ): Observable<WebWidgetEntity[]> {
        return this.productCatalogHttpService
            .getAllCatalogProducts(userId, {
                limit: 300,
                offset: 0,
                ids: matchingWidgets.map((widget) => (widget?.data as ProductCardWidgetData)?.element?.catalogProductId),
            })
            .pipe(
                take(1),
                switchMap(({ products }) => {
                    if (!products || !products.length) {
                        return of(widgets);
                    }

                    return from(products).pipe(
                        concatMap((product) => {
                            const widgetToUpdate = widgets.find(
                                (widget) =>
                                    widget.name === Widgets.PRODUCT_CARD &&
                                    (widget?.data as ProductCardWidgetData)?.element?.catalogProductId === product.id,
                            );

                            if (!widgetToUpdate) {
                                return of(null);
                            }

                            return this.transformProductData(
                                product,
                                widgetToUpdate.data.element.userHash,
                                environment.fileManagerCdnUrl,
                            ).pipe(
                                map((transformedProduct) => {
                                    widgetToUpdate.data.element = {
                                        ...widgetToUpdate.data.element,
                                        ...transformedProduct,
                                    };

                                    return widgetToUpdate;
                                }),
                                catchError(() => {
                                    return of(null);
                                }),
                            );
                        }),
                        filter((processedWidget) => !!processedWidget),
                        reduce(
                            (updatedWidgets, processedWidget) => {
                                const index = widgets.findIndex((widget) => widget === processedWidget);
                                if (index !== -1) {
                                    updatedWidgets[index] = processedWidget;
                                }
                                return updatedWidgets;
                            },
                            [...widgets],
                        ),
                        catchError(() => {
                            return of(widgets);
                        }),
                    );
                }),
            );
    }

    constructor(
        private readonly productCatalogHttpService: ProductCatalogHttpService,
        private readonly transferStateService: TransferStateService,
    ) {}
}
