<div class="btn-group btn-group-justified" data-toggle="buttons">
    <div *ngFor="let option of options" class="btn-group">
        <button
            class="btn btn-grey radio-button"
            [disabled]="disabled"
            [class.active]="selected === option"
            (click)="changeSelectedHandler($event, option)"
        >
            <ng-container *ngIf="type === ERadioGroupType.size">
                <img [src]="getSizeImage(option).src" [alt]="getSizeImage(option).alt">
            </ng-container>

            <ng-container *ngIf="type === ERadioGroupType.menu">
                <img [src]="getMenuImage(option).src" [alt]="getMenuImage(option).alt">
            </ng-container>

            <ng-container *ngIf="type === ERadioGroupType.logoPosition">
                <img [src]="getMenuLogoPosition(option).src" [alt]="getMenuLogoPosition(option).alt">
            </ng-container>

            <ng-container *ngIf="type === ERadioGroupType.faqIconStyle">
                <img [src]="getFaqIconStyleImage(option).src" [alt]="getFaqIconStyleImage(option).alt">
            </ng-container>

            <ng-container *ngIf="type === ERadioGroupType.faqIconPosition">
                <img [src]="getFaqIconPositionImage(option).src" [alt]="getFaqIconPositionImage(option).alt">
            </ng-container>

            <ng-container *ngIf="type === ERadioGroupType.horizontalPosition">
                <img [src]="getHorizontalPositionImage(option).src" [alt]="getHorizontalPositionImage(option).alt">
            </ng-container>

            <ng-container *ngIf="type === ERadioGroupType.verticalPosition">
                <img [src]="getVerticalPositionImage(option).src" [alt]="getVerticalPositionImage(option).alt">
            </ng-container>

            <ng-container *ngIf="type === ERadioGroupType.loginDefaultForm">
                <img [src]="getEduLoginViewImage(option).src" [alt]="getEduLoginViewImage(option).alt">
            </ng-container>
        </button>
    </div>

</div>
