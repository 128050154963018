<div class="sp-video-root" [class]="classes?.videoWrapper">
    <div [class]="classes?.video">
        <div
            *ngIf="this.videoUrl"
            class="sp-video-player"
            spPlayer
            [playerSources]="videoSource"
            [playerPoster]="this.widgetData.customPreviewUrl ? this.widgetData.customPreviewUrl : ''"
            [isShorts]="this.widgetData?.isShorts"
            style="width: 100%"
        >
        </div>

        <div *ngIf="!this.videoUrl" class="sp-video-blank" [class]="classes?.blankImageWrapper">
            <img [class]="classes?.video" [src]="blankVideoImg" alt="gallery_blank">
        </div>
    </div>
</div>
