import { Observable, shareReplay } from 'rxjs';
import type { CatalogProductCard } from '@web/widgets/product-card/type/product-card-widget.type';
import type { HttpDataResponse } from '@common/types/http-response.type';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { TransferStateService } from '@web-builder/core/services/transfer-state.service';
import { environment } from '@web-builder-env/environment';
import { SKIP_AUTH } from '@web-builder/core/interceptors/jwt.interceptor';

type PayloadData = {
    categoryId?: number;
    search?: string;
    offset?: number;
    limit?: number;
    fromUpdatedAt?: string;
    ids?: number[];
};

@Injectable({
    providedIn: 'root',
})
export class ProductCatalogHttpService {
    private readonly host = environment.proxyUrl;
    private readonly baseLandingApiUrl = '/api/landings-service';

    constructor(private http: HttpClient, private transferStateService: TransferStateService) {}

    private get context() {
        return new HttpContext().set(SKIP_AUTH, true);
    }

    getFormatLandingUrl(url: string = ''): string {
        if (environment.type === 'dev') {
            return `https://stage.sendpulse.test${this.baseLandingApiUrl}${url}`;
        }

        return `${this.host}${this.baseLandingApiUrl}${url}`;
    }

    public getProductById(userId: string, productId: number): Observable<CatalogProductCard> {
        return this.http
            .get<HttpDataResponse<CatalogProductCard>>(this.getFormatLandingUrl(`/public/crm/users/${userId}/products/${productId}`), {
                context: this.context,
            })
            .pipe(
                shareReplay(),
                map((res) => res.data),
            );
    }

    public getAllCatalogProducts(
        userId: string,
        payloadData: PayloadData = {
            categoryId: null,
            search: '',
            offset: 0,
            limit: 20,
        },
    ): Observable<{ products: CatalogProductCard[]; total: number }> {
        const { categoryId, search, offset, limit, fromUpdatedAt, ids } = payloadData;
        return this.http
            .post<{ data: CatalogProductCard[]; total: number }>(this.getFormatLandingUrl(`/public/crm/users/${userId}/products/all`), {
                orderBy: {
                    fieldName: 'id',
                    direction: 'asc',
                },
                limit,
                offset,
                categoryId,
                search,
                fromUpdatedAt,
                ids,
            })
            .pipe(
                shareReplay(),
                map(({ data, total }) => ({
                    products: data,
                    total: total,
                })),
            );
    }

    public resizeFile(url: string, path: string): Observable<any> {
        return this.http
            .post(`${this.transferStateService.getResourceUrl()}/api/landings-service/files/resize`, { url, path })
            .pipe(map((res: HttpDataResponse<any>) => res));
    }
}
