<div class="block-container" style="position: relative">
    <div
        *ngIf="block.data.pinOnTheTop"
        class="pined-block-placeholder"
        [class.hideDesktop]="block.data.hideOnDesktop"
        [class.hideMobile]="block.data.hideOnMobile"
        [style.height.px]="pinedTopBlockHeight"
        [style.background-color]="backgroundColor"
    ></div>
    <div
        *ngIf="block.data.pinOnTheBottom"
        class="pined-block-placeholder"
        [class.hideDesktop]="block.data.hideOnDesktop"
        [class.hideMobile]="block.data.hideOnMobile"
        [style.height.px]="pinedBottomBlockHeight"
        [style.background-color]="backgroundColor"
    ></div>
    <div
        [id]="blockId"
        class="block sp-block"
        [class]="isBrowser ? classes?.blockWrap : classes?.blockWrapServer"
        [class.pined-on-top-block]="block.data.pinOnTheTop"
        [class.not-pined-on-top-block]="!block.data.pinOnTheTop"
        [class.pined-on-bottom-block]="block.data.pinOnTheBottom"
        [class.not-pined-on-bottom-block]="!block.data.pinOnTheBottom">

        <web-builder-block-shape-dividers [block]="block"></web-builder-block-shape-dividers>

        <div #blockBg [class]="classes?.blockBg" *ngIf="!isParallax">
            <video class="desktop-video" style="width: 100%; height: 100%; object-fit: cover;"
                *ngIf="block.data?.backgroundVideo"
                [autoplay]="true" [muted]="true" [loop]="true" muted autoplay playsinline
                [src]="getBackgroundVideoUrl()">
            </video>

            <video class="mobile-video" style="width: 100%; height: 100%; object-fit: cover;"
                *ngIf="block.mobileData?.backgroundVideo"
                [autoplay]="true" [muted]="true" [loop]="true" muted autoplay playsinline
                [src]="getMobileBackgroundVideoUrl()">
            </video>
        </div>

        <div #blockBg class="parallax-container" *ngIf="isParallax">
            <div class="background-parallax">
                <div class="parallax-wrapper">
                    <div #parallax class="parallax">
                        <div [class]="classes?.blockBg" class="background" ></div>
                    </div>
                </div>
            </div>
        </div>

        <div [class]="classes?.blockInner">
            <ng-content></ng-content>
        </div>
    </div>
</div>
