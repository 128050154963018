import type { JssStyle } from 'jss';
import type { WebWidgetEntity } from '@web/types';
import type { MenuWidgetData } from '@web/widgets/menu/type/menu-widget.type';

import { EFontStyle, EHorizontalPosition, EMenuLayoutType, EMenuLogoPosition, ESize } from '@common/enums';
import { EffectTypes } from '@web/shared/components/web-hover-effects/types';
import { websiteColorCss } from '@libs/themes';

const DEFAULT_HOVER_EFFECTS = {
    type: EffectTypes.transparency,
    transparency: 80,
    lineWidth: 1,
    color: '#000',
    lineType: 'solid',
    animationType: 'moving_up',
};

function getAlignMenuList(elementData: MenuWidgetData): JssStyle {
    switch (elementData.horizontalPosition) {
        case EHorizontalPosition.L:
            return { justifyContent: 'flex-start' };
        case EHorizontalPosition.M:
            return { justifyContent: 'center' };
        case EHorizontalPosition.R:
            return { justifyContent: 'flex-end' };
        default:
            return { justifyContent: 'normal' };
    }
}

function getMenuListMargin(elementData: MenuWidgetData): JssStyle {
    if (elementData.layoutDirection === EMenuLayoutType.HORIZONTAL) {
        switch (elementData?.menuItemIndent) {
            case ESize.M:
                return { margin: '0 -15px' };
            case ESize.L:
                return { margin: '0 -20px' };
            case ESize.S:
            default:
                return { margin: '0 -10px' };
        }
    } else {
        switch (elementData?.menuItemIndent) {
            case ESize.M:
                return { marginTop: '-15px', marginRight: '-15px', marginBottom: '-15px', marginLeft: '-15px' };
            case ESize.L:
                return { marginTop: '-20px', marginRight: '-20px', marginBottom: '-20px', marginLeft: '-20px' };
            case ESize.S:
            default:
                return { marginTop: '-10px', marginRight: '-10px', marginBottom: '-10px', marginLeft: '-10px' };
        }
    }
}

function getItemsIndent(elementData: MenuWidgetData): JssStyle {
    if (elementData.layoutDirection === EMenuLayoutType.HORIZONTAL) {
        switch (elementData?.menuItemIndent) {
            case ESize.S:
                return { padding: '10px' };
            case ESize.M:
                return { padding: '10px 15px' };
            case ESize.L:
                return { padding: '10px 20px' };
            default:
                return { padding: '10px' };
        }
    } else {
        switch (elementData?.menuItemIndent) {
            case ESize.S:
                return { padding: '10px' };
            case ESize.M:
                return { padding: '15px' };
            case ESize.L:
                return { padding: '20px' };
            default:
                return { padding: '10px' };
        }
    }
}

function getItemsAlign(elementData: MenuWidgetData): JssStyle {
    switch (elementData.horizontalPosition) {
        case EHorizontalPosition.L:
            return { textAlign: 'left' };
        case EHorizontalPosition.M:
            return { textAlign: 'center' };
        case EHorizontalPosition.R:
            return { textAlign: 'right' };
        default:
            return { textAlign: 'center' };
    }
}

function getAlignSubmenuList(elementData: MenuWidgetData): JssStyle {
    switch (elementData.horizontalPosition) {
        case EHorizontalPosition.L:
            return { left: '0', right: 'auto' };
        case EHorizontalPosition.M:
            return { left: '0', right: 'auto' };
        case EHorizontalPosition.R:
            return { left: 'auto', right: '0' };
        default:
            return { left: '0', right: 'auto' };
    }
}

function getDirection(elementData: MenuWidgetData): JssStyle {
    switch (elementData.layoutDirection) {
        case EMenuLayoutType.HORIZONTAL:
            return { flexDirection: 'row' };
        case EMenuLayoutType.VERTICAL:
            return { flexDirection: 'column' };
        default:
            return { justifyContent: 'initial' };
    }
}

function getLogoPosition(elementData: MenuWidgetData): JssStyle {
    switch (elementData.logoPosition) {
        case EMenuLogoPosition.LEFT:
            return { flexDirection: 'row' };
        case EMenuLogoPosition.CENTER:
            return { flexDirection: 'column' };
        case EMenuLogoPosition.RIGHT:
            return { flexDirection: 'row-reverse' };
        default:
            return { flexDirection: 'row' };
    }
}

function getLogoMargin(elementData: MenuWidgetData): JssStyle {
    switch (elementData.logoPosition) {
        case EMenuLogoPosition.LEFT:
            return { marginRight: '48px' };
        case EMenuLogoPosition.CENTER:
            return { marginBottom: '16px' };
        case EMenuLogoPosition.RIGHT:
            return { marginLeft: '48px' };
        default:
            return { marginRight: '48px' };
    }
}

// function getMobileLogoMargin(elementData: MenuWidgetData): JssStyle {
//     if (elementData.showIconOnMobile) {
//         return {
//             '@media (max-width: 992px)': {
//                 margin: '0',
//             },
//         };
//     } else {
//         switch (elementData.logoPosition) {
//             case EMenuLogoPosition.LEFT:
//                 return { '@media (max-width: 992px)': { marginRight: '24px' } };
//             case EMenuLogoPosition.CENTER:
//                 return { '@media (max-width: 992px)': { marginBottom: '16px' } };
//             case EMenuLogoPosition.RIGHT:
//                 return { '@media (max-width: 992px)': { marginLeft: '24px' } };
//             default:
//                 return { '@media (max-width: 992px)': { marginRight: '24px' } };
//         }
//     }
// }

function getMobileLogoMargin(elementData: MenuWidgetData): JssStyle {
    if (elementData.showIconOnMobile) {
        return {
            margin: '0',
        };
    } else {
        switch (elementData.logoPosition) {
            case EMenuLogoPosition.LEFT:
                return { marginRight: '24px' };
            case EMenuLogoPosition.CENTER:
                return { marginBottom: '16px' };
            case EMenuLogoPosition.RIGHT:
                return { marginLeft: '24px' };
            default:
                return { marginRight: '24px' };
        }
    }
}

// function getMobileMenuLayout(elementData: MenuWidgetData): JssStyle {
//     if (elementData.showIconOnMobile && elementData.isLogo) {
//         switch (elementData.iconPosition) {
//             case EHorizontalPosition.L:
//                 return {
//                     '@media (max-width: 992px)': {
//                         flexDirection: 'row-reverse',
//                         justifyContent: 'space-between',
//                         alignItems: 'center',
//                     },
//                 };
//             case EHorizontalPosition.R:
//                 return { '@media (max-width: 992px)': { flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' } };
//             default:
//                 return {
//                     '@media (max-width: 992px)': {
//                         flexDirection: 'row-reverse',
//                         justifyContent: 'space-between',
//                         alignItems: 'center',
//                     },
//                 };
//         }
//     } else if (elementData.showIconOnMobile && !elementData.isLogo) {
//         switch (elementData.iconPosition) {
//             case EHorizontalPosition.L:
//                 return {
//                     '@media (max-width: 992px)': {
//                         flexDirection: 'row',
//                         justifyContent: 'space-between',
//                         alignItems: 'flex-start',
//                     },
//                 };
//             case EHorizontalPosition.R:
//                 return {
//                     '@media (max-width: 992px)': {
//                         flexDirection: 'row-reverse',
//                         justifyContent: 'space-between',
//                         alignItems: 'flex-start',
//                     },
//                 };
//             default:
//                 return {
//                     '@media (max-width: 992px)': {
//                         flexDirection: 'row',
//                         justifyContent: 'space-between',
//                         alignItems: 'flex-start',
//                     },
//                 };
//         }
//     }
// }

function getMobileMenuLayout(elementData: MenuWidgetData): JssStyle {
    if (elementData.showIconOnMobile && elementData.isLogo) {
        switch (elementData.iconPosition) {
            case EHorizontalPosition.L:
                return {
                    flexDirection: 'row-reverse',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                };
            case EHorizontalPosition.R:
                return {
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                };
            default:
                return {
                    flexDirection: 'row-reverse',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                };
        }
    } else if (elementData.showIconOnMobile && !elementData.isLogo) {
        switch (elementData.iconPosition) {
            case EHorizontalPosition.L:
                return {
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                };
            case EHorizontalPosition.R:
                return {
                    flexDirection: 'row-reverse',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                };
            default:
                return {
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                };
        }
    }
}

function getMobileIconMargin(elementData: MenuWidgetData): JssStyle {
    switch (elementData.iconPosition) {
        case EHorizontalPosition.L:
            return { marginRight: '10px' };
        case EHorizontalPosition.R:
            return { marginLeft: '10px' };
        default:
            return { marginRight: '10px' };
    }
}

function getFontStyles({ fontStile }: MenuWidgetData): JssStyle {
    return {
        fontWeight: fontStile.includes(EFontStyle.B) ? 'bold' : 'initial',
        fontStyle: fontStile.includes(EFontStyle.I) ? 'italic' : 'initial',
        textTransform: fontStile.includes(EFontStyle.AT) ? 'uppercase' : 'initial',
    };
}

function getSubmenuFontStyles({ submenuFontStile }: MenuWidgetData): JssStyle {
    if (!submenuFontStile) {
        return {};
    } else {
        return {
            fontWeight: submenuFontStile.includes(EFontStyle.B) ? 'bold' : 'initial',
            fontStyle: submenuFontStile.includes(EFontStyle.I) ? 'italic' : 'initial',
            textTransform: submenuFontStile.includes(EFontStyle.AT) ? 'uppercase' : 'initial',
        };
    }
}

function getHoverEffectsStyles({ hoverEffects }: MenuWidgetData): JssStyle {
    switch (hoverEffects.type) {
        case EffectTypes.color:
            return {
                color: websiteColorCss(hoverEffects.color),
            };
        case EffectTypes.underline:
            return {
                '&:after': {
                    opacity: 1,
                },
            };
        case EffectTypes.transparency:
            return {
                opacity: hoverEffects.transparency / 100,
            };
        default:
            return {};
    }
}

function getPseudoUnderline({ hoverEffects }: MenuWidgetData): JssStyle {
    if (hoverEffects.type === EffectTypes.underline) {
        return {
            '&:after': {
                content: '""',
                position: 'absolute',
                bottom: '-12px',
                right: 0,
                left: 0,
                height: '1px',
                borderBottom: `${hoverEffects.lineWidth}px ${hoverEffects.lineType} ${websiteColorCss(hoverEffects.color)}`,
                transition: '0.2s ease-in-out',
                opacity: 0,
            },
        };
    } else {
    }
}

export function getStyles({ data }: WebWidgetEntity<MenuWidgetData>, { isConstructor }: any): JssStyle {
    const menuWrapperStyles: any = {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        transform: `rotate(${data?.position?.rotate ?? 0}deg)`,
        zIndex: data?.position?.zIndex,
        ...getLogoPosition(data),
        '@media (max-width: 992px)': {
            ...getMobileMenuLayout(data),
            zIndex: data?.position?.mobileZIndex,
            transform: `rotate(${data?.position?.mobileRotate ?? 0}deg)`,
        },
        // '@media (max-width: 992px) and (orientation: landscape)': {
        //     ...getMobileMenuLayout(data),
        //     zIndex: data?.position?.mobileZIndex,
        //     transform: `rotate(${data?.position?.mobileRotate ?? 0}deg)`,
        // },
        '&.mobileWidgetViewWrap': {
            ...getMobileMenuLayout(data),
            zIndex: data?.position?.mobileZIndex,
            transform: `rotate(${data?.position?.mobileRotate ?? 0}deg)`,
        },
        '&:hover': {
            zIndex: data?.position?.zIndex ? (data?.position?.zIndex + 1) : 1,
        }
    };

    if (!isConstructor) {
        if (data?.hideOnMobile && !data?.hideOnDesktop) {
            menuWrapperStyles['@media (max-width: 992px)'] = { display: 'none' };
        }

        if (!data?.hideOnMobile && data?.hideOnDesktop) {
            menuWrapperStyles['@media (min-width: 993px)'] = { display: 'none' };
        }

        if (data?.hideOnMobile && data?.hideOnDesktop) {
            menuWrapperStyles.display = 'none';
        }
    }

    if (isConstructor) {
        menuWrapperStyles['&.mobileWidgetViewWrap'].transform = `rotate(${data?.position?.mobileRotate ?? 0}deg)`;
    }

    return {
        'menu-wrapper': menuWrapperStyles,
        // 'menu-wrapper': {
        //     display: 'flex',
        //     alignItems: 'center',
        //     ...getLogoPosition(data),
        //     ...getMobileMenuLayout(data),
        // },
        'menu-logo': {
            maxWidth: data.logoWidth + 'px',
            ...getLogoMargin(data),
            ...getMobileLogoMargin(data),
            '@media (max-width: 992px)': {
                ...getMobileLogoMargin(data),
            },
            // '@media (max-width: 992px) and (orientation: landscape)': {
            //     ...getMobileLogoMargin(data),
            // },
            '&.mobileWidgetViewElement': {
                ...getMobileLogoMargin(data),
            },
            '& img': {
                display: 'block',
                width: '100%',
            },
        },
        // 'menu-logo': {
        //     maxWidth: data.logoWidth + 'px',
        //     ...getLogoMargin(data),
        //     ...getMobileLogoMargin(data),
        //     '& img': {
        //         display: 'block',
        //         width: '100%',
        //     },
        // },
        'menu-list-wrapper': {
            display: 'flex',
            width: '100%',
            ...getAlignMenuList(data),
            '@media (max-width: 992px)': {
                display: data.showIconOnMobile ? 'none' : 'flex',
            },
            // '@media (max-width: 992px) and (orientation: landscape)': {
            //     display: data.showIconOnMobile ? 'none' : 'flex',
            // },
            '&.mobileWidgetViewElement': {
                display: data.showIconOnMobile ? 'none' : 'flex',
            },
        },
        'menu-list': {
            paddingLeft: 0,
            marginBottom: 0,
            listStyleType: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            ...getDirection(data),
            ...getAlignMenuList(data),
            ...getMenuListMargin(data),
            '& li': {
                position: 'relative',
                '& span': {
                    ...getItemsIndent(data),
                    ...getItemsAlign(data),
                    display: 'block',
                    '& a': {
                        fontSize: `${data.fontSize}px`,
                        ...getFontStyles(data),
                        color: data.textColor,
                        position: 'relative',
                        transition: '0.2s ease-in-out',
                        '&:hover': {
                            ...getHoverEffectsStyles({
                                ...data,
                                hoverEffects: data.hoverEffects ?? DEFAULT_HOVER_EFFECTS,
                            }),
                            textDecoration: 'none',
                        },
                        ...getPseudoUnderline({
                            ...data,
                            hoverEffects: data.hoverEffects ?? DEFAULT_HOVER_EFFECTS,
                        }),
                        '& .caret-icon': {
                            display: 'inline-block',
                            marginLeft: '4px',
                        },
                    },
                },

                '& .dropdown-submenu-list': {
                    position: 'absolute',
                    top: 'calc(100% - 5px)',
                    ...getAlignSubmenuList(data),
                    zIndex: 999999,
                    padding: '4px',
                    backgroundColor: data.submenuListBackground,
                    borderRadius: '6px',
                    boxShadow: '0px 6px 12px 0px rgba(0, 0, 0, 0.12)',
                    display: 'none',
                    listStyleType: 'none',
                    '& li': {
                        marginBottom: '0px',
                        '& span': {
                            ...getItemsAlign(data),
                            display: 'block',
                            '& a': {
                                fontSize: `${data.submenuFontSize}px`,
                                ...getSubmenuFontStyles(data),
                                color: data.submenuTextColor,
                                position: 'relative',
                                transition: '0.2s ease-in-out',
                                textWrap: 'nowrap',
                                '&:hover': {
                                    opacity: '0.8',
                                    textDecoration: 'none',
                                    '&:after': {
                                        display: 'none',
                                    },
                                },
                            },
                        },
                    },
                },

                '&:hover': {
                    '& .dropdown-submenu-list': {
                        display: 'block',
                    },
                },
            },
            '&.v-list': {
                flexDirection: 'column',
            },
        },
        'mobile-icon': {
            '&:before': {
                backgroundColor: data.iconColor,
            },
            '&:after': {
                backgroundColor: data.iconColor,
            },
            '@media (max-width: 992px)': {
                ...getMobileIconMargin(data),
                display: data.showIconOnMobile ? 'flex !important' : 'none',
            },
            // '@media (max-width: 992px) and (orientation: landscape)': {
            //     ...getMobileIconMargin(data),
            //     display: data.showIconOnMobile ? 'flex !important' : 'none',
            // },
            '&.mobileWidgetViewElement': {
                ...getMobileIconMargin(data),
                display: data.showIconOnMobile ? 'flex !important' : 'none',
            },
        },
        'mobile-icon-lines': {
            backgroundColor: data.iconColor,
        },
        'mobile-menu-list': {
            '& li': {
                position: 'relative',
                '& span': {
                    '& a': {
                        color: data.textColor,
                        '&:hover': {
                            color: data.textColor,
                            opacity: '0.8',
                            textDecoration: 'none',
                        },
                    },
                },
                '& .caret-icon': {
                    position: 'absolute',
                    top: '0px',
                    right: '0px',
                    padding: '10px',
                    color: data.textColor,
                    '&:hover': {
                        cursor: 'pointer',
                        opacity: '0.8',
                    },
                },
                '& .dropdown-submenu-list': {
                    visibility: 'hidden',
                    opacity: 0,
                    height: 0,
                    listStyleType: 'none',
                    paddingLeft: '15px',
                    '& li': {
                        marginBottom: '0px',
                    },
                },
                '&.open': {
                    '& .dropdown-submenu-list': {
                        visibility: 'visible',
                        opacity: 1,
                        height: 'auto',
                    },
                    '& .caret-icon': {
                        rotate: '180deg',
                    },
                },
            },
        },
    };
}
