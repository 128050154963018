import type { JssStyle } from 'jss';
import type { WebWidgetEntity } from '@web/types';
import type { GalleryWidgetData } from '@web/widgets/gallery/type/gallery-widget.type';
import {
    type CarouselOptions,
    type CollageOptions,
    type CollageType,
    type GridOptions,
} from '@web/widgets/gallery/type/gallery-widget.type';
import { EConditionType, EFontStyle, EGalleryTypes, EHorizontalPosition, EOverlayType, type ESize, EVerticalPosition } from '@common/enums';
import { type SelectedFileExtended } from '@common/types/file.type';
import { commonFontSizeCoefficient, webPStyles } from '@common/libs/themes/src/variants/website/styles/text.styles';
import { collageMetadata } from '@web/widgets/gallery/model/defaultOptions';

interface GalleryReturnType {
    gridContainer: JssStyle;
    gridItem: JssStyle;
    gridImage: JssStyle;
    imageWrapper: JssStyle;
    [other: string]: JssStyle;
}

const textPosition = {
    [EHorizontalPosition.L]: 'left',
    [EHorizontalPosition.M]: 'center',
    [EHorizontalPosition.R]: 'right',
};

function getFontStyles(fontStyle: EFontStyle[]): JssStyle {
    return {
        fontWeight: fontStyle?.includes(EFontStyle.B) ? 'bold' : 'initial',
        fontStyle: fontStyle?.includes(EFontStyle.I) ? 'italic' : 'initial',
        textTransform: fontStyle?.includes(EFontStyle.AT) ? 'uppercase' : 'initial',
    };
}

function getDescriptionStyles(image: SelectedFileExtended): JssStyle {
    let commonDescription: JssStyle = {
        position: 'absolute',
        width: '100%',
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        wordBreak: 'break-word',
        boxSizing: 'border-box',
        padding: image?.description ? `${image.paddingTop}px ${image.paddingRight}px ${image.paddingBottom}px ${image.paddingLeft}px` : '0',
        background: image?.textBackground,
    };

    if (image?.condition === EConditionType.ON_HOVER && image?.isImageEffect) {
        commonDescription = {
            ...commonDescription,
            ...{
                opacity: 0,
                transition: 'opacity 1s linear',
            },
        };
    }

    if (!image?.description) {
        return {
            ...commonDescription,
            bottom: 0,
            left: 0,
        };
    }

    // TODO: REFACTOR TO FLEX
    const descriptionPositionStyles: Record<EVerticalPosition, JssStyle> = {
        [EVerticalPosition.B]: {
            bottom: 0,
            left: 0,
        },
        [EVerticalPosition.M]: {
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
        [EVerticalPosition.T]: {
            top: 0,
            left: 0,
        },
    };
    return {
        ...commonDescription,
        ...descriptionPositionStyles[image.textPosition],
    };
}

function calcFontSize(size: ESize): string {
    return `calc(${webPStyles.fontSize} * ${commonFontSizeCoefficient[size]})`;
}

const justifyStyles = {
    [EHorizontalPosition.L]: 'flex-start',
    [EHorizontalPosition.M]: 'center',
    [EHorizontalPosition.R]: 'flex-end',
};

function getTextStyles(image: SelectedFileExtended): JssStyle {
    if (!image?.description) {
        return {};
    }

    const fontSizeCoefficient: number = commonFontSizeCoefficient[image.textSize];
    const scaledFontSize: string = image.fontSize.toString();
    const fontStyles: JssStyle = getFontStyles(image?.textStyle);
    const textStyles: JssStyle = { ...webPStyles, fontSize: scaledFontSize + 'px', color: image.textColor };
    const marginLeft: string = image.link ? '16px' : '0';
    const marginRight: string = image.link ? `calc(${webPStyles.fontSize} * ${fontSizeCoefficient} + 15px)` : '0';

    return {
        display: 'flex',
        flex: '1',
        justifyContent: justifyStyles[image.textJustify],
        textAlign: textPosition[image.textJustify],
        marginRight,
        marginLeft,
        ...textStyles,
        ...fontStyles,
    };
}

function getImageMaskStyles(image: SelectedFileExtended): JssStyle {
    let imageMaskStyles = {};

    if (!image) {
        return imageMaskStyles;
    }

    if (image?.maskShape) {
        imageMaskStyles = { WebkitMaskImage: `url('assets/img/websites/mask-shapes/${image?.maskShape}.svg')` };
    }

    return imageMaskStyles;
}

const mobileMediaQuery = '@media (max-width: 992px)';

const commonCollageTransition = {
    transitionProperty: 'margin, padding, row-gap',
    transitionDuration: '0.25s',
    transitionTimingFunction: 'ease-out',
};

const commonImageStyles = (imagesLength: number, borderRadius: number, clickable: boolean) => {
    const styles: Record<'gridImage' | 'imageWrapper' | 'thumbWrapper' | 'thumbImage', JssStyle> = {
        gridImage: imagesLength
            ? {
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  inset: 0,
                  objectFit: 'cover',
                  userSelect: 'none',
              }
            : { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' },
        imageWrapper: {
            position: 'relative',
            overflow: 'hidden',
            height: '100%',
            borderRadius,
            paddingTop: '100%',
            cursor: clickable ? 'pointer' : 'default',
        },
        thumbWrapper: {
            position: 'relative',
            overflow: 'hidden',
            height: '100%',
            borderRadius,
        },
        thumbImage: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            userSelect: 'none',
        },
    };

    if (!imagesLength) {
        styles.imageWrapper.height = '200px';
        styles.imageWrapper.background = '#F8FDFE';
    }

    return styles;
};

const getImageStyle = (image: SelectedFileExtended): JssStyle => {
    let imageStyles: JssStyle = {};

    if (image?.isImageEffect) {
        if (image?.overlay === EOverlayType.INCREASE) {
            imageStyles = {
                ...imageStyles,
                ...{
                    transition: 'all .3s ease-in-out',
                    '&:hover': {
                        transform: 'scale(1.1)',
                    },
                },
            };
        }

        if (image?.overlay === EOverlayType.DECREASE) {
            imageStyles = {
                ...imageStyles,
                ...{
                    transition: 'all .3s ease-in-out',
                    '&:hover': {
                        transform: 'scale(0.9)',
                    },
                },
            };
        }
    }

    return imageStyles;
};

const defaultDescriptionStyles = (widgetData: GalleryWidgetData): Record<string, JssStyle> => {
    const styles = {};

    widgetData.images.forEach((image, index) => {
        styles[`imageDescription${index}`] = getDescriptionStyles(image);
        styles[`text${index}`] = getTextStyles(image);
        styles[`imageMask${index}`] = getImageMaskStyles(image);
        styles[`image${index}`] = getImageStyle(image);
    });
    return styles;
};

const masonryDescriptionStyles = (widgetData: GalleryWidgetData): Record<string, JssStyle> => {
    const columns = (widgetData.options as CollageOptions).style === '2col-2row' ? 2 : 3;
    const arr = [...Array(columns)].map(() => []);

    for (let i = 0; i < widgetData.images.length; i++) {
        arr[i % columns].push(widgetData.images[i]);
    }

    const styles = {};
    arr.forEach((column, columnIndex) => {
        column.forEach((image, imageIndex) => {
            styles[`imageDescription${columnIndex}-${imageIndex}`] = getDescriptionStyles(image);
            styles[`text${columnIndex}-${imageIndex}`] = getTextStyles(image);
            styles[`imageMask${columnIndex}-${imageIndex}`] = getImageMaskStyles(image);
            styles[`image${columnIndex}-${imageIndex}`] = getImageStyle(image);
        });
    });

    return styles;
};

const calcImageRemainder = (style: CollageType, imagesCount: number) => imagesCount % collageMetadata[style]?.itemPerLine;

const generateCollageTailStyles = (style: CollageType, imagesRemainder: number): JssStyle => {
    const { gridColumns } = collageMetadata[style];

    const styles = {};
    if (imagesRemainder === 0) return styles;

    for (let i = 0; i < imagesRemainder; i++) {
        styles[`&:nth-last-child(${i + 1})`] = {
            gridRow: 'auto !important',
            gridColumn: `span ${gridColumns / imagesRemainder} !important`,
        };
    }

    return styles;
};

const collageStyles = (widgetData: GalleryWidgetData): GalleryReturnType => {
    const { imageGap: gap, borderRadius, style, clickable } = widgetData.options as CollageOptions;
    const { gridColumns } = collageMetadata[style];

    const halfGap = gap / 2;
    const quarterGap = gap / 4;

    const imagesRemainder = calcImageRemainder(style, widgetData.images.length);
    const collageTailStyles = generateCollageTailStyles(style, imagesRemainder);

    const isMasonry = ['masonry', '2col-2row'].includes(style);
    const masonryColumns = style === '2col-2row' ? 2 : 3;

    const defaultItemStyles: JssStyle = {
        extend: commonCollageTransition,
        padding: `0 ${halfGap}px`,
        position: 'relative',
        [mobileMediaQuery]: {
            padding: `0 ${quarterGap}px`,
            position: 'relative',
        },

        ...collageTailStyles,
    };

    const autoRowsCondition = imagesRemainder === 0 && !(['2row-3item', '5col-2row'] as Partial<CollageType[]>).includes(style);

    const defaultContainerStyles: JssStyle = {
        extend: commonCollageTransition,
        display: 'grid',
        gridTemplateColumns: `repeat(${gridColumns}, 1fr)`,
        gridAutoRows: autoRowsCondition ? '1fr' : 'initial',
        // todo - gridTemplateRows: '1fr 1fr 2fr' - calc this dynamically base on images count needed for at least one full structure,
        margin: `0 -${halfGap}px`,
        rowGap: gap,

        [mobileMediaQuery]: {
            margin: `0 ${-quarterGap}px`,
            rowGap: halfGap,
        },
    };

    const masonryContainerStyles = {
        display: 'flex',
        columnGap: widgetData.options.imageGap,
        transition: 'gap 0.25s ease-out',
        rowGap: 'unset',
        margin: 'unset',
        '& > div': {
            display: 'flex',
            flexDirection: 'column',
            flexBasis: masonryColumns === 2 ? '50%' : '33.3333%',
            rowGap: widgetData.options.imageGap,
            transition: 'gap 0.25s ease-out',
            [mobileMediaQuery]: {
                rowGap: halfGap,
            },
        },

        [mobileMediaQuery]: {
            columnGap: halfGap,
        },
    };
    const collageContainerStyles = isMasonry ? masonryContainerStyles : defaultContainerStyles;
    const collageItemStyles = isMasonry ? {} : defaultItemStyles;
    const collageDescriptionStyles = isMasonry ? masonryDescriptionStyles : defaultDescriptionStyles;

    const collageGridStyles: Record<CollageType, any> = {
        '2col-1': {
            item: {
                gridColumn: 'span 3',

                '&:nth-child(2n)': {
                    gridColumn: 'span 2',
                },
            },
        },
        '2col-2': {
            item: {
                gridColumn: 'span 2',

                '&:nth-child(2n)': {
                    gridColumn: 'span 3',
                },
            },
        },
        '2col-3item-1': {
            item: {
                gridColumn: 'span 4',

                '&:nth-child(6n+1)': {
                    gridColumn: 'span 8',
                    gridRow: 'span 2',
                },
                '&:nth-child(6n+5)': {
                    gridColumn: 'span 8',
                    gridRow: 'span 2',
                },
            },
        },
        '2col-3item-2': {
            item: {
                gridColumn: 'span 4',

                '&:nth-child(6n+2)': {
                    gridColumn: 'span 8',
                    gridRow: 'span 2',
                },
                '&:nth-child(6n+4)': {
                    gridColumn: 'span 8',
                    gridRow: 'span 2',
                },
            },
        },
        '2row-3item': {
            item: {
                gridColumn: 'span 6',

                '&:nth-child(3n+1)': {
                    gridColumn: 'span 12',
                },
            },
        },
        '5col-2row': {
            item: {
                gridColumn: 'span 4',

                '&:nth-child(5n+1)': {
                    gridColumn: 'span 6',
                },
                '&:nth-child(5n+2)': {
                    gridColumn: 'span 6',
                },
            },
        },
        '3col-1': {
            item: {
                gridColumn: 'span 3',

                '&:nth-child(10n+1)': {
                    gridColumn: 'span 6',
                    gridRow: 'span 2',
                },
                '&:nth-child(10n+8)': {
                    gridColumn: 'span 6',
                    gridRow: 'span 2',
                },
            },
        },
        '3col-2': {
            item: {
                gridColumn: 'span 3',

                '&:nth-child(10n+3)': {
                    gridColumn: 'span 6',
                    gridRow: 'span 2',
                },
                '&:nth-child(10n+6)': {
                    gridColumn: 'span 6',
                    gridRow: 'span 2',
                },
            },
        },
        masonry: {
            item: {
                position: 'relative',
            },
        },
        '2col-2row': {
            item: {
                position: 'relative',
            },
        },
    };

    return {
        gridContainer: collageContainerStyles,
        gridItem: {
            extend: collageItemStyles,
            ...collageGridStyles[style].item,
        },
        ...commonImageStyles(widgetData.images.length, borderRadius, clickable),
        ...collageDescriptionStyles(widgetData),
    };
};

const gridStyles = (widgetData: GalleryWidgetData): GalleryReturnType => {
    const { imageGap: gap, rowItems, borderRadius, clickable } = widgetData.options as GridOptions;
    const halfGap = gap / 2;

    const gridContainerStyles = {
        display: 'grid',
        gap,
        transition: 'gap 0.25s ease-out',
        gridTemplateColumns: `repeat(${rowItems}, 1fr)`,
        gridAutoRows: '1fr',
        [mobileMediaQuery]: {
            gap: halfGap,
        },
    };

    const gridItemStyles = {
        position: 'relative',
    };

    return {
        gridContainer: gridContainerStyles,
        gridItem: gridItemStyles,
        ...commonImageStyles(widgetData.images.length, borderRadius, clickable),
        ...defaultDescriptionStyles(widgetData),
    };
};

const styles = (widgetData: GalleryWidgetData): JssStyle => {
    const { imageGap: gap, borderRadius, clickable } = widgetData.options as CarouselOptions;
    if (widgetData.type === EGalleryTypes.collage) {
        return collageStyles(widgetData);
    }
    if (widgetData.type === EGalleryTypes.grid) {
        return gridStyles(widgetData);
    }

    const style: Record<'carousel', GalleryReturnType> = {
        carousel: {
            gridItem: {},
            gridContainer: {},
            thumbsSwiperContainer: {
                marginTop: gap,
            },
            ...commonImageStyles(widgetData.images.length, borderRadius, clickable),
            ...defaultDescriptionStyles(widgetData),
        },
    };
    return style[widgetData.type];
};

const getGalleryStyles = (widgetData: GalleryWidgetData) => {
    let horizontalAlignValue: string = '';

    switch (widgetData?.horizontalAlign) {
        case EHorizontalPosition.L:
            horizontalAlignValue = '0 auto 0 0';
            break;
        case EHorizontalPosition.R:
            horizontalAlignValue = '0 0 0 auto';
            break;
        default:
            horizontalAlignValue = '0 auto';
    }

    return {
        margin: horizontalAlignValue,
        maxWidth: widgetData?.maxWidth + 'px',
    };
};

export function getStyles(widget: WebWidgetEntity<GalleryWidgetData>): JssStyle {
    return {
        ...styles(widget.data),
        galleryWrapper: getGalleryStyles(widget.data),
    };
}
