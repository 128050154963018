import type { JssStyle } from 'jss';
import type { WebWidgetEntity } from '@web/types';
import type { IndentWidgetData } from '@web/widgets/indent/type/indent-widget.type';

export function getStyles(widget: WebWidgetEntity<IndentWidgetData>): JssStyle {
    const { styles, mobileStyles } = widget;

    const indent: JssStyle = {
        height: `${styles.height}px`,
    };

    if (mobileStyles) {
        indent['@media (max-width: 992px)'] = {
            height: `${mobileStyles.height}px`,
        };

        indent['&.mobileWidgetViewWrap'] = {
            height: `${mobileStyles.height}px`,
        }
    }

    return {
        indent,
    };
}
