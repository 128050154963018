import { type AbstractControl } from '@angular/forms';
import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';

import { DeviceMode } from '@common/types/layout.type';
import { getValidFontSize, getValidLetterSpacing, getValidLineHeight } from '@common/helpers';

import { FONT_FAMILY_RULES } from '@libs/themes/src/constants/font-families.conts';

import { EThemeFontTags } from '@libs/themes/src/enums/font-tags.enum';
import { EThemeFontWeights } from '@libs/themes/src/enums/font-families.enum';
import { ESimpleThemeFontProperties } from '@libs/themes/src/variants/simple/enums/font.enum';
import { SpThemeFontSettingsComponent } from '@libs/themes/src/abstract/abstract-fonts-settings.component';

@Component({
    selector: 'sp-website-font-settings',
    styleUrls: ['./website-font-settings.component.less'],
    templateUrl: './website-font-settings.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpWebsiteFontSettingsComponent extends SpThemeFontSettingsComponent {
    @Input() public deviceMode: DeviceMode;
    @Output() public changeDeviceMode: EventEmitter<DeviceMode> = new EventEmitter();

    public readonly HIDDEN_FONT_TAGS: EThemeFontTags[] = [EThemeFontTags.pre];
    public readonly FONT_TAG_TRANSLATIONS: Readonly<Record<EThemeFontTags, string>> = {
        [EThemeFontTags.leading]: 'theme_settings_font_leading',
        [EThemeFontTags.big]: 'theme_settings_font_big',
        [EThemeFontTags.small]: 'theme_settings_font_small',
        [EThemeFontTags.quote]: 'theme_settings_font_quote',
        [EThemeFontTags.signature]: 'theme_settings_font_signature',
        [EThemeFontTags.p]: 'theme_settings_font_paragraph',
        [EThemeFontTags.h1]: 'theme_settings_font_heading_1',
        [EThemeFontTags.h2]: 'theme_settings_font_heading_2',
        [EThemeFontTags.h3]: 'theme_settings_font_heading_3',
        [EThemeFontTags.h4]: 'theme_settings_font_heading_4',
        [EThemeFontTags.pre]: 'theme_settings_font_pre',
        [EThemeFontTags.button]: 'theme_settings_font_button',
    };
    public readonly FONT_WEIGHT_TRANSLATIONS: Readonly<Record<EThemeFontWeights, string>> = {
        [EThemeFontWeights.light]: 'theme_settings_font_weight_light',
        [EThemeFontWeights.normal]: 'theme_settings_font_weight_normal',
        [EThemeFontWeights.bold]: 'theme_settings_font_weight_bold',
    };

    protected beforeInitForm(): void {
        this.hasMobileStyles = true;

        this.FONTS_TAGS = Object.values(EThemeFontTags);
    }

    public toggleTextTransform(control: AbstractControl): void {
        let value = this.getControlValue(control, ESimpleThemeFontProperties.textTransform);
        value = value === 'uppercase' ? 'none' : 'uppercase';

        this.setControlValue(control, ESimpleThemeFontProperties.textTransform, value, this.isSynchronizedControl.value);
    }

    public toggleFontStyle(control: AbstractControl): void {
        let value = this.getControlValue(control, ESimpleThemeFontProperties.fontStyle);
        value = value === 'italic' ? 'normal' : 'italic';

        this.setControlValue(control, ESimpleThemeFontProperties.fontStyle, value, this.isSynchronizedControl.value);
    }

    public setFontFamily(control: AbstractControl, event: Event): void {
        const { value } = event.target as HTMLSelectElement;

        control.get('font').patchValue(value, { emitEvent: false });
        this.setControlValue(control, this.simpleThemeFontProperties.fontFamily, FONT_FAMILY_RULES[value], true);
    }

    public setLineHeight(control: AbstractControl, value: number): void {
        this.setControlValue(
            control,
            this.simpleThemeFontProperties.lineHeight,
            getValidLineHeight(value),
            this.isSynchronizedControl.value,
        );
    }

    public setLetterSpacing(control: AbstractControl, value: number): void {
        this.setControlValue(
            control,
            this.simpleThemeFontProperties.letterSpacing,
            getValidLetterSpacing(value),
            this.isSynchronizedControl.value,
        );
    }

    public setFontSize(control: AbstractControl, value: number): void {
        this.setControlValue(control, this.simpleThemeFontProperties.fontSize, getValidFontSize(value), this.isSynchronizedControl.value);
    }

    public setFontWeight(control: AbstractControl, event: Event): void {
        const { value } = event.target as HTMLSelectElement;

        this.setControlValue(control, this.simpleThemeFontProperties.fontWeight, value, true);
    }

    public toggleIsSynchronized(): void {
        const value = !this.isSynchronizedControl.value;

        if (value) {
            this.form.controls.forEach((control) => {
                control.get('mobileStyles').patchValue(control.get('styles').value, { emitEvent: false });
            });

            this.form.updateValueAndValidity({ onlySelf: true });
        }

        this.isSynchronizedControl.setValue(value);
    }

    public onChangeDeviceMode(mode: DeviceMode): void {
        if (this.deviceMode === mode) {
            return;
        }

        this.deviceMode = mode;
        this.changeDeviceMode.emit(mode);
    }

    public get hideMobileSettings(): boolean {
        return !(!this.isSynchronizedControl.value && this.deviceMode === DeviceMode.mobile);
    }
}
