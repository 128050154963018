import type { ThemeColorPreset } from '@common/libs/themes/src/types/theme-color.type';

import { hashCode } from '@common/helpers';

import { DEFAULT_WEBSITE_COLOR_PRESETS } from '../constants/default-presets';
import { EWebsiteThemeColorNames } from '../enums/color.enum';

export function getWebsiteThemeColorsPresets(): ThemeColorPreset[] {
    const colorNames: EWebsiteThemeColorNames[] = Object.values(EWebsiteThemeColorNames);
    return DEFAULT_WEBSITE_COLOR_PRESETS.map((preset: string[]) => ({
        index: hashCode(preset),
        values: colorNames.map((name: EWebsiteThemeColorNames, idx: number) => ({ name, value: preset[idx] })),
    }));
}
