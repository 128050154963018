import type { JssStyle } from 'jss';
import type { WebWidgetEntity } from '@web/types';
import type { TextWidgetData } from '@web/widgets/text/type/text-widget.type';

export function getStyles(widget: WebWidgetEntity<TextWidgetData>): JssStyle {
    return {
        imgWrapper: {
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            height: '200px',
            background: '#F8FDFE',
        },
        text: {
            color: '#023346',
            fontSize: '16px',
            textAlign: 'center',
            padding: '0 10px',
        },
    };
}
