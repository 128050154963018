import { EThemeFontTags } from '@libs/themes/src/enums/font-tags.enum';

export const DEFAULT_FONT_LETTER_SPACING = {
    [EThemeFontTags.leading]: '0px',
    [EThemeFontTags.big]: '0px',
    [EThemeFontTags.small]: '0px',
    [EThemeFontTags.quote]: '0px',
    [EThemeFontTags.signature]: '0px',
    [EThemeFontTags.p]: '0px',
    [EThemeFontTags.h1]: '0px',
    [EThemeFontTags.h2]: '0px',
    [EThemeFontTags.h3]: '0px',
    [EThemeFontTags.h4]: '0px',
    [EThemeFontTags.button]: '0px',
};
