import { ChangeDetectorRef, Component, EventEmitter, Input, type OnInit, Output } from '@angular/core';
import { type Classes } from 'jss';
import { EduCourse } from '@common/types/edu-course.type';
import {
    type AbstractControl,
    type FormArray,
    type FormControl,
    type FormGroup,
    NonNullableFormBuilder,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { TransferStateService } from '@web-builder/core/services/transfer-state.service';
import { UtilsService } from '@web-builder/core/services/utils.service';
import { EduHttpService } from '@web-builder/core/services/REST/edu.http.service';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { type HttpErrorResponse } from '@angular/common/http';
import { ELoginEduDefaultForm } from '@common/enums/login-edu-default-form.enum';
import { type CheckboxButtonData } from '@web/widgets/login-edu';
import { SpPipesModule } from '@common/pipes/pipes.module';
import { type AnalyticsSettings } from '@web/shared/components/web-analytics-settings/web-analytics-setings.type';
import { AnalyticsService } from '@web-builder/core/services/analytics.service';
import { UtmTagsService } from '@web-builder/core/services/utm-tags.service';
import { EAttributeTypesCrm } from '@common/enums';
import { type CheckboxesFormControl, type SignUpFormData, type SignUpFormGroup } from '@web-builder/widgets/login-edu/type/login-edu.type';
import { TooltipDirective } from '@web-builder/core/directives/tooltip.directive';

@Component({
    selector: 'web-builder-sign-up-form',
    templateUrl: './sign-up-form.component.html',
    imports: [CommonModule, ReactiveFormsModule, TranslateModule, SpPipesModule, TooltipDirective],
    standalone: true,
})
export class SignUpFormComponent implements OnInit {
    @Input() public data: {
        classes: Classes;
        buttonStyles: string;
        buttonLabel: string;
        analytics: AnalyticsSettings;
        checkboxes: CheckboxButtonData[];
        isShowCheckboxes: boolean;
    };
    @Input() public eduCourse!: EduCourse;
    @Input() public domainId!: number;
    @Input() public isAuthorizationButtonActive!: boolean;

    @Output() public signUpSuccessEvent = new EventEmitter();
    @Output() public changeFormEvent: EventEmitter<ELoginEduDefaultForm> = new EventEmitter<ELoginEduDefaultForm>();

    protected readonly ELoginEduDefaultForm = ELoginEduDefaultForm;

    public form: SignUpFormGroup;

    constructor(
        private readonly fb: NonNullableFormBuilder,
        private readonly transferStateService: TransferStateService,
        private readonly utilsService: UtilsService,
        private readonly eduHttpService: EduHttpService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly analyticsService: AnalyticsService,
        private readonly utmTagsService: UtmTagsService,
    ) {}

    ngOnInit() {
        this.initForm();
    }

    private initForm(): void {
        this.form = this.fb.group({
            name: this.fb.control(null, [Validators.required]),
            email: this.fb.control(null, [Validators.email, Validators.required]),
            checkboxes: this.checkboxesArray,
        });
    }

    public onSignUp(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        const payload: any = {
            firstName: this.form.value.name,
            email: this.form.value.email,
            language: this.utilsService.getLanguageCode(this.transferStateService.get('lang')),
            domainId: this.domainId,
            responsibleId: this.eduCourse.userId,
            courseId: this.eduCourse.id,
            attributes: this.checkboxesPayload,
        };

        const utm = this.utmTagsService.getUtmTags();
        if (Boolean(utm)) {
            payload.utm = utm;
        }

        this.form.disable();
        this.eduHttpService.register(payload).subscribe(
            () => {
                if (this.data?.analytics?.googleAnalytic) {
                    this.analyticsService.gaEventRequest(this.data?.analytics?.googleSettings);
                }

                if (this.data?.analytics?.pixelAnalytic) {
                    this.analyticsService.pixelFbEventRequest(this.data?.analytics?.pixelSettings);
                }
                this.signUpSuccessEvent.emit(this.form.getRawValue());
            },
            (error: HttpErrorResponse) => {
                this.form.enable();

                if (error.status === 422) {
                    const { errors } = error?.error;

                    if ('email' in errors) {
                        this.form.setErrors({ unique: true });
                        this.email.setErrors({ unique: true });

                        this.changeDetectorRef.detectChanges();
                    }

                    if ('courseId' in errors) {
                        this.form.setErrors({ invalidCourse: true });

                        this.changeDetectorRef.detectChanges();
                    }
                }
            },
        );
    }

    public changeCheckBoxValue(control: AbstractControl): void {
        control.patchValue({ ...control.value, value: !control.value.value });
    }

    public get name(): FormControl<SignUpFormData['name']> {
        return this.form.get('name') as FormControl<SignUpFormData['name']>;
    }

    public get email(): FormControl<SignUpFormData['email']> {
        return this.form.get('email') as FormControl<SignUpFormData['email']>;
    }

    public get checkboxesArray(): FormArray<FormGroup<CheckboxesFormControl>> {
        const array = this.fb.array<FormGroup<CheckboxesFormControl>>([]);

        if (!this.data.checkboxes?.length) {
            return array as FormArray<FormGroup<CheckboxesFormControl>>;
        }

        this.data.checkboxes.forEach((checkbox) => {
            array.push(
                this.fb.group({
                    crmVariableType: this.fb.control(checkbox.crmVariableType),
                    crmContactVariable: this.fb.control(checkbox.crmContactVariable),
                    required: this.fb.control(checkbox.required),
                    label: this.fb.control(checkbox.label),
                    value: this.fb.control(false, this.data.isShowCheckboxes && checkbox.required ? [Validators.requiredTrue] : null),
                }),
            );
        });

        return array as FormArray<FormGroup<CheckboxesFormControl>>;
    }

    public get checkboxesControls(): FormArray {
        return this.form.get('checkboxes') as FormArray;
    }

    private get checkboxesPayload() {
        if (!this.checkboxesControls.controls.length) return [];

        const checkboxes = [];
        this.checkboxesControls.controls.forEach((control) => {
            const checkbox = {
                value:
                    control.value?.crmVariableType === EAttributeTypesCrm.checkbox ? control.value.value : control.value.value?.toString(),
                attributeId: control.value?.crmContactVariable,
            };

            if (checkbox.attributeId) {
                checkboxes.push(checkbox);
            }
        });

        return checkboxes;
    }
}
