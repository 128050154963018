import type { OnInit } from '@angular/core';
import { Component, HostListener, Input } from '@angular/core';
import type { HttpErrorResponse } from '@angular/common/http';
import type { FormControl, FormGroup } from '@angular/forms';
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateModule } from '@ngx-translate/core';

import { EduCourse } from '@common/types/edu-course.type';
import { AnalyticsSettings } from '@web/shared/components/web-analytics-settings/web-analytics-setings.type';

import { TransferStateService } from '@web-builder/core/services/transfer-state.service';
import { UtilsService } from '@web-builder/core/services/utils.service';
import { EduHttpService } from '@web-builder/core/services/REST/edu.http.service';
import { AnalyticsService } from '@web-builder/core/services/analytics.service';
import { UtmTagsService } from '@web-builder/core/services/utm-tags.service';

export type SignUpFormData = {
    name: string;
    email: string;
    password: string;
};

export type SignUpFormGroup = FormGroup<{
    name: FormControl<SignUpFormData['name']>;
    email: FormControl<SignUpFormData['email']>;
    password: FormControl<SignUpFormData['password']>;
}>;

export type SignUpModalResult = {
    message?: 'signIn';
    data?: SignUpFormData;
};

@Component({
    selector: 'web-edu-course-builder-sign-up-modal',
    styleUrls: ['./edu-course-sign-up-modal.component.less'],
    templateUrl: './edu-course-sign-up-modal.component.html',
    imports: [CommonModule, ReactiveFormsModule, TranslateModule],
    standalone: true,
})
export class EduCourseSignUpModalComponent implements OnInit {
    @Input() public userId!: number;
    @Input() public courseId!: number;
    @Input() public domainId!: number;
    @Input() public isAuthorizationButtonActive!: boolean;
    @Input() public analytics: AnalyticsSettings;

    public onClose: Subject<SignUpModalResult>;
    public form: SignUpFormGroup;
    public showPassword: boolean = false;

    constructor(
        private readonly fb: NonNullableFormBuilder,
        private readonly bsModalRef: BsModalRef,
        private readonly transferStateService: TransferStateService,
        private readonly utilsService: UtilsService,
        private readonly eduHttpService: EduHttpService,
        private readonly analyticsService: AnalyticsService,
        private readonly utmTagsService: UtmTagsService,
    ) {
        this.initForm();
    }

    public ngOnInit(): void {
        this.onClose = new Subject();
    }

    private initForm(): void {
        this.form = this.fb.group({
            name: this.fb.control(null, [Validators.required]),
            email: this.fb.control(null, [Validators.email, Validators.required]),
            password: this.fb.control(null, [Validators.required, Validators.minLength(8)]),
        });
    }

    public onSignUp(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        const payload: any = {
            firstName: this.form.value.name,
            email: this.form.value.email,
            password: this.form.value.password,
            confirmPassword: this.form.value.password,
            language: this.utilsService.getLanguageCode(this.transferStateService.get('lang')),
            domainId: this.domainId,
            responsibleId: this.userId,
            courseId: this.courseId,
        };

        const utm = this.utmTagsService.getUtmTags();
        if (Boolean(utm)) {
            payload.utm = utm;
        }

        this.form.disable();
        this.eduHttpService.register(payload).subscribe(
            () => {
                this.bsModalRef.hide();
                if (this.analytics?.googleAnalytic) {
                    this.analyticsService.gaEventRequest(this.analytics?.googleSettings);
                }

                if (this.analytics?.pixelAnalytic) {
                    this.analyticsService.pixelFbEventRequest(this.analytics?.pixelSettings);
                }

                this.onClose.next({
                    data: this.form.getRawValue(),
                });
            },
            (error: HttpErrorResponse) => {
                this.form.enable();

                if (error.status === 422) {
                    const { errors } = error?.error;

                    if ('email' in errors) {
                        this.email.setErrors({ unique: true });
                    }

                    if ('courseId' in errors) {
                        this.form.setErrors({ invalidCourse: true });
                    }
                }
            },
        );
    }

    public onSignIn(): void {
        this.onClose.next({ message: 'signIn' });
        this.bsModalRef.hide();
    }

    public onCloseModal(): void {
        this.bsModalRef.hide();
    }

    public toggleShowPassword(): void {
        this.showPassword = !this.showPassword;
    }

    @HostListener('document:keydown.enter')
    public onEnterPress(): void {
        this.onSignUp();
    }

    public get name(): FormControl<SignUpFormData['name']> {
        return this.form.get('name') as FormControl<SignUpFormData['name']>;
    }

    public get email(): FormControl<SignUpFormData['email']> {
        return this.form.get('email') as FormControl<SignUpFormData['email']>;
    }

    public get password(): FormControl<SignUpFormData['password']> {
        return this.form.get('password') as FormControl<SignUpFormData['password']>;
    }
}
