import { ReactiveFormsModule } from '@angular/forms';
import { type AfterViewInit, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { type BsModalRef, BsModalService, ModalModule } from 'ngx-bootstrap/modal';

import { EUserTariffStatus } from '@common/enums';

import { PlatformService } from '@common/services/platform.service';

import { EduHttpService } from '@web-builder/core/services/REST/edu.http.service';
import { TransferStateService } from '@web-builder/core/services/transfer-state.service';

import { EduCourseSignInModalComponent } from './edu-course-sign-in-modal';
import { EduCourseSignUpModalComponent, type SignUpModalResult } from './edu-course-sign-up-modal';
import { EduCourseSignUpSuccessModalComponent } from './edu-course-sign-up-success-modal';
import { EduCourseTariffRestrictionsModalComponent } from './edu-course-tariff-restrictions-modal';
import { EduCoursePasswordRecoveryModalComponent } from './edu-course-password-recovery-modal';
import { EduCoursePasswordRecoverySuccessModalComponent } from './edu-course-password-recovery-success-modal';

@Component({
    selector: 'web-modals',
    styleUrls: ['./modals.component.less'],
    templateUrl: './modals.component.html',
    imports: [CommonModule, ReactiveFormsModule, TranslateModule, ModalModule, EduCourseSignInModalComponent],
    standalone: true,
})
export class ModalsComponent implements AfterViewInit {
    private readonly eduHttpService: EduHttpService = inject(EduHttpService);
    private readonly platformService: PlatformService = inject(PlatformService);
    private readonly modalService: BsModalService = inject(BsModalService);
    private readonly transferStateService: TransferStateService = inject(TransferStateService);

    private urlParams: URLSearchParams;

    public ngAfterViewInit(): void {
        if (this.platformService.isPlatformServer()) {
            return;
        }

        this.urlParams = new URLSearchParams(window.location.search);
        const action = this.urlParams.get('action');

        if (action === 'login') {
            this.openSignInModal();
        } else if (action === 'signup') {
            this.checkUserTariff();
        }
    }

    private openSignInModal(): void {
        const domainId = this.transferStateService.get('domainId');
        const courseId = this.urlParams.get('courseId');

        if (!domainId) {
            console.warn('Domain id is not defined');
            return;
        }

        // if (!courseId) {
        //     console.warn('Course id is not defined');
        //     return;
        // }

        const modal = this.modalService.show(EduCourseSignInModalComponent, {
            animated: false,
            initialState: {
                // courseId: Number(courseId),
                domainId: Number(domainId),
            },
        });

        modal.content.onClose.subscribe(({ message }) => {
            if (message === 'passwordRecovery') {
                this.openPasswordRecoveryModal();
            }
        });
    }

    public openPasswordRecoveryModal(): void {
        const domainId = this.transferStateService.get('domainId');

        const modal: BsModalRef<EduCoursePasswordRecoveryModalComponent> = this.modalService.show(EduCoursePasswordRecoveryModalComponent, {
            animated: false,
            initialState: {
                // eduCourse: this.course,
                domainId,
            },
        });

        modal.content.onClose.subscribe(({ message, email }) => {
            if (message === 'passwordRecoverySuccess') {
                this.openPasswordRecoverySuccessModal(email);
            }
        });
    }

    public openPasswordRecoverySuccessModal(email: string): void {
        const modal: BsModalRef<EduCoursePasswordRecoverySuccessModalComponent> = this.modalService.show(
            EduCoursePasswordRecoverySuccessModalComponent,
            {
                animated: false,
                initialState: {
                    email,
                },
            },
        );

        modal.content.onClose.subscribe((result: boolean) => {
            if (result) {
                this.openSignInModal();
            }
        });
    }

    private openSignUpModal(): void {
        const domainId = this.transferStateService.get('domainId');
        const courseId = this.urlParams.get('courseId');

        if (!domainId) {
            console.warn('Domain id is not defined');
            return;
        }

        if (!courseId) {
            console.warn('Course id is not defined');
            return;
        }

        const modal = this.modalService.show(EduCourseSignUpModalComponent, {
            animated: false,
            initialState: {
                userId: this.transferStateService.get('userId'),
                courseId: Number(courseId),
                domainId: Number(domainId),
            },
        });

        modal.content.onClose.subscribe(({ data, message }: SignUpModalResult) => {
            if (data) {
                this.openSignUpSuccessModal(data);
                return;
            }

            if (message === 'signIn') {
                this.openSignInModal();
            }
        });
    }

    private checkUserTariff(): void {
        const userId = this.transferStateService.get('userId');

        if (!userId) {
            console.warn('User id is not defined');
            return;
        }

        this.eduHttpService.getUserTariff(userId).subscribe((status) => {
            if (status === EUserTariffStatus.blocked) {
                this.openTariffRestrictionsModal();
            } else {
                this.openSignUpModal();
            }
        });
    }

    public openSignUpSuccessModal(signUpFormData: SignUpModalResult['data']): void {
        const modal: BsModalRef<EduCourseSignUpSuccessModalComponent> = this.modalService.show(EduCourseSignUpSuccessModalComponent, {
            animated: false,
            initialState: { signUpFormData },
        });
        modal.content.onClose.subscribe((isSuccess: boolean) => {
            if (!isSuccess) {
                return;
            }
            this.openSignInModal();
        });
    }

    private openTariffRestrictionsModal(): void {
        this.modalService.show(EduCourseTariffRestrictionsModalComponent, { animated: false });
    }
}
