export const CSS_RESTRICTIONS: Record<string, number> = {
    FONT_SIZE_MIN: 8,
    FONT_SIZE_MAX: 200,
    LINE_HEIGHT_MIN: 50,
    LINE_HEIGHT_MAX: 200,
    LETTER_SPACING_MIN: 0,
    LETTER_SPACING_MAX: 6,
    BORDER_WIDTH_MIN: 0,
    BORDER_WIDTH_MAX: 3,
    BORDER_RADIUS_MIN: 0,
    BORDER_RADIUS_MAX: 250,
    PADDING_MIN: 0,
    PADDING_MAX: 240,
    BLOCK_PADDING_MIN: 0,
    BLOCK_PADDING_MAX: 240,
    MAX_DESCRIPTION_LENGTH: 100,
    COLUMN_INDENT_MIN: 0,
    COLUMN_INDENT_MAX: 60,
    MIN_WIDTH_BUTTON_VALUE: 30,
    SIZE_MIN: 0,
    SIZE_MAX: 100,
    OPACITY_MIN: 10,
    OPACITY_MAX: 90,
};
