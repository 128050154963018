import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

import { SelectedFileExtended } from '@common/types/file.type';

@Component({
    selector: 'sp-audio-preview',
    templateUrl: './sp-audio-preview.component.html',
    styleUrls: ['./sp-audio-preview.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpAudioPreviewComponent {
    @Input() public file: SelectedFileExtended = null;
    @Input() public isLink: boolean;
    @Input() public showSetupBtn: boolean;

    @Output() public removeFileEvent: EventEmitter<void> = new EventEmitter();
    @Output() public replaceFileEvent: EventEmitter<void> = new EventEmitter();

    public readonly closeSubject: Subject<void> = new Subject<void>();

    public emitHiddenEventToChild(): void {
        this.closeSubject.next();
    }
}
