import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { TransferStateService } from '@web-builder/core/services/transfer-state.service';
import {
    type GoogleSettings,
    PaymentGoogleSettings,
    type PixelSettings,
} from '@web/shared/components/web-analytics-settings/web-analytics-setings.type';

declare var gtag;
declare var fbq;

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    private readonly http: HttpClient = inject(HttpClient);
    private readonly transferStateService: TransferStateService = inject(TransferStateService);

    public gaEventRequest(settings: GoogleSettings, isPaymentForm: boolean = false, paymentGoogleSettings?: PaymentGoogleSettings) {
        if (typeof gtag != 'undefined' && gtag != null) {
            if (settings.action && !(settings.currency || settings.value || settings.leadSource)) {
                //old request
                gtag('event', settings.action, { event_category: settings.category, event_label: settings.label });
            } else if (isPaymentForm) {
                //new request for payment
                gtag('event', 'begin_checkout', {
                    currency: paymentGoogleSettings.currency,
                    value: paymentGoogleSettings.value,
                    items: [{ item_name: paymentGoogleSettings.productName }],
                });
            } else {
                //new request if not payment
                gtag('event', 'generate_lead', { currency: settings.currency, value: settings.value, lead_source: settings.leadSource });
            }
        } else {
            return null;
        }
    }

    public pixelFbEventRequest(settings: PixelSettings) {
        if (typeof fbq != 'undefined' && fbq != null) {
            return fbq('track', settings.type, { ...settings });
        } else {
            return null;
        }
    }
}
