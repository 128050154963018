import {
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    TemplateRef,
    ViewContainerRef,
    Renderer2,
    HostListener,
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { merge, Observable, Subscription } from 'rxjs';
import { InputBoolean } from '@common/helpers/convert';

export interface DropdownPanel {
    templateRef: TemplateRef<any>;
    readonly closed: EventEmitter<void>;
}

@Directive({
    selector: '[dropdownTriggerFor]',
    exportAs: 'dropdownTriggerFor',
    standalone: true,
})
export class SpOverlayDropdownTriggerForDirective implements OnDestroy {
    @Input('dropdownTriggerFor') public dropdownPanel!: DropdownPanel;
    @Input() @InputBoolean() toggless: boolean = false;
    @Input() @InputBoolean() menuFullWidth: boolean = false;

    private isDropdownOpen = false;
    private overlayRef!: OverlayRef;
    private dropdownClosingActionsSub = Subscription.EMPTY;
    private globalClickListener!: () => void;

    @HostListener('click', ['$event'])
    handleClick(event: Event): void {
        if (this.toggless) return;
        this.toggle();
    }

    constructor(
        private overlay: Overlay,
        private elementRef: ElementRef<HTMLElement>,
        private viewContainerRef: ViewContainerRef,
        private renderer: Renderer2,
    ) {}

    public open(): void {
        if (!this.isDropdownOpen) {
            this.openDropdown();
        }
    }

    public close(): void {
        if (this.isDropdownOpen) {
            this.destroyDropdown();
        }
    }

    public toggle(): void {
        this.isDropdownOpen ? this.destroyDropdown() : this.openDropdown();
    }

    public get isOpen(): boolean {
        return this.isDropdownOpen;
    }

    private openDropdown(): void {
        this.isDropdownOpen = true;
        this.overlayRef = this.overlay.create({
            hasBackdrop: false,
            scrollStrategy: this.overlay.scrollStrategies.noop(),
            positionStrategy: this.overlay
                .position()
                .flexibleConnectedTo(this.elementRef)
                .withPositions([
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                        offsetY: 4,
                    },
                ]),
            width: this.menuFullWidth ? this.elementRef.nativeElement.offsetWidth : undefined,
        });

        const templatePortal = new TemplatePortal(this.dropdownPanel.templateRef, this.viewContainerRef);
        this.overlayRef.attach(templatePortal);

        this.dropdownClosingActionsSub = this.dropdownClosingActions().subscribe(() => this.destroyDropdown());

        this.globalClickListener = this.renderer.listen('document', 'click', this.onDocumentClick.bind(this));
    }

    private dropdownClosingActions(): Observable<MouseEvent | void> {
        const backdropClick$ = this.overlayRef.backdropClick();

        const detachment$ = this.overlayRef.detachments();
        const dropdownClosed = this.dropdownPanel.closed;

        return merge(backdropClick$, detachment$, dropdownClosed);
    }

    private onDocumentClick(event: MouseEvent): void {
        const clickTarget = event.target as HTMLElement;
        const triggerElement = this.elementRef.nativeElement;

        if (this.overlayRef && this.overlayRef.overlayElement) {
            const overlayElement = this.overlayRef.overlayElement;

            const clickedInsideTrigger = triggerElement.contains(clickTarget);
            const clickedInsideOverlay = overlayElement.contains(clickTarget);

            if (!clickedInsideTrigger && !clickedInsideOverlay) {
                this.destroyDropdown();
            }
        }
    }

    private destroyDropdown(): void {
        if (!this.overlayRef || !this.isDropdownOpen) {
            return;
        }

        this.dropdownClosingActionsSub.unsubscribe();
        this.isDropdownOpen = false;
        this.overlayRef.detach();

        if (this.globalClickListener) {
            this.globalClickListener();
        }
    }

    ngOnDestroy(): void {
        if (this.overlayRef) {
            this.overlayRef.dispose();
        }

        if (this.globalClickListener) {
            this.globalClickListener();
        }
        this.dropdownClosingActionsSub.unsubscribe();
    }
}
