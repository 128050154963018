import { ChangeDetectionStrategy, Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'sp-overlay-dropdown',
    template: `
        <ng-template>
            <div (click)="closed.emit()" class="dropdown-content">
                <ng-content></ng-content>
            </div>
        </ng-template>
    `,
    styles: [
        `
            .dropdown-content {
                min-width: 160px;
                padding: 4px;
                background-color: white;
                border: none;
                border-radius: 8px;
                box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
                font-size: 16px;
                text-align: left;
                list-style: none;
                width: 100%;
            }
        `,
    ],
    standalone: true,
    imports: [CommonModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpOverlayDropDown {
    @ViewChild(TemplateRef) templateRef!: TemplateRef<any>;
    @Output() closed = new EventEmitter<void>();
}
