import { Injectable } from '@angular/core';
import { environment } from '@web-builder-env/environment';

import { ELanguages } from '@common/enums';

import { TransferStateService } from '@web-builder/core/services/transfer-state.service';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    private readonly languageMap = {
        [ELanguages.EN]: { code: 'en_US', lang: 'English', region: 'United States' },
        [ELanguages.ES_MX]: { code: 'es_MX', lang: 'Spanish', region: 'Mexico' },
        [ELanguages.FR]: { code: 'fr_FR', lang: 'French', region: 'France' },
        [ELanguages.PT_BR]: { code: 'pt_BR', lang: 'Portuguese', region: 'Brazil' },
        [ELanguages.TR]: { code: 'tr_TR', lang: 'Turkish', region: 'Turkey' },
        [ELanguages.RU]: { code: 'ru_RU', lang: 'Russian', region: 'Russia' },
        [ELanguages.UA]: { code: 'uk_UA', lang: 'Ukrainian', region: 'Ukraine' },
        [ELanguages.IT]: { code: 'it_IT', lang: 'Italian', region: 'Italy' },
        // edu only
        [ELanguages.AZ]: { code: 'az_AZ', lang: 'Azerbaijani', region: 'Azerbaijan' },
        [ELanguages.CS]: { code: 'cs_CZ', lang: 'Czech', region: 'Czech Republic' },
        [ELanguages.DE]: { code: 'de_DE', lang: 'German', region: 'Germany' },
        [ELanguages.KA]: { code: 'ka_GE', lang: 'Georgian', region: 'Georgia' },
        [ELanguages.PL]: { code: 'pl_PL', lang: 'Polish', region: 'Poland' },
        [ELanguages.HY]: { code: 'hy_AM', lang: 'Armenian', region: 'Armenia' },
        [ELanguages.NL]: { code: 'nl_NL', lang: 'Dutch', region: 'Netherlands' },
        [ELanguages.SH]: { code: 'sh_RS', lang: 'Serbian', region: 'Serbia' },
    };

    constructor(private readonly transferStateService: TransferStateService) {}

    public formatImgSrc(src: string): string {
        const resourceUrl = this.transferStateService.getResourceUrl();
        const url = `${resourceUrl}${environment.constructorHref}assets/img`;

        return src.replace(/.\/assets\/img/i, url);
    }

    public getLanguageCode(value: string): string {
        return this.languageMap[value].code;
    }
}
