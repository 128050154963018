import { ChangeDetectionStrategy, Component, type OnInit } from '@angular/core';
import type { EMenuLogoPosition } from '@common/enums';
import { EButtonLinkType, EHorizontalPosition, ESize } from '@common/enums';
import type { SelectedFileExtended } from '@common/types/file.type';
import type { MenuItem, MenuWidgetData } from '@web/widgets/menu';
import { CommonModule } from '@angular/common';

import { AbstractWidgetComponent } from '@web-builder/widgets/abstract-widget.component';
import { getStyles } from '@web/widgets/menu/components/menu-view/menu-view.component.styles';

import { BLANK_LOGO } from '@web/widgets/image/constants/image.consts';
import { type BlockStructureEntity } from '@web/types';
import { SpDirectivesModule } from '@common/directives/directives.module';
import { EffectTypes } from '@web/shared/components/web-hover-effects/types';
import { SpPipesModule } from '@common/pipes/pipes.module';
import { environment } from '@web-builder-env/environment';

@Component({
    selector: 'web-builder-menu',
    standalone: true,
    imports: [CommonModule, SpDirectivesModule, SpPipesModule],
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent extends AbstractWidgetComponent<MenuWidgetData> implements OnInit {
    protected getStyles = getStyles;

    public readonly structure: BlockStructureEntity[] = this.transferStateService.get('structure');

    public isShowMobileMenu: boolean = false;
    public topMenuPosition;
    public menuItemMobileHeight: number = 45.2;
    public menuListHeight;
    public menuListLength;

    public linkTypes = EButtonLinkType;
    public EHorizontalPosition = EHorizontalPosition;
    public ESize = ESize;

    public get parentBlock() {
        return this.structure.find((block) => {
            return block.sections.find((section) => {
                return section.columns.find((column) => {
                    return column.widgets.find((widget) => widget.id === this.widget.id);
                });
            });
        });
    }

    public get isLogo(): boolean {
        return this.widgetData.isLogo;
    }

    public get blankImage(): string {
        return this.formatImgSrc(BLANK_LOGO);
    }

    public get uploadImage(): SelectedFileExtended {
        return this.widgetData.uploadImage;
    }

    public get uploadImageLink(): string {
        if (!this.uploadImage?.link) {
            return null;
        }

        return this.linkFormatterService.formatLink(this.uploadImage.link);
    }

    public get imgSrc(): string {
        return `${environment.fileManagerCdnUrl}${this.uploadImage.url}`;
    }

    public get logoPosition(): EMenuLogoPosition {
        return this.widgetData.logoPosition;
    }

    public get iconPosition(): EHorizontalPosition {
        return this.widgetData.iconPosition;
    }

    public get iconSize(): ESize {
        return this.widgetData.iconSize;
    }

    public get menuList(): MenuItem[] {
        return this.widgetData.menuList;
    }

    public get showIconOnMobile(): boolean {
        return this.widgetData.showIconOnMobile;
    }

    private getNormalizedLink(type: EButtonLinkType, link: string): string {
        const trimmedLink = link?.trim();

        if (!trimmedLink) {
            return null;
        }

        if (this.isPreview && type === EButtonLinkType.anchor) {
            return `about:srcdoc#${trimmedLink}`;
        }

        if (type === EButtonLinkType.anchor) {
            const domain = this.transferStateService.get('domainUrl');
            const pageUrl = this.transferStateService.get('pageUrl');
            const query = this.platformService.isPlatformBrowser() ? window.location.search : '';

            if (!domain || !pageUrl) {
                let anchorPageUrl = '';
                if (this.platformService.isPlatformBrowser()) {
                    anchorPageUrl = window.location.pathname.substring(1);
                }

                const replacedLink = trimmedLink.replace('#', '');
                return `${anchorPageUrl}${query}#${replacedLink}`;
            }

            return `${domain}/${pageUrl}${query}#${trimmedLink}`;
        }

        if (type === EButtonLinkType.email) {
            return this.linkFormatterService.formatEmailLink(trimmedLink);
        }

        if (type === EButtonLinkType.phone) {
            return this.linkFormatterService.formatPhoneLink(trimmedLink);
        }

        return this.linkFormatterService.formatLink(trimmedLink);
    }

    public getMenuLink(menuItem: MenuItem): string {
        const { linkUrl, linkType } = menuItem;
        const normalizedLink = this.getNormalizedLink(linkType, linkUrl);

        if (!normalizedLink) {
            return null;
        }

        return normalizedLink;
    }

    public getMenuTarget(menuItem: MenuItem): string {
        if (menuItem.linkType === EButtonLinkType.anchor) {
            return null;
        }

        return menuItem.isTargetBlank ? '_blank' : '_self';
    }

    public getActiveMenuList(): void {
        const activeMenuList = this.menuList.filter((item) => item.isVisible === true);

        this.menuListLength = activeMenuList.length;
    }

    public toggleMobileMenu(): void {
        this.topMenuPosition = (document.querySelector(`[id='${this.widget.id}']`) as HTMLDivElement)?.getBoundingClientRect().top + 15;

        this.getActiveMenuList();

        this.menuListHeight = this.menuItemMobileHeight * this.menuListLength;

        this.isShowMobileMenu = !this.isShowMobileMenu;
    }

    public hideMobileMenu(): void {
        if (this.parentBlock.data.pinOnTheTop || this.parentBlock.data.pinOnTheBottom) {
            this.isShowMobileMenu = !this.isShowMobileMenu;
        }
    }

    public getVisibleSubmenuItemList(item: MenuItem): boolean {
        if (item?.submenuList) {
            return Boolean(item.submenuList.find((item) => item.isVisible));
        } else {
            return false;
        }
    }

    public get animationClass(): string {
        return this.widgetData?.hoverEffects?.type === EffectTypes.animation ? this.widgetData?.hoverEffects?.animationType : null;
    }

    public toggleSubmenu(event, menuItem) {
        let parentMenuItem = event.currentTarget.parentNode;

        const activeSubmenuList = menuItem.submenuList.filter((item) => item.isVisible === true);

        let submenuListLength = activeSubmenuList.length;

        if (parentMenuItem.classList.contains('open')) {
            parentMenuItem.classList.remove('open');

            this.menuListHeight = this.menuListHeight - submenuListLength * this.menuItemMobileHeight;
        } else {
            parentMenuItem.classList.add('open');

            this.menuListHeight = this.menuListHeight + submenuListLength * this.menuItemMobileHeight;
        }
    }
}
