<ng-container *ngIf="classes?.columnWrapper">
    <div
        #scrollTrigger
        class="sp-column"
        [class]="classes?.columnWrapper"
        [id]="columnId">
        <div #animatedElem [class]="classes?.columnAnimationPreset">
            <div
                #columnBg
                [class]="classes?.columnBg">
            </div>
            <div [class]="classes?.columnInner">
                <div [class]="classes?.columnWidgetsList">
                    <ng-container *ngTemplateOutlet="contentRef"></ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="classes?.columnWrap">
    <div
        #animatedElem
        class="sp-column"
        [id]="columnId"
        [class]="classes?.columnWrap">
        <div [class]="classes?.columnInner">
            <div [class]="classes?.columnWidgetsList">
                <ng-container *ngTemplateOutlet="contentRef"></ng-container>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #contentRef>
    <ng-content></ng-content>
</ng-template>
