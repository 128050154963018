import type { ImageItem } from '@ui/sp-radio-group/src/type';
import {
    EFaqIconPosition,
    EFaqIconStyle,
    EHorizontalPosition,
    EMenuLayoutType,
    EMenuLogoPosition,
    ESize,
    EVerticalPosition,
} from '@common/enums';
import { ELoginEduDefaultForm } from '@common/enums/login-edu-default-form.enum';

export const SIZE_IMAGE: Record<ESize, ImageItem> = {
    [ESize.S]: {
        src: './assets/img/radio-group/lpc-icon-size-small.svg',
        alt: 'S',
    },
    [ESize.M]: {
        src: './assets/img/radio-group/lpc-icon-size-medium.svg',
        alt: 'M',
    },
    [ESize.L]: {
        src: './assets/img/radio-group/lpc-icon-size-large.svg',
        alt: 'L',
    },
};

export const MENU_IMAGE: Record<EMenuLayoutType, ImageItem> = {
    [EMenuLayoutType.HORIZONTAL]: {
        src: './assets/img/radio-group/lpc-menu-h-icon.svg',
        alt: 'Horizontal',
    },
    [EMenuLayoutType.VERTICAL]: {
        src: './assets/img/radio-group/lpc-menu-v-icon.svg',
        alt: 'Vertical',
    },
};

export const MENU_LOGO_POSITION: Record<EMenuLogoPosition, ImageItem> = {
    [EMenuLogoPosition.LEFT]: {
        src: './assets/img/radio-group/lpc-menu-logo-left-icon.svg',
        alt: 'left',
    },
    [EMenuLogoPosition.CENTER]: {
        src: './assets/img/radio-group/lpc-menu-logo-center-icon.svg',
        alt: 'left',
    },
    [EMenuLogoPosition.RIGHT]: {
        src: './assets/img/radio-group/lpc-menu-logo-right-icon.svg',
        alt: 'right',
    },
};

export const FAQ_ICON_STYLE_IMAGE: Record<EFaqIconStyle, ImageItem> = {
    [EFaqIconStyle.CARET]: {
        src: './assets/img/radio-group/lpc-faq-caret-icon.svg',
        alt: 'Caret',
    },
    [EFaqIconStyle.PLUS]: {
        src: './assets/img/radio-group/lpc-faq-plus-icon.svg',
        alt: 'Plus',
    },
};

export const FAQ_ICON_STYLE_POSITION: Record<EFaqIconPosition, ImageItem> = {
    [EFaqIconPosition.L]: {
        src: './assets/img/radio-group/lpc-icon-justify-left.svg',
        alt: 'Align left',
    },
    [EFaqIconPosition.R]: {
        src: './assets/img/radio-group/lpc-icon-justify-right.svg',
        alt: 'Align right',
    },
};

export const HORIZONTAL_POSITION_IMAGE: Record<EHorizontalPosition, ImageItem> = {
    [EHorizontalPosition.L]: {
        src: './assets/img/radio-group/lpc-icon-justify-left.svg',
        alt: 'Align left',
    },
    [EHorizontalPosition.M]: {
        src: './assets/img/radio-group/lpc-icon-justify-center.svg',
        alt: 'Align center',
    },
    [EHorizontalPosition.R]: {
        src: './assets/img/radio-group/lpc-icon-justify-right.svg',
        alt: 'Align right',
    },
};

export const VERTICAL_POSITION_IMAGE: Record<EVerticalPosition, ImageItem> = {
    [EVerticalPosition.T]: {
        src: './assets/img/radio-group/lpc-icon-align-top.svg',
        alt: 'Align top',
    },
    [EVerticalPosition.B]: {
        src: './assets/img/radio-group/lpc-icon-align-bottom.svg',
        alt: 'Align bottom',
    },
    [EVerticalPosition.M]: {
        src: './assets/img/radio-group/lpc-icon-align-center.svg',
        alt: 'Align middle',
    },
};

export const DEFAULT_EDU_FORM_IMAGE: Record<ELoginEduDefaultForm, ImageItem> = {
    [ELoginEduDefaultForm.register]: {
        src: './assets/img/radio-group/lpc-sign-up-form-icon.svg',
        alt: 'Register',
    },
    [ELoginEduDefaultForm.login]: {
        src: './assets/img/radio-group/lpc-sign-in-form-icon.svg',
        alt: 'Login',
    },
};
