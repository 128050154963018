import type { TextWidgetData } from '@web/widgets/text';
import {
    EAnimationPosition,
    EAnimationSpeed,
    EAnimationType,
    type AnimationSettings,
} from '@common/components/sp-animation-settings/type/animation-settings.type';

import { type AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SpPipesModule } from '@common/pipes/pipes.module';

import { getStyles } from '@web/widgets/text/components/text-view/text-view.component.styles';

import { AbstractWidgetComponent } from '@web-builder/widgets/abstract-widget.component';
import { getGsapScrollTrigger } from '@components/sp-animation-settings';

@Component({
    selector: 'web-builder-text',
    templateUrl: './text.component.html',
    styleUrls: ['./text.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, SpPipesModule],
})
export class TextComponent extends AbstractWidgetComponent<TextWidgetData> implements AfterViewInit {
    @ViewChild('animatedElem') animatedElem: ElementRef<HTMLDivElement>;
    @ViewChild('marqueeDiv') marqueeDiv: ElementRef<HTMLDivElement>;

    protected getStyles = getStyles;

    public ngAfterViewInit(): void {
        const animationOptions = this.widgetData.animation;
        if (animationOptions?.isActive && this.animatedElem) getGsapScrollTrigger(animationOptions, this.animatedElem.nativeElement);

        if (this.isBrowser) {
            if (animationOptions?.isActive && this.marqueeDiv) this.marqueeText();
        }
    }

    public get animation(): AnimationSettings {
        return this.widgetData.animation;
    }

    public get isActiveAnimation(): boolean {
        return this.animation.isActive;
    }

    public get isRunningStripeAnimation(): boolean {
        return this.animation.animationType === EAnimationType.runningStripe;
    }

    public get content(): string {
        return this.widgetData.content;
    }

    public get hasContent(): boolean {
        return this.content?.length > 0;
    }

    public get runningStripeAnimationSpeed(): number {
        switch (this.animation.animationSpeed) {
            case EAnimationSpeed.slow:
                return 5;
            case EAnimationSpeed.fast:
                return 30;
            default:
                return 15;
        }
    }

    public get runningStripeAnimationPosition(): string {
        switch (this.animation.animationPosition) {
            case EAnimationPosition.right:
                return EAnimationPosition.right;
            default:
                return EAnimationPosition.left;
        }
    }

    public marqueeText() {
        let marqueeWrap = this.marqueeDiv.nativeElement as HTMLDivElement;
        let clone = marqueeWrap.innerHTML;
        let firstElement = marqueeWrap.children[0] as HTMLDivElement;

        let elementSumWidth = 0;
        let i = 0;

        if (this.runningStripeAnimationPosition === EAnimationPosition.left) {
            marqueeWrap.style.justifyContent = 'flex-end';

            setTimeout(() => {
                for (let j = 0; elementSumWidth < marqueeWrap.clientWidth; j++) {
                    marqueeWrap.insertAdjacentHTML('afterbegin', clone);
                    elementSumWidth = elementSumWidth + firstElement.clientWidth;
                }

                firstElement.animate([{ marginRight: 0 }, { marginRight: `-${firstElement.clientWidth}px` }], {
                    duration: (firstElement.clientWidth / this.runningStripeAnimationSpeed) * 100,
                    iterations: Infinity,
                });
            });
        } else {
            marqueeWrap.style.justifyContent = 'flex-start';

            setTimeout(() => {
                for (let j = 0; elementSumWidth < marqueeWrap.clientWidth; j++) {
                    marqueeWrap.insertAdjacentHTML('beforeend', clone);
                    elementSumWidth = elementSumWidth + firstElement.clientWidth;
                }

                firstElement.animate([{ marginLeft: 0 }, { marginLeft: `-${firstElement.clientWidth}px` }], {
                    duration: (firstElement.clientWidth / this.runningStripeAnimationSpeed) * 100,
                    iterations: Infinity,
                });
            });
        }
    }
}
