import type { JssStyle } from 'jss';
import type { WebWidgetEntity } from '@web/types';
import type { TextWidgetData } from '@web/widgets/text/type/text-widget.type';
import { EHorizontalPosition } from '@common/enums';

const justifyStyles = {
    [EHorizontalPosition.L]: 'flex-start',
    [EHorizontalPosition.M]: 'center',
    [EHorizontalPosition.R]: 'flex-end',
};

export function getStyles(widget: WebWidgetEntity<TextWidgetData>, { isConstructor }: any): JssStyle {
    const { data } = widget;

    const mobileMediaQueryKey = '@media (max-width: 993px)';
    const root: any = {
        zIndex: data?.position?.zIndex,
        position: 'relative',
        transform: `rotate(${data?.position?.rotate ?? 0}deg)`,
        display: 'flex',
        justifyContent: justifyStyles[data.horizontalAlign] ?? 'flex-start',
        [mobileMediaQueryKey]: {
            transform: `rotate(${data?.position?.mobileRotate ?? 0}deg)`,
        },
    };

    if (isConstructor) {
        root['&.mobileWidgetViewWrap'] = {
            transform: `rotate(${data?.position?.mobileRotate ?? 0}deg)`,
        };
    }

    if (!isConstructor) {
        if (data?.hideOnMobile && !data?.hideOnDesktop) {
            root[mobileMediaQueryKey] = { display: 'none' };
        }

        if (!data?.hideOnMobile && data?.hideOnDesktop) {
            root['@media (min-width: 481px)'] = { display: 'none' };
        }

        if (data?.hideOnMobile && data?.hideOnDesktop) {
            root.display = 'none';
        }
    }

    const content: any = {
        width: data?.position?.width > 100 ? undefined : `${data?.position?.width || 100}%`,
    };

    if (isConstructor) {
        content['&.mobileWidgetViewWrap'] = {
            width: data?.position?.mobileWidth > 100 ? undefined : `${data?.position?.mobileWidth || 100}%`,
        };
    } else {
        content['@media (max-width: 992px)'] = {
            width: data?.position?.mobileWidth > 100 ? undefined : `${data?.position?.mobileWidth || 100}%`,
        };
    }

    return {
        root,
        content,
    };
}
