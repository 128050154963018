import type { Observable } from 'rxjs';
import type { HttpDataResponse } from '@common/types/http-response.type';
import type { ShopCartResponseType } from '@common/types/shop-cart-response.type';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TransferStateService } from '@web-builder/core/services/transfer-state.service';
import { getAnalyticsHeaders } from '@sites/analytics/hooks';

@Injectable({
    providedIn: 'root',
})
export class ShopCartHttpService {
    constructor(protected readonly http: HttpClient, private transferStateService: TransferStateService) {}

    public submitCartPayment(payload: any): Observable<HttpDataResponse<ShopCartResponseType>> {
        return this.http.post<HttpDataResponse<ShopCartResponseType>>(
            `${this.transferStateService.getResourceUrl()}/api/landings-service/public/payment/user-url`,
            payload,
            {
                headers: getAnalyticsHeaders(),
            },
        );
    }

    public getCartStatus(cartId: string, userId: number) {
        return this.http.get<any>(`${this.transferStateService.getResourceUrl()}/api/landings-service/public/cart/status`, {
            params: { cartId, userId: userId.toString() },
        });
    }
}
