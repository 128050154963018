<div [class]="classes?.imgWidgetWrapper">
    <a
        #animatedElem
        class="sp-image-root"
        [attr.href]="link"
        [attr.target]="isTargetBlank ? '_blank' : '_self'"
        [class]="classes?.imgWrapper"
        (mouseleave)="mouseImgOut()"
        (mouseenter)="mouseImgIn()">

        <img
            #overlayImageRef
            *ngIf="image?.overlayImage && image?.isImageEffect"
            [class]="classes?.image"
            [ngClass]="{ 'overlayImage': overlayImageSrc }"
            [style.objectPosition]="image && (image.backgroundPositionX || 50) + '% ' + (image.backgroundPositionY || 50) + '%'"
            [src]="overlayImageSrc"
            [alt]="overlayImage.name"/>
        <picture>
            <ng-container *ngIf="image?.hasResize">
                <source
                    *ngFor="let source of srcsetSources"
                    [srcset]="source.url"
                    [media]="source.media"/>
            </ng-container>
            <img
                #imageRef
                *ngIf="image && image?.maskShape"
                [class]="classes?.image + ' shape '"
                [ngClass]="{ 'mainImage': overlayImageSrc }"
                [ngStyle]="{ '-webkit-mask-image': image?.isImageEffect && image?.maskShape ? getMaskUrl : ''  }"
                [src]="imgSrc"
                [alt]="placeholder"
                [style.objectPosition]="image && (image.backgroundPositionX || 50) + '% ' + (image.backgroundPositionY || 50) + '%'"/>
            <img
                #imageRef
                *ngIf="image && !image?.maskShape"
                [class]="classes?.image + ' shape'"
                [ngClass]="{ 'mainImage': overlayImageSrc }"
                [src]="imgSrc"
                [alt]="placeholder"
                [style.objectPosition]="image && (image.backgroundPositionX || 50) + '% ' + (image.backgroundPositionY || 50) + '%'"/>
            <img
                *ngIf="!image"
                [class]="classes?.image"
                [src]="imgSrc"
                [alt]="placeholder"/>
        </picture>
        <div *ngIf="image?.isImageEffect" class="sp-image-description" [class]="classes?.description">
            <div [class]="classes?.text">
                <span>{{ widgetData.image?.description }}</span>
            </div>
        </div>
    </a>
</div>
