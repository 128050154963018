import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { SelectedFileInterface } from '@sendpulse/file-manager';

import { encodeFilePreviewUrl } from '../helpers';

@Component({
    selector: 'sp-file-preview',
    templateUrl: './sp-file-preview.component.html',
    styleUrls: ['./sp-file-preview.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpFilePreviewComponent implements OnInit, OnChanges {
    @Input() public files: SelectedFileInterface[] = [];
    @Input() public showSetupBtn: boolean = true;
    @Input() public cdnUrl: string;

    @Output() public setFileEvent: EventEmitter<SelectedFileInterface> = new EventEmitter<SelectedFileInterface>();
    @Output() public updateFilesEvent: EventEmitter<SelectedFileInterface[]> = new EventEmitter<SelectedFileInterface[]>();
    @Output() public replaceFileEvent: EventEmitter<number> = new EventEmitter<number>();
    @Output() public activateFileEvent: EventEmitter<number> = new EventEmitter<number>();

    public form: UntypedFormGroup;
    public readonly closeSubject: Subject<void> = new Subject<void>();

    constructor(private readonly fb: UntypedFormBuilder) {
        this.initForm();
    }

    public ngOnInit(): void {
        this.setFilesData();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!changes || !changes.files) {
            return;
        }

        const { files } = changes;
        if (files.previousValue && files.currentValue && files.previousValue.length < files.currentValue.length) {
            const diff: number = files.currentValue.length - files.previousValue.length;
            [...Array(diff).keys()].forEach(() => {
                this.filesForm.push(this.fb.control(null));
            });
        }

        this.filesForm.patchValue(changes.files.currentValue);
    }

    private initForm(): void {
        this.form = this.fb.group({
            files: this.fb.array([]),
        });
    }

    private setFilesData(): void {
        this.files.forEach((file: SelectedFileInterface) => {
            this.filesForm.push(this.fb.control(file));
        });
    }

    public get filesForm(): UntypedFormArray {
        return this.form.get('files') as UntypedFormArray;
    }

    public emitHiddenEventToChild(): void {
        this.closeSubject.next();
    }

    public removeMultipleFile(file: SelectedFileInterface): void {
        const files = this.files.filter((f: SelectedFileInterface) => f !== file);
        this.updateFilesEvent.emit(files.length ? files : null);
    }

    public saveSetupData(setupImageData: SelectedFileInterface, i: number): void {
        this.filesForm.controls[i].patchValue({ ...this.files[i], ...setupImageData });
        this.updateFilesEvent.emit(this.filesForm.value.length ? this.filesForm.value : null);
    }

    public encodeFilePreviewUrl(file: SelectedFileInterface): string {
        return encodeFilePreviewUrl(file, this.cdnUrl);
    }
}
