import type { FormArray, FormControl, FormGroup } from '@angular/forms';
import type { Id } from '@common/types';
import type { CrmPipeline, CrmPipelineSetting, CrmPipelineStep } from '@common/types/crm-pipeline.type';
import type { FormElement } from '@common/types/form-element.type';
import type { FormElementFormGroup } from '@common/types/form-element-forms.type';
import type { PaymentElement } from '@common/types/payment-form.type';
import type { PaymentElementFormGroup } from '@common/types/payment-element.form';
import type { FormAdditionalSettings, FormAdditionalSettingsFormGroupType } from '@components/sp-form-additional-settings';
import type { FormButtonSettings, FormButtonSettingsFormGroupType } from '@components/sp-form-button-settings';
import type { FormInputSettings, FormInputSettingsFormGroupType } from '@components/sp-form-input-settings';
import {
    type AnalyticsSettings,
    type AnalyticsSettingsFormGroupType,
} from '@web/shared/components/web-analytics-settings/web-analytics-setings.type';
import { type EPaymentView } from '@common/enums/payment-form-view.enum';
import { ButtonIcon } from '@common/components/sp-button-icon/type/button-icon.type';

export type PaymentFormWidgetData = {
    selectedAddressBook: number;
    selectedPipeline: CrmPipeline;
    selectedPipelineStep: CrmPipelineStep;
    selectedPipelineSettings: CrmPipelineSetting[];
    createAddressBook: boolean;
    elements: FormElement[];
    dealName: string;
    inputSettings: FormInputSettings;
    formButtonSettings: FormButtonSettings;
    additionalFormSettings: FormAdditionalSettings;
    paymentFormName: string;
    productType: ProductType;
    productName: string;
    productSettings: ProductSettings;
    isShowModal: boolean;
    paymentElements: PaymentElement[];
    analyticsSettings?: AnalyticsSettings;
    modalTextHint: string;
    formView: EPaymentView;
    isSynchronizeStyle: boolean;
    hideOnMobile?: boolean;
    hideOnDesktop?: boolean;
    position: {
        width?: number;

        x?: number;
        y?: number;
        rotate?: number;
        zIndex?: number;

        mobileX?: number;
        mobileY?: number;
        mobileRotate?: number;
        mobileZIndex?: number;
    };

    /** @deprecated */
    subscriptionMessage?: string;
};

export type PaymentWidgetDataFormType = {
    selectedAddressBook: FormControl<PaymentFormWidgetData['selectedAddressBook']>;
    selectedPipeline: FormControl<PaymentFormWidgetData['selectedPipeline']>;
    selectedPipelineStep: FormControl<PaymentFormWidgetData['selectedPipelineStep']>;
    selectedPipelineSettings: FormControl<PaymentFormWidgetData['selectedPipelineSettings']>;
    createAddressBook: FormControl<PaymentFormWidgetData['createAddressBook']>;
    elements: FormArray<FormElementFormGroup>;
    paymentElements: FormArray<PaymentElementFormGroup>;
    subscriptionMessage: FormControl<PaymentFormWidgetData['subscriptionMessage']>;
    dealName: FormControl<PaymentFormWidgetData['dealName']>;
    paymentFormName: FormControl<PaymentFormWidgetData['paymentFormName']>;
    productType: FormControl<PaymentFormWidgetData['productType']>;
    productName: FormControl<PaymentFormWidgetData['productName']>;
    productSettings: FormControl<PaymentFormWidgetData['productSettings']>;
    isShowModal: FormControl<PaymentFormWidgetData['isShowModal']>;
    inputSettings: FormInputSettingsFormGroupType;
    formButtonSettings: FormButtonSettingsFormGroupType;
    additionalFormSettings: FormAdditionalSettingsFormGroupType;
    analyticsSettings: AnalyticsSettingsFormGroupType;
    modalTextHint: FormControl<PaymentFormWidgetData['modalTextHint']>;
    formView: FormControl<PaymentFormWidgetData['formView']>;
    isSynchronizeStyle: FormControl<PaymentFormWidgetData['isSynchronizeStyle']>;
    hideOnMobile: FormControl<PaymentFormWidgetData['hideOnMobile']>;
    hideOnDesktop: FormControl<PaymentFormWidgetData['hideOnDesktop']>;
    position: FormGroup<{
        width: FormControl<PaymentFormWidgetData['position']['width']>;
        x: FormControl<PaymentFormWidgetData['position']['x']>;
        y: FormControl<PaymentFormWidgetData['position']['y']>;
        rotate: FormControl<PaymentFormWidgetData['position']['rotate']>;
        zIndex: FormControl<PaymentFormWidgetData['position']['zIndex']>;
        mobileX: FormControl<PaymentFormWidgetData['position']['mobileX']>;
        mobileY: FormControl<PaymentFormWidgetData['position']['mobileY']>;
        mobileRotate: FormControl<PaymentFormWidgetData['position']['mobileRotate']>;
        mobileZIndex: FormControl<PaymentFormWidgetData['position']['mobileZIndex']>;
    }>;
};

export type ProductSettings = EduCourseProductSettings;

export type EduCourseProductSettings = {
    courseId: Id;
    isPaid: boolean;
};

export enum ProductType {
    default = 'default',
    eduCourse = 'eduCourse',
}
