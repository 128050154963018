<div class="alert-box edu-modal">
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="onCloseModal()">
            <span aria-hidden="true">&times;</span>
        </button>
        <h3 class="modal-title">{{ 'password_recovery' | translate }}</h3>
    </div>
    <div class="modal-body">
        <div class="form-control-row">
            <p>{{ 'new_password_has_been_sent' | translate }} <strong>{{ email }}</strong>.</p>
            <br>
            <p>{{ 'please_check_your_spam_folder' | translate }}</p>
        </div>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="sp-ui-button sp-ui-button-md sp-ui-button-primary-light"
            (click)="onConfirm()"
        >
            {{ 'sign_in' | translate }}
        </button>
    </div>
</div>
