import type { JssStyle } from 'jss';
import type { WebWidgetEntity } from '@web/types';
import type { TestimonialWidgetData } from '@web/widgets/testimonial';
import { EButtonStyleType } from '@common/enums';
import { getBaseButtonData } from '@web/widgets/common/base/base-button/base-button.styles';
import { commonButtonTextStyles } from '@common/constants/button-sizes.const';
import { type TestimonialButtonSettings } from '@web/widgets/testimonial/type/testimonial-widget.type';

export function getStyles(widget: WebWidgetEntity<TestimonialWidgetData>): JssStyle {
    const { data } = widget;

    let gapCount = data.settings.rowItems - 1;
    let totalGapWidth = gapCount * data.settings.spaceBetween;

    function getButtonStyles({ buttonBase, buttonHover, styleType }: TestimonialButtonSettings): JssStyle {
        if (styleType === EButtonStyleType.custom) {
            return {
                ...getBaseButtonData(buttonBase, buttonHover),
            };
        }
    }

    const testimonialWrapper: JssStyle = {
        display: 'grid',
        gap: data.settings.spaceBetween + 'px',
        gridTemplateColumns:
            'repeat(auto-fill, minmax(max(180px, calc((100% - ' + totalGapWidth + 'px) / ' + data.settings.rowItems + ')), 1fr));',
    };

    const borderValue = `${data.settings.borderWidth}px ` + data.settings.borderStyle + ` ${data.settings.borderColor}`;

    const testimonial: JssStyle = {
        width: '100%',
        height: '100%',
        border: data.settings.isBorder ? borderValue : 'none',
        paddingTop: data.settings.paddingTop + 'px',
        paddingBottom: data.settings.paddingBottom + 'px',
        paddingLeft: data.settings.paddingLeft + 'px',
        paddingRight: data.settings.paddingRight + 'px',
        background: data.settings.backgroundColor,
        borderRadius: data.settings.rounding + 'px',
        boxShadow: data.settings.isShadow ? 'rgba(0, 0, 0, 0.08) 0px 0px 12px 4px' : 'none',
    };

    const noAvatarType: JssStyle = {
        '& .testimonial-body': {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            '& .testimonial-message': {
                marginBottom: '5px',
                fontSize: data.settings.fontSize + 'px',
                color: data.settings.fontColor,
                lineHeight: data.settings.fontSize * 1.4 + 'px',
            },
            '& .testimonial-footer': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                '& .testimonial-avatar': {
                    width: data.settings.fontSize * 3 + 'px',
                    height: data.settings.fontSize * 3 + 'px',
                    borderRadius: '50%',
                    marginRight: data.settings.fontSize * 0.6 + 'px',
                },
                '& .testimonial-name': {
                    marginBottom: 0,
                    fontWeight: 'bold',
                    fontSize: data.settings.fontSize + 'px',
                    color: data.settings.fontColor,
                    lineHeight: data.settings.fontSize * 1.4 + 'px',
                },
                '& .testimonial-rate': {
                    margin: '0 -2px',
                    height: '17px',
                    '& svg': {
                        color: '#D5D5D5',
                        width: '17px',
                        height: '17px',
                        margin: '0 2px',

                        '&.current': {
                            color: '#FFC907',
                        },
                    },
                },
            },
        },
    };
    const avatarTopType: JssStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& .testimonial-avatar': {
            width: '100px',
            height: '100px',
            borderRadius: '50%',
            marginBottom: data.settings.fontSize + 'px',
        },
        '& .testimonial-body': {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            '& .testimonial-name': {
                marginBottom: data.settings.fontSize * 0.6 + 'px',
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: data.settings.fontSize + 'px',
                color: data.settings.fontColor,
                lineHeight: data.settings.fontSize * 1.4 + 'px',
            },
            '& .testimonial-message': {
                textAlign: 'center',
                marginBottom: data.settings.fontSize * 1.25 + 'px',
                fontSize: data.settings.fontSize + 'px',
                color: data.settings.fontColor,
                lineHeight: data.settings.fontSize * 1.4 + 'px',
            },
            '& .testimonial-footer': {
                display: 'flex',
                alignItems: 'center',
                '& .testimonial-rate': {
                    margin: '0 -2px',
                    height: '25px',
                    '& svg': {
                        color: '#D5D5D5',
                        width: '25px',
                        height: '25px',
                        margin: '0 2px',

                        '&.current': {
                            color: '#FFC907',
                        },
                    },
                },
            },
        },
    };
    const avatarLeftType: JssStyle = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
        '& .testimonial-avatar': {
            width: '80px',
            height: '80px',
            borderRadius: '50%',
            marginBottom: data.settings.fontSize + 'px',
            marginRight: data.settings.fontSize + 'px',
        },
        '& .testimonial-body': {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            '& .testimonial-name': {
                marginBottom: data.settings.fontSize * 0.6 + 'px',
                fontWeight: 'bold',
                fontSize: data.settings.fontSize + 'px',
                color: data.settings.fontColor,
                lineHeight: data.settings.fontSize * 1.4 + 'px',
            },
            '& .testimonial-message': {
                marginBottom: data.settings.fontSize * 1.25 + 'px',
                fontSize: data.settings.fontSize + 'px',
                color: data.settings.fontColor,
                lineHeight: data.settings.fontSize * 1.4 + 'px',
            },
            '& .testimonial-footer': {
                '& .testimonial-rate': {
                    margin: '0 -2px',
                    height: '25px',
                    '& svg': {
                        color: '#D5D5D5',
                        width: '25px',
                        height: '25px',
                        margin: '0 2px',

                        '&.current': {
                            color: '#FFC907',
                        },
                    },
                },
            },
        },
    };

    const showMoreLink: JssStyle = {
        display: 'block',
        fontSize: data.settings.fontSize + 'px',
        color: data.settings.linkColor,
        lineHeight: data.settings.fontSize * 1.4 + 'px',
        textDecoration: 'underline',
        marginBottom: '20px',
        '&:hover': {
            cursor: 'pointer',
            opacity: '0.8',
        },
    };

    const showMoreButtonWrapper: JssStyle = {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '30px',
        '& .sp-ui-button': {
            margin: '0px !important',
            padding: commonButtonTextStyles[data.buttonSettings.size].padding,
            ...getButtonStyles(data.buttonSettings),
        },
    };

    const showMoreTextStyle: JssStyle = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: data.settings.isShortText ? 5 : 'unset',
        WebkitBoxOrient: 'vertical',
    };

    const blankImageWrapper: JssStyle = {
        height: '200px',
        background: '#F2F5F5',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '15px',
        justifyContent: 'center',
        '& img': {
            height: '64px',
            marginBottom: '10px',
        },
        '& span': {
            fontSize: '15px',
            color: '#023346',
            textAlign: 'center',
        },
    };

    return {
        testimonialWrapper,
        testimonial,
        noAvatarType,
        avatarTopType,
        avatarLeftType,
        blankImageWrapper,
        showMoreLink,
        showMoreButtonWrapper,
        showMoreTextStyle,
    };
}
