import { EButtonStyleType } from '@common/enums';
import { EWebsiteThemeButtonProperties, EWebsiteThemeButtonHoverProperties } from '@common/libs/themes/src/variants/website/enums/button.enum';

export const WEBSITE_THEME_BUTTONS_VARIABLES = {
    [EButtonStyleType.primary]: {
        [EWebsiteThemeButtonProperties.borderRadius]: '--primary-button-border-radius',
        [EWebsiteThemeButtonProperties.borderStyle]: '--primary-button-border-style',
        [EWebsiteThemeButtonProperties.borderWidth]: '--primary-button-border-width',
        [EWebsiteThemeButtonProperties.borderColor]: ['--primary-button-border-color-light', '--primary-button-border-color-dark'],
        [EWebsiteThemeButtonProperties.color]: ['--primary-button-color-light', '--primary-button-color-dark'],
        [EWebsiteThemeButtonProperties.background]: ['--primary-button-background-light', '--primary-button-background-dark'],
    },
    [EButtonStyleType.secondary]: {
        [EWebsiteThemeButtonProperties.borderRadius]: '--secondary-button-border-radius',
        [EWebsiteThemeButtonProperties.borderStyle]: '--secondary-button-border-style',
        [EWebsiteThemeButtonProperties.borderWidth]: '--secondary-button-border-width',
        [EWebsiteThemeButtonProperties.borderColor]: ['--secondary-button-border-color-light', '--secondary-button-border-color-dark'],
        [EWebsiteThemeButtonProperties.color]: ['--secondary-button-color-light', '--secondary-button-color-dark'],
        [EWebsiteThemeButtonProperties.background]: ['--secondary-button-background-light', '--secondary-button-background-dark'],
    },
};

export const WEBSITE_THEME_BUTTONS_HOVER_VARIABLES = {
    [EButtonStyleType.primary]: {
        [EWebsiteThemeButtonHoverProperties.opacity]: '--primary-button-hover-opacity',
        [EWebsiteThemeButtonHoverProperties.shadow]: '--primary-button-hover-shadow',
        [EWebsiteThemeButtonHoverProperties.color]: ['--primary-button-hover-color-light', '--primary-button-hover-color-dark'],
        [EWebsiteThemeButtonHoverProperties.background]: ['--primary-button-hover-background-light', '--primary-button-hover-background-dark'],
        [EWebsiteThemeButtonHoverProperties.borderColor]: ['--primary-button-hover-border-color-light', '--primary-button-hover-border-color-dark'],
    },
    [EButtonStyleType.secondary]: {
        [EWebsiteThemeButtonHoverProperties.opacity]: '--secondary-button-hover-opacity',
        [EWebsiteThemeButtonHoverProperties.shadow]: '--secondary-button-hover-shadow',
        [EWebsiteThemeButtonHoverProperties.color]: ['--secondary-button-hover-color-light', '--secondary-button-hover-color-dark'],
        [EWebsiteThemeButtonHoverProperties.background]: ['--secondary-button-hover-background-light', '--secondary-button-hover-background-dark'],
        [EWebsiteThemeButtonHoverProperties.borderColor]: ['--secondary-button-hover-border-color-light', '--secondary-button-hover-border-color-dark'],
    },
};
