import type { Uuid } from '@common/types';
import { v4 as uuid, validate } from 'uuid';

export function getUuid(): Uuid {
    return uuid();
}

export function isUuid(id: Uuid | number): boolean {
    if (typeof id !== 'string') {
        return false;
    }

    return validate(id);
}
