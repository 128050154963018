import type { OnDestroy, OnInit } from '@angular/core';
import type { Observable } from 'rxjs';
import type { ThemeColor, ThemeColorPreset } from '@common/libs/themes/src/types/theme-color.type';
import type { ThemeFont, ThemeFontPreset } from '@common/libs/themes/src/types/theme-font.type';
import type { ThemeButton, ThemeButtonPreset } from '@common/libs/themes/src/types/theme-button.type';

import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { EThemeSettings } from '@common/libs/themes/src/enums/theme-settings.enum';
import { EThemeFontTags } from '@common/libs/themes/src/enums/font-tags.enum';
import { EButtonStyleType } from '@common/enums';

import { FONT_FAMILY_NAMES } from '@common/libs/themes/src/constants/font-families.conts';

import { ThemeFacade } from '@common/libs/themes/src/store/theme/theme.facade';
import { ThemeConfigFacade } from '@common/libs/themes/src/store/theme-config/theme-config.facade';
import { ThemePresetsFacade } from '@common/libs/themes/src/store/theme-presets/theme-presets.facade';
import { ThemeSettingsFacade } from '@common/libs/themes/src/store/theme-settings/theme-settings.facade';

@Component({
    template: '',
})
export abstract class SpPresetSettingsComponent implements OnInit, OnDestroy {
    public readonly selectedThemeColors$: Observable<ThemeColor[]> = this.themeSettingsFacade.selectedThemeColors$;
    public readonly selectedThemeColorIndex$: Observable<string> = this.themeSettingsFacade.selectedThemeColorIndex$;
    public readonly selectedThemeButtons$: Observable<ThemeButton[]> = this.themeSettingsFacade.selectedThemeButtons$;
    public readonly selectedThemeButtonIndex$: Observable<string> = this.themeSettingsFacade.selectedThemeButtonIndex$;
    public readonly selectedThemeFonts$: Observable<ThemeFont[]> = this.themeSettingsFacade.selectedThemeFonts$;
    public readonly selectedThemeFontIndex$: Observable<string> = this.themeSettingsFacade.selectedThemeFontIndex$;
    public readonly colorsPresets$: Observable<ThemeColorPreset[]> = this.themePresetsFacade.colorsPresets$;
    public readonly fontsPresets$: Observable<ThemeFontPreset[]> = this.themePresetsFacade.fontsPresets$;
    public readonly buttonsPresets$: Observable<ThemeButtonPreset[]> = this.themePresetsFacade.buttonsPresets$;

    public readonly BUTTON_STYLE_TYPE_ARRAY = [EButtonStyleType.primary, EButtonStyleType.secondary];
    public readonly FONT_FAMILY_NAMES = FONT_FAMILY_NAMES;
    public readonly EThemeSettings = EThemeSettings;
    public pFont: ThemeFont;
    public h1Font: ThemeFont;

    private readonly h1PresetCache = new Map<string, ThemeFont>();
    private readonly pPresetCache = new Map<string, ThemeFont>();

    protected unsubscribe$ = new Subject<void>();

    constructor(
        private readonly themeFacade: ThemeFacade,
        private readonly themeConfigFacade: ThemeConfigFacade,
        private readonly themePresetsFacade: ThemePresetsFacade,
        private readonly themeSettingsFacade: ThemeSettingsFacade,
    ) {}

    public ngOnInit(): void {
        if (!this.isConfigFonts) {
            return;
        }

        this.selectedThemeFonts$.pipe(takeUntil(this.unsubscribe$)).subscribe((themeFonts: ThemeFont[]) => {
            themeFonts.forEach((font: ThemeFont) => {
                if (font.tag === EThemeFontTags.p) {
                    this.pFont = font;
                }

                if (font.tag === EThemeFontTags.h1) {
                    this.h1Font = font;
                }
            });
        });
    }

    /**
     * Select colors theme handler
     */
    public selectColorsThemeHandler(colors: ThemeColorPreset): void {
        this.themeSettingsFacade.changeColorsAction(colors);
    }

    /**
     * Select buttons theme handler
     */
    public selectButtonsThemeHandler(buttons: ThemeButtonPreset): void {
        this.themeSettingsFacade.changeButtonsAction(buttons);
    }

    /**
     * Select fonts theme handler
     */
    public selectFontsThemeHandler(fonts: ThemeFontPreset): void {
        this.themeSettingsFacade.changeFontsAction(fonts);
    }

    public openDetailSettingsView(selectedThemeSettings: EThemeSettings): void {
        this.themeConfigFacade.setActiveThemeSettingAction(selectedThemeSettings);
    }

    public findTitleFont(preset: ThemeFontPreset): ThemeFont {
        if (this.h1PresetCache.has(preset.index)) {
            return this.h1PresetCache.get(preset.index);
        }

        const h1Font = preset.values.find((font: ThemeFont) => font.tag === EThemeFontTags.h1);
        this.h1PresetCache.set(preset.index, h1Font);
        return h1Font;
    }

    public findParagraphFont(preset: ThemeFontPreset): ThemeFont {
        if (this.pPresetCache.has(preset.index)) {
            return this.pPresetCache.get(preset.index);
        }

        const pFont = preset.values.find((font: ThemeFont) => font.tag === EThemeFontTags.p);
        this.pPresetCache.set(preset.index, pFont);
        return pFont;
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();

        this.h1PresetCache.clear();
        this.pPresetCache.clear();
    }

    public get isConfigColors(): boolean {
        return this.themeFacade.isConfigColors;
    }

    public get isConfigButtons(): boolean {
        return this.themeFacade.isConfigButtons;
    }

    public get isConfigFonts(): boolean {
        return this.themeFacade.isConfigFonts;
    }
}
