<div class="panel panel-default" *ngIf="file">
    <div class="panel-body">
        <div class="media panel-image" dropdown #dropdown="bs-dropdown" [insideClick]="true" (onHidden)="emitHiddenEventToChild()">
            <div class="panel-image-content">
                <div class="preview-thumbnail" [style.background-image]="encodeFilePreviewUrl(file)"></div>
                <div class="file-name">
                    <strong class="text-muted">{{ file.name }}</strong>
                </div>
            </div>

            <div>
                <button class="btn btn-link" (click)="imageEditEvent.emit()" *ngIf="!short">
                    <span class="sp-icon icon-crop-pic"> </span>
                </button>
                <button class="btn btn-link" (click)="replaceFileEvent.emit()">
                    <a>
                        <span class="sp-icon icon-marquee-upload"> </span>
                    </a>
                </button>

                <button *ngIf="showSetupBtn && !short" class="btn btn-link" dropdownToggle>
                    <span class="dropdown-toggle sp-icon icon-settings"> </span>
                </button>

                <button (click)="removeFileEvent.emit()" class="btn btn-link">
                    <a>
                        <span class="sp-icon icon-bin"> </span>
                    </a>
                </button>
            </div>

            <div *dropdownMenu class="dropdown-menu">
                <sp-setup-image-dropdown
                    [file]="file"
                    [isLink]="isLink"
                    [closeEvent]="closeSubject.asObservable()"
                    (save)="saveSetupData($event)"
                    (close)="dropdown.hide()"
                >
                </sp-setup-image-dropdown>
            </div>
        </div>
    </div>
</div>
