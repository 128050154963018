<div class="sp-menu-root" [class]="classes?.['menu-wrapper']">
    <div
        *ngIf="isLogo"
        class="sp-menu-logo"
        [class]="classes?.['menu-logo']"
    >
        <img *ngIf="!uploadImage" [src]="blankImage" alt="blank image" />

        <a *ngIf="uploadImage" [attr.href]="uploadImageLink">
            <img
                [src]="imgSrc"
                [spFallbackImage]="imgSrc"
                [alt]="uploadImage.alt"
            />
        </a>
    </div>
    <div [class]="classes?.['menu-list-wrapper']">
        <ul class="sp-menu-group" [class]="classes?.['menu-list']">
            <li class="sp-ui-text sp-menu-item" *ngFor="let menuItem of menuList">
                <span *ngIf="menuItem.isVisible">
                    <a *ngIf="menuItem.linkUrl" [attr.href]="getMenuLink(menuItem) | spSafeUrl" [attr.target]="getMenuTarget(menuItem)" [class]="animationClass">
                        {{ menuItem.name }}
                        <div *ngIf="getVisibleSubmenuItemList(menuItem)" class="caret-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
                                <path d="M6 6.5L11.1962 0.5H0.803848L6 6.5Z" fill="currentColor"/>
                            </svg>
                        </div>
                    </a>
                    <a *ngIf="!menuItem.linkUrl" class="sp-menu-item-without-link" [class]="animationClass">
                        {{ menuItem.name }}
                        <div *ngIf="getVisibleSubmenuItemList(menuItem)" class="caret-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
                                <path d="M6 6.5L11.1962 0.5H0.803848L6 6.5Z" fill="currentColor"/>
                            </svg>
                        </div>
                    </a>
                </span>

                <ul *ngIf="getVisibleSubmenuItemList(menuItem)" class="dropdown-submenu-list">
                    <li class="sp-ui-text" *ngFor="let submenuItem of menuItem?.submenuList">
                        <span *ngIf="submenuItem.isVisible">
                            <a [attr.href]="getMenuLink(submenuItem) | spSafeUrl" [attr.target]="getMenuTarget(submenuItem)">
                                {{ submenuItem.name }}
                            </a>
                        </span>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
    <div
        class="mobile-icon"
        [class]="classes?.['mobile-icon']"
        [ngClass]="{
            'close-btn': isShowMobileMenu,
            'sm-icon': iconSize === ESize.S,
            'md-icon': iconSize === ESize.M,
            'lg-icon': iconSize === ESize.L
        }"
        (click)="toggleMobileMenu()">
        <div class="mobile-icon-lines" [class]="classes?.['mobile-icon-lines']"></div>
    </div>
</div>
<div
    *ngIf="showIconOnMobile"
    [id]="widget.id"
    class="mobile-menu-container"
    [style.height]="isShowMobileMenu ? (menuListHeight + 'px') : '0'"
    [class.show]="isShowMobileMenu"
>
    <ul
        class="mobile-menu-list sp-menu-mobile-group"
        [class]="classes?.['mobile-menu-list']"
        [style.height]="isShowMobileMenu ? 'calc(100vh - ' + topMenuPosition + 'px)' : '0'"
    >
        <li
            class="sp-ui-text sp-menu-mobile-item"
            *ngFor="let menuItem of menuList"
            [id]="menuItem.id ? menuItem.id : ''">
            <span *ngIf="menuItem.isVisible" (click)="hideMobileMenu()">
                <a *ngIf="menuItem.linkUrl" [attr.href]="getMenuLink(menuItem)" [attr.target]="getMenuTarget(menuItem)">
                    {{ menuItem.name }}
                </a>
                <a *ngIf="!menuItem.linkUrl" class="sp-menu-item-without-link">
                    {{ menuItem.name }}
                </a>
            </span>

            <div *ngIf="getVisibleSubmenuItemList(menuItem)" class="caret-icon" (click)="toggleSubmenu($event, menuItem)">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
                    <path d="M6 6.5L11.1962 0.5H0.803848L6 6.5Z" fill="currentColor"/>
                </svg>
            </div>

            <ul *ngIf="getVisibleSubmenuItemList(menuItem)" class="dropdown-submenu-list">
                <li class="sp-ui-text" *ngFor="let submenuItem of menuItem?.submenuList">
                    <span *ngIf="submenuItem.isVisible">
                        <a [attr.href]="getMenuLink(submenuItem) | spSafeUrl" [attr.target]="getMenuTarget(submenuItem)">
                            {{ submenuItem.name }}
                        </a>
                    </span>
                </li>
            </ul>
        </li>
    </ul>
</div>
