import type { ImageItem } from './type';

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
    EFaqIconPosition,
    EFaqIconStyle,
    EHorizontalPosition,
    EMenuLayoutType,
    EMenuLogoPosition,
    ESize,
    EVerticalPosition,
} from '@common/enums';

import { ERadioGroupType } from './enums/radio-group.enum';
import {
    DEFAULT_EDU_FORM_IMAGE,
    FAQ_ICON_STYLE_IMAGE,
    FAQ_ICON_STYLE_POSITION,
    HORIZONTAL_POSITION_IMAGE,
    MENU_IMAGE,
    MENU_LOGO_POSITION,
    SIZE_IMAGE,
    VERTICAL_POSITION_IMAGE,
} from './constants/radio-group.const';
import { ELoginEduDefaultForm } from '@common/enums/login-edu-default-form.enum';

type RadioGroupValue = string | EVerticalPosition | EHorizontalPosition | ESize;

@Component({
    selector: 'sp-radio-group',
    templateUrl: './sp-radio-group.component.html',
    styleUrls: ['./sp-radio-group.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpRadioGroupComponent {
    @Input() public selected!: RadioGroupValue;
    @Input() public type!: ERadioGroupType;
    @Input() public disabled = false;
    @Input() public excludedPosition?: EVerticalPosition | EHorizontalPosition;

    @Output() public changeSelectedEvent: EventEmitter<RadioGroupValue> = new EventEmitter();

    public readonly ESize = ESize;
    public readonly ERadioGroupType = ERadioGroupType;
    public readonly EVerticalPosition = EVerticalPosition;
    public readonly EHorizontalPosition = EHorizontalPosition;
    public readonly EMenuType = EMenuLayoutType;
    public readonly EFaqIconStyle = EFaqIconStyle;
    public readonly EMenuLogoPosition = EMenuLogoPosition;

    public changeSelectedHandler(event: Event, opt: string): void {
        event.preventDefault();
        if (this.selected === opt) {
            return;
        }

        this.selected = opt;
        this.changeSelectedEvent.emit(opt);
    }

    public get options(): string[] {
        switch (this.type) {
            case ERadioGroupType.size:
                return Object.values(ESize);
            case ERadioGroupType.menu:
                return Object.values(EMenuLayoutType);
            case ERadioGroupType.logoPosition:
                return Object.values(EMenuLogoPosition);
            case ERadioGroupType.faqIconStyle:
                return Object.values(EFaqIconStyle);
            case ERadioGroupType.faqIconPosition:
                return Object.values(EFaqIconPosition);
            case ERadioGroupType.verticalPosition:
                if (this.excludedPosition) {
                    return Object.values(EVerticalPosition).filter((position) => position !== this.excludedPosition);
                }

                return Object.values(EVerticalPosition);
            case ERadioGroupType.horizontalPosition:
                if (this.excludedPosition) {
                    return Object.values(EHorizontalPosition).filter((position) => position !== this.excludedPosition);
                }

                return Object.values(EHorizontalPosition);
            case ERadioGroupType.loginDefaultForm:
                return Object.values(ELoginEduDefaultForm);
            default:
                return Object.values(ESize);
        }
    }

    public getSizeImage(size: string): ImageItem {
        return SIZE_IMAGE[size as ESize];
    }

    public getMenuImage(menu: string): ImageItem {
        return MENU_IMAGE[menu as EMenuLayoutType];
    }

    public getMenuLogoPosition(logoPosition: string): ImageItem {
        return MENU_LOGO_POSITION[logoPosition as EMenuLogoPosition];
    }

    public getFaqIconStyleImage(faqIconStyle: string): ImageItem {
        return FAQ_ICON_STYLE_IMAGE[faqIconStyle as EFaqIconStyle];
    }

    public getFaqIconPositionImage(faqIconPosition: string): ImageItem {
        return FAQ_ICON_STYLE_POSITION[faqIconPosition as EFaqIconPosition];
    }

    public getHorizontalPositionImage(position: string): ImageItem {
        return HORIZONTAL_POSITION_IMAGE[position as EHorizontalPosition];
    }

    public getVerticalPositionImage(position: string): ImageItem {
        return VERTICAL_POSITION_IMAGE[position as EVerticalPosition];
    }

    public getEduLoginViewImage(view: string): ImageItem {
        return DEFAULT_EDU_FORM_IMAGE[view as ELoginEduDefaultForm];
    }
}
