import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import type { Source } from '@sendpulse/plyr';
import type { VideoWidgetData } from '@web/widgets/video';
import { CommonModule } from '@angular/common';

import { SpPlayerModule } from '@components/sp-player';

import { getStyles } from '@web/widgets/video/components/video-view/video-view.component.styles';
import { BLANK_VIDEO } from '@web/widgets/image/constants/image.consts';

import { AbstractWidgetComponent } from '@web-builder/widgets/abstract-widget.component';

@Component({
    selector: 'web-builder-video',
    standalone: true,
    imports: [CommonModule, SpPlayerModule],
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoComponent extends AbstractWidgetComponent<VideoWidgetData> implements OnInit {
    protected getStyles = getStyles;

    public get videoUrl() {
        return this.widgetData.videoUrl;
    }

    public get videoSource(): Source[] {
        return [
            {
                src: this.widgetData.videoUrl,
                provider: this.widgetData.videoType as any,
            },
        ];
    }

    public get blankVideoImg() {
        return this.formatImgSrc(BLANK_VIDEO);
    }
}
