<div
    #animatedElem
    class="sp-column"
    [id]="columnId"
    [class]="classes?.columnWrap">
    <div [class]="classes?.columnInner">
        <div [class]="classes?.columnWidgetsList" class="specific-widget-list">
            <ng-content></ng-content>
        </div>
    </div>
</div>
