import type { JssStyle } from 'jss';
import type { WebWidgetEntity } from '@web/types';
import type { PaymentFormWidgetData } from '@web/widgets/payment-form';

import { getBaseFormStyles } from '@web/widgets/common/base/base-form/base-form.styles';
import { Widgets } from '@common/enums';

export function getStyles({ data }: WebWidgetEntity<PaymentFormWidgetData>, { isConstructor }: any): JssStyle {
    return getBaseFormStyles(data, Widgets.PAYMENT_FORM, isConstructor);
}
