import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders, HttpParams, type HttpResponse } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';

import type { EUserTariffStatus } from '@common/enums/user-tariff-status.enum';
import type { HttpDataResponse } from '@common/types/http-response.type';
import type { Testimonial } from '@common/types/testimonial.type';

import { SKIP_AUTH } from '@web-builder/core/interceptors/jwt.interceptor';

import { environment } from '@web-builder-env/environment';
import { of, type Observable } from 'rxjs';
import { type EPaymentType } from '@common/enums';
import { type CheckboxData } from '@web-builder/widgets/login-edu/type/login-edu.type';

type Data<T> = Record<'data', T>;

export type RegisterRequestData = {
    firstName: string;
    email: string;
    language: string;
    domainId: number;
    responsibleId: number;
    courseId: number;
    utm?: string;
    checkboxes?: CheckboxData[];
};

export type LoginRequestData = {
    email: string;
    password: string;
    domainId: number;
    utm: string;
};

export type LoginResponseData = {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    domainId: number;
    responsibleId: number;
    status: string;
};

type ForgotPasswordRequestData = {
    email: string;
    domainId: number;
    courseId: number;
};

type ForgotPasswordRequestDataV2 = {
    email: string;
    domainId: number;
    cabinetUrl: boolean;
};

type CourseStatusData = {
    status: ECourseStatus;
};

type CoursePromoCodeExistsData = {
    promoCodeExists: boolean;
};

enum ECourseStatus {
    BLOCKED = 'blocked',
    IN_PROGRESS = 'inProgress',
    INACTIVE = 'inactive',
    UNKNOWN = 'unknown',
}

type TestimonialRequestData = {
    data: Testimonial[];
    meta: { total: number };
};

@Injectable({
    providedIn: 'root',
})
export class EduHttpService {
    private readonly host = environment.proxyUrl;
    private readonly baseApiUrl = '/api/edu-service';
    private readonly baseLandingApiUrl = '/api/landings-service';

    constructor(private readonly http: HttpClient, private readonly cookieService: CookieService) {}

    private get context() {
        return new HttpContext().set(SKIP_AUTH, true);
    }

    private formatUrl(url: string = '') {
        if (environment.type === 'dev') {
            return `https://stage.sendpulse.test${this.baseApiUrl}${url}`;
        }

        return `${this.host}${this.baseApiUrl}${url}`;
    }

    getFormatLandingUrl(url: string = ''): string {
        if (environment.type === 'dev') {
            return `https://stage.sendpulse.test${this.baseLandingApiUrl}${url}`;
        }

        return `${this.host}${this.baseLandingApiUrl}${url}`;
    }

    private getLandingUrl(url: string = ''): string {
        if (environment.type === 'dev') {
            return `https://edu-stage.sendpulse.test${this.baseLandingApiUrl}${url}`;
        }

        return `${this.host}${this.baseLandingApiUrl}${url}`;
    }

    public refreshToken(token) {
        const headers = new HttpHeaders().append('Refreshtoken', token);

        return this.http.get<Data<any>>(this.formatUrl('/refresh-token'), {
            observe: 'response',
            headers,
            context: this.context,
        });
    }

    public register(data: RegisterRequestData) {
        return this.http.post<Data<any>>(this.formatUrl('/register'), data, { context: this.context });
    }

    public login(data: LoginRequestData): Observable<HttpResponse<LoginResponseData>> {
        return this.http.post<LoginResponseData>(this.formatUrl('/login'), data, { observe: 'response', context: this.context });
    }

    public quickLoginEmail(data: Omit<LoginRequestData, 'password'>): Observable<HttpResponse<null>> {
        return this.http.post<null>(this.formatUrl('/quick-login/email'), data, { observe: 'response', context: this.context });
    }

    public forgotPassword(data: ForgotPasswordRequestData) {
        return this.http.post<Data<any>>(this.formatUrl('/forgot-password'), data, { context: this.context });
    }

    public forgotPasswordV2(data: ForgotPasswordRequestDataV2) {
        return this.http.post<Data<any>>(this.formatUrl('/forgot-password'), data, { context: this.context });
    }

    // students
    public getStudentInfo() {
        return this.http.get<Data<any>>(this.formatUrl('/students/student'));
    }

    // courses
    public studentCourseStatus(courseId: number) {
        return this.http.get<Data<any>>(this.formatUrl(`/students/courses/${courseId}/status`)).pipe(map((data) => data.data));
    }

    public registerOnCourse(courseId: number) {
        return this.http.post<Data<any>>(this.formatUrl(`/students/courses/${courseId}/register`), {});
    }

    public getUserTariff(userId: number): Observable<EUserTariffStatus> {
        return this.http
            .get<HttpDataResponse<EUserTariffStatus>>(this.getLandingUrl(`/public/education/users/${userId}/user-tariff/status`), {
                context: this.context,
            })
            .pipe(map((res: HttpDataResponse<EUserTariffStatus>) => res.data));
    }

    public getCourseInfo(userId: number, courseId: number): Observable<CourseStatusData> {
        return this.http.get<CourseStatusData>(this.getLandingUrl(`/public/education/users/${userId}/courses/${courseId}/status`));
    }

    // feedbacks
    public getTestimonials(userId: number, courseId: number, offset: number, limit: number): Observable<TestimonialRequestData> {
        return this.http
            .get<TestimonialRequestData>(
                this.getLandingUrl(`/public/education/users/${userId}/courses/${courseId}/feedbacks?offset=${offset}&limit=${limit}`),
            )
            .pipe(map((res: TestimonialRequestData) => res));
    }

    public usePromoCode(
        pageId: number,
        courseId: number,
        paymentType: EPaymentType,
        paymentMethodId: string,
        promoCode: string,
        eduPaymentMethodId: number = null,
    ) {
        return this.http.post(this.getFormatLandingUrl(`/public/education/promo-code`), {
            pageId,
            courseId,
            paymentType,
            paymentMethodId,
            promoCode,
            eduPaymentMethodId,
        });
    }

    public getPromoCodeExists(userId: number, courseId: number) {
        return this.http
            .get<CoursePromoCodeExistsData>(
                this.getFormatLandingUrl(`/public/education/users/${userId}/courses/${courseId}/promo-code-exists`),
            )
            .pipe(map((res: CoursePromoCodeExistsData) => res));
    }
}
