import { TranslateLoader, TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';

import { ELanguages } from '@common/enums';
import { ServerTranslationsService } from '@common/services/server-translations.service';

export class TranslationBrowserLoader implements TranslateLoader {
    constructor(private readonly translationsService: ServerTranslationsService) {}

    public static getCurrentLocale(translationsService: ServerTranslationsService): string {
        return translationsService.getLanguageAsValue() || ELanguages.EN;
    }

    public getTranslation(): Observable<any> {
        return this.translationsService.getTranslations().pipe(map((translations) => ({ ...translations })));
    }
}

export function translateBrowserLoaderFactory(translationsService: ServerTranslationsService) {
    return new TranslationBrowserLoader(translationsService);
}

export function initializeBrowserTranslations(
    translate: TranslateService,
    translationsService: ServerTranslationsService,
): () => Promise<unknown> {
    return async () => {
        translate.use(TranslationBrowserLoader.getCurrentLocale(translationsService));
        return new Promise<void>((resolve) => resolve());
    };
}
