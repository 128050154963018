<div [class]="classes?.cartViewType">
    <ng-container *ngIf="isPreview && isServer">
        <ng-container *ngTemplateOutlet="emptyState"></ng-container>
    </ng-container>
    <ng-container *ngIf="(selectCartLoaded$ | async) === true">
        <ng-container *ngIf="(cartItems$ | async).length; else emptyState">
            <div class="cart-items" [class]="classes?.cartStyle">
                <div class="item" *ngFor="let item of cartItems$ | async;">
                    <div class="item-info">
                        <ng-container *ngIf="item.images.length; else emptyImage">
                            <div class="item-info-photo">
                                <img [src]="formatImageURL(item.images[0])" [alt]="item.name">
                            </div>
                        </ng-container>
                        <ng-template #emptyImage>
                            <div class="item-info-photo">
                                <img [src]="formatImgSrc('./assets/img/websites/lpc-empty-product.png')" alt="product_blank">
                            </div>
                        </ng-template>
                        <div class="item-info-text">
                            <h3 (click)="goToCardPage(item)">{{ item.name }}</h3>
                            <ng-container *ngIf="item.selectedProperties.length">
                                <div class="parameters" *ngFor="let property of item.selectedProperties;">
                                    <span>{{ property }}</span>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="item-price">
                        <div class="card-product-counter" [ngStyle]="item.type === 'digital'  && {'visibility': 'hidden'}">
                            <button class="counter-control" [class.disabled]="item.selectedQuantity === 1" (click)="decreaseQuantity(item)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                    <rect y="4" width="10" height="2" rx="1" fill="currentColor"/>
                                </svg>
                            </button>
                            <input class="quantity-control"
                                   [value]="item.selectedQuantity"
                                   [min]="1"
                                   [max]="item.quantity"
                                   type="number"
                                   (change)="setQuantity(item, $event.target['value'])"
                            />
                            <button class="counter-control"[class.disabled]="(item.quantity !== null) && (item.selectedQuantity >= item.quantity)" (click)="increaseQuantity(item)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4 9C4 9.55228 4.44772 10 5 10C5.55228 10 6 9.55228 6 9V6H9C9.55229 6 10 5.55228 10 5C10 4.44772 9.55228 4 9 4H6V1C6 0.447715 5.55228 0 5 0C4.44772 0 4 0.447716 4 1V4H1C0.447715 4 0 4.44772 0 5C0 5.55228 0.447715 6 1 6H4V9Z" fill="currentColor"/>
                                </svg>
                            </button>
                        </div>
                        <div class="price">
                            <span>{{item.price * item.selectedQuantity}} {{ currency }}</span>
                        </div>
                    </div>
                    <div class="close-icon" (click)="removeItemFromCart(item)">×</div>
                </div>
                <div class="total-price" *ngIf="deliveryVariants.length && ((isAllDigitalProducts$ | async) === false)">
                    <p>{{ 'delivery'| translate }}: {{ deliveryPrice }} {{ currency }}</p>
                </div>
                <div class="total-price">
                    <p>{{ 'shop_cart_total_price'| translate }}: {{ (cartTotalPrice$ | async) + deliveryPrice }} {{ currency }}</p>
                </div>
            </div>
            <div
                [class]="classes?.cartFormWrapper"
                class="cart-form-wrapper">
                <div [class]="classes?.form"
                     class="cart-form"
                     [id]="getWidgetId(widget)">
                    <form class="sp-lpc-form" [class]="classes?.formContainer" [formGroup]="formGroup">
                        <div formArrayName="formArray">
                            <div
                                *ngFor="let control of formArrayControls; index as index"
                                class="field-block"
                                [class]="classes.formField"
                                [formGroupName]="index"
                                [ngSwitch]="true"
                            >
                                <ng-container *ngSwitchCase="typeGuards.isInput(control.value)">
                                    <label [class.hidden]="control.value.type === formInputType.hidden" *ngIf="inputSettings.showLabels">
                                        {{ getControlValue(control, "label")}}{{ getControlValue(control, "required") ? " *" : "" }}
                                    </label>
                                    <ng-container *ngIf="!control.value.isDynamicPlaceholderEnabled">
                                        <input
                                            [id]="getInputIdForSelenium(control)"
                                            [class]="classes.formControl"
                                            [class.is-invalid]="control.invalid"
                                            [formControlName]="'value'"
                                            [type]="getControlValue(control, 'htmlInputType')"
                                            [placeholder]="getControlValue(control, 'placeholder') | translate"
                                            [value]="control.value.type === formInputType.hidden ? getControlValue(control, 'value') : ''"
                                            (focusout)="refreshErrorMessageValue()"
                                        />
                                    </ng-container>
                                    <ng-container *ngIf="control.value.type === 'phone' && control.value.isDynamicPlaceholderEnabled">
                                        <ngx-intl-tel-input
                                            [class]="'intl-size-' + inputSettings.inputSize"
                                            [formControlName]="'value'"
                                            [cssClass]="classes?.formControl"
                                            [class.is-invalid]="control.invalid"
                                            [enableAutoCountrySelect]="true"
                                            [selectFirstCountry]="false"
                                            [separateDialCode]="true"
                                            [enablePlaceholder]="true"
                                            [searchCountryFlag]="true"
                                            [phoneValidation]="true"
                                            [searchCountryField]="[SearchCountryField.All]"
                                            [excludeCountries]="excludeCountries"
                                            [selectedCountryISO]="CountryISO"
                                            [numberFormat]="PhoneNumberFormat.International">
                                        </ngx-intl-tel-input>
                                    </ng-container>

                                    <ng-container *ngIf="control.invalid && control.touched && showErrorMessage">
                                        <div class="text-error-msg-container">
                                            <div class="text-error-msg">
                                                {{ inputErrors(control)[0] | translate }}
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="getControlValue(control, 'htmlInputType') === 'tel' && control.invalid && control.touched && control.value.isDynamicPlaceholderEnabled">
                                        <div class="text-error-msg-container">
                                            <div class="text-error-msg">{{ 'control_errors_phone_format' | translate: { PHONE_FORMAT: getPhoneErrorMsg() } }}</div>
                                        </div>
                                    </ng-container>
                                </ng-container>

                                <ng-container *ngSwitchCase="typeGuards.isTextarea(control.value)">
                                    <label *ngIf="inputSettings.showLabels">
                                        {{ getControlValue(control, "label") }}{{ getControlValue(control, "required") ? " *" : "" }}
                                    </label>
                                    <textarea
                                        [class]="classes?.formControlTextarea"
                                        [class.is-invalid]="control.invalid"
                                        class="sp-form-input"
                                        [formControlName]="'value'"
                                        [placeholder]="getControlValue(control, 'placeholder')"
                                        (focusout)="refreshErrorMessageValue()"></textarea>

                                    <ng-container *ngIf="control.invalid && control.touched && showErrorMessage">
                                        <div class="text-error-msg-container">
                                            <div class="text-error-msg">{{ inputErrors(control)[0] | translate }}</div>
                                        </div>
                                    </ng-container>
                                </ng-container>

                                <ng-container *ngSwitchCase="typeGuards.isCheckbox(control.value)">
                                    <label class="sp-checkbox">
                                        <input
                                            [id]="getInputIdForSelenium(control)"
                                            [class]="classes.spCheckbox"
                                            [value]="getControlValue(control, 'value')"
                                            [type]="getControlValue(control, 'htmlInputType')"
                                            (change)="changeCheckBoxValue(control)"
                                        />
                                        <span *ngIf="getControlValue(control, 'required')" class="checkbox-text" [innerHTML]="' ' + (getControlValue(control, 'label') | removePTags) + ' *'"></span>
                                        <span *ngIf="!getControlValue(control, 'required')" class="checkbox-text" [innerHTML]="' ' + (getControlValue(control, 'label') | removePTags)"></span>
                                    </label>
                                    <ng-container *ngIf="control.invalid && control.touched && showErrorMessage">
                                        <div class="text-error-msg-container">
                                            <div class="text-error-msg">
                                                {{ inputErrors(control)[0] | translate }}
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>

                                <ng-container *ngSwitchCase="typeGuards.isRadio(control.value)">
                                    <label *ngIf="inputSettings.showLabels">
                                        {{ getControlValue(control, "label") }}{{ getControlValue(control, "required") ? " *" : "" }}
                                    </label>
                                    <div class="radio-options" *ngFor="let option of getControlValue(control, 'options')">
                                        <label class="sp-radio">
                                            <input
                                                [id]="getInputIdForSelenium(control)"
                                                [class]="classes.spRadio"
                                                [type]="getControlValue(control, 'htmlInputType')"
                                                [formControlName]="'value'"
                                                [checked]="option.selected"
                                                (change)="changeCheckRadioValue(control, option)"
                                            />
                                            <span> {{ option.label }}</span>
                                        </label>
                                    </div>
                                    <ng-container *ngIf="control.invalid && control.touched">
                                        <div class="text-error-msg-container">
                                            <div class="text-error-msg">{{ inputErrors(control)[0] | translate }}</div>
                                        </div>
                                    </ng-container>
                                </ng-container>

                                <ng-container *ngSwitchCase="typeGuards.isSelect(control.value)">
                                    <label *ngIf="inputSettings.showLabels">
                                        <label *ngIf="inputSettings.showLabels">
                                            {{ getControlValue(control, "label") }}{{ getControlValue(control, "required") ? " *" : "" }}
                                        </label>
                                    </label>
                                    <select
                                        [id]="getInputIdForSelenium(control)"
                                        [class]="classes.formControl"
                                        (change)="changeSelectValue(control, $event)"
                                    >
                                        <option [ngValue]="null" [selected]="getSelected(getControlValue(control, 'options'))" disabled>
                                            {{ "select_value" | translate }}
                                        </option>
                                        <option
                                            *ngFor="let option of getControlValue(control, 'options')"
                                            [value]="option.value"
                                            [selected]="option.selected"
                                        >
                                            {{ option.label }}
                                        </option>
                                    </select>
                                    <ng-container *ngIf="control.invalid && control.touched">
                                        <div class="text-error-msg-container">
                                            <div class="text-error-msg">{{ inputErrors(control)[0] | translate }}</div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                        <ng-container *ngIf="deliveryVariants.length && ((isAllDigitalProducts$ | async) === false)">
                            <ng-container [formGroup]="deliveryVariant">
                                <label class="delivery-label">{{ 'delivery_variants' | translate }}</label>
                                <div class="delivery-options" *ngFor="let delivery of deliveryVariants; let i = index">
                                    <ng-container *ngIf="delivery.isDeliveryCondition">
                                        <label *ngIf="((cartTotalPrice$ | async) >= (delivery?.minPrice ? delivery?.minPrice : 0)) && ((cartTotalPrice$ | async) <= (delivery?.maxPrice ? delivery?.maxPrice : 9999999))">
                                            <input [class]="classes?.spRadio" [value]="i" type="radio" formControlName="delivery"/>
                                            <span class="delivery-text"> {{ delivery.name }} - {{ delivery.price }} {{ currency }}</span>
                                            <p class="delivery-text-small">
                                                {{ delivery.description }}
                                            </p>
                                        </label>
                                    </ng-container>
                                    <ng-container *ngIf="!delivery.isDeliveryCondition">
                                        <label>
                                            <input [class]="classes?.spRadio" [value]="i" type="radio" formControlName="delivery"/>
                                            <span class="delivery-text"> {{ delivery.name }} - {{ delivery.price }} {{ currency }}</span>
                                            <p class="delivery-text-small">
                                                {{ delivery.description }}
                                            </p>
                                        </label>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="deliveryVariant.invalid && showErrorMessage">
                                <div class="sp-lpc-form">
                                    <div class="field-block">
                                        <div class="text-error-msg-container">
                                            <div class="text-error-msg">{{ "control_errors_required" | translate }}</div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="activePaymentElements.length > 1">
                            <div [class]="classes?.formField">
                                <label *ngIf="inputSettings.showLabels">{{ "payment_method" | translate }}</label>
                                <div class="type-dropdown-default" dropdown>
                                    <button
                                        class="btn btn-default dropdown-toggle custom-btn"
                                        data-toggle="dropdown"
                                        dropdownToggle>
                                        <div>
                                            <img
                                                [src]="getPaymentIconSrc(paymentElement.type)"
                                                [alt]="paymentElement.type"
                                            />
                                            <span>{{ getPaymentElementLabel(paymentElement.type, currentPaymentMethodIndex) | translate }}</span>
                                        </div>
                                        <span class="caret"></span>
                                    </button>

                                    <ul class="dropdown-menu" *dropdownMenu>
                                        <ng-container *ngFor="let payment of activePaymentElements; let i = index">
                                            <li (click)="changeCurrentPaymentMethodIndex(i)" [class.selected]="i === currentPaymentMethodIndex">
                                                <img
                                                    [src]="getPaymentIconSrc(payment.type)"
                                                    [alt]="getPaymentElementLabel(payment.type, i) | translate"
                                                />
                                                <span>{{ getPaymentElementLabel(payment.type, i) | translate }}</span>
                                            </li>
                                        </ng-container>
                                    </ul>

                                </div>
                            </div>
                        </ng-container>

                        <div [class]="classes?.buttonWrapper">
                            <a
                                [id]="widget.id + '-submit'"
                                class="submit-btn"
                                [class]="getButtonStyles(formButtonSettings)"
                                [class.sp-ui-button-loading]="formGroup.disabled"
                                (click)="submitForm()">
                                <span *ngIf="formButtonSettings.buttonIcon?.isActive"
                                    class="icon"
                                    [innerHTML]="getIcon(formButtonSettings.buttonIcon?.icon)"></span>
                                {{ formButtonSettings.label }}
                            </a>
                        </div>

                        <div *ngIf="isShowInvalidPaymentMethodError" class="error-reason text-danger">
                            {{ "invalid_payment_method_error" | translate }}
                            </div>

                        <div *ngIf="isShowSuccessMessage" style="margin-top: 15px;color: #7fbc6c;">
                            {{ "custom_payment_success" | translate: { PAYMENT_NAME: getPaymentElementLabel(paymentElement.type, currentPaymentMethodIndex)  } }}
                        </div>
                    </form>
                </div>
            </div>
        </ng-container>
    </ng-container>
    <ng-template #emptyState>
        <div [class]="classes?.cartEmptyState">
            <svg xmlns="http://www.w3.org/2000/svg" width="160" height="160" viewBox="0 0 160 160" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M44 50C44 49.4477 44.4477 49 45 49H51.5C51.9355 49 52.3209 49.2818 52.4529 49.6968L55.7312 60H106.56C108.454 60 109.873 61.732 109.502 63.5883L104.302 89.5883C104.022 90.9906 102.79 92 101.36 92H63C61.5416 92 60.0082 92.1774 58.866 92.8212C58.3131 93.1328 57.8608 93.5488 57.5408 94.1106C57.2196 94.6745 57 95.4411 57 96.5C57 98.5687 57.8363 99.6368 58.6923 100.226C59.6121 100.86 60.6504 101 61 101H101C101.552 101 102 101.448 102 102C102 102.552 101.552 103 101 103H61C60.3496 103 58.8879 102.79 57.5577 101.874C56.1637 100.913 55 99.2313 55 96.5C55 95.1589 55.2804 94.038 55.803 93.1207C56.3267 92.2012 57.0619 91.5422 57.884 91.0788C59.0692 90.4108 60.4495 90.1414 61.7106 90.046L54.0399 61.2806L50.7688 51H45C44.4477 51 44 50.5523 44 50ZM56.3016 62L63.7683 90H101.36C101.837 90 102.247 89.6635 102.341 89.1961L107.541 63.1961C107.665 62.5773 107.191 62 106.56 62H56.3016Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M78.2929 71.2929C78.6834 70.9024 79.3166 70.9024 79.7071 71.2929L83 74.5858L86.2929 71.2929C86.6834 70.9024 87.3166 70.9024 87.7071 71.2929C88.0976 71.6834 88.0976 72.3166 87.7071 72.7071L84.4142 76L87.7071 79.2929C88.0976 79.6834 88.0976 80.3166 87.7071 80.7071C87.3166 81.0976 86.6834 81.0976 86.2929 80.7071L83 77.4142L79.7071 80.7071C79.3166 81.0976 78.6834 81.0976 78.2929 80.7071C77.9024 80.3166 77.9024 79.6834 78.2929 79.2929L81.5858 76L78.2929 72.7071C77.9024 72.3166 77.9024 71.6834 78.2929 71.2929Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M66 103C63.2386 103 61 105.239 61 108C61 110.761 63.2386 113 66 113C68.7614 113 71 110.761 71 108C71 105.239 68.7614 103 66 103ZM59 108C59 104.134 62.134 101 66 101C69.866 101 73 104.134 73 108C73 111.866 69.866 115 66 115C62.134 115 59 111.866 59 108Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M101 103C98.2386 103 96 105.239 96 108C96 110.761 98.2386 113 101 113C103.761 113 106 110.761 106 108C106 105.239 103.761 103 101 103ZM94 108C94 104.134 97.134 101 101 101C104.866 101 108 104.134 108 108C108 111.866 104.866 115 101 115C97.134 115 94 111.866 94 108Z" fill="currentColor"/>
            </svg>
            <p class="sp-ui-text">{{ 'cart_empty_state_label' | translate }}</p>
        </div>
    </ng-template>
</div>
