import type { JssStyle } from 'jss';
import type { SpecificBlockStructureEntity } from '@web/types';

import { EHorizontalPosition, ESize } from '@common/enums';

import { encodeFilePreviewUrl } from '@components/sp-file-manager';
import { websiteColorCss } from '@libs/themes/src/variants/website/utils/variables';

const horizontalPositionMatrix = {
    [EHorizontalPosition.L]: 'flex-start',
    [EHorizontalPosition.M]: 'center',
    [EHorizontalPosition.R]: 'flex-end',
};

const horizontalTextPositionMatrix = {
    [EHorizontalPosition.L]: 'left',
    [EHorizontalPosition.M]: 'center',
    [EHorizontalPosition.R]: 'right',
};

function getMobileIconHorizontal(blockData: SpecificBlockStructureEntity['data']): JssStyle {
    switch (blockData.iconHorizontalPosition) {
        case EHorizontalPosition.R:
            return { justifyContent: 'space-between', flexDirection: 'row-reverse' };
        case EHorizontalPosition.L:
        default:
            return { justifyContent: 'space-between', flexDirection: 'row' };
    }
}

function getMobileIconMargin(blockData: SpecificBlockStructureEntity['data']): JssStyle {
    switch (blockData.iconHorizontalPosition) {
        case EHorizontalPosition.L:
            return { marginRight: '10px' };
        case EHorizontalPosition.R:
            return { marginLeft: '10px' };
        default:
            return { marginRight: '10px' };
    }
}

function getCartIconSize(size: ESize): number {
    switch (size) {
        case ESize.S:
            return 19;
        case ESize.M:
            return 24;
        case ESize.L:
            return 29;
    }
}

function getCartIconWrapperSize(size: ESize): number {
    switch (size) {
        case ESize.S:
            return 40;
        case ESize.M:
            return 48;
        case ESize.L:
            return 56;
    }
}

function getBlockInnerStyles(block: SpecificBlockStructureEntity, isConstructor: boolean) {
    const { styles, data, mobileStyles } = block;
    const blockInner: JssStyle = {
        position: 'relative',
        paddingTop: styles.paddingTop + 'px',
        paddingBottom: styles.paddingBottom + 'px',
    };

    const blockInnerMobile: JssStyle = {
        paddingTop: mobileStyles.paddingTop + 'px',
        paddingBottom: mobileStyles.paddingBottom + 'px',
    };

    if (data.hideContentOnMobile) {
        blockInnerMobile.display = 'none';
        blockInnerMobile['& .mobile-column-direction'] = {
            flexDirection: 'column',
        };
    }

    if (isConstructor) {
        return {
            ...blockInner,
            '&.mobileViewInner': blockInnerMobile,
        };
    }

    return {
        ...blockInner,
    };
}

function getBlockWrapStyles(block: SpecificBlockStructureEntity, isConstructor: boolean, fileManagerCdnUrl: string) {
    const { styles, data, mobileStyles, mobileData } = block;

    const blockWrap: JssStyle = {
        background: websiteColorCss(styles.backgroundColor as string),
        '& .desktop-content': {
            display: 'block',
        },
        '& .mobile-content': {
            display: 'none',
        },
        '& .mobile-cart-icon .cart-icon-wrapper': {
            color: `${data.iconColor} !important`,
        },
        '& .mobile-content .cart-icon-wrapper': {
            color: `${data.iconColor} !important`,
            background: 'transparent !important',
            boxShadow: 'none !important',
            width: `${getCartIconWrapperSize(data.iconSize)}px`,
            height: `${getCartIconWrapperSize(data.iconSize)}px`,

            '& svg': {
                width: `${getCartIconSize(data.iconSize)}px`,
                height: `${getCartIconSize(data.iconSize)}px`,
            },
        },
        '@media (max-width: 992px)': {
            '& .desktop-content': {
                display: data.hideContentOnMobile ? 'none' : 'block',
            },
            '& .mobile-content': {
                display: data.hideContentOnMobile ? 'block' : 'none',
            },
        },
    };

    const blockMobileWrap: JssStyle = {
        '& .desktop-content': {
            display: data.hideContentOnMobile ? 'none' : 'block',
        },
        '& .mobile-content': {
            display: data.hideContentOnMobile ? 'block' : 'none',
        },
    };

    if (data.backgroundImage) {
        blockWrap.background = `${encodeFilePreviewUrl(data.backgroundImage, fileManagerCdnUrl)}, ${blockWrap.background}`;
        blockWrap.backgroundPosition = styles.backgroundPosition;
        blockWrap.backgroundRepeat = styles.backgroundRepeat;
        blockWrap.backgroundSize = styles.backgroundSize;
    }

    if (mobileData.backgroundImage) {
        blockMobileWrap.background = `${encodeFilePreviewUrl(mobileData.backgroundImage, fileManagerCdnUrl)}, ${
            blockMobileWrap.background
        }`;
        blockMobileWrap.backgroundPosition = mobileStyles.backgroundPosition;
        blockMobileWrap.backgroundRepeat = mobileStyles.backgroundRepeat;
        blockMobileWrap.backgroundSize = mobileStyles.backgroundSize;
    }

    if (!isConstructor && data.pinOnTheTop) {
        blockWrap.position = 'fixed';
        blockWrap.top = '0';
        blockWrap.left = '0';
        blockWrap.right = '0';
        blockWrap.zIndex = '2';
    }

    if (!isConstructor && data.pinOnTheBottom) {
        blockWrap.position = 'fixed';
        blockWrap.bottom = '0';
        blockWrap.left = '0';
        blockWrap.right = '0';
        blockWrap.zIndex = '2';
    }

    if (isConstructor) {
        return {
            ...blockWrap,
            '&.mobileViewWrap': blockMobileWrap,
        };
    }

    if (data.hideContentOnMobile) {
        return {
            ...blockWrap,
            '@media (max-width: 992px)': {
                '& .desktop-content': {
                    display: 'none',
                },
                '& .mobile-content': {
                    display: 'block',
                },
            },
            // '@media (max-width: 992px) and (orientation: landscape)': {
            //     '& .desktop-content': {
            //         display: 'none',
            //     },
            //     '& .mobile-content': {
            //         display: 'block',
            //     },
            // },
        };
    }

    return {
        ...blockWrap,
        '& .mobile-content': {
            display: 'none',
        },
    };
}

function getMobileContentWrapperStyles(block: SpecificBlockStructureEntity): JssStyle {
    const { mobileStyles } = block;
    return {
        paddingTop: mobileStyles.paddingTop + 'px',
        paddingBottom: mobileStyles.paddingBottom + 'px',
        paddingRight: mobileStyles.paddingRight + 'px',
        paddingLeft: mobileStyles.paddingLeft + 'px',
        background: websiteColorCss(mobileStyles.backgroundColor as string),
    };
}

function getMobileWidgetsContainer(block: SpecificBlockStructureEntity): JssStyle {
    const { data } = block;
    return {
        '&.show': {
            '& .specific-mobile-widget': {
                marginTop: '15px',
            },
        },
        '& .specific-mobile-menu': {
            justifyContent: horizontalPositionMatrix[data.horizontalPosition] + ' !important',
            '& .widget-specific-nav-align': {
                flexDirection: 'column',
                '& .sp-menu-item span': {
                    textAlign: horizontalTextPositionMatrix[data.horizontalPosition] + ' !important',
                    '& a': {
                        color: websiteColorCss(data.textColor as string),
                    },
                },
                '& .sp-menu-item': {
                    '& .dropdown-submenu-list': {
                        position: 'relative',
                        top: 'auto',
                        left: 'auto',
                        right: 'auto',
                        background: 'none',
                        boxShadow: 'none',
                        padding: 0,
                        minWidth: 'auto',
                        paddingLeft: '15px',
                    },
                    '& .caret-icon': {
                        display: 'none',
                    },
                    '& .sp-menu-item-without-link': {
                        '&.desktop-item': {
                            display: 'none',
                        },
                        '&.mobile-item': {
                            display: 'inline-block',
                            marginRight: '4px',
                        },
                    },
                    '& .caret-icon-mobile': {
                        display: 'inline-block',
                    },
                    '&.open': {
                        '& .dropdown-submenu-list': {
                            display: 'block',
                        },
                        '& .caret-icon-mobile': {
                            rotate: '180deg',
                            padding: '0px 6px 4px 6px',
                        },
                    },
                },
            },
        },
        '& .widget-specific-nav-align': {
            justifyContent: horizontalPositionMatrix[data.horizontalPosition] + ' !important',
            '&.sp-button-group': {
                alignItems: horizontalPositionMatrix[data.horizontalPosition] + ' !important',
                flexDirection: 'column',
            },
        },
        '& .sp-ui-text': {
            textAlign: horizontalTextPositionMatrix[data.horizontalPosition] + ' !important',
            '& p, h1, h2, h3, h4, span, li, em, strong, b': {
                color: websiteColorCss(data.textColor as string) + ' !important',
            },
        },
    };
}

function getMobileIconWrapperStyles(block: SpecificBlockStructureEntity): JssStyle {
    const { data } = block;
    return {
        display: 'none',
        '@media (max-width: 992px)': {
            display: 'flex',
            alignItems: 'center',
            ...getMobileIconHorizontal(data),
            '& .mobile-icon': {
                display: 'block',
            },
        },
        // '@media (max-width: 992px) and (orientation: landscape)': {
        //     display: 'flex',
        //     alignItems: 'center',
        //     ...getMobileIconHorizontal(data),
        //     '& .mobile-icon': {
        //         display: 'block',
        //     },
        // },
        '&.mobileViewWrap': {
            display: 'flex',
            alignItems: 'center',
            ...getMobileIconHorizontal(data),
            '& .mobile-icon': {
                display: 'block',
            },
        },
    };
}

function getMobileIconStyles(block: SpecificBlockStructureEntity): JssStyle {
    const { data } = block;
    return {
        '&:before': {
            backgroundColor: websiteColorCss(data.iconColor),
        },
        '&:after': {
            backgroundColor: websiteColorCss(data.iconColor),
        },
        '@media (max-width: 992px)': {
            display: data.hideContentOnMobile ? 'flex !important' : 'none',
            ...getMobileIconMargin(data),
        },
        // '@media (max-width: 992px) and (orientation: landscape)': {
        //     display: data.hideContentOnMobile ? 'flex !important' : 'none',
        //     ...getMobileIconMargin(data),
        // },
        '&.mobileWidgetViewElement': {
            display: data.hideContentOnMobile ? 'flex !important' : 'none',
            ...getMobileIconMargin(data),
        },
    };
}

export function getStyles(block: SpecificBlockStructureEntity, isConstructor: boolean = false, fileManagerCdnUrl: string): JssStyle {
    const blockWrap: JssStyle = getBlockWrapStyles(block, isConstructor, fileManagerCdnUrl);
    const blockWrapServer = { ...blockWrap };

    return {
        blockWrap,
        blockWrapServer,
        blockInner: getBlockInnerStyles(block, isConstructor),
        mobileContentWrapper: getMobileContentWrapperStyles(block),
        mobileIconWrapper: getMobileIconWrapperStyles(block),
        mobileWidgetsContainer: getMobileWidgetsContainer(block),
        mobileIcon: getMobileIconStyles(block),
        mobileIconLines: {
            backgroundColor: websiteColorCss(block.data.iconColor),
        },
    };
}
