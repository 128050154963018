<ng-container *ngIf="isModalForm">
    <div [class]="rootFormWithPositionClasses">
        <div [class]="classes?.buttonWrapper" class="button-without-form">
            <a [id]="widget.id + '-sp'" [class]="getButtonStyles(formButtonSettings)" (click)="showPaymentModal()">
                <span *ngIf="formButtonSettings.buttonIcon?.isActive"
                    class="icon"
                    [innerHTML]="getIcon(formButtonSettings.buttonIcon?.icon)"></span>
                {{ formButtonSettings.label }}
            </a>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="isStaticForm">
    <web-builder-payment-form-static
        [classes]="classes"
        [elements]="elements"
        [widgetData]="widgetData"
        [widget]="widget">
    </web-builder-payment-form-static>
</ng-container>
