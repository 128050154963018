<div class="sp-chat-bot-group mobile-column-direction widget-specific-nav-align" [class]="classes?.chatBotWrapper">
    <ng-container *ngFor="let chatBot of chatBots">
        <a class="sp-chat-bot-item" [class]="getStyleList(chatBot)" [attr.href]="getChatBotLink(chatBot)" [target]="'_blank'">
            <ng-container *ngIf="chatBot.type === EButtonType.icon">
                <img [src]="getSocialNetworkIconSrcByChatBot(chatBot)" [alt]="chatBot.socialNetwork">
            </ng-container>
            <ng-container *ngIf="chatBot.type === EButtonType.button">
                <div [innerHTML]="getIcon(chatBot.socialNetwork)" class="icon"></div>
                <span>{{ chatBot.name }}</span>
            </ng-container>
        </a>
    </ng-container>
</div>
