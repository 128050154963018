<div [class]="rootFormWithPositionClasses">
    <form class="sp-lpc-form sp-form-root" [class]="classes.formContainer" [formGroup]="formGroup">
        <div formArrayName="formArray" [class]="classes.formFieldsContainer"
             [class.sp-ui-button-loading]="formGroup.disabled"
             [class.btn-loading]="isLoading">
            <div
                *ngFor="let control of formArrayControls; index as index"
                class="field-block sp-form-control"
                [class]="classes.formField"
                [formGroupName]="index"
                [ngSwitch]="true"
            >
                <ng-container *ngSwitchCase="typeGuards.isInput(control.value)">
                    <label *ngIf="inputSettings.showLabels" [class.hidden]="isControlTypeHidden(control.value.type)">
                        {{ getControlValue(control, "label") }}{{ getControlValue(control, "required") ? " *" : "" }}
                    </label>
                    <ng-container *ngIf="!control.value.isDynamicPlaceholderEnabled && control.value.type !== 'file'">
                        <input
                            class="sp-form-input"
                            [id]="getInputIdForSelenium(control)"
                            [class]="classes.formControl"
                            [class.is-invalid]="control.invalid"
                            [formControlName]="'value'"
                            [type]="getControlValue(control, 'htmlInputType')"
                            [placeholder]="isControlTypeHidden(control.value.type) ? '' : getControlValue(control, 'placeholder')"
                            (focusout)="refreshErrorMessageValue()"
                            [value]="isControlTypeHidden(control.value.type) ? getControlValue(control, 'value') : ''"
                        />
                    </ng-container>
                    <ng-container *ngIf="control.value.type === 'phone' && control.value.isDynamicPlaceholderEnabled">
                        <ngx-intl-tel-input
                            [class]="'intl-size-' + inputSettings.inputSize"
                            [formControlName]="'value'"
                            [cssClass]="classes?.formControl"
                            [class.is-invalid]="control.invalid"
                            [enableAutoCountrySelect]="true"
                            [selectFirstCountry]="false"
                            [separateDialCode]="true"
                            [enablePlaceholder]="true"
                            [searchCountryFlag]="true"
                            [phoneValidation]="true"
                            [searchCountryField]="[SearchCountryField.All]"
                            [excludeCountries]="excludeCountries"
                            [selectedCountryISO]="CountryISO"
                            [numberFormat]="PhoneNumberFormat.International">
                        </ngx-intl-tel-input>
                    </ng-container>
                    <ng-container *ngIf="control.value.type === 'file'">
                        <ngx-dropzone (change)="onFileUpload($event)">
                            <ngx-dropzone-label>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 11C3.55228 11 4 11.4477 4 12V17C4 18.6568 5.34315 20 7 20H17C18.6568 20 20 18.6568 20 17V12C20 11.4477 20.4477 11 21 11C21.5523 11 22 11.4477 22 12V17C22 19.7614 19.7614 22 17 22H7C4.23857 22 2 19.7614 2 17V12C2 11.4477 2.44772 11 3 11Z" fill="#3A83A2"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4001 3.2C11.7556 2.93333 12.2445 2.93333 12.6001 3.2L16.6001 6.2C17.0419 6.53137 17.1314 7.15817 16.8001 7.6C16.4687 8.04183 15.8419 8.13137 15.4001 7.8L13.0001 6V15C13.0001 15.5523 12.5523 16 12.0001 16C11.4478 16 11.0001 15.5523 11.0001 15V6L8.60006 7.8C8.15823 8.13137 7.53143 8.04183 7.20006 7.6C6.86869 7.15817 6.95823 6.53137 7.40006 6.2L11.4001 3.2Z" fill="#3A83A2"/>
                                </svg>
                                <p>{{ control.value.placeholder | translate }}</p>
                            </ngx-dropzone-label>
                            <ngx-dropzone-preview *ngFor="let file of files"
                                                  [removable]="true"
                                                  (removed)="onFileRemove(file)">
                                <ngx-dropzone-label>{{ file.name }}</ngx-dropzone-label>
                            </ngx-dropzone-preview>
                        </ngx-dropzone>
                        <div class="text-error-msg-container" *ngIf="filesSize > 500">
                            <div class="text-error-msg">{{ 'control_file_error' | translate }}</div>
                        </div>
                        <div class="text-error-msg-container" *ngIf="isFilesError">
                            <div class="text-error-msg">{{ 'control_file_error_required' | translate }}</div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="control.invalid && control.touched && showErrorMessage">
                        <div class="text-error-msg-container">
                            <div class="text-error-msg">{{ inputErrors(control)[0] | translate }}</div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="getControlValue(control, 'htmlInputType') === 'tel' && phoneError">
                        <div class="text-error-msg-container">
                            <div class="text-error-msg">{{ phoneError }}</div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="getControlValue(control, 'htmlInputType') === 'tel' && control.invalid && this.showErrorMessage && control.value.isDynamicPlaceholderEnabled">
                        <div class="text-error-msg-container">
                            <div class="text-error-msg">{{ 'control_errors_phone_format' | translate: { PHONE_FORMAT: getPhoneErrorMsg() } }}</div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="getControlValue(control, 'htmlInputType') === 'email' && emailError">
                        <div class="text-error-msg-container">
                            <div class="text-error-msg">{{ emailError }}</div>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="typeGuards.isTextarea(control.value)">
                    <label *ngIf="inputSettings.showLabels">
                        {{ getControlValue(control, "label") }}{{ getControlValue(control, "required") ? " *" : "" }}
                    </label>
                    <textarea
                        [class]="classes?.formControlTextarea"
                        [class.is-invalid]="control.invalid"
                        class="sp-form-input"
                        [formControlName]="'value'"
                        [placeholder]="getControlValue(control, 'placeholder')"
                        (focusout)="refreshErrorMessageValue()"></textarea>

                        <ng-container *ngIf="control.invalid && control.touched && showErrorMessage">
                            <div class="text-error-msg-container">
                                <div class="text-error-msg">{{ inputErrors(control)[0] | translate }}</div>
                            </div>
                        </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="typeGuards.isCheckbox(control.value)">
                    <label class="sp-checkbox">
                        <input
                            [id]="getInputIdForSelenium(control)"
                            class="checkbox-input sp-form-checkbox"
                            [class]="classes.spCheckbox"
                            [class.is-invalid]="control.invalid"
                            [value]="getControlValue(control, 'value')"
                            [type]="getControlValue(control, 'htmlInputType')"
                            (change)="changeCheckBoxValue(control)"
                        />
                        <span *ngIf="getControlValue(control, 'required')" class="checkbox-text" [innerHTML]="' ' + (getControlValue(control, 'label') | removePTags) + ' *'"></span>
                        <span *ngIf="!getControlValue(control, 'required')" class="checkbox-text" [innerHTML]="' ' + (getControlValue(control, 'label') | removePTags)"></span>
                    </label>
                    <ng-container *ngIf="control.invalid && control.touched && showErrorMessage">
                        <div class="text-error-msg-container">
                            <div class="text-error-msg">
                                {{ inputErrors(control)[0] | translate }}
                            </div>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="typeGuards.isRadio(control.value)">
                    <label *ngIf="inputSettings.showLabels">
                        {{ getControlValue(control, "label") }}{{ getControlValue(control, "required") ? " *" : "" }}
                    </label>
                    <div *ngFor="let option of getControlValue(control, 'options')" class="radio-options">
                        <label>
                            <input
                                [id]="getInputIdForSelenium(control)"
                                class="radio-input sp-form-radio"
                                [class]="classes.spRadio"
                                [type]="getControlValue(control, 'htmlInputType')"
                                [formControlName]="'value'"
                                [checked]="option.selected"
                                (change)="changeCheckRadioValue(control, option)"
                            />
                            <span class="radio-text"> {{ option.label }}</span>
                        </label>
                    </div>
                    <ng-container *ngIf="control.invalid && control.touched && showErrorMessage">
                        <div class="text-error-msg-container">
                            <div class="text-error-msg">{{ inputErrors(control)[0] | translate }}</div>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="typeGuards.isSelect(control.value)">
                    <label *ngIf="inputSettings.showLabels">
                        {{ getControlValue(control, "label") }}{{ getControlValue(control, "required") ? " *" : "" }}
                    </label>
                    <ng-select
                        [appendTo]="'body'"
                        [class]="classes?.formControl"
                        [id]="getInputIdForSelenium(control)"
                        [placeholder]="'select_value' | translate"
                        [items]="getControlValue(control, 'options')"
                        (change)="changeSelectValue(control, $event)"
                        [(ngModel)]="getControlValue(control, 'htmlInputType') === 'multiselect' ? dropdownListValues[index] : dropdownListValues[index][0]"
                        [ngModelOptions]="{ standalone: true }"
                        [multiple]="getControlValue(control, 'htmlInputType') === 'multiselect'"
                        [closeOnSelect]="false">
                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                            <div class="ng-value" *ngFor="let item of items">
                                <span class="ng-value-icon left" (click)="clear(item);" aria-hidden="true">×</span>
                                <span class="ng-value-label">{{ item.label }}</span>
                            </div>
                        </ng-template>
                    </ng-select>
                    <ng-container *ngIf="isShowSelectErr(control)">
                        <div class="text-error-msg-container">
                            <div class="text-error-msg">{{ 'control_errors_required' | translate }}</div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>

            <div [class]="classes?.buttonWrapper">
                <a
                    [id]="widget.id + '-submit'"
                    class="submit-btn sp-form-submit"
                    [class]="getButtonStyles(formButtonSettings)"
                    (click)="submitForm()">
                    <span *ngIf="formButtonSettings.buttonIcon?.isActive"
                        class="icon"
                        [innerHTML]="getIcon(formButtonSettings.buttonIcon?.icon)"></span>
                    {{ formButtonSettings.label }}
                </a>
            </div>
        </div>
    </form>
</div>
