import { type EButtonStyleType, type EFontStyle, type EHorizontalPosition, type ESize, type InputBorderEnum } from '@common/enums';
import { type ThemedBaseButtonProp } from '@common/types/base-button-widget.type';
import { type ThemeButtonHover, type ThemeColorValue } from '@libs/themes';
import type { JssStyle } from 'jss';
import { type ProductCard } from '@common/types/product-card.type';

export type ProductCardWidgetData = {
    element: ProductCard;
    cardViewType: CardViewType;
    cardKindViewType: CardKindViewType;
    cardModalViewType: ViewModalType;
    basketButton: ButtonSettings;
    showMoreButton: ButtonSettings;
    cardSettings: CardSettings;
    previewModalSettings: PreviewModalSettings;
    modalSettings: ModalSettings;
    isShowCardSettings: boolean;
    previewHorizontalPosition: EHorizontalPosition;
    selectedFromCatalog: boolean;
};

export type ButtonSettings = {
    label: string;
    action: ButtonActionType;
    styleType: EButtonStyleType;
    size: ESize;
    buttonBase: ThemedBaseButtonProp;
    buttonHover?: ThemeButtonHover;
    isShowButton?: boolean;
};

export type CardSettings = {
    general: GeneralSettings;
    gallery: GallerySettings;
    info: InfoSettings;
    typography: TypographySettings;
    productVariants: ProductVariantsSettings;
};

export type GallerySettings = {
    attachment: Attachment;
    aspectRatio: AspectRatio;
    backgroundColor: string;
    borderRadius: number;
    margin?: number;
    buttonColor?: string;
    buttonBackground?: string;
    paginationColor?: string;
    changeSlide?: boolean;
    fullByClick?: boolean;
};

export type InfoSettings = {
    backgroundColor: string;
    borderRadius: number;
    isBorder: boolean;
    borderWidth: ProductStyleSettings['borderWidth'];
    borderStyle: ProductStyleSettings['borderStyle'];
    borderColor: ProductStyleSettings['borderColor'];
    paddingTop: ProductStyleSettings['paddingTop'];
    paddingBottom: ProductStyleSettings['paddingBottom'];
    paddingLeft: ProductStyleSettings['paddingLeft'];
    paddingRight: ProductStyleSettings['paddingRight'];
};

export type TypographySettings = {
    titleSettings: {
        color: string;
        fontSize: number;
        fontStyle: Array<EFontStyle>;
        isShow?: boolean;
    };
    descriptionSettings: {
        color: string;
        fontSize: number;
        fontStyle: Array<EFontStyle>;
        isShow?: boolean;
    };
    priceSettings: {
        color: string;
        fontSize: number;
        fontStyle: Array<EFontStyle>;
        isShow?: boolean;
    };
    oldPriceSettings: {
        color: string;
        fontSize: number;
        fontStyle: Array<EFontStyle>;
        isShow?: boolean;
    };
};

export type ProductVariantsSettings = {
    showLabels: boolean;
    textColor: ThemeColorValue;
    inputBorderType: InputBorderEnum;
    inputTextColor: ThemeColorValue;
    backgroundColor: ThemeColorValue;
    borderColor: ThemeColorValue;
    borderRadius: number;
    inputSize: ESize;
};

export type GeneralSettings = {
    backgroundColor: string;
    borderRadius: number;
    padding: number;
    isBorder: boolean;
    borderWidth: ProductStyleSettings['borderWidth'];
    borderStyle: ProductStyleSettings['borderStyle'];
    borderColor: ProductStyleSettings['borderColor'];
    substrateColor?: string;
    galleryWidth?: string;
    contentWidth?: string;
};

export type PreviewModalSettings = {
    general: GeneralSettings;
    gallery: GallerySettings;
    info: InfoSettings;
    typography: TypographySettings;
};

export type ModalSettings = {
    general: GeneralSettings;
    typography: TypographySettings;
    productVariants: ProductVariantsSettings;
};

export type ProductStyleSettings = Pick<
    JssStyle,
    'borderWidth' | 'borderStyle' | 'borderColor' | 'paddingTop' | 'paddingBottom' | 'paddingLeft' | 'paddingRight'
>;

export enum AspectRatio {
    default = 'default',
    manual = 'manual',
    onoToOne = '1:1',
    wide = '16:9',
    verticalWide = '9:16',
    fourToThree = '4:3',
    threeToFour = '3:4',
    threeToTwo = '3:2',
    twoToThree = '2:3',
}

export enum Attachment {
    cover = 'cover',
    contain = 'contain',
}

export enum CardViewType {
    open,
    modal,
}

export enum ViewModalType {
    vertical,
    horizontal,
}

export enum CardKindViewType {
    first = 'first',
    second = 'second',
    third = 'third',
    fourth = 'fourth',
    fifth = 'fifth',
    sixth = 'sixth',
    seventh = 'seventh',
    eighth = 'eighth',
}

export enum ButtonActionType {
    basket,
    modal,
}

export enum CreateCardOption {
    create,
    catalog,
}

export type CatalogCard = {
    balance: {
        type: number;
        value: number;
    };
    categoryId: number;
    countSales: number;
    createdAt: string;
    id: number;
    images: string[];
    name: string;
    price: {
        id: number;
        currency: string;
        value: number;
    };
    type: number;
    userId: number;
    vendorCode: string;
    visible: boolean;
};

export type CatalogCategory = {
    id: number;
    name: string;
    parent_id: number;
    depth: number;
    path: number;
    children?: CatalogCategory[];
};

export enum CatalogProductType {
    physical = 1,
    digital = 2,
}

export type CatalogProductCard = {
    availability: number;
    balance: {
        type: number;
        value: number;
    };
    categoryId: number;
    categoryName: string;
    categorySections: Array<{
        categoryId: number;
        id: number;
        name: string;
        order: number;
        values: Array<{
            id: number;
            sectionId: number;
            key: string;
            value: string;
        }>;
    }>;
    description: string;
    id: number;
    images: string[];
    name: string;
    prices: Array<{
        id: number;
        currency: string;
        isDefault: boolean;
        value: number;
        oldValue: number;
        productId: number;
    }>;
    sections: Array<{
        id: number;
        name: string;
        productId: number;
        values: Array<{
            id: number;
            sectionId: number;
            key: string;
            value: string;
        }>;
    }>;
    seo: {
        name: string;
        description: string;
        keys: string;
    };
    shortDescription: string;
    type: number;
    userId: number;
    visible: number;
    vendorCode: string;
    productType: CatalogProductType;
};
