<div class="sp-social-group mobile-column-direction widget-specific-nav-align" [class]="classes?.socialWrapper">
    <ng-container *ngFor="let social of socials">
        <a class="sp-social-item" [class]="getStyleList(social)" [href]="getSocialLink(social)" [target]="'_blank'">
            <ng-container *ngIf="social.type === EButtonType.icon">
                <img [src]="getSocialIconSrcBySocial(social)" [alt]="social.socialNetwork">
            </ng-container>
            <ng-container *ngIf="social.type === EButtonType.button">
                <div [innerHTML]="getIcon(social.socialNetwork)" class="icon"></div>
                <span>{{ social.name }}</span>
            </ng-container>
        </a>
    </ng-container>
</div>
