import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
    selector: 'sp-video-preview',
    templateUrl: './sp-video-preview.component.html',
    styleUrls: ['./sp-video-preview.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpVideoPreviewComponent implements OnInit {
    @Input() public file: any;
    @Input() public isFreeTariff: boolean = true;

    @Output() public removeFileEvent: EventEmitter<void> = new EventEmitter();
    @Output() public replaceFileEvent: EventEmitter<void> = new EventEmitter();
    @Output() public addVideos: EventEmitter<any> = new EventEmitter();
    @Output() public openImagePreviewFm: EventEmitter<any> = new EventEmitter();
    @Output() public removePreviewImage: EventEmitter<any> = new EventEmitter();

    showPoster: boolean = false;

    public addVideo(): void {
        this.addVideos.emit();
    }
    public togglePreview(showPoster): void {
        this.showPoster = showPoster;
        this.removePreview();
    }

    removePreview(): void {
        this.removePreviewImage.emit();
    }

    public encodeFilePreviewUrl(): string {
        return `url('${this.file.previewUrl}')`;
    }

    ngOnInit() {}

    goToTariffs() {
        window.open(`${window.location.origin}/edu/tariffs`, '_blank');
    }
}
