import {
    type AfterViewInit,
    Directive,
    ElementRef,
    Inject,
    Input,
    type OnChanges,
    type OnDestroy,
    type SimpleChanges,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { fromEvent, type Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SelectedEntity } from '@common/types/layout.type';

const DEFAULT_BOUNDARY: string = 'body';

@Directive({
    selector: '[spPanelDrag]',
})
export class SpPanelDragDirective implements AfterViewInit, OnChanges, OnDestroy {
    @Input() public draggingBoundary: string = DEFAULT_BOUNDARY;
    @Input() public spPanelDragTarget: string = null;
    @Input() public selectedEntity: SelectedEntity;

    private element: HTMLElement;
    private handleElement: HTMLElement;
    private draggingBoundaryElement: HTMLElement | HTMLBodyElement;
    private subscriptions: Subscription[] = [];
    private initialX: number;
    private initialY: number;
    private maxYDiff: number;
    private minYDiff: number;
    private maxXDiff: number;
    private currentX: number = 0;
    private currentY: number = 0;
    private observer: MutationObserver;
    private processing: boolean = false;
    private readonly headerHeight: number = 64;
    private readonly extraBoundaryBorder = 10;

    constructor(private readonly elementRef: ElementRef, @Inject(DOCUMENT) private readonly document: Document) {}

    public ngAfterViewInit(): void {
        this.draggingBoundaryElement = this.document.querySelector(this.draggingBoundary);

        this.element = this.spPanelDragTarget ? this.document.querySelector(this.spPanelDragTarget) : this.elementRef.nativeElement;
        this.handleElement = this.element.querySelector('[spPanelDragHandler]') || this.element;
        this.initDrag();

        this.observer = new MutationObserver((mutations) => {
            mutations.forEach(() => {
                if (!this.processing) {
                    this.processing = true;
                    setTimeout(() => {
                        this.processing = false;
                        this.unsubscribe();
                        this.handleElement = this.element.querySelector('[spPanelDragHandler]') || this.element;
                        this.initDrag();
                    });
                }
            });
        });

        this.observer.observe(this.element, {
            childList: true,
            subtree: true,
        });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (!changes?.selectedEntity?.firstChange && changes?.selectedEntity?.currentValue !== changes?.selectedEntity?.previousValue)
            this.updatePanelPosition();
    }

    private initDrag(): void {
        const dragStart$ = fromEvent<MouseEvent>(this.handleElement, 'mousedown');
        const dragEnd$ = fromEvent<MouseEvent>(this.document, 'mouseup');
        const drag$ = fromEvent<MouseEvent>(this.document, 'mousemove').pipe(takeUntil(dragEnd$));

        if (this.currentX > 180) {
            this.currentY = 0;
            this.currentX = 0;
        }

        let dragSub: Subscription;

        this.maxYDiff = this.headerHeight - this.element.offsetTop;
        this.minYDiff =
            this.draggingBoundaryElement.clientHeight -
            this.element.offsetHeight -
            this.handleElement.offsetHeight / 2 -
            this.extraBoundaryBorder - 10;
        this.maxXDiff = this.draggingBoundaryElement.offsetWidth - this.element.offsetLeft;

        const dragStartSub = dragStart$.subscribe((startEvent: MouseEvent) => {
            this.initialX = startEvent.clientX - this.calculateCurrentX;
            this.initialY = startEvent.clientY - this.calculateCurrentY;

            this.handleElement.classList.add('dragging');

            dragSub = drag$.subscribe((dragEvent: MouseEvent) => {
                dragEvent.preventDefault();

                this.currentX = dragEvent.clientX - this.initialX;
                this.currentY = dragEvent.clientY - this.initialY;
                this.element.style.transform = `translate3d(${this.calculateCurrentX}px, ${this.calculateCurrentY}px, 0)`;
            });
        });

        const dragEndSub = dragEnd$.subscribe(() => {
            this.initialX = this.currentX;
            this.initialY = this.currentY;
            this.handleElement.classList.remove('dragging');

            if (dragSub) {
                dragSub.unsubscribe();
            }
        });

        this.subscriptions.push.apply(this.subscriptions, [dragStartSub, dragSub, dragEndSub]);
    }

    private updatePanelPosition() {
        // if (this.minYDiff < this.currentY || this.maxYDiff > this.currentY) {
        //     this.element.style.transform = null;
        // }
        if (this.currentX > 180) {
            this.element.style.transform = 'translateX(0px)';
        }
    }

    private get calculateCurrentY(): number {
        if (this.minYDiff < this.currentY) {
            return this.minYDiff;
        } else if (this.maxYDiff > this.currentY) {
            return this.maxYDiff;
        } else {
            return this.currentY;
        }
    }

    private get calculateCurrentX(): number {
        return Math.min(this.maxXDiff, this.currentX);
    }

    private unsubscribe(): void {
        this.subscriptions.forEach((subscription: Subscription) => subscription?.unsubscribe());
    }

    public ngOnDestroy(): void {
        this.unsubscribe();
        this.observer.disconnect();
    }
}
