import type { JssStyle } from 'jss';
import type { WebWidgetEntity } from '@web/types';
import type { ChatBotWidgetData } from '@web/widgets/chat-bot/type/chat-bot-widget.type';

import { EButtonStyleType, EButtonType, EHorizontalPosition } from '@common/enums';
import { getBaseButtonData, getBaseButtonIconStyles } from '@web/widgets/common/base/base-button/base-button.styles';

function getButtonStyles({ buttonBase, buttonHover, styleType }: ChatBotWidgetData, fontSize: number): JssStyle {
    if (styleType === EButtonStyleType.custom) {
        return {
            ...getBaseButtonData(buttonBase, buttonHover),
            ...getBaseButtonIconStyles(fontSize),
        };
    }

    return {
        ...getBaseButtonIconStyles(fontSize),
    };
}

function getChatBotIconStyles(height: number): JssStyle {
    return {
        marginRight: '10px',
        '&:last-child': {
            marginRight: 0,
        },
        '& img': {
            height: `${height}px`,
            marginRight: '10px',
            '&:last-child': {
                marginRight: 0,
            },
        },
    };
}

export function getStyles({ data, styles }: WebWidgetEntity<ChatBotWidgetData>, { isConstructor }: any): JssStyle {
    const { type } = data;
    const horizontalPositionMatrix = {
        [EHorizontalPosition.L]: 'flex-start',
        [EHorizontalPosition.M]: 'center',
        [EHorizontalPosition.R]: 'flex-end',
    };

    const mobileMediaQueryKey = '@media (max-width: 992px)';
    let chatBotWrapper: any = {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: `${horizontalPositionMatrix[data.horizontalAlign]}`,
        margin: data.type === EButtonType.button ? '-10px' : '0',
        alignItems: 'center',
        transform: `rotate(${data?.position?.rotate ?? 0}deg)`,
        '& .sp-ui-button': {
            alignItems: 'center',
        },
        [mobileMediaQueryKey]: {
            transform: `rotate(${data?.position?.mobileRotate ?? 0}deg)`,
        },
    };

    if (data.mobileHorizontalAlign) {
        chatBotWrapper = {
            ...chatBotWrapper,
            [mobileMediaQueryKey]: {
                ...chatBotWrapper[mobileMediaQueryKey],
                justifyContent: `${horizontalPositionMatrix[data.mobileHorizontalAlign]}`,
            },
            '&.mobileWidgetViewWrap': {
                justifyContent: `${horizontalPositionMatrix[data.mobileHorizontalAlign]}`,
            },
        };
    }

    if (!isConstructor) {
        if (data?.hideOnMobile && !data?.hideOnDesktop) {
            chatBotWrapper['@media (max-width: 992px)'] = { display: 'none' };
        }

        if (!data?.hideOnMobile && data?.hideOnDesktop) {
            chatBotWrapper['@media (min-width: 993px)'] = { display: 'none' };
        }

        if (data?.hideOnMobile && data?.hideOnDesktop) {
            chatBotWrapper.display = 'none';
        }
    }

    if (isConstructor) {
        chatBotWrapper['&.mobileWidgetViewWrap'].transform = `rotate(${data?.position?.mobileRotate ?? 0}deg)`;
    }

    if (type === EButtonType.icon) {
        return {
            chatBotWrapper,
            chatBotGroup: getChatBotIconStyles(data.height),
        };
    }

    return {
        chatBotWrapper,
        chatBotGroup: getButtonStyles(data, styles.fontSize as number),
    };
}
