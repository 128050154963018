import { Injectable } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';

import { PlatformService } from '@common/services/platform.service';
import { TransferStateService } from '@web-builder/core/services/transfer-state.service';

// const SP_API_KEY = 'AIzaSyBYiQUaXavZm_xebWcX51pvELaiXX0o-Gg';

@Injectable({
    providedIn: 'root',
})
export class MapsService {
    loader: Loader;
    constructor(private readonly transferStateService: TransferStateService, private readonly platformService: PlatformService) {
        if (this.platformService.isPlatformServer()) return;

        const lang = this.transferStateService.get('lang');
        const apiKey = this.transferStateService.get('googleMapsApiKey');
        this.loader = new Loader({
            apiKey,
            language: lang,
            region: 'UA',
        });
    }

    loadMapsAPi() {
        if (this.platformService.isPlatformServer()) return;
        return this.loader.load();
    }
}
