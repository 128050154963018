<div class="sp-menu-root" [class]="classes?.['menu-wrapper']">
    <div [class]="classes?.['menu-list-wrapper']" class="specific-mobile-menu">
        <ul class="sp-menu-group mobile-column-direction widget-specific-nav-align" [class]="classes?.['menu-list']">
            <li class="sp-ui-text sp-menu-item"
                *ngFor="let menuItem of menuList">
                <span *ngIf="menuItem.isVisible">
                    <a *ngIf="menuItem.linkUrl" [attr.href]="getMenuLink(menuItem) | spSafeUrl" [attr.target]="getMenuTarget(menuItem)" [class]="animationClass"
                        (click)="toggleMobileMenu()">
                        {{ menuItem.name }}
                        <div *ngIf="getVisibleSubmenuItemList(menuItem)" class="caret-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
                                <path d="M6 6.5L11.1962 0.5H0.803848L6 6.5Z" fill="currentColor"/>
                            </svg>
                        </div>
                    </a>
                    
                    <a *ngIf="!menuItem.linkUrl" class="sp-menu-item-without-link desktop-item" [class]="animationClass">
                        {{ menuItem.name }}
                        <div *ngIf="getVisibleSubmenuItemList(menuItem)" class="caret-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
                                <path d="M6 6.5L11.1962 0.5H0.803848L6 6.5Z" fill="currentColor"/>
                            </svg>
                        </div>
                    </a>

                    <a *ngIf="!menuItem.linkUrl" class="sp-menu-item-without-link mobile-item" [class]="animationClass"
                        (click)="toggleSubmenu($event)">
                        {{ menuItem.name }}
                        <div *ngIf="getVisibleSubmenuItemList(menuItem)" class="caret-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
                                <path d="M6 6.5L11.1962 0.5H0.803848L6 6.5Z" fill="currentColor"/>
                            </svg>
                        </div>
                    </a>

                    <div *ngIf="getVisibleSubmenuItemList(menuItem)" class="caret-icon-mobile" (click)="toggleSubmenu($event)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
                            <path d="M6 6.5L11.1962 0.5H0.803848L6 6.5Z" fill="currentColor"/>
                        </svg>
                    </div>
                </span>

                <ul *ngIf="getVisibleSubmenuItemList(menuItem)" class="dropdown-submenu-list">
                    <li class="sp-ui-text" *ngFor="let submenuItem of menuItem?.submenuList">
                        <span *ngIf="submenuItem.isVisible">
                            <a [attr.href]="getMenuLink(submenuItem) | spSafeUrl" [attr.target]="getMenuTarget(submenuItem)"
                                (click)="toggleMobileMenu()">
                                {{ submenuItem.name }}
                            </a>
                        </span>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</div>
