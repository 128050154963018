import type { JssStyle } from 'jss';
import type { SpecificBlockStructureEntity, SpecificSectionStructureEntity } from '@web/types';

import { EVerticalPosition } from '@common/enums';

import { encodeFilePreviewUrl } from '@components/sp-file-manager';
import { websiteColorCss } from '@libs/themes/src/variants/website/utils/variables';

const MOBILE_MEDIA_QUERY = '@media (max-width: 992px)';

export function getStyles(
    section: SpecificSectionStructureEntity,
    block: SpecificBlockStructureEntity,
    isConstructor: boolean = false,
    fileManagerCdnUrl: string,
): JssStyle {
    const { styles, data } = section;
    const verticalPositionMap = {
        [EVerticalPosition.T]: 'flex-start',
        [EVerticalPosition.M]: 'center',
        [EVerticalPosition.B]: 'flex-end',
    };

    const sectionWrap: JssStyle = {
        background: websiteColorCss(styles.backgroundColor as string),
    };

    if (data.backgroundImage) {
        sectionWrap.background = `${encodeFilePreviewUrl(data.backgroundImage, fileManagerCdnUrl)}, ${sectionWrap.background}`;
        sectionWrap.backgroundPosition = styles.backgroundPosition;
        sectionWrap.backgroundRepeat = styles.backgroundRepeat;
        sectionWrap.backgroundSize = styles.backgroundSize;
    }

    const sectionInner: JssStyle = {
        position: 'relative',
        maxWidth: block.data.isFullWidth ? '100vw' : '1200px',
        margin: '0 auto',
        display: 'flex',
        alignItems: verticalPositionMap[data.verticalPosition],
        columnGap: styles.columnGap + 'px',
        rowGap: styles.columnGap + 'px',
        paddingTop: styles.paddingTop + 'px',
        paddingBottom: styles.paddingBottom + 'px',
        paddingRight: styles.paddingRight + 'px',
        paddingLeft: styles.paddingLeft + 'px',
        '& .specific-widget-list': {
            columnGap: +styles.columnGap / 2 + 'px',
            rowGap: +styles.columnGap / 2 + 'px',
            '& .sp-ui-text p:last-child, h1:last-child, h2:last-child, h3:last-child, h4:last-child': {
                marginBottom: 0,
            },
            '& .sp-menu-item:hover': {
                '& .dropdown-submenu-list': {
                    display: 'block',
                },
            },
        },
        '& .without-widgets': {
            '@media (max-width: 992px)': {
                display: 'none',
            },
        },
        '&.mobile-section': {
            paddingTop: +styles.columnGap / 2 + 'px',
            paddingBottom: +styles.columnGap / 2 + 'px',
            paddingRight: 0,
            paddingLeft: 0,
        },
    };

    if (isConstructor) {
        sectionInner['&.mobileSectionInner'] = {
            flexDirection: 'column',
        };
        sectionInner[MOBILE_MEDIA_QUERY] = {
            flexDirection: 'column',
            rowGap: styles.columnGap + 'px',
        };
    } else {
        sectionInner[MOBILE_MEDIA_QUERY] = {
            flexDirection: 'column',
            rowGap: styles.columnGap + 'px',
        };
    }

    const sectionIndex = block.sections.findIndex((item) => item.id === section.id);
    const isSectionLast = sectionIndex !== block.sections.length - 1;
    if (isSectionLast && block.data.hasSeparator) {
        sectionInner['&::after'] = {
            content: '""',
            width: '100%',
            height: '1px',
            background: '#E5E5E5',
            position: 'absolute',
            bottom: 0,
            left: 0,
        };
    }

    if (!isConstructor) {
        if ('showLeftColumn' in data && data.showLeftColumn === false) {
            sectionInner['& > div:nth-child(1)'] = {
                display: 'none',
            };
        }

        if ('showCenterColumn' in data && data.showCenterColumn === false) {
            sectionInner['& > div:nth-child(2)'] = {
                display: 'none',
            };
        }

        if ('showRightColumn' in data && data.showRightColumn === false) {
            sectionInner['& > div:nth-child(3)'] = {
                display: 'none',
            };
        }
    }

    return {
        sectionWrap,
        sectionInner,
    };
}
