<div
    *ngIf="block.data.pinOnTheTop"
    class="pined-block-placeholder"
    [class.hideDesktop]="block.data.hideOnDesktop"
    [class.hideMobile]="block.data.hideOnMobile"
    [style.height.px]="pinedTopBlockHeight"
    [style.background-color]="backgroundColor"
></div>
<div
    *ngIf="block.data.pinOnTheBottom"
    class="pined-block-placeholder"
    [class.hideDesktop]="block.data.hideOnDesktop"
    [class.hideMobile]="block.data.hideOnMobile"
    [style.height.px]="pinedBottomBlockHeight"
    [style.background-color]="backgroundColor"
></div>
<div
    [id]="blockId"
    class="block sp-block"
    [class]="isBrowser ? classes?.blockWrap : classes?.blockWrapServer"
    [class.pined-on-top-block]="block.data.pinOnTheTop"
    [class.not-pined-on-top-block]="!block.data.pinOnTheTop"
    [class.pined-on-bottom-block]="block.data.pinOnTheBottom"
    [class.not-pined-on-bottom-block]="!block.data.pinOnTheBottom"
>
    <div
        [class]="classes?.blockInner"
        class="desktop-content">
        <ng-content></ng-content>
    </div>
    <div class="mobile-content"
        [class]="classes?.mobileContentWrapper">
        <div
            *ngIf="hideContentOnMobile"
            [class]="classes?.mobileIconWrapper" class="specific-top-nav">
            <div
                class="mobile-icon"
                [class]="classes?.mobileIcon"
                [class.close-btn]="isShowMobileMenu"
                [class.sm-icon]="iconSize === size.S"
                [class.md-icon]="iconSize === size.M"
                [class.lg-icon]="iconSize === size.L"
                (click)="toggleMobileMenu()"
            >
                <div
                    class="mobile-icon-lines"
                    [class]="classes?.mobileIconLines"
                ></div>
            </div>
            <ng-content select="[logo]"></ng-content>
        </div>
        <div
            *ngIf="hideContentOnMobile"
            [class]="classes?.mobileWidgetsContainer"
            class="specific-mobile-menu-container"
            [class.show]="isShowMobileMenu"
            [style.height]="getBlockHeight()">
            <ng-content select="[mobile]"></ng-content>
        </div>
    </div>
</div>


