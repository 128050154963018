import type { OnInit } from '@angular/core';
import type { Classes, JssStyle } from 'jss';
import { WebWidgetEntity } from '@web/types';

import { ChangeDetectorRef, Directive, Input } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { BsModalService } from 'ngx-bootstrap/modal';

import { ServerStylesService } from '@common/services/server-styles.service';
import { PlatformService } from '@common/services/platform.service';
import { LinkFormatterService } from '@common/services/tracking-link-converter.service';
import { UtmTagsService } from '@web-builder/core/services/utm-tags.service';

import { UtilsService } from '@web-builder/core/services/utils.service';
import { TransferStateService } from '@web-builder/core/services/transfer-state.service';

@Directive({ standalone: true })
export abstract class AbstractWidgetComponent<T> implements OnInit {
    @Input() public widget: WebWidgetEntity;
    @Input() public isSpecific: boolean;

    public classes: Classes | undefined;
    protected siteId = this.transferStateService.get('siteId');
    protected userId = this.transferStateService.get('userId');

    protected abstract getStyles: (widget: WebWidgetEntity, config?: { isConstructor: boolean; isSpecific?: boolean }) => JssStyle;
    protected widgetInit(): void {}

    public isSafari: boolean;

    constructor(
        protected readonly fb: UntypedFormBuilder,
        protected readonly stylesService: ServerStylesService,
        protected readonly sanitizer: DomSanitizer,
        protected readonly changeDetectorRef: ChangeDetectorRef,
        protected readonly cookieService: CookieService,
        protected readonly utilsService: UtilsService,
        protected readonly modalService: BsModalService,
        protected readonly platformService: PlatformService,
        protected readonly transferStateService: TransferStateService,
        protected readonly linkFormatterService: LinkFormatterService,
        protected readonly utmTagsService: UtmTagsService,
    ) {
        this.linkFormatterService.init(this.transferStateService.get('domainUrl'));
    }

    public ngOnInit(): void {
        this.classes = this.stylesService.getStyles(
            this.widget.id,
            this.getStyles(this.widget, { isConstructor: false, isSpecific: this.isSpecific }),
        );
        this.widgetInit();
        
        if (this.isBrowser) {
            const ua = navigator.userAgent;
    
            this.isSafari = ua.includes('Safari') && !ua.includes('Chrome') && !ua.includes('Chromium');
        }
    }

    public get widgetData(): T {
        return this.widget.data as T;
    }

    protected formatImgSrc(src: string): string {
        return this.utilsService.formatImgSrc(src);
    }

    public get isServer(): boolean {
        return this.platformService.isPlatformServer();
    }

    public get isBrowser(): boolean {
        return this.platformService.isPlatformBrowser();
    }

    public get isPreview(): boolean {
        return this.transferStateService.isPreview;
    }
}
