import { ChangeDetectionStrategy, Component, inject, type OnInit } from '@angular/core';
import { EButtonLinkType, EHorizontalPosition, ESize } from '@common/enums';
import type { MenuItem, NavigationWidgetData } from '@web/widgets/navigation';
import { CommonModule } from '@angular/common';

import { AbstractWidgetComponent } from '@web-builder/widgets/abstract-widget.component';
import { getStyles } from '@web/widgets/navigation/components/navigation-view/navigation-view.component.styles';

import { SpDirectivesModule } from '@common/directives/directives.module';
import { EffectTypes } from '@web/shared/components/web-hover-effects/types';
import { SpPipesModule } from '@common/pipes/pipes.module';
import { Store } from '@ngrx/store';
import { ToggleMobileMenuActions } from '@web-builder/store/global/toggle-mobile-menu.action';

@Component({
    selector: 'web-builder-navigation',
    standalone: true,
    imports: [CommonModule, SpDirectivesModule, SpPipesModule],
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent extends AbstractWidgetComponent<NavigationWidgetData> implements OnInit {
    protected getStyles = getStyles;

    private store$: Store = inject(Store);

    public EHorizontalPosition = EHorizontalPosition;
    public ESize = ESize;

    public get menuList(): MenuItem[] {
        return this.widgetData.menuList;
    }

    private getNormalizedLink(type: EButtonLinkType, link: string): string {
        const trimmedLink = link?.trim();

        if (!trimmedLink) {
            return null;
        }

        if (this.isPreview && type === EButtonLinkType.anchor) {
            return `about:srcdoc#${trimmedLink}`;
        }

        if (type === EButtonLinkType.anchor) {
            const domain = this.transferStateService.get('domainUrl');
            const pageUrl = this.transferStateService.get('pageUrl');
            const query = this.platformService.isPlatformBrowser() ? window.location.search : '';

            if (!domain || !pageUrl) {
                let anchorPageUrl = '';
                if (this.platformService.isPlatformBrowser()) {
                    anchorPageUrl = window.location.pathname.substring(1);
                }

                const replacedLink = trimmedLink.replace('#', '');
                return `${anchorPageUrl}${query}#${replacedLink}`;
            }

            return `${domain}/${pageUrl}${query}#${trimmedLink}`;
        }
        // if (type === EButtonLinkType.anchor) {
        //     let anchorPageUrl = '';
        //     if (this.platformService.isPlatformBrowser()) {
        //         anchorPageUrl = window.location.pathname.substring(1);
        //     }
        //
        //     const query = this.platformService.isPlatformBrowser() ? window.location.search : '';
        //
        //     const replacedLink = trimmedLink.replace('#', '');
        //     return `${anchorPageUrl}${query}#${replacedLink}`;
        // }

        if (type === EButtonLinkType.email) {
            return this.linkFormatterService.formatEmailLink(trimmedLink);
        }

        if (type === EButtonLinkType.phone) {
            return this.linkFormatterService.formatPhoneLink(trimmedLink);
        }

        return this.linkFormatterService.formatLink(trimmedLink);
    }

    public getMenuLink(menuItem: MenuItem): string {
        const { linkUrl, linkType } = menuItem;
        const normalizedLink = this.getNormalizedLink(linkType, linkUrl);

        if (!normalizedLink) {
            return null;
        }

        return normalizedLink;
    }

    public getMenuTarget(menuItem: MenuItem): string {
        if (menuItem.linkType === EButtonLinkType.anchor) {
            return null;
        }

        return menuItem.isTargetBlank ? '_blank' : '_self';
    }

    public getVisibleSubmenuItemList(item: MenuItem): boolean {
        if (item?.submenuList) {
            return Boolean(item.submenuList.find((item) => item.isVisible));
        } else {
            return false;
        }
    }

    public toggleMobileMenu() {
        this.store$.dispatch(ToggleMobileMenuActions.toggle());
    }

    public toggleSubmenu(event) {
        let parentMenuItem = event.currentTarget.parentNode.parentNode;

        if (parentMenuItem.classList.contains('open')) {
            parentMenuItem.classList.remove('open');
        } else {
            parentMenuItem.classList.add('open');
        }
    }

    public get animationClass(): string {
        return this.widgetData?.hoverEffects?.type === EffectTypes.animation ? this.widgetData?.hoverEffects?.animationType : null;
    }
}
