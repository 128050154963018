import type { OnInit } from '@angular/core';

import { Component, Input, ViewChild, ChangeDetectionStrategy, ViewContainerRef } from '@angular/core';

import { WebWidgetEntity } from '@web/types';
import WidgetsConfig from '@web-builder/widgets/widgets.config';
import { Widgets } from '@common/enums';

@Component({
    selector: 'web-builder-widget-resolver',
    template: `<div
        [id]="widgetId"
        [style.position]="'relative'"
        [style.top.px]="positionY"
        [style.left.px]="positionX"
        [style.width.%]="width"
    >
        <ng-container #container></ng-container>
    </div>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetResolverComponent implements OnInit {
    @ViewChild('container', { static: true, read: ViewContainerRef }) public featureHost: ViewContainerRef;
    @Input() public widget!: WebWidgetEntity;
    @Input() public index!: number;
    @Input() public isSpecific: boolean = false;
    @Input() public isMobile?: boolean = false;

    public ngOnInit(): void {
        this.loadComponent();
    }

    private loadComponent(): void {
        if (!this.widget?.name || !WidgetsConfig[this.widget.name]) {
            return;
        }

        const { component } = WidgetsConfig[this.widget.name] || {};

        if (!component) {
            return;
        }

        this.featureHost.clear();
        const componentInstance = this.featureHost.createComponent(component);

        componentInstance.setInput('isSpecific', this.isSpecific);
        componentInstance.setInput('widget', this.widget);
    }

    public get widgetId(): string {
        return this.widget.displayId ? this.widget.displayId : `widget-${this.widget.id}`;
    }

    public get position() {
        return this.widget?.data?.position;
    }

    public get positionX() {
        if (this.isMobile) {
            return this.position?.mobileX ?? 0;
        }

        return this.position?.x ?? 0;
    }

    public get positionY() {
        if (this.isMobile) {
            return this.position?.mobileY ?? 0;
        }

        return this.position?.y ?? 0;
    }

    public get width(): number {
        if (!this.isWidgetText) {
            return null;
        }

        const widthValue = (this.isMobile ? this.position?.mobileWidth : this.position?.width) ?? 100;
        if (widthValue > 100) {
            return widthValue;
        }

        return 100;
    }

    public get isWidgetText(): boolean {
        return this.widget.name === Widgets.TEXT;
    }
}
