import type { JssStyle } from 'jss';
import type { ThemeButtonData } from '@libs/themes';

import { EButtonStyleType } from '@common/enums';
import { ESimpleThemeButtonProperties } from '@common/libs/themes/src/variants/simple/enums/button.enum';
import { ESimpleThemeFontProperties } from '@common/libs/themes/src/variants/simple/enums/font.enum';
import {
    simpleColorCss,
    simpleFontButtonCss,
    simplePrimaryButtonCss,
    simpleSecondaryButtonCss,
} from '@common/libs/themes/src/variants/simple/utils/variables';

const commonStyles: JssStyle = {
    height: '100%',
    width: '100%',
    padding: '8px 10px',
    textAlign: 'center',
    letterSpacing: '0.08px',
    fontSize: '15px',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.12)',
};

function selectedPreset(): any {
    return {
        [EButtonStyleType.primary]: {
            ...commonStyles,
            lineHeight: simpleFontButtonCss(ESimpleThemeFontProperties.lineHeight),
            textTransform: simpleFontButtonCss(ESimpleThemeFontProperties.textTransform),
            fontFamily: simpleFontButtonCss(ESimpleThemeFontProperties.fontFamily),
            fontStyle: simpleFontButtonCss(ESimpleThemeFontProperties.fontStyle),
            fontWeight: simpleFontButtonCss(ESimpleThemeFontProperties.fontWeight),
            background: simplePrimaryButtonCss(ESimpleThemeButtonProperties.background),
            borderColor: simplePrimaryButtonCss(ESimpleThemeButtonProperties.borderColor),
            color: simplePrimaryButtonCss(ESimpleThemeButtonProperties.color),
            borderRadius: simplePrimaryButtonCss(ESimpleThemeButtonProperties.borderRadius),
            borderStyle: simplePrimaryButtonCss(ESimpleThemeButtonProperties.borderStyle),
            borderWidth: simplePrimaryButtonCss(ESimpleThemeButtonProperties.borderWidth),
        },
        [EButtonStyleType.secondary]: {
            ...commonStyles,
            lineHeight: simpleFontButtonCss(ESimpleThemeFontProperties.lineHeight),
            textTransform: simpleFontButtonCss(ESimpleThemeFontProperties.textTransform),
            fontFamily: simpleFontButtonCss(ESimpleThemeFontProperties.fontFamily),
            fontStyle: simpleFontButtonCss(ESimpleThemeFontProperties.fontStyle),
            fontWeight: simpleFontButtonCss(ESimpleThemeFontProperties.fontWeight),
            background: simpleSecondaryButtonCss(ESimpleThemeButtonProperties.background),
            borderColor: simpleSecondaryButtonCss(ESimpleThemeButtonProperties.borderColor),
            color: simpleSecondaryButtonCss(ESimpleThemeButtonProperties.color),
            borderRadius: simpleSecondaryButtonCss(ESimpleThemeButtonProperties.borderRadius),
            borderStyle: simpleSecondaryButtonCss(ESimpleThemeButtonProperties.borderStyle),
            borderWidth: simpleSecondaryButtonCss(ESimpleThemeButtonProperties.borderWidth),
        },
    };
}

function listPreset(styles: JssStyle, data: ThemeButtonData): any {
    return {
        [EButtonStyleType.primary]: {
            ...commonStyles,
            lineHeight: simpleFontButtonCss(ESimpleThemeFontProperties.lineHeight),
            textTransform: simpleFontButtonCss(ESimpleThemeFontProperties.textTransform),
            fontFamily: simpleFontButtonCss(ESimpleThemeFontProperties.fontFamily),
            fontStyle: simpleFontButtonCss(ESimpleThemeFontProperties.fontStyle),
            fontWeight: simpleFontButtonCss(ESimpleThemeFontProperties.fontWeight),
            background: simpleColorCss(data.background as any),
            borderColor: simpleColorCss(data.borderColor as any),
            color: simpleColorCss(data.color as any),
            ...styles,
        },
        [EButtonStyleType.secondary]: {
            ...commonStyles,
            lineHeight: simpleFontButtonCss(ESimpleThemeFontProperties.lineHeight),
            textTransform: simpleFontButtonCss(ESimpleThemeFontProperties.textTransform),
            fontFamily: simpleFontButtonCss(ESimpleThemeFontProperties.fontFamily),
            fontStyle: simpleFontButtonCss(ESimpleThemeFontProperties.fontStyle),
            fontWeight: simpleFontButtonCss(ESimpleThemeFontProperties.fontWeight),
            background: simpleColorCss(data.background as any),
            borderColor: simpleColorCss(data.borderColor as any),
            color: simpleColorCss(data.color as any),
            ...styles,
        },
    };
}

export function getStyles(styles: JssStyle, selected: boolean, type: string, data: ThemeButtonData): JssStyle {
    if (selected) {
        return {
            ...selectedPreset()[type],
        };
    }
    return {
        ...listPreset(styles, data)[type],
    };
}
