<div *ngIf="files">
    <div *ngFor="let file of files; let i = index" class="control-handle">
        <img class="handle" src="./assets/img/dnd-icon.svg" alt="handle icon"/>

        <div class="control-handle-container">
            <div class="file-box">
                <div class="control-handle-content">
                    <div class="wrap-text">
                        <span class="file-name text-nowrap">{{ file.name }}</span>
                        <span class="file-size text-nowrap">{{ file.size | spByteFormat }}</span>
                    </div>
                </div>
                <div>
                    <button class="btn btn-link" (click)="removeMultipleFile(file)">
                        <span class="sp-icon icon-bin"> </span>
                    </button>
                    <button class="btn btn-link" (click)="replaceFileEvent.emit(i)">
                        <span class="sp-icon icon-marquee-upload"> </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
