import type { JssStyle } from 'jss';
import type { BlockStructureEntity } from '@web/types';

import { encodeFilePreviewUrlWithResize } from '@components/sp-file-manager';
import { websiteColorCss } from '@libs/themes/src/variants/website/utils/variables';
import { EScrollEffect } from '@common/enums';

function getRgbaColor(color: string, opacity: number): string {
    let r, g, b;

    if (color.includes('rgb')) {
        const rgbArr = color.match(/\d+/g);
        r = rgbArr[0];
        g = rgbArr[1];
        b = rgbArr[2];
    } else {
        r = parseInt(color.slice(1, 3), 16);
        g = parseInt(color.slice(3, 5), 16);
        b = parseInt(color.slice(5, 7), 16);
    }

    return `rgba(${r},${g},${b},${opacity})`;
}

function isParallax(isScrollEffect: boolean, scrollEffect: EScrollEffect): boolean {
    return isScrollEffect && scrollEffect === EScrollEffect.PARALLAX;
}

export function getStyles(block: BlockStructureEntity, isConstructor: boolean = false, fileManagerCdnUrl: string): JssStyle {
    const { styles, data, mobileStyles, mobileData } = block;

    let blockWrap: JssStyle = {};
    let blockWrapServer = {};
    let colorOverflow = '';

    let blockBg: JssStyle = {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: isParallax(data.isScrollEffect, data.scrollEffect) ? '-50%' : 0,
        top: isParallax(data.isScrollEffect, data.scrollEffect) ? '-50%' : 0,
        zIndex: 0,
        display: 'block',
        background: websiteColorCss(styles.backgroundColor as string),
    };

    const blockInner: JssStyle = {
        paddingTop: styles.paddingTop + 'px',
        paddingBottom: styles.paddingBottom + 'px',
        zIndex: 1,
        position: 'relative',
    };

    if (!data.isFullWidth) {
        blockInner.maxWidth = '1200px';
        blockInner.margin = '0 auto';
    }

    if (data.isFullWidth) {
        blockInner.maxWidth = '100vw';
        blockInner.margin = '0 auto';
    }

    if (data.isFullWidth && isConstructor) {
        blockInner.maxWidth = '100vw';
    }

    if (data.isColorOverflow) {
        let rgba = getRgbaColor(data.colorOverflow, data.colorOverflowOpacity);
        colorOverflow = `linear-gradient(${rgba}, ${rgba}), `;
    }

    if (data.backgroundImage) {
        blockBg.background = `${colorOverflow}${encodeFilePreviewUrlWithResize(data.backgroundImage, fileManagerCdnUrl)}, ${
            blockBg.background
        }`;
        blockBg.backgroundPosition = isParallax(data.isScrollEffect, data.scrollEffect) ? '40% 50%' : styles.backgroundPosition;
        blockBg.backgroundRepeat = isParallax(data.isScrollEffect, data.scrollEffect) ? 'no-repeat' : styles.backgroundRepeat;
        blockBg.backgroundSize = isParallax(data.isScrollEffect, data.scrollEffect) ? 'cover' : styles.backgroundSize;

        if (data.isScrollEffect) {
            blockBg.backgroundAttachment = isParallax(data.isScrollEffect, data.scrollEffect) ? 'initial' : 'fixed';
        }

        if (data.isFilterOverlay) {
            blockBg.filter = data.filterOverlay;
        }
    }

    if (data.isFullHeight) {
        blockWrap.display = 'flex';
        blockWrap.alignItems = 'center';
        blockWrap.minHeight = '100vh';

        blockInner.width = '100%';
    }

    if (mobileStyles) {
        blockBg['@media (max-width: 992px)'] = {
            background: websiteColorCss(mobileStyles.backgroundColor as string),
        };

        blockBg['&.mobileBlockBg'] = {
            background: websiteColorCss(mobileStyles.backgroundColor as string),
        };

        blockInner['@media (max-width: 992px)'] = {
            paddingTop: mobileStyles.paddingTop + 'px',
            paddingBottom: mobileStyles.paddingBottom + 'px',
        };

        if (isConstructor) {
            blockBg['&.mobileViewWrap'] = {
                background: websiteColorCss(mobileStyles.backgroundColor as string),
            };

            blockInner['&.mobileViewInner'] = {
                paddingTop: mobileStyles.paddingTop + 'px',
                paddingBottom: mobileStyles.paddingBottom + 'px',
            };
        }
    }

    if (mobileStyles && mobileData) {
        if (mobileData.backgroundImage) {
            blockBg['@media (max-width: 992px)'] = {
                background: `${colorOverflow}${encodeFilePreviewUrlWithResize(
                    mobileData.backgroundImage,
                    fileManagerCdnUrl,
                )}`,
                backgroundPosition: isParallax(data.isScrollEffect, data.scrollEffect) ? '40% 50%' : mobileStyles.backgroundPosition,
                backgroundRepeat: isParallax(data.isScrollEffect, data.scrollEffect) ? 'no-repeat' : mobileStyles.backgroundRepeat,
                backgroundSize: isParallax(data.isScrollEffect, data.scrollEffect) ? 'cover' : mobileStyles.backgroundSize,
                backgroundColor: websiteColorCss(mobileStyles.backgroundColor as string),
            };

            if (data.isScrollEffect) {
                blockBg['@media (max-width: 992px)'].backgroundAttachment = isParallax(data.isScrollEffect, data.scrollEffect)
                    ? 'initial'
                    : 'fixed';
            }
        }

        if (isConstructor && mobileData.backgroundImage) {
            blockBg['&.mobileBlockBg'] = {
                background: `${colorOverflow}${encodeFilePreviewUrlWithResize(
                    mobileData.backgroundImage,
                    fileManagerCdnUrl,
                )}`,
                backgroundPosition: isParallax(data.isScrollEffect, data.scrollEffect) ? '40% 50%' : mobileStyles.backgroundPosition,
                backgroundRepeat: isParallax(data.isScrollEffect, data.scrollEffect) ? 'no-repeat' : mobileStyles.backgroundRepeat,
                backgroundSize: isParallax(data.isScrollEffect, data.scrollEffect) ? 'cover' : mobileStyles.backgroundSize,
                backgroundColor: websiteColorCss(mobileStyles.backgroundColor as string),
            };
        }
    }

    if (!isConstructor) {
        if (data.hideOnMobile && !data.hideOnDesktop) {
            blockWrap['@media (max-width: 992px)'] = {
                display: 'none',
            };
        }

        if (!data.hideOnMobile && data.hideOnDesktop) {
            blockWrap['@media (min-width: 993px)'] = {
                display: 'none',
            };
        }

        if (data.hideOnMobile && data.hideOnDesktop) {
            blockWrap.display = 'none';
        }

        blockWrapServer = {
            ...blockWrap,
        };

        if (data.pinOnTheTop) {
            blockWrap.position = 'fixed';
            blockWrap.top = '0';
            blockWrap.left = '0';
            blockWrap.right = '0';
            blockWrap.zIndex = '2';
        }

        if (data.pinOnTheBottom) {
            blockWrap.position = 'fixed';
            blockWrap.bottom = '0';
            blockWrap.left = '0';
            blockWrap.right = '0';
            blockWrap.zIndex = '2';
        }
    }

    return {
        blockBg,
        blockWrap,
        blockWrapServer,
        blockInner,
    };
}
