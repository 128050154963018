<div class="theme-settings">
    <div class="form form-horizontal">
        <div class="form-row">
            <ng-container *ngFor="let fontControl of form.controls; index as i">
                <div class="form-group form-group-fonts" *ngIf="HIDDEN_FONT_TAGS.indexOf(fontControl.get('tag').value) === -1">
                    <label class="col-xs-12">
                        {{ FONT_TAG_TRANSLATIONS[fontControl.get('tag').value] | translate }}
                    </label>
                    <div class="col-xs-12 mb-10">
                        <select class="form-control" (change)="changeFont(fontControl, $event)">
                            <option *ngFor="let opt of FONT_FAMILY_LIST"
                                    [value]="opt"
                                    [selected]="fontControl.value.font === opt">
                                {{ FONT_FAMILY_NAMES[opt] }}
                            </option>
                        </select>
                    </div>
                    <div class="col-xs-6 mb-10">
                        <sp-input-num
                            suffixText="px"
                            [min]="FONT_SIZE_MIN"
                            [max]="FONT_SIZE_MAX"
                            [value]="fontControl.get('styles').get('fontSize').value"
                            (valueChange)="setFontSize(fontControl.get('styles').get('fontSize'), $event)"
                        ></sp-input-num>
                    </div>
                    <div class="col-xs-6 mb-10">
                        <sp-input-num
                            prefixImgUrl="./assets/img/themes/lpc-icon-line-height.svg"
                            suffixText="%"
                            [min]="LINE_HEIGHT_MIN"
                            [max]="LINE_HEIGHT_MAX"
                            [value]="fontControl.get('styles').get('lineHeight').value"
                            (valueChange)="setLineHeight(fontControl.get('styles').get('lineHeight'), $event)"
                        ></sp-input-num>
                    </div>
                    <div class="col-xs-6 mb-10">
                        <select class="form-control" (change)="changeFontWeight(fontControl, $event)">
                            <option *ngFor="let opt of FONT_FAMILY_WEIGHTS[fontControl.value.font]"
                                    [value]="opt"
                                    [selected]="fontControl.value.styles.fontWeight === FONT_FAMILY_WEIGHTS[opt] ||
                                        fontControl.value.styles.fontWeight === opt">
                                {{ FONT_WEIGHT_TRANSLATIONS[opt] | translate }}
                            </option>
                        </select>
                    </div>
                    <div class="col-xs-6 mb-10">
                        <div class="btn-group btn-group-justified" data-toggle="buttons">
                            <label class="btn btn-grey" [class.active]="getStyleValue(fontControl, 'textTransform') === 'uppercase'"
                                   (click)="toggleTextTransform(fontControl)">
                                <img src="./assets/img/themes/lpc-icon-uppercase.svg" alt="AT">
                            </label>
                            <label class="btn btn-grey" [class.active]="getStyleValue(fontControl, 'fontStyle') === 'italic'"
                                   (click)="toggleFontStyle(fontControl)">
                                <img src="./assets/img/themes/lpc-icon-italic.svg" alt="I">
                            </label>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="form-footer fixed-bottom">
        <button type="button" class="btn btn-default mr-10" (click)="onCancel()">{{ 'cancel' | translate }}</button>
        <button type="button" class="btn btn-success" (click)="onSave()">{{ 'save' | translate }}</button>
    </div>
</div>
