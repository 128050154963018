<div class="alert-box edu-modal" [class]="classes.modalBodyStyles">
    <div class="sp-lpc-form">
        <button type="button" class="close-modal-btn" aria-label="Close" (click)="onCloseModal()">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="sp-ui-text">
            <h2 class="edu-modal-title">{{ "sign_up_course" | translate }}</h2>
        </div>

        <div *ngIf="invalidCourse" class="error-alert">
            {{ "error_invalid_course_enroll" | translate }}
        </div>

        <div class="field-block" [class]="classes.formField">
            <p *ngIf="!invalidCourse">
                <strong>{{ signInData?.firstName }}</strong>
                <br />
                {{ signInData?.email }}
            </p>
        </div>
    </div>

    <div *ngIf="!invalidCourse"
        class="edu-modal-footer"
        [class]="classes?.buttonWrapper">
        <button type="button" class="sp-ui-button sp-ui-button-md sp-ui-button-secondary-light sp-ui-button-secondary-edu" (click)="onChangeUser()">
            {{ "change_user" | translate }}
        </button>
        <button type="button" class="sp-ui-button sp-ui-button-md sp-ui-button-primary-light" (click)="onEnroll()">
            {{ "enroll" | translate }}
        </button>
    </div>
</div>
