import DeviceDetector from 'device-detector-js';
import { DeviceType } from 'device-detector-js/dist/typings/device';

const getClientMetadata = () => {
    const referrer = document.referrer;
    const { device } = new DeviceDetector().parse(navigator.userAgent);

    if (device) {
        return {
            referrer,
            deviceType: device.type === 'desktop' ? 1 : (['smartphone', 'tablet'] as DeviceType[]).includes(device.type) ? 2 : null,
        };
    }
    return {
        referrer,
        deviceType: null,
    };
};

export default function (): { [key: string]: string } {
    const { deviceType, referrer } = getClientMetadata();

    const headers: { [key: string]: string } = {
        'X-Real-Referrer': referrer,
        // 'X-Real-IP':ip
    };

    if (deviceType) {
        headers['X-Device-Type'] = deviceType.toString();
    }

    return headers;
}
