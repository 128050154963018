import type { BooleanInput } from '@angular/cdk/coercion';
import type { SelectedFileExtended } from '@common/types/file.type';
import type { ReplacedFile } from './types';
import { PreviewType } from './types';
import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
    type TemplateRef,
} from '@angular/core';

import { DirectoryItemCategory, FmFeatureApiService, FmSourceType, type SelectedFileInterface } from '@sendpulse/file-manager';
import { InputBoolean } from '@common/helpers/convert';
import { encodeFilePreviewUrl } from './helpers';
import { filter, take } from 'rxjs';
import { SpFileManagerContextService } from '@common/components/sp-file-manager/src/sp-file-manager-context.service';

@Component({
    selector: 'sp-file-manager',
    templateUrl: './sp-file-manager.component.html',
    styleUrls: ['./sp-file-manager.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [SpFileManagerContextService],
})
export class SpFileManagerComponent implements OnInit {
    @ContentChild('previewTemplate') public previewTemplate: TemplateRef<any> | undefined;
    @ContentChild('uploadButtonTemplate') public uploadButtonTemplate: TemplateRef<any> | undefined;

    @Input() @InputBoolean() public isLink: boolean = false;
    @Input() @InputBoolean() public showSetupBtn: boolean = true;
    @Input() @InputBoolean() public disabled: boolean = false;
    @Input() @InputBoolean() public multiSelect: boolean = false;
    @Input() public displayOnly: DirectoryItemCategory[] = [DirectoryItemCategory.Image];
    @Input() public displayedExtensions: string[] = null;
    @Input() public previewType: PreviewType;
    @Input() public fmSourceType?: FmSourceType;
    @Input() public addButtonLabel: string;
    @Input() public file: any = null;
    @Input() public files: SelectedFileExtended[];
    @Input() public isFreeTariff: boolean;
    @Input() public short: boolean = false;
    @Input() public initOpen: boolean = false;

    @Output() public setFileEvent: EventEmitter<SelectedFileExtended | SelectedFileExtended[]> = new EventEmitter();
    @Output() public editFileEvent: EventEmitter<SelectedFileExtended> = new EventEmitter();
    @Output() public replaceFileEvent: EventEmitter<ReplacedFile> = new EventEmitter();
    @Output() public removeFileEvent: EventEmitter<number> = new EventEmitter();
    @Output() public imageEditEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() public imageCarouselEditEvent: EventEmitter<number> = new EventEmitter<number>();

    @Output() public updateFileEvent: EventEmitter<SelectedFileExtended> = new EventEmitter();
    @Output() public activateFileEvent: EventEmitter<number> = new EventEmitter();
    @Output() public updateFilesEvent: EventEmitter<SelectedFileExtended[]> = new EventEmitter();

    @Output() public setVideoPreviewUrl: EventEmitter<string> = new EventEmitter();
    @Output() public removeVideoPreviewUrl: EventEmitter<any> = new EventEmitter();

    private readonly fmFeatureApiService: FmFeatureApiService = inject(FmFeatureApiService);
    public readonly fileManagerContextService: SpFileManagerContextService = inject(SpFileManagerContextService, {
        self: true,
    });

    public readonly PreviewType = PreviewType;
    public cdnUrl: string = null;

    // Bindable methods for passing into templates
    public replaceFileHandlerFn = this.replaceFileHandler.bind(this);
    public encodeFilePreviewUrlFn = this.encodeFilePreviewUrl.bind(this);

    constructor() {
        this.fmFeatureApiService.cdnInfo$.pipe(filter(Boolean), take(1)).subscribe(({ cname }) => {
            this.cdnUrl = cname;
        });
    }

    public ngOnInit(): void {
        this.fileManagerContextService.setFmData({
            displayOnly: this.displayOnly,
            multiSelect: this.multiSelect,
            fmSourceType: this.fmSourceType,
        });

        this.fileManagerContextService.getSelectedFileData().subscribe(({ file, replace, replaceIndex }) => {
            this.fileSelectedHandler(file, replace, replaceIndex);
        });

    }
    public ngAfterViewInit(): void {
        if (this.initOpen) {
            this.fileManagerContextService.openFileManagerDrawer();
        }
    }

    public setupPreviewImage() {
        this.fileManagerContextService
            .openDrawerService({ displayOnly: DirectoryItemCategory.Image })
            .pipe(take(1))
            .subscribe((file: SelectedFileInterface) => {
                this.setVideoPreviewUrl.emit(`${this.cdnUrl}${file.url}`);
            });
    }

    public fileSelectedHandler(file: SelectedFileExtended | SelectedFileExtended[], replace: boolean, replaceIndex: number): void {
        if (!replace) {
            this.setFileEvent.emit(file);
            return;
        }
        if (!Array.isArray(file)) {
            this.replaceFileEvent.emit({
                file,
                index: replaceIndex,
            });
        }
    }

    public replaceFileHandler(index?: number): void {
        this.fileManagerContextService.openFileManagerDrawer({ replace: true, replaceIndex: index });
    }

    public encodeFilePreviewUrl(file: SelectedFileInterface): string {
        return encodeFilePreviewUrl(file, this.cdnUrl);
    }

    public removePreviewImage(): void {
        this.removeVideoPreviewUrl.emit();
    }

    public get showCreateButton(): boolean {
        return !(Boolean(this.file?.path) || Boolean(this.file?.videoFiles?.length) || Boolean(this.files?.length));
    }

    static ngAcceptInputType_closeAfterSelect: BooleanInput;
}
