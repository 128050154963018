import type { JssStyle } from 'jss';
import type { ThemeColorName } from '@libs/themes/src/types/theme-color.type';

import { EButtonHoverType, type EButtonStyleType } from '@common/enums';

import { type ESimpleThemeButtonProperties } from '@libs/themes/src/variants/simple/enums/button.enum';
import { type EWebsiteThemeButtonProperties } from '@libs/themes/src/variants/website/enums/button.enum';

export type ThemeButton = {
    styleType: EButtonStyleType;
    /**
     * @deprecated This property should be removed in the future
     */
    styles?: ThemeButtonStyles;
    /**
     * @deprecated This property should be removed in the future
     */
    data?: ThemeButtonData;
    base?: ThemeButtonData;
    active?: ThemeButtonData;
    hover?: ThemeButtonHover;
};

export type ThemeButtonDataProp = ESimpleThemeButtonProperties | EWebsiteThemeButtonProperties;

export type ThemeButtonData = {
    [key in ThemeButtonDataProp]?: number | string | string[] | ThemeColorName[];
};

export type ThemeButtonHover = {
    hoverType: EButtonHoverType;
    opacity: number;
    shadow: string;
    color: any;
    background: any;
    borderColor: any;
};

export type ThemeButtonStyles = Pick<JssStyle, 'background' | 'color' | 'borderColor' | 'borderStyle' | 'borderWidth' | 'borderRadius'>;

export type ThemeButtonPreset = {
    index: string;
    values: ThemeButton[];
};

export type ThemeSelectedButtons = {
    selected: ThemeButton[];
    selectedIndex: string;
};

export interface IButtonHoverTypeOption {
    label: string;
    value: EButtonHoverType;
}

export const buttonHoverTypeOptions: IButtonHoverTypeOption[] = [
    {
        label: 'control_hover_effect_option_opacity',
        value: EButtonHoverType.opacity,
    },
    {
        label: 'control_hover_effect_option_shadow',
        value: EButtonHoverType.shadow,
    },
    {
        label: 'control_hover_effect_option_color',
        value: EButtonHoverType.color,
    },
];
