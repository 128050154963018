<div class="panel-options-body overflow-wrapper font-setting-panel">
    <div class="form form-horizontal">
        <div class="form-row">
            <div class="form-group mb-10">
                <div class="col-xs-12 device-toggle-wrapper">
                    <sp-device-toggle
                        [isDesktop]="isDesktopMode"
                        [isMobile]="isMobileMode"
                        (changeDeviceMode)="onChangeDeviceMode($event)"
                    ></sp-device-toggle>

                    <sp-synchronize-style-toggle
                        [active]="isSynchronizedControl?.value"
                        (click)="toggleIsSynchronized()"
                    ></sp-synchronize-style-toggle>
                </div>
            </div>

<!--            <pre>{{ hideMobileSettings }}</pre>-->
            <ng-container *ngFor="let fontControl of form.controls; index as i">
                <div *ngIf="HIDDEN_FONT_TAGS.indexOf(fontControl.get('tag').value) === -1" class="form-group mb-20" >
                    <label class="col-xs-12">
                        {{ FONT_TAG_TRANSLATIONS[fontControl.get('tag').value] | translate }}
                    </label>
                    <div *ngIf="hideMobileSettings" class="col-xs-6 mb-10">
                        <select class="form-control" (change)="setFontFamily(fontControl, $event)">
                            <option
                                *ngFor="let opt of FONT_FAMILY_LIST"
                                [value]="opt"
                                [style.font-family]="FONT_FAMILY_NAMES[opt]"
                                [selected]="getFontValue(fontControl) === opt"
                            >
                               {{ FONT_FAMILY_NAMES[opt] }}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="hideMobileSettings" class="col-xs-6 mb-10">
                        <select class="form-control" (change)="setFontWeight(fontControl, $event)">
                            <option
                                *ngFor="let opt of FONT_FAMILY_WEIGHTS[fontControl.value.font]"
                                [value]="FONT_WEIGHT_VALUES[opt]"
                                [selected]="+getControlValue(fontControl, simpleThemeFontProperties.fontWeight) === FONT_WEIGHT_VALUES[opt] ||
                                    getControlValue(fontControl, simpleThemeFontProperties.fontWeight) === opt"
                            >
                                {{ FONT_WEIGHT_TRANSLATIONS[opt] | translate }}
                            </option>
                        </select>
                    </div>
                    <div class="col-xs-6 mb-10">
                        <sp-input-num
                            suffixText="px"
                            [min]="FONT_SIZE_MIN"
                            [max]="FONT_SIZE_MAX"
                            [value]="getControlValue(fontControl, simpleThemeFontProperties.fontSize)"
                            (valueChange)="setFontSize(fontControl, $event)"
                        ></sp-input-num>
                    </div>
                    <div class="col-xs-6 mb-10">
                        <div class="btn-group btn-group-justified" data-toggle="buttons">
                            <label class="btn btn-grey" [class.active]="getControlValue(fontControl, simpleThemeFontProperties.textTransform) === 'uppercase'"
                                   (click)="toggleTextTransform(fontControl)">
                                <img src="./assets/img/lpc-icon-uppercase.svg" alt="AT">
                            </label>
                            <label class="btn btn-grey" [class.active]="getControlValue(fontControl, simpleThemeFontProperties.fontStyle) === 'italic'"
                                   (click)="toggleFontStyle(fontControl)">
                                <img src="./assets/img/lpc-icon-italic.svg" alt="I">
                            </label>
                        </div>
                    </div>
                    <div class="col-xs-6 mb-10">
                        <sp-input-num
                            prefixImgUrl="./assets/img/lpc-icon-line-height.svg"
                            suffixText="%"
                            [min]="LINE_HEIGHT_MIN"
                            [max]="LINE_HEIGHT_MAX"
                            [value]="getControlValue(fontControl, simpleThemeFontProperties.lineHeight)"
                            (valueChange)="setLineHeight(fontControl, $event)"
                        ></sp-input-num>
                    </div>
                    <div class="col-xs-6 mb-10">
                        <sp-input-num
                            prefixImgUrl="./assets/img/lpc-icon-letter-spacing.svg"
                            suffixText="px"
                            [min]="LETTER_SPACING_MIN"
                            [max]="LETTER_SPACING_MAX"
                            [value]="getControlValue(fontControl, simpleThemeFontProperties.letterSpacing)"
                            (valueChange)="setLetterSpacing(fontControl, $event)"
                        ></sp-input-num>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<div class="panel-options-footer">
    <button type="button" class="btn btn-default mr-10" (click)="onCancel()">{{ 'cancel' | translate }}</button>
    <button type="button" class="btn btn-success" (click)="onSave()">{{ 'save' | translate }}</button>
</div>
