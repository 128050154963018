import type { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { UntypedFormBuilder } from '@angular/forms';
import type { CdkDragDrop } from '@angular/cdk/drag-drop';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import type { SelectedFileExtended } from '@common/types/file.type';

import { ChangeDetectorRef, Component, EventEmitter, Input, type OnChanges, type OnInit, Output, type SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';

import { encodeFilePreviewUrl } from '../helpers';

@Component({
    selector: 'sp-image-preview-multiple',
    templateUrl: './sp-image-preview-multiple.component.html',
    styleUrls: ['./sp-image-preview-multiple.component.less'],
})
export class SpImagePreviewMultipleComponent implements OnInit, OnChanges {
    @Input() public files: SelectedFileExtended[] = [];
    @Input() public isLink: boolean;
    @Input() public showSetupBtn: boolean = true;
    @Input() public cdnUrl: string;

    @Output() public setFileEvent: EventEmitter<SelectedFileExtended> = new EventEmitter<SelectedFileExtended>();
    @Output() public updateFilesEvent: EventEmitter<SelectedFileExtended[]> = new EventEmitter<SelectedFileExtended[]>();
    @Output() public replaceFileEvent: EventEmitter<number> = new EventEmitter<number>();
    @Output() public activateFileEvent: EventEmitter<number> = new EventEmitter<number>();
    @Output() public imageEditEvent: EventEmitter<number> = new EventEmitter<number>();

    public form: UntypedFormGroup;
    public closeSubject: Subject<void> = new Subject<void>();

    constructor(private readonly fb: UntypedFormBuilder, protected readonly changeDetectorRef: ChangeDetectorRef) {
        this.initForm();
    }

    public ngOnInit(): void {
        this.setFilesData();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!changes || !changes.files) {
            return;
        }

        const { files } = changes;
        if (files.previousValue && files.currentValue && files.previousValue.length < files.currentValue.length) {
            const diff: number = files.currentValue.length - files.previousValue.length;
            [...Array(diff).keys()].forEach(() => {
                this.filesForm.push(this.fb.control(null));
            });
        }

        this.filesForm.patchValue(changes.files.currentValue);
    }

    private initForm(): void {
        this.form = this.fb.group({
            files: this.fb.array([]),
        });
    }

    private setFilesData(): void {
        this.files.forEach((file: SelectedFileExtended) => {
            this.filesForm.push(this.fb.control(file));
        });
    }

    public get filesForm(): UntypedFormArray {
        return this.form.get('files') as UntypedFormArray;
    }

    public emitHiddenEventToChild(): void {
        this.closeSubject.next();
    }

    public removeMultipleFile(file: SelectedFileExtended): void {
        const fileIdx: number = this.filesForm.value.findIndex((f: SelectedFileExtended) => f === file);
        this.filesForm.removeAt(fileIdx);
        this.updateFilesEvent.emit(this.filesForm.value.length ? this.filesForm.value : null);
    }

    public saveSetupData(setupImageData: SelectedFileExtended, i: number): void {
        this.filesForm.controls[i].patchValue({ ...this.files[i], ...setupImageData });
        this.updateFilesEvent.emit(this.filesForm.value.length ? this.filesForm.value : null);
    }

    public encodeFilePreviewUrl(file: SelectedFileExtended): string {
        return encodeFilePreviewUrl(file, this.cdnUrl);
    }

    public dropGroup(event: CdkDragDrop<any[]>): void {
        const files = [...this.files];

        if (event.previousIndex === event.currentIndex) {
            return;
        }

        moveItemInArray(files, event.previousIndex, event.currentIndex);
        this.filesForm.patchValue(files);
        this.updateFilesEvent.emit(this.filesForm.value.length ? this.filesForm.value : null);
    }

    public trackFn(idx: any, file: SelectedFileExtended) {
        return file.url;
    }
}
