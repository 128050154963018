import {
    type AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    inject,
    Input,
    type OnInit,
} from '@angular/core';
import {
    AspectRatio,
    CardKindViewType,
    type CardSettings,
    type GeneralSettings,
    ProductCardWidgetData,
} from '@web/widgets/product-card/type/product-card-widget.type';
import { Classes } from 'jss';
import { BUTTON_SIZE_CLASS_MAP, BUTTON_TYPE_CLASS_MAP, DEFAULT_BUTTON_CLASS } from '@web/widgets/common/base/base-button/base-button.const';
import { EButtonStyleType } from '@common/enums';
import { CommonModule, DOCUMENT } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LightgalleryModule } from 'lightgallery/angular';
import { type LightGallery } from 'lightgallery/lightgallery';
import Swiper, { Autoplay, EffectCards, EffectFade, FreeMode, Navigation, Pagination, type SwiperOptions, Thumbs } from 'swiper';
import { SwiperModule } from 'swiper/angular';
import { PlatformService } from '@common/services/platform.service';
import { type LightGallerySettings } from 'lightgallery/lg-settings';
import { type SelectedFileExtended } from '@common/types/file.type';
import { type InitDetail } from 'lightgallery/lg-events';
import { websiteColorCss } from '@libs/themes';
import { setChatAt } from '@common/helpers/string';
import { type FormArray, type FormControl, type FormGroup, FormsModule, ReactiveFormsModule, UntypedFormBuilder } from '@angular/forms';
import { SpPipesModule } from '@common/pipes/pipes.module';
import { lightGallerySettings } from '@web/widgets/product-card/constants/defaults-product-card.conts';
import { type ProductCard, ProductType, type ProductUnits } from '@common/types/product-card.type';
import { CartFacade } from '@web-builder/store/cart/cart.facade';
import { TransferStateService } from '@web-builder/core/services/transfer-state.service';
import { UtilsService } from '@web-builder/core/services/utils.service';
import { HideSwiperOnSsrDirective } from '@web-builder/core/directives/swiper-ssr.directive';

Swiper.use([Autoplay, Pagination, Thumbs, Navigation, EffectCards, EffectFade, FreeMode]);

export type ParametersFormGroup = FormGroup<{
    label: FormControl<string>;
    parameters: FormControl<string[]>;
    value: FormControl<string>;
}>;

@Component({
    selector: 'web-builder-open-view-card',
    templateUrl: './open-view-card.component.html',
    styleUrls: ['./open-view-card.component.less'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        TranslateModule,
        SwiperModule,
        LightgalleryModule,
        FormsModule,
        SpPipesModule,
        ReactiveFormsModule,
        HideSwiperOnSsrDirective,
    ],
})
export class OpenViewCardComponent implements OnInit, AfterViewInit {
    @Input() data: ProductCardWidgetData;
    @Input() classes: Classes;
    @Input() widgetId: string;
    @Input() displayId: string;

    public readonly currency: string = this.transferStateService.get('site')?.settings?.shop?.currency;
    private readonly cartFacade: CartFacade = inject(CartFacade);
    private readonly document: Document = inject(DOCUMENT);

    public lightGallery!: LightGallery;
    public aspectRatio = AspectRatio;
    public thumbsSwiper: Swiper;
    public mainSwiper: Swiper;
    public swiperConfig: SwiperOptions = {
        lazy: {
            loadPrevNext: true,
        },
    };

    public thumbsParentConfig: SwiperOptions = {
        lazy: {
            loadPrevNext: true,
        },
    };

    private defaultData: {
        price: number;
        oldPrice: number;
        vendorCode: string;
        quantity: number;
        images: SelectedFileExtended[];
        id: string;
    };

    public imagesSrcSets = [];
    public innerWidth: number;

    public counterControl: FormControl<number>;
    public propertiesArray: FormArray<ParametersFormGroup>;

    constructor(
        protected readonly platformService: PlatformService,
        protected readonly changeDetectorRef: ChangeDetectorRef,
        private readonly fb: UntypedFormBuilder,
        protected readonly transferStateService: TransferStateService,
        protected readonly utilsService: UtilsService,
    ) {
        this.counterControl = this.fb.control(1);
        this.propertiesArray = this.fb.array([]);
    }

    ngOnInit() {
        this.onResize();
        this.setImagesSrc();
        this.handleBackClick();
        this.setFormData();
        this.defaultData = {
            price: this.element.price,
            oldPrice: this.element.oldPrice,
            vendorCode: this.element.vendorCode,
            quantity: this.quantity,
            images: this.cardImages,
            id: this.element.id,
        };
    }

    private setImagesSrc() {
        if (this.element) {
            this.imagesSrcSets = this.cardImages.map((image) => {
                if (!image?.hasResize) {
                    return null;
                }

                const srcSets = [
                    {
                        size: 720,
                        media: '(max-width: 720px)',
                    },
                    {
                        size: 1080,
                        media: '(max-width: 1080px)',
                    },
                    {
                        size: 1920,
                        media: '(min-width: 1081px)',
                    },
                ];

                const splitImgSrc = this.formatImageURL(image).split('/');
                splitImgSrc.splice(4, 0, 'resize_landing');
                const extensionDotIndex = splitImgSrc[splitImgSrc.length - 1].lastIndexOf('.');
                splitImgSrc[splitImgSrc.length - 1] = setChatAt(splitImgSrc[splitImgSrc.length - 1], extensionDotIndex, '_');

                const res = splitImgSrc.join('/');

                return srcSets.map((srcset) => {
                    if (srcset.size >= image.imageWidth || image.extension.includes('svg')) {
                        if (image?.hasSourceResize) {
                            return { ...srcset, url: `${res}/source.webp` };
                        }

                        return { ...srcset, url: this.formatImageURL(image) };
                    }

                    return { ...srcset, url: `${res}/${srcset.size}.webp` };
                });
            });
        }
    }

    private setFormData() {
        if (!this.element?.properties?.length || !this.isPhysicalProductType) return;

        this.element.properties.forEach((property) => {
            const { name, parameters } = property;
            this.propertiesArray.push(
                this.fb.group({
                    label: this.fb.control(name),
                    parameters: this.fb.control(parameters),
                    value: this.fb.control(''),
                }),
            );
        });
    }

    public setPropertyValue(value: string, index: number) {
        this.propertiesArray.at(index).controls.value.patchValue(value);

        this.variantHandler();
    }

    private variantHandler() {
        let selectedPropertiesName = '';

        this.propertiesArray.value.forEach((property, index) => {
            if (this.propertiesArray.value.length === 1) {
                selectedPropertiesName = property?.value.trim();
            } else {
                selectedPropertiesName =
                    selectedPropertiesName + (index === this.propertiesArray.value.length - 1 ? '/' : '') + property.value.trim();
            }
        });

        const choosedVariant = this.variants.find((variant) => variant.name === selectedPropertiesName);

        if (!choosedVariant) return;

        this.data.element = {
            ...this.element,
            price: choosedVariant.price.value,
            oldPrice: choosedVariant.oldPrice.value,
            quantity: choosedVariant.quantity.value,
            vendorCode: choosedVariant.vendorCode.value,
            images: choosedVariant.images.length ? choosedVariant.images : this.defaultData.images,
            id: choosedVariant.id,
        };

        this.setImagesSrc();

        this.thumbsSwiper?.slideTo(0);
        this.mainSwiper?.slideTo(0);
        this.changeCounter(1);

        this.changeDetectorRef.detectChanges();
    }

    public setSwiper(swiper: Swiper) {
        this.mainSwiper = swiper;
    }

    public setThumbs(swiper: Swiper): void {
        this.thumbsSwiper = swiper;
        this.thumbsParentConfig = {
            thumbs: {
                swiper: swiper,
            },
        };

        this.changeDetectorRef.detectChanges();
    }

    public changeCounter(event: number): void {
        this.counterControl.setValue(Number(event));
    }

    public validateCounter(): void {
        if (Number(this.counterControl.value) > this.quantity && this.quantity !== null) {
            this.counterControl.setValue(this.quantity);
        } else if (Number(this.counterControl.value) <= 0) {
            this.counterControl.setValue(1);
        }
    }

    public openGallery(index: number) {
        if (!this.data.cardSettings.gallery.fullByClick) return;

        this.lightGallery.openGallery(index);
    }

    public closeGallery() {
        this.lightGallery.closeGallery();
    }

    public formatImageURL(image: SelectedFileExtended): string {
        return `https://${image.defaultCdnUrl}${image.url}`;
    }

    public init = (detail: InitDetail) => {
        this.lightGallery = detail.instance;
    };

    public instanceId = (index: number): number => index;

    public imgIndex(columnIndex: number, imageIndex: number): number {
        return imageIndex * this.masonryArrays.length + columnIndex;
    }

    public openMasonryGallery(columnIndex: number, imageIndex: number) {
        const columns = 2;

        let normalizedIndex = columnIndex + columns * imageIndex;
        this.openGallery(normalizedIndex);
    }

    public handleBackClick() {
        if (this.platformService.isPlatformBrowser()) {
            history.pushState(null, null, location.href);
            window.addEventListener('popstate', (e) => {
                if (document.querySelector('.lg-components-open')) {
                    e.preventDefault();
                    this.closeGallery();
                    history.go(1);
                }
            });
        }
    }

    public addItemToCart(item: ProductCard, event) {
        this.cartFacade.addItemToCart({
            ...item,
            selectedQuantity: this.counterControl.value,
            selectedProperties: this.selectedProperties,
            cardUrl: this.cardUrl,
            id: `product-${this.widgetId}-${item.id}`,
        });
        this.animateButton(event);
    }

    public animateButton(event) {
        let button: HTMLElement = event.currentTarget;
        let cart: HTMLElement = document.getElementById('shopCart');

        button.classList.add('adding-to-cart');
        setTimeout(() => {
            button.classList.remove('adding-to-cart');
        }, 1000);

        cart.classList.add('adding-to-cart');
        setTimeout(() => {
            cart.classList.remove('adding-to-cart');
        }, 600);
    }

    public get loopedSlides(): number {
        return this.cardImages.length === 3 ? this.cardImages.length : this.cardImages.length - 1;
    }

    public get isShowThumbs(): boolean {
        return [CardKindViewType.second, CardKindViewType.fourth].includes(this.data.cardKindViewType);
    }

    public get isCollageView(): boolean {
        return [CardKindViewType.sixth, CardKindViewType.seventh].includes(this.data.cardKindViewType);
    }

    public get isFullPageImage(): boolean {
        return this.data.cardKindViewType === CardKindViewType.eighth;
    }

    public get isGalleryViewTypeFirst(): boolean {
        return this.data.cardKindViewType === CardKindViewType.first;
    }

    public get isGalleryViewTypeSecond(): boolean {
        return this.data.cardKindViewType === CardKindViewType.second;
    }

    public get isGalleryViewTypeFifth(): boolean {
        return this.data.cardKindViewType === CardKindViewType.fifth;
    }

    public get isGalleryViewTypeSixth(): boolean {
        return this.data.cardKindViewType === CardKindViewType.sixth;
    }

    public get gallerySettings(): LightGallerySettings {
        return {
            ...lightGallerySettings,
            dynamic: true,
            dynamicEl: this.cardImages.map((image) => ({
                src: this.formatImageURL(image),
                thumb: this.formatImageURL(image),
            })),
            download: false,
        };
    }

    public get isMasonry(): boolean {
        return this.data.cardKindViewType === CardKindViewType.sixth;
    }

    public get element(): ProductCardWidgetData['element'] {
        return this.data?.element;
    }

    public get cardImages() {
        return this.element?.images;
    }

    public get basketButton(): ProductCardWidgetData['basketButton'] {
        return this.data?.basketButton;
    }

    public get getBasketButtonStyles(): string {
        let styles = `${DEFAULT_BUTTON_CLASS} ${BUTTON_SIZE_CLASS_MAP[this.data.basketButton.size]}`;

        if (this.basketButton.styleType === EButtonStyleType.custom) {
            return `${styles} ${this.classes?.buttonStyles}`;
        }

        return `${styles} ${BUTTON_TYPE_CLASS_MAP[this.basketButton.styleType]}`;
    }

    public get quantity(): number {
        return this.element?.quantity;
    }

    public get isZeroQuantity(): boolean {
        return this.quantity === 0;
    }

    public get isHasAvailableVariants(): boolean {
        if (!this.variants?.length || !this.isPhysicalProductType) return true;

        return !!this.variants?.filter((variant) => variant.isShow).length;
    }

    public get unit(): ProductUnits {
        return this.element?.unit;
    }

    public get isMaxValue(): boolean {
        return +this.counterControl.value === this.quantity;
    }

    public get isMinValue(): boolean {
        return +this.counterControl.value === 1;
    }

    public get isHasBottomSpace(): boolean {
        return this.cardImages.length > 1;
    }

    public get cardSettings(): CardSettings {
        return this.data.cardSettings;
    }

    public get autoplay() {
        if (!this.cardSettings.gallery.changeSlide) return false;

        return {
            delay: 2000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        };
    }

    public get masonryArrays(): SelectedFileExtended[][] {
        const images = [...this.data.element.images];

        let columns = 2;

        let arr = [...Array(columns)].map(() => []);

        for (let i = 0; i < images.length; i++) {
            arr[i % columns].push(images[i]);
        }
        return arr;
    }

    public get backgroundUrl(): string {
        return `url(//${this.element?.images[0].defaultCdnUrl}${this.element.images[0]?.url})`;
    }

    public get generalSettings(): GeneralSettings {
        return this.data.cardSettings.general as GeneralSettings;
    }

    public get isMobileWidth(): boolean {
        return this.innerWidth <= 480;
    }

    public get cardUrl(): string {
        return `${location.href}#${this.displayId}`;
    }

    public get isPhysicalProductType(): boolean {
        return this.element.type === ProductType.physical;
    }

    protected formatImgSrc(src: string): string {
        return this.utilsService.formatImgSrc(src);
    }

    public get variants(): ProductCard['variants'] {
        return this.element?.variants || [];
    }

    public get isDisabledCartButton(): boolean {
        if (!this.propertiesArray.length) return false;

        return !!this.propertiesArray.controls.filter((control) => !control.value.value).length;
    }

    private get selectedProperties(): string[] {
        return Array.from(this.propertiesArray.getRawValue(), (item) => item.value);
    }

    public isDisabledOption(option: string, currentIndex: number): boolean {
        if (this.propertiesArray.at(currentIndex).value.value === option) return false;

        const selectedValues = this.propertiesArray.controls
            .filter((_, index) => index !== currentIndex)
            .map((control) => control.value.value.trim())
            .filter(Boolean);

        const validOptions = new Set<string>();

        for (let variant of this.variants) {
            if (!selectedValues.length && variant.isShow && variant.quantity.value !== 0 && variant?.properties?.includes(option)) {
                return false;
            }

            if (variant.isShow && variant.quantity.value !== 0 && variant?.properties?.some((prop) => selectedValues.includes(prop))) {
                variant?.properties?.forEach((prop) => validOptions.add(prop));
            }
        }

        return !validOptions.has(option);
    }

    public getImagePaddingTop(image: SelectedFileExtended): number {
        let galleryImageRatio: AspectRatio = this.data.cardSettings.gallery.aspectRatio;

        switch (galleryImageRatio) {
            case AspectRatio.default:
                return 100;
            case AspectRatio.manual:
                if (!image.resizedHeight || !image.resizedWidth) {
                    return 100;
                }

                return (image.resizedHeight / image.resizedWidth) * 100;
            case AspectRatio.onoToOne:
                return 100;
            case AspectRatio.fourToThree:
                return 75;
            case AspectRatio.threeToFour:
                return 133;
            case AspectRatio.threeToTwo:
                return 66;
            case AspectRatio.twoToThree:
                return 150;
            case AspectRatio.verticalWide:
                return 177;
            case AspectRatio.wide:
                return 56;
        }
    }

    protected readonly websiteColorCss = websiteColorCss;

    public getFixedImageId(): string {
        return this.isGalleryViewTypeFifth ? this.widgetId + '_fixedImageLayer' : '';
    }

    public getFixedContentId(): string {
        return this.isGalleryViewTypeFifth ? this.widgetId + '_fixedContentLayer' : '';
    }

    public fixedHeaderHeight: number;

    public imageWrapperElement: HTMLDivElement;
    public contentWrapperElement: HTMLDivElement;

    public imageWrapperElementHeight: number;
    public contentWrapperElementHeight: number;

    public cardTopPosition: number;
    public imageWrapperBottomPosition: number;
    public contentWrapperBottomPosition: number;

    public ngAfterViewInit(): void {
        if (!this.isGalleryViewTypeFifth || this.platformService.isPlatformServer()) {
            return;
        }

        setTimeout(() => {
            //get fixed header height
            let fixedHeader = this.document.querySelector('.pined-on-top-block') as HTMLDivElement;
            this.fixedHeaderHeight = fixedHeader ? fixedHeader?.offsetHeight : 0;

            //get image and content outer elements
            let imageContainer = this.document.getElementById(this.widgetId + '_fixedImageLayer') as HTMLDivElement;
            let contentContainer = this.document.getElementById(this.widgetId + '_fixedContentLayer') as HTMLDivElement;

            if (imageContainer && contentContainer) {
                //get image and content inner elements
                this.imageWrapperElement = this.document.querySelector(
                    `[id='${this.widgetId}_fixedImageLayer'] .vertical-image-wrapper`,
                ) as HTMLDivElement;
                this.contentWrapperElement = this.document.querySelector(
                    `[id='${this.widgetId}_fixedContentLayer'] .contentWrapper`,
                ) as HTMLDivElement;

                //get image and content elements height
                this.imageWrapperElementHeight = this.imageWrapperElement?.offsetHeight;
                this.contentWrapperElementHeight = this.contentWrapperElement?.offsetHeight;

                //set image and content default slyles and classes state
                imageContainer.style.minHeight = this.imageWrapperElementHeight + 'px';
                contentContainer.style.minHeight = this.contentWrapperElementHeight + 'px';

                this.imageWrapperElement.style.maxWidth = this.imageWrapperElement?.offsetWidth + 'px';
                this.contentWrapperElement.style.maxWidth = this.contentWrapperElement?.offsetWidth + 'px';

                //get top card position and bottom position for image and content elements
                this.cardTopPosition = this.imageWrapperElement.getBoundingClientRect().top + window.scrollY;
                this.imageWrapperBottomPosition = this.imageWrapperElement.getBoundingClientRect().bottom + window.scrollY;
                this.contentWrapperBottomPosition = this.contentWrapperElement.getBoundingClientRect().bottom + window.scrollY;

                //init state
                if (
                    this.imageWrapperElementHeight > this.contentWrapperElementHeight &&
                    window.scrollY > this.cardTopPosition - 30 - this.fixedHeaderHeight &&
                    window.scrollY < this.imageWrapperBottomPosition - this.contentWrapperElement.offsetHeight - 30 - this.fixedHeaderHeight
                ) {
                    this.contentWrapperElement.classList.add('floating');
                    this.contentWrapperElement.style.top = `calc(30px + ${this.fixedHeaderHeight}px)`;
                } else if (
                    this.contentWrapperElementHeight > this.imageWrapperElementHeight &&
                    window.scrollY > this.cardTopPosition - 30 - this.fixedHeaderHeight &&
                    window.scrollY < this.contentWrapperBottomPosition - this.imageWrapperElement.offsetHeight - 30 - this.fixedHeaderHeight
                ) {
                    this.imageWrapperElement.classList.add('floating');
                    this.imageWrapperElement.style.top = `calc(30px + ${this.fixedHeaderHeight}px)`;
                } else {
                    this.imageWrapperElement.classList.add('fixedTop');
                    this.contentWrapperElement.classList.add('fixedTop');
                }

                this.changeDetectorRef.detectChanges();
            }
        }, 500);
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll() {
        if (this.imageWrapperElement && this.contentWrapperElement && this.platformService.isPlatformBrowser()) {
            if (this.imageWrapperElementHeight > this.contentWrapperElementHeight) {
                if (window.scrollY > this.cardTopPosition - 30 - this.fixedHeaderHeight) {
                    if (this.imageWrapperElement.classList.contains('fixedTop')) {
                        this.imageWrapperElement.classList.remove('fixedTop');
                        this.imageWrapperElement.classList.add('fixedBottom');
                        this.imageWrapperElement.style.top = '';
                    }

                    if (this.contentWrapperElement.classList.contains('fixedTop')) {
                        this.contentWrapperElement.classList.remove('fixedTop');
                        this.contentWrapperElement.classList.add('floating');
                        this.contentWrapperElement.style.top = `calc(30px + ${this.fixedHeaderHeight}px)`;
                    }
                }
                if (window.scrollY < this.cardTopPosition - 30 - this.fixedHeaderHeight) {
                    if (this.imageWrapperElement.classList.contains('fixedBottom')) {
                        this.imageWrapperElement.classList.remove('fixedBottom');
                        this.imageWrapperElement.classList.add('fixedTop');
                        this.imageWrapperElement.style.top = '';
                    }

                    if (this.contentWrapperElement.classList.contains('floating')) {
                        this.contentWrapperElement.classList.remove('floating');
                        this.contentWrapperElement.classList.add('fixedTop');
                        this.contentWrapperElement.style.top = '';
                    }
                }
                if (
                    window.scrollY >
                    this.imageWrapperBottomPosition - this.contentWrapperElement.offsetHeight - 30 - this.fixedHeaderHeight
                ) {
                    if (this.contentWrapperElement.classList.contains('floating')) {
                        this.contentWrapperElement.classList.remove('floating');
                        this.contentWrapperElement.classList.add('fixedBottom');
                        this.contentWrapperElement.style.top = '';
                    }
                }
                if (
                    window.scrollY <
                    this.imageWrapperBottomPosition - this.contentWrapperElement.offsetHeight - 30 - this.fixedHeaderHeight
                ) {
                    if (this.contentWrapperElement.classList.contains('fixedBottom')) {
                        this.contentWrapperElement.classList.remove('fixedBottom');
                        this.contentWrapperElement.classList.add('floating');
                        this.contentWrapperElement.style.top = `calc(30px + ${this.fixedHeaderHeight}px)`;
                    }
                }
            } else if (this.contentWrapperElementHeight > this.imageWrapperElementHeight) {
                if (window.scrollY > this.cardTopPosition - 30 - this.fixedHeaderHeight) {
                    if (this.contentWrapperElement.classList.contains('fixedTop')) {
                        this.contentWrapperElement.classList.remove('fixedTop');
                        this.contentWrapperElement.classList.add('fixedBottom');
                        this.contentWrapperElement.style.top = '';
                    }

                    if (this.imageWrapperElement.classList.contains('fixedTop')) {
                        this.imageWrapperElement.classList.remove('fixedTop');
                        this.imageWrapperElement.classList.add('floating');
                        this.imageWrapperElement.style.top = `calc(30px + ${this.fixedHeaderHeight}px)`;
                    }
                }
                if (window.scrollY < this.cardTopPosition - 30 - this.fixedHeaderHeight) {
                    if (this.contentWrapperElement.classList.contains('fixedBottom')) {
                        this.contentWrapperElement.classList.remove('fixedBottom');
                        this.contentWrapperElement.classList.add('fixedTop');
                        this.contentWrapperElement.style.top = '';
                    }

                    if (this.imageWrapperElement.classList.contains('floating')) {
                        this.imageWrapperElement.classList.remove('floating');
                        this.imageWrapperElement.classList.add('fixedTop');
                        this.imageWrapperElement.style.top = '';
                    }
                }
                if (
                    window.scrollY >
                    this.contentWrapperBottomPosition - this.imageWrapperElement.offsetHeight - 30 - this.fixedHeaderHeight
                ) {
                    if (this.imageWrapperElement.classList.contains('floating')) {
                        this.imageWrapperElement.classList.remove('floating');
                        this.imageWrapperElement.classList.add('fixedBottom');
                        this.imageWrapperElement.style.top = '';
                    }
                }
                if (
                    window.scrollY <
                    this.contentWrapperBottomPosition - this.imageWrapperElement.offsetHeight - 30 - this.fixedHeaderHeight
                ) {
                    if (this.imageWrapperElement.classList.contains('fixedBottom')) {
                        this.imageWrapperElement.classList.remove('fixedBottom');
                        this.imageWrapperElement.classList.add('floating');
                        this.imageWrapperElement.style.top = `calc(30px + ${this.fixedHeaderHeight}px)`;
                    }
                }
            }
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        if (this.platformService.isPlatformBrowser()) {
            this.innerWidth = window.innerWidth;
        }
    }
}
