import type { Jss, JssStyle } from 'jss';
import type { WebWidgetEntity } from '@web/types';
import type { LoginEduWidgetData, LoginWidgetButtons } from '@web/widgets/login-edu/type/login-edu-widget.type';
import { ELoginEduView } from '@common/enums/login-edu-view-form.enum';

import { EButtonStyleType, EHorizontalPosition, ESize } from '@common/enums';

import { getBaseButtonData } from '@web/widgets/common/base/base-button/base-button.styles';
import { getBaseFormStyles } from '@web/widgets/common/base/base-form/base-form.styles';

import { commonWebsitesFormButtonTextStyles } from '@common/constants/button-sizes.const';

function getButtonGroupStyles({ data }: WebWidgetEntity<LoginEduWidgetData>, isConstructor: boolean): JssStyle {
    const justifyStyles = {
        [EHorizontalPosition.L]: 'flex-start',
        [EHorizontalPosition.M]: 'center',
        [EHorizontalPosition.R]: 'flex-end',
    };

    const styles = {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        margin: '-10px',
        justifyContent: justifyStyles[data.horizontalPosition],
    };

    if (!isConstructor) {
        if (data?.hideOnMobile && !data?.hideOnDesktop) {
            styles['@media (max-width: 992px)'] = { display: 'none' };
        }

        if (!data?.hideOnMobile && data?.hideOnDesktop) {
            styles['@media (min-width: 993px)'] = { display: 'none' };
        }

        if (data?.hideOnMobile && data?.hideOnDesktop) {
            styles.display = 'none';
        }
    }

    return styles;
}

function getButtonWrapperStyles(size: ESize): JssStyle {
    const marginStyles = {
        [ESize.S]: '20px',
        [ESize.M]: '24px',
        [ESize.L]: '28px',
    };

    return {
        marginTop: marginStyles[size],
        '& .common-form-btn': {
            textAlign: 'center',
            display: 'block !important',
            boxSizing: 'border-box',
            margin: 0,
        },
    };
}

export function getStyles(widget: WebWidgetEntity<LoginEduWidgetData>, { isConstructor }: any): JssStyle {
    const { data } = widget;

    let regBtnSize: ESize;

    let loginBtnSize: ESize;

    const buttonsStyles = data.buttons.reduce((result: Record<string, JssStyle>, button: LoginWidgetButtons, index: number) => {
        if (button.buttonType === 'registration') {
            regBtnSize = button.registrationButton.size;
            if (button.registrationButton.styleType === EButtonStyleType.custom) {
                result['button_registration_' + index] = getBaseButtonData(button.buttonBase, button.buttonHover);
            }

            if (button.enrollButton.styleType === EButtonStyleType.custom) {
                result['button_enroll_' + index] = getBaseButtonData(button.buttonBase, button.buttonHover);
            }

            return result;
        }

        if (button.buttonType === 'authorization') {
            loginBtnSize = button.size;
        }

        if (button.styleType === EButtonStyleType.custom) {
            result['button_' + index] = getBaseButtonData(button.buttonBase, button.buttonHover);
        }

        return result;
    }, {});

    const formStyles = getBaseFormStyles({ ...data, formButtonSettings: {} }, widget.name);

    return {
        ...buttonsStyles,
        ...formStyles,
        'button-group': getButtonGroupStyles(widget, isConstructor),
        'registration-button-wrapper': getButtonWrapperStyles(regBtnSize),
        'login-button-wrapper': getButtonWrapperStyles(loginBtnSize),
    };
}
