<div #animatedElem class="sp-button-group mobile-column-direction widget-specific-nav-align" [class]="classes?.['button-group']">
    <ng-container *ngFor="let button of buttons; let i = index">
        <a *ngIf="button.link.url"
            class="sp-button-item"
            [id]="button.elementId"
            [class]="getButtonClass(button, i)"
            [attr.href]="getButtonLink(button) | spSafeUrl"
            [attr.target]="getButtonTarget(button)"
            (click)="trySendAnalytic(button)">
            <span *ngIf="button.buttonIcon?.isActive"
                class="icon"
                [innerHTML]="getIcon(button.buttonIcon?.icon)"></span>
            {{ button.label }}
        </a>
        <span *ngIf="!button.link.url"
            class="sp-button-item"
            [id]="button.elementId"
            [class]="getButtonClass(button, i)"
            (click)="trySendAnalytic(button)">
            <span *ngIf="button.buttonIcon?.isActive"
                class="icon"
                [innerHTML]="getIcon(button.buttonIcon?.icon)"></span>
            {{ button.label }}
        </span>
    </ng-container>
</div>
