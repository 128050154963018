import type { JssStyle } from 'jss';

import { ESize } from '@common/enums';

import { EThemeFontTags } from '@common/libs/themes/src/enums/font-tags.enum';
import { EWebsiteThemeFontProperties } from '@libs/themes/src/variants/website/enums/font.enum';
import { websiteFontCss } from '@common/libs/themes/src/variants/website/utils/variables';

// TODO: !!!!!!!!!!!!!!!!!!!!!

// TEXT STYLES
const textCommon: JssStyle = {
    padding: 0,
};

export const webH1Styles: JssStyle = {
    ...textCommon,
    fontFamily: websiteFontCss(EThemeFontTags.h1, EWebsiteThemeFontProperties.fontFamily),
    fontSize: websiteFontCss(EThemeFontTags.h1, EWebsiteThemeFontProperties.fontSize),
    fontWeight: websiteFontCss(EThemeFontTags.h1, EWebsiteThemeFontProperties.fontWeight),
    fontStyle: websiteFontCss(EThemeFontTags.h1, EWebsiteThemeFontProperties.fontStyle),
    lineHeight: websiteFontCss(EThemeFontTags.h1, EWebsiteThemeFontProperties.lineHeight),
    textTransform: websiteFontCss(EThemeFontTags.h1, EWebsiteThemeFontProperties.textTransform),
};

export const webH2Styles: JssStyle = {
    ...textCommon,
    fontFamily: websiteFontCss(EThemeFontTags.h2, EWebsiteThemeFontProperties.fontFamily),
    fontSize: websiteFontCss(EThemeFontTags.h2, EWebsiteThemeFontProperties.fontSize),
    fontWeight: websiteFontCss(EThemeFontTags.h2, EWebsiteThemeFontProperties.fontWeight),
    fontStyle: websiteFontCss(EThemeFontTags.h2, EWebsiteThemeFontProperties.fontStyle),
    lineHeight: websiteFontCss(EThemeFontTags.h2, EWebsiteThemeFontProperties.lineHeight),
    textTransform: websiteFontCss(EThemeFontTags.h2, EWebsiteThemeFontProperties.textTransform),
};

export const webH3Styles: JssStyle = {
    ...textCommon,
    fontFamily: websiteFontCss(EThemeFontTags.h3, EWebsiteThemeFontProperties.fontFamily),
    fontSize: websiteFontCss(EThemeFontTags.h3, EWebsiteThemeFontProperties.fontSize),
    fontWeight: websiteFontCss(EThemeFontTags.h3, EWebsiteThemeFontProperties.fontWeight),
    fontStyle: websiteFontCss(EThemeFontTags.h3, EWebsiteThemeFontProperties.fontStyle),
    lineHeight: websiteFontCss(EThemeFontTags.h3, EWebsiteThemeFontProperties.lineHeight),
    textTransform: websiteFontCss(EThemeFontTags.h3, EWebsiteThemeFontProperties.textTransform),
};

export const webH4Styles: JssStyle = {
    ...textCommon,
    fontFamily: websiteFontCss(EThemeFontTags.h4, EWebsiteThemeFontProperties.fontFamily),
    fontSize: websiteFontCss(EThemeFontTags.h4, EWebsiteThemeFontProperties.fontSize),
    fontWeight: websiteFontCss(EThemeFontTags.h4, EWebsiteThemeFontProperties.fontWeight),
    fontStyle: websiteFontCss(EThemeFontTags.h4, EWebsiteThemeFontProperties.fontStyle),
    lineHeight: websiteFontCss(EThemeFontTags.h4, EWebsiteThemeFontProperties.lineHeight),
    textTransform: websiteFontCss(EThemeFontTags.h4, EWebsiteThemeFontProperties.textTransform),
};

export const webPStyles: JssStyle = {
    ...textCommon,
    fontFamily: websiteFontCss(EThemeFontTags.p, EWebsiteThemeFontProperties.fontFamily),
    fontSize: websiteFontCss(EThemeFontTags.p, EWebsiteThemeFontProperties.fontSize),
    fontWeight: websiteFontCss(EThemeFontTags.p, EWebsiteThemeFontProperties.fontWeight),
    fontStyle: websiteFontCss(EThemeFontTags.p, EWebsiteThemeFontProperties.fontStyle),
    lineHeight: websiteFontCss(EThemeFontTags.p, EWebsiteThemeFontProperties.lineHeight),
    textTransform: websiteFontCss(EThemeFontTags.p, EWebsiteThemeFontProperties.textTransform),
};
// END TEXT STYLES

export const commonFontSizeCoefficient = {
    [ESize.S]: 0.8,
    [ESize.M]: 1,
    [ESize.L]: 1.2,
};
