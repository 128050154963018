import { HttpClient } from '@angular/common/http';
import { Provider } from '@angular/core';
import { TranslateModuleConfig, TranslateService } from '@ngx-translate/core';

import { createTranslationModuleConfig, createTranslationProvider } from '@common/helpers/translations';

import { translateBrowserLoaderFactory, initializeBrowserTranslations } from '@web-builder/core/loaders/translation-browser.loader';
import { translateServerLoaderFactory, initializeServerTranslations } from '@web-builder/core/loaders/translation-server.loader';
import { ServerTranslationsService } from '@common/services/server-translations.service';

// BROWSER
export const browserTranslationsModuleConfig: TranslateModuleConfig = createTranslationModuleConfig(translateBrowserLoaderFactory, [
    ServerTranslationsService,
]);

export const BrowserTranslationProvider: Provider = createTranslationProvider(initializeBrowserTranslations, [
    TranslateService,
    ServerTranslationsService,
]);
// END BROWSER

// SERVER
export const serverTranslationsModuleConfig: TranslateModuleConfig = createTranslationModuleConfig(translateServerLoaderFactory, [
    HttpClient,
    ServerTranslationsService,
]);

export const ServerTranslationsProvider: Provider = createTranslationProvider(initializeServerTranslations, [
    TranslateService,
    ServerTranslationsService,
]);
// END SERVER
