import { CardKindViewType, CardViewType } from '@web/widgets/product-card/type/product-card-widget.type';
import { TRANSPARENT_VALUE } from '@components/sp-color-picker';
import { AspectRatio, Attachment } from '../type/product-card-widget.type';
import { type LightGallerySettings } from 'lightgallery/lg-settings';

export const cardTypeOptions: Array<{ value: CardViewType; key: string }> = [
    { value: CardViewType.open, key: 'widget_product_type_option_open' },
    { value: CardViewType.modal, key: 'widget_product_type_option_modal' },
];

export const productViewCardIcons: Array<{ src: string; alt: number; value: CardKindViewType }> = [
    {
        src: './assets/img/websites/product-card-icons/lpc-product-view-1.svg',
        alt: 1,
        value: CardKindViewType.first,
    },
    {
        src: './assets/img/websites/product-card-icons/lpc-product-view-2.svg',
        alt: 2,
        value: CardKindViewType.second,
    },
    // {
    //     src: './assets/img/websites/product-card-icons/lpc-product-view-3.svg',
    //     alt: 3,
    //     value: CardKindViewType.third,
    // },
    // {
    //     src: './assets/img/websites/product-card-icons/lpc-product-view-4.svg',
    //     alt: 4,
    //     value: CardKindViewType.fourth,
    // },
    {
        src: './assets/img/websites/product-card-icons/lpc-product-view-5.svg',
        alt: 5,
        value: CardKindViewType.fifth,
    },
    {
        src: './assets/img/websites/product-card-icons/lpc-product-view-6.svg',
        alt: 6,
        value: CardKindViewType.sixth,
    },
    // {
    //     src: './assets/img/websites/product-card-icons/lpc-product-view-7.svg',
    //     alt: 7,
    //     value: CardKindViewType.seventh,
    // },
    // {
    //     src: './assets/img/websites/product-card-icons/lpc-product-view-8.svg',
    //     alt: 8,
    //     value: CardKindViewType.eighth,
    // },
];

export const cardSettingsStyles: Record<CardKindViewType, { general: any; gallery: any; info: any }> = {
    [CardKindViewType.first]: {
        general: {
            galleryWidth: '60%',
            contentWidth: '40%',
        },
        gallery: {
            margin: 0,
        },
        info: {
            backgroundColor: TRANSPARENT_VALUE,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 50,
            paddingRight: 50,
        },
    },
    [CardKindViewType.second]: {
        general: {
            galleryWidth: '50%',
            contentWidth: '50%',
        },
        gallery: {
            margin: 0,
        },
        info: {
            backgroundColor: TRANSPARENT_VALUE,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 40,
            paddingRight: 40,
        },
    },
    [CardKindViewType.third]: {
        general: {
            galleryWidth: '60%',
            contentWidth: '40%',
        },
        gallery: {
            attachment: Attachment.contain,
            aspectRatio: AspectRatio.default,
            margin: 0,
            backgroundColor: TRANSPARENT_VALUE,
        },
        info: {
            backgroundColor: TRANSPARENT_VALUE,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 40,
            paddingRight: 40,
        },
    },
    [CardKindViewType.fourth]: {
        general: {
            galleryWidth: '60%',
            contentWidth: '40%',
        },
        gallery: {
            attachment: Attachment.contain,
            aspectRatio: AspectRatio.default,
            margin: 0,
            backgroundColor: TRANSPARENT_VALUE,
        },
        info: {
            backgroundColor: TRANSPARENT_VALUE,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 40,
            paddingRight: 40,
        },
    },
    [CardKindViewType.fifth]: {
        general: {
            galleryWidth: '65%',
            contentWidth: '35%',
        },
        gallery: {
            attachment: Attachment.contain,
            aspectRatio: AspectRatio.default,
            margin: 0,
            backgroundColor: TRANSPARENT_VALUE,
        },
        info: {
            backgroundColor: TRANSPARENT_VALUE,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 50,
            paddingRight: 50,
        },
    },
    [CardKindViewType.sixth]: {
        general: {
            galleryWidth: '65%',
            contentWidth: '35%',
        },
        gallery: {
            attachment: Attachment.cover,
            aspectRatio: AspectRatio.onoToOne,
            margin: 0,
            backgroundColor: TRANSPARENT_VALUE,
        },
        info: {
            backgroundColor: TRANSPARENT_VALUE,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 50,
            paddingRight: 50,
        },
    },
    [CardKindViewType.seventh]: {
        general: {
            galleryWidth: '60%',
            contentWidth: '40%',
        },
        gallery: {
            attachment: Attachment.contain,
            aspectRatio: AspectRatio.default,
            margin: 0,
            backgroundColor: TRANSPARENT_VALUE,
        },
        info: {
            backgroundColor: TRANSPARENT_VALUE,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 40,
            paddingRight: 40,
        },
    },
    [CardKindViewType.eighth]: {
        general: {
            galleryWidth: '60%',
            contentWidth: '40%',
        },
        gallery: {
            attachment: Attachment.contain,
            aspectRatio: AspectRatio.default,
            margin: 0,
            backgroundColor: TRANSPARENT_VALUE,
        },
        info: {
            backgroundColor: TRANSPARENT_VALUE,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 40,
            paddingRight: 40,
        },
    },
};

export const lightGallerySettings: LightGallerySettings = {
    mobileSettings: {
        controls: false,
        showCloseIcon: true,
        download: false,
    },
    swipeToClose: true,
    closeOnTap: true,
    hideScrollbar: true,
    thumbnail: true,
};
