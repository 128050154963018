import type { JssStyle } from 'jss';
import type { WebWidgetEntity } from '@web/types';
import { EResizeType, type ImageWidgetData } from '@web/widgets/image/type/image-widget.type';

import { EConditionType, EFontStyle, EHorizontalPosition, EOverlayType, EVerticalPosition } from '@common/enums';
import { commonFontSizeCoefficient, webPStyles } from '@libs/themes/src/variants/website/styles/text.styles';

const justifyStyles = {
    [EHorizontalPosition.L]: 'flex-start',
    [EHorizontalPosition.M]: 'center',
    [EHorizontalPosition.R]: 'flex-end',
};

const textPosition = {
    [EHorizontalPosition.L]: 'left',
    [EHorizontalPosition.M]: 'center',
    [EHorizontalPosition.R]: 'right',
};

function getFontStyles(fontStyle: EFontStyle[]): JssStyle {
    return {
        fontWeight: fontStyle?.includes(EFontStyle.B) ? 'bold' : 'initial',
        fontStyle: fontStyle?.includes(EFontStyle.I) ? 'italic' : 'initial',
        textTransform: fontStyle?.includes(EFontStyle.AT) ? 'uppercase' : 'initial',
    };
}

function getDescriptionStyles({ data }: WebWidgetEntity<ImageWidgetData>): JssStyle {
    let commonDescription: JssStyle = {
        position: 'absolute',
        width: '100%',
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        wordBreak: 'break-word',
        boxSizing: 'border-box',
        padding: data.image?.description
            ? `${data.image.paddingTop}px ${data.image.paddingRight}px ${data.image.paddingBottom}px ${data.image.paddingLeft}px`
            : '0',
        background: data.image?.textBackground,
    };

    if (data.image?.condition === EConditionType.ON_HOVER && data.image?.isImageEffect) {
        commonDescription = {
            ...commonDescription,
            ...{
                opacity: 0,
                transition: 'opacity 1s linear',
            },
        };
    }

    if (!data.image?.description) {
        return {
            ...commonDescription,
            bottom: '0',
            left: '0',
        };
    }

    // TODO: REFACTOR TO FLEX
    const descriptionPositionStyles: Record<EVerticalPosition, JssStyle> = {
        [EVerticalPosition.B]: {
            bottom: '0',
            left: '0',
        },
        [EVerticalPosition.M]: {
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
        [EVerticalPosition.T]: {
            top: '0',
            left: '0',
        },
    };

    return {
        ...commonDescription,
        ...descriptionPositionStyles[data.image.textPosition],
    };
}

function getTextStyles({ data }: WebWidgetEntity<ImageWidgetData>): JssStyle {
    if (!data.image?.description) {
        return {};
    }

    const fontSizeCoefficient: number = commonFontSizeCoefficient[data.image.textSize];
    const scaledFontSize: string = data.image.fontSize;
    const fontStyles: JssStyle = getFontStyles(data.image?.textStyle);
    const textStyles: JssStyle = { ...webPStyles, fontSize: scaledFontSize, color: data.image.textColor };
    const marginLeft: string = data.image.link ? '16px' : '0';
    const marginRight: string = data.image.link ? `calc(${webPStyles.fontSize} * ${fontSizeCoefficient} + 15px)` : '0';

    return {
        display: 'flex',
        flex: '1',
        justifyContent: justifyStyles[data.image.textJustify],
        textAlign: textPosition[data.image.textJustify],
        marginRight,
        marginLeft,
        ...textStyles,
        ...fontStyles,
    };
}

function getImgWidgetWrapperStyles({ data }: WebWidgetEntity<ImageWidgetData>, isConstructor: boolean): JssStyle {
    let horizontalAlignValue: string;

    if (data.mobileHorizontalAlign) {
        horizontalAlignValue = justifyStyles[data.mobileHorizontalAlign];
    } else {
        horizontalAlignValue = justifyStyles[data.horizontalAlign];
    }

    const mediaQueryKey = '@media (max-width: 992px)';
    const styles: any = {
        zIndex: data?.position?.zIndex,
        position: 'relative',
        transform: `rotate(${data?.position?.rotate ?? 0}deg)`,
        display: 'flex',
        justifyContent: justifyStyles[data.horizontalAlign],
        [mediaQueryKey]: {
            justifyContent: horizontalAlignValue,
            transform: `rotate(${data?.position?.mobileRotate ?? 0}deg)`,
        },
        '&.mobileWidgetViewWrap': {
            justifyContent: horizontalAlignValue,
        },
    };

    if (!isConstructor) {
        if (data?.hideOnMobile && !data?.hideOnDesktop) {
            styles[mediaQueryKey] = { display: 'none' };
        }

        if (!data?.hideOnMobile && data?.hideOnDesktop) {
            styles['@media (min-width: 481px)'] = { display: 'none' };
        }

        if (data?.hideOnMobile && data?.hideOnDesktop) {
            styles.display = 'none';
        }
    }

    if (isConstructor) {
        styles['&.mobileWidgetViewWrap'].transform = `rotate(${data?.position?.mobileRotate ?? 0}deg)`;
    }

    return styles;
}

function getImgWrapperStyles({ data, styles }: WebWidgetEntity<ImageWidgetData>, isSpecific?: boolean): JssStyle {
    const boxShadowValue: string = '0 0 12px 4px rgba(0, 0, 0, .08)';
    const borderValue = `${styles.borderWidth}px ` + styles.borderStyle + ` ${styles.borderColor}`;
    const imgWrapper: JssStyle = {
        position: 'relative',
        width: '100%',
        borderRadius: styles.borderRadius + 'px',
        overflow: 'hidden',
    };

    if (!data.resizeType || data.resizeType === EResizeType.scale) {
        imgWrapper.maxWidth = data.image ? styles.maxWidth + 'px' : '100%';
    } else if (data.resizeType === EResizeType.fill) {
        if (!data.image) {
            imgWrapper.paddingTop = '200px';
        } else if (data.image.resizedHeight && data.image.resizedWidth) {
            imgWrapper.paddingTop = (data.image.resizedHeight / data.image.resizedWidth) * 100 + '%';
        } else {
            imgWrapper.paddingTop = '100%';
        }
    }
    if (data.isShadow) {
        imgWrapper.boxShadow = boxShadowValue;
    }

    if (data.isBorder) {
        imgWrapper.border = borderValue;
    }

    if (data.isBorder === false) {
        imgWrapper.border = 'none';
    }

    if (!data.image) {
        imgWrapper.height = '200px';
        imgWrapper.background = '#F8FDFE';
    }

    if (isSpecific && !data.image) {
        imgWrapper.minWidth = '100px';
        imgWrapper.height = '80px';
        imgWrapper.paddingTop = '80px';
    }

    return imgWrapper;
}

function getImageStyles({ data }: WebWidgetEntity<ImageWidgetData>): JssStyle {
    if (!data.image) {
        return {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        };
    }
    let imageStyles: JssStyle = {
        display: 'block',
        height: 'auto',
        maxWidth: '100%',
    };

    if (data.resizeType === EResizeType.fill) {
        imageStyles = {
            position: 'absolute',
            inset: 0,
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            userSelect: 'none',
        };
    }
    if (data.image?.isImageEffect) {
        if (data.image?.overlay === EOverlayType.INCREASE) {
            imageStyles = {
                ...imageStyles,
                ...{
                    transition: 'all .3s ease-in-out',
                    '&:hover': {
                        transform: 'scale(1.1)',
                    },
                },
            };
        }

        if (data.image?.overlay === EOverlayType.DECREASE) {
            imageStyles = {
                ...imageStyles,
                ...{
                    transition: 'all .3s ease-in-out',
                    '&:hover': {
                        transform: 'scale(0.9)',
                    },
                },
            };
        }
    }

    return imageStyles;
}

/*function getImageTestStyles({ styles }: WebWidgetEntity<ImageWidgetData>): JssStyle {
    return {
        display: 'block',
        position: 'relative',
        maxWidth: '100%',
        minHeight: '40px',
        overflow: 'hidden',
        paddingTop: styles.height + '%',
    };
}*/

function getImageItemStyles({ data }: WebWidgetEntity<ImageWidgetData>): JssStyle {
    if (!data.image) {
        return {
            backgroundSize: 'cover',
            backgroundColor: 'transparent',
            backgroundPosition: '50% 50%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '10%',
            height: '30%',
        };
    }

    return {
        backgroundSize: 'cover',
        backgroundColor: 'transparent',
        backgroundPosition: '50% 50%',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    };
}

function getImageMaskStyles({ data }: WebWidgetEntity<ImageWidgetData>): JssStyle {
    let imageMaskStyles = {};

    if (!data.image) {
        return imageMaskStyles;
    }

    if (data.image?.maskShape) {
        imageMaskStyles = { WebkitMaskImage: `url('assets/img/websites/mask-shapes/${data.image?.maskShape}.svg')` };
    }

    return imageMaskStyles;
}

export function getStyles(widget: WebWidgetEntity<ImageWidgetData>, config: any): JssStyle {
    return {
        imgWidgetWrapper: getImgWidgetWrapperStyles(widget, config.isConstructor),
        imgWrapper: getImgWrapperStyles(widget, config.isSpecific),
        image: getImageStyles(widget),
        imageMask: getImageMaskStyles(widget),
        imageItem: getImageItemStyles(widget),
        description: getDescriptionStyles(widget),
        text: getTextStyles(widget),
    };
}
