import type { JssStyle } from 'jss';
import type { WebWidgetEntity } from '@web/types';
import type { ShopCartWidgetData } from '@web/widgets/shop-cart';

import { getBaseFormStyles } from '@web/widgets/common/base/base-form/base-form.styles';
import { ECartViewType, EFontStyle, ESize } from '@common/enums';
import { EThemeFontTags, EWebsiteThemeFontProperties, websiteColorCss, websiteFontCss } from '@libs/themes';
import { counterSizeStyles } from '@web/widgets/product-card/components/product-card-view/product-card-view.component.styles';
import { simpleColorCss } from '@libs/themes/src/variants/simple/utils/variables';
import { ESimpleThemeColorNames } from '@libs/themes/src/variants/simple/enums/color.enum';

function previewSize(size: ESize): string {
    switch (size) {
        case ESize.S:
            return '80px';
        case ESize.M:
            return '120px';
        case ESize.L:
            return '160px';
        default:
            return '80px';
    }
}

function previewIconSize(size: ESize): string {
    switch (size) {
        case ESize.S:
            return '40px';
        case ESize.M:
            return '48px';
        case ESize.L:
            return '56px';
        default:
            return '48px';
    }
}

function getFontStyles(fontStyle: EFontStyle[]): JssStyle {
    return {
        fontWeight: fontStyle.includes(EFontStyle.B) ? 'bold' : 'initial',
        fontStyle: fontStyle.includes(EFontStyle.I) ? 'italic' : 'initial',
        textTransform: fontStyle.includes(EFontStyle.AT) ? 'uppercase' : 'initial',
    };
}

export function getStyles({ data }: WebWidgetEntity<ShopCartWidgetData>): JssStyle {
    const cartSet = data.cartSettings;
    const cartIconSet = data.cartIconSettings;
    const cartViewType: JssStyle = {
        position: 'relative',
        display: 'flex',
        flexDirection: data.cartViewType === ECartViewType.HORIZONTAL ? 'row' : 'column',
        gap: '30px',
        '& .cart-items': {
            width: data.cartViewType === ECartViewType.HORIZONTAL ? 'calc(60% - 30px)' : '100%',
        },
        '& .cart-form-wrapper': {
            width: data.cartViewType === ECartViewType.HORIZONTAL ? '40%' : '100%',
            height: 'fit-content',
        },
        '@media (max-width: 1200px)': {
            flexDirection: 'column',
            '& .cart-items': {
                width: '100%',
            },
            '& .cart-form-wrapper': {
                width: '100%',
                '& .cart-form': {
                    margin: '0 auto',
                },
            },
        },
        '&.mobileViewCart': {
            flexDirection: 'column',
            '& .cart-items': {
                width: '100%',
            },
            '& .cart-form-wrapper': {
                width: '100%',
                '& .cart-form': {
                    margin: '0 auto',
                },
            },
        }
    };
    const cartStyle: JssStyle = {
        '& .item': {
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '20px 50px 20px 0',
            borderBottom: cartSet.isSeparatedItems
                ? `${cartSet.separatorWidth}px ${cartSet.separatorType} ${websiteColorCss(cartSet.separatorColor as string)}`
                : 'none',
            '& .modal-divider': {
                display: 'block',
                width: '100%',
                height: '1px',
                margin: '25px 0',
                background: simpleColorCss(ESimpleThemeColorNames.primary),
            },
            '& .item-info': {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                '& .item-info-photo': {
                    background: '#eee',
                    marginRight: '20px',
                    '& img': {
                        width: previewSize(cartSet.previewSize),
                        height: previewSize(cartSet.previewSize),
                        objectFit: 'cover',
                    },
                },
                '& .item-info-text': {
                    marginRight: '20px',
                    '& h3': {
                        ...getFontStyles(cartSet.itemTitleFontStyle),
                        fontSize: `${cartSet.itemTitleFontSize}px`,
                        color: cartSet.itemTitleFontColor,
                        cursor: 'pointer',
                        marginBottom: '20px',
                        marginTop: '10px',
                        fontFamily: websiteFontCss(EThemeFontTags.p, EWebsiteThemeFontProperties.fontFamily),
                    },
                    '& .parameters': {
                        '& label': {
                            fontSize: `${cartSet.itemPropertyFontSize + 2}px`,
                            fontWeight: 'bold',
                            marginBottom: '10px',
                            fontFamily: websiteFontCss(EThemeFontTags.p, EWebsiteThemeFontProperties.fontFamily),
                        },
                        '& p': {
                            ...getFontStyles(cartSet.itemPropertyFontStyle),
                            fontSize: `${cartSet.itemPropertyFontSize}px`,
                            color: cartSet.itemPropertyFontColor,
                            marginBottom: '5px',
                            fontFamily: websiteFontCss(EThemeFontTags.p, EWebsiteThemeFontProperties.fontFamily),
                            '& .param-prop': {
                                marginRight: '5px',
                            },
                            '& .param-value': {
                                opacity: 0.7,
                            },
                        },
                    },
                },
            },
            '& .item-price': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'end',
                gap: '10px',
                justifyContent: 'flex-start',
                marginTop: '10px',
                '& .card-product-counter': {
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    borderWidth: '1px',
                    boxSizing: 'border-box',
                    borderStyle: 'solid',
                    whiteSpace: 'nowrap',
                    borderRadius: `${cartSet.countButtonRounding}px`,
                    background: websiteColorCss(cartSet.countButtonBgColor as string),
                    borderColor: websiteColorCss(cartSet.countButtonBorderColor as string),
                    color: websiteColorCss(cartSet.countButtonTextColor as string),
                    ...counterSizeStyles[cartSet.countButtonSize || ESize.M],
                    padding: '0 !important',
                    '& .counter-control': {
                        cursor: 'pointer',
                        padding: counterSizeStyles[cartSet.countButtonSize || ESize.M].padding,
                        background: 'transparent',
                        border: 'none',
                        color: websiteColorCss(cartSet.countButtonTextColor as string),
                        '&:hover': {
                            opacity: 0.8,
                        },
                    },
                    '& .quantity-control': {
                        background: 'transparent',
                        color: websiteColorCss(cartSet.countButtonTextColor as string),
                        fontSize: counterSizeStyles[cartSet.inputSize || ESize.M].fontSize,
                        lineHeight: counterSizeStyles[cartSet.inputSize || ESize.M].lineHeight,
                    },
                    '& .disabled': {
                        cursor: 'not-allowed',
                        pointerEvents: 'none',
                        opacity: 0.5,
                    },
                },
                '& .price': {
                    ...getFontStyles(cartSet.itemPriceFontStyle),
                    fontSize: `${cartSet.itemPriceFontSize}px`,
                    color: cartSet.itemPriceFontColor,
                    fontFamily: websiteFontCss(EThemeFontTags.p, EWebsiteThemeFontProperties.fontFamily),
                },
            },
            '& .close-icon': {
                position: 'absolute',
                top: 'calc(50% - 12px)',
                right: 0,
                background: websiteColorCss(cartSet.deleteButtonBgColor),
                color: websiteColorCss(cartSet.deleteButtonIconColor),
                width: '24px',
                height: '24px',
                textAlign: 'center',
                fontSize: '22px',
                lineHeight: '24px',
                borderRadius: '50%',
                fontWeight: 'lighter',
                fontFamily: 'inherit',
                alignSelf: 'center',
                '&:hover': {
                    opacity: 0.8,
                    cursor: 'pointer',
                },
            },
            '@media (max-width: 768px)': {
                flexDirection: 'column',
                gap: '20px',
                paddingBottom: '20px',
                '& .item-info': {
                    flexDirection: 'column',
                    '& .item-info-photo': {
                        marginBottom: '20px',
                    },
                },
                '& .item-price': {
                    justifyContent: 'start',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '20px',
                    marginTop: '0',
                },
                '& .close-icon': {
                    top: '20px',
                },
            },
            '&.mobileViewCartItem': {
                flexDirection: 'column',
                gap: '20px',
                paddingBottom: '20px',
                '& .item-info': {
                    flexDirection: 'column',
                    '& .item-info-photo': {
                        marginBottom: '20px',
                    },
                },
                '& .item-price': {
                    justifyContent: 'start',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '20px',
                    marginTop: '0',
                },
                '& .close-icon': {
                    top: '20px',
                },
            },
        },
        '& .cart-form-wrapper': {
            width: data.cartViewType === ECartViewType.HORIZONTAL ? '45%' : '100%',
        },
        '& .total-price': {
            marginTop: '30px',
            '& p': {
                ...getFontStyles(cartSet.itemTotalPriceFontStyle),
                fontSize: `${cartSet.itemTotalPriceFontSize}px`,
                color: cartSet.itemTotalPriceFontColor,
            },
        },
    };
    const cartIconStyle: JssStyle = {
        position: 'fixed',
        right: '3%',
        top: '3%',
        '& .cart-icon-wrapper': {
            borderRadius: `${cartIconSet.iconRounding}px`,
            background: cartIconSet.iconBgColor,
            color: cartIconSet.iconColor,
            height: previewIconSize(cartIconSet.iconSize),
            width: previewIconSize(cartIconSet.iconSize),
        },
        '& .icon-counter': {
            background: cartIconSet.iconCounterBgColor,
            color: cartIconSet.iconCounterColor,
        },
    };

    const cartFormWrapper: JssStyle = {
        background: websiteColorCss(data.inputSettings.modalBackgroundColor),
        padding: data.inputSettings.modalPadding + 'px',
        borderRadius: data.inputSettings.modalBorderRadius + 'px',
    };

    const formDropdownButton = {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '39px',
        backgroundColor: '#fff',
        border: '1px solid #bcc8c8',
        boxSizing: 'border-box',
        borderRadius: '4px',

        '& div': {
            display: 'flex',
            alignItems: 'center',
        },

        '& span': {
            fontWeight: 'normal',
        },

        '& img': {
            width: '20px',
            height: '20px',
            marginRight: '10px',
        },

        '&:hover': {
            borderColor: '#b8cece',
            backgroundColor: '#fff',
        },

        '& .open': {
            '& .dropdown-toggle.custom-btn': {
                backgroundColor: '#fff',
                borderColor: '#00b175',
                outline: '0 !important',
                boxShadow: '0 0 0 2px rgba(49,193,120,0.2)',
            },

            '& .dropdown-menu': {
                margin: '0 5px',
                maxWidth: '300px',
            },
        },
    };

    const cartEmptyState: JssStyle = {
        width: '100%',
        padding: '20px',
        color: cartSet.itemTitleFontColor,
        textAlign: 'center',
    }

    return {
        ...getBaseFormStyles(data),
        cartIconStyle,
        cartViewType,
        cartStyle,
        cartFormWrapper,
        cartEmptyState,
        formDropdownButton,
    };
}
