<div [class]="classes?.imgWrapper" *ngIf="isPreview">
    <img
        [class]="classes?.img"
        [src]="formatImgSrc('./assets/img/websites/code-placeholder.svg')"
        alt="code placeholder"
    />
    <div [class]="classes?.text">
        {{ "custom_code_on_site" | translate }}
    </div>
</div>
