import { type WebWidgetEntity } from '@web/types';
import {
    Attachment,
    type ButtonSettings,
    CardKindViewType,
    type GallerySettings,
    type GeneralSettings,
    type InfoSettings,
    type ProductCardWidgetData,
    ViewModalType,
} from '@web/widgets/product-card/type/product-card-widget.type';
import type { JssStyle } from 'jss';
import { EFontStyle, EHorizontalPosition, ESize, InputBorderEnum } from '@common/enums';
import { getBaseButtonData } from '@web/widgets/common/base/base-button/base-button.styles';
import { EThemeFontTags, EWebsiteThemeFontProperties, websiteColorCss, websiteFontCss } from '@libs/themes';
import { commonWebsitesFormButtonTextStyles } from '@common/constants/button-sizes.const';

const justifyStyles = {
    [EHorizontalPosition.L]: 'flex-start',
    [EHorizontalPosition.M]: 'center',
    [EHorizontalPosition.R]: 'flex-end',
};

export const counterSizeStyles: Record<ESize, JssStyle> = {
    [ESize.S]: {
        width: '108px',
        height: '35px',
        padding: '10px 6px !important',
        fontSize: '13px !important',
        lineHeight: '15px !important',
    },
    [ESize.M]: {
        width: '120px',
        height: '41px',
        padding: '12px 10px !important',
        fontSize: '15px !important',
        lineHeight: '17px !important',
    },
    [ESize.L]: {
        width: '132px',
        height: '57px',
        padding: '18px 16px !important',
        fontSize: '19px !important',
        lineHeight: '21px !important',
    },
};

export const variantSizeStyles: Record<ESize, JssStyle> = {
    [ESize.S]: {
        maxWidth: '220px',
        minWidth: '100px',
        height: '35px',
        padding: '8px 10px',
        fontSize: '13px',
        lineHeight: '15px',
    },
    [ESize.M]: {
        maxWidth: '240px',
        minHWidth: '120px',
        height: '41px',
        padding: '10px 16px',
        fontSize: '15px',
        lineHeight: '17px',
    },
    [ESize.L]: {
        maxWidth: '260px',
        minHWidth: '140px',
        height: '57px',
        padding: '16px 22px',
        fontSize: '19px',
        lineHeight: '21px',
    },
};

function getFontStyles(fontStyle: EFontStyle[]): JssStyle {
    return {
        fontWeight: fontStyle?.includes(EFontStyle.B) ? 'bold' : 'initial',
        fontStyle: fontStyle?.includes(EFontStyle.I) ? 'italic' : 'initial',
        textTransform: fontStyle?.includes(EFontStyle.AT) ? 'uppercase' : 'initial',
    };
}

function getGeneralSettings(data: GeneralSettings): JssStyle {
    const generalSettings: JssStyle = {
        backgroundColor: websiteColorCss(data.backgroundColor as string),
        borderRadius: `${data.borderRadius}px`,
        padding: `${data.padding}px`,
    };

    if (data.isBorder) {
        generalSettings.borderWidth = `${data.borderWidth}px`;
        generalSettings.borderStyle = data.borderStyle;
        generalSettings.borderColor = websiteColorCss(data.borderColor as string);
    }
    // substrateColor?: string;

    return generalSettings;
}

function getGallerySettings(data: GallerySettings): JssStyle {
    return {
        margin: data.margin,

        '& img': {
            borderRadius: `${data.borderRadius}px`,
        },
    };

    // buttonColor: string;
    // changeSlide: boolean;
    // fullByClick: boolean;
}

function getInfoSettings(data: InfoSettings): JssStyle {
    const infoSettings: JssStyle = {
        backgroundColor: websiteColorCss(data.backgroundColor as string),
        borderRadius: data.borderRadius,
        paddingTop: `${data.paddingTop}px`,
        paddingBottom: `${data.paddingBottom}px`,
        paddingLeft: `${data.paddingLeft}px`,
        paddingRight: `${data.paddingRight}px`,
    };

    if (data.isBorder) {
        infoSettings.borderWidth = `${data.borderWidth}px`;
        infoSettings.borderStyle = data.borderStyle;
        infoSettings.borderColor = websiteColorCss(data.borderColor as string);
    }

    return infoSettings;
}

function commonTypographySettings(data): JssStyle {
    const fontStyles: JssStyle = getFontStyles(data.fontStyle);

    return {
        ...fontStyles,
        color: websiteColorCss(data.color as string),
        fontSize: `${data.fontSize}px`,
    };
}

function getCustomButtonStyle(data: ButtonSettings): JssStyle {
    return getBaseButtonData(data.buttonBase, data.buttonHover);
}

function getHorizontalPreviewLayout(cardModalViewType: ViewModalType): JssStyle {
    if (cardModalViewType === ViewModalType.horizontal) {
        return {
            width: '70%',
            display: 'flex',
            flexDirection: 'row',
            '@media (max-width: 768px)': {
                width: '100%',
                flexDirection: 'column',
                paddingLeft: 0,
                paddingTop: '30px',
            },
            '& .left-side': {
                marginRight: '50px',
                '@media (max-width: 768px)': {
                    marginRight: 0,
                },
                '& .card-description': {
                    marginBottom: 0,
                    '@media (max-width: 768px)': {
                        marginBottom: '30px',
                    },
                },
            },
            '& .right-side': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                '& .card-price-block': {
                    flexDirection: 'column',
                    alignItems: 'end',
                    textWrap: 'nowrap',
                    '@media (max-width: 768px)': {
                        flexDirection: 'row',
                        alignItems: 'baseline',
                    },
                    '& .card-new-price': {
                        marginRight: 0,
                        '@media (max-width: 768px)': {
                            marginRight: '10px',
                        },
                    },
                },
            },
        };
    }
}

function getPreviewMainWrapperAling(position: EHorizontalPosition): JssStyle {
    switch (position) {
        case EHorizontalPosition.M:
            return { justifyContent: 'center' };
        case EHorizontalPosition.R:
            return { justifyContent: 'flex-end' };
        default:
            return { justifyContent: 'flex-start' };
    }
}

export function getStyles(widget: WebWidgetEntity<ProductCardWidgetData>): JssStyle {
    const {
        previewModalSettings,
        modalSettings,
        cardSettings,
        basketButton,
        cardModalViewType,
        previewHorizontalPosition,
        element,
        cardKindViewType,
        showMoreButton,
    } = widget.data;

    const { borderRadius, fullByClick, margin } = cardSettings?.gallery;

    const isMasonry = [CardKindViewType.sixth].includes(cardKindViewType);

    const blankImageWrapper: JssStyle = {
        height: '200px',
        background: '#F8FDFE',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '15px',
        justifyContent: 'center',
        '& img': {
            height: '64px',
            marginBottom: '10px',
        },
        '& span': {
            fontSize: '15px',
            color: '#023346',
            textAlign: 'center',
        },
    };

    const previewMainWrapper: JssStyle = {
        display: 'flex',
        ...getPreviewMainWrapperAling(widget.data.previewHorizontalPosition),
    };

    const previewVerticalViewWrapper: JssStyle = {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '480px',
        alignItems: justifyStyles[previewHorizontalPosition],
        ...getGeneralSettings(previewModalSettings.general),

        '& .preview-image-container': {
            width: '100%',
            '& .image-wrapper.resizeble': {
                position: 'relative',
                overflow: 'hidden',
                height: '100%',
                paddingTop: '100%',
                '& img': {
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    inset: 0,
                    objectFit: previewModalSettings?.gallery?.attachment === Attachment.cover ? 'cover' : 'contain',
                    margin: previewModalSettings?.gallery?.attachment === Attachment.cover ? '0' : 'auto',
                    userSelect: 'none',
                    borderRadius: previewModalSettings.gallery.borderRadius + 'px',
                },
            },
            '& .image-wrapper': {
                backgroundColor: websiteColorCss(previewModalSettings?.gallery?.backgroundColor as string),
                '& img': {
                    width: '100%',
                    height: 'auto',
                    borderRadius: previewModalSettings.gallery.borderRadius + 'px',
                },
            },
            '& .blank-wrapper': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                '& img': {
                    width: '100%',
                    height: 'auto',
                },
            },
        },
    };

    const previewHorizontalViewWrapper: JssStyle = {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        ...getGeneralSettings(previewModalSettings.general),
        '@media (max-width: 768px)': {
            flexDirection: 'column',
        },

        '& .preview-image-container': {
            width: '30%',
            '@media (max-width: 768px)': {
                width: '100%',
            },
            '& .image-wrapper.resizeble': {
                position: 'relative',
                overflow: 'hidden',
                height: '100%',
                paddingTop: '100%',
                '& img': {
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    inset: 0,
                    objectFit: previewModalSettings?.gallery?.attachment === Attachment.cover ? 'cover' : 'contain',
                    margin: previewModalSettings?.gallery?.attachment === Attachment.cover ? '0' : 'auto',
                    userSelect: 'none',
                    borderRadius: previewModalSettings.gallery.borderRadius + 'px',
                },
            },
            '& .image-wrapper': {
                backgroundColor: websiteColorCss(previewModalSettings?.gallery?.backgroundColor as string),
                '& img': {
                    width: '100%',
                    height: 'auto',
                    borderRadius: previewModalSettings.gallery.borderRadius + 'px',
                },
            },
            '& .blank-wrapper': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                '& img': {
                    width: '100%',
                    height: 'auto',
                },
            },
        },
    };

    const previewContentWrapper: JssStyle = {
        width: '100%',
        ...getInfoSettings(previewModalSettings.info),
        ...getHorizontalPreviewLayout(cardModalViewType),

        '& .card-title': {
            '& h2': {
                ...commonTypographySettings(previewModalSettings.typography.titleSettings),
                marginBottom: '10px',
            },
            marginBottom: '15px',
            '& .card-article': {
                ...commonTypographySettings(previewModalSettings.typography.descriptionSettings),
                opacity: '0.7',
                fontFamily: websiteFontCss(EThemeFontTags.p, EWebsiteThemeFontProperties.fontFamily),
            },
        },

        '& .card-description': {
            marginBottom: '30px',
            '& p': {
                ...commonTypographySettings(previewModalSettings.typography.descriptionSettings),
            },
        },

        '& .card-old-price': {
            ...commonTypographySettings(previewModalSettings.typography.oldPriceSettings),
            textDecoration: 'line-through',
            fontFamily: websiteFontCss(EThemeFontTags.p, EWebsiteThemeFontProperties.fontFamily),
        },

        '& .card-price-block': {
            marginBottom: '15px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
            '& .card-new-price': {
                ...commonTypographySettings(previewModalSettings.typography.priceSettings),
                marginRight: '10px',
                lineHeight: previewModalSettings.typography.priceSettings.fontSize + 'px',
            },

            '& .card-old-price': {
                ...commonTypographySettings(previewModalSettings.typography.oldPriceSettings),
                textDecoration: 'line-through',
                lineHeight: cardSettings.typography.priceSettings.fontSize + 'px',
            },
        },

        '& .buttonWrapper': {
            display: 'flex',
            margin: '-10px',
            '& .card-button': {
                padding: commonWebsitesFormButtonTextStyles[showMoreButton.size || ESize.M].padding,
                fontSize: commonWebsitesFormButtonTextStyles[showMoreButton.size || ESize.M].fontSize,
                lineHeight: commonWebsitesFormButtonTextStyles[showMoreButton.size || ESize.M].lineHeight,
            },
        },
    };

    const gridContainerStyles = {
        display: 'grid',
        gap: margin,
        transition: 'gap 0.25s ease-out',
        gridTemplateColumns: `repeat(2, 1fr)`,
        gridAutoRows: '1fr',
        '@media (max-width: 992px)': {
            gap: margin / 2,
        },
    };

    const masonryContainerStyles = {
        display: 'flex',
        columnGap: margin,
        transition: 'gap 0.25s ease-out',
        rowGap: 'unset',
        margin: '-5px',
        '& > div': {
            display: 'flex',
            flexDirection: 'column',
            flexBasis: '50%',
            rowGap: margin,
            transition: 'gap 0.25s ease-out',
        },
        '& .gridItem': {
            margin: '5px',
        },
    };

    const openViewWrapper: JssStyle = {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        ...getGeneralSettings(cardSettings.general),
        '@media (max-width: 768px)': {
            flexDirection: 'column',
        },

        '& .imageContainer': {
            width: cardSettings?.general?.galleryWidth,
            ...getGallerySettings(cardSettings.gallery),
            '@media (max-width: 768px)': {
                width: '100%',
                minHeight: 'auto !important',
            },

            '& .gridContainer': isMasonry ? masonryContainerStyles : gridContainerStyles,

            '& .gridItem': {
                extend: {},
                position: 'relative',
            },
            '& .blank-wrapper': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                '& img': {
                    width: '100%',
                    height: 'auto',
                },
            },
            '& .default-image': {
                width: '100%',
                height: 'auto',
            },
            '& .gridImage': element?.images?.length
                ? {
                      width: '100%',
                      height: '100%',
                      position: 'absolute',
                      inset: 0,
                      objectFit: cardSettings?.gallery?.attachment === Attachment.cover ? 'cover' : 'contain',
                      margin: cardSettings?.gallery?.attachment === Attachment.cover ? '0' : 'auto',
                      userSelect: 'none',
                  }
                : { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' },
            '& .imageWrapper.resizeble': {
                position: 'relative',
                overflow: 'hidden',
                height: '100%',
                borderRadius,
                paddingTop: '100%',
                cursor: fullByClick ? 'pointer' : 'default',
            },
            '& .thumbWrapper': {
                position: 'relative',
                overflow: 'hidden',
                height: '100%',
                borderRadius,
                backgroundColor: websiteColorCss(cardSettings?.gallery?.backgroundColor as string),
            },
            '& .thumbImage': {
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                userSelect: 'none',
            },
            '& .vertical-image-wrapper': {
                // maxHeight: '100vh',
                // height: '100%',
                // overflowX: 'hidden',
                // overflowY: 'auto',
                width: '100%',
                '& .default-image': {
                    marginBottom: '10px',
                },
                '& div:last-child': {
                    '& .default-image': {
                        marginBottom: 0,
                    },
                },
                '@media (max-width: 768px)': {
                    maxHeight: '100%',
                    minHeight: 'auto !important',
                    position: 'relative !important',
                },
            },
            '& .thumbs-swiper-container': {
                '& .thumbsSwiper': {
                    '& .swiper-slide': {
                        filter: 'brightness(0.8)',
                        height: '80px !important',
                        width: '80px !important',
                        margin: '10px 10px 0 0',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                    '& .swiper-slide-thumb-active': {
                        filter: 'brightness(1)',
                        border: '3px solid',
                        borderColor: websiteColorCss(cardSettings?.gallery?.paginationColor as string),
                    },
                },
            },
            '& .image-wrapper': {
                backgroundColor: websiteColorCss(cardSettings?.gallery?.backgroundColor as string),
            },
            '& .swiper-button-prev, .swiper-button-next': {
                top: 'calc(50% - 20px)',
                width: '40px',
                height: '40px',
                padding: '10px',
                borderRadius: '50%',
                backgroundColor: websiteColorCss(cardSettings?.gallery?.buttonBackground as string),
                boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.3)',
                '&:after': {
                    fontSize: '22px',
                },
                '&:hover': {
                    opacity: 0.8,
                },

                '@media (max-width: 992px)': {
                    display: 'none',
                },
            },
            '& .swiper-button-prev': {
                '&:after': {
                    marginRight: '3px',
                },
            },
            '& .swiper-button-next': {
                '&:after': {
                    marginLeft: '3px',
                },
            },
        },
        '& .contentContainer': {
            width: cardSettings?.general?.contentWidth,
            '& .contentWrapper': {
                ...getInfoSettings(cardSettings.info),
                '@media (max-width: 768px)': {
                    width: '100%',
                    padding: '30px 0 0 0',
                    position: 'relative !important',
                    top: 'auto !important',
                },

                '& .card-title': {
                    '& h2': {
                        ...commonTypographySettings(cardSettings.typography.titleSettings),
                        marginBottom: '10px',
                    },
                    marginBottom: '30px',
                    '& .card-article': {
                        ...commonTypographySettings(cardSettings.typography.descriptionSettings),
                        opacity: '0.7',
                        fontFamily: websiteFontCss(EThemeFontTags.p, EWebsiteThemeFontProperties.fontFamily),
                    },
                },

                '& .card-price-block': {
                    marginBottom: '30px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'baseline',
                    flexWrap: 'wrap',
                    '& .card-new-price': {
                        ...commonTypographySettings(cardSettings.typography.priceSettings),
                        marginRight: '10px',
                        lineHeight: cardSettings.typography.priceSettings.fontSize + 'px',
                    },

                    '& .card-old-price': {
                        ...commonTypographySettings(cardSettings.typography.oldPriceSettings),
                        textDecoration: 'line-through',
                        lineHeight: cardSettings.typography.priceSettings.fontSize + 'px',
                    },
                },

                '& .card-description': {
                    marginBottom: '30px',
                    '& p, li': {
                        ...commonTypographySettings(cardSettings.typography.descriptionSettings),
                    },
                },

                '& .buttonWrapper': {
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '30px',
                    margin: '-10px',
                    flexWrap: 'wrap',
                    '& .card-button': {
                        padding: commonWebsitesFormButtonTextStyles[basketButton.size || ESize.M].padding,
                        fontSize: commonWebsitesFormButtonTextStyles[basketButton.size || ESize.M].fontSize,
                        lineHeight: commonWebsitesFormButtonTextStyles[basketButton.size || ESize.M].lineHeight,
                    },
                    '& .card-product-counter': {
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                        borderWidth: '1px',
                        boxSizing: 'border-box',
                        borderStyle: 'solid',
                        whiteSpace: 'nowrap',
                        borderTopWidth: `${cardSettings.productVariants.inputBorderType === InputBorderEnum.BOTTOM_BORDER ? '0px' : '1px'}`,
                        borderRightWidth: `${
                            cardSettings.productVariants.inputBorderType === InputBorderEnum.BOTTOM_BORDER ? '0px' : '1px'
                        }`,
                        borderLeftWidth: `${
                            cardSettings.productVariants.inputBorderType === InputBorderEnum.BOTTOM_BORDER ? '0px' : '1px'
                        }`,
                        borderRadius: `${cardSettings.productVariants.borderRadius}px`,
                        background: websiteColorCss(cardSettings.productVariants.backgroundColor as string),
                        borderColor: websiteColorCss(cardSettings.productVariants.borderColor as string),
                        color: websiteColorCss(cardSettings.productVariants.inputTextColor as string),
                        margin: '10px',
                        ...counterSizeStyles[cardSettings.productVariants.inputSize || ESize.M],
                        padding: '0 !important',
                        '& .counter-control': {
                            cursor: 'pointer',
                            padding: counterSizeStyles[cardSettings.productVariants.inputSize || ESize.M].padding,
                            background: 'transparent',
                            border: 'none',
                            color: websiteColorCss(cardSettings.productVariants.inputTextColor as string),
                            '&:hover': {
                                opacity: 0.8,
                            },
                        },
                        '& .quantity-control': {
                            background: 'transparent',
                            color: websiteColorCss(cardSettings.productVariants.inputTextColor as string),
                            fontSize: counterSizeStyles[cardSettings.productVariants.inputSize || ESize.M].fontSize,
                            lineHeight: counterSizeStyles[cardSettings.productVariants.inputSize || ESize.M].lineHeight,
                        },
                        '& .counter': {
                            color: websiteColorCss(cardSettings.productVariants.inputTextColor as string),
                            background: websiteColorCss(cardSettings.productVariants.backgroundColor as string),
                            padding: counterSizeStyles[cardSettings.productVariants.inputSize || ESize.M].padding,
                        },
                        '& .disabled': {
                            cursor: 'not-allowed',
                            pointerEvents: 'none',
                            opacity: 0.5,
                        },
                    },
                    '& .disabled': {
                        cursor: 'not-allowed',
                        pointerEvents: 'none',
                        opacity: 0.5,
                    },
                    '@media(max-width: 992px)': {
                        flexWrap: 'wrap-reverse',
                        '& .card-button': {
                            width: '100%',
                        },
                        '& .card-product-counter': {
                            width: '50%',
                            '& .counter-control': {
                                // padding: '10px',
                                // margin: '-10px',
                                '& svg': {
                                    width: '12px',
                                    height: '12px',
                                },
                            },
                        },
                    },
                },

                '& .card-parameters': {
                    marginBottom: '20px',
                    '& label': {
                        display: 'block',
                        fontWeight: 'bold',
                        fontSize: cardSettings.typography.descriptionSettings.fontSize + 2 + 'px',
                        color: websiteColorCss(cardSettings.typography.descriptionSettings.color as string),
                        fontFamily: websiteFontCss(EThemeFontTags.p, EWebsiteThemeFontProperties.fontFamily),
                        marginBottom: '10px',
                    },
                    '& p': {
                        ...commonTypographySettings(cardSettings.typography.descriptionSettings),
                        marginBottom: '5px',
                        '& .param-prop': {
                            marginRight: '5px',
                        },
                        '& .param-value': {
                            opacity: 0.7,
                        },
                    },
                    '&:last-child': {
                        marginBottom: 0,
                    },
                },

                '& .variants-block': {
                    flexWrap: 'wrap',
                    marginBottom: '20px',

                    '& label': {
                        display: cardSettings.productVariants.showLabels ? 'block' : 'none',
                        fontWeight: 'bold',
                        fontSize: cardSettings.typography.descriptionSettings.fontSize + 'px',
                        color: websiteColorCss(cardSettings.productVariants.textColor as string),
                        fontFamily: websiteFontCss(EThemeFontTags.p, EWebsiteThemeFontProperties.fontFamily),
                        marginBottom: `${
                            cardSettings.productVariants.inputSize === ESize.S
                                ? '6px'
                                : cardSettings.productVariants.inputSize === ESize.M
                                ? '8px'
                                : '10px'
                        }`,
                    },

                    '& select': {
                        ...variantSizeStyles[cardSettings.productVariants.inputSize || ESize.M],
                        marginBottom: `${
                            cardSettings.productVariants.inputSize === ESize.S
                                ? '10px'
                                : cardSettings.productVariants.inputSize === ESize.M
                                ? '12px'
                                : '15px'
                        }`,
                        '&:hover': {
                            borderColor: '#9a9a9a',
                        },
                        '&:focus': {
                            borderColor: '#82f6ff',
                            boxShadow: '0 0 0 2px rgb(151 247 255 / 30%);',
                            outline: '0',
                        },
                        marginRight: '15px',
                        borderWidth: '1px',
                        boxSizing: 'border-box',
                        borderStyle: 'solid',
                        whiteSpace: 'nowrap',
                        borderTopWidth: `${cardSettings.productVariants.inputBorderType === InputBorderEnum.BOTTOM_BORDER ? '0px' : '1px'}`,
                        borderRightWidth: `${
                            cardSettings.productVariants.inputBorderType === InputBorderEnum.BOTTOM_BORDER ? '0px' : '1px'
                        }`,
                        borderLeftWidth: `${
                            cardSettings.productVariants.inputBorderType === InputBorderEnum.BOTTOM_BORDER ? '0px' : '1px'
                        }`,
                        borderRadius: `${cardSettings.productVariants.borderRadius}px`,
                        background: websiteColorCss(cardSettings.productVariants.backgroundColor as string),
                        borderColor: websiteColorCss(cardSettings.productVariants.borderColor as string),
                        color: websiteColorCss(cardSettings.productVariants.inputTextColor as string),
                        '& option': {
                            background: websiteColorCss(cardSettings.productVariants.backgroundColor as string),
                            '&[disabled]': {
                                opacity: 0.5,
                                background: '#e7e7e7',
                            },
                        },
                    },
                },
            },
            '@media (max-width: 768px)': {
                minHeight: 'auto !important',
                width: '100%',
            },
        },
    };

    const modalContainerStyles: JssStyle = {
        width: '100%',
        maxWidth: '900px',
        margin: '10px auto',

        '@media (max-width: 768px)': {
            margin: 0,
        },
        '& .modal-content': {
            maxWidth: '900px',
        },
    };

    const modalWrapper: JssStyle = {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        position: 'relative',
        ...getGeneralSettings(modalSettings.general),
        '@media (max-width: 600px)': {
            flexDirection: 'column',
        },

        '& .close-modal-btn': {
            position: 'absolute',
            top: '10px',
            right: '10px',
            width: '24px',
            height: '24px',
            fontSize: '24px',
            lineHeight: '24px',
            borderRadius: '50%',
            padding: 0,
            border: 0,
            backgroundColor: '#f3f3f3',
            color: '#555',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#ebebeb',
            },
        },

        '& .imageContainer': {
            width: 'calc(40% - 30px)',
            marginRight: '30px',
            ...getGallerySettings(cardSettings.gallery),
            '@media (max-width: 600px)': {
                width: '100%',
                marginRight: 0,
                marginBottom: '30px',
            },

            '& .blank-wrapper': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                '& img': {
                    width: '100%',
                    height: 'auto',
                },
            },

            '& .default-image': {
                width: '100%',
                height: 'auto',
            },

            '& .gridImage': element?.images?.length
                ? {
                      width: '100%',
                      height: '100%',
                      position: 'absolute',
                      inset: 0,
                      objectFit: cardSettings?.gallery?.attachment === Attachment.cover ? 'cover' : 'contain',
                      margin: cardSettings?.gallery?.attachment === Attachment.cover ? '0' : 'auto',
                      userSelect: 'none',
                  }
                : { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' },

            '& .image-wrapper': {
                backgroundColor: websiteColorCss(cardSettings?.gallery?.backgroundColor as string),
                paddingTop: '120%',
            },
            '& .imageWrapper.resizeble': {
                position: 'relative',
                overflow: 'hidden',
                height: '100%',
                borderRadius,
                paddingTop: '100%',
                cursor: fullByClick ? 'pointer' : 'default',
            },
            '& .swiper-button-prev, .swiper-button-next': {
                top: 'calc(50% - 20px)',
                width: '40px',
                height: '40px',
                padding: '10px',
                borderRadius: '50%',
                backgroundColor: websiteColorCss(cardSettings?.gallery?.buttonBackground as string),
                boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.3)',
                '&:after': {
                    fontSize: '22px',
                },
                '&:hover': {
                    opacity: 0.8,
                },

                '@media (max-width: 992px)': {
                    display: 'none',
                },
            },
            '& .swiper-button-prev': {
                '&:after': {
                    marginRight: '3px',
                },
            },
            '& .swiper-button-next': {
                '&:after': {
                    marginLeft: '3px',
                },
            },

            '& img': {
                width: '100%',
                height: 'auto',
                borderRadius,
            },
        },

        '& .contentWrapper': {
            width: '60%',
            '@media (max-width: 600px)': {
                width: '100%',
            },

            '& .card-title': {
                '& h2': {
                    ...commonTypographySettings(modalSettings.typography.titleSettings),
                    marginBottom: '10px',
                },
                marginBottom: '30px',
                '& .card-article': {
                    ...commonTypographySettings(modalSettings.typography.descriptionSettings),
                    opacity: '0.7',
                    fontFamily: websiteFontCss(EThemeFontTags.p, EWebsiteThemeFontProperties.fontFamily),
                },
            },

            '& .card-price-block': {
                marginBottom: '30px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'baseline',
                '& .card-new-price': {
                    ...commonTypographySettings(modalSettings.typography.priceSettings),
                    marginRight: '10px',
                    lineHeight: modalSettings.typography.priceSettings.fontSize + 'px',
                },

                '& .card-old-price': {
                    ...commonTypographySettings(modalSettings.typography.oldPriceSettings),
                    textDecoration: 'line-through',
                    lineHeight: modalSettings.typography.priceSettings.fontSize + 'px',
                },
            },

            '& .card-description': {
                marginBottom: '30px',
                '& p, li': {
                    ...commonTypographySettings(modalSettings.typography.descriptionSettings),
                },
            },

            '& .buttonWrapper': {
                display: 'flex',
                alignItems: 'center',
                marginBottom: '30px',
                margin: '-10px',
                '& .card-button': {
                    padding: commonWebsitesFormButtonTextStyles[basketButton.size || ESize.M].padding,
                    fontSize: commonWebsitesFormButtonTextStyles[basketButton.size || ESize.M].fontSize,
                    lineHeight: commonWebsitesFormButtonTextStyles[basketButton.size || ESize.M].lineHeight,
                },
                '& .card-product-counter': {
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    borderWidth: '1px',
                    boxSizing: 'border-box',
                    borderStyle: 'solid',
                    whiteSpace: 'nowrap',
                    borderTopWidth: `${modalSettings.productVariants.inputBorderType === InputBorderEnum.BOTTOM_BORDER ? '0px' : '1px'}`,
                    borderRightWidth: `${modalSettings.productVariants.inputBorderType === InputBorderEnum.BOTTOM_BORDER ? '0px' : '1px'}`,
                    borderLeftWidth: `${modalSettings.productVariants.inputBorderType === InputBorderEnum.BOTTOM_BORDER ? '0px' : '1px'}`,
                    borderRadius: `${modalSettings.productVariants.borderRadius}px`,
                    background: websiteColorCss(modalSettings.productVariants.backgroundColor as string),
                    borderColor: websiteColorCss(modalSettings.productVariants.borderColor as string),
                    color: websiteColorCss(modalSettings.productVariants.inputTextColor as string),
                    margin: '10px',
                    ...counterSizeStyles[modalSettings.productVariants.inputSize || ESize.M],
                    padding: '0 !important',
                    '& .counter-control': {
                        cursor: 'pointer',
                        padding: counterSizeStyles[modalSettings.productVariants.inputSize || ESize.M].padding,
                        background: 'transparent',
                        border: 'none',
                        color: websiteColorCss(modalSettings.productVariants.inputTextColor as string),
                        '&:hover': {
                            opacity: 0.8,
                        },
                    },
                    '& .quantity-control': {
                        background: 'transparent',
                        color: websiteColorCss(modalSettings.productVariants.inputTextColor as string),
                        fontSize: counterSizeStyles[modalSettings.productVariants.inputSize || ESize.M].fontSize,
                        lineHeight: counterSizeStyles[modalSettings.productVariants.inputSize || ESize.M].lineHeight,
                    },
                    '& .counter': {
                        color: websiteColorCss(modalSettings.productVariants.inputTextColor as string),
                        background: websiteColorCss(modalSettings.productVariants.backgroundColor as string),
                        padding: counterSizeStyles[modalSettings.productVariants.inputSize || ESize.M].padding,
                    },
                    '& .disabled': {
                        cursor: 'not-allowed',
                        pointerEvents: 'none',
                        opacity: 0.5,
                    },
                },
                '& .disabled': {
                    cursor: 'not-allowed',
                    pointerEvents: 'none',
                    opacity: 0.5,
                },
                '@media(max-width: 992px)': {
                    flexWrap: 'wrap-reverse',
                    '& .card-button': {
                        width: '100%',
                    },
                    '& .card-product-counter': {
                        width: '50%',
                        '& .counter-control': {
                            '& svg': {
                                width: '12px',
                                height: '12px',
                            },
                        },
                    },
                },
            },

            '& .card-parameters': {
                marginBottom: '20px',
                '& label': {
                    display: 'block',
                    fontWeight: 'bold',
                    fontSize: modalSettings.typography.descriptionSettings.fontSize + 2 + 'px',
                    color: websiteColorCss(modalSettings.typography.descriptionSettings.color as string),
                    fontFamily: websiteFontCss(EThemeFontTags.p, EWebsiteThemeFontProperties.fontFamily),
                    marginBottom: '10px',
                },
                '& p': {
                    ...commonTypographySettings(modalSettings.typography.descriptionSettings),
                    marginBottom: '5px',
                    '& .param-prop': {
                        marginRight: '5px',
                    },
                    '& .param-value': {
                        opacity: 0.7,
                    },
                },
                '&:last-child': {
                    marginBottom: 0,
                },
            },

            '& .variants-block': {
                marginBottom: '20px',

                '& label': {
                    display: modalSettings.productVariants.showLabels ? 'block' : 'none',
                    fontWeight: 'bold',
                    fontSize: modalSettings.typography.descriptionSettings.fontSize + 2 + 'px',
                    color: websiteColorCss(modalSettings.productVariants.textColor as string),
                    fontFamily: websiteFontCss(EThemeFontTags.p, EWebsiteThemeFontProperties.fontFamily),
                    marginBottom: '10px',
                },

                '& select': {
                    ...variantSizeStyles[modalSettings.productVariants.inputSize || ESize.M],
                    '&:hover': {
                        borderColor: '#9a9a9a',
                    },
                    '&:focus': {
                        borderColor: '#82f6ff',
                        boxShadow: '0 0 0 2px rgb(151 247 255 / 30%);',
                        outline: '0',
                    },
                    marginRight: '30px',
                    borderWidth: '1px',
                    boxSizing: 'border-box',
                    borderStyle: 'solid',
                    whiteSpace: 'nowrap',
                    borderTopWidth: `${modalSettings.productVariants.inputBorderType === InputBorderEnum.BOTTOM_BORDER ? '0px' : '1px'}`,
                    borderRightWidth: `${modalSettings.productVariants.inputBorderType === InputBorderEnum.BOTTOM_BORDER ? '0px' : '1px'}`,
                    borderLeftWidth: `${modalSettings.productVariants.inputBorderType === InputBorderEnum.BOTTOM_BORDER ? '0px' : '1px'}`,
                    borderRadius: `${modalSettings.productVariants.borderRadius}px`,
                    background: websiteColorCss(modalSettings.productVariants.backgroundColor as string),
                    borderColor: websiteColorCss(modalSettings.productVariants.borderColor as string),
                    color: websiteColorCss(modalSettings.productVariants.inputTextColor as string),
                    '& option': {
                        background: websiteColorCss(modalSettings.productVariants.backgroundColor as string),
                        '&[disabled]': {
                            opacity: 0.5,
                            background: '#e7e7e7',
                        },
                    },
                },
            },
        },
    };

    const fullDescription: JssStyle = {
        marginTop: '30px',
        '&.sp-ui-text': {
            '& p, li': {
                ...commonTypographySettings(cardSettings.typography.descriptionSettings),
            },
        },
    };

    let buttonStyles: JssStyle = {
        ...getCustomButtonStyle(basketButton),
    };

    let showMoreButtonStyle: JssStyle = {
        ...getCustomButtonStyle(showMoreButton),
    };

    return {
        blankImageWrapper,
        openViewWrapper,
        previewMainWrapper,
        previewVerticalViewWrapper,
        previewContentWrapper,
        previewHorizontalViewWrapper,
        modalContainerStyles,
        modalWrapper,
        fullDescription,
        buttonStyles,
        showMoreButtonStyle,
    };
}
