import type { JssStyle } from 'jss';
import type { ThemedBaseButtonProp, ThemedBaseButton } from './base-button.type';

import { websiteColorCss } from '@libs/themes/src/variants/website/utils/variables';
import { ThemeButtonHover } from '@common/libs/themes';
import { EButtonHoverType } from '@common/enums';

export function getBaseButtonStyles(buttonBase: ThemedBaseButtonProp = {}): JssStyle {
    // TODO: remove all nullish coalescing operators
    return {
        color: websiteColorCss(buttonBase?.color?.[0]),
        background: websiteColorCss(buttonBase?.background?.[0]),
        borderColor: websiteColorCss(buttonBase?.borderColor?.[0]),
        borderWidth: buttonBase?.borderWidth + 'px',
        borderRadius: buttonBase?.borderRadius + 'px',
        borderStyle: buttonBase?.borderStyle,
        '&:hover': {
            color: websiteColorCss(buttonBase?.color?.[0]),
        },
    };
}

export function getBaseButtonData( buttonBase: ThemedBaseButtonProp, buttonHover: ThemeButtonHover): JssStyle {
    let hoverStyles: JssStyle;

    switch (buttonHover?.hoverType) {
        case EButtonHoverType.opacity:
            hoverStyles = {
                opacity: buttonHover.opacity * 0.01,
                color: websiteColorCss(buttonBase?.color?.[0]),
            }
            break;
        case EButtonHoverType.shadow:
            hoverStyles = {
                boxShadow: buttonHover.shadow,
                color: websiteColorCss(buttonBase?.color?.[0]),
            }
            break;
        case EButtonHoverType.color:
            hoverStyles = {
                color: websiteColorCss(buttonHover?.color?.[0]),
                background: websiteColorCss(buttonHover?.background?.[0]),
                borderColor: websiteColorCss(buttonHover?.borderColor?.[0]),
            }
        default:
            break;
    }

    return {
        color: websiteColorCss(buttonBase?.color?.[0]),
        background: websiteColorCss(buttonBase?.background?.[0]),
        borderColor: websiteColorCss(buttonBase?.borderColor?.[0]),
        borderWidth: buttonBase?.borderWidth + 'px',
        borderRadius: buttonBase?.borderRadius + 'px',
        borderStyle: buttonBase?.borderStyle,
        '&:hover': {
            ...hoverStyles,
        },
    };
}

export function getBaseButtonIconStyles(fontSize: number = 16): JssStyle {
    return {
        '& .icon': {
            width: 'var(--button-font-size)',
            height: 'var(--button-font-size)',
            '& svg': {
                width: 'var(--button-font-size)',
                height: 'var(--button-font-size)',
            },
        },
    };
}
