import type { OnInit, AfterViewInit } from '@angular/core';
import type { Classes } from 'jss';

import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, inject } from '@angular/core';

import { ServerStylesService } from '@common/services/server-styles.service';
import { PlatformService } from '@common/services/platform.service';
import { websiteColorCss } from '@common/libs/themes/src/variants/website/utils/variables';
import { ESize } from '@common/enums';

import { SpecificBlockStructureEntity } from '@web/types';
import { getStyles } from '@web/layout/content/page-content/components/blocks/specific-block-wrapper/specific-block-wrapper.component.styles';
import { Actions, ofType } from '@ngrx/effects';
import { ToggleMobileMenuActions } from '@web-builder/store/global/toggle-mobile-menu.action';
import { environment } from '@web-builder-env/environment';

@Component({
    selector: 'web-builder-specific-block-wrapper',
    templateUrl: './specific-block-wrapper.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class SpecificBlockWrapperComponent implements OnInit, AfterViewInit {
    @Input() public block: SpecificBlockStructureEntity;
    @Input() public index: number;

    private readonly actions$: Actions = inject(Actions);

    public classes: Classes;

    public readonly size = ESize;

    public isShowMobileMenu: boolean = false;

    public pinedTopBlockHeight: number = 0;
    public pinedBottomBlockHeight: number = 0;
    public backgroundColor: string = 'transparent';
    public isBrowser: boolean = this.platformService.isPlatformBrowser();

    constructor(
        private readonly stylesService: ServerStylesService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly platformService: PlatformService,
    ) {}

    public ngOnInit(): void {
        const styles = getStyles(this.block, false, environment.fileManagerCdnUrl);

        this.classes = this.stylesService.getStyles(this.block.id, styles);

        if (this.isBrowser) {
            this.actions$.pipe(ofType(ToggleMobileMenuActions.toggle)).subscribe(() => {
                this.toggleMobileMenu();
            });
        }

        this.changeDetectorRef.detectChanges();
    }

    public ngAfterViewInit(): void {
        if (this.platformService.isPlatformServer()) {
            return;
        }

        if (this.block.data.pinOnTheTop) {
            this.pinedTopBlockHeight = (document.querySelector(`[id='block-${this.block.id}']`) as HTMLDivElement)?.offsetHeight;
            this.backgroundColor = websiteColorCss(this.block.styles.backgroundColor as string);

            const htmlNode = document.querySelector('html') as HTMLHtmlElement;
            htmlNode.style.scrollPaddingTop = (this.pinedTopBlockHeight?.toString() ?? '0') + 'px';

            this.changeDetectorRef.detectChanges();
        }

        if (this.block.data.pinOnTheBottom) {
            this.pinedBottomBlockHeight = (document.querySelector(`[id='block-${this.block.id}']`) as HTMLDivElement)?.offsetHeight;
            this.backgroundColor = websiteColorCss(this.block.styles.backgroundColor as string);

            this.changeDetectorRef.detectChanges();
        }
    }

    public getMobileWidgetsListHeight() {
        if (this.block.data.pinOnTheTop) {
            let topNavHeight =
                (document.querySelector(`[id='block-${this.block.id}'] .specific-top-nav`) as HTMLDivElement)?.offsetHeight +
                +this.block.mobileStyles.paddingTop * 2;
            let mobileWidgetListNode = document.querySelector(
                `[id='block-${this.block.id}'] .specific-mobile-widgets-list`,
            ) as HTMLDivElement;

            mobileWidgetListNode.style.overflow = 'hidden overlay';
            mobileWidgetListNode.style.height = 'calc(100vh - ' + topNavHeight + 'px)';
        }
    }

    public toggleMobileMenu(): void {
        this.getMobileWidgetsListHeight();

        this.isShowMobileMenu = !this.isShowMobileMenu;
    }

    public getBlockHeight(): string {
        if (this.block.data.pinOnTheTop && this.isShowMobileMenu) {
            return '1000px';
        } else if (!this.block.data.pinOnTheTop && this.isShowMobileMenu) {
            return 'auto';
        } else {
            return '0';
        }
    }

    public get blockId(): string {
        return `block-${this.block.id}`;
    }

    public get iconSize(): ESize {
        return this.block.data.iconSize;
    }

    public get hideContentOnMobile(): boolean {
        return this.block.data.hideContentOnMobile;
    }
}
