<div [class]="classes?.imgWidgetWrapper">
    <a
        class="sp-image-root"
        [attr.href]="link"
        [class]="classes?.imgWrapper"
    >
        <picture *ngIf="image?.hasResize; else displayDefault">
            <source
                *ngFor="let source of srcsetSources"
                [srcset]="source.url"
                [media]="source.media"
            />
            <img
                [class]="classes?.image"
                [src]="imgSrc"
                [alt]="placeholder"
                [style.objectPosition]="image && (image.backgroundPositionX || 50) + '% ' + (image.backgroundPositionY || 50) + '%'"
            />
        </picture>
        <ng-template #displayDefault>
            <img
                [class]="classes?.image"
                [src]="imgSrc"
                [alt]="placeholder"
                [style.objectPosition]="image && (image.backgroundPositionX || 50) + '% ' + (image.backgroundPositionY || 50) + '%'"
            />
        </ng-template>
    </a>
</div>
