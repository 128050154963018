import { type TranslateLoader, type TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { type HttpClient } from '@angular/common/http';

import { ELanguages } from '@common/enums';
import { type ServerTranslationsService } from '@common/services/server-translations.service';

import * as en from '@web-builder-assets/translations/en.json';
import * as es from '@web-builder-assets/translations/es-mx.json';
import * as fr from '@web-builder-assets/translations/fr.json';
import * as it from '@web-builder-assets/translations/it.json';
import * as pt from '@web-builder-assets/translations/pt-br.json';
import * as ru from '@web-builder-assets/translations/ru.json';
import * as tr from '@web-builder-assets/translations/tr.json';
import * as ua from '@web-builder-assets/translations/ua.json';
import * as az from '@web-builder-assets/translations/az.json';
import * as cs from '@web-builder-assets/translations/cs.json';
import * as de from '@web-builder-assets/translations/de.json';
import * as hy from '@web-builder-assets/translations/hy.json';
import * as ka from '@web-builder-assets/translations/ka.json';
import * as nl from '@web-builder-assets/translations/nl.json';
import * as pl from '@web-builder-assets/translations/pl.json';
import * as sh from '@web-builder-assets/translations/sh.json';
import * as fil from '@web-builder-assets/translations/fil.json';
import * as id from '@web-builder-assets/translations/id.json';

export class TranslationServerLoader implements TranslateLoader {
    private readonly translationsMap: Record<ELanguages, Record<string, string>> = {
        [ELanguages.EN]: en,
        [ELanguages.ES_MX]: es,
        [ELanguages.FR]: fr,
        [ELanguages.IT]: it,
        [ELanguages.PT_BR]: pt,
        [ELanguages.RU]: ru,
        [ELanguages.TR]: tr,
        [ELanguages.UA]: ua,
        [ELanguages.FIL]: fil,
        [ELanguages.ID]: id,
        // edu only
        [ELanguages.AZ]: az,
        [ELanguages.CS]: cs,
        [ELanguages.DE]: de,
        [ELanguages.HY]: hy,
        [ELanguages.KA]: ka,
        [ELanguages.NL]: nl,
        [ELanguages.PL]: pl,
        [ELanguages.SH]: sh,
    };

    constructor(private readonly http: HttpClient, private readonly translationsService: ServerTranslationsService) {}

    public static getCurrentLocale(translationsService: ServerTranslationsService): ELanguages {
        return translationsService.getLanguageAsValue() || ELanguages.EN;
    }

    public getTranslation(): Observable<any> {
        return new Observable((observer) => {
            const translations: Record<string, string> =
                this.translationsMap[TranslationServerLoader.getCurrentLocale(this.translationsService)];
            this.translationsService.setTranslations(translations);

            observer.next(translations);
            observer.complete();
        });
    }
}

export function translateServerLoaderFactory(http: HttpClient, translationsService: ServerTranslationsService) {
    return new TranslationServerLoader(http, translationsService);
}

export function initializeServerTranslations(
    translate: TranslateService,
    translationsService: ServerTranslationsService,
): () => Promise<unknown> {
    return async () => {
        translate.use(TranslationServerLoader.getCurrentLocale(translationsService));
        return new Promise<void>((resolve) => resolve());
    };
}
