<div id="shopCart" class="cart-icon" *ngIf="shopEnabled" (click)="cartIconClick()">
    <div class="cart-icon-wrapper" [ngStyle]="getIconStyles()">
        <span class="icon-counter" [ngStyle]="getCounterStyles()" *ngIf="(cartNumberOfItems$ | async) > 0">{{ cartNumberOfItems$ | async }}</span>
        <div [innerHTML]="getIcon()" class="icon"></div>
        <div class="hint-container">
            <span *ngIf="(cartNumberOfItems$ | async) > 0; else empty">{{ 'go_to_cart_hint' | translate }}</span>
            <ng-template #empty>{{ 'empty_cart_hint' | translate }}</ng-template>
        </div>
    </div>
</div>
