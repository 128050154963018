<div>
    <span class="sp-icon icon-arrow-left back-btn small" (click)="toggleShowCustom($event)"> </span>
    <label class="mb-10 small">{{ 'control_custom_color' | translate }}</label>
    
    <div class="mb-10" *ngIf="!hideGradient">
        <select class="form-control input-sm" [ngModel]="colorPickerType" (ngModelChange)="setColorPickerType($event)">
            <option [value]="EColorPickerTypes.solid">
                {{ 'control_color_solid' | translate }}
            </option>
            <option [value]="EColorPickerTypes.gradient">
                {{ 'control_color_gradient' | translate }}
            </option>
        </select>
    </div>

    <div *ngIf="gradientObject"
         class="cp-preset-colors-class preset-gradient-bg"
         [style.background]="colorValue">
        <div *ngFor="let value of gradientObject.values; index as i" class="presets-wrapper">
            <div class="preset-color"
                 [class.selected]="i === gradientValueIndex"
                 (click)="setGradientValueIndex(i)"
                 [style.background]="value.color"
            ></div>
        </div>
    </div>
    
    <span
        #ignoredInput
        [cpIgnoredElements]="[ignoredInput]"
        [cpToggle]="true"
        [(colorPicker)]="colorValue"
        cpOutputFormat="rgba"
        cpDialogDisplay="inline"
        cpWidth="160px"
    ></span>

    <small class="text-muted alpha-value">{{ 'control_color_opacity' | translate }}: {{ getAlphaFromRgba(colorValue) }}%</small>

    <div class="spc-color-picker-button">
        <input type="text"
               [class.error-inp]="formColor.invalid"
               [ngModel]="colorValue | rgbaToHex"
               (ngModelChange)="changeColorHandler($event)"
               maxlength="7"
               class="spc-color-picker-button-name form-control">
        <span class="spc-color-picker-button-area"
              [style.background]="colorValue">
            <sp-transparent-icon
                [color]="colorPickerType === EColorPickerTypes.solid ? colorValue : gradientColor"
            ></sp-transparent-icon>
        </span>
    </div>

</div>
