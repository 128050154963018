import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SpSafeHtmlPipe } from './safe-html.pipe';
import { SpByteFormat } from './byte-format.pipe';
import { HtmlToTextPipe } from './html-to-text.pipe';
import { SpTimeFormat } from './time-format.pipe';
import { RemovePTags } from './remove-p-tag.pipe';
import { SpSafeUrlPipe } from '@common/pipes/safe-url.pipe';
import { FmFileUrlPipe } from '@sendpulse/file-manager';
import { SpReverseArrayPipe } from '@common/pipes/reverse-array.pipe';

const PIPES = [SpSafeHtmlPipe, SpByteFormat, SpTimeFormat, RemovePTags, SpSafeUrlPipe, SpReverseArrayPipe];

const STANDALONE_PIPES = [HtmlToTextPipe, FmFileUrlPipe];
const MODULES = [CommonModule];

@NgModule({
    imports: [...MODULES, ...STANDALONE_PIPES],
    declarations: [...PIPES],
    exports: [...PIPES, ...STANDALONE_PIPES],
})
export class SpPipesModule {}
