import { ChangeDetectorRef, Component, inject, Input, type OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AspectRatio, type CardSettings, ProductCardWidgetData } from '@web/widgets/product-card/type/product-card-widget.type';
import { Classes } from 'jss';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { type FormArray, type FormControl, FormsModule, ReactiveFormsModule, UntypedFormBuilder } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BUTTON_SIZE_CLASS_MAP, BUTTON_TYPE_CLASS_MAP, DEFAULT_BUTTON_CLASS } from '@web/widgets/common/base/base-button/base-button.const';
import { EButtonStyleType } from '@common/enums';
import { SpPipesModule } from '@common/pipes/pipes.module';
import { websiteColorCss } from '@libs/themes';
import { SwiperModule } from 'swiper/angular';
import { type ProductCard, ProductType, type ProductUnits } from '@common/types/product-card.type';
import { type SwiperOptions } from 'swiper';
import { type SelectedFileExtended } from '@common/types/file.type';
import { setChatAt } from '@common/helpers/string';
import { CartFacade } from '@web-builder/store/cart/cart.facade';
import { LightgalleryModule } from 'lightgallery/angular';
import { PlatformService } from '@common/services/platform.service';
import { type ParametersFormGroup } from '@web-builder/widgets/product-card/open-view-card/open-view-card.component';
import { TransferStateService } from '@web-builder/core/services/transfer-state.service';
import { UtilsService } from '@web-builder/core/services/utils.service';
import { HideSwiperOnSsrDirective } from '@web-builder/core/directives/swiper-ssr.directive';

@Component({
    selector: 'web-builder-product-card-modal',
    templateUrl: './product-card-modal.component.html',
    styleUrls: ['./product-card-modal.component.less'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        SpPipesModule,
        SwiperModule,
        LightgalleryModule,
        ReactiveFormsModule,
        HideSwiperOnSsrDirective,
    ],
})
export class ProductCardModalComponent implements OnInit {
    @Input() data: ProductCardWidgetData;
    @Input() classes: Classes;
    @Input() displayId: string;
    @Input() widgetId: string;

    private readonly cartFacade: CartFacade = inject(CartFacade);

    public swiperConfig: SwiperOptions = {};
    public aspectRatio = AspectRatio;
    protected readonly websiteColorCss = websiteColorCss;

    public imagesSrcSets = [];
    public counterControl: FormControl;
    public propertiesArray: FormArray<ParametersFormGroup>;
    public isShowVariant: boolean = true;
    private defaultData: {
        price: number;
        oldPrice: number;
        vendorCode: string;
        quantity: number;
        images: SelectedFileExtended[];
        id: string;
    };
    public readonly currency: string = this.transferStateService.get('site')?.settings?.shop?.currency;

    constructor(
        private readonly bsModalRef: BsModalRef,
        private readonly fb: UntypedFormBuilder,
        protected readonly platformService: PlatformService,
        protected readonly changeDetectorRef: ChangeDetectorRef,
        protected readonly transferStateService: TransferStateService,
        protected readonly utilsService: UtilsService,
    ) {
        this.counterControl = this.fb.control(1);
        this.propertiesArray = this.fb.array([]);
    }

    ngOnInit() {
        this.setImagesSrc();
        this.setFormData();
        this.defaultData = {
            price: this.element.price,
            oldPrice: this.element.oldPrice,
            vendorCode: this.element.vendorCode,
            quantity: this.quantity,
            images: this.images,
            id: this.element.id,
        };
    }

    private setImagesSrc() {
        this.imagesSrcSets = this.images.map((image) => {
            if (!image?.hasResize) {
                return null;
            }

            const srcSets = [
                {
                    size: 720,
                    media: '(max-width: 720px)',
                },
                {
                    size: 1080,
                    media: '(max-width: 1080px)',
                },
                {
                    size: 1920,
                    media: '(min-width: 1081px)',
                },
            ];

            const splitImgSrc = this.formatImageURL(image).split('/');
            splitImgSrc.splice(4, 0, 'resize_landing');
            const extensionDotIndex = splitImgSrc[splitImgSrc.length - 1].lastIndexOf('.');
            splitImgSrc[splitImgSrc.length - 1] = setChatAt(splitImgSrc[splitImgSrc.length - 1], extensionDotIndex, '_');

            const res = splitImgSrc.join('/');

            return srcSets.map((srcset) => {
                if (srcset.size >= image.imageWidth || image.extension.includes('svg')) {
                    if (image?.hasSourceResize) {
                        return { ...srcset, url: `${res}/source.webp` };
                    }

                    return { ...srcset, url: this.formatImageURL(image) };
                }

                return { ...srcset, url: `${res}/${srcset.size}.webp` };
            });
        });
    }

    private setFormData() {
        if (!this.element?.properties?.length || !this.isPhysicalProductType) return;

        this.element.properties.forEach((property) => {
            const { name, parameters } = property;
            this.propertiesArray.push(
                this.fb.group({
                    label: this.fb.control(name),
                    parameters: this.fb.control(parameters),
                    value: this.fb.control(''),
                }),
            );
        });
    }

    public setPropertyValue(value: string, index: number) {
        this.propertiesArray.at(index).controls.value.patchValue(value);

        this.variantHandler();
    }

    private variantHandler() {
        let selectedPropertiesName = '';

        this.propertiesArray.value.forEach((property, index) => {
            if (this.propertiesArray.value.length === 1) {
                selectedPropertiesName = property?.value.trim();
            } else {
                selectedPropertiesName =
                    selectedPropertiesName + (index === this.propertiesArray.value.length - 1 ? '/' : '') + property.value.trim();
            }
        });

        const choosedVariant = this.variants.find((variant) => variant.name === selectedPropertiesName);

        if (!choosedVariant) return;

        this.data.element = {
            ...this.element,
            price: choosedVariant.price.value,
            oldPrice: choosedVariant.oldPrice.value,
            quantity: choosedVariant.quantity.value,
            vendorCode: choosedVariant.vendorCode.value,
            images: choosedVariant.images.length ? choosedVariant.images : this.defaultData.images,
            id: choosedVariant.id,
        };

        this.setImagesSrc();
        this.changeCounter(1);

        this.changeDetectorRef.detectChanges();
    }

    isDisabledOption(option: string, currentIndex: number): boolean {
        if (this.propertiesArray.at(currentIndex).value.value === option) return false;

        const selectedValues = this.propertiesArray.controls
            .filter((_, index) => index !== currentIndex)
            .map((control) => control.value.value.trim())
            .filter(Boolean);

        const validOptions = new Set<string>();

        for (let variant of this.variants) {
            if (!selectedValues.length && variant.isShow && variant.quantity.value !== 0 && variant?.properties?.includes(option)) {
                return false;
            }

            if (variant.isShow && variant.quantity.value !== 0 && variant?.properties?.some((prop) => selectedValues.includes(prop))) {
                variant?.properties?.forEach((prop) => validOptions.add(prop));
            }
        }

        return !validOptions.has(option);
    }

    public changeCounter(event: number): void {
        this.counterControl.setValue(Number(event));
    }

    public validateCounter(): void {
        if (Number(this.counterControl.value) > this.quantity && this.quantity !== null) {
            this.counterControl.setValue(this.quantity);
        } else if (Number(this.counterControl.value) <= 0) {
            this.counterControl.setValue(1);
        }
    }

    protected formatImgSrc(src: string): string {
        return this.utilsService.formatImgSrc(src);
    }

    public onCancel(): void {
        this.bsModalRef.hide();
    }

    public instanceId = (index: number): number => index;

    public formatImageURL(image: SelectedFileExtended): string {
        return `https://${image.defaultCdnUrl}${image.url}`;
    }

    public addItemToCart(item: ProductCard, event) {
        this.cartFacade.addItemToCart({
            ...item,
            selectedQuantity: this.counterControl.value,
            selectedProperties: this.selectedProperties,
            cardUrl: this.cardUrl,
            id: `product-${this.widgetId}-${item.id}`,
        });
        this.animateButton(event);
    }

    public animateButton(event) {
        let button: HTMLElement = event.currentTarget;
        let cart: HTMLElement = document.getElementById('shopCart');

        button.classList.add('adding-to-cart');
        setTimeout(() => {
            button.classList.remove('adding-to-cart');
        }, 1000);

        cart.classList.add('adding-to-cart');
        setTimeout(() => {
            cart.classList.remove('adding-to-cart');
        }, 600);
    }

    public get isDisabledCartButton(): boolean {
        if (!this.propertiesArray.length) return false;

        return !!this.propertiesArray.controls.filter((control) => !control.value.value).length;
    }

    public get element(): ProductCardWidgetData['element'] {
        return this.data?.element;
    }

    public get images(): ProductCard['images'] {
        return this.element?.images;
    }

    public get basketButton(): ProductCardWidgetData['basketButton'] {
        return this.data?.basketButton;
    }

    public get getBasketButtonStyles(): string {
        let styles = `${DEFAULT_BUTTON_CLASS} ${BUTTON_SIZE_CLASS_MAP[this.data.basketButton.size]}`;

        if (this.basketButton.styleType === EButtonStyleType.custom) {
            return `${styles} ${this.classes?.buttonStyles}`;
        }

        return `${styles} ${BUTTON_TYPE_CLASS_MAP[this.basketButton.styleType]}`;
    }

    public get quantity(): number {
        return this.element?.quantity;
    }

    public get unit(): ProductUnits {
        return this.element?.unit;
    }

    public get isMaxValue(): boolean {
        return +this.counterControl.value === this.quantity;
    }

    public get isMinValue(): boolean {
        return +this.counterControl.value === 0;
    }

    public get isZeroQuantity(): boolean {
        return this.quantity === 0;
    }

    public get cardUrl(): string {
        return `${location.href}#${this.displayId}`;
    }

    public get isHasAvailableVariants(): boolean {
        if (!this.variants.length || !this.isPhysicalProductType) return true;

        return !!this.variants.filter((variant) => variant.isShow).length;
    }

    public get isHasBottomSpace(): boolean {
        return this.images.length > 1;
    }

    public get cardSettings(): CardSettings {
        return this.data.cardSettings;
    }

    public get autoplay() {
        if (!this.cardSettings.gallery.changeSlide) return false;

        return {
            delay: 2000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        };
    }

    public get variants(): ProductCard['variants'] {
        return this.element?.variants || [];
    }

    public get cardImages() {
        return this.element?.images;
    }

    private get selectedProperties(): string[] {
        return Array.from(this.propertiesArray.getRawValue(), (item) => item.value);
    }

    public get isPhysicalProductType(): boolean {
        return this.element.type === ProductType.physical;
    }
}
