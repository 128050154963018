<div class="image-setup-component">
    <div class="body form form-horizontal" [formGroup]="imageSetupForm">
        <div class="mb-10">
            <label>{{ 'control_alt' | translate }}</label>
            <input
                class="form-control"
                [formControl]="alt"/>
        </div>

        <div *ngIf="isLink">
            <div class="mb-10">
                <label>{{ 'control_link' | translate }}</label>
                <input
                    class="form-control"
                    placeholder="https://example.com"
                    [class.invalid-input]="!link.valid && link.touched"
                    [formControl]="link"/>
                <div *ngIf="!link.valid && link.touched" class="text-warning">
                    <span *ngIf="link.errors.pattern">{{ 'control_errors_invalid_link' | translate }}</span>
                </div>
            </div>

            <div class="mb-15">
                <label>{{ 'control_description' | translate }}</label>
                <small class="pull-right color-light char-counter">
                    {{ description?.value?.length || 0 }}/{{ MAX_DESCRIPTION_LENGTH }}
                </small>

                <textarea
                    placeholder="{{'control_description_placeholder' | translate}}"
                    class="form-control"
                    [maxlength]="MAX_DESCRIPTION_LENGTH"
                    [formControl]="description">
                </textarea>
            </div>

            <div class="form-row">
                <div class="form-group">
                    <label class="col-xs-6 control-label">
                        {{ 'control_text_size' | translate }}
                    </label>
                    <div class="col-xs-6">
                        <sp-radio-group
                            [selected]="textSize.value"
                            [type]="ERadioGroupType.size"
                            (changeSelectedEvent)="setTextSize($event)"
                        ></sp-radio-group>
                    </div>
                </div>

                <div class="form-group">
                    <label class="col-xs-6 control-label">
                        {{ 'control_text_color' | translate }}
                    </label>
                    <div class="col-xs-6">
                        <sp-color-picker
                            [color]="textColor.value"
                            (colorChangeEvent)="setTextColor($event)">
                        </sp-color-picker>
                    </div>
                </div>

                <div class="form-group">
                    <label class="col-xs-6 control-label">
                        {{ 'control_background_color' | translate }}
                    </label>
                    <div class="col-xs-6">
                        <sp-color-picker
                            [color]="textBackground.value"
                            (colorChangeEvent)="setTextBackground($event)">
                        </sp-color-picker>
                    </div>
                </div>

                <div class="form-group">
                    <label class="col-xs-6 control-label">
                        {{ 'control_vertical_position' | translate }}
                    </label>
                    <div class="col-xs-6">
                        <sp-radio-group
                            [selected]="textPosition.value"
                            [type]="ERadioGroupType.verticalPosition"
                            (changeSelectedEvent)="setTextPosition($event)"
                        ></sp-radio-group>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer">
        <button
            type="button"
            class="btn btn-primary"
            [disabled]="!link.valid"
            (click)="onSave()">
            {{ 'apply' | translate }}
        </button>
        <a class="dotted" (click)="onClose()">{{ 'cancel' | translate }}</a>
    </div>
</div>

