import type { PipeTransform } from '@angular/core';
import type { DateTime as DateTimeType } from '@common/types';

import { Pipe } from '@angular/core';
import { DateTime } from 'luxon';

import { ELanguages } from '@common/enums';

@Pipe({
    name: 'spTimeFormat',
})
export class SpTimeFormat implements PipeTransform {
    public transform(date: DateTimeType, locale: ELanguages = ELanguages.EN): string {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm:ss', { zone: 'utc' })
            .setLocale(this.getLocale(locale))
            .setZone(timeZone)
            .toFormat('dd MMMM, yyyy HH:mm:ss');
    }

    private getLocale(locale: ELanguages): string {
        switch (locale) {
            case ELanguages.UA:
                return 'uk';
            case ELanguages.ES_MX:
                return 'es-mx';
            case ELanguages.PT_BR:
                return 'pt-br';
            case ELanguages.IT:
                return 'it';
            case ELanguages.TR:
                return 'tr';
            case ELanguages.FR:
                return 'fr';
            case ELanguages.RU:
                return 'ru';
            case ELanguages.EN:
            default:
                return 'en';
        }
    }
}
