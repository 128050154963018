import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { AspectRatio, type ProductCardWidgetData, ViewModalType } from '@web/widgets/product-card/type/product-card-widget.type';
import { Classes } from 'jss';
import { BUTTON_SIZE_CLASS_MAP, BUTTON_TYPE_CLASS_MAP, DEFAULT_BUTTON_CLASS } from '@web/widgets/common/base/base-button/base-button.const';
import { EButtonStyleType } from '@common/enums';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ProductCardModalComponent } from '@web-builder/widgets/product-card/preview-view-card/product-card-modal/product-card-modal.component';
import { type SelectedFileExtended } from '@common/types/file.type';
import { SpPipesModule } from '@common/pipes/pipes.module';
import { TransferStateService } from '@web-builder/core/services/transfer-state.service';
import { UtilsService } from '@web-builder/core/services/utils.service';

@Component({
    selector: 'web-builder-preview-view-card',
    templateUrl: './preview-view-card.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, TranslateModule, SpPipesModule],
})
export class PreviewViewCardComponent {
    @Input() data: ProductCardWidgetData;
    @Input() classes: Classes;
    @Input() displayId: string;
    @Input() widgetId: string;

    public aspectRatio = AspectRatio;

    public readonly currency: string = this.transferStateService.get('site')?.settings?.shop?.currency;

    protected readonly modalService: BsModalService = inject(BsModalService);

    constructor(protected readonly transferStateService: TransferStateService, protected readonly utilsService: UtilsService) {}

    public showCard(): void {
        this.modalService.show(ProductCardModalComponent, {
            backdrop: true,
            ignoreBackdropClick: false,
            class: `${this.classes?.modalContainerStyles} modal-lg`,
            initialState: {
                classes: this.classes,
                data: this.data,
                displayId: this.displayId,
                widgetId: this.widgetId,
            },
        });
    }

    public getImagePaddingTop(image: SelectedFileExtended): number {
        let galleryImageRatio: AspectRatio = this.data.previewModalSettings.gallery.aspectRatio;

        switch (galleryImageRatio) {
            case AspectRatio.default:
                return 100;
            case AspectRatio.manual:
                if (!image.resizedHeight || !image.resizedWidth) {
                    return 100;
                }

                return (image.resizedHeight / image.resizedWidth) * 100;
            case AspectRatio.onoToOne:
                return 100;
            case AspectRatio.fourToThree:
                return 75;
            case AspectRatio.threeToFour:
                return 133;
            case AspectRatio.threeToTwo:
                return 66;
            case AspectRatio.twoToThree:
                return 150;
            case AspectRatio.verticalWide:
                return 177;
            case AspectRatio.wide:
                return 56;
        }
    }

    public formatImageURL(image: SelectedFileExtended): string {
        return `https://${image.defaultCdnUrl}${image.url}`;
    }

    protected formatImgSrc(src: string): string {
        return this.utilsService.formatImgSrc(src);
    }

    public get cardModalViewType(): ViewModalType {
        return this.data.cardModalViewType;
    }

    public get horizontalViewType(): boolean {
        return this.cardModalViewType === ViewModalType.horizontal;
    }

    public get verticalViewType(): boolean {
        return this.cardModalViewType === ViewModalType.vertical;
    }

    public get element(): ProductCardWidgetData['element'] {
        return this.data.element;
    }

    public get previewModalSettings(): ProductCardWidgetData['previewModalSettings'] {
        return this.data.previewModalSettings;
    }

    public get wrapperClass(): string {
        return this.data.cardModalViewType === ViewModalType.horizontal
            ? this.classes?.previewHorizontalViewWrapper
            : this.classes?.previewVerticalViewWrapper;
    }

    public get showMoreButton(): ProductCardWidgetData['showMoreButton'] {
        return this.data.showMoreButton;
    }

    public get buttonClass(): string {
        let styles = `${DEFAULT_BUTTON_CLASS} ${BUTTON_SIZE_CLASS_MAP[this.showMoreButton.size]}`;

        if (this.showMoreButton.styleType === EButtonStyleType.custom) {
            return `${styles} ${this.classes?.showMoreButtonStyle}`;
        }

        return `${styles} ${BUTTON_TYPE_CLASS_MAP[this.showMoreButton.styleType]}`;
    }
}
