<div class="alert-box edu-modal" [class]="classes.modalBodyStyles">
    <div class="sp-lpc-form">
        <button type="button" class="close-modal-btn" aria-label="Close" (click)="onCloseModal()">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="sp-ui-text">
            <h2 class="edu-modal-title">{{ 'password_recovery' | translate }}</h2>
        </div>

        <div class="field-block" [class]="classes.formField">
            <p>{{ 'new_password_has_been_sent' | translate }} <strong>{{ email }}</strong>.</p>
            <br>
            <p>{{ 'please_check_your_spam_folder' | translate }}</p>
        </div>
    </div>

    <div class="edu-modal-footer"
        [class]="classes?.buttonWrapper">
        <button
            type="button"
            class="sp-ui-button sp-ui-button-md sp-ui-button-primary-light"
            (click)="onConfirm()"
        >
            {{ 'sign_in' | translate }}
        </button>
    </div>
</div>
