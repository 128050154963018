<web-builder-shop-cart-icon-view></web-builder-shop-cart-icon-view>
<div *ngFor="let block of preparedStructure; let blockIndex = index;" [id]="getBlockDisplayId(block)">
    <ng-container *ngIf="block.type === 'specific'; else showDefault">
        <ng-container *ngIf="!is404Page()">
            <web-builder-specific-block-wrapper *ngIf="!isSpecificBlockHidden(block)" [block]="block" [index]="blockIndex">
                <div *ngFor="let section of block.sections">
                    <web-builder-specific-section-wrapper [section]="section" [block]="block">
                        <div
                            *ngFor="let column of section.columns; let columnIndex = index"
                            class="column-container"
                            [style.marginTop]="section.columnIndent / 2 + 'px'"
                            [style.marginBottom]="section.columnIndent / 2 + 'px'"
                            [class]="getColumnClassesSpec(column, section.columns.length)"
                            [class.without-widgets]="!column.widgets.length"
                        >
                            <web-builder-specific-column-wrapper
                                [column]="column"
                                [columnSection]="section"
                                [block]="block"
                                [index]="columnIndex"
                            >
                                <div *ngFor="let widget of column.widgets; let widgetIndex = index">
                                    <web-builder-widget-resolver
                                        *ngIf="widget"
                                        [widget]="widget"
                                        [index]="widgetIndex"
                                        [isSpecific]="true"
                                        [isMobile]="isMobile"
                                    ></web-builder-widget-resolver>
                                </div>
                            </web-builder-specific-column-wrapper>
                        </div>
                    </web-builder-specific-section-wrapper>
                </div>
                <ng-container *ngIf="block.data.hideContentOnMobile" logo>
                    <ng-container *ngIf="getSpecificBlockLogoWidget(block) as logo">
                        <web-builder-widget-resolver
                            [widget]="logo"
                            [isMobile]="isMobile"
                        ></web-builder-widget-resolver>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="block.data.hideContentOnMobile" mobile>
                    <div class="specific-mobile-widgets-list">
                        <div *ngFor="let widget of getSpecificWidgets(block); let widgetIndex = index" class="specific-mobile-widget">
                            <web-builder-widget-resolver
                                *ngIf="widget"
                                [widget]="widget"
                                [index]="widgetIndex"
                                [isMobile]="isMobile"
                            ></web-builder-widget-resolver>
                        </div>
                    </div>
                </ng-container>
            </web-builder-specific-block-wrapper>
        </ng-container>
    </ng-container>
    <ng-template #showDefault>
        <ng-container *ngIf="!isSharedBlock(block) || !is404Page()">
            <web-builder-block-wrapper [block]="block" [index]="blockIndex">
                <div *ngFor="let section of block.sections">
                    <div class="section-container">
                        <div
                            *ngFor="let column of section.columns; let columnIndex = index"
                            class="column-container"
                            [style.marginTop]="section.columnIndent / 2 + 'px'"
                            [style.marginBottom]="section.columnIndent / 2 + 'px'"
                            [class]="getColumnClasses(column, section)"
                            [style.width]="getColumnWidth(column, section)"
                        >
                            <web-builder-column-wrapper
                                [column]="column"
                                [columnSection]="section"
                                [index]="columnIndex"
                            >
<!--                                <div-->
<!--                                    [ngStyle]="{'z-index': getWidgetZIndex(widget) }"-->
<!--                                    *ngFor="let widget of column.widgets; let widgetIndex = index">-->
<!--                                >-->
                                <div *ngFor="let widget of column.widgets; let widgetIndex = index">
                                    <web-builder-widget-resolver
                                        *ngIf="!isStructure(widget) && widget"
                                        [widget]="widget"
                                        [index]="widgetIndex"
                                        [isMobile]="isMobile"
                                    ></web-builder-widget-resolver>

                                    <web-builder-structure-wrapper
                                        *ngIf="isStructure(widget)"
                                        [structure]="$any(widget)"
                                    >
                                        <ng-container *ngFor="let row of widget.widgets; let rowIndex = index">
                                            <div [class]="'row' + rowIndex">
                                                <ng-container *ngFor="let structureWidget of $any(row); let structureWidgetIndex = index">
                                                    <web-builder-widget-resolver
                                                        *ngIf="structureWidget"
                                                        [widget]="structureWidget"
                                                        [index]="structureWidgetIndex"
                                                        [isMobile]="isMobile"
                                                    ></web-builder-widget-resolver>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                    </web-builder-structure-wrapper>
                                </div>
                            </web-builder-column-wrapper>
                        </div>
                    </div>
                </div>
            </web-builder-block-wrapper>
        </ng-container>
    </ng-template>
</div>

<div *ngIf="structure.length && showPoweredBy && !isTestGroupSiteId" class="powered-by-block">
    <a class="powered-sendpulse" [href]="sendpulseArticleLink" target="_blank">
        <img [src]="sendpulseLogoImg" alt="SendPulse logo" />
        <span>{{ "footer_logo_powered_by" | translate }} SendPulse</span>
    </a>
</div>

<div
    *ngIf="structure.length && showPoweredBy && isTestGroupSiteId"
    class="powered-by-block"
    [class.new-banner]="isOddSiteId"
>
    <ng-container *ngIf="siteId && isOddSiteId; then newBanner; else oldBanner"> </ng-container>
    <ng-template #oldBanner>
        <a class="powered-sendpulse" [href]="sendpulseArticleLinkTestV1" target="_blank">
            <img [src]="sendpulseLogoImg" alt="SendPulse logo" />
            <span>{{ "footer_logo_powered_by" | translate }} SendPulse</span>
        </a>
    </ng-template>
    <ng-template #newBanner>
        <a class="powered-sendpulse" [href]="sendpulseArticleLinkTestV3" target="_blank">
            <img [src]="sendpulseLogoImg" alt="SendPulse logo" />
            <span>{{ "footer_logo_powered_by_ab_test" | translate }}</span>
        </a>
    </ng-template>
</div>

<web-modals></web-modals>
