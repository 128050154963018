export const environment = {
    production: true,
    constructorHref: '/websites/builder/',
    fileManagerCdnUrl: 'https://fm.sendpul.se',
    memberAreaUrl: 'https://login.sendpulse.com',
    proxyUrl: '/sp-auth',
    eduStudentUrl: '/courses',
    landingPagesHref: '/landing-pages/',
    type: 'prod',
};
