export enum Widgets {
    AVATAR = 'avatar',
    BUTTON = 'button',
    INDENT = 'indent',
    LINE = 'line',
    SOCIAL_NETWORKS = 'social-networks',
    CHAT_BOTS = 'chat-bots',
    TEXT = 'text',
    GALLERY = 'gallery',
    SUBSCRIPTION_FORM = 'subscription-form',
    PAYMENT_FORM = 'payment',
    TIMER = 'timer',
    ACCORDION = 'accordion',
    MENU = 'menu',
    MAP = 'map',
    EDU_BANNER = 'edu-banner',
    EDU_TEACHERS = 'edu-teachers',
    AUDIO = 'audio',
    FILE = 'file',
    IMAGE = 'image',
    VIDEO = 'video',
    FORM = 'form',
    TASK = 'task',
    LOGIN_EDU = 'login-edu',
    TESTIMONIAL = 'testimonial',
    CODE = 'code',
    NAVIGATION = 'navigation',
    LOGO = 'logo',
    PRODUCT_CARD = 'product-card',
    SHOP_CART = 'shop-cart',
}

export enum WidgetsDataTypes {
    image = 'image',
    uploadImage = 'uploadImage',
    images = 'images',
    backgroundImage = 'backgroundImage',
    customPreviewFile = 'customPreviewFile',
    overlayImage = 'overlayImage',
}

export enum WidgetDataSubTypes {
    column = 'column',
    block = 'block',
}
