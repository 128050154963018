<ng-container *ngIf="block.data?.isTopShapeDivider">
    <svg
        class="desktop-top-divider"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        preserveAspectRatio="none"
        shape-rendering="geometricPrecision"
        style="position: absolute; z-index: 1; top: -0.5px; left: 0px; right: 0px;"
        [attr.fill]="getDividerColorValue(block.data?.topShapeDividerColor)"
        [attr.height]="block.data?.topShapeDividerHeight">
        <defs>
            <pattern [id]="'top-divider_' + block.id" x="0" y="0" [attr.width]="block.data?.topShapeDividerRepeat ? (100 / block.data?.topShapeDividerRepeat) + '%' : '100%'" height="101%" viewBox="0 0 1920 100" preserveAspectRatio="none">
                <mask [id]="'top-mask_' + block.id" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="1920" height="100">
                    <rect width="1920" height="100"/>
                </mask>
                <g #desktopTopDividerContainer [id]="'desktop-top-divider-container_' + block.id" [attr.mask]="'url(#top-mask_' + block.id + ')'">
                </g>
            </pattern>
        </defs>
        <rect x="0" y="0" width="100%" height="100%" [attr.fill]="'url(#top-divider_' + block.id + ')'"/>
    </svg>
</ng-container>

<ng-container *ngIf="block.data?.isBottomShapeDivider">
    <svg
        class="desktop-bottom-divider"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        preserveAspectRatio="none"
        shape-rendering="geometricPrecision"
        style="position: absolute; z-index: 1; bottom: -0.5px; left: 0px; right: 0px;"
        [attr.fill]="getDividerColorValue(block.data?.bottomShapeDividerColor)"
        [attr.height]="block.data?.bottomShapeDividerHeight">
        <defs>
            <pattern [id]="'bottom-divider_' + block.id" x="0" y="0" [attr.width]="block.data?.bottomShapeDividerRepeat ? (100 / block.data?.bottomShapeDividerRepeat) + '%' : '100%'" height="101%" viewBox="0 0 1920 100" preserveAspectRatio="none">
                <mask [id]="'bottom-mask_' + block.id" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="1920" height="100">
                    <rect width="1920" height="100"/>
                </mask>
                <g #desktopBottomDividerContainer [id]="'desktop-bottom-divider-container_' + block.id" [attr.mask]="'url(#bottom-mask_' + block.id + ')'">
                </g>
            </pattern>
        </defs>
        <rect x="0" y="0" width="100%" height="100%" [attr.fill]="'url(#bottom-divider_' + block.id + ')'"/>
    </svg>
</ng-container>

<ng-container *ngIf="block.mobileData?.isTopShapeDivider">
    <svg
        class="mobile-top-divider"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        preserveAspectRatio="none"
        shape-rendering="geometricPrecision"
        style="position: absolute; z-index: 1; top: -0.5px; left: 0px; right: 0px;"
        [attr.fill]="getDividerColorValue(block.mobileData?.topShapeDividerColor)"
        [attr.height]="block.mobileData?.topShapeDividerHeight">
        <defs>
            <pattern [id]="'mobile-top-divider_' + block.id" x="0" y="0" [attr.width]="block.mobileData?.topShapeDividerRepeat ? (100 / block.mobileData?.topShapeDividerRepeat) + '%' : '100%'" height="101%" viewBox="0 0 1920 100" preserveAspectRatio="none">
                <mask [id]="'mobile-top-mask_' + block.id" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="1920" height="100">
                    <rect width="1920" height="100"/>
                </mask>
                <g #mobileTopDividerContainer [id]="'mobile-top-divider-container_' + block.id" [attr.mask]="'url(#mobile-top-mask_' + block.id + ')'">
                </g>
            </pattern>
        </defs>
        <rect x="0" y="0" width="100%" height="100%" [attr.fill]="'url(#mobile-top-divider_' + block.id + ')'"/>
    </svg>
</ng-container>

<ng-container *ngIf="block.mobileData?.isBottomShapeDivider">
    <svg
        class="mobile-bottom-divider"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        preserveAspectRatio="none"
        shape-rendering="geometricPrecision"
        style="position: absolute; z-index: 1; bottom: -0.5px; left: 0px; right: 0px;"
        [attr.fill]="getDividerColorValue(block.mobileData?.bottomShapeDividerColor)"
        [attr.height]="block.mobileData?.bottomShapeDividerHeight">
        <defs>
            <pattern [id]="'mobile-bottom-divider_' + block.id" x="0" y="0" [attr.width]="block.mobileData?.bottomShapeDividerRepeat ? (100 / block.mobileData?.bottomShapeDividerRepeat) + '%' : '100%'" height="101%" viewBox="0 0 1920 100" preserveAspectRatio="none">
                <mask [id]="'mobile-bottom-mask_' + block.id" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="1920" height="100">
                    <rect width="1920" height="100"/>
                </mask>
                <g #mobileBottomDividerContainer [id]="'mobile-bottom-divider-container_' + block.id" [attr.mask]="'url(#mobile-bottom-mask_' + block.id + ')'">
                </g>
            </pattern>
        </defs>
        <rect x="0" y="0" width="100%" height="100%" [attr.fill]="'url(#mobile-bottom-divider_'  + block.id + ')'"/>
    </svg>
</ng-container>