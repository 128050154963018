import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { HttpErrorResponse } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateModule } from '@ngx-translate/core';

import { EduCourse } from '@common/types/edu-course.type';

import { EduHttpService } from '@web-builder/core/services/REST/edu.http.service';

import { environment } from '@web-builder-env/environment';
import { Classes } from 'jss';
import { type SignInModalResult } from '@web-builder/widgets/login-edu/type/login-edu.type';

type SignInSuccessModalResult = {
    success: boolean;
    message?: 'enroll' | 'changeUser';
};

@Component({
    selector: 'web-builder-sign-in-success-modal',
    styleUrls: ['./sign-in-success-modal.component.less'],
    templateUrl: './sign-in-success-modal.component.html',
    imports: [CommonModule, ReactiveFormsModule, TranslateModule],
    standalone: true,
})
export class SignInSuccessModalComponent implements OnInit {
    @Input() public eduCourse!: EduCourse;
    @Input() public signInData!: Partial<SignInModalResult['data']>;
    @Input() public classes!: Classes;

    public onClose: Subject<SignInSuccessModalResult>;

    public invalidCourse: boolean = false;

    constructor(private readonly bsModalRef: BsModalRef, private readonly eduHttpService: EduHttpService) {}

    public ngOnInit(): void {
        this.onClose = new Subject();
    }

    public onEnroll(): void {
        this.eduHttpService.registerOnCourse(this.eduCourse.id).subscribe(
            (res) => {
                console.log(res);
                window.location.href = environment.eduStudentUrl;
            },
            (error: HttpErrorResponse) => {
                if (error.status === 422) {
                    const { errors } = error?.error;
                    if ('courseId' in errors) {
                        this.invalidCourse = true;
                    }
                }

                if (error.status === 403) {
                    this.invalidCourse = true;
                }
            },
        );
    }

    public onChangeUser(): void {
        this.onClose.next({ success: true, message: 'changeUser' });
        this.bsModalRef.hide();
    }

    public onCloseModal(): void {
        this.bsModalRef.hide();
    }
}
