import type { JssStyle } from 'jss';
import type { WebWidgetEntity } from '@web/types';
import type { FormWidgetData } from '@web/widgets/form/type/form-widget.type';

import { getBaseFormStyles } from '@web/widgets/common/base/base-form/base-form.styles';
import { Widgets } from '@common/enums';

export function getStyles(widget: WebWidgetEntity<FormWidgetData>, { isConstructor }: any): JssStyle {
    return getBaseFormStyles(widget.data, Widgets.FORM, isConstructor);
}
