<div class="alert-box edu-modal" [class]="classes.modalBodyStyles" [formGroup]="form">
    <div class="sp-lpc-form">
        <button type="button" class="close-modal-btn" aria-label="Close" (click)="onCloseModal()">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="sp-ui-text">
            <h2 class="edu-modal-title">{{ "sign_up_title" | translate }}</h2>
        </div>

        <div *ngIf="form.hasError('invalidCourse')" class="error-alert">
            {{ "error_invalid_course" | translate }}
        </div>
        <div *ngIf="form.hasError('unique')" class="error-alert">
            {{ "error_user_already_exist" | translate }}
        </div>

        <div *ngIf="!form.hasError('invalidCourse')">
            <div class="field-block" [class]="classes.formField">
                <label>{{ "name" | translate }}</label>
                <input
                    name="name"
                    [class]="classes.formControl"
                    [class.has-error]="name.invalid && name.touched"
                    [class.disabled]="form.disabled"
                    [placeholder]="'input_name_placeholder' | translate"
                    [formControl]="name"
                />
                <div *ngIf="name.invalid && name.touched" class="error-reason text-danger">
                    <span *ngIf="name.hasError('required')">{{ "error_required" | translate }}</span>
                </div>
            </div>

            <div class="field-block" [class]="classes.formField">
                <label>Email</label>
                <input
                    name="email"
                    [class]="classes.formControl"
                    placeholder="example@mail.com"
                    [class.has-error]="email.invalid && email.touched"
                    [class.disabled]="form.disabled"
                    [formControl]="email"
                />
                <div *ngIf="email.invalid && email.touched" class="error-reason text-danger">
                    <span *ngIf="email.hasError('required')">{{ "error_required" | translate }}</span>
                    <span *ngIf="email.hasError('email')">{{ "error_invalid_email" | translate }}</span>
                    <span *ngIf="email.hasError('unique')">{{ "error_user_already_exist" | translate }}</span>
                    <span *ngIf="email.hasError('pattern')">{{ "error_invalid_symbol" | translate }}</span>
                    <!--                    TODO: RENAME TRANSLATIONS KEY -->
                </div>
            </div>

            <ng-container *ngIf="isShowCheckboxes">
                <div class="field-block" [class]="classes.formField" formArrayName="checkboxes">
                    <ng-container *ngFor="let control of checkboxesControls.controls">
                        <label  class="sp-checkbox">
                            <input class="checkbox-input" [class.is-invalid]="control.invalid" type="checkbox" (change)="changeCheckBoxValue(control)">
                            <span *ngIf="control.value.required" class="checkbox-text" [innerHTML]="' ' + (control.value.label | removePTags) + ' *'"></span>
                            <span *ngIf="!control.value.required" class="checkbox-text" [innerHTML]="' ' + (control.value.label | removePTags)"></span>
                        </label>
                        <ng-container *ngIf="control.invalid && control.touched">
                            <div class="error-reason text-danger">
                                <span>
                                    {{ 'control_errors_checkbox_required' | translate }}
                                </span>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>

    <div *ngIf="!form.hasError('invalidCourse')"
        class="edu-modal-footer"
        [class]="classes?.buttonWrapper">
        <button *ngIf="isAuthorizationButtonActive" type="button" class="sp-ui-button sp-ui-button-md sp-ui-button-secondary-light sp-ui-button-secondary-edu" (click)="onSignIn()">
            {{ "sign_in" | translate }}
        </button>
        <button
            type="button"
            class="sp-ui-button sp-ui-button-md sp-ui-button-primary-light"
            [class.disabled]="form.disabled"
            (click)="onSignUp()"
        >
            {{ "sign_up" | translate }}
        </button>
    </div>
</div>
