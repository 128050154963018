import type { ThemeConfig } from '@libs/themes/src/types/theme-config.type';
import type { ThemeButtonPreset, ThemeColorPreset, ThemeFontPreset, ThemeSettingsState } from '@libs/themes';

import { EThemeVariants } from '@libs/themes/src/enums/theme-variants.enum';

import { getWebsiteThemeColorsPresets } from '@libs/themes/src/variants/website/models/color-presets.model';
import { getWebsiteThemeButtonPresets } from '@libs/themes/src/variants/website/models/button-presets.model';
import { getWebsiteThemeFontPresets } from '@libs/themes/src/variants/website/models/font-presets.model';
import { adaptThemeSettings } from '@libs/themes/src/variants/website/utils/adapter';

import { WebThemeFormatter } from './theme-formatter/web-theme-formatter';
import { AbstractBaseThemeStrategy } from './abstract-base-theme.strategy';

export class WebsiteThemeStrategy extends AbstractBaseThemeStrategy {
    public type = EThemeVariants.WEBSITE;
    public config: ThemeConfig = {
        fonts: true,
        colors: true,
        buttons: true,
    };
    public formatter = new WebThemeFormatter();

    public adaptThemeSettings(settings: ThemeSettingsState): ThemeSettingsState {
        return adaptThemeSettings(settings);
    }

    public get colorPresets(): ThemeColorPreset[] {
        return getWebsiteThemeColorsPresets();
    }

    public get buttonPresets(): ThemeButtonPreset[] {
        return getWebsiteThemeButtonPresets();
    }

    public get fontPresets(): ThemeFontPreset[] {
        return getWebsiteThemeFontPresets();
    }
}
